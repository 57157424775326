import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import useStyles from './useStyles';

import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';

import LockIcon from 'src/assets/icons/lock.svg';
import { MainNavigation } from 'src/components/Views/MainNavigation';
import { MainHeader } from 'src/components/Views/MainHeader';
import { IconButton, Typography } from '@mui/material';
import { ROLES } from 'src/constants/ROLES';

import { ReactComponent as LogoutIcon } from 'src/assets/icons/logout.svg';

import {
  getAccountManagerMenuLinks,
  adminMenuLinks,
  battleCardsCompletingLinks,
} from 'src/constants/MenuLinks';
import { resetStore } from 'src/modules/app/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ScreenName, Store } from 'src/types';
import { getSurveyResultAccess } from '../../../modules/surveys/actions';

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      overflowX: 'hidden',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

type Props = {
  currentRole: ROLES;
  isBattleCards?: boolean;
  availableScreens?: ScreenName[];
  managerLoginLink?: string;
};

export const MainLayout: React.FC<Props> = ({
  currentRole,
  isBattleCards,
  availableScreens,
  managerLoginLink,
}) => {
  const { projectId } = useSelector((state: Store) => state.projects);
  const { surveyResultAccessing } = useSelector((state: Store) => state.surveys);

  const dispatch = useDispatch();

  const classes = useStyles();

  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const signOut = () => {
    if (currentRole === ROLES.ROLE_ADMIN || currentRole === ROLES.NO_ROLE) {
      dispatch(resetStore());
      window.location.href = process.env.REACT_APP_LOGOUT_URL as string;
    } else {
      window.location.href = managerLoginLink as string;
      dispatch(resetStore());
    }
  };

  const menuLinksControl = () => {
    if (currentRole === ROLES.ROLE_ACCOUNT_MANAGER) {
      if (isBattleCards) {
        return battleCardsCompletingLinks;
      }

      return getAccountManagerMenuLinks(availableScreens);
    }

    if (currentRole === ROLES.ROLE_ADMIN) {
      return adminMenuLinks(projectId === 0, surveyResultAccessing?.allowedSurveyResultPage);
    }

    return [];
  };

  useEffect(() => {
    if (currentRole === ROLES.ROLE_ADMIN) {
      const interval = setInterval(() => {
        dispatch(getSurveyResultAccess.request());
      }, 15000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [currentRole, dispatch]);

  return (
    <section className={classes.main_section}>
      <Drawer variant="permanent" open={open}>
        <aside className={classes.aside}>
          <Box sx={{ justifyContent: 'flex-end', display: 'flex' }}>
            <IconButton sx={{ marginRight: '-10px' }} onClick={handleDrawerClose}>
              <img alt="lock" className={classes.lock_icon} src={LockIcon} />
            </IconButton>
          </Box>
          <MainNavigation
            role={currentRole}
            menuLinks={menuLinksControl()}
            isDrawerOpen={open}
            handleDrawerOpen={handleDrawerOpen}
          />
          <div className={classes.logoutButton} onClick={signOut}>
            <LogoutIcon width={16} height={16} fill="#EAEBEB" />
            <Typography sx={{ fontWeight: 700 }} variant="h3">
              Sign out
            </Typography>
          </div>
        </aside>
      </Drawer>

      <section className={classes.container}>
        <MainHeader />
        <main className={classes.main}>
          <Outlet />
        </main>
      </section>
    </section>
  );
};

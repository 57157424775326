export enum ScreenNames {
  SETTINGS_BUSINESS_UNITS = 'SETTINGS_BUSINESS_UNITS',
  SETTINGS_ACCOUNT_MANAGER_LIST = 'SETTINGS_ACCOUNT_MANAGER_LIST',
  SETTINGS_CUSTOMER_REGISTRY = 'SETTINGS_CUSTOMER_REGISTRY',
  ACCOUNT_MANAGER_SURVEY_SETTINGS = 'ACCOUNT_MANAGER_SURVEY_SETTINGS',
  SETTINGS_SURVEY_STATUS = 'SETTINGS_SURVEY_STATUS',
  SURVEY_RESULT = 'SURVEY_RESULT',
  CAMPAIGN_TARGET_TARGET_GIVEN = 'CAMPAIGN_TARGET_TARGET_GIVEN',
  CAMPAIGN_TARGET_PRICE_POTENTIAL = 'CAMPAIGN_TARGET_PRICE_POTENTIAL',
  PRICE_POTENTIAL_MATRIX = 'PRICE_POTENTIAL_MATRIX',
  TARGET_SETTINGS = 'TARGET_SETTINGS',
  EASE_OF_INCREASE_INDICATOR_WEIGHTING = 'EASE_OF_INCREASE_INDICATOR_WEIGHTING',
  BUSINESS_CASE_ASSUMPTIONS = 'BUSINESS_CASE_ASSUMPTIONS',
  TARGET_FINE_TUNING = 'TARGET_FINE_TUNING',
  CAMPAIGN_IMPACT = 'CAMPAIGN_IMPACT',
  FINAL_CUSTOMER_TARGETS = 'FINAL_CUSTOMER_TARGETS',
  CUSTOMER_TARGETS = 'CUSTOMER_TARGETS',
  ESCALATION_PROCESS = 'ESCALATION_PROCESS',
  SECOND_SURVEY = 'SECOND_SURVEY',
}

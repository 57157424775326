import { createReducer } from 'deox';
import { produce } from 'immer';
import { BattleCardsState } from './types';

import {
  getBattleCardsCustomersForAdmin,
  getBattleCardsCustomersForAccountManager,
  getBattleCard,
  createObjectionHandling,
  deleteObjectionHandling,
  createValueArgumentation,
  deleteValueArgumentation,
  getBattleCardForAccountManager,
  createValueArgumentationForAccountManager,
  deleteValueArgumentationForAccountManager,
  createObjectionHandlingForAccountManager,
  deleteObjectionHandlingForAccountManager,
  updatePriceIncreaseBridgeContractTerm,
  updatePriceIncreaseBridgePlanB,
  createPriceIncreaseBridgeContractTerm,
  deletePriceIncreaseBridgeContractTerm,
  createPriceIncreaseBridgePlanB,
  deletePriceIncreaseBridgePlanB,
  updatePriceIncreaseBridgeContractTermForAccountManager,
  updatePriceIncreaseBridgePlanBForAccountManager,
  createPriceIncreaseBridgeContractTermForAccountManager,
  createPriceIncreaseBridgePlanBForAccountManager,
  deletePriceIncreaseBridgeContractTermForAccountManager,
  deletePriceIncreaseBridgePlanBForAccountManager,
  getBattleCardsMailStatuses,
  sendBattleCardsMailStatusesToAll,
  sendBattleCardsToAccountManager,
} from './actions';

const initialState: BattleCardsState = {
  loading: false,
  customers: [],
  customersPageable: null,
  battleCardData: null,
  battleCardsMailStatuses: null,
  loadingButtons: false,
};
export const battleCardsReducer = createReducer(initialState, (handleAction) => [
  handleAction(getBattleCardsCustomersForAdmin.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(getBattleCardsCustomersForAdmin.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.customers = payload.data;
      draft.customersPageable = payload.pageable;
    }),
  ),
  handleAction(getBattleCardsCustomersForAdmin.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),

  handleAction(getBattleCardsCustomersForAccountManager.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(getBattleCardsCustomersForAccountManager.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.customers = payload.data;
      draft.customersPageable = payload.pageable;
    }),
  ),
  handleAction(getBattleCardsCustomersForAccountManager.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(getBattleCard.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(getBattleCard.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.battleCardData = payload;
    }),
  ),
  handleAction(getBattleCard.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(getBattleCardForAccountManager.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(getBattleCardForAccountManager.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.battleCardData = payload;
    }),
  ),
  handleAction(getBattleCardForAccountManager.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),

  // Battle card objection handling
  handleAction(createObjectionHandling.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(createObjectionHandling.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      if (state.battleCardData) {
        draft.battleCardData = { ...state.battleCardData, objectionHandling: payload };
      }
    }),
  ),
  handleAction(createObjectionHandling.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(createObjectionHandlingForAccountManager.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(createObjectionHandlingForAccountManager.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      if (state.battleCardData) {
        draft.battleCardData = { ...state.battleCardData, objectionHandling: payload };
      }
    }),
  ),
  handleAction(createObjectionHandlingForAccountManager.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(deleteObjectionHandling.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(deleteObjectionHandling.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      if (state.battleCardData) {
        draft.battleCardData = { ...state.battleCardData, objectionHandling: payload };
      }
    }),
  ),
  handleAction(deleteObjectionHandling.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(deleteObjectionHandlingForAccountManager.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(deleteObjectionHandlingForAccountManager.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      if (state.battleCardData) {
        draft.battleCardData = { ...state.battleCardData, objectionHandling: payload };
      }
    }),
  ),
  handleAction(deleteObjectionHandlingForAccountManager.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),

  // Battle card value argumentation
  handleAction(createValueArgumentation.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(createValueArgumentation.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      if (state.battleCardData) {
        draft.battleCardData = { ...state.battleCardData, valueArgumentation: payload };
      }
    }),
  ),
  handleAction(createValueArgumentation.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(createValueArgumentationForAccountManager.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(createValueArgumentationForAccountManager.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      if (state.battleCardData) {
        draft.battleCardData = { ...state.battleCardData, valueArgumentation: payload };
      }
    }),
  ),
  handleAction(createValueArgumentationForAccountManager.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(deleteValueArgumentation.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(deleteValueArgumentation.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      if (state.battleCardData) {
        draft.battleCardData = { ...state.battleCardData, valueArgumentation: payload };
      }
    }),
  ),
  handleAction(deleteValueArgumentation.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(deleteValueArgumentationForAccountManager.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(deleteValueArgumentationForAccountManager.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      if (state.battleCardData) {
        draft.battleCardData = { ...state.battleCardData, valueArgumentation: payload };
      }
    }),
  ),
  handleAction(deleteValueArgumentationForAccountManager.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(updatePriceIncreaseBridgeContractTerm.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(updatePriceIncreaseBridgeContractTerm.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      if (state.battleCardData) {
        draft.battleCardData = { ...state.battleCardData, priceIncreaseBridge: payload };
      }
    }),
  ),
  handleAction(updatePriceIncreaseBridgeContractTerm.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(updatePriceIncreaseBridgeContractTermForAccountManager.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(
    updatePriceIncreaseBridgeContractTermForAccountManager.success,
    (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        if (state.battleCardData) {
          draft.battleCardData = { ...state.battleCardData, priceIncreaseBridge: payload };
        }
      }),
  ),
  handleAction(updatePriceIncreaseBridgeContractTermForAccountManager.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(updatePriceIncreaseBridgePlanB.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(updatePriceIncreaseBridgePlanB.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      if (state.battleCardData) {
        draft.battleCardData = { ...state.battleCardData, priceIncreaseBridge: payload };
      }
    }),
  ),
  handleAction(updatePriceIncreaseBridgePlanB.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(updatePriceIncreaseBridgePlanBForAccountManager.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(updatePriceIncreaseBridgePlanBForAccountManager.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      if (state.battleCardData) {
        draft.battleCardData = { ...state.battleCardData, priceIncreaseBridge: payload };
      }
    }),
  ),
  handleAction(updatePriceIncreaseBridgePlanBForAccountManager.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(createPriceIncreaseBridgeContractTerm.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(createPriceIncreaseBridgeContractTerm.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      if (state.battleCardData) {
        draft.battleCardData = { ...state.battleCardData, priceIncreaseBridge: payload };
      }
    }),
  ),
  handleAction(createPriceIncreaseBridgeContractTerm.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(createPriceIncreaseBridgeContractTermForAccountManager.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(
    createPriceIncreaseBridgeContractTermForAccountManager.success,
    (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        if (state.battleCardData) {
          draft.battleCardData = { ...state.battleCardData, priceIncreaseBridge: payload };
        }
      }),
  ),
  handleAction(createPriceIncreaseBridgeContractTermForAccountManager.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(createPriceIncreaseBridgePlanB.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(createPriceIncreaseBridgePlanB.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      if (state.battleCardData) {
        draft.battleCardData = { ...state.battleCardData, priceIncreaseBridge: payload };
      }
    }),
  ),
  handleAction(createPriceIncreaseBridgePlanB.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(createPriceIncreaseBridgePlanBForAccountManager.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(createPriceIncreaseBridgePlanBForAccountManager.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      if (state.battleCardData) {
        draft.battleCardData = { ...state.battleCardData, priceIncreaseBridge: payload };
      }
    }),
  ),
  handleAction(createPriceIncreaseBridgePlanBForAccountManager.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(deletePriceIncreaseBridgeContractTerm.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(deletePriceIncreaseBridgeContractTerm.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      if (state.battleCardData) {
        draft.battleCardData = { ...state.battleCardData, priceIncreaseBridge: payload };
      }
    }),
  ),
  handleAction(deletePriceIncreaseBridgeContractTerm.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(deletePriceIncreaseBridgeContractTermForAccountManager.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(
    deletePriceIncreaseBridgeContractTermForAccountManager.success,
    (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        if (state.battleCardData) {
          draft.battleCardData = { ...state.battleCardData, priceIncreaseBridge: payload };
        }
      }),
  ),
  handleAction(deletePriceIncreaseBridgeContractTermForAccountManager.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(deletePriceIncreaseBridgePlanB.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(deletePriceIncreaseBridgePlanB.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      if (state.battleCardData) {
        draft.battleCardData = { ...state.battleCardData, priceIncreaseBridge: payload };
      }
    }),
  ),
  handleAction(deletePriceIncreaseBridgePlanB.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(deletePriceIncreaseBridgePlanBForAccountManager.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(deletePriceIncreaseBridgePlanBForAccountManager.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      if (state.battleCardData) {
        draft.battleCardData = { ...state.battleCardData, priceIncreaseBridge: payload };
      }
    }),
  ),
  handleAction(deletePriceIncreaseBridgePlanBForAccountManager.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(getBattleCardsMailStatuses.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(getBattleCardsMailStatuses.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.battleCardsMailStatuses = payload;
    }),
  ),
  handleAction(getBattleCardsMailStatuses.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(sendBattleCardsMailStatusesToAll.request, (state) =>
    produce(state, (draft) => {
      draft.loadingButtons = true;
    }),
  ),
  handleAction(sendBattleCardsMailStatusesToAll.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loadingButtons = false;

      if (state.battleCardsMailStatuses && !payload.isSecondBattleCardsMailStatus) {
        draft.battleCardsMailStatuses = {
          ...state.battleCardsMailStatuses,
          data: state.battleCardsMailStatuses.data.map((status) => ({
            ...status,
            battleCardsSent: true,
            status: 'SENT',
          })),
        };
      }
    }),
  ),
  handleAction(sendBattleCardsMailStatusesToAll.fail, (state) =>
    produce(state, (draft) => {
      draft.loadingButtons = false;
    }),
  ),
  handleAction(sendBattleCardsToAccountManager.success, (state, { payload }) =>
    produce(state, (draft) => {
      const { isSecondBattleCards, accountManagerId } = payload;

      if (state.battleCardsMailStatuses && !isSecondBattleCards) {
        draft.battleCardsMailStatuses = {
          ...state.battleCardsMailStatuses,
          data: state.battleCardsMailStatuses.data.map((status) =>
            status.accountManagerId === accountManagerId
              ? {
                  ...status,
                  battleCardsSent: true,
                  status: 'SENT',
                }
              : status,
          ),
        };
      }
    }),
  ),
]);

import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  wrapper: {
    marginTop: '20px',
    overflowX: 'auto',
    overflowY: 'auto',
    boxShadow: '0px 2px 10px rgba(199, 201, 201, 0.5)',
    borderRadius: '8px',
  },
  table: {
    width: '100%',
    minWidth: '1180px',
    borderCollapse: 'collapse',
    borderRadius: '8px',
    '& th, td': {
      paddingLeft: 24,
      paddingRight: 24,
    },
  },
  tableHeader: {
    minHeight: '154px',
    '& td': {
      backgroundColor: COLORS.blue12,
      minWidth: 140,
      fontSize: 16,
      color: COLORS.black2,
      height: '65px',
    },

    '& tr:nth-child(2) td': {
      textAlign: 'center',
    },
    '& tr:first-child th': {
      backgroundColor: COLORS.gray29,
      textAlign: 'left',
      fontWeight: 700,
      color: COLORS.black6,
      fontSize: 16,
      paddingLeft: 24,
      borderBottom: '1px solid #B3B3BB',
      height: 78,
    },
  },
  tableHeaderColumnLeft: {
    textAlign: 'left',
    backgroundColor: COLORS.gray29,
  },
  headerCustomerColumn: {
    fontSize: 16,
    color: COLORS.black6,
    fontWeight: 400,
  },
  headerCustomerIdColumn: {
    fontSize: 16,
    color: COLORS.black6,
    fontWeight: 400,
    minWidth: 160,
  },
  headerRightBorder: {
    borderRight: '1px solid #B3B3BB',
  },
  headerTopLeftLastColumns: {
    border: `1px solid ${COLORS.blue7}`,
    height: '40px',
  },
  // general information
  headerTop: {
    backgroundColor: COLORS.blue12,
    borderLeft: '1px solid #B3B3BB',
    borderRight: '1px solid #B3B3BB',
    borderBottom: '1px solid #B3B3BB',
    height: 78,
    paddingTop: 16,
    verticalAlign: 'top',
  },
  emptyCell: {
    backgroundColor: COLORS.blue12,
  },
  emptyCellGray: {
    backgroundColor: `${COLORS.gray29} !important`,
  },
  commentField: {
    minWidth: 240,
    backgroundColor: COLORS.blue12,
    borderBottom: '1px solid #B3B3BB',
  },
  headerTopTitle: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: 700,
    color: COLORS.black6,
    minHeight: 44,
  },
  icon: {
    cursor: 'pointer',
  },
}));

export default useStyles;

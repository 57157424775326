import React, { FC } from 'react';
import useStyles from './useStyles';
import { Bar, BarChart, CartesianGrid, LabelList, ResponsiveContainer, Tooltip } from 'recharts';
import Segment from './Segment';
import { battleCardColors } from 'src/constants/BATTLE_CARD_COLORS';
import { RevenueDevelopmentType, SegmentRevenueType } from '../../../../../../../types';
import { formatNumberWithCommas } from '../../../../../../../helpers/formatNumberWithCommas';

type RevenueDevelopmentProps = {
  revenueDevelopment?: RevenueDevelopmentType[];
};
const RevenueDevelopment: FC<RevenueDevelopmentProps> = ({ revenueDevelopment }) => {
  const classes = useStyles();

  let segmentTemplate: SegmentRevenueType[] = [];

  const modifiedChartData = revenueDevelopment?.map((item, index) => {
    if (item.segmentsRevenue.length > segmentTemplate.length) {
      segmentTemplate = item.segmentsRevenue;
    }
    const keys: Record<string, number> = {};

    const segments = item.segmentsRevenue.map((el) => {
      keys[el.businessUnit] = el.revenue;
      return el;
    });
    return {
      ...item,
      segmentsRevenue: segments,
      ...keys,
      color: battleCardColors[index],
    };
  });

  const renderCustomBarLabel = (props: any) => {
    const { x, y, value, width } = props;
    return (
      <text
        fontWeight={700}
        x={x + width / 2}
        y={y - 5}
        fontSize="12px"
        textAnchor="middle"
        fill={'#000'}
        dy={-6}>
        {formatNumberWithCommas(value)}
      </text>
    );
  };

  const segmentTemplateWithColors = segmentTemplate.map((el, i) => {
    return { ...el, color: battleCardColors[i] };
  });

  const customLabel = (entry: any) => {
    const { value, y, x } = entry;
    return (
      <g>
        <foreignObject textAnchor="middle" x={x} y={y} width={100} height={100} color="#fff">
          <div className={classes.labelValue} style={{ height: entry.height }}>
            {formatNumberWithCommas(value)}
          </div>
        </foreignObject>
      </g>
    );
  };

  const customLabelBlackColor = (entry: any) => {
    const { value, y, x } = entry;
    return (
      <g>
        <foreignObject textAnchor="middle" x={x} y={y} width={100} height={100} color="#000">
          <div className={classes.labelValue} style={{ height: entry.height }}>
            {formatNumberWithCommas(value)}
          </div>
        </foreignObject>
      </g>
    );
  };

  const dataSeries = () => {
    const series = [] as any;
    segmentTemplateWithColors.forEach((item, index) => {
      if (index === segmentTemplateWithColors.length - 1) {
        series.push(
          <Bar
            isAnimationActive={false}
            label={renderCustomBarLabel}
            dataKey={item.businessUnit}
            key={item.businessUnit}
            stackId="a"
            barSize={60}
            fill={item.color}
            fillOpacity={1}
            radius={[4, 4, 0, 0]}>
            {item.percentage > 10 && (
              <LabelList
                dataKey={item.businessUnit}
                content={index === 0 || index === 1 ? customLabel : customLabelBlackColor}
              />
            )}
          </Bar>,
        );
      } else {
        series.push(
          <Bar
            isAnimationActive={false}
            dataKey={item.businessUnit}
            key={item.businessUnit}
            stackId="a"
            barSize={60}
            fill={item.color}
            fillOpacity={1}>
            {item.percentage > 10 && (
              <LabelList
                dataKey={item.businessUnit}
                content={index === 0 || index === 1 ? customLabel : customLabelBlackColor}
              />
            )}
          </Bar>,
        );
      }
    });
    return series;
  };

  const CustomTooltip = (props: any) => {
    const { active, payload } = props;

    if (active && payload && payload.length) {
      return (
        <div className={classes.customTooltip}>
          {payload?.reverse().map((el: any) => {
            return (
              <div key={el.name} className={classes.customTooltipItem}>
                <div className={classes.tooltipText}>{`${el.name}:`}</div>
                <div>{formatNumberWithCommas(el.value)}</div>
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  };

  const getLabel = (year: number, array: RevenueDevelopmentType[]) => {
    if (array.length === year) {
      return 'y';
    }
    return `y${year - array.length}`;
  };

  return (
    <div className={classes.container}>
      <div className={classes.title}>Revenue development</div>
      <div className={classes.main}>
        <Segment revenueDevelopment={revenueDevelopment} />
        <div className={classes.chart}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={170}
              data={modifiedChartData}
              margin={{
                top: 20,
                right: 0,
                left: 0,
                bottom: 0,
              }}>
              <Tooltip content={<CustomTooltip />} />
              <CartesianGrid vertical={false} horizontal={false} />
              {dataSeries()}
            </BarChart>
          </ResponsiveContainer>
          <div className={classes.xAxisLine} />
          <div className={classes.yAxisLine} />
          <div className={classes.chartLabels}>
            {revenueDevelopment?.map((item, _, array) => {
              return (
                <div className={classes.chartLabel} key={item.year}>
                  Year {getLabel(item.year, array)}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default RevenueDevelopment;

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  breakdownChart: {
    marginTop: 100,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 50,
  },
  chartContainer: {
    height: 450,
    width: 550,
    position: 'relative',
    marginRight: '10%',
  },
  textCustomers: {
    position: 'absolute',
    bottom: -45,
    left: 45,
    fontSize: 16,
    fontWeight: 700,
    maxWidth: 90,
    textAlign: 'center',
  },
  textRevenues: {
    position: 'absolute',
    bottom: -45,
    right: 77,
    fontSize: 16,
    fontWeight: 700,
    maxWidth: 90,
    textAlign: 'center',
  },
  // line: {
  //   position: 'absolute',
  //   bottom: -4,
  //   width: '115%',
  //   height: 4,
  //   left: 0,
  //   backgroundColor: '#E3E4E4',
  // },

  customTooltip: {
    backgroundColor: '#404055',
    borderRadius: 8,
    color: '#fff',
    padding: '12px 16px 16px 16px',
    minWidth: 240,
  },
  customTooltipName: {
    fontSize: 16,
    lineHeight: '19px',
    fontWeight: 700,
    marginBottom: 16,
  },
  customTooltipItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tooltipText: {
    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
  chartLegend: {
    marginTop: 150,
    display: 'flex',
    justifyContent: 'space-between',
    alignSelf: 'flex-end',
  },
  chartLegendItem: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 24,
  },
  chartLegendItemSquare: {
    marginTop: 2,
    marginRight: 8,
    width: 16,
    height: 16,
  },
}));

export default useStyles;

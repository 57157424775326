import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { COLORS } from 'src/constants/COLORS';
import { DEFAULT_COUNT_OF_ITEMS } from 'src/constants/dafaultCountOfItems';

import { Stack } from '@mui/material';
import { ButtonBase } from 'src/components/common/ButtonBase';
import { Pagination } from 'src/components//common/Pagination';

import { UploadButton } from 'src/components/common/UploadButton';

import {
  downloadCustomerRegistryExcel,
  exportCustomerRegistryExcel,
  getAccountManagerNames,
  getCustomerRegistry as getCustomerRegistryAction,
  setAccountManager,
  updateCustomerRegistryTable,
  updateCustomerRevenues,
} from 'src/modules/TouchableRevenue/actions';

import { Store, UpdateCustomerRegistryTable } from 'src/types';
import { Loader } from 'src/components/common/Loader';
import { NoDataPlaceholder } from 'src/components/common/NoDataPlaceholder';
import { CustomerRegistryTable } from './components/CustomerRegistryTable';
import { ForbidEditCheckbox } from '../../../../common/ForbidEditCheckbox';
import { ScreenNames } from '../../../../../constants/ScreenNames';
import { toast } from 'react-toastify';
import { InfoModal } from './components/InfoModal';
import { Stepper } from '../../../../common/Stepper';
import { StepNames } from '../../../../../constants/stepNames';
import { Dropdown } from '../../../../common/Dropdown';

export const CustomerRegistry: React.FC = () => {
  const { customerRegistry, uploadLoading, loading, accountManagerNames } = useSelector(
    (state: Store) => state.touchableRevenue,
  );

  const [infoModalVisible, setInfoModalVisible] = useState(false);

  const { disableEditPage } = useSelector((state: Store) => state.home);

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_COUNT_OF_ITEMS);

  const [yearsState, setYearsState] = useState<string>('1 year');

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleChangeRowsPerPage = (value: number) => {
    setRowsPerPage(value);
    setPage(0);
  };

  const handleInfoModalClose = useCallback(() => {
    setInfoModalVisible(false);
  }, []);

  const handleInfoModalOpen = useCallback(() => {
    setInfoModalVisible(true);
  }, []);

  const getCustomerRegistry = useCallback(
    ({
      sortBy,
      pageNumber,
      itemsOnPage,
    }: {
      sortBy: string;
      itemsOnPage: number;
      pageNumber: number;
    }) => {
      dispatch(
        getCustomerRegistryAction.request({
          pageNumber,
          itemsOnPage,
          sortBy,
          sortOrder: 'asc',
        }),
      );
    },
    [dispatch],
  );

  const onDownloadExcel = useCallback(
    (val: string) => {
      const newVal = val.split(' ')[0];
      dispatch(downloadCustomerRegistryExcel.request(newVal));
    },
    [dispatch],
  );

  const handleChangeYears = (value: string) => {
    setYearsState(value);
  };

  const onUpdateRevenue = useCallback(
    (params) => {
      dispatch(updateCustomerRevenues.request(params));
    },
    [dispatch],
  );

  const onUploadExcel = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const fileList = e.target.files;

      if (fileList) {
        dispatch(
          exportCustomerRegistryExcel.request({
            requestData: {
              file: fileList[0],
              itemsOnPage: rowsPerPage,
            },
          }),
        );

        handleInfoModalOpen();

        e.target.value = '';
      }
    },
    [dispatch, handleInfoModalOpen, rowsPerPage],
  );

  const goNext = () => {
    const isValid = validateSelectedAccountManager();

    if (isValid) {
      navigate('/configure-levels/touchable-revenue/survey-setting');
    }
  };

  const goBack = () => {
    navigate('/configure-levels/touchable-revenue/account-manager-list');
  };

  const onUpdateCell = useCallback(
    (values: UpdateCustomerRegistryTable) => {
      dispatch(
        updateCustomerRegistryTable.request({
          payload: values,
          pageableData: {
            sortBy: 'name',
            pageNumber: page === 0 ? page : page - 1,
            itemsOnPage: rowsPerPage,
            sortOrder: 'asc',
          },
        }),
      );
    },
    [dispatch, page, rowsPerPage],
  );

  const validateSelectedAccountManager = () => {
    if (customerRegistry) {
      const hasNull = customerRegistry.data.some((item) => item.accountManager === null);

      if (hasNull) {
        toast.error('Please select account manager.');

        return false;
      }

      return true;
    }

    return false;
  };

  const onSelectAccountManager = useCallback(
    (id: string, name: string) => {
      dispatch(
        setAccountManager.request({
          body: {
            id,
            accountManager: name,
          },
          params: {
            sortBy: 'name',
            pageNumber: page === 0 ? page : page - 1,
            itemsOnPage: rowsPerPage,
            sortOrder: 'asc',
          },
        }),
      );
    },
    [dispatch, page, rowsPerPage],
  );

  const goToPage = useCallback((page: number) => {
    setPage(page);
  }, []);

  useEffect(() => {
    getCustomerRegistry({
      sortBy: 'name',
      pageNumber: page === 0 ? page : page - 1,
      itemsOnPage: rowsPerPage,
    });
  }, [getCustomerRegistry, page, rowsPerPage]);

  useEffect(() => {
    dispatch(getAccountManagerNames.request());
  }, [dispatch]);

  return (
    <div style={{ position: 'relative' }}>
      <Stack direction="row" columnGap={3} marginTop={4}>
        <ButtonBase
          onClick={() => onDownloadExcel(yearsState)}
          title="Download Excel template"
          variant="outlined"
        />
        <Dropdown
          width={200}
          dropdownArrowColor={COLORS.black1}
          dropdownItems={['1 year', '2 years', '3 years']}
          defaultItem="1 year"
          onSelectCb={(value) => handleChangeYears(value)}
        />
        <UploadButton
          disabled={loading || disableEditPage}
          variant="outlined"
          onChange={onUploadExcel}
          title="Upload from Excel"
        />
      </Stack>
      <Stepper steps={{ name: 'Setup', activeIndex: 2 }} type={StepNames.SETUP} />

      {(loading || uploadLoading) && (
        <div
          style={{
            position: 'absolute',
            zIndex: 2,
            display: 'flex',
            height: '40vh',
            width: '100%',
            justifyContent: 'center',
          }}>
          <Loader isLoading={true} marginTop={14} />
        </div>
      )}

      {!customerRegistry || (customerRegistry && customerRegistry.data.length === 0) ? (
        <NoDataPlaceholder />
      ) : null}

      {customerRegistry && customerRegistry.data.length > 0 && !uploadLoading && (
        <>
          <CustomerRegistryTable
            onUpdateRevenue={onUpdateRevenue}
            onUpdateCell={onUpdateCell}
            businessUnits={customerRegistry.businessUnits}
            onSelectAccountManager={onSelectAccountManager}
            data={customerRegistry.data}
            accountManagerNames={accountManagerNames}
            notForEditing={disableEditPage}
          />

          <Pagination
            goToPage={goToPage}
            totalPages={customerRegistry.pageable.totalPages}
            setRowPerPage={handleChangeRowsPerPage}
            setPage={handleChangePage}
            page={page}
            rowPerPage={rowsPerPage}
            marginTop={4}
          />
        </>
      )}

      <Stack direction="row" marginTop={uploadLoading ? 70 : 8} justifyContent="space-between">
        <ForbidEditCheckbox
          showCheckbox={customerRegistry ? customerRegistry.data.length > 0 : false}
          checkForEmptyFields={validateSelectedAccountManager}
          checkboxValue={disableEditPage}
          screenName={ScreenNames.SETTINGS_CUSTOMER_REGISTRY}
        />
        <Stack width="100%" direction="row" spacing={4} justifyContent="flex-end">
          <ButtonBase
            iconColor={COLORS.red1}
            variant="outlined"
            title="Account manager list"
            onClick={goBack}
            icon="goBack"
            iconLeft={true}
          />
          <ButtonBase
            iconColor={COLORS.red1}
            variant="contained"
            title="Survey setting"
            onClick={goNext}
            icon="next"
          />
        </Stack>
      </Stack>

      <InfoModal open={infoModalVisible} onClose={handleInfoModalClose} />
    </div>
  );
};

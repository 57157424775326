import React, { memo } from 'react';

import useStyles from './useStyles';
import { Loader } from '../Loader';

type Props = {
  title: string;
  loading: boolean;
};

export const SurveySettingBox: React.FC<Props> = memo(function SurveySettingBox({
  children,
  title,
  loading,
}) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.header}>{title}</div>
      <div className={classes.contentContainer}>
        {loading ? (
          <div className={classes.loaderContainer}>
            <Loader isLoading={loading} />
          </div>
        ) : (
          children
        )}
      </div>
    </div>
  );
});

import React, { useCallback, useContext, FC, useState } from 'react';
import useStyles from '../../useStyles';
import { OtherOptionsDropdownItem } from './OtherOptionsDropdownItem';
import { Stack } from '@mui/material';
import { TextButton } from '../../../../../../common/TextButton';
import { SurveysProvider } from '../../SurveysProvider';
import {
  createSurveySettingsOtherOption,
  setSelectedOther,
  updateSurveySettingsOtherOption,
} from '../../../../../../../modules/surveys/actions';
import { useDispatch } from 'react-redux';
import { OtherOptionsDropdownModal } from './OtherOptionsDropdownModal';
import { OtherDropdownAndFreeText } from '../../../../../../../types';

type OtherOptionsDropdownProps = {
  dropdownOptions: OtherDropdownAndFreeText[];
};

const OtherOptionsDropdown: FC<OtherOptionsDropdownProps> = ({ dropdownOptions }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { disabled } = useContext(SurveysProvider);

  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => setModalOpen(false);

  const onAddNewContractCommitmentOption = useCallback(() => {
    dispatch(
      createSurveySettingsOtherOption.request({
        option: '',
        optionType: 'LIST',
      }),
    );
  }, [dispatch]);

  const handleChange = (value: string, id: number) => {
    dispatch(
      updateSurveySettingsOtherOption.request({
        option: value,
        optionId: id,
      }),
    );
  };

  const onSelectOther = useCallback(
    (data) => {
      dispatch(setSelectedOther(data));
    },
    [dispatch],
  );

  return (
    <>
      <div className={classes.innerContainer}>
        <div
          className={classes.inputsList}
          style={{ marginBottom: dropdownOptions?.length > 0 ? 20 : 0 }}>
          {dropdownOptions?.map((option: any) => {
            return (
              <OtherOptionsDropdownItem
                id={option.id}
                onChangeValueCb={handleChange}
                error={false}
                handleModalOpen={handleOpenModal}
                onSelectOther={onSelectOther}
                key={option.id}
                {...option}
              />
            );
          })}
        </div>
        {!disabled && (
          <Stack direction="row" justifyContent="center">
            <TextButton onClick={onAddNewContractCommitmentOption} title="Add option" />
          </Stack>
        )}
      </div>

      <OtherOptionsDropdownModal open={modalOpen} onCloseModal={handleCloseModal} />
    </>
  );
};

export default OtherOptionsDropdown;

import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../constants/COLORS';

const useStyles = makeStyles(() => ({
  button: {
    display: 'block',
    width: '100%',
    height: '65%',
    textAlign: 'left',
    backgroundColor: COLORS.white1,
    border: 'none',
    fontSize: 14,
    color: COLORS.black1,
    margin: 0,
    padding: 0,
    cursor: 'pointer',
    '&:disabled': {
      cursor: 'no-drop',
    },
  },
  input: {
    borderColor: COLORS.gray3,
    fontSize: 14,
  },
  disabled: {
    backgroundColor: COLORS.gray4,
  },
  placeholder: {
    color: COLORS.gray25,
    fontSize: 12,
  },
}));

export default useStyles;

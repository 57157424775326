import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    borderRadius: 10,
    paddingTop: 64,
    overflow: 'hidden',
    boxShadow: '2px 4px 10px rgba(199, 201, 201, 0.5)',
    '& th': {
      height: 48,
      backgroundColor: COLORS.blue12,
      color: COLORS.black4,
      textAlign: 'left',
      paddingLeft: 16,
      paddingRight: 16,
      fontSize: 12,
      lineHeight: '14px',
    },
    '& td': {
      height: 70,
      paddingLeft: 16,
      fontSize: 16,
      width: '33%',
      '&:nth-child(1)': {
        width: '48%',
      },
      '&:nth-child(2)': {
        width: '48%',
      },
      '&:nth-child(3)': {
        paddingLeft: 5,
        width: '4%',
      },
      color: COLORS.black4,
    },
    '& tr:nth-child(even)': {
      backgroundColor: COLORS.blue13,
      '& button': {
        backgroundColor: COLORS.blue13,
      },
    },
  },
  wrapper: {
    marginTop: '20px',
    overflowY: 'auto',
    boxShadow: '2px 4px 10px rgba(199, 201, 201, 0.5)',
  },
  buttonWrapper: {
    margin: '10px 0 20px 10px',
  },
}));

export default useStyles;

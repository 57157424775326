import React, { useMemo } from 'react';
import { TargetSetting, UpdateTargetForTargetSettings } from 'src/types';
import useStyles from './useStyles';
import { numberSeparator } from '../../../../../../../helpers/numberSeparator';
import { EditableCell } from '../../../../../../common/EditableCell';

type Props = {
  settings: TargetSetting[];
  disableEdit: boolean;
  updateTarget: (body: UpdateTargetForTargetSettings) => void;
};

export const TargetSettingsTable: React.FC<Props> = ({ settings, updateTarget, disableEdit }) => {
  const classes = useStyles();

  const Headers = useMemo(
    () =>
      settings.map((item) => <th key={item.businessUnitId}>{item.businessUnitAbbreviation}</th>),
    [settings],
  );

  const Revenues = useMemo(
    () =>
      settings.map((item) => <td key={item.businessUnitId}>{numberSeparator(item.revenue)}</td>),
    [settings],
  );

  const TouchableRevenues = useMemo(
    () =>
      settings.map((item) => (
        <td key={item.businessUnitId}>{numberSeparator(item.touchableRevenue)}</td>
      )),
    [settings],
  );

  const TargetsOnTouchableRevenue = useMemo(
    () =>
      settings.map((item) =>
        item.businessUnitAbbreviation === 'Overall' ? (
          <td key={item.businessUnitId}>
            <div>{item.campaignTarget ? item.campaignTarget + '%' : '0%'}</div>
          </td>
        ) : (
          <td key={item.businessUnitId}>
            <div className={classes.numberInputContainer}>
              <EditableCell
                isInput
                buttonStyle={classes.numberInput}
                innerInputStyle={classes.numberInput}
                disabled={disableEdit}
                onEditCb={(value) => {
                  if (value) {
                    updateTarget({
                      businessUnitId: item.businessUnitId,
                      campaignTarget: +value,
                    });
                  }
                }}
                onlyNumbers
                allowNegative={true}
                value={item.campaignTarget ? item.campaignTarget.toString() : '0'}
                suffix="%"
              />
            </div>
          </td>
        ),
      ),
    [classes.numberInput, classes.numberInputContainer, disableEdit, settings, updateTarget],
  );

  const EffectiveIncreases = useMemo(
    () => settings.map((item) => <td key={item.businessUnitId}>{item.effectiveIncrease}%</td>),
    [settings],
  );

  return (
    <table className={classes.table}>
      <thead>
        <tr>
          <th></th>
          {Headers}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Revenue</td>
          {Revenues}
        </tr>
        <tr>
          <td>Touchable revenue</td>
          {TouchableRevenues}
        </tr>
        <tr>
          <td>Campaign target [on touchable revenue]</td>
          {TargetsOnTouchableRevenue}
        </tr>
        <tr>
          <td>Effective increase on total revenue</td>
          {EffectiveIncreases}
        </tr>
      </tbody>
    </table>
  );
};

import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    width: '60%',
    height: 570,
    paddingTop: 90,
    borderRadius: 8,
    boxShadow: '0px 2px 10px rgba(199, 201, 201, 0.5)',
  },
  y1LabelContainer: {
    position: 'absolute',
    top: 40,
    left: 40,
  },
  y2LabelContainer: {
    position: 'absolute',
    top: 40,
    right: 40,
  },
  labelText: {
    fontSize: 16,
    color: COLORS.black1,
  },
  subLabelText: {
    fontSize: 14,
    color: COLORS.black1,
  },
}));

export default useStyles;

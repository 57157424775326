import React from 'react';
import { Outlet } from 'react-router-dom';
import { Grid } from '@mui/material';

export const HomeLayout: React.FC = () => {
  return (
    <Grid>
      <Outlet />
    </Grid>
  );
};

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  icon: {
    transition: '0.3s ease',
    cursor: 'pointer',
  },
  iconDisabled: {
    cursor: 'auto',
  },
}));

export default useStyles;

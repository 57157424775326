import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    columnGap: 49,
  },
  categoryContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  indicatorsContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
  },
  indicatorItemContainer: {
    position: 'relative',
  },
  onAddIndicatorButton: {
    margin: '0 auto',
    marginBottom: 16,
  },
  onAddNewCategoryButton: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '24px',
  },
  deleteCategoryButton: {
    position: 'absolute',
    top: '12px',
    right: '-20px',
  },
  deleteIndicatorButton: {
    position: 'absolute',
    top: '12px',
  },
  input: {
    width: 267,
  },
}));

export default useStyles;

import React from 'react';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import { IconButton, Stack } from '@mui/material';
import { ReactComponent as CloseIcon } from 'src/assets/icons/Close.svg';
import { ButtonBase } from 'src/components/common/ButtonBase';
import useStyles from './useStyles';

type Props = {
  open: boolean;
  handleClose: () => void;
  onDelete: () => void;
  loading: boolean;
  selectedProject: { name: string; projectId: number } | null;
};

export const DeleteProjectModal: React.FC<Props> = ({
  open,
  handleClose,
  onDelete,
  loading,
  selectedProject,
}) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={open}>
        <div className={classes.modal}>
          <div className={classes.header}>
            <span>Delete {selectedProject && selectedProject.name}?</span>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <Stack
            padding="0 18px"
            direction="row"
            columnGap={5}
            marginTop={12}
            justifyContent="space-between">
            <ButtonBase
              inlineStyle={{ width: 120 }}
              onClick={onDelete}
              isLoading={loading}
              title="Ok"
              variant="contained"
            />
            <ButtonBase
              inlineStyle={{ width: 120 }}
              onClick={handleClose}
              title="Cancel"
              variant="contained"
            />
          </Stack>
        </div>
      </Fade>
    </Modal>
  );
};

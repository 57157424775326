import React, { memo, useCallback, useContext, useState } from 'react';
import { EaseOfIncreaseDrivers as EaseOfIncreaseDriversType } from 'src/types';
import { useDispatch } from 'react-redux';

import useStyles from '../../useStyles';

import { Stack } from '@mui/material';
import { EaseOfIncreaseDriverItem } from '../EaseOfIncreaseDriverItem';

import {
  setSelectedEaseOfIncreaseDriver,
  surveySettingsCreateNewEOI,
  surveySettingsUpdateEOIOption,
} from 'src/modules/surveys/actions';
import { SurveySettingsModal } from '../SurveySettingsModal';
import { ErrorOption } from '../../index';
import { SurveysProvider } from '../../SurveysProvider';
import { TextButton } from '../../../../../../common/TextButton';

type Props = {
  easeOfIncreaseDrivers?: EaseOfIncreaseDriversType[];
  idsWithError?: ErrorOption;
};

export const EaseOfIncreaseDrivers: React.FC<Props> = memo(function EaseOfIncreaseDrivers({
  easeOfIncreaseDrivers,
  idsWithError,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { disabled } = useContext(SurveysProvider);

  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = () => {
    setModalOpen(true);
  };
  const handleClose = () => setModalOpen(false);

  const addNewEaseOfIncreaseDriver = useCallback(() => {
    dispatch(
      surveySettingsCreateNewEOI.request({
        option: '',
      }),
    );
  }, [dispatch]);

  const updateEOIOption = useCallback(
    (option, optionId) => {
      dispatch(
        surveySettingsUpdateEOIOption.request({
          option,
          optionId,
        }),
      );
    },
    [dispatch],
  );

  const onSelectEOIDrivers = useCallback(
    (EOIDrivers) => {
      dispatch(setSelectedEaseOfIncreaseDriver(EOIDrivers));
    },
    [dispatch],
  );

  return (
    <>
      <div className={classes.innerContainer}>
        {easeOfIncreaseDrivers && easeOfIncreaseDrivers.length > 0 && (
          <div className={classes.inputsList}>
            {easeOfIncreaseDrivers.map((item) => (
              <EaseOfIncreaseDriverItem
                error={idsWithError ? idsWithError.ids.includes(item.id) : false}
                onChangeEaseOfIncreaseDriverOption={updateEOIOption}
                handleModalOpen={handleOpen}
                onSelectEOIDrivers={onSelectEOIDrivers}
                key={item.id}
                {...item}
              />
            ))}
          </div>
        )}

        {!disabled && (
          <Stack direction="row" marginTop={4} justifyContent="center">
            <TextButton onClick={addNewEaseOfIncreaseDriver} title="Add driver" />
          </Stack>
        )}
      </div>
      <SurveySettingsModal open={modalOpen} handleClose={handleClose} />
    </>
  );
});

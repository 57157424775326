export const checkIsNumber = (str: string) => {
  const re = /^-?(0|[1-9]\d*)?(\.\d+)?(?<=\d)$/;

  if (str === '' || str === '-') {
    return true;
  }

  if (str.length > 0 && str.slice(-1) === '.' && str.slice(-2) !== '..') {
    return true;
  }

  return str.match(re);
};

import { Action, combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Store } from 'src/types';
import { getType } from 'deox';

import { homeReducer } from 'src/modules/home/reducer';
import { touchableRevenueReducer } from 'src/modules/TouchableRevenue/reducer';
import { campaignTargetReducer } from 'src/modules/campaignTarget/reducer';
import { surveysReducer } from 'src/modules/surveys/reducer';
import { specificTargetsReducer } from 'src/modules/specificTargets/reducer';
import { projectsReducer } from 'src/modules/projects/reducer';
import { appReducer } from 'src/modules/app/reducer';
import { resetStore } from 'src/modules/app/actions';
import { battleCardsReducer } from 'src/modules/battleCards/reducer';
import { monitoringReducer } from '../modules/monitoring/reducer';

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['projects', 'app', 'surveys'],
};

const allReducers = combineReducers<Store>({
  home: homeReducer,
  touchableRevenue: touchableRevenueReducer,
  campaignTarget: campaignTargetReducer,
  surveys: surveysReducer,
  specificTargets: specificTargetsReducer,
  projects: projectsReducer,
  app: appReducer,
  battleCards: battleCardsReducer,
  monitoring: monitoringReducer,
});

const rootReducer = (state: Store | undefined, action: Action): ReturnType<typeof allReducers> => {
  if (action.type === getType(resetStore)) {
    state = undefined;
  }

  return allReducers(state, action);
};

export default persistReducer(rootPersistConfig, rootReducer);

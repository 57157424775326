import React from 'react';
import { SettingOverviewResponse } from 'src/types';
import dayjs from 'dayjs';
import useStyles from './useStyles';
import { useNavigate } from 'react-router-dom';

type Props = SettingOverviewResponse;

export const SettingsOverviewTable: React.FC<Props> = ({
  accountManagerList,
  businessUnit,
  customerRegistry,
  surveySettings,
}) => {
  const classes = useStyles();

  const navigate = useNavigate();

  const onNavigateToSegment = () => {
    navigate('/configure-levels/touchable-revenue/business-units');
  };

  const onNavigateToAccountManagers = () => {
    navigate('/configure-levels/touchable-revenue/account-manager-list');
  };

  const onNavigateToCustomerRegistry = () => {
    navigate('/configure-levels/touchable-revenue/customer-registry');
  };

  const onNavigateToSurveySetting = () => {
    navigate('/configure-levels/touchable-revenue/survey-setting');
  };

  return (
    <table className={classes.table}>
      <thead>
        <tr>
          <th />
          <th>last edit Date</th>
          <th>Last Edit By</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <div className={classes.button} onClick={onNavigateToSegment}>
              Segment
            </div>
          </td>
          <td>
            {businessUnit.lastEditDate
              ? dayjs(businessUnit.lastEditDate).format('YYYY/MM/DD')
              : '-'}
          </td>
          <td>{businessUnit.lastEditBy ? businessUnit.lastEditBy : '-'}</td>
          <td>{businessUnit.status}</td>
        </tr>
        <tr>
          <td>
            <div onClick={onNavigateToAccountManagers} className={classes.button}>
              Account Manager List
            </div>
          </td>
          <td>
            {accountManagerList.lastEditDate
              ? dayjs(accountManagerList.lastEditDate).format('YYYY/MM/DD')
              : '-'}
          </td>
          <td>{accountManagerList.lastEditBy ? accountManagerList.lastEditBy : '-'}</td>
          <td>{accountManagerList.status}</td>
        </tr>
        <tr>
          <td>
            <div onClick={onNavigateToCustomerRegistry} className={classes.button}>
              Customer Registry
            </div>
          </td>
          <td>
            {customerRegistry.lastEditDate
              ? dayjs(customerRegistry.lastEditDate).format('YYYY/MM/DD')
              : '-'}
          </td>
          <td>{customerRegistry.lastEditBy ? customerRegistry.lastEditBy : '-'}</td>
          <td>{customerRegistry.status}</td>
        </tr>
        <tr>
          <td>
            <div onClick={onNavigateToSurveySetting} className={classes.button}>
              Survey Setting
            </div>
          </td>
          <td>
            {surveySettings.lastEditDate
              ? dayjs(surveySettings.lastEditDate).format('YYYY/MM/DD')
              : '-'}
          </td>
          <td>{surveySettings.lastEditBy ? surveySettings.lastEditBy : '-'}</td>
          <td>{surveySettings.status}</td>
        </tr>
      </tbody>
    </table>
  );
};

import { createAction, Action } from 'deox';

export type ResponseError = { message: string; name?: string; id?: string };

type AbstractFailActionCreatorType = (payload: ResponseError) => Action<string, ResponseError>;

export const processRequestError = createAction(
  'errors/PROCESS_REQUEST_ERROR',
  (resolve) =>
    (payload: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      error: any;
      failAction: AbstractFailActionCreatorType;
      fieldId?: string;
      fieldName?: string;
    }) =>
      resolve(payload),
);

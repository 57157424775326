import { COLORS } from './COLORS';

export const battleCardColors = [
  COLORS.blue16,
  COLORS.blue17,
  COLORS.blue18,
  COLORS.blue3,
  COLORS.blue19,
  COLORS.blue20,
  COLORS.pink6,
  COLORS.pink5,
  COLORS.pink4,
  COLORS.red1,
];

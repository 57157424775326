import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../../../../constants/COLORS';

const useStyles = makeStyles(() => ({
  inputBlocks: {
    marginTop: 50,
    display: 'flex',
    justifyContent: 'space-between',
  },
  block: {
    borderRadius: 8,
    flex: '1 1 100%',
    overflow: 'hidden',
    boxShadow: '2px 4px 10px rgba(199, 201, 201, 0.5)',
    '&:first-child': {
      marginRight: 20,
    },
  },
  header: {
    backgroundColor: COLORS.blue12,
    padding: '16px 0 16px 24px',
    fontSize: 12,
    lineHeight: '14px',
    fontWeight: 700,
  },
  fieldWrapper: {
    display: 'flex',
    alignItems: 'baseline',
    padding: '16px 24px',
    '&:nth-child(2)': {
      backgroundColor: COLORS.blue13,
    },
  },
  fieldNumber: {
    flex: '1 1 3%',
    marginRight: 10,
  },
  fieldInput: {
    flex: '1 1 97%',
  },
  input: {
    border: `1px solid ${COLORS.gray26}`,
    padding: '5px 0px 16px 10px',
    borderRadius: 8,
    height: '38px',
    width: '97%',
    outline: 'none',
    fontSize: '12px',
    color: COLORS.black1,
    '&:placeholder': {
      color: COLORS.gray23,
      fontSize: 12,
    },
    '&:hover': {
      borderColor: COLORS.blue2,
    },
    display: 'block',
    verticalAlign: 'top',
  },
}));

export default useStyles;

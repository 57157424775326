import React from 'react';
import useStyles from './useStyles';

import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { TargetFineTuningDataItem, TargetFineTuningDotLabel } from 'src/types';
import { Loader } from 'src/components/common/Loader';
//eslint-disable-next-line
const CustomLabel = (props: any) => {
  const { name, dotLabels } = props;

  //eslint-disable-next-line
  const value = dotLabels.find((dot: any) => dot.xvalue === name);

  if (value) {
    return (
      <g>
        <circle cx={props.viewBox.x + 10} cy={20} r="15" fill="transparent" stroke="#00ACC0" />
        <text color="#4F4F4F" dx={6} dy={4} fontSize={14} x={props.viewBox.x} y={20} fill="#111">
          {value.dotName}
        </text>
      </g>
    );
  }

  return <g />;
};

type Props = {
  data: TargetFineTuningDataItem[];
  loading: boolean;
  dotLabels: TargetFineTuningDotLabel[];
};

export const Chart: React.FC<Props> = ({ data, loading, dotLabels }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {loading ? (
        <Loader isLoading={true} marginTop={35} />
      ) : (
        <>
          <div className={classes.y1LabelContainer}>
            <p className={classes.labelText}>Frequency of EOI score</p>
            <p className={classes.subLabelText}>(number of customers)</p>
          </div>

          <div className={classes.y2LabelContainer}>
            <p className={classes.labelText}>Price increase</p>
          </div>
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart data={data}>
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis
                padding={{ left: 25, right: 25 }}
                scale="point"
                dataKey="name"
                angle={-60}
                dx={-4}
                dy={85}
                height={160}
                minTickGap={-200}
              />
              <YAxis dataKey="numberOfCustomers" padding={{ top: 100 }} yAxisId="left" />

              <YAxis padding={{ top: 100 }} yAxisId="right" orientation="right" />
              <Tooltip />
              <Legend
                formatter={(value) => {
                  const result = value.replace(/([A-Z])/g, ' $1');
                  return result.charAt(0).toUpperCase() + result.slice(1);
                }}
                wrapperStyle={{
                  paddingTop: '25px',
                }}
              />
              <Bar
                label={<CustomLabel dotLabels={dotLabels} />}
                dataKey="numberOfCustomers"
                barSize={20}
                fill="#75D2DD"
                yAxisId="left"
              />
              <Line
                yAxisId="right"
                type="linear"
                strokeWidth={2}
                height={5}
                dataKey="goingInPrice"
                stroke="#828282"
              />
              <Line
                yAxisId="right"
                type="linear"
                strokeWidth={2}
                dataKey="targetPrice"
                stroke="#000000"
              />
              <Line
                yAxisId="right"
                type="linear"
                strokeWidth={2}
                dataKey="escalationPrice"
                stroke="#969696"
              />
            </ComposedChart>
          </ResponsiveContainer>
        </>
      )}
    </div>
  );
};

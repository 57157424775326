export enum SurveyStatuses {
  SURVEY_SENT = 'survey sent',
  SURVEY_COMPLETED = 'survey completed',
  PENDING = 'pending',
  NOT_FILLED = 'not filled',
  NOT_SENT = 'not sent',
  FILLED = 'filled',
  SENT_BUT_NOT_FILLED = 'sent but not filled',
}

export enum ProjectCreationStatus {
  CREATED = 'CREATED',
  IN_PROGRESS = 'IN_PROGRESS',
}

export enum BattleCardsStatuses {
  SENT = 'SENT',
  NOT_SENT = 'NOT_SENT',
}

import React, { memo, useCallback, useContext, useMemo, useState } from 'react';
import useStyles from './useStyles';
import { useDebouncedCallback } from 'use-debounce';

import {
  EaseOfIncreaseOption,
  OtherDropdownAndFreeText,
  SurveyResultCopyOptionsFromFirstBUToOtherRequestConfig,
  SurveyResultCustomerData,
  SurveyResultSetAdditionalFields,
  SurveyResultSetContractCommitment,
  SurveyResultSetEOIOptionRequestBody,
  SurveyResultSetOtherOptionRequestBody,
  SurveyResultSetPlanBOptions,
  SurveyResultSetTouchableOptionRequestBody,
  TouchableOption,
} from 'src/types';

import { GeneralInformationColumns } from '../GeneralInformationColumns';
import { EOIColumns } from '../EOIColumns';
import { ContractCommitmentsColumns } from '../ContractCommitmentsColumns';
import { PlanBColumns } from '../PlanBColumns';
import { InputBase } from 'src/components/common/InputBase';

import { SurveyResultProvider, SurveyResultProviderTypes } from '../../SurveyResultProvider';
import { COLORS } from '../../../../../../../constants/COLORS';
import { ButtonBase } from '../../../../../../common/ButtonBase';
import { Stack } from '@mui/material';
import { OtherColumns } from '../OtherColumns';

type Props = SurveyResultCustomerData & {
  touchableOptions: TouchableOption[];
  eoiOptions: EaseOfIncreaseOption[];
  setTouchableOption: (body: SurveyResultSetTouchableOptionRequestBody) => void;
  setEOIOption: (body: SurveyResultSetEOIOptionRequestBody) => void;
  setOtherOption: (body: SurveyResultSetOtherOptionRequestBody) => void;
  setContractCommitments: (body: SurveyResultSetContractCommitment) => void;
  setAdditionalFields: (body: SurveyResultSetAdditionalFields) => void;
  setPlanBOption: (body: SurveyResultSetPlanBOptions) => void;
  buVisible: {
    general: boolean;
    eoi: boolean;
    contract: boolean;
    planB: boolean;
    other: boolean;
  };
  index: number;
  isBusinessUnitsVisible: boolean;
  copyBuValueGeneralInfo?: (config: SurveyResultCopyOptionsFromFirstBUToOtherRequestConfig) => void;
  otherOptions: OtherDropdownAndFreeText[];
};

export const TableBodyRow: React.FC<Props> = memo(function TableBodyRow({
  customerId,
  customerName,
  parentAccountName,
  parentAccountId,
  comment,
  contractExpirationDate,
  chosenEaseOfIncreaseOptions,
  chosenPlanBOptions,
  chosenContractCommitmentOptions,
  chosenOtherOptions,
  eoiOptions,
  touchableName,
  touchableOptions,
  lastPriceIncrease,
  setTouchableOption,
  setEOIOption,
  setOtherOption,
  setContractCommitments,
  setAdditionalFields,
  setPlanBOption,
  buVisible,
  businessUnitsData,
  index,
  copyBuValueGeneralInfo,
  isBusinessUnitsVisible,
  isGroup,
  otherOptions,
}) {
  const classes = useStyles();
  const [showSyncWarning, setShowSyncWarning] = useState(false);

  const isEven = useMemo(() => {
    return index % 2 === 0;
  }, [index]);

  const { disableEditPage } = useContext(SurveyResultProvider) as SurveyResultProviderTypes;

  const onChangeComment = useDebouncedCallback((comment) => {
    setAdditionalFields({
      customerData: {
        customerId,
        comment,
        isGroup,
      },
    });
  }, 400);

  const handleShowWarning = useCallback((value = true) => {
    setShowSyncWarning(value);
  }, []);

  const copyBuValues = useCallback(() => {
    if (copyBuValueGeneralInfo) {
      copyBuValueGeneralInfo({
        body: {
          isPlanBOption: buVisible.planB,
          isGeneralInfo: buVisible.general,
          isBreachOfContractTerms: buVisible.contract,
          isEaseOfIncrease: buVisible.eoi,
          customerId: customerId,
          isGroup: isGroup,
          isOtherOption: buVisible.other,
          // businessUnitId:
        },
      });

      setShowSyncWarning(false);
    }
  }, [
    buVisible.contract,
    buVisible.eoi,
    buVisible.general,
    buVisible.planB,
    buVisible.other,
    copyBuValueGeneralInfo,
    customerId,
    isGroup,
  ]);

  const EoiEnhancedOptions = useMemo(() => {
    if (buVisible.eoi) {
      return businessUnitsData.map((bu) => (
        <EOIColumns
          isGroup={isGroup}
          isEven={isEven}
          key={bu.businessUnitId}
          chosenEaseOfIncreaseOptions={bu.chosenEaseOfIncreaseOptions}
          eoiOptions={eoiOptions}
          setEOIOption={setEOIOption}
          customerId={customerId}
          businessUnitId={bu.businessUnitId}
          isDisabled={bu.isDisabled}
        />
      ));
    } else {
      return (
        <EOIColumns
          isGroup={isGroup}
          isEven={isEven}
          chosenEaseOfIncreaseOptions={chosenEaseOfIncreaseOptions}
          eoiOptions={eoiOptions}
          setEOIOption={setEOIOption}
          customerId={customerId}
        />
      );
    }
  }, [
    buVisible.eoi,
    businessUnitsData,
    chosenEaseOfIncreaseOptions,
    customerId,
    eoiOptions,
    isEven,
    isGroup,
    setEOIOption,
  ]);

  const EnhancedContractCommitments = useMemo(() => {
    if (buVisible.contract) {
      return businessUnitsData.map((bu) => (
        <ContractCommitmentsColumns
          isGroup={isGroup}
          isEven={isEven}
          key={bu.businessUnitId}
          businessUnitId={bu.businessUnitId}
          chosenContractCommitmentOptions={bu.chosenContractCommitmentOptions}
          setContractCommitments={setContractCommitments}
          customerId={customerId}
          isDisabled={bu.isDisabled}
        />
      ));
    } else {
      return (
        <ContractCommitmentsColumns
          isGroup={isGroup}
          isEven={isEven}
          chosenContractCommitmentOptions={chosenContractCommitmentOptions}
          setContractCommitments={setContractCommitments}
          customerId={customerId}
        />
      );
    }
  }, [
    buVisible.contract,
    businessUnitsData,
    chosenContractCommitmentOptions,
    customerId,
    isEven,
    isGroup,
    setContractCommitments,
  ]);

  const EnhancedGeneralInformation = useMemo(() => {
    if (buVisible.general) {
      return businessUnitsData.map((bu) => (
        <GeneralInformationColumns
          isGroup={isGroup}
          isEven={isEven}
          key={bu.businessUnitId}
          businessUnitId={bu.businessUnitId}
          customerId={customerId}
          contractExpirationDate={bu.contractExpirationDate}
          touchableOptions={touchableOptions}
          touchableName={bu.touchableName}
          lastPriceIncrease={bu.lastPriceIncrease}
          setTouchableOption={setTouchableOption}
          setAdditionalFields={setAdditionalFields}
          isDisabled={bu.isDisabled}
        />
      ));
    }

    return (
      <GeneralInformationColumns
        isGroup={isGroup}
        isEven={isEven}
        customerId={customerId}
        contractExpirationDate={contractExpirationDate}
        touchableOptions={touchableOptions}
        touchableName={touchableName}
        lastPriceIncrease={lastPriceIncrease}
        setTouchableOption={setTouchableOption}
        setAdditionalFields={setAdditionalFields}
      />
    );
  }, [
    buVisible.general,
    businessUnitsData,
    contractExpirationDate,
    customerId,
    isEven,
    isGroup,
    lastPriceIncrease,
    setAdditionalFields,
    setTouchableOption,
    touchableName,
    touchableOptions,
  ]);

  const EnhancedPlanBOptions = useMemo(() => {
    if (buVisible.planB) {
      return businessUnitsData.map((bu) => (
        <PlanBColumns
          isGroup={isGroup}
          key={bu.businessUnitId}
          chosenPlanBOptions={bu.chosenPlanBOptions}
          setPlanBOption={setPlanBOption}
          customerId={customerId}
          businessUnitId={bu.businessUnitId}
          isDisabled={bu.isDisabled}
          isEven={isEven}
        />
      ));
    } else {
      return (
        <PlanBColumns
          isGroup={isGroup}
          chosenPlanBOptions={chosenPlanBOptions}
          setPlanBOption={setPlanBOption}
          customerId={customerId}
          isEven={isEven}
        />
      );
    }
  }, [
    buVisible.planB,
    businessUnitsData,
    chosenPlanBOptions,
    customerId,
    isEven,
    isGroup,
    setPlanBOption,
  ]);

  const OtherOptions = useMemo(() => {
    if (buVisible.other) {
      return businessUnitsData.map((bu) => (
        <OtherColumns
          isGroup={isGroup}
          isEven={isEven}
          key={bu.businessUnitId}
          chosenOtherOptions={bu.chosenOtherOptions}
          otherOptions={otherOptions}
          customerId={customerId}
          businessUnitId={bu.businessUnitId}
          isDisabled={bu.isDisabled}
          setOtherOption={setOtherOption}
        />
      ));
    } else {
      return (
        <OtherColumns
          isGroup={isGroup}
          isEven={isEven}
          chosenOtherOptions={chosenOtherOptions}
          otherOptions={otherOptions}
          customerId={customerId}
          setOtherOption={setOtherOption}
        />
      );
    }
  }, [
    buVisible.other,
    businessUnitsData,
    chosenOtherOptions,
    customerId,
    otherOptions,
    isEven,
    isGroup,
    setOtherOption,
  ]);

  return (
    <tr className={classes.row}>
      <td className={!isEven ? classes.cellGray : ''}>
        <div style={{ width: '80px' }}>{customerName}</div>
      </td>
      <td className={!isEven ? classes.cellGray : ''}>{customerId}</td>
      <td className={!isEven ? classes.cellGray : ''} style={{ width: '80px' }}>
        {parentAccountName ? parentAccountName : '-'}
      </td>
      <td
        className={`${!isEven ? classes.cellGray : ''} ${
          !isBusinessUnitsVisible && classes.cellRightBorder
        }`}>
        {parentAccountId ? parentAccountId : '-'}
      </td>
      {copyBuValueGeneralInfo && (
        <td className={`${!isEven ? classes.cellGray : ''} ${classes.cellRightBorder}`}>
          <ButtonBase
            title="Sync"
            variant="contained"
            disabled={disableEditPage}
            onClick={handleShowWarning}
          />
          {showSyncWarning && (
            <div className={classes.popper}>
              <div>
                <h4 className={classes.popperText}>
                  Are you sure you want to apply the value from the first business unit to all
                  others?
                </h4>
              </div>
              <Stack columnGap={2} direction="row">
                <ButtonBase
                  variant="contained"
                  title="Cancel"
                  onClick={() => {
                    handleShowWarning(false);
                  }}
                />
                <ButtonBase variant="contained" title="Confirm" onClick={copyBuValues} />
              </Stack>
            </div>
          )}
        </td>
      )}

      {EnhancedGeneralInformation}
      {EoiEnhancedOptions}
      {EnhancedContractCommitments}
      {EnhancedPlanBOptions}
      {OtherOptions}
      <td style={{ backgroundColor: !isEven ? COLORS.blue13 : COLORS.white1 }}>
        <InputBase
          disabled={disableEditPage}
          onChangeCb={onChangeComment}
          inputStyles={classes.commentInput}
          value={comment}
        />
      </td>
    </tr>
  );
});

import React, { FC } from 'react';
import useStyles from './useStyles';
import InputColumn from './InputColumn';
import { ButtonBase } from '../../../../../../common/ButtonBase';
import {
  createPriceIncreaseBridgeContractTerm,
  createPriceIncreaseBridgeContractTermForAccountManager,
  createPriceIncreaseBridgePlanB,
  createPriceIncreaseBridgePlanBForAccountManager,
} from '../../../../../../../modules/battleCards/actions';
import { useDispatch } from 'react-redux';
import { PriceIncreaseBridgeResponse } from '../../../../../../../types';

const MAX_NUMBER_FIELDS = 5;

type FieldsProps = {
  priceIncreaseBridge: PriceIncreaseBridgeResponse;
  customerId?: string;
  isBattleCards: boolean;
};

const Fields: FC<FieldsProps> = ({ priceIncreaseBridge, customerId, isBattleCards }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { breachOfContractTermOptions, planBOptions } = priceIncreaseBridge || {};

  const handleAddNewDriver = () => {
    const payload = {
      params: {
        customerId: customerId,
      },
      body: {
        id: null,
        name: `c${breachOfContractTermOptions?.length + 1}`,
        value: 0,
        argumentation: '',
      },
    };
    if (breachOfContractTermOptions?.length < MAX_NUMBER_FIELDS) {
      if (isBattleCards) {
        dispatch(createPriceIncreaseBridgeContractTermForAccountManager.request(payload));
      } else {
        dispatch(createPriceIncreaseBridgeContractTerm.request(payload));
      }
    }
  };
  const handleAddNewPlanB = () => {
    const payload = {
      params: {
        customerId: customerId,
      },
      body: {
        id: null,
        name: `t${planBOptions?.length + 1}`,
        value: 0,
        argumentation: '',
      },
    };
    if (planBOptions?.length < MAX_NUMBER_FIELDS) {
      if (isBattleCards) {
        dispatch(createPriceIncreaseBridgePlanBForAccountManager.request(payload));
      } else {
        dispatch(createPriceIncreaseBridgePlanB.request(payload));
      }
    }
  };

  return (
    <>
      <div className={classes.fields}>
        <div className={classes.nameColumn}>
          <div className={classes.name}>Driver</div>
          <div className={classes.name}>Value</div>
          <div className={classes.name}>Argumentation</div>
        </div>
        {breachOfContractTermOptions?.map((item) => (
          <div key={item.id} className={classes.fieldColumn}>
            <InputColumn
              item={item}
              option="contract-term"
              customerId={customerId}
              isBattleCards={isBattleCards}
            />
          </div>
        ))}
        <div className={`${classes.addButtonWrapper} ${classes.addButtonLongWrapper}`}>
          <ButtonBase
            title="Add new driver"
            variant="outlined"
            containerStyle={classes.addButton}
            onClick={handleAddNewDriver}
            disabled={breachOfContractTermOptions?.length === MAX_NUMBER_FIELDS}
          />
        </div>
        {planBOptions?.map((item) => (
          <div key={item.id} className={classes.fieldColumn}>
            <InputColumn
              item={item}
              option="plan-b"
              customerId={customerId}
              isBattleCards={isBattleCards}
            />
          </div>
        ))}
        <div className={classes.addButtonWrapper}>
          <ButtonBase
            title="Add plan B"
            variant="outlined"
            containerStyle={classes.addButton}
            onClick={handleAddNewPlanB}
            disabled={planBOptions?.length === MAX_NUMBER_FIELDS}
          />
        </div>
      </div>
    </>
  );
};

export default Fields;

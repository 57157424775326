import React, { memo, useEffect, useMemo } from 'react';
import { ReactComponent as CheckIcon } from 'src/assets/icons/check.svg';
import { ReactComponent as SmallCheckIcon } from 'src/assets/icons/checkSmall.svg';

import useStyles from './useStyles';

import { COLORS } from 'src/constants/COLORS';

type Props = {
  isChecked: boolean;
  onChangeCb?: (value: boolean) => void;
  width?: number;
  disabled?: boolean;
  nonEditable?: boolean;
  id?: string;
  size?: number;
};

export const Checkbox: React.FC<Props> = memo(function Checkbox({
  isChecked,
  onChangeCb,
  size = 16,
  nonEditable,
  disabled,
  id,
}) {
  const [checked, setChecked] = React.useState(false);

  const classes = useStyles();

  const handleChange = (isChecked: boolean) => {
    if (!nonEditable) {
      setChecked(isChecked);

      onChangeCb && onChangeCb(isChecked);
    }
  };

  const memoizedContainerStyle = useMemo(() => {
    return `${classes.container} ${disabled && classes.disabled}`;
  }, [classes.container, classes.disabled, disabled]);

  const memoizedIconColor = useMemo(() => {
    if (disabled) {
      return COLORS.gray23;
    }

    return COLORS.black6;
  }, [disabled]);

  const Icon = useMemo(() => {
    if (size < 24) {
      return <SmallCheckIcon fill={memoizedIconColor} />;
    }

    return <CheckIcon fill={memoizedIconColor} />;
  }, [memoizedIconColor, size]);

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div
        id={id}
        className={memoizedContainerStyle}
        style={{ width: size, height: size }}
        onClick={() => {
          handleChange(!checked);
        }}>
        {checked && Icon}
      </div>
    </div>
  );
});

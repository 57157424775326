import { createReducer } from 'deox';
import { produce } from 'immer';

import { TouchableRevenueState } from './types';
import {
  getBusinessUnits,
  exportBusinessUnitsExcel,
  exportAccountManagersListExcel,
  getAccountManagersList,
  exportCustomerRegistryExcel,
  getCustomerRegistry,
  getAccountManagerNames,
  setAccountManager,
  sendSurveyToAll,
  sendSurveyToAccountManager,
  getSurveyStatuses,
  getRevenueBase,
  getBusinessUnitsName,
  updateCustomerRegistryTable,
  getAccountManagerGroups,
} from './actions';

const initialState: TouchableRevenueState = {
  loading: false,
  uploadLoading: false,
  loadingButtons: false,
  businessUnits: null,
  accountManagers: null,
  customerRegistry: null,
  accountManagerNames: [],
  surveyStatuses: null,
  revenueBaseCharts: null,
  businessUnitsNames: [],
  accountManagerGroups: [],
};

export const touchableRevenueReducer = createReducer(initialState, (handleAction) => [
  handleAction(updateCustomerRegistryTable.request, (state, { payload }) =>
    produce(state, (draft) => {
      if (state.customerRegistry) {
        draft.customerRegistry = {
          ...state.customerRegistry,
          data: state.customerRegistry.data.map((item) =>
            item.id === payload.payload.id
              ? {
                  ...item,
                  negotiationOnParentLevel:
                    payload.payload.negotiation !== undefined
                      ? payload.payload.negotiation
                      : item.negotiationOnParentLevel,
                  parent:
                    payload.payload.parent !== undefined ? payload.payload.parent : item.parent,
                  parentId:
                    payload.payload.parentId !== undefined
                      ? payload.payload.parentId
                      : item.parentId,
                }
              : item,
          ),
        };
      }
    }),
  ),
  handleAction(updateCustomerRegistryTable.success, (state, { payload }) =>
    produce(state, (draft) => {
      if (state.customerRegistry) {
        draft.customerRegistry = {
          ...state.customerRegistry,
          data: state.customerRegistry.data.map((item) =>
            item.id === payload.id
              ? {
                  ...item,
                  parent: payload.parent,
                }
              : item,
          ),
        };
      }
    }),
  ),
  handleAction(updateCustomerRegistryTable.fail, (state, { payload }) =>
    produce(state, (draft) => {
      if (state.customerRegistry) {
        draft.customerRegistry = {
          ...state.customerRegistry,
          data: state.customerRegistry.data.map((item) =>
            item.id === payload.id
              ? {
                  ...item,
                  negotiationOnParentLevel:
                    payload.name === 'negotiation'
                      ? !item.negotiationOnParentLevel
                      : item.negotiationOnParentLevel,
                  parent: payload.name === 'parent' ? '-' : item.parent,
                  parentId: payload.name === 'parentId' ? '-' : item.parentId,
                }
              : item,
          ),
        };
      }
    }),
  ),
  handleAction(getAccountManagerGroups.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.accountManagerGroups = payload;
    }),
  ),
  handleAction(getBusinessUnits.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(getBusinessUnits.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;

      draft.businessUnits = payload;
    }),
  ),
  handleAction(getBusinessUnits.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(exportBusinessUnitsExcel.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(exportBusinessUnitsExcel.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.businessUnits = payload;
    }),
  ),
  handleAction(exportBusinessUnitsExcel.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(exportAccountManagersListExcel.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(exportAccountManagersListExcel.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.accountManagers = payload;
    }),
  ),
  handleAction(exportAccountManagersListExcel.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(getAccountManagersList.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(getAccountManagersList.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;

      draft.accountManagers = payload;
    }),
  ),
  handleAction(getAccountManagersList.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(exportCustomerRegistryExcel.request, (state) =>
    produce(state, (draft) => {
      draft.uploadLoading = true;
    }),
  ),
  handleAction(exportCustomerRegistryExcel.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.uploadLoading = false;
      draft.customerRegistry = payload;
    }),
  ),
  handleAction(exportCustomerRegistryExcel.fail, (state) =>
    produce(state, (draft) => {
      draft.uploadLoading = false;
    }),
  ),
  handleAction(getCustomerRegistry.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(getCustomerRegistry.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.customerRegistry = payload;
    }),
  ),
  handleAction(getCustomerRegistry.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(getAccountManagerNames.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.accountManagerNames = payload;
    }),
  ),
  handleAction(setAccountManager.success, (state, { payload }) =>
    produce(state, (draft) => {
      if (state.customerRegistry) {
        draft.customerRegistry = {
          ...state.customerRegistry,
          data: state.customerRegistry.data.map((item) =>
            item.id === payload.id
              ? {
                  ...item,
                  accountManager: payload.accountManager,
                }
              : item,
          ),
        };
      }
    }),
  ),
  handleAction(getSurveyStatuses.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(getSurveyStatuses.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.surveyStatuses = payload;
    }),
  ),
  handleAction(getSurveyStatuses.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(sendSurveyToAll.request, (state) =>
    produce(state, (draft) => {
      draft.loadingButtons = true;
    }),
  ),
  handleAction(sendSurveyToAll.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loadingButtons = false;

      if (state.surveyStatuses && !payload.isSecondSurveyStatus) {
        draft.surveyStatuses = {
          ...state.surveyStatuses,
          data: state.surveyStatuses.data.map((status) => ({
            ...status,
            surveySent: true,
            status: 'survey sent',
          })),
        };
      }
    }),
  ),
  handleAction(sendSurveyToAll.fail, (state) =>
    produce(state, (draft) => {
      draft.loadingButtons = false;
    }),
  ),
  handleAction(sendSurveyToAccountManager.success, (state, { payload }) =>
    produce(state, (draft) => {
      const { isSecondSurvey, accountManagerId } = payload;

      if (state.surveyStatuses && !isSecondSurvey) {
        draft.surveyStatuses = {
          ...state.surveyStatuses,
          data: state.surveyStatuses.data.map((status) =>
            status.accountManagerId === accountManagerId
              ? {
                  ...status,
                  surveySent: true,
                  status: 'survey sent',
                }
              : status,
          ),
        };
      }
    }),
  ),
  handleAction(getRevenueBase.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(getRevenueBase.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.revenueBaseCharts = payload;
    }),
  ),
  handleAction(getRevenueBase.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(getBusinessUnitsName.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.businessUnitsNames = payload;
    }),
  ),
]);

import React, { memo, useCallback, useContext } from 'react';

import useStyles from './useStyles';
import { ContractCommitment } from 'src/types';
import { useDispatch } from 'react-redux';
import { updateSurveysSettings } from 'src/modules/surveys/actions';
import { ContractCommitmentItem } from '../ContractCommitmentItem';
import { Stack } from '@mui/material';
import { ErrorOption } from '../../index';
import { SurveysProvider } from '../../SurveysProvider';
import { TextButton } from '../../../../../../common/TextButton';

type Props = {
  contractCommitments?: ContractCommitment[];
  idsWithError?: ErrorOption;
};

export const ContractCommitments: React.FC<Props> = memo(function ContractCommitment({
  contractCommitments,
  idsWithError,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { disabled } = useContext(SurveysProvider);

  const onAddNewContractCommitmentOption = useCallback(() => {
    dispatch(
      updateSurveysSettings.request({
        body: {
          contractCommitments: [
            {
              option: '',
            },
          ],
        },
        needUpdateState: true,
      }),
    );
  }, [dispatch]);

  const onChangeContractCommitmentOption = useCallback(
    (option, id) => {
      dispatch(
        updateSurveysSettings.request({
          body: {
            contractCommitments: [
              {
                id,
                option,
              },
            ],
          },
          needUpdateState: true,
        }),
      );
    },
    [dispatch],
  );

  return (
    <div className={classes.container}>
      {contractCommitments &&
        contractCommitments.map((commitment) => (
          <ContractCommitmentItem
            onChangeValueCb={onChangeContractCommitmentOption}
            key={commitment.id}
            error={idsWithError ? idsWithError.ids.includes(commitment.id) : false}
            {...commitment}
          />
        ))}

      {!disabled && (
        <Stack>
          <TextButton onClick={onAddNewContractCommitmentOption} title="Add option" />
        </Stack>
      )}
    </div>
  );
});

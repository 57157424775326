import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../../../../constants/COLORS';

const useStyles = makeStyles(() => ({
  input: {
    border: `1px solid ${COLORS.gray26}`,
    padding: '5px 0px 16px 10px',
    borderRadius: 8,
    height: '38px',
    width: '97%',
    outline: 'none',
    fontSize: '12px',
    color: COLORS.black1,
    '&:placeholder': {
      color: COLORS.gray23,
      fontSize: 12,
    },
    '&:hover': {
      borderColor: COLORS.blue2,
    },
    display: 'block',
    verticalAlign: 'top',
  },
}));

export default useStyles;

import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 30,
    display: 'flex',
  },
  day: {
    width: 50,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${COLORS.gray16}`,
    cursor: 'pointer',
    borderRight: 'none',
    '&:last-child': {
      borderRight: `1px solid ${COLORS.gray16}`,
    },
  },
  selected: {
    backgroundColor: COLORS.gray12,
  },
}));

export default useStyles;

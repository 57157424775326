import React, { memo, useCallback, useMemo } from 'react';
import { CustomerTarget, Store } from 'src/types';
import { CalculatedPriceCells } from '../CalculatedPriceCells';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateCustomerTargetBusinessUnitCell,
  updateCustomerTargetCell,
} from 'src/modules/specificTargets/actions';
import {
  resetOverwriteImpactEffect,
  updateRealizedPrice,
} from '../../../../modules/surveys/actions';
import { Switch } from '../../Switch';
import { numberSeparator } from '../../../../helpers/numberSeparator';
import { ButtonBase } from '../../ButtonBase';

type Props = CustomerTarget & {
  buVisible: boolean;
  disableEditable?: boolean;
  showRealizedPrice?: boolean;
  isRealizedPriceDisabled?: boolean;
  page: number;
  rowPerPage: number;
};

export const CustomerTargetsRow: React.FC<Props> = memo(function CustomerTargetsRow({
  customerId,
  longTailCustomer,
  name,
  parent,
  parentId,
  revenue,
  accountManagerName,
  buVisible,
  goingInPriceIncrease,
  targetPriceIncrease,
  walkAwayPriceIncrease,
  businessUnitData,
  overwriteImpactEffect,
  disableEditable,
  showRealizedPrice,
  isRealizedPriceDisabled,
  escalationPriceIncrease,
  page,
  rowPerPage,
}) {
  const dispatch = useDispatch();

  const { projectId } = useSelector((state: Store) => state.projects);

  const onEditCell = useCallback(
    (body: {
      goingInPriceIncrease?: number;
      targetPriceIncrease?: number;
      walkAwayPriceIncrease?: number;
      escalationPriceIncrease?: number;
    }) => {
      dispatch(
        updateCustomerTargetCell.request({
          customerId,
          ...body,
        }),
      );
    },
    [customerId, dispatch],
  );

  const onEditBusinessUnitCell = useCallback(
    (body: {
      businessUnitId?: number;
      goingInPriceIncrease?: number;
      targetPriceIncrease?: number;
      walkAwayPriceIncrease?: number;
    }) => {
      dispatch(
        updateCustomerTargetBusinessUnitCell.request({
          customerId,
          ...body,
        }),
      );
    },
    [customerId, dispatch],
  );

  const onEditRealizedPrice = useCallback(
    (body) => {
      dispatch(
        updateRealizedPrice.request({
          customerId,
          ...body,
        }),
      );
    },
    [customerId, dispatch],
  );

  const handleResetOverwriteImpactEffect = useCallback(() => {
    dispatch(
      resetOverwriteImpactEffect.request({
        customerId,
        projectId,
        page,
        rowPerPage,
      }),
    );
  }, [customerId, dispatch, projectId]);

  const CalculatedPriceIncrease = useMemo(() => {
    return buVisible ? (
      businessUnitData.map((bu) => (
        <CalculatedPriceCells
          realizedPrice={bu.realizedPrice}
          onEditRealizedPrice={onEditRealizedPrice}
          showRealizedPrice={showRealizedPrice}
          disableEditable={disableEditable}
          businessUnitId={bu.businessUnitId}
          key={bu.businessUnitId}
          goingInPriceIncrease={bu.goingInPriceIncrease}
          targetPriceIncrease={bu.targetPriceIncrease}
          walkAwayPriceIncrease={bu.walkAwayPriceIncrease}
          onEdit={onEditBusinessUnitCell}
          isRealizedPriceDisabled={isRealizedPriceDisabled}
          escalationPriceIncrease={bu.escalationPriceIncrease}
        />
      ))
    ) : (
      <CalculatedPriceCells
        disableEditable={disableEditable}
        goingInPriceIncrease={goingInPriceIncrease}
        targetPriceIncrease={targetPriceIncrease}
        walkAwayPriceIncrease={walkAwayPriceIncrease}
        escalationPriceIncrease={escalationPriceIncrease}
        onEdit={onEditCell}
      />
    );
  }, [
    buVisible,
    businessUnitData,
    disableEditable,
    escalationPriceIncrease,
    goingInPriceIncrease,
    isRealizedPriceDisabled,
    onEditBusinessUnitCell,
    onEditCell,
    onEditRealizedPrice,
    showRealizedPrice,
    targetPriceIncrease,
    walkAwayPriceIncrease,
  ]);

  return (
    <tr>
      <td>
        <div>{name}</div>
      </td>
      <td>
        <div>{customerId}</div>
      </td>
      <td>
        <div>{parent}</div>
      </td>
      <td>
        <div>{parentId}</div>
      </td>
      <td>
        <div>
          <Switch disabled isChecked={longTailCustomer} />
        </div>
      </td>
      <td>
        <div>{accountManagerName}</div>
      </td>
      <td>
        <div>{numberSeparator(revenue)}</div>
      </td>
      {CalculatedPriceIncrease}
      <td>
        <div>
          <div
            style={{
              backgroundColor: overwriteImpactEffect,
              width: '100%',
              height: 15,
              marginLeft: 22,
              marginRight: 10,
            }}>
            {overwriteImpactEffect || 0}%
          </div>
          <div>
            <ButtonBase
              variant="outlined"
              title="Reset"
              onClick={handleResetOverwriteImpactEffect}
              padding={'0px'}
              inlineStyle={{ height: '20px', minWidth: '70px' }}
            />
          </div>
        </div>
      </td>
    </tr>
  );
});

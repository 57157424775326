import { createReducer } from 'deox';
import { produce } from 'immer';

import {
  getSurveyResultAccess,
  getSurveysSettings,
  updateSurveysSettings,
  setSelectedEaseOfIncreaseDriver,
  setSelectedOther,
  cleanSelectedEaseOfIncreaseDriver,
  deleteSurveyPriceIncreaseTimeLevelOption,
  getSurveyResult,
  setEOIFieldError,
  surveySettingsCreateNewEOI,
  surveySettingsUpdateEOIOption,
  surveySettingUpdateEOIModalInfo,
  deleteSurveySettingsOption,
  getSurveysCompleting,
  setSurveyResultAdditionalFields,
  setTouchableOption,
  setEOIOption,
  setContractCommitment,
  setPlanBOption,
  surveyResultCopyOptionsFromFirstBUToOther,
  saveSurveyCompletingCustomersDate,
  getSecondSurvey,
  editCustomerRowSecondSurvey,
  createSurveySettingsOtherOption,
  deleteSurveyOtherModalOption,
  surveySettingUpdateOtherModalInfo,
  surveyResultCopyOptionsFromFirstBUToOtherAccountManager,
  updateSurveySettingsOtherOption,
} from './actions';
import { SurveysState } from './types';
import { SurveySettingsCategories } from 'src/constants/SurveySettingCategories';

const initialState: SurveysState = {
  loading: false,
  firstButtonLoading: false,
  secondButtonLoading: false,
  updateSettingsLoading: false,
  surveySettings: null,
  selectedEaseOfIncreaseDriver: null,
  selectedOther: null,
  surveyResult: null,
  surveyCompleting: null,
  surveyCompletingDate: '',
  secondSurvey: null,
  surveyResultAccessing: null,
};

export const surveysReducer = createReducer(initialState, (handleAction) => [
  handleAction(editCustomerRowSecondSurvey, (state, { payload }) =>
    produce(state, (draft) => {
      if (state.secondSurvey) {
        draft.secondSurvey = {
          ...state.secondSurvey,
          customerData: state.secondSurvey.customerData.map((customer) =>
            customer.id === payload.customerId
              ? {
                  ...customer,
                  status: payload.status || customer.status,
                  expectedOutOfRealizedIncreases: customer.expectedOutOfRealizedIncreases.map(
                    (bu) =>
                      bu.businessUnitId === payload.businessUnitId
                        ? { ...bu, percentage: payload.percentage || bu.percentage }
                        : bu,
                  ),
                  comment: payload.comment || customer.comment,
                }
              : customer,
          ),
        };
      }
    }),
  ),
  handleAction(getSecondSurvey.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(getSecondSurvey.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.secondSurvey = payload;
    }),
  ),
  handleAction(getSecondSurvey.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(saveSurveyCompletingCustomersDate, (state, { payload }) =>
    produce(state, (draft) => {
      draft.surveyCompletingDate = payload;
    }),
  ),
  handleAction(surveyResultCopyOptionsFromFirstBUToOther.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(surveyResultCopyOptionsFromFirstBUToOther.success, (state, { payload }) =>
    produce(state, (draft) => {
      const {
        body: { businessUnitId },
      } = payload;

      if (state.surveyResult) {
        draft.loading = false;
        draft.surveyResult = {
          ...state.surveyResult,
          customersData: state.surveyResult.customersData.map((item) => ({
            ...item,
            businessUnitsData: item.businessUnitsData.map((bu) =>
              bu.businessUnitId !== businessUnitId
                ? {
                    ...bu,
                    contractExpirationDate: payload.body.isGeneralInfo
                      ? item.businessUnitsData[0].contractExpirationDate
                      : bu.contractExpirationDate,
                    lastPriceIncrease: payload.body.isGeneralInfo
                      ? item.businessUnitsData[0].lastPriceIncrease
                      : bu.lastPriceIncrease,
                    touchableName: payload.body.isGeneralInfo
                      ? item.businessUnitsData[0].touchableName
                      : bu.touchableName,
                    chosenEaseOfIncreaseOptions: payload.body.isEaseOfIncrease
                      ? item.businessUnitsData[0].chosenEaseOfIncreaseOptions
                      : bu.chosenEaseOfIncreaseOptions,
                    chosenContractCommitmentOptions: payload.body.isBreachOfContractTerms
                      ? item.businessUnitsData[0].chosenContractCommitmentOptions
                      : bu.chosenContractCommitmentOptions,
                    chosenPlanBOptions: payload.body.isPlanBOption
                      ? item.businessUnitsData[0].chosenPlanBOptions
                      : bu.chosenPlanBOptions,
                    chosenOtherOptions: payload.body.isOtherOption
                      ? item.businessUnitsData[0].chosenOtherOptions
                      : bu.chosenOtherOptions,
                  }
                : bu,
            ),
          })),
        };
      }
    }),
  ),
  handleAction(surveyResultCopyOptionsFromFirstBUToOther.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),

  handleAction(surveyResultCopyOptionsFromFirstBUToOtherAccountManager.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(
    surveyResultCopyOptionsFromFirstBUToOtherAccountManager.success,
    (state, { payload }) =>
      produce(state, (draft) => {
        const {
          body: { businessUnitId },
        } = payload;

        if (state.surveyCompleting) {
          draft.loading = false;
          draft.surveyCompleting = {
            ...state.surveyCompleting,
            surveyResultData: {
              ...state.surveyCompleting.surveyResultData,
              customersData: state.surveyCompleting.surveyResultData.customersData.map((item) => ({
                ...item,
                businessUnitsData: item.businessUnitsData.map((bu) =>
                  bu.businessUnitId !== businessUnitId
                    ? {
                        ...bu,
                        contractExpirationDate: payload.body.isGeneralInfo
                          ? item.businessUnitsData[0].contractExpirationDate
                          : bu.contractExpirationDate,
                        lastPriceIncrease: payload.body.isGeneralInfo
                          ? item.businessUnitsData[0].lastPriceIncrease
                          : bu.lastPriceIncrease,
                        touchableName: payload.body.isGeneralInfo
                          ? item.businessUnitsData[0].touchableName
                          : bu.touchableName,
                        chosenEaseOfIncreaseOptions: payload.body.isEaseOfIncrease
                          ? item.businessUnitsData[0].chosenEaseOfIncreaseOptions
                          : bu.chosenEaseOfIncreaseOptions,
                        chosenContractCommitmentOptions: payload.body.isBreachOfContractTerms
                          ? item.businessUnitsData[0].chosenContractCommitmentOptions
                          : bu.chosenContractCommitmentOptions,
                        chosenPlanBOptions: payload.body.isPlanBOption
                          ? item.businessUnitsData[0].chosenPlanBOptions
                          : bu.chosenPlanBOptions,
                        chosenOtherOptions: payload.body.isOtherOption
                          ? item.businessUnitsData[0].chosenOtherOptions
                          : bu.chosenOtherOptions,
                      }
                    : bu,
                ),
              })),
            },
          };
        }
      }),
  ),
  handleAction(surveyResultCopyOptionsFromFirstBUToOtherAccountManager.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),

  handleAction(setPlanBOption.request, (state, { payload }) =>
    produce(state, (draft) => {
      if (state.surveyResult) {
        if (payload.body.customerData) {
          draft.surveyResult = {
            ...state.surveyResult,
            customersData: state.surveyResult.customersData.map((item) =>
              item.customerId === payload.body.customerData?.customerId
                ? {
                    ...item,
                    chosenPlanBOptions: item.chosenPlanBOptions.map((option) =>
                      option.optionId === payload.body.customerData?.optionId
                        ? {
                            ...option,
                            isChosen: payload.body.customerData?.isChosen,
                          }
                        : option,
                    ),
                    businessUnitsData: item.businessUnitsData.map((bu) => ({
                      ...bu,
                      chosenPlanBOptions: bu.chosenPlanBOptions.map((option) =>
                        option.optionId === payload.body.customerData?.optionId
                          ? {
                              ...option,
                              isChosen: payload.body.customerData?.isChosen,
                            }
                          : option,
                      ),
                    })),
                  }
                : item,
            ),
          };
        }

        if (payload.body.businessUnitData) {
          draft.surveyResult = {
            ...state.surveyResult,
            customersData: state.surveyResult.customersData.map((item) =>
              item.customerId === payload.body.businessUnitData?.customerId
                ? {
                    ...item,
                    businessUnitsData: item.businessUnitsData.map((bu) =>
                      bu.businessUnitId === payload.body.businessUnitData?.businessUnitId
                        ? {
                            ...bu,
                            chosenPlanBOptions: bu.chosenPlanBOptions.map((option) =>
                              option.optionId === payload.body.businessUnitData?.optionId
                                ? {
                                    ...option,
                                    isChosen: payload.body.businessUnitData.isChosen,
                                  }
                                : option,
                            ),
                          }
                        : bu,
                    ),
                  }
                : item,
            ),
          };
        }
      }
    }),
  ),
  handleAction(setContractCommitment.request, (state, { payload }) =>
    produce(state, (draft) => {
      if (state.surveyResult) {
        if (payload.body.customerData) {
          draft.surveyResult = {
            ...state.surveyResult,
            customersData: state.surveyResult.customersData.map((item) =>
              item.customerId === payload.body.customerData?.customerId
                ? {
                    ...item,
                    chosenContractCommitmentOptions: item.chosenContractCommitmentOptions.map(
                      (option) =>
                        option.optionId === payload.body.customerData?.optionId
                          ? {
                              ...option,
                              isChosen: payload.body.customerData?.isChosen,
                            }
                          : option,
                    ),
                    businessUnitsData: item.businessUnitsData.map((bu) => {
                      return {
                        ...bu,
                        chosenContractCommitmentOptions: bu?.chosenContractCommitmentOptions?.map(
                          (commitmentOption) =>
                            commitmentOption.optionId === payload.body.customerData?.optionId
                              ? {
                                  ...commitmentOption,
                                  isChosen: payload.body.customerData?.isChosen,
                                }
                              : commitmentOption,
                        ),
                      };
                    }),
                  }
                : item,
            ),
          };
        }

        if (payload.body.businessUnitData) {
          draft.surveyResult = {
            ...state.surveyResult,
            customersData: state.surveyResult.customersData.map((item) =>
              item.customerId === payload.body.businessUnitData?.customerId
                ? {
                    ...item,
                    businessUnitsData: item.businessUnitsData.map((bu) =>
                      bu.businessUnitId === payload.body.businessUnitData?.businessUnitId
                        ? {
                            ...bu,
                            chosenContractCommitmentOptions: bu.chosenContractCommitmentOptions.map(
                              (option) =>
                                option.optionId === payload.body.businessUnitData?.optionId
                                  ? {
                                      ...option,
                                      isChosen: payload.body.businessUnitData.isChosen,
                                    }
                                  : option,
                            ),
                          }
                        : bu,
                    ),
                  }
                : item,
            ),
          };
        }
      }
    }),
  ),
  handleAction(setEOIOption.request, (state, { payload }) =>
    produce(state, (draft) => {
      if (state.surveyResult) {
        if (payload.body.customerData) {
          const priceIncreaseTimeLevel = state.surveyResult.options.easeOfIncreaseOptions.find(
            (item) => item.driverId === payload.body.customerData?.driverId,
          );

          const priceIncreaseTimeLevelOption =
            priceIncreaseTimeLevel?.priceIncreaseTimeLevelOptions.find(
              (item) =>
                item.priceIncreaseTimeLevelId ===
                payload.body.customerData?.priceIncreaseTimeLevelId,
            );

          draft.surveyResult = {
            ...state.surveyResult,
            customersData: state.surveyResult.customersData.map((item) =>
              item.customerId === payload.body.customerData?.customerId
                ? {
                    ...item,
                    chosenEaseOfIncreaseOptions: item.chosenEaseOfIncreaseOptions.map((eoi) =>
                      eoi.driverId === payload.body.customerData?.driverId
                        ? {
                            ...eoi,
                            priceIncreaseTimeLevelId:
                              payload.body.customerData?.priceIncreaseTimeLevelId,
                            priceIncreaseTimeLevelName:
                              priceIncreaseTimeLevelOption?.priceIncreaseTimeLevel || '',
                          }
                        : eoi,
                    ),
                    businessUnitsData: item.businessUnitsData.map((bu) => ({
                      ...bu,
                      chosenEaseOfIncreaseOptions: bu.chosenEaseOfIncreaseOptions.map((eoi) =>
                        eoi.driverId === payload.body.customerData?.driverId
                          ? {
                              ...eoi,
                              priceIncreaseTimeLevelId:
                                payload.body.customerData?.priceIncreaseTimeLevelId,
                              priceIncreaseTimeLevelName:
                                priceIncreaseTimeLevelOption?.priceIncreaseTimeLevel || '',
                            }
                          : eoi,
                      ),
                    })),
                  }
                : item,
            ),
          };
        }

        // change value in businessUnits array
        if (payload.body.businessUnitData) {
          const priceIncreaseTimeLevel = state.surveyResult.options.easeOfIncreaseOptions.find(
            (item) => item.driverId === payload.body.businessUnitData?.driverId,
          );

          const priceIncreaseTimeLevelOption =
            priceIncreaseTimeLevel?.priceIncreaseTimeLevelOptions.find(
              (item) =>
                item.priceIncreaseTimeLevelId ===
                payload.body.businessUnitData?.priceIncreaseTimeLevelId,
            );

          draft.surveyResult = {
            ...state.surveyResult,
            customersData: state.surveyResult.customersData.map((item) =>
              item.customerId === payload.body.businessUnitData?.customerId
                ? {
                    ...item,
                    businessUnitsData: item.businessUnitsData.map((bu) =>
                      bu.businessUnitId === payload.body.businessUnitData?.businessUnitId
                        ? {
                            ...bu,
                            chosenEaseOfIncreaseOptions: bu.chosenEaseOfIncreaseOptions.map((eoi) =>
                              eoi.driverId === payload.body.businessUnitData?.driverId
                                ? {
                                    ...eoi,
                                    priceIncreaseTimeLevelId:
                                      payload.body.businessUnitData?.priceIncreaseTimeLevelId,
                                    priceIncreaseTimeLevelName:
                                      priceIncreaseTimeLevelOption?.priceIncreaseTimeLevel || '',
                                  }
                                : eoi,
                            ),
                          }
                        : bu,
                    ),
                  }
                : item,
            ),
          };
        }
      }
    }),
  ),

  handleAction(setTouchableOption.request, (state, { payload }) =>
    produce(state, (draft) => {
      if (state.surveyResult) {
        if (payload.body.customerData) {
          const newTouchableName = state.surveyResult.options.touchableOptions.find(
            (option) => option.optionId === payload.body?.customerData?.optionId,
          );

          draft.surveyResult = {
            ...state.surveyResult,
            customersData: state.surveyResult.customersData.map((item) =>
              item.customerId === payload.body?.customerData?.customerId
                ? {
                    ...item,
                    touchableName: newTouchableName?.option || '',
                    businessUnitsData: item.businessUnitsData.map((bu) => ({
                      ...bu,
                      touchableName: newTouchableName?.option || '',
                    })),
                  }
                : item,
            ),
          };
        }
        if (payload.body.businessUnitData) {
          const newTouchableName = state.surveyResult.options.touchableOptions.find(
            (option) => option.optionId === payload.body?.businessUnitData?.optionId,
          );

          draft.surveyResult = {
            ...state.surveyResult,
            customersData: state.surveyResult.customersData.map((item) =>
              item.customerId === payload.body?.businessUnitData?.customerId
                ? {
                    ...item,
                    businessUnitsData: item.businessUnitsData.map((bu) =>
                      bu.businessUnitId === payload.body.businessUnitData?.businessUnitId
                        ? {
                            ...bu,
                            touchableName: newTouchableName?.option || '',
                          }
                        : bu,
                    ),
                  }
                : item,
            ),
          };
        }
      }
    }),
  ),
  handleAction(setSurveyResultAdditionalFields.request, (state, { payload }) =>
    produce(state, (draft) => {
      if (state.surveyResult) {
        if (payload.body.customerData) {
          draft.surveyResult = {
            ...state.surveyResult,
            customersData: state.surveyResult.customersData.map((item) =>
              item.customerId === payload.body?.customerData?.customerId
                ? {
                    ...item,
                    ...payload.body?.customerData,
                    businessUnitsData: item.businessUnitsData.map((bu) => ({
                      ...bu,
                      ...payload.body?.customerData,
                    })),
                  }
                : item,
            ),
          };
        }

        if (payload.body.businessUnitData) {
          draft.surveyResult = {
            ...state.surveyResult,
            customersData: state.surveyResult.customersData.map((item) =>
              item.customerId === payload.body.businessUnitData?.customerId
                ? {
                    ...item,
                    businessUnitsData: item.businessUnitsData.map((bu) =>
                      bu.businessUnitId === payload.body.businessUnitData?.businessUnitId
                        ? {
                            ...bu,
                            ...payload.body.businessUnitData,
                          }
                        : bu,
                    ),
                  }
                : item,
            ),
          };
        }
      }
    }),
  ),
  handleAction(getSurveysSettings.request, (state, { payload }) =>
    produce(state, (draft) => {
      if (payload.needShowLoader) {
        draft.loading = true;
      }
    }),
  ),
  handleAction(getSurveysSettings.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;

      draft.surveySettings = payload;
    }),
  ),
  handleAction(getSurveysSettings.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(surveySettingsUpdateEOIOption.success, (state, { payload }) =>
    produce(state, (draft) => {
      if (state.surveySettings) {
        draft.surveySettings = {
          ...state.surveySettings,
          easeOfIncreaseDrivers: state.surveySettings.easeOfIncreaseDrivers.map((driver) =>
            driver.id === payload.optionId
              ? {
                  ...driver,
                  option: payload.option,
                }
              : driver,
          ),
        };
      }
    }),
  ),
  handleAction(surveySettingUpdateEOIModalInfo.request, (state) =>
    produce(state, (draft) => {
      draft.updateSettingsLoading = true;
    }),
  ),
  handleAction(surveySettingUpdateEOIModalInfo.success, (state) =>
    produce(state, (draft) => {
      draft.updateSettingsLoading = false;
    }),
  ),
  handleAction(surveySettingUpdateEOIModalInfo.fail, (state) =>
    produce(state, (draft) => {
      draft.updateSettingsLoading = false;
    }),
  ),
  handleAction(surveySettingUpdateOtherModalInfo.request, (state) =>
    produce(state, (draft) => {
      draft.updateSettingsLoading = true;
    }),
  ),
  handleAction(surveySettingUpdateOtherModalInfo.success, (state) =>
    produce(state, (draft) => {
      draft.updateSettingsLoading = false;
    }),
  ),
  handleAction(surveySettingUpdateOtherModalInfo.fail, (state) =>
    produce(state, (draft) => {
      draft.updateSettingsLoading = false;
    }),
  ),
  handleAction(updateSurveysSettings.request, (state, { payload }) =>
    produce(state, (draft) => {
      const { needUpdateState } = payload;

      if (needUpdateState) {
        draft.updateSettingsLoading = true;
      }
    }),
  ),
  handleAction(updateSurveysSettings.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.surveySettings = payload;
      draft.updateSettingsLoading = false;
    }),
  ),
  handleAction(updateSurveysSettings.fail, (state) =>
    produce(state, (draft) => {
      draft.surveySettings = state.surveySettings;
      draft.updateSettingsLoading = false;
    }),
  ),
  handleAction(surveySettingsCreateNewEOI.success, (state, { payload }) =>
    produce(state, (draft) => {
      if (state.surveySettings) {
        draft.surveySettings = {
          ...state.surveySettings,
          easeOfIncreaseDrivers: [...state.surveySettings.easeOfIncreaseDrivers, payload],
        };
      }
    }),
  ),
  handleAction(setEOIFieldError, (state, { payload }) =>
    produce(state, (draft) => {
      if (state.surveySettings) {
        draft.surveySettings = {
          ...state.surveySettings,
          easeOfIncreaseDrivers: state.surveySettings.easeOfIncreaseDrivers.map((item) =>
            item.id === payload.id
              ? {
                  ...item,
                  option: '',
                }
              : item,
          ),
        };
      }
    }),
  ),
  handleAction(setSelectedEaseOfIncreaseDriver, (state, { payload }) =>
    produce(state, (draft) => {
      draft.selectedEaseOfIncreaseDriver = payload;
    }),
  ),
  handleAction(setSelectedOther, (state, { payload }) =>
    produce(state, (draft) => {
      draft.selectedOther = payload;
    }),
  ),
  handleAction(cleanSelectedEaseOfIncreaseDriver, (state) =>
    produce(state, (draft) => {
      draft.selectedEaseOfIncreaseDriver = null;
    }),
  ),
  handleAction(deleteSurveySettingsOption.request, (state, { payload }) =>
    produce(state, (draft) => {
      if (state.surveySettings) {
        const {
          category,
          requestParams: { optionId },
        } = payload;

        draft.surveySettings = {
          ...state.surveySettings,
          contractStatusOptions:
            category === SurveySettingsCategories.contractStatusOptions
              ? state.surveySettings.contractStatusOptions.filter((item) => item.id !== optionId)
              : state.surveySettings.contractStatusOptions,
          easeOfIncreaseDrivers:
            category === SurveySettingsCategories.easeOfIncreaseDrivers
              ? state.surveySettings.easeOfIncreaseDrivers.filter((item) => item.id !== optionId)
              : state.surveySettings.easeOfIncreaseDrivers,
          contractCommitments:
            category === SurveySettingsCategories.contractCommitments
              ? state.surveySettings.contractCommitments.filter((item) => item.id !== optionId)
              : state.surveySettings.contractCommitments,
          planBOptions:
            category === SurveySettingsCategories.planBOptions
              ? state.surveySettings.planBOptions.filter((item) => item.id !== optionId)
              : state.surveySettings.planBOptions,
          otherOptions:
            category === SurveySettingsCategories.otherOptions ||
            category === SurveySettingsCategories.otherOptionsText
              ? state.surveySettings.otherOptions.filter((item: any) => item.id !== optionId)
              : state.surveySettings.otherOptions,
        };
      }
    }),
  ),
  handleAction(deleteSurveyPriceIncreaseTimeLevelOption.request, (state, { payload }) =>
    produce(state, (draft) => {
      if (state.selectedEaseOfIncreaseDriver) {
        draft.selectedEaseOfIncreaseDriver = {
          ...state.selectedEaseOfIncreaseDriver,
          priceIncreaseTimeLevels:
            state.selectedEaseOfIncreaseDriver.priceIncreaseTimeLevels.filter((item) => {
              if (item.id !== payload.id || item.temporaryId !== payload.temporaryId) {
                return { ...item };
              }
            }),
        };
      }
    }),
  ),
  handleAction(deleteSurveyOtherModalOption.request, (state, { payload }) =>
    produce(state, (draft) => {
      if (state.selectedOther) {
        draft.selectedOther = {
          ...state.selectedOther,
          items: state.selectedOther.items.filter((item: any) => {
            if (item.id !== payload.id || item.temporaryId !== payload.temporaryId) {
              return { ...item };
            }
          }),
        };
      }
    }),
  ),
  handleAction(getSurveyResult.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(getSurveyResult.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;

      draft.surveyResult = payload;
    }),
  ),
  handleAction(getSurveyResult.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  // Survey completing
  handleAction(getSurveysCompleting.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(getSurveysCompleting.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.surveyCompleting = payload;
      draft.loading = false;
    }),
  ),
  handleAction(getSurveysCompleting.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(getSurveyResultAccess.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.surveyResultAccessing = payload;
    }),
  ),

  handleAction(createSurveySettingsOtherOption.success, (state, { payload }) =>
    produce(state, (draft) => {
      if (state.surveySettings) {
        draft.surveySettings = {
          ...state.surveySettings,
          otherOptions: [...state.surveySettings.otherOptions, payload],
        };
      }
    }),
  ),
  handleAction(updateSurveySettingsOtherOption.request, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(updateSurveySettingsOtherOption.success, (state, { payload }) =>
    produce(state, (draft) => {
      if (state.surveySettings) {
        const filteredOtherOptions = state.surveySettings.otherOptions.filter(
          (option) => option.id !== payload.id,
        );
        draft.surveySettings = {
          ...state.surveySettings,
          otherOptions: [...filteredOtherOptions, payload],
        };
      }
    }),
  ),

  handleAction(updateSurveySettingsOtherOption.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
]);

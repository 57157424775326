import React, { memo, useContext } from 'react';
import { PricePotentialCategory } from 'src/types';
import { useDebouncedCallback } from 'use-debounce';

import { InputBase } from 'src/components/common/InputBase';
import useStyles from './useStyles';
import { DeleteButton } from 'src/components/common/DeleteButton';
import { PricePotentialProvider } from '../../PricePotentialProvider';
import { TextButton } from '../../../TextButton';

type Props = PricePotentialCategory & {
  onAddNewIndicator: (category: {
    id: number;
    category: string;
    indicators: {
      indicator: string;
    }[];
  }) => void;
  onChangeSelectedIndicator: (category: {
    id: number;
    category: string;
    indicators: {
      indicator: string;
      id: number;
    }[];
  }) => void;
  onDeleteCategory: (categoryId: number) => void;
  onDeleteIndicator: (indicatorId: number, categoryId: number) => void;
  onAddNewCategory?: () => void;
  onChangePricePotentialCategoryName: (category: { id: number; category: string }) => void;
};

export const PricePotentialIndicators: React.FC<Props> = memo(function PricePotentialIndicators({
  category,
  id,
  indicators,
  onAddNewIndicator,
  onAddNewCategory,
  onChangeSelectedIndicator,
  onDeleteCategory,
  onDeleteIndicator,
  onChangePricePotentialCategoryName,
}) {
  const classes = useStyles();

  const { disableEditPage } = useContext(PricePotentialProvider);

  const addNewIndicator = () => {
    if (!disableEditPage) {
      onAddNewIndicator({
        id,
        category,
        indicators: [
          {
            indicator: '[Indicator]',
          },
        ],
      });
    }
  };

  const debouncedChangeCategoryName = useDebouncedCallback((value: string) => {
    onChangePricePotentialCategoryName({
      id,
      category: value,
    });
  }, 500);

  const debouncedChangeIndicator = useDebouncedCallback((indicatorId: number, value: string) => {
    onChangeSelectedIndicator({
      id,
      category,
      indicators: [
        {
          id: indicatorId,
          indicator: value,
        },
      ],
    });
  }, 500);

  return (
    <div className={classes.container}>
      <div className={classes.categoryContainer}>
        <InputBase
          inputStyles={classes.input}
          disabled={disableEditPage}
          onChangeCb={debouncedChangeCategoryName}
          value={category}
        />
        <DeleteButton
          disabled={disableEditPage}
          styles={classes.deleteCategoryButton}
          onClick={() => {
            if (!disableEditPage) {
              onDeleteCategory(id);
            }
          }}
        />
        {onAddNewCategory && !disableEditPage && (
          <div className={classes.onAddNewCategoryButton}>
            <TextButton onClick={onAddNewCategory} title="Add category" />
          </div>
        )}
      </div>
      <div className={classes.indicatorsContainer}>
        {indicators.map((indicator) => (
          <div key={indicator.id} className={classes.indicatorItemContainer}>
            <InputBase
              inputStyles={classes.input}
              disabled={disableEditPage}
              onChangeCb={(value) => {
                debouncedChangeIndicator(indicator.id, value);
              }}
              value={indicator.indicator}
            />
            <DeleteButton
              styles={classes.deleteCategoryButton}
              disabled={disableEditPage}
              onClick={() => {
                if (!disableEditPage) {
                  onDeleteIndicator(indicator.id, id);
                }
              }}
            />
          </div>
        ))}
        {!disableEditPage && (
          <div className={classes.onAddIndicatorButton}>
            <TextButton onClick={addNewIndicator} title="Add indicator" />
          </div>
        )}
      </div>
    </div>
  );
});

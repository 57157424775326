import React, { FC } from 'react';
import useStyles from './useStyles';
import NegotiationPower from './NegotiationPower';
import RevenueDevelopment from './RevenueDevelopment';
import { RevenueDevelopmentType } from '../../../../../../types';
import { Loader } from 'src/components/common/Loader';

type NegotiationAndRevenueDevelopmentProps = {
  pricePotential: number;
  revenueDevelopment?: RevenueDevelopmentType[];
  customerName: string;
  loading: boolean;
};
const NegotiationAndRevenueDevelopment: FC<NegotiationAndRevenueDevelopmentProps> = ({
  pricePotential,
  revenueDevelopment,
  customerName,
  loading,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {loading ? (
        <Loader isLoading={loading} marginTop={17} />
      ) : (
        <>
          <div className={classes.title}>{customerName}</div>
          <div className={classes.main}>
            <NegotiationPower pricePotential={pricePotential} />
            <RevenueDevelopment revenueDevelopment={revenueDevelopment} />
          </div>
        </>
      )}
    </div>
  );
};

export default NegotiationAndRevenueDevelopment;

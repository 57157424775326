import React, { useCallback, useEffect, useMemo } from 'react';
import { Stack } from '@mui/material';
import { ButtonBase } from 'src/components/common/ButtonBase';
import { COLORS } from 'src/constants/COLORS';
import { UploadButton } from 'src/components/common/UploadButton';
import {
  exportCustomerTargetsExcel,
  downloadCustomerTargetsTemplate,
  getCustomerTargets as getCustomerTargetsAction,
  getBUAbbreviations,
  updateExpandedStatusOfCustomerTargetsTable,
  getCustomerTargetsExpandedStatus,
} from 'src/modules/specificTargets/actions';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerTargetsTable } from 'src/components/common/CustomerTargetsTable/CustomerTargetsTable';
import { Store } from 'src/types';
import { Loader } from 'src/components/common/Loader';
import { NoDataPlaceholder } from 'src/components/common/NoDataPlaceholder';
import { useNavigate } from 'react-router-dom';

import { ForbidEditCheckbox } from 'src/components/common/ForbidEditCheckbox';
import { ScreenNames } from 'src/constants/ScreenNames';
import { Pagination } from 'src/components/common/Pagination';
import { Stepper } from '../../../../common/Stepper';
import { StepNames } from '../../../../../constants/stepNames';

export const CustomerTargets: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleChangeRowsPerPage = (value: number) => {
    setRowsPerPage(value);
    setPage(0);
  };

  const goToPage = useCallback((page: number) => {
    setPage(page);
  }, []);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { customerTargets, BUAbbreviations, loading, expandedTableStatus } = useSelector(
    (state: Store) => state.specificTargets,
  );

  const { disableEditPage } = useSelector((state: Store) => state.home);

  const getCustomerTargets = useCallback(
    ({ pageNumber, itemsOnPage }: { itemsOnPage: number; pageNumber: number }) => {
      dispatch(
        getCustomerTargetsAction.request({
          data: { pageNumber, itemsOnPage },
        }),
      );
    },
    [dispatch],
  );

  const goNext = useCallback(() => {
    navigate('/configure-levels/customer-specific-targets/campaign-impact');
  }, [navigate]);

  const goBack = useCallback(() => {
    navigate('/configure-levels/customer-specific-targets/target-finetuning');
  }, [navigate]);

  const onDownloadExcel = useCallback(() => {
    dispatch(downloadCustomerTargetsTemplate.request());
  }, [dispatch]);

  const onUploadExcel = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const fileList = e.target.files;

      if (fileList) {
        dispatch(
          exportCustomerTargetsExcel.request({
            file: fileList[0],
            itemsOnPage: rowsPerPage,
            pageNumber: page === 0 ? page : page - 1,
          }),
        );

        e.target.value = '';
      }
    },
    [dispatch, page, rowsPerPage],
  );

  const toggleBuVisible = useCallback(() => {
    dispatch(
      updateExpandedStatusOfCustomerTargetsTable.request({ expanded: !expandedTableStatus }),
    );
  }, [expandedTableStatus, dispatch]);

  const EnhancedTableContainer = useMemo(() => {
    if (loading) {
      return <Loader isLoading={true} marginTop={50} />;
    }

    if (!customerTargets || (customerTargets && customerTargets.data.length === 0)) {
      return <NoDataPlaceholder />;
    }

    return (
      <>
        <CustomerTargetsTable
          disableEditable={disableEditPage}
          buVisible={expandedTableStatus}
          businessUnitAbbreviations={BUAbbreviations}
          customerTargets={customerTargets.data}
          toggleBuVisible={toggleBuVisible}
          page={page - 1}
          rowPerPage={rowsPerPage}
        />
        <Pagination
          totalPages={customerTargets.pageable.totalPages}
          setRowPerPage={handleChangeRowsPerPage}
          setPage={handleChangePage}
          page={page}
          rowPerPage={rowsPerPage}
          marginTop={4}
          goToPage={goToPage}
        />
      </>
    );
  }, [
    customerTargets,
    loading,
    disableEditPage,
    expandedTableStatus,
    BUAbbreviations,
    toggleBuVisible,
    page,
    rowsPerPage,
    goToPage,
  ]);

  useEffect(() => {
    getCustomerTargets({
      pageNumber: page === 0 ? page : page - 1,
      itemsOnPage: rowsPerPage,
    });
  }, [dispatch, getCustomerTargets, page, rowsPerPage]);

  useEffect(() => {
    dispatch(getBUAbbreviations.request());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCustomerTargetsExpandedStatus.request());
  }, [dispatch]);

  return (
    <div>
      <Stepper
        steps={[
          {
            name: 'Touchable revenue',
            activeIndex: null,
            allCompleted: true,
          },
          {
            name: 'Campaign target',
            activeIndex: null,
            allCompleted: true,
          },
          {
            name: 'Customer-specific targets',
            activeIndex: 3,
          },
        ]}
        type={StepNames.ALL}
      />
      <Stack direction="row" columnGap={3} marginTop={4}>
        <ButtonBase
          title="Download Excel template"
          iconColor={COLORS.red1}
          variant="outlined"
          onClick={onDownloadExcel}
        />
        <UploadButton
          disabled={loading}
          onChange={onUploadExcel}
          variant="outlined"
          title="Upload from Excel"
        />
      </Stack>

      {EnhancedTableContainer}

      <Stack
        spacing={6}
        direction="row"
        marginTop={loading ? 35 : 8}
        justifyContent="space-between">
        <ForbidEditCheckbox
          showCheckbox={customerTargets ? customerTargets.data.length > 0 : false}
          checkboxValue={disableEditPage}
          screenName={ScreenNames.CUSTOMER_TARGETS}
        />
        <Stack width="100%" direction="row" spacing={4} justifyContent="flex-end">
          <ButtonBase
            variant="outlined"
            title="Target finetuning"
            onClick={goBack}
            icon="goBack"
            iconLeft={true}
          />
          <ButtonBase variant="contained" title="Campaign impact" onClick={goNext} icon="next" />
        </Stack>
      </Stack>
    </div>
  );
};

import React from 'react';
import {
  ComposedChart,
  Line,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Brush,
  Legend,
} from 'recharts';
import { GetNegotiationProcessResponse } from 'src/types';
import useStyles from './useStyles';
import { Loader } from 'src/components/common/Loader';
import { useHorizontalScroll } from 'src/hooks/useHorizontalScroll';
import { COLORS } from 'src/constants/COLORS';

// eslint-disable-next-line
const CustomTooltip = ({ active, payload, label, styles }: any) => {
  if (active && payload.length) {
    return (
      <div className={styles}>
        <p>{`Date: ${label}`}</p>
        {payload[0].payload.target !== null && (
          <p style={{ color: COLORS.blue1 }}>Target: {payload[0].payload.target}%</p>
        )}
        {payload[0].payload.total !== null && (
          <p style={{ color: COLORS.blue9 }}>Progress: {payload[0].payload.total}%</p>
        )}
      </div>
    );
  }

  return null;
};

// eslint-disable-next-line
const CustomizedEscalationDot = (props: any) => {
  if (props.payload.total === null) {
    return null;
  }

  return (
    <g>
      <circle cx={props.cx} cy={props.cy} r="10" fill="white" stroke="#828282" strokeWidth={2} />
      <text
        fontWeight={700}
        color="#4F4F4F"
        dx={props.cx - 15}
        dy={props.cy + 30}
        fontSize={16}
        fill="#111">
        {props.payload.total}%
      </text>
    </g>
  );
};

// eslint-disable-next-line
const CustomizedTargetPriceDot = (props: any) => {
  return (
    <g>
      <text
        fontWeight={700}
        dx={props.cx - 15}
        dy={props.cy - 14}
        fontSize={16}
        fill={COLORS.blue1}>
        {props.payload.target === 100 ? props.payload.target + '%' : ''}
      </text>
    </g>
  );
};

type Props = {
  data: GetNegotiationProcessResponse | null;
  loading: boolean;
};

export const NegotiationProcessChart: React.FC<Props> = ({ data, loading }) => {
  const classes = useStyles();

  const scrollRef = useHorizontalScroll();

  if (loading) {
    return (
      <div className={classes.container}>
        <Loader isLoading marginTop={20} />
      </div>
    );
  }

  if (data && data.dots.length === 0) {
    return (
      <div className={`${classes.container} ${classes.emptyArrayContainer}`}>
        {[0, 1, 2, 3, 4, 5, 6].map((item) => (
          <div className={classes.emptyArrayItem} key={item} />
        ))}
      </div>
    );
  }

  if (!data || (data && data.dots.length === 0)) {
    return <div className={classes.container} ref={scrollRef}></div>;
  }

  const renderCustomizedLegend = () => {
    return (
      <div className={classes.legend}>
        <div className={classes.legendItem}>
          <div className={classes.legendItemLine} />
          <div>Target</div>
        </div>
        <div className={classes.legendItem}>
          <div className={classes.legendItemCircle} />
          <div>Progress</div>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.container} ref={scrollRef}>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          data={data.dots}
          margin={{
            top: 20,
            right: 40,
            bottom: 20,
            left: 20,
          }}>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="1%" stopColor="#00ACC0" stopOpacity={0.6} />
              <stop offset="100%" stopColor="#F9FDFE" stopOpacity={1} />
            </linearGradient>
          </defs>
          {data.dots.length > 20 && (
            <Brush travellerWidth={0} startIndex={0} endIndex={20} dataKey="date" />
          )}
          <Tooltip content={<CustomTooltip styles={classes.tooltipContainer} />} />
          <CartesianGrid horizontal={false} stroke="#E3E4E4" strokeWidth={4} />
          <XAxis
            axisLine={{ stroke: COLORS.gray20, strokeWidth: 2 }}
            dx={-4}
            dy={20}
            height={70}
            dataKey="date"
            scale="point"
          />
          <YAxis
            axisLine={{ stroke: COLORS.gray20, strokeWidth: 2 }}
            dx={-20}
            padding={{ top: 50 }}
          />
          <Legend content={renderCustomizedLegend} />
          <Line
            isAnimationActive={false}
            type="linear"
            dot={<CustomizedTargetPriceDot />}
            dataKey="target"
            stroke={COLORS.blue1}
          />
          <Area
            isAnimationActive={false}
            dot={<CustomizedEscalationDot />}
            type="monotone"
            dataKey="total"
            fill="url(#colorUv)"
            stroke={COLORS.blue9}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

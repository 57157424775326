import React, { useEffect, useMemo, useState } from 'react';
import { Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import {
  getBUAbbreviations,
  getTargetFineTuning,
  updateTargetFineTuning,
} from 'src/modules/specificTargets/actions';
import { Store } from 'src/types';
import { useNavigate } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import useStyles from './useStyles';
import { AdditionalFields } from './components/AdditionalFields';
import { Dropdown } from 'src/components/common/Dropdown';

import { Chart } from './components/Chart';
import { ButtonBase } from 'src/components/common/ButtonBase';
import { Loader } from 'src/components/common/Loader';
import { NoDataPlaceholder } from 'src/components/common/NoDataPlaceholder';
import { ForbidEditCheckbox } from 'src/components/common/ForbidEditCheckbox';

import { ScreenNames } from 'src/constants/ScreenNames';
import { Stepper } from '../../../../common/Stepper';
import { StepNames } from '../../../../../constants/stepNames';
import { toast } from 'react-toastify';

export const TargetFineTuning: React.FC = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const classes = useStyles();

  const [selectedBU, setSelectedBU] = useState('');

  const { BUAbbreviations, targetFineTuning, loading, chartLoading } = useSelector(
    (state: Store) => state.specificTargets,
  );

  const { disableEditPage } = useSelector((state: Store) => state.home);

  const goNext = () => {
    if (targetFineTuning) {
      const { additionalFields } = targetFineTuning;

      if (
        additionalFields.scoreB === null ||
        additionalFields.scoreC === null ||
        additionalFields.priceIncreaseA === null ||
        additionalFields.priceIncreaseB === null ||
        additionalFields.priceIncreaseC === null ||
        additionalFields.priceIncreaseD === null ||
        additionalFields.priceIncreaseLongTailCustomers === null
      ) {
        toast.warn('Please fill in all required fields');

        return;
      }
    }
    navigate('/configure-levels/customer-specific-targets/customer-targets');
  };

  const goBack = () => {
    navigate('/configure-levels/customer-specific-targets/business-case-assumptions');
  };

  const onUpdateAdditionalFields = useDebouncedCallback((body) => {
    dispatch(
      updateTargetFineTuning.request({
        params: {
          businessUnitAbbreviation: selectedBU,
        },
        body,
      }),
    );
  }, 300);

  const EnhancedContentContainer = useMemo(() => {
    if (!targetFineTuning && !loading) {
      return <NoDataPlaceholder text="No data yet. Please try again later." />;
    }

    return loading ? (
      <Loader isLoading={true} marginTop={40} />
    ) : (
      <div className={classes.contentContainer}>
        {targetFineTuning && (
          <>
            <Chart
              loading={chartLoading}
              data={targetFineTuning.data}
              dotLabels={targetFineTuning.dotLabels}
            />

            <AdditionalFields
              isDisabled={disableEditPage}
              onUpdateFields={onUpdateAdditionalFields}
              {...targetFineTuning.additionalFields}
            />
          </>
        )}
      </div>
    );
  }, [
    chartLoading,
    classes.contentContainer,
    disableEditPage,
    loading,
    onUpdateAdditionalFields,
    targetFineTuning,
  ]);

  useEffect(() => {
    dispatch(getBUAbbreviations.request());
  }, [dispatch]);

  useEffect(() => {
    if (BUAbbreviations.length > 0) {
      setSelectedBU(BUAbbreviations[0]);
    }
  }, [BUAbbreviations]);

  useEffect(() => {
    if (selectedBU) {
      dispatch(
        getTargetFineTuning.request({
          businessUnitAbbreviation: selectedBU,
        }),
      );
    }
  }, [dispatch, selectedBU]);

  return (
    <div>
      <Stepper
        steps={[
          {
            name: 'Touchable revenue',
            activeIndex: null,
            allCompleted: true,
          },
          {
            name: 'Campaign target',
            activeIndex: null,
            allCompleted: true,
          },
          {
            name: 'Customer-specific targets',
            activeIndex: 2,
          },
        ]}
        type={StepNames.ALL}
      />
      {BUAbbreviations.length > 0 && (
        <Dropdown
          onSelectCb={(selectedItem: string) => {
            setSelectedBU(selectedItem);
          }}
          needDisableDefault={false}
          width={220}
          dropdownItems={BUAbbreviations}
          defaultItem={BUAbbreviations[0]}
        />
      )}

      {EnhancedContentContainer}

      <Stack
        spacing={6}
        direction="row"
        marginTop={loading ? 60 : 7}
        justifyContent="space-between">
        <ForbidEditCheckbox
          showCheckbox={!!targetFineTuning}
          checkboxValue={disableEditPage}
          screenName={ScreenNames.TARGET_FINE_TUNING}
        />
        <Stack width="100%" direction="row" spacing={4} justifyContent="flex-end">
          <ButtonBase
            variant="outlined"
            title="Business case assumptions"
            onClick={goBack}
            icon="goBack"
            iconLeft={true}
          />
          <ButtonBase variant="contained" title="Customer targets" onClick={goNext} icon="next" />
        </Stack>
      </Stack>
    </div>
  );
};

import React, { memo, useContext } from 'react';
import { Switch } from 'src/components/common/Switch';
import useStyles from '../../useStyles';
import { ContractStatusOption } from 'src/types';
import { DeleteButton } from 'src/components/common/DeleteButton';

import { SurveysProvider } from '../../SurveysProvider';

import { ReactComponent as WarningIcon } from 'src/assets/icons/warning.svg';
import { SurveySettingsCategories } from '../../../../../../../constants/SurveySettingCategories';
import { EditableCell } from '../../../../../../common/EditableCell';

type Props = ContractStatusOption & {
  onChangeContractStatusOption: (value: string, id: number, touchable: boolean) => void;
  onChangeContractStatusOptionSwitch: (optionId: number, touchable: boolean) => void;
  error: boolean;
};

export const ContractStatusItem: React.FC<Props> = memo(function ContractStatusItem({
  id,
  option,
  touchable,
  onChangeContractStatusOption,
  onChangeContractStatusOptionSwitch,
  error,
}) {
  const classes = useStyles();
  const { onDeleteOption, handleCleanError, disabled } = useContext(SurveysProvider);

  const onChange = (value: string) => {
    if (error) {
      handleCleanError(id, 'contractStatusOptions');
    }

    if (value.slice(-1) === ' ') {
      return;
    }

    if (value !== option) {
      onChangeContractStatusOption(value, id, touchable);
    }
  };

  return (
    <div className={classes.listItem}>
      <div className={classes.inputContainer}>
        <EditableCell
          isInput
          buttonStyle={`${classes.input} ${disabled && classes.inputDisabled} ${
            error && classes.listItemErrorInput
          }`}
          innerInputStyle={classes.input}
          disabled={disabled}
          value={option}
          onEditCb={onChange}
        />
        {error && <WarningIcon className={classes.listItemErrorIcon} />}
      </div>

      <div className={classes.switchWrapper}>
        <Switch
          disabled={disabled}
          isChecked={touchable}
          onChangeCb={(isTouchable) => {
            onChangeContractStatusOptionSwitch(id, isTouchable);
          }}
        />
      </div>

      <DeleteButton
        disabled={disabled}
        styles={classes.deleteButton}
        onClick={() => {
          if (!disabled) {
            onDeleteOption(id, SurveySettingsCategories.contractStatusOptions);
          }
        }}
      />
    </div>
  );
});

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  addNewProject,
  deleteProject,
  editProject,
  getProjects,
  setCurrentProject,
} from 'src/modules/projects/actions';

import { Store } from 'src/types';

import { Loader } from 'src/components/common/Loader';
import { ProjectCard } from './components/ProjectCard';

import useStyles from './useStyles';

import { ReactComponent as PlusIcon } from 'src/assets/icons/plus.svg';
import { AddNewProjectModal } from './components/AddNewProjectModal';
import { DeleteProjectModal } from './components/DeleteProjectModal';
import { useNavigate } from 'react-router-dom';

export const Projects: React.FC = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, projects, buttonLoading, projectId } = useSelector(
    (state: Store) => state.projects,
  );

  const [selectedProject, setSelectedProject] = useState<null | {
    name: string;
    projectId: number;
  }>(null);

  const [addNewModalVisible, setAddNewModalVisible] = React.useState(false);
  const handleOpenAddNewModal = () => setAddNewModalVisible(true);
  const handleCloseAddNewModal = () => setAddNewModalVisible(false);

  const [deleteProjectModalVisible, setDeleteProjectModalVisible] = useState(false);
  const handleOpenDeleteModal = () => setDeleteProjectModalVisible(true);
  const handleCloseDeleteModal = () => setDeleteProjectModalVisible(false);

  const [editProjectModalVisible, setEditProjectModalVisible] = useState(false);
  const handleOpenEditModal = () => setEditProjectModalVisible(true);
  const handleCloseEditModal = () => setEditProjectModalVisible(false);

  const handleClickDelete = useCallback((projectId: number, name: string) => {
    setSelectedProject({ name, projectId });

    handleOpenDeleteModal();
  }, []);

  const handleClickEdit = useCallback((projectId: number, name: string) => {
    setSelectedProject({ name, projectId });

    handleOpenEditModal();
  }, []);

  const onSelectCurrentProject = useCallback(
    (name, id) => {
      dispatch(
        setCurrentProject({
          name,
          id,
        }),
      );

      navigate('/home/status-overview');
    },
    [dispatch, navigate],
  );

  const onDeleteProject = useCallback(() => {
    if (selectedProject) {
      dispatch(
        deleteProject.request({
          requestPayload: {
            projectId: selectedProject.projectId,
          },
          onSuccessCb: () => {
            handleCloseDeleteModal();
          },
        }),
      );
    }
  }, [dispatch, selectedProject]);

  const onEditProject = useCallback(
    (id: number, name: string) => {
      if (selectedProject) {
        dispatch(
          editProject.request({
            requestPayload: {
              id,
              name,
            },
            onSuccessCb: () => {
              handleCloseEditModal();

              onSelectCurrentProject(name, id);
            },
          }),
        );
      }
    },
    [dispatch, onSelectCurrentProject, selectedProject],
  );

  const onAddNewProject = useCallback(
    (name: string) => {
      dispatch(
        addNewProject.request({
          requestPayload: {
            body: {
              name,
            },
          },
          onSuccessCb: () => {
            handleCloseAddNewModal();
          },
        }),
      );
    },
    [dispatch],
  );

  const getAllProjects = useCallback(
    ({ pageNumber, itemsOnPage }: { itemsOnPage: number; pageNumber: number }) => {
      dispatch(
        getProjects.request({
          pageNumber,
          itemsOnPage,
        }),
      );
    },
    [dispatch],
  );

  const EnhancedProjectListComponent = useMemo(() => {
    if (loading) {
      return <Loader isLoading={true} marginTop={40} />;
    }

    return (
      <div className={classes.projectList}>
        {projects.map((project) => (
          <ProjectCard
            key={project.id}
            onEdit={handleClickEdit}
            onDelete={handleClickDelete}
            onSelectCurrentProject={onSelectCurrentProject}
            {...project}
          />
        ))}
        <div onClick={handleOpenAddNewModal} className={classes.addNewProjectContainer}>
          <PlusIcon width={40} height={40} />
        </div>
      </div>
    );
  }, [
    classes.addNewProjectContainer,
    classes.projectList,
    handleClickDelete,
    handleClickEdit,
    loading,
    onSelectCurrentProject,
    projects,
  ]);

  useEffect(() => {
    getAllProjects({
      pageNumber: 0,
      itemsOnPage: 100,
    });
  }, [getAllProjects]);

  useEffect(() => {
    if (projects.length > 0 && !projectId) {
      onSelectCurrentProject(projects[0].name, projects[0].id);
    }
  }, [onSelectCurrentProject, projectId, projects]);

  return (
    <div>
      {EnhancedProjectListComponent}
      <AddNewProjectModal
        onSave={onAddNewProject}
        loading={buttonLoading}
        handleClose={handleCloseAddNewModal}
        open={addNewModalVisible}
      />
      <AddNewProjectModal
        onEdit={onEditProject}
        loading={buttonLoading}
        selectedProject={selectedProject}
        handleClose={handleCloseEditModal}
        open={editProjectModalVisible}
      />
      <DeleteProjectModal
        selectedProject={selectedProject}
        open={deleteProjectModalVisible}
        handleClose={handleCloseDeleteModal}
        onDelete={onDeleteProject}
        loading={buttonLoading}
      />
    </div>
  );
};

import React, { memo } from 'react';
import { SurveyStatus } from 'src/types';
import { ColoredRound } from '../../../ColoredRound';
import { ButtonBase } from '../../../ButtonBase';

import useStyles from './useStyles';
import { BattleCardsStatuses } from '../../../../../constants/STATUSES';

type Props = SurveyStatus & {
  onSendBattleCards: (accManagerId: number) => void;
};

const getStatus = (status: string) => {
  if (status === BattleCardsStatuses.SENT) {
    return 'sent';
  }
  return 'not sent';
};

export const BattleCardsStatusRow: React.FC<Props> = memo(function SurveyStatusRow({
  accountManagerId,
  name,
  status,
  onSendBattleCards,
}) {
  const classes = useStyles();

  return (
    <tr>
      <td>
        <div>{name}</div>
      </td>
      <td>
        <div className={classes.statusContainer}>
          <ColoredRound status={status} />
          <div>{getStatus(status)}</div>
        </div>
      </td>
      <td>
        <div>
          <ButtonBase
            variant="contained"
            onClick={() => {
              onSendBattleCards(accountManagerId);
            }}
            title="Send battlecard"
          />
        </div>
      </td>
    </tr>
  );
});

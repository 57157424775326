import React, { memo } from 'react';
import useStyles from './useStyles';

import { week } from 'src/constants/week';

type Props = {
  selectedDay: string | null;
  onSelectDay: (day: string) => void;
};

export const WeekPicker: React.FC<Props> = memo(function WeekPicker({ selectedDay, onSelectDay }) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {week.map((item) => (
        <div
          onClick={() => {
            onSelectDay(item);
          }}
          className={`${classes.day} ${selectedDay === item && classes.selected}`}
          key={item}>
          {item[0]}
        </div>
      ))}
    </div>
  );
});

import { Modal } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import useStyles from './useStyles';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as CloseIcon } from 'src/assets/icons/Close.svg';
import { InputBase } from '../InputBase';
import { ButtonBase } from '../ButtonBase';
import { Loader } from '../Loader';
import { Store, TemplateMailResponse } from '../../../types';
import { clearTemplateForEmail, updateTemplateMail } from '../../../modules/app/actions';
import { useDispatch, useSelector } from 'react-redux';
import { scrollTo } from '../../../helpers/scrollTo';

interface MailTemplateModalProps {
  open: boolean;
  templateType: string;
  onClose: () => void;
}

export const MailTemplateModal: FC<MailTemplateModalProps> = ({ open, templateType, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading, templateMailData } = useSelector((state: Store) => state.app);

  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [data, setData] = useState<TemplateMailResponse>(templateMailData);

  useEffect(() => {
    setData(templateMailData);
  }, [templateMailData]);

  useEffect(() => {
    return () => {
      dispatch(clearTemplateForEmail());
    };
  }, []);

  const handleChange = (value: string, field: keyof TemplateMailResponse) => {
    setData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const getValidation = () => Object.values(data).every(Boolean);
  const getValidationErrors = () =>
    Object.entries(data)
      .filter(([, value]) => !value)
      .map(([key]) => key);

  const fieldIdWithError = getValidationErrors()[0];

  const handleSave = () => {
    setIsClicked(true);
    scrollTo(fieldIdWithError);
    if (getValidation()) {
      dispatch(updateTemplateMail.request({ params: { templateType: templateType }, body: data }));
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className={classes.container}>
        {loading ? (
          <div className={classes.loader}>
            <Loader color={'secondary'} size={48} isLoading={true} />
          </div>
        ) : (
          <div>
            <div className={classes.header}>
              <p className={classes.title}>Configure survey invite</p>
              <IconButton
                onClick={onClose}
                aria-label="delete"
                sx={{ display: 'flex', alignSelf: 'flex-start' }}>
                <CloseIcon width={10} height={10} />
              </IconButton>
            </div>
            <div>
              <div className={classes.item} id="subject">
                <p className={classes.label}>
                  Subject <span>*</span>
                </p>
                <InputBase
                  inputStyles={`${classes.input} ${
                    isClicked && !data?.subject && classes.borderColorRed
                  }`}
                  disabled={false}
                  onChangeCb={(value) => {
                    handleChange(value, 'subject');
                  }}
                  value={data.subject}
                  defaultValue={data.subject}
                  placeholder={'Enter subject'}
                />
              </div>
              <div className={`${classes.item} ${classes.itemSecondary}`} id="greeting">
                <div className={classes.itemInnerLeft}>
                  <p className={classes.label}>
                    Greeting <span>*</span>
                  </p>
                  <InputBase
                    inputStyles={`${classes.input} ${
                      isClicked && !data?.greeting && classes.borderColorRed
                    }`}
                    disabled={false}
                    onChangeCb={(value) => {
                      handleChange(value, 'greeting');
                    }}
                    value={data?.greeting}
                    defaultValue={data.greeting}
                    placeholder={'Enter greeting'}
                  />
                </div>
                <div className={classes.innerRight}>
                  <p className={classes.itemTitle}>Account manager name</p>
                  <p className={classes.itemText}>[Placeholder]</p>
                </div>
              </div>
              <div className={classes.item} id="intro">
                <p className={classes.label}>
                  Introduction <span>*</span>
                </p>
                <textarea
                  className={`${classes.textarea} ${
                    isClicked && !data?.intro && classes.borderColorRed
                  }`}
                  value={data?.intro}
                  placeholder={'Enter introduction text'}
                  onChange={(e) => handleChange(e.target.value, 'intro')}
                />
              </div>
              <div className={classes.item}>
                <p className={classes.itemTitle}>Link</p>
                <p className={classes.itemText}>[Placeholder]</p>
              </div>
              <div className={classes.item} id="body">
                <p className={classes.label}>
                  Body <span>*</span>
                </p>
                <textarea
                  className={`${classes.textarea} ${
                    isClicked && !data?.body && classes.borderColorRed
                  }`}
                  value={data?.body}
                  placeholder={'Enter body text'}
                  onChange={(e) => handleChange(e.target.value, 'body')}
                />
              </div>
              <div className={classes.item}>
                <p className={classes.itemTitle}>Password</p>
                <p className={classes.itemText}>[Placeholder]</p>
              </div>
              <div className={classes.item} id="closing">
                <p className={classes.label}>
                  Closing <span>*</span>
                </p>
                <textarea
                  className={`${classes.textarea} ${
                    isClicked && !data?.closing && classes.borderColorRed
                  }`}
                  value={data?.closing}
                  placeholder={'Enter closing text'}
                  onChange={(e) => handleChange(e.target.value, 'closing')}
                />
              </div>
            </div>
            <div className={classes.buttons}>
              <ButtonBase
                inlineStyle={{ fontWeight: 700 }}
                onClick={onClose}
                variant="outlined"
                title="Cancel"
                loadingColor="secondary"
              />
              <ButtonBase onClick={handleSave} variant="contained" title="Save" />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

import axios, { AxiosInstance as AxiosInstanceType, AxiosRequestConfig } from 'axios';
import { Store } from 'redux';
import { changeRoleForRedirectToUnauthorized, resetStore } from 'src/modules/app/actions';
import { dispatch } from 'src/store';

import { Store as AppStore } from 'src/types';
import customHistory from '../helpers/history';
import { ROLES } from '../constants/ROLES';
import { AUTH_ADMIN_URL } from './constants';

type AxiosInstance = {
  instance: AxiosInstanceType | null;
  requestsConfigsQueue: Promise<AxiosRequestConfig>[];
  create: (store: Store) => void;
};

export const axiosInstance: AxiosInstance = {
  instance: null,
  requestsConfigsQueue: [],
  create(store) {
    this.instance = axios.create({
      baseURL: '/api',
      // baseURL: 'https://pic.businessapps-stg.simon-kucher.com/api',
      timeout: 240000000,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      transformRequest: (requestData) => {
        return requestData instanceof FormData ? requestData : JSON.stringify(requestData);
      },
      validateStatus: (status) => {
        return (status >= 200 && status < 300) || status === 401;
      },
    });

    this.instance.interceptors.request.use(
      (config) => {
        const appStore: AppStore = store.getState();

        if (appStore.app.token && config.headers) {
          config.headers.Authorization = `Bearer ${appStore.app.token}`;
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    this.instance.interceptors.response.use(
      async (response) => {
        if (response?.status === 403) {
          const appStore: AppStore = store.getState();

          const path =
            appStore.app.currentRole === ROLES.ROLE_ACCOUNT_MANAGER
              ? '/login'
              : (AUTH_ADMIN_URL as string);

          dispatch(resetStore());
          customHistory.push(path);

          return Promise.reject(response);
        }

        if (response?.status === 401) {
          const path = '/unauthorized';
          customHistory.push(path);
          dispatch(changeRoleForRedirectToUnauthorized(ROLES.NO_ROLE));
        }
        return response;
      },
      (error) => {
        if (error.response?.status === 403) {
          const appStore: AppStore = store.getState();
          if (appStore.app.currentRole === ROLES.ROLE_ACCOUNT_MANAGER) {
            window.location.href = appStore.app.managerLoginLink as string;
          } else if (appStore.app.currentRole === ROLES.ROLE_ADMIN) {
            const path = AUTH_ADMIN_URL as string;
            customHistory.push(path);
          } else {
            const path = AUTH_ADMIN_URL as string;
            customHistory.push(path);
          }

          dispatch(resetStore());

          return Promise.reject(error.response);
        }
        return Promise.reject(error);
      },
    );
  },
};

import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../../constants/COLORS';

const useStyles = makeStyles(() => ({
  dropdowns: {
    padding: '15px 0',
    display: 'flex',
  },
  dropdown: {
    marginRight: 25,
  },
  dropdownButton: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: COLORS.gray11,
      borderRadius: 0,
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: COLORS.black2,
    },
  },
}));

export default useStyles;

import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    display: 'flex',
    width: '28%',
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 8,
  },
  title: {
    color: COLORS.black6,
    fontSize: 16,
  },
  titleSelected: {
    fontWeight: 700,
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: 100,
  },
  circleWithBorder: {
    border: `1px solid ${COLORS.gray26}`,
  },
  icon: {
    width: 30,
    height: 30,
  },
  bottomLine: {
    position: 'absolute',
    bottom: -13,
    width: '100%',
    height: 2,
    backgroundColor: COLORS.red1,
    borderRadius: 5,
  },
  bottomGrayLine: {
    position: 'absolute',
    width: '2000px',
    height: 1,
    backgroundColor: COLORS.gray27,
    bottom: -14,
    right: -20,
  },
  numberRound: {
    width: 30,
    height: 30,
    borderRadius: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${COLORS.gray26}`,
    fontSize: 16,
    fontWeight: 700,
    color: COLORS.gray26,
  },
}));

export default useStyles;

import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const headerH = 90;
const tabsH = 48;
const marginT = 20;
const paddingT = 90 + 24;
const dropdownsH = 66;

const totalH = headerH + tabsH + marginT + paddingT + dropdownsH;

const useStyles = makeStyles(() => ({
  container: {
    overflowX: 'auto',
    overflowY: 'hidden',
    height: `calc(100vh - ${totalH}px)`,
    paddingTop: 70,
  },
  tooltipContainer: {
    backgroundColor: COLORS.white1,
    border: `1px solid ${COLORS.gray1}`,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 6,
    padding: '10px 12px',
    fontSize: 16,
    color: COLORS.black1,
  },
  emptyArrayContainer: {
    display: 'grid',
    paddingTop: 0,
    height: `calc(100vh - 480px)`,
    marginTop: 70,
    gridTemplateColumns: 'repeat(7, 1fr)',
    borderBottom: `4px solid ${COLORS.gray20}`,
    borderLeft: `4px solid ${COLORS.gray20}`,
    borderRight: `4px solid ${COLORS.gray20}`,
    '& :first-child': {
      backgroundColor: 'transparent',
    },
  },
  emptyArrayItem: {
    width: 4,
    backgroundColor: COLORS.gray21,
  },
  legend: {
    display: 'flex',
    marginLeft: 50,
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 50,
  },
  legendItemLine: {
    height: 4,
    width: 38,
    backgroundColor: COLORS.blue1,
    marginRight: 8,
  },
  legendItemCircle: {
    height: 18,
    width: 18,
    border: `2px solid ${COLORS.gray16}`,
    borderRadius: '50%',
    marginRight: 8,
  },
}));

export default useStyles;

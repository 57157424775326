import React, { memo, useCallback } from 'react';
import useStyles from './useStyles';
import { AdditionalFields as AdditionalFieldsType, TargetFineTuningRequestBody } from 'src/types';
import { numberSeparator } from 'src/helpers/numberSeparator';
import { EditableCell } from '../../../../../../common/EditableCell';

type Props = AdditionalFieldsType & {
  onUpdateFields: (body: TargetFineTuningRequestBody) => void;
  isDisabled: boolean;
};

export const AdditionalFields: React.FC<Props> = memo(function AdditionalFields({
  scoreB,
  scoreA,
  scoreC,
  scoreD,
  priceIncreaseA,
  priceIncreaseB,
  priceIncreaseC,
  priceIncreaseD,
  grossImpact,
  netImpact,
  targetImpact,
  onUpdateFields,
  priceIncreaseLongTailCustomers,
  isDisabled,
}) {
  const classes = useStyles();

  const getInputValue = useCallback((value: number) => {
    if (value === 0) {
      return '0';
    }

    if (!value) {
      return '';
    }

    return value.toString();
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.box}>
        <div className={classes.row}>
          <div />
          {['a', 'b', 'c', 'd'].map((item) => (
            <div className={classes.circleWrapper} key={item}>
              <div className={classes.circle}>{item}</div>
            </div>
          ))}
        </div>

        <div className={classes.row}>
          <div>
            <h4 className={classes.text}>Score</h4>
          </div>
          <div>
            <p className={classes.scoreNumberText}>{scoreA}</p>
          </div>
          <div>
            <EditableCell
              isInput
              buttonStyle={classes.input}
              innerInputStyle={classes.input}
              disabled={isDisabled}
              value={scoreB ? scoreB.toString() : ''}
              onEditCb={(value) => {
                onUpdateFields({
                  scoreB: +value,
                });
              }}
            />
          </div>

          <div>
            <EditableCell
              isInput
              buttonStyle={classes.input}
              innerInputStyle={classes.input}
              disabled={isDisabled}
              value={scoreC ? scoreC.toString() : ''}
              onEditCb={(value) => {
                onUpdateFields({
                  scoreC: +value,
                });
              }}
            />
          </div>

          <div>
            <p className={classes.scoreNumberText}>{scoreD}</p>
          </div>
        </div>

        <div className={classes.row}>
          <div>
            <h4 className={classes.text}>Price Increase Value</h4>
          </div>
          <div>
            <EditableCell
              isInput
              buttonStyle={classes.input}
              innerInputStyle={classes.input}
              suffix="%"
              disabled={isDisabled}
              value={getInputValue(priceIncreaseA)}
              onEditCb={(value) => {
                onUpdateFields({
                  priceIncreaseA: +value,
                });
              }}
            />
          </div>
          <div>
            <EditableCell
              isInput
              buttonStyle={classes.input}
              innerInputStyle={classes.input}
              suffix="%"
              disabled={isDisabled}
              value={getInputValue(priceIncreaseB)}
              onEditCb={(value) => {
                onUpdateFields({
                  priceIncreaseB: +value,
                });
              }}
            />
          </div>
          <div>
            <EditableCell
              isInput
              buttonStyle={classes.input}
              innerInputStyle={classes.input}
              suffix="%"
              disabled={isDisabled}
              value={getInputValue(priceIncreaseC)}
              onEditCb={(value) => {
                onUpdateFields({
                  priceIncreaseC: +value,
                });
              }}
            />
          </div>
          <div>
            <EditableCell
              isInput
              buttonStyle={classes.input}
              innerInputStyle={classes.input}
              suffix="%"
              disabled={isDisabled}
              value={getInputValue(priceIncreaseD)}
              onEditCb={(value) => {
                onUpdateFields({
                  priceIncreaseD: +value,
                });
              }}
            />
          </div>
        </div>
        <div className={classes.secondTableRow}>
          <div />
          <div>
            <h4 className={classes.text}>Price increase for long-tail customers</h4>
          </div>
          <div style={{ justifySelf: 'center' }}>
            <EditableCell
              isInput
              buttonStyle={classes.input}
              innerInputStyle={classes.input}
              suffix="%"
              disabled={isDisabled}
              value={getInputValue(priceIncreaseLongTailCustomers)}
              onEditCb={(value) => {
                onUpdateFields({
                  priceIncreaseLongTailCustomers: +value,
                });
              }}
            />
          </div>
        </div>
      </div>
      <div style={{ paddingRight: '6.4%' }} className={classes.box}>
        <div className={classes.secondTableRow}>
          <div />
          <div className={classes.text}>Gross impact</div>
          <div className={classes.notEditableBlock}>
            {grossImpact ? numberSeparator(grossImpact) : 0}
          </div>
        </div>
        <div className={classes.secondTableRow}>
          <div />
          <div className={classes.text}>Net impact</div>
          <div className={classes.notEditableBlock}>
            {netImpact ? numberSeparator(netImpact) : 0}
          </div>
        </div>
        <div className={classes.secondTableRow}>
          <div />
          <div className={classes.text}>Target impact</div>
          <div className={classes.notEditableBlock}>
            {targetImpact ? numberSeparator(targetImpact) : 0}
          </div>
        </div>
      </div>
    </div>
  );
});

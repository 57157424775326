import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'src/types';
import { Loader } from 'src/components/common/Loader';

import { PricePotentialProvider } from './PricePotentialProvider';

import {
  editPricePotentialIndicator,
  getPricePotential,
  updateCostDriver,
  updatePricePotentialCategories,
  deletePricePotentialCategory,
  deletePricePotentialIndicator,
} from 'src/modules/campaignTarget/actions';

import useStyles from './useStyles';
import { PricePotentialIndicators } from './components/PricePotentialIndicators';
import { CostDriversDnD } from './components/CostDriversDnD';
import { TextButton } from '../TextButton';

export const PricePotentialContainer: React.FC = memo(function PricePotentialContainer() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { pricePotential, loading } = useSelector((state: Store) => state.campaignTarget);

  const { disableEditPage } = useSelector((state: Store) => state.home);

  const onAddNewIndicator = useCallback(
    ({
      category,
      indicators,
      id,
    }: {
      id: number;
      category: string;
      indicators: {
        indicator: string;
      }[];
    }) => {
      dispatch(
        updatePricePotentialCategories.request({
          categories: [
            {
              category,
              id,
              indicators,
            },
          ],
        }),
      );
    },
    [dispatch],
  );

  const onAddNewCategory = useCallback(() => {
    if (!disableEditPage) {
      dispatch(
        updatePricePotentialCategories.request({
          categories: [
            {
              category: '[Category]',
              indicators: [
                {
                  indicator: '[Indicator]',
                },
              ],
            },
          ],
        }),
      );
    }
  }, [disableEditPage, dispatch]);

  const onChangeSelectedIndicator = useCallback(
    ({
      id,
      category,
      indicators,
    }: {
      id: number;
      category: string;
      indicators: {
        indicator: string;
        id: number;
      }[];
    }) => {
      dispatch(
        editPricePotentialIndicator.request({
          categories: [
            {
              id,
              category,
              indicators,
            },
          ],
        }),
      );
    },
    [dispatch],
  );

  const onChangePricePotentialCategoryName = useCallback(
    ({ id, category }: { id: number; category: string }) => {
      dispatch(
        editPricePotentialIndicator.request({
          categories: [
            {
              id,
              category,
            },
          ],
        }),
      );
    },
    [dispatch],
  );

  const onAddNewCostDriver = useCallback(
    (position: number, costDriverName: string) => {
      dispatch(
        updateCostDriver.request({
          requestBody: {
            costDrivers: [{ position, costDriverName }],
          },
          needUpdateReducer: true,
        }),
      );
    },
    [dispatch],
  );

  const onEditCostDriver = useCallback(
    (costDrivers: { position: number; costDriverName: string; id: number }[]) => {
      dispatch(
        updateCostDriver.request({
          requestBody: {
            costDrivers,
          },
          needUpdateReducer: true,
        }),
      );
    },
    [dispatch],
  );

  const onDeleteCategory = useCallback(
    (categoryId) => {
      dispatch(deletePricePotentialCategory.request({ categoryId }));
    },
    [dispatch],
  );

  const onDeleteIndicator = useCallback(
    (indicatorId, categoryId) => {
      dispatch(deletePricePotentialIndicator.request({ indicatorId, categoryId }));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(getPricePotential.request());
  }, [dispatch]);

  return (
    <PricePotentialProvider.Provider value={{ disableEditPage }}>
      <div className={classes.container}>
        <div className={`${classes.box} ${classes.box1}`}>
          {loading || !pricePotential ? (
            <Loader marginTop={15} isLoading={loading} />
          ) : (
            <>
              <h3 className={classes.subtitle}>Cost drivers</h3>
              <div className={classes.indicatorList}>
                <CostDriversDnD
                  onEditCostDriver={onEditCostDriver}
                  onAddNewCostDriver={onAddNewCostDriver}
                  costDrivers={pricePotential.costDrivers}
                />
              </div>
            </>
          )}
        </div>
        <div className={`${classes.box} ${classes.box2}`}>
          {loading || !pricePotential ? (
            <Loader marginTop={15} isLoading={loading} />
          ) : (
            <>
              <h3 className={classes.subtitle}>Price potential indicators</h3>
              <div className={classes.categoriesContainer}>
                {pricePotential.categories.length === 0 && (
                  <div className={classes.addCategoryButtonContainer}>
                    <TextButton onClick={onAddNewCategory} title="Add category" />
                  </div>
                )}
                <div className={classes.indicatorList}>
                  {pricePotential.categories.map((category, index, array) => (
                    <PricePotentialIndicators
                      onDeleteIndicator={onDeleteIndicator}
                      onDeleteCategory={onDeleteCategory}
                      onChangePricePotentialCategoryName={onChangePricePotentialCategoryName}
                      onChangeSelectedIndicator={onChangeSelectedIndicator}
                      onAddNewCategory={array.length - 1 === index ? onAddNewCategory : undefined}
                      onAddNewIndicator={onAddNewIndicator}
                      key={category.id}
                      {...category}
                    />
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </PricePotentialProvider.Provider>
  );
});

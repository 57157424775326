import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  button: {
    '& button': {
      width: 200,
    },
  },
}));

export default useStyles;

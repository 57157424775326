import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  container: {
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    marginBottom: 16,
    borderRadius: 10,
    padding: 24,
    height: 315,
  },
  title: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 700,
  },
  main: {
    marginTop: 24,
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export default useStyles;

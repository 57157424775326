import React from 'react';
import { Navigate } from 'react-router-dom';

export const PrivateRoute = ({
  children,
  isAuthenticated,
}: {
  isAuthenticated: boolean;
  children: JSX.Element;
}) => {
  return isAuthenticated ? children : <Navigate to="/login" />;
};

import React, { memo, useState } from 'react';
import { ReactComponent as DeleteBasket } from 'src/assets/icons/delete-basket.svg';

import { COLORS } from 'src/constants/COLORS';
import useStyles from './useStyles';

type Props = {
  styles?: string;
  onClick: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  disabled?: boolean;
};

export const DeleteButton: React.FC<Props> = memo(function DeleteButton({
  styles,
  onClick,
  disabled,
}) {
  const [isMouseOver, setIsMouseOver] = useState(false);

  const classes = useStyles();

  return (
    <DeleteBasket
      onClick={onClick}
      onMouseOver={() => {
        setIsMouseOver(true);
      }}
      onMouseLeave={() => {
        setIsMouseOver(false);
      }}
      className={`${classes.icon} ${disabled && classes.iconDisabled} ${styles && styles}`}
      fill={disabled ? COLORS.gray3 : isMouseOver ? COLORS.red1 : COLORS.black4}
    />
  );
});

import React from 'react';
import { ButtonBase, ButtonProps } from 'src/components/common/ButtonBase';
import { styled } from '@mui/material';

const Input = styled('input')({
  display: 'none',
});

export const UploadButton: React.FC<
  ButtonProps & { onChange: (e: React.ChangeEvent<HTMLInputElement>) => void }
> = ({ onChange, ...props }) => {
  return (
    <label htmlFor="contained-button-file">
      <Input
        disabled={props.disabled}
        onChange={onChange}
        accept=".xlsx, .xls, .csv"
        id="contained-button-file"
        multiple
        type="file"
      />
      <ButtonBase component="span" {...props} />
    </label>
  );
};

import React, { useCallback, useEffect, useMemo } from 'react';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { COLORS } from 'src/constants/COLORS';

import { Store } from 'src/types';
import { DEFAULT_COUNT_OF_ITEMS } from 'src/constants/dafaultCountOfItems';

import {
  downloadAccountManagersListExcel,
  exportAccountManagersListExcel,
  getAccountManagersList as getAccountMangersListAction,
} from 'src/modules/TouchableRevenue/actions';

import { ScreenNames } from 'src/constants/ScreenNames';

import { ButtonBase } from 'src/components/common/ButtonBase';
import { Pagination } from 'src/components/common/Pagination';
import { UploadButton } from 'src/components/common/UploadButton';
import { Loader } from 'src/components/common/Loader';
import { NoDataPlaceholder } from 'src/components/common/NoDataPlaceholder';
import { ForbidEditCheckbox } from 'src/components/common/ForbidEditCheckbox';
import { AccountManagersTable } from './components/AccountManagersTable';

import useStyles from './useStyles';
import { Stepper } from '../../../../common/Stepper';
import { StepNames } from '../../../../../constants/stepNames';

export const AccountManagerList: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_COUNT_OF_ITEMS);

  const { accountManagers, loading } = useSelector((state: Store) => state.touchableRevenue);

  const { disableEditPage } = useSelector((state: Store) => state.home);

  const navigate = useNavigate();

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleChangeRowsPerPage = (value: number) => {
    setRowsPerPage(value);
    setPage(0);
  };

  const goToPage = useCallback((page: number) => {
    setPage(page);
  }, []);

  const onDownloadExcel = useCallback(() => {
    dispatch(downloadAccountManagersListExcel.request());
  }, [dispatch]);

  const goNext = useCallback(() => {
    navigate('/configure-levels/touchable-revenue/customer-registry');
  }, [navigate]);

  const goBack = useCallback(() => {
    navigate('/configure-levels/touchable-revenue/business-units');
  }, [navigate]);

  const onUploadExcel = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const fileList = e.target.files;

      if (fileList) {
        dispatch(
          exportAccountManagersListExcel.request({
            file: fileList[0],
            params: {
              itemsOnPage: rowsPerPage,
            },
          }),
        );

        e.target.value = '';
      }
    },
    [dispatch, rowsPerPage],
  );

  const getAccountManagersList = useCallback(
    ({
      sortBy,
      pageNumber,
      itemsOnPage,
    }: {
      sortBy: string;
      itemsOnPage: number;
      pageNumber: number;
    }) => {
      dispatch(
        getAccountMangersListAction.request({
          pageNumber,
          itemsOnPage,
          sortBy,
          sortOrder: 'asc',
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    getAccountManagersList({
      sortBy: 'name',
      pageNumber: page === 0 ? page : page - 1,
      itemsOnPage: rowsPerPage,
    });
  }, [getAccountManagersList, page, rowsPerPage]);

  const EnhancedContentContainer = useMemo(() => {
    if (!accountManagers || (accountManagers && accountManagers.data.length === 0)) {
      return <NoDataPlaceholder />;
    }

    return (
      <>
        <AccountManagersTable disableEditing={disableEditPage} data={accountManagers.data} />

        <Pagination
          totalPages={accountManagers.pageable.totalPages}
          setRowPerPage={handleChangeRowsPerPage}
          setPage={handleChangePage}
          page={page}
          rowPerPage={rowsPerPage}
          marginTop={4}
          goToPage={goToPage}
        />
      </>
    );
  }, [accountManagers, disableEditPage, goToPage, page, rowsPerPage]);

  return (
    <div className={classes.container}>
      <Stack direction="row" columnGap={3} marginTop={4}>
        <ButtonBase
          onClick={onDownloadExcel}
          title="Download Excel template"
          iconColor={COLORS.red1}
          variant="outlined"
        />
        <UploadButton
          variant="outlined"
          disabled={loading || disableEditPage}
          iconColor={COLORS.blue1}
          onChange={onUploadExcel}
          title="Upload from Excel"
        />
      </Stack>
      <Stepper steps={{ name: 'Setup', activeIndex: 1 }} type={StepNames.SETUP} />

      {loading && (
        <div className={classes.loaderContainer}>
          <Loader isLoading={true} />
        </div>
      )}

      {EnhancedContentContainer}

      <Stack spacing={6} direction="row" marginTop={8} justifyContent="space-between">
        <ForbidEditCheckbox
          showCheckbox={accountManagers ? accountManagers.data.length > 0 : false}
          checkboxValue={disableEditPage}
          screenName={ScreenNames.SETTINGS_ACCOUNT_MANAGER_LIST}
        />
        <Stack width="100%" direction="row" spacing={4} justifyContent="flex-end">
          <ButtonBase
            iconColor={COLORS.red1}
            variant="outlined"
            title="Business units"
            onClick={goBack}
            icon="goBack"
            iconLeft={true}
          />
          <ButtonBase
            iconColor={COLORS.red1}
            variant="contained"
            title="Customer registry"
            onClick={goNext}
            icon="next"
          />
        </Stack>
      </Stack>
    </div>
  );
};

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  container: {
    width: '18px',
    height: '18px',
    borderRadius: '50%',
  },
}));

export default useStyles;

import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
  },
  title: {
    fontSize: 16,
    lineHeight: '19px',
    fontWeight: 700,
    color: COLORS.gray26,
  },
  chartContainer: {
    marginTop: 90,
    marginLeft: 30,
    height: 100,
  },
  chart: {
    display: 'flex',
    width: 160,
    height: 12,
    transform: 'rotate(90deg)',
    position: 'relative',
  },
  chartTextTop: {
    position: 'absolute',
    transform: 'rotate(-90deg)',
    top: 50,
    left: -30,
    maxWidth: 84,
    fontSize: 12,
    lineHeight: '14px',
    textAlign: 'right',
  },
  chartTextBottom: {
    position: 'absolute',
    transform: 'rotate(-90deg)',
    top: 62,
    left: 95,
    width: 100,
    fontSize: 12,
    lineHeight: '14px',
    textAlign: 'right',
  },
  indicator: {
    position: 'absolute',
    height: 25,
    width: 2,
    backgroundColor: COLORS.gray26,
    top: '-70%',
  },
  indicatorText: {
    position: 'absolute',
    transform: 'rotate(-90deg)',
    left: -97,
    bottom: 130,
    width: 200,
    fontSize: 16,
    lineHeight: '19px',
    fontWeight: 700,
  },
  item: {
    height: '100%',
    flex: 1,
    '&:first-child': {
      borderRadius: '8px 0 0 8px',
    },
    '&:nth-child(10)': {
      borderRadius: '0 8px 8px 0',
    },
  },
}));

export default useStyles;

import React, { useEffect } from 'react';
import MuiSwitch from '@mui/material/Switch';

type Props = {
  onChangeCb?: (value: boolean) => void;
  isChecked?: boolean;
  disabled?: boolean;
};

export const Switch: React.FC<Props> = ({ onChangeCb, disabled, isChecked = true }) => {
  const [checked, setChecked] = React.useState(isChecked);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);

    onChangeCb && onChangeCb(event.target.checked);
  };

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  return (
    <MuiSwitch
      disabled={disabled}
      color="secondary"
      checked={checked}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'controlled' }}
    />
  );
};

import React, { memo, useCallback, useContext, useMemo } from 'react';
import { DateTimePicker } from 'src/components/common/DateTimePicker';
import { Dropdown } from 'src/components/common/Dropdown';
import {
  SurveyResultSetAdditionalFields,
  SurveyResultSetTouchableOptionRequestBody,
  TouchableOption,
} from 'src/types';
import { EmptyCell } from '../EmptyCell';

import { SurveyResultProvider, SurveyResultProviderTypes } from '../../SurveyResultProvider';
import { COLORS } from '../../../../../../../constants/COLORS';

type Props = {
  customerId: number;
  businessUnitId?: number;
  contractExpirationDate: string;
  touchableOptions: TouchableOption[];
  touchableName: string;
  lastPriceIncrease: string;
  setTouchableOption: (body: SurveyResultSetTouchableOptionRequestBody) => void;
  setAdditionalFields: (body: SurveyResultSetAdditionalFields) => void;
  isDisabled?: boolean;
  isEven: boolean;
  isGroup: boolean;
};

export const GeneralInformationColumns: React.FC<Props> = memo(function GeneralInformationColumns({
  customerId,
  contractExpirationDate,
  touchableOptions,
  lastPriceIncrease,
  touchableName,
  setTouchableOption,
  setAdditionalFields,
  businessUnitId,
  isDisabled,
  isEven,
  isGroup,
}) {
  const { disableEditPage, additionalFields } = useContext(
    SurveyResultProvider,
  ) as SurveyResultProviderTypes;

  const onSelectContractExpirationDate = useCallback(
    (date) => {
      if (businessUnitId) {
        setAdditionalFields({
          businessUnitData: {
            customerId,
            businessUnitId,
            contractExpirationDate: date,
            isGroup,
          },
        });
      } else {
        setAdditionalFields({
          customerData: {
            customerId,
            contractExpirationDate: date,
            isGroup,
          },
        });
      }
    },
    [businessUnitId, customerId, isGroup, setAdditionalFields],
  );

  const onSelectLastPriceIncreaseDate = useCallback(
    (date) => {
      if (businessUnitId) {
        setAdditionalFields({
          businessUnitData: {
            customerId,
            businessUnitId,
            lastPriceIncrease: date,
            isGroup,
          },
        });
      } else {
        setAdditionalFields({
          customerData: {
            customerId,
            lastPriceIncrease: date,
            isGroup,
          },
        });
      }
    },
    [businessUnitId, customerId, isGroup, setAdditionalFields],
  );

  const onSelectTouchableOption = useCallback(
    (optionId) => {
      if (businessUnitId) {
        setTouchableOption({
          businessUnitData: {
            customerId,
            businessUnitId,
            optionId: Number(optionId),
            isGroup,
          },
        });
      } else {
        setTouchableOption({
          customerData: {
            customerId,
            optionId: Number(optionId),
            isGroup,
          },
        });
      }
    },
    [businessUnitId, customerId, isGroup, setTouchableOption],
  );

  const EnhancedCellsComponent = useMemo(() => {
    if (isDisabled) {
      return (
        <>
          {additionalFields && additionalFields.showContractExpirationDate && (
            <EmptyCell isEven={isEven}>Empty</EmptyCell>
          )}
          <EmptyCell isEven={isEven}>Empty</EmptyCell>
          {additionalFields && additionalFields.showLastPriceIncreaseDate && (
            <EmptyCell isEven={isEven}>Empty</EmptyCell>
          )}
        </>
      );
    }

    return (
      <>
        {additionalFields && additionalFields.showContractExpirationDate && (
          <td style={{ width: '80px', backgroundColor: isEven ? COLORS.white1 : COLORS.blue13 }}>
            <DateTimePicker
              disabled={disableEditPage}
              defaultDate={contractExpirationDate}
              onChangeCb={onSelectContractExpirationDate}
            />
          </td>
        )}

        {additionalFields && additionalFields.showLastPriceIncreaseDate && (
          <td style={{ backgroundColor: isEven ? COLORS.white1 : COLORS.blue13 }}>
            <DateTimePicker
              disabled={disableEditPage}
              defaultDate={lastPriceIncrease}
              onChangeCb={onSelectLastPriceIncreaseDate}
            />
          </td>
        )}

        <td
          style={{
            backgroundColor: isEven ? COLORS.white1 : COLORS.blue13,
            borderRight: `1px solid ${COLORS.gray3}`,
          }}>
          <div>
            <Dropdown
              disabled={disableEditPage}
              onSelectCb={onSelectTouchableOption}
              width={'100%'}
              height={40}
              dropdownItems={[]}
              dropdownItemsWithObjects={touchableOptions.map((item) => ({
                value: item.option,
                id: item.optionId,
              }))}
              placeholder="Please select"
              defaultItem={touchableName}
            />
          </div>
        </td>
      </>
    );
  }, [
    additionalFields,
    contractExpirationDate,
    disableEditPage,
    isDisabled,
    isEven,
    lastPriceIncrease,
    onSelectContractExpirationDate,
    onSelectLastPriceIncreaseDate,
    onSelectTouchableOption,
    touchableName,
    touchableOptions,
  ]);

  return <>{EnhancedCellsComponent}</>;
});

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '30px',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 10,
  },
}));

export default useStyles;

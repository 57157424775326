import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  container: {
    width: '31%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minWidth: 360,
    backgroundColor: COLORS.white1,
    padding: '7px 13px',
    height: 350,
  },
  topContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  chartContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: -30,
  },
  numberInput: {
    width: 120,
    height: 40,
    marginLeft: 24,
    outline: 'none',
    border: `1px solid ${COLORS.gray26}`,
    borderRadius: 8,
    fontSize: 16,
    fontWeight: 400,
    color: COLORS.black1,
    textAlign: 'center',
    '&:disabled': {
      backgroundColor: COLORS.gray2,
      color: COLORS.gray3,
      borderColor: COLORS.gray15,
    },
  },
  tooltipContainer: {
    backgroundColor: COLORS.white1,
    border: `1px solid ${COLORS.gray1}`,
    display: 'flex',
    flexDirection: 'column',
    height: 60,
    borderRadius: 6,
    padding: '10px 12px',
    fontSize: 16,
    color: COLORS.black1,
  },
}));

export default useStyles;

import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Checkbox } from '../Checkbox';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import useStyles from './useStyles';

import {
  getProgressCheckboxValue,
  getProgressShowModal,
  setProgressCheckboxValue,
  setProgressShowModal,
} from 'src/modules/home/actions';

import { ScreenNames } from 'src/constants/ScreenNames';
import { Store } from 'src/types';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    //eslint-disable-next-line
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  screenName: ScreenNames;
  checkboxValue: boolean;
  checkForEmptyFields?: () => boolean;
  showCheckbox: boolean;
  dialogText?: string;
  onSuccessCb?: () => void;
};

export const ForbidEditCheckbox: React.FC<Props> = memo(function ForbidEditCheckbox({
  screenName,
  checkboxValue,
  checkForEmptyFields,
  showCheckbox,
  dialogText,
  onSuccessCb,
}) {
  const { progressShowModal } = useSelector((state: Store) => state.home);

  const [showingModalInFuture, setShowingModalInFuture] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [isChecked, setIsChecked] = React.useState(false);

  const dispatch = useDispatch();

  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const agreeModal = () => {
    updateCheckboxValue(true);

    if (showingModalInFuture) {
      stopShowingModal();
    }

    handleClose();
  };

  const disagreeModal = () => {
    setIsChecked(false);

    handleClose();
  };

  const updateCheckboxValue = useCallback(
    (value) => {
      dispatch(
        setProgressCheckboxValue.request({
          body: {
            screenName,
            checkboxValue: value,
          },
          onSuccessCb: () => {
            onSuccessCb && onSuccessCb();

            // setIsChecked(false);
          },
          onFailCb: () => {
            setIsChecked(true);
            setIsChecked(false);
          },
        }),
      );
    },
    [dispatch, onSuccessCb, screenName],
  );

  const handleCheckboxClick = useCallback(
    (value) => {
      if (checkboxValue) {
        updateCheckboxValue(value);

        return;
      }

      const isValid = checkForEmptyFields && checkForEmptyFields();

      if (isValid === false) {
        setIsChecked(true);
        setTimeout(() => {
          setIsChecked(false);
        }, 0);
      }

      if (isValid || isValid === undefined) {
        if (progressShowModal) {
          handleClickOpen();

          setIsChecked(true);

          return;
        }

        updateCheckboxValue(value);
      }
    },
    [checkForEmptyFields, checkboxValue, progressShowModal, updateCheckboxValue],
  );

  const stopShowingModal = useCallback(() => {
    dispatch(
      setProgressShowModal.request({
        showModal: false,
      }),
    );
  }, [dispatch]);

  const handleShowingModalCheckbox = useCallback((value) => {
    setShowingModalInFuture(value);
  }, []);

  useEffect(() => {
    dispatch(getProgressShowModal.request());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getProgressCheckboxValue.request({
        screenName,
      }),
    );
  }, [dispatch, screenName]);

  useEffect(() => {
    setIsChecked(checkboxValue);
  }, [checkboxValue]);

  return showCheckbox ? (
    <>
      <div className={classes.checkboxContainer}>
        <Checkbox
          // disabled={isChecked}
          onChangeCb={handleCheckboxClick}
          width={30}
          isChecked={isChecked}
        />
        <span className={classes.checkboxText}>Mark as complete</span>
      </div>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={disagreeModal}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle>{'Are you sure?'}</DialogTitle>
        <DialogContent>
          <p>
            {dialogText
              ? dialogText
              : 'Are you sure you want to mark this page as done? Inputs cannot be edited afterwards.'}
          </p>
          <div className={classes.modalCheckboxContainer}>
            <Checkbox id="checkbox-1" onChangeCb={handleShowingModalCheckbox} isChecked={false} />
            <label className={classes.label} htmlFor="checkbox-1">
              Don't ask me again.
            </label>
          </div>
        </DialogContent>

        <DialogActions sx={{ columnGap: 2 }}>
          <Button variant="outlined" onClick={disagreeModal}>
            Disagree
          </Button>
          <Button variant="outlined" onClick={agreeModal}>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  ) : null;
});

import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  header: {
    width: '100%',
    minWidth: '1200px',
    background: COLORS.white4,
    height: '90px',
    display: 'flex',
    paddingLeft: '20px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #D9D8DA66',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  image: {
    marginRight: 24,
  },
  title: {
    display: 'flex',
    color: COLORS.black6,
    fontWeight: 400,
    fontSize: 24,
    '& span': {
      fontWeight: 700,
    },
  },
  titleBold: {
    fontWeight: 700,
  },
  secondTitle: {
    marginRight: 10,
  },
  verticalLine: {
    display: 'block',
    height: 24,
    width: 1,
    backgroundColor: COLORS.gray20,
    margin: '0 16px',
  },
  categoryName: {
    textTransform: 'capitalize',
    color: COLORS.gray24,
  },
}));

export default useStyles;

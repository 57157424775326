import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../constants/COLORS';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiOutlinedInput-root:hover': {
      '& svg': {
        fill: COLORS.blue2,
      },
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      '& svg': {
        fill: COLORS.blue2,
      },
    },
    '& .MuiOutlinedInput-input': {
      color: COLORS.black1,
      fontSize: '16px',
      paddingLeft: '16px',
      paddingRight: '16px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: COLORS.gray26,
      borderRadius: 8,
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: COLORS.blue2,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: COLORS.blue11,
      borderWidth: '1px',
    },
    '& label': {
      fontSize: '12px',
      top: -4,
      left: -4,
    },
    '& label.MuiInputLabel-shrink': {
      paddingRight: 3,
      top: 0,
      fontSize: '15px',
    },
    '& legend > span': {
      padding: 0,
    },
    '& .MuiOutlinedInput-root.Mui-disabled > .MuiOutlinedInput-notchedOutline': {
      borderColor: COLORS.gray12,
    },
  },
  multipleDropdown: {
    width: 220,
  },
  label: {
    marginBottom: 5,
  },
  select: {
    width: 220,
    borderRadius: 8,
    '& ul': {
      backgroundColor: COLORS.white1,
      borderRadius: 8,
      border: `1px solid ${COLORS.gray25}`,
    },
    '& li': {
      fontSize: 16,
    },
  },
}));

export default useStyles;

import React, { memo } from 'react';
import { Box, Typography } from '@mui/material';
import { ButtonBase } from '../ButtonBase';
import { IconButton } from '@mui/material';
import { COLORS } from '../../../constants/COLORS';

type Props = {
  onConfirm: () => void;
  text?: string;
};

export const Popper: React.FC<Props> = memo(function Popper({
  onConfirm,
  children,
  text = 'This action will expand the table per segment view. Are you sure?',
}) {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen((previousOpen) => !previousOpen);
  };

  const onClose = () => {
    setOpen((previousOpen) => !previousOpen);
  };

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <div style={{ position: 'relative' }}>
      <IconButton type="button" onClick={handleClick}>
        {children}
      </IconButton>
      {open && (
        <Box
          sx={{
            border: `1px solid ${COLORS.gray3}`,
            width: 255,
            bgcolor: COLORS.gray19,
            position: 'absolute',
            zIndex: 11,
            borderRadius: 2,
            padding: '16px 24px',
            boxShadow: '0px 2px 10px rgba(199, 201, 201, 0.5)',
          }}>
          <Typography>{text}</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <ButtonBase
              inlineStyle={{ width: 60 }}
              variant="contained"
              title="Confirm"
              onClick={handleConfirm}
            />
            <ButtonBase
              inlineStyle={{ width: 60 }}
              variant="contained"
              title="Cancel"
              onClick={onClose}
            />
          </Box>
        </Box>
      )}
    </div>
  );
});

import React, { memo } from 'react';
import { PricePotentialMatrixCostDriver } from 'src/types';
import useStyles from './useStyles';

import { EditableCell } from '../../../../../../common/EditableCell';

type Props = {
  businessUnitId: number;
  businessUnitAbbreviation: string;
  revenue: number;
  costDrivers: PricePotentialMatrixCostDriver[];
  updateCostDriver: (body: {
    businessUnitId: number;
    costDriverId: number;
    percentage: number;
  }) => void;
  disabled: boolean;
};

export const MatrixFirstPartRow: React.FC<Props> = memo(function MatrixFirstPartRow({
  businessUnitAbbreviation,
  revenue,
  costDrivers,
  updateCostDriver,
  businessUnitId,
  disabled,
}) {
  const classes = useStyles();

  return (
    <tr className={classes.row}>
      <td>{businessUnitAbbreviation}</td>
      <td>
        <div>{revenue}</div>
      </td>
      {costDrivers.map((costDriver) => (
        <EditableCell
          buttonStyle={classes.numberInput}
          innerInputStyle={classes.numberInput}
          key={costDriver.costDriverId}
          disabled={disabled}
          value={costDriver.percentage ? costDriver.percentage.toString() : ''}
          suffix="%"
          onEditCb={(value) => {
            if (value !== '') {
              updateCostDriver({
                businessUnitId,
                costDriverId: costDriver.costDriverId,
                percentage: +value,
              });
            }
          }}
        />
      ))}
    </tr>
  );
});

import React, { FC } from 'react';
import useStyles from './useStyles';
import { battleCardColors } from 'src/constants/BATTLE_CARD_COLORS';

type NegotiationPowerProps = {
  pricePotential: number;
};

const NegotiationPower: FC<NegotiationPowerProps> = ({ pricePotential }) => {
  const classes = useStyles();

  return (
    <div className={classes.negotiationPower}>
      <div className={classes.chart}>
        {battleCardColors.map((color) => (
          <div key={color} className={classes.item} style={{ backgroundColor: color }} />
        ))}
        <div className={classes.indicator} style={{ right: `${pricePotential || 0}%` }}>
          <div className={classes.indicatorLine} />
          <div className={classes.indicatorText} style={{ left: pricePotential > 15 ? 0 : -170 }}>
            {pricePotential}% pricing potential
          </div>
        </div>
      </div>
      <div className={classes.bottom}>
        <div className={classes.bottomItem}>
          <div className={classes.bottomItemValue}>100% High</div>
          <div className={classes.bottomItemText}>Company has full power</div>
        </div>
        <div className={classes.bottomItem} />
        <div className={classes.bottomItem} />
        <div className={classes.bottomItem} />
        <div className={classes.bottomItem}>
          <div className={classes.bottomItemValue}>50% Moderate</div>
          <div className={classes.bottomItemText}>Power is evenly divided</div>
        </div>
        <div className={classes.bottomItem} />
        <div className={classes.bottomItem} />
        <div className={classes.bottomItem} />
        <div className={classes.bottomItem} />
        <div className={classes.bottomItem}>
          <div className={classes.bottomItemValue}>0% Low</div>
          <div className={classes.bottomItemText}>Customer has full power</div>
        </div>
      </div>
    </div>
  );
};

export default NegotiationPower;

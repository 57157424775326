import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  projectList: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: 40,
    rowGap: 40,
  },
  addNewProjectContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 170,
    height: 170,
    backgroundColor: COLORS.black2,
    transition: '0.3s ease',
    cursor: 'pointer',
    boxShadow: '0px 2px 10px rgba(199, 201, 201, 0.5)',
    '&:hover': {
      backgroundColor: COLORS.gray11,
    },
  },
}));

export default useStyles;

import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
  },
  boxContainer: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    height: 370,
    rowGap: 3,
  },
  boxSquare: {
    width: 'calc(50% - 2px)',
    backgroundColor: 'red',
    display: 'flex',
    flexDirection: 'column',
    padding: '63px 20px 0 39px',
    flexShrink: 0,
    flexGrow: 0,
    rowGap: 5,
    position: 'relative',
    '&:nth-child(2)': {
      alignItems: 'flex-end',
    },
    '&:nth-child(4)': {
      alignItems: 'flex-end',
      paddingTop: 15,
      paddingBottom: 48,
    },
    '&:nth-child(3)': {
      paddingTop: 15,
      paddingBottom: 48,
    },
  },
  boxSquareCircle: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: 46,
    height: 46,
    backgroundColor: COLORS.white1,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 24,
    lineHeight: '28px',
    fontWeight: 700,
  },
  axisContainer: {
    width: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    top: 24,
    left: 13,
  },
  xAxis: {
    position: 'absolute',
    left: 7.5,
    bottom: 2,
    width: '92%',
    height: 2,
    backgroundColor: COLORS.white1,
  },
  whiteArrowRight: {
    position: 'absolute',
    right: -6.5,
    bottom: -7,
  },
  highText1: {
    position: 'absolute',
    top: 25,
    left: 35,
    color: COLORS.gray5,
    fontSize: 14,
    fontWeight: 700,
  },
  lowText: {
    position: 'absolute',
    left: 35,
    bottom: 30,
    color: COLORS.black1,
    fontSize: 14,
    fontWeight: 700,
  },
  highText2: {
    position: 'absolute',
    bottom: 30,
    color: COLORS.black1,
    fontSize: 14,
    right: 35,
    fontWeight: 700,
  },
  bottomText: {
    position: 'absolute',
    bottom: -22,
    color: COLORS.black1,
    fontSize: 16,
    right: 10,
  },
  centeredCircle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    left: '50%',
    bottom: '50%',
    transform: 'translate(-50%, 50%)',
    width: 220,
    height: 220,
    borderRadius: '50%',
    backgroundColor: COLORS.white1,
  },
  textInCircle: {
    fontSize: 16,
    fontWeight: 700,
    textAlign: 'center',
    color: COLORS.black1,
    width: 128,
  },
  ellipse: {
    position: 'absolute',
    left: '50%',
    bottom: '50%',
    transform: 'translate(-50%, 48%)',
  },
  ellipseReversed: {
    position: 'absolute',
    left: '50%',
    bottom: '50%',
    transform: 'translate(-50%, 52%)',
  },
}));

export default useStyles;

import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  row: {
    height: '51px',
    backgroundColor: COLORS.white1,
    '& td': {
      paddingLeft: '24px',
      paddingRight: '24px',
      fontSize: '14px',
      color: COLORS.black1,
    },
  },
  dropdownList: {
    '& li': {
      fontSize: '16px',
      padding: '6px',
    },
  },
  commentInput: {
    width: '100%',
    height: '40px',
    borderColor: COLORS.gray15,
    padding: '0 7px',
    fontSize: '14px',
  },
  otherInput: {
    width: '100%',
    minWidth: 191,
    height: '40px',
    borderColor: COLORS.gray15,
    padding: '0 7px',
    fontSize: '14px',
  },
  cellGray: {
    backgroundColor: COLORS.gray28,
  },
  cellRightBorder: {
    position: 'relative',
    borderRight: '1px solid #B3B3BB',
  },
  popper: {
    transition: '0.3s ease-out',
    position: 'absolute',
    top: -200,
    width: 244,
    height: 200,
    borderRadius: 8,
    boxShadow: '0px 2px 10px rgba(199, 201, 201, 0.5)',
    padding: '16px 24px',
    zIndex: 15,
    backgroundColor: COLORS.white1,
  },
  popperText: {
    fontSize: 16,
    fontWeight: 400,
    color: COLORS.black1,
  },
}));

export default useStyles;

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  boxes: {
    width: '100%',
    marginTop: 30,
    display: 'flex',
    columnGap: 20,
  },
}));

export default useStyles;

import { createAction } from 'deox';
import {
  GetConfigOfSecondSurveyResponse,
  GetInfoForMonitoringScreenRequestParams,
  GetMonitoringDatesResponse,
  GetMonitoringInfoScreeResponse,
  GetMonitoringSurveyRequestParams,
  GetMonitoringSurveyResponse,
  GetNegotiationProcessRequestParams,
  GetNegotiationProcessResponse,
  GetNegotiationStatusRequest,
  GetNegotiationStatusResponse,
  GetPerformanceBreakdownRequest,
  GetPerformanceBreakdownResponse,
  UpdateConfOfSecondSurvey,
  UpdateMonitoringScreenByAdminParams,
} from 'src/types';
import { ResponseError } from '../Errors/actions';

export const getNegotiationProcess = {
  request: createAction(
    'monitoring/GET_NEGOTIATION_PROCESS_REQUEST',
    (resolve) => (payload: GetNegotiationProcessRequestParams) => resolve(payload),
  ),
  success: createAction(
    'monitoring/GET_NEGOTIATION_PROCESS_SUCCESS',
    (resolve) => (payload: GetNegotiationProcessResponse) => resolve(payload),
  ),
  fail: createAction(
    'monitoring/GET_NEGOTIATION_PROCESS_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};
export const getNegotiationStatus = {
  request: createAction(
    'monitoring/GET_NEGOTIATION_STATUS_REQUEST',
    (resolve) => (payload: GetNegotiationStatusRequest) => resolve(payload),
  ),
  success: createAction(
    'monitoring/GET_NEGOTIATION_STATUS_SUCCESS',
    (resolve) => (payload: GetNegotiationStatusResponse) => resolve(payload),
  ),
  fail: createAction(
    'monitoring/GET_NEGOTIATION_STATUS_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const updateConfigOfSecondSurvey = {
  request: createAction(
    'monitoring/UPDATE_CONFIG_OF_SECOND_SURVEY_REQUEST',
    (resolve) => (payload: UpdateConfOfSecondSurvey) => resolve(payload),
  ),
  success: createAction(
    'monitoring/UPDATE_CONFIG_OF_SECOND_SURVEY_SUCCESS',
    (resolve) => () => resolve(),
  ),
  fail: createAction(
    'monitoring/UPDATE_CONFIG_OF_SECOND_SURVEY_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const getMonitoringSurvey = {
  request: createAction(
    'monitoring/GET_MONITORING_SURVEY_REQUEST',
    (resolve) => (payload: GetMonitoringSurveyRequestParams) => resolve(payload),
  ),
  success: createAction(
    'monitoring/GET_MONITORING_SURVEY_SUCCESS',
    (resolve) => (payload: GetMonitoringSurveyResponse) => resolve(payload),
  ),
  fail: createAction(
    'monitoring/GET_MONITORING_SURVEY_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const getMonitoringInfoScreen = {
  request: createAction(
    'monitoring/GET_MONITORING_INFO_REQUEST',
    (resolve) => (payload: GetInfoForMonitoringScreenRequestParams) => resolve(payload),
  ),
  success: createAction(
    'monitoring/GET_MONITORING_INFO_SUCCESS',
    (resolve) => (payload: GetMonitoringInfoScreeResponse) => resolve(payload),
  ),
  fail: createAction(
    'monitoring/ET_MONITORING_INFO_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const getConfigOfSecondSurvey = {
  request: createAction(
    'monitoring/GET_CONFIG_OF_SECOND_SURVEY_REQUEST',
    (resolve) => () => resolve(),
  ),
  success: createAction(
    'monitoring/GET_CONFIG_OF_SECOND_SURVEY_SUCCESS',
    (resolve) => (payload: GetConfigOfSecondSurveyResponse) => resolve(payload),
  ),
  fail: createAction(
    'monitoring/GET_CONFIG_OF_SECOND_SURVEY_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const updateMonitoringByAdmin = {
  request: createAction(
    'monitoring/UPDATE_MONITORING_SCREEN_BY_ADMIN_REQUEST',
    (resolve) => (payload: UpdateMonitoringScreenByAdminParams) => resolve(payload),
  ),
  success: createAction(
    'monitoring/UPDATE_MONITORING_SCREEN_BY_ADMIN_SUCCESS',
    (resolve) => () => resolve(),
  ),
  fail: createAction(
    'monitoring/UPDATE_MONITORING_SCREEN_BY_ADMIN_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const getPerformanceBreakdown = {
  request: createAction(
    'monitoring/GET_PERFORMANCE_BREAKDOWN_REQUEST',
    (resolve) => (payload: GetPerformanceBreakdownRequest) => resolve(payload),
  ),
  success: createAction(
    'monitoring/GET_PERFORMANCE_BREAKDOWN_SUCCESS',
    (resolve) => (payload: GetPerformanceBreakdownResponse) => resolve(payload),
  ),
  fail: createAction(
    'monitoring/GET_PERFORMANCE_BREAKDOWN_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const getMonitoringDates = {
  request: createAction('monitoring/GET_MONITORING_DATES_REQUEST', (resolve) => () => resolve()),
  success: createAction(
    'monitoring/GET_MONITORING_DATES_SUCCESS',
    (resolve) => (payload: GetMonitoringDatesResponse) => resolve(payload),
  ),
  fail: createAction(
    'monitoring/GET_MONITORING_DATES_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const downloadMonitoringExcel = {
  request: createAction(
    'monitoring/GET_DOWNLOAD_MONITORING_EXCEL_REQUEST',
    (resolve) => (payload: string) => resolve(payload),
  ),
  success: createAction(
    'monitoring/GET_DOWNLOAD_MONITORING_EXCEL_SUCCESS',
    (resolve) => (payload: File) => resolve(payload),
  ),
  fail: createAction(
    'monitoring/GET_DOWNLOAD_MONITORING_EXCEL_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

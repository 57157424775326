import React, { memo } from 'react';
import { SurveyStatus } from 'src/types';
import { ColoredRound } from '../../../ColoredRound';
import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';
import { ButtonBase } from '../../../ButtonBase';

const useStyles = makeStyles(() => ({
  statusContainer: {
    display: 'flex',
    columnGap: 10,
  },
  button: {
    width: 100,
    height: 32,
    backgroundColor: COLORS.white1,
    border: `1px solid ${COLORS.red1}`,
    color: COLORS.red1,
    fontSize: 14,
    transition: '0.3s ease',
    cursor: 'pointer',
    '&:disabled': {
      backgroundColor: COLORS.gray21,
      border: `1px solid ${COLORS.gray21}`,
      color: COLORS.red1,
      cursor: 'no-drop',
    },
    '&:disabled:hover': {
      backgroundColor: COLORS.gray21,
      color: COLORS.red1,
    },
    '&:hover': {
      backgroundColor: COLORS.red1,
      color: COLORS.white1,
    },
  },
}));

type Props = SurveyStatus & {
  onSendSurvey: (accManagerId: number) => void;
  allowSendSurveys?: boolean;
};

export const SurveyStatusRow: React.FC<Props> = memo(function SurveyStatusRow({
  accountManagerId,
  name,
  status,
  allowSendSurveys,
  onSendSurvey,
}) {
  const classes = useStyles();

  return (
    <tr>
      <td>
        <div>{name}</div>
      </td>
      <td>
        <div className={classes.statusContainer}>
          <ColoredRound status={status} />
          <div>{status}</div>
        </div>
      </td>
      <td>
        <div>
          <ButtonBase
            variant="contained"
            disabled={!allowSendSurveys}
            onClick={() => {
              onSendSurvey(accountManagerId);
            }}
            title="Send survey"
          />
        </div>
      </td>
    </tr>
  );
});

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  nav: {
    position: 'relative',
    left: '-18px',
    width: '240px',
    paddingTop: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default useStyles;

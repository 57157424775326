import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default useStyles;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Stack } from '@mui/material';

import { CAMPAIGN_TARGETS } from 'src/constants/CAMPAIGN_TARGETS';

import useStyles from './useStyles';
import { CampaignTargetHeaderController } from 'src/components/common/CampaignTargetHeaderController';
import { TargetGivenContainer } from 'src/components/common/TargetGivenContainer';
import { ButtonBase } from 'src/components/common/ButtonBase';
import { PricePotentialContainer } from 'src/components/common/PricePotentialContainer';

import { COLORS } from 'src/constants/COLORS';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'src/types';
import { updateApproachCampaignTarget as updateApproachCampaignTargetAction } from 'src/modules/campaignTarget/actions';
import { ForbidEditCheckbox } from '../../../../common/ForbidEditCheckbox';
import { ScreenNames } from '../../../../../constants/ScreenNames';
import { StepNames } from '../../../../../constants/stepNames';
import { Stepper } from '../../../../common/Stepper';

export const CampaignTarget: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { targetsForBusinessUnits, campaignTargetApproach, pricePotential } = useSelector(
    (state: Store) => state.campaignTarget,
  );
  const { disableEditPage } = useSelector((state: Store) => state.home);

  const [sectionSelected, setSectionSelected] = useState<string>(campaignTargetApproach);

  const showCheckbox = useMemo(() => {
    if (
      sectionSelected === 'TARGET_GIVEN' &&
      targetsForBusinessUnits &&
      targetsForBusinessUnits.targets.length > 0
    ) {
      return true;
    }

    return !!(
      sectionSelected === 'PRICE_POTENTIAL' &&
      pricePotential &&
      pricePotential.categories.length > 0 &&
      pricePotential.costDrivers.length > 0
    );
  }, [pricePotential, sectionSelected, targetsForBusinessUnits]);

  const handleChange = useCallback(
    (value: string) => {
      setSectionSelected(value);

      dispatch(
        updateApproachCampaignTargetAction.request({
          approach: value,
        }),
      );
    },
    [dispatch],
  );

  const validatePricePotentialForEmptyFields = useCallback(() => {
    if (pricePotential) {
      const hasEmptyCostDrivers = pricePotential.costDrivers.some(
        (item) => item.costDriverName === null || item.costDriverName.length === 0,
      );

      const hasEmptyCategories = pricePotential.categories.some((item) => {
        const hasEmptyIndicators =
          item.indicators.length === 0
            ? true
            : item.indicators.some((indicator) => indicator.indicator.length === 0);

        return item.category.length === 0 || hasEmptyIndicators;
      });

      if (
        hasEmptyCostDrivers ||
        hasEmptyCategories ||
        pricePotential.costDrivers.length === 0 ||
        pricePotential.categories.length === 0
      ) {
        toast.error('Some cost drivers or indicators are empty.');

        return false;
      }

      return true;
    }

    return true;
  }, [pricePotential]);

  const validateTargetsForEmptyFields = useCallback(() => {
    if (targetsForBusinessUnits) {
      const hasEmpty = targetsForBusinessUnits.targets.some((item) => item.target === null);

      if (hasEmpty) {
        toast.error('Some fields are empty.');

        return false;
      } else {
        return true;
      }
    }

    return true;
  }, [targetsForBusinessUnits]);

  useEffect(() => {
    handleChange(campaignTargetApproach);
  }, [campaignTargetApproach, handleChange]);

  const goBack = useCallback(() => {
    navigate('/configure-levels/touchable-revenue/revenue-base');
  }, [navigate]);

  const goNext = useCallback(
    (url: string) => {
      navigate(`/configure-levels/campaign-target/${url}`);
    },
    [navigate],
  );

  const RenderSelectedTarget = useMemo(() => {
    switch (sectionSelected) {
      case CAMPAIGN_TARGETS.TARGET_GIVEN:
        return (
          <TargetGivenContainer validateTargetsForEmptyFields={validateTargetsForEmptyFields} />
        );
      case CAMPAIGN_TARGETS.PRICE_POTENTIAL:
        return <PricePotentialContainer />;
    }
  }, [sectionSelected, validateTargetsForEmptyFields]);

  const EnhancedButtonsComponent = useMemo(() => {
    const nextPage =
      sectionSelected === CAMPAIGN_TARGETS.TARGET_GIVEN
        ? { name: 'Target setting', url: 'target-setting' }
        : { name: 'Price potential', url: 'price-potential' };

    return (
      <Stack width="100%" spacing={4} direction="row" marginTop={8} justifyContent="space-between">
        <ForbidEditCheckbox
          showCheckbox={showCheckbox}
          checkForEmptyFields={
            sectionSelected === CAMPAIGN_TARGETS.TARGET_GIVEN
              ? validateTargetsForEmptyFields
              : validatePricePotentialForEmptyFields
          }
          checkboxValue={disableEditPage}
          screenName={
            sectionSelected === CAMPAIGN_TARGETS.TARGET_GIVEN
              ? ScreenNames.CAMPAIGN_TARGET_TARGET_GIVEN
              : ScreenNames.CAMPAIGN_TARGET_PRICE_POTENTIAL
          }
        />
        <Stack width="100%" direction="row" justifyContent="flex-end" spacing={4}>
          <ButtonBase
            iconColor={COLORS.red1}
            variant="outlined"
            title="Campaign revenue base"
            onClick={goBack}
            icon="goBack"
            iconLeft={true}
          />
          <ButtonBase
            iconColor={COLORS.red1}
            variant="contained"
            title={nextPage.name}
            onClick={() => {
              if (nextPage.url === 'target-setting') {
                const isValid = validateTargetsForEmptyFields();

                if (!isValid) {
                  return;
                }
              } else {
                const isValid = validatePricePotentialForEmptyFields();

                if (!isValid) {
                  return;
                }
              }

              goNext(nextPage.url);
            }}
            icon="next"
          />
        </Stack>
      </Stack>
    );
  }, [
    disableEditPage,
    goBack,
    goNext,
    sectionSelected,
    showCheckbox,
    validatePricePotentialForEmptyFields,
    validateTargetsForEmptyFields,
  ]);

  return (
    <div>
      <Stepper
        steps={[
          {
            name: 'Touchable revenue',
            activeIndex: null,
            allCompleted: true,
          },
          {
            name: 'Campaign target',
            activeIndex: 0,
          },
          {
            name: 'Customer-specific targets',
            activeIndex: null,
          },
        ]}
        type={StepNames.ALL}
      />

      <div className={classes.container}>
        <CampaignTargetHeaderController
          sectionSelected={sectionSelected}
          handleChange={handleChange}
        />
      </div>

      {RenderSelectedTarget}

      {EnhancedButtonsComponent}
    </div>
  );
};

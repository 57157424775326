import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  row: {
    height: 61,
    '& td': {
      fontSize: 16,
      fontWeight: 700,
      color: COLORS.black6,
      padding: '16px 24px',
    },
  },
  numberInput: {
    border: `1px solid ${COLORS.gray26}`,
    borderRadius: 8,
    height: '24px',
    width: '74px',
    outline: 'none',
    padding: '0 16px',
    textAlign: 'center',
    fontSize: '16px',
    color: COLORS.black1,
    marginBottom: 16,
    '&::placeholder': {
      color: COLORS.gray22,
    },
    '&:placeholder': {
      color: COLORS.gray25,
    },
    '&:hover': {
      borderColor: COLORS.blue2,
    },
    '&:focus': {
      borderColor: COLORS.blue11,
    },
  },
}));

export default useStyles;

import { createAction } from 'deox';
import {
  DeleteSurveySettingsOptionRequestParams,
  EaseOfIncreaseDrivers,
  GetSecondSurveyDataRequestParams,
  GetSecondSurveyResponse,
  GetSurveyCompleting,
  GetSurveyResultAccessResponse,
  GetSurveyResultRequestParams,
  GetSurveyResultResponse,
  GetSurveysCompletingResponse,
  GetSurveySettingsByIdRequest,
  GetSurveysSettingsByIdResponse,
  SurveyResultCopyOptionsFromFirstBUToOtherRequestConfig,
  SurveyResultSetAdditionalFields,
  SurveyResultSetContractCommitment,
  SurveyResultSetEOIOptionRequestBody,
  SurveyResultSetPlanBOptions,
  SurveyResultSetTouchableOptionRequestBody,
  SurveySettingCreateNewEOI,
  SurveySettingCreateNewEOIResponse,
  SurveySettingUpdateEOIModalInfo,
  SurveySettingUpdateEOIOption,
  UpdateAdditionalFieldsOfSurveySettings,
  UpdateSecondFinalCustomerTargetsRealizedPrice,
  ResetOverwriteImpactEffectParams,
  UpdateSurveySettingsResponse,
  UpdateSurveySettingTouchable,
  UpdateSurveysSettingsRequestBody,
  CreateSurveySettingsOptionRequestBody,
  UpdateSurveySettingsOptionRequest,
  OtherDropdownAndFreeText,
  SurveySettingUpdateOtherModalInfo,
  SelectedOther,
  SurveyResultSetOtherOptionRequestBody,
  SaveSecondSurveyOneRequestParams,
} from 'src/types';
import { ResponseError } from '../Errors/actions';
import { SurveySettingsCategories } from 'src/constants/SurveySettingCategories';
import { ScreenNames } from 'src/constants/ScreenNames';

export const getSurveysSettings = {
  request: createAction(
    'surveys/GET_SURVEYS_SETTINGS_REQUEST',
    (resolve) =>
      (payload: { requestPayload: GetSurveySettingsByIdRequest; needShowLoader?: boolean }) =>
        resolve(payload),
  ),
  success: createAction(
    'surveys/GET_SURVEYS_SETTINGS_SUCCESS',
    (resolve) => (payload: GetSurveysSettingsByIdResponse) => resolve(payload),
  ),
  fail: createAction(
    'surveys/GET_SURVEYS_SETTINGS_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const updateSurveysSettings = {
  request: createAction(
    'surveys/UPDATE_SURVEYS_SETTINGS_REQUEST',
    (resolve) =>
      (payload: {
        body: UpdateSurveysSettingsRequestBody;
        needUpdateState?: boolean;
        onSuccessCb?: () => void;
        onFailCb?: () => void;
      }) =>
        resolve(payload),
  ),
  success: createAction(
    'surveys/UPDATE_SURVEYS_SETTINGS_SUCCESS',
    (resolve) => (payload: UpdateSurveySettingsResponse) => resolve(payload),
  ),
  fail: createAction(
    'surveys/UPDATE_SURVEYS_SETTINGS_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const updateAdditionalFieldsOfSurveySettings = {
  request: createAction(
    'surveys/UPDATE_ADDITIONAL_FIELDS_OF_SURVEY_SETTINGS_REQUEST',
    (resolve) => (payload: UpdateAdditionalFieldsOfSurveySettings) => resolve(payload),
  ),
  success: createAction(
    'surveys/UPDATE_ADDITIONAL_FIELDS_OF_SURVEY_SETTINGS_SUCCESS',
    (resolve) => () => resolve(),
  ),
  fail: createAction(
    'surveys/UPDATE_ADDITIONAL_FIELDS_OF_SURVEY_SETTINGS_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const setEOIFieldError = createAction(
  'surveys/SET_ERROR',
  (resolve) => (payload: { id: number }) => resolve(payload),
);

export const surveySettingsCreateNewEOI = {
  request: createAction(
    'surveys/CREATE_NEW_EOI_REQUEST',
    (resolve) => (payload: SurveySettingCreateNewEOI) => resolve(payload),
  ),
  success: createAction(
    'surveys/CREATE_NEW_EOI_SUCCESS',
    (resolve) => (payload: SurveySettingCreateNewEOIResponse) => resolve(payload),
  ),
  fail: createAction(
    'surveys/CREATE_NEW_EOI_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const surveySettingsUpdateEOIOption = {
  request: createAction(
    'surveys/UPDATE_EOI_OPTION_REQUEST',
    (resolve) => (payload: SurveySettingUpdateEOIOption) => resolve(payload),
  ),
  success: createAction(
    'surveys/UPDATE_EOI_OPTION_SUCCESS',
    (resolve) => (payload: SurveySettingUpdateEOIOption) => resolve(payload),
  ),
  fail: createAction(
    'surveys/UPDATE_EOI_OPTION_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const surveySettingUpdateEOIModalInfo = {
  request: createAction(
    'surveys/UPDATE_EOI_MODAL_INFO_REQUEST',
    (resolve) =>
      (payload: { requestPayload: SurveySettingUpdateEOIModalInfo; onSuccessCb: () => void }) =>
        resolve(payload),
  ),
  success: createAction('surveys/UPDATE_EOI_MODAL_INFO_SUCCESS', (resolve) => () => resolve()),
  fail: createAction(
    'surveys/UPDATE_EOI_MODAL_INFO_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const surveySettingUpdateOtherModalInfo = {
  request: createAction(
    'surveys/UPDATE_OTHER_MODAL_INFO_REQUEST',
    (resolve) =>
      (payload: { requestPayload: SurveySettingUpdateOtherModalInfo; onSuccessCb: () => void }) =>
        resolve(payload),
  ),
  success: createAction('surveys/UPDATE_OTHER_MODAL_INFO_SUCCESS', (resolve) => () => resolve()),
  fail: createAction(
    'surveys/UPDATE_OTHER_MODAL_INFO_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const setSelectedEaseOfIncreaseDriver = createAction(
  'surveys/SET_SELECTED_EOI',
  (resolve) => (payload: EaseOfIncreaseDrivers) => resolve(payload),
);

export const setSelectedOther = createAction(
  'surveys/SET_SELECTED_OTHER',
  (resolve) => (payload: SelectedOther) => resolve(payload),
);

export const cleanSelectedEaseOfIncreaseDriver = createAction(
  'surveys/CLEAN_SELECTED_EOI',
  (resolve) => () => resolve(),
);

export const updateSurveySettingTouchable = {
  request: createAction(
    'surveys/UPDATE_SURVEYS_SETTING_TOUCHABLE_REQUEST',
    (resolve) => (payload: UpdateSurveySettingTouchable) => resolve(payload),
  ),
  success: createAction(
    'surveys/UPDATE_SURVEYS_SETTING_TOUCHABLE_SUCCESS',
    (resolve) => () => resolve(),
  ),
  fail: createAction(
    'surveys/UPDATE_SURVEYS_SETTING_TOUCHABLE_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const deleteSurveyPriceIncreaseTimeLevelOption = {
  request: createAction(
    'surveys/DELETE_PRICE_INCREASE_TIME_LEVEL_OPTION_REQUEST',
    (resolve) => (payload: { id?: number; temporaryId?: number }) => resolve(payload),
  ),
  success: createAction(
    'surveys/DELETE_PRICE_INCREASE_TIME_LEVEL_OPTION_SUCCESS',
    (resolve) => (payload: { id?: number; temporaryId?: number }) => resolve(payload),
  ),
  fail: createAction(
    'surveys/DELETE_PRICE_INCREASE_TIME_LEVEL_OPTION_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};
export const deleteSurveyOtherModalOption = {
  request: createAction(
    'surveys/DELETE_SURVEY_OTHER_MODAL_OPTION_REQUEST',
    (resolve) => (payload: { itemId?: number; id?: number; temporaryId?: number }) =>
      resolve(payload),
  ),
  success: createAction(
    'surveys/DELETE_SURVEY_OTHER_MODAL_OPTION_SUCCESS',
    (resolve) => (payload: { id?: number; temporaryId?: number }) => resolve(payload),
  ),
  fail: createAction(
    'surveys/DELETE_SURVEY_OTHER_MODAL_OPTION_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const deleteSurveySettingsOption = {
  request: createAction(
    'surveys/DELETE_SURVEY_SETTINGS_OPTION_REQUEST',
    (resolve) =>
      (payload: {
        requestParams: DeleteSurveySettingsOptionRequestParams;
        category: SurveySettingsCategories;
      }) =>
        resolve(payload),
  ),
  success: createAction(
    'surveys/DELETE_SURVEY_SETTINGS_OPTION_SUCCESS',
    (resolve) => () => resolve(),
  ),
  fail: createAction(
    'surveys/DELETE_SURVEY_SETTINGS_OPTION_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const createSurveySettingsOtherOption = {
  request: createAction(
    'surveys/CREATE_SURVEY_SETTINGS_OTHER_OPTION_REQUEST',
    (resolve) => (payload: CreateSurveySettingsOptionRequestBody) => resolve(payload),
  ),
  success: createAction(
    'surveys/CREATE_SURVEY_SETTINGS_OTHER_OPTION_SUCCESS',
    (resolve) => (payload: OtherDropdownAndFreeText) => resolve(payload),
  ),
  fail: createAction(
    'surveys/CREATE_SURVEY_SETTINGS_OTHER_OPTION_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const updateSurveySettingsOtherOption = {
  request: createAction(
    'surveys/UPDATE_SURVEY_SETTINGS_OTHER_OPTION_REQUEST',
    (resolve) => (payload: UpdateSurveySettingsOptionRequest) => resolve(payload),
  ),
  success: createAction(
    'surveys/UPDATE_SURVEY_SETTINGS_OTHER_OPTION_SUCCESS',
    (resolve) => (payload: OtherDropdownAndFreeText) => resolve(payload),
  ),
  fail: createAction(
    'surveys/UPDATE_SURVEY_SETTINGS_OTHER_OPTION_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const getSurveyResult = {
  request: createAction(
    'surveys/GET_SURVEY_RESULT_REQUEST',
    (resolve) => (payload: GetSurveyResultRequestParams) => resolve(payload),
  ),
  success: createAction(
    'surveys/GET_SURVEY_RESULT_SUCCESS',
    (resolve) => (payload: GetSurveyResultResponse) => resolve(payload),
  ),
  fail: createAction(
    'surveys/GET_SURVEY_RESULT_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const setTouchableOption = {
  request: createAction(
    'surveys/SET_TOUCHABLE_OPTION_REQUEST',
    (resolve) =>
      (payload: {
        params: { accountManager: string };
        body: SurveyResultSetTouchableOptionRequestBody;
      }) =>
        resolve(payload),
  ),
  success: createAction('surveys/SET_TOUCHABLE_OPTION_SUCCESS', (resolve) => () => resolve()),
  fail: createAction(
    'surveys/SET_TOUCHABLE_OPTION_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const setEOIOption = {
  request: createAction(
    'surveys/SET_EOI_OPTION_REQUEST',
    (resolve) =>
      (payload: {
        params: { accountManager: string };
        body: SurveyResultSetEOIOptionRequestBody;
      }) =>
        resolve(payload),
  ),
  success: createAction('surveys/SET_EOI_OPTION_SUCCESS', (resolve) => () => resolve()),
  fail: createAction(
    'surveys/SET_EOI_OPTION_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const setOtherOption = {
  request: createAction(
    'surveys/SET_OTHER_OPTION_REQUEST',
    (resolve) =>
      (payload: {
        params: { accountManager: string };
        body: SurveyResultSetOtherOptionRequestBody;
      }) =>
        resolve(payload),
  ),
  success: createAction('surveys/SET_OTHER_OPTION_SUCCESS', (resolve) => () => resolve()),
  fail: createAction(
    'surveys/SET_OTHER_OPTION_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const setContractCommitment = {
  request: createAction(
    'surveys/SET_CONTRACT_COMMITMENT_REQUEST',
    (resolve) =>
      (payload: { params: { accountManager: string }; body: SurveyResultSetContractCommitment }) =>
        resolve(payload),
  ),
  success: createAction('surveys/SET_CONTRACT_COMMITMENT_SUCCESS', (resolve) => () => resolve()),
  fail: createAction(
    'surveys/SET_CONTRACT_COMMITMENT_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const setSurveyResultAdditionalFields = {
  request: createAction(
    'surveys/SET_SURVEY_RESULT_ADDITIONAL_FIELDS_REQUEST',
    (resolve) =>
      (payload: { params: { accountManager: string }; body: SurveyResultSetAdditionalFields }) =>
        resolve(payload),
  ),
  success: createAction(
    'surveys/SET_SURVEY_RESULT_ADDITIONAL_FIELDS_SUCCESS',
    (resolve) => () => resolve(),
  ),
  fail: createAction(
    'surveys/SET_SURVEY_RESULT_ADDITIONAL_FIELDS_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const setPlanBOption = {
  request: createAction(
    'surveys/SET_PLAN_B_OPTION_REQUEST',
    (resolve) =>
      (payload: { params: { accountManager: string }; body: SurveyResultSetPlanBOptions }) =>
        resolve(payload),
  ),
  success: createAction('surveys/SET_PLAN_B_OPTION_SUCCESS', (resolve) => () => resolve()),
  fail: createAction(
    'surveys/SET_PLAN_B_OPTION_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

// survey completing below

export const getSurveysCompleting = {
  request: createAction(
    'surveys/GET_SURVEYS_COMPLETING_REQUEST',
    (resolve) => (payload: GetSurveyCompleting) => resolve(payload),
  ),
  success: createAction(
    'surveys/GET_SURVEYS_COMPLETING_SUCCESS',
    (resolve) => (payload: GetSurveysCompletingResponse) => resolve(payload),
  ),
  fail: createAction(
    'surveys/GET_SURVEYS_COMPLETING_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const getSecondSurvey = {
  request: createAction(
    'surveys/GET_SECOND_SURVEY_REQUEST',
    (resolve) => (payload: GetSecondSurveyDataRequestParams) => resolve(payload),
  ),
  success: createAction(
    'surveys/GET_SECOND_SURVEY_SUCCESS',
    (resolve) => (payload: GetSecondSurveyResponse) => resolve(payload),
  ),
  fail: createAction(
    'surveys/GET_SECOND_SURVEY_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const saveSurveyCompletingCustomersDate = createAction(
  'surveys/SAVE_SURVEYS_COMPLETING_CUSTOMER_DATE',
  (resolve) => (payload: string) => resolve(payload),
);

export const setSurveysCompletingTouchableOption = {
  request: createAction(
    'surveys/SET_SURVEY_COMPLETING_TOUCHABLE_OPTION_REQUEST',
    (resolve) => (payload: SurveyResultSetTouchableOptionRequestBody) => resolve(payload),
  ),
  success: createAction(
    'surveys/SET_SURVEY_COMPLETING_TOUCHABLE_OPTION_SUCCESS',
    (resolve) => () => resolve(),
  ),
  fail: createAction(
    'surveys/SET_SURVEY_COMPLETING_TOUCHABLE_OPTION_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const checkSurveyResultFieldsAreFilled = {
  request: createAction(
    'surveys/CHECK_SURVEY_RESULT_REQUEST',
    (resolve) => (payload: { onSuccessCb: () => void }) => resolve(payload),
  ),
  success: createAction(
    'surveys/CHECK_SURVEY_RESULT_REQUEST_SUCCESS',
    (resolve) => () => resolve(),
  ),
  fail: createAction(
    'surveys/CHECK_SURVEY_RESULT_REQUEST_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const setSurveysCompletingEOIOption = {
  request: createAction(
    'surveys/SET_SURVEY_COMPLETING_EOI_OPTION_REQUEST',
    (resolve) => (payload: SurveyResultSetEOIOptionRequestBody) => resolve(payload),
  ),
  success: createAction(
    'surveys/SET_SURVEY_COMPLETING_EOI_OPTION_SUCCESS',
    (resolve) => () => resolve(),
  ),
  fail: createAction(
    'surveys/SET_SURVEY_COMPLETING_EOI_OPTION_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const setSurveysCompletingOtherOption = {
  request: createAction(
    'surveys/SET_SURVEY_COMPLETING_OTHER_OPTION_REQUEST',
    (resolve) => (payload: SurveyResultSetOtherOptionRequestBody) => resolve(payload),
  ),
  success: createAction(
    'surveys/SET_SURVEY_COMPLETING_OTHER_OPTION_SUCCESS',
    (resolve) => () => resolve(),
  ),
  fail: createAction(
    'surveys/SET_SURVEY_COMPLETING_OTHER_OPTION_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const setSurveysCompletingContractCommitmentOption = {
  request: createAction(
    'surveys/SET_SURVEY_COMPLETING_CONTRACT_COMMITMENT_REQUEST',
    (resolve) => (payload: SurveyResultSetContractCommitment) => resolve(payload),
  ),
  success: createAction(
    'surveys/SET_SURVEY_COMPLETING_CONTRACT_COMMITMENT_SUCCESS',
    (resolve) => () => resolve(),
  ),
  fail: createAction(
    'surveys/SET_SURVEY_COMPLETING_CONTRACT_COMMITMENT_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const setSurveysCompletingAdditionalFields = {
  request: createAction(
    'surveys/SET_SURVEY_COMPLETING_SURVEY_RESULT_ADDITIONAL_FIELDS_REQUEST',
    (resolve) => (payload: SurveyResultSetAdditionalFields) => resolve(payload),
  ),
  success: createAction(
    'surveys/SET_SURVEY_COMPLETING_SURVEY_RESULT_ADDITIONAL_FIELDS_SUCCESS',
    (resolve) => () => resolve(),
  ),
  fail: createAction(
    'surveys/SET_SURVEY_COMPLETING_SURVEY_RESULT_ADDITIONAL_FIELDS_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const setSurveyCompletingPlanBOption = {
  request: createAction(
    'surveys/SET_SURVEY_COMPLETING_PLAN_B_OPTION_REQUEST',
    (resolve) => (payload: SurveyResultSetPlanBOptions) => resolve(payload),
  ),
  success: createAction(
    'surveys/SET_SURVEY_COMPLETING_PLAN_B_OPTION_SUCCESS',
    (resolve) => () => resolve(),
  ),
  fail: createAction(
    'surveys/SET_SURVEY_COMPLETING_PLAN_B_OPTION_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const surveysCompletingSaveProgress = {
  request: createAction(
    'surveys/SET_PROGRESS_STATUS_REQUEST',
    (resolve) => (payload: ScreenNames) => resolve(payload),
  ),
  success: createAction(
    'surveys/SET_PROGRESS_STATUS_SUCCESS',
    (resolve) => (payload: ScreenNames) => resolve(payload),
  ),
  fail: createAction(
    'surveys/SET_PROGRESS_STATUS_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const updateRealizedPrice = {
  request: createAction(
    'surveys/UPDATE_REALIZED_PRICE_REQUEST',
    (resolve) => (payload: UpdateSecondFinalCustomerTargetsRealizedPrice) => resolve(payload),
  ),
  success: createAction('surveys/UPDATE_REALIZED_PRICE_SUCCESS', (resolve) => () => resolve()),
  fail: createAction(
    'surveys/UPDATE_REALIZED_PRICE_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const resetOverwriteImpactEffect = {
  request: createAction(
    'surveys/RESET_OVERWRITE_IMPACT_EFFECT_REQUEST',
    (resolve) => (payload: ResetOverwriteImpactEffectParams) => resolve(payload),
  ),
  success: createAction(
    'surveys/RESET_OVERWRITE_IMPACT_EFFECT_SUCCESS',
    (resolve) => () => resolve(),
  ),
  fail: createAction(
    'surveys/RESET_OVERWRITE_IMPACT_EFFECT_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const surveyResultCopyOptionsFromFirstBUToOther = {
  request: createAction(
    'surveys/SURVEY_RESULT_COPY_OPTIONS_REQUEST',
    (resolve) => (payload: SurveyResultCopyOptionsFromFirstBUToOtherRequestConfig) =>
      resolve(payload),
  ),
  success: createAction(
    'surveys/SURVEY_RESULT_COPY_OPTIONS_SUCCESS',
    (resolve) => (payload: SurveyResultCopyOptionsFromFirstBUToOtherRequestConfig) =>
      resolve(payload),
  ),
  fail: createAction(
    'surveys/SURVEY_RESULT_COPY_OPTIONS_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const surveyResultCopyOptionsFromFirstBUToOtherAccountManager = {
  request: createAction(
    'surveys/SURVEY_RESULT_COPY_OPTIONS_ACCOUNT_MANAGER_REQUEST',
    (resolve) => (payload: SurveyResultCopyOptionsFromFirstBUToOtherRequestConfig) =>
      resolve(payload),
  ),
  success: createAction(
    'surveys/SURVEY_RESULT_COPY_OPTIONS_ACCOUNT_MANAGER_SUCCESS',
    (resolve) => (payload: SurveyResultCopyOptionsFromFirstBUToOtherRequestConfig) =>
      resolve(payload),
  ),
  fail: createAction(
    'surveys/SURVEY_RESULT_COPY_OPTIONS_ACCOUNT_MANAGER_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const editCustomerRowSecondSurvey = createAction(
  'surveys/EDIT_CUSTOMER_ROW',
  (resolve) =>
    (payload: {
      customerId: number;
      status?: string;
      businessUnitId?: number;
      percentage?: number;
      comment?: string;
    }) =>
      resolve(payload),
);

export const saveSecondSurveyOne = {
  request: createAction(
    'surveys/SAVE_SECOND_SURVEY_ONE_REQUEST',
    (resolve) => (payload: SaveSecondSurveyOneRequestParams) => resolve(payload),
  ),
  success: createAction('surveys/SAVE_SECOND_SURVEY_ONE_SUCCESS', (resolve) => () => resolve()),
  fail: createAction(
    'surveys/SAVE_SECOND_SURVEY_ONE_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const getSurveyResultAccess = {
  request: createAction('surveys/GET_SURVEY_RESULT_ACCESS_REQUEST', (resolve) => () => resolve()),
  success: createAction(
    'surveys/GET_SURVEY_RESULT_ACCESS_SUCCESS',
    (resolve) => (payload: GetSurveyResultAccessResponse) => resolve(payload),
  ),
  fail: createAction(
    'surveys/GET_SURVEY_RESULT_ACCESS_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

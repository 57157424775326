import React, { FC } from 'react';
import useStyles from './useStyles';
import { ResponsiveContainer, BarChart, Bar, Cell, XAxis, YAxis, Tooltip } from 'recharts';
import { COLORS } from 'src/constants/COLORS';
import { RevenueDividing } from 'src/types';
import { Loader } from 'src/components/common/Loader';
import { useHorizontalScroll } from 'src/hooks/useHorizontalScroll';
import { formatNumberWithCommas } from '../../../helpers/formatNumberWithCommas';

type Props = {
  chartsData: RevenueDividing[] | null;
  loading: boolean;
};

const CELL_WIDTH = 48;
const CELL_GAP = 55;

export const RevenueDividingChart: FC<Props> = ({ chartsData, loading }) => {
  const classes = useStyles();

  const scrollRef = useHorizontalScroll();

  const cData = chartsData
    ? chartsData
        .map((item, index) => {
          const revenue = index > 0 ? -item.revenue : item.revenue;

          const currentPv = 0;

          return {
            name: item.option,
            revenue,
            pv: currentPv,
            customers: item.customers,
          };
        })
        .map((item, index, array) => {
          const prevPvs = array
            .slice(0, index)
            .reduce((prevValue, value) => value.revenue + prevValue, 0);

          return {
            ...item,
            pv: prevPvs,
          };
        })
    : [];

  const CustomLabel = (props: any) => {
    if (!chartsData) {
      return <g />;
    }
    const label = chartsData[props.index]?.revenue || '';
    const labelText = formatNumberWithCommas(label);

    const fontSize = 14;
    const labelWidth = labelText.length * (fontSize * 0.5);
    const dx = CELL_WIDTH / 2 - labelWidth / 2;
    return (
      <g>
        <text color="#4F4F4F" dx={dx} dy={-1} fontSize={14} x={props.viewBox.x} y={20} fill="#111">
          {formatNumberWithCommas(chartsData[props.index]?.revenue)}
        </text>
      </g>
    );
  };

  const width = (CELL_WIDTH + CELL_GAP) * cData.length;

  const CustomTooltip = (props: any) => {
    const { active, payload } = props;
    if (active && payload && payload.length) {
      return (
        <div className={classes.customTooltip}>
          <div className={classes.customTooltipName}>{payload[0]?.payload.name}</div>
          <div className={classes.customTooltipRevenue}>
            revenue: {formatNumberWithCommas(Math.abs(payload[0]?.payload?.revenue))}
          </div>
          <div>
            {payload[0]?.payload?.customers.map((item: any, index: number) => (
              <div key={index}>
                {item.name} - {formatNumberWithCommas(item.revenue)}
              </div>
            ))}
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div ref={scrollRef} className={classes.container}>
      {loading || !chartsData ? (
        <Loader marginTop={37} isLoading={true} />
      ) : (
        <>
          <div style={{ width: '95%' }} className={classes.xAxisContainer}>
            <div style={{ width: '95%' }} className={classes.xAxisTopLine} />
            <ul style={{ width }} className={classes.xAxisLabelsContainer}>
              {cData.map((item) => (
                <li className={classes.xAxisLabel} key={item.name}>
                  {item.name}
                </li>
              ))}
            </ul>
          </div>
          <ResponsiveContainer width={width} height="100%" className={classes.responsiveContainer}>
            <BarChart
              data={cData}
              margin={{
                top: 20,
                // right: 30,
                // left: 30,
                bottom: 5,
              }}>
              <XAxis hide dataKey="name" />
              <YAxis hide padding={{ top: 20 }} />
              <Tooltip content={<CustomTooltip />} />
              <Bar dataKey="pv" stackId="a" fill="transparent" radius={[8, 8, 8, 8]} />
              <Bar
                radius={[8, 8, 8, 8]}
                label={<CustomLabel />}
                dataKey="revenue"
                stackId="a"
                fill="#82ca9d">
                {cData.map((item, index) => {
                  return (
                    <Cell
                      width={CELL_WIDTH}
                      key={item.name}
                      fill={index > 0 ? COLORS.gray25 : COLORS.orange1}
                    />
                  );
                })}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </>
      )}
    </div>
  );
};

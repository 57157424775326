import React from 'react';
import useStyles from './useStyles';
import { Loader } from '../../common/Loader';
import { ButtonBase } from '../../common/ButtonBase';
import { useNavigate } from 'react-router-dom';
import { ROLES } from 'src/constants/ROLES';

type Props = {
  isAuthenticated: boolean;
  currentRole: string;
};

export const NotFound: React.FC<Props> = ({ isAuthenticated, currentRole }) => {
  const classes = useStyles();

  const navigate = useNavigate();

  return (
    <div className={classes.notFoundWrapper}>
      {isAuthenticated ? (
        <div className={classes.notFound}>
          <div className={classes.notFoundText}>Page not found</div>
          <ButtonBase
            variant="outlined"
            onClick={() => {
              navigate(currentRole === ROLES.ROLE_ADMIN ? '/home/status-overview' : '/');
            }}
            icon="goBack"
            title="Back to home page"
          />
        </div>
      ) : (
        <Loader isLoading={true} />
      )}
    </div>
  );
};

import React, { memo } from 'react';
import { BusinessCaseAssumption, UpdateBusinessCaseAssumptions } from 'src/types';
import useStyles from './useStyles';
import { EditableCell } from '../../../../../../common/EditableCell';

type Props = BusinessCaseAssumption & {
  onUpdateBusinessCase: (body: UpdateBusinessCaseAssumptions) => void;
  disabledEdit: boolean;
};

export const CaseItem: React.FC<Props> = memo(function CaseItem({
  businessUnitName,
  onUpdateBusinessCase,
  priceDifferentialToTarget,
  revenueShare,
  businessUnitId,
  priceElasticity,
  disabledEdit,
}) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <table className={classes.table}>
        <thead>
          <tr>
            <th />
            <th colSpan={2}>
              <h3>{businessUnitName}</h3>
            </th>
          </tr>
          <tr>
            <th />
            <th>
              <h3 className={classes.titleHeader}>Price differential to target</h3>
            </th>
            <th>
              <h3 className={classes.titleHeader}>Revenue share</h3>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>Going-in price</td>
            <td>
              <EditableCell
                suffix={'%'}
                isInput
                isRevenue
                disabled={disabledEdit}
                onlyNumbers
                innerInputStyle={classes.numberInput}
                buttonStyle={classes.numberInput}
                value={
                  priceDifferentialToTarget.goingInPrice === null
                    ? ''
                    : priceDifferentialToTarget.goingInPrice.toString()
                }
                onEditCb={(value) => {
                  if (value !== '') {
                    onUpdateBusinessCase({
                      businessUnitId,
                      priceDifferentialToTarget: {
                        goingInPrice: +value,
                      },
                    });
                  }
                }}
              />
            </td>
            <td>
              <EditableCell
                suffix={'%'}
                isInput
                isRevenue
                disabled={disabledEdit}
                onlyNumbers
                innerInputStyle={classes.numberInput}
                buttonStyle={classes.numberInput}
                value={
                  revenueShare.goingInPrice === null ? '' : revenueShare.goingInPrice.toString()
                }
                onEditCb={(value) => {
                  if (value !== '') {
                    onUpdateBusinessCase({
                      businessUnitId,
                      revenueShare: {
                        goingInPrice: +value,
                      },
                    });
                  }
                }}
              />
            </td>
          </tr>
          <tr>
            <td>Target price</td>
            <td>
              <div className={`${classes.textNotEditable} ${disabledEdit && classes.disableCell}`}>
                {priceDifferentialToTarget.targetPrice}%
              </div>
            </td>
            <td>
              <EditableCell
                isInput
                isRevenue
                suffix={'%'}
                disabled={disabledEdit}
                onlyNumbers
                innerInputStyle={classes.numberInput}
                buttonStyle={classes.numberInput}
                value={revenueShare.targetPrice === null ? '' : revenueShare.targetPrice.toString()}
                onEditCb={(value) => {
                  if (value !== '') {
                    onUpdateBusinessCase({
                      businessUnitId,
                      revenueShare: {
                        targetPrice: +value,
                      },
                    });
                  }
                }}
              />
            </td>
          </tr>
          <tr>
            <td>Escalation price</td>
            <td>
              <EditableCell
                isInput
                isRevenue
                suffix={'%'}
                disabled={disabledEdit}
                onlyNumbers
                innerInputStyle={classes.numberInput}
                buttonStyle={classes.numberInput}
                value={
                  priceDifferentialToTarget.escalationPrice === null
                    ? ''
                    : priceDifferentialToTarget.escalationPrice.toString()
                }
                onEditCb={(value) => {
                  if (value !== '') {
                    onUpdateBusinessCase({
                      businessUnitId,
                      priceDifferentialToTarget: {
                        escalationPrice: +value,
                      },
                    });
                  }
                }}
              />
            </td>
            <td>
              <EditableCell
                isInput
                suffix={'%'}
                isRevenue
                disabled={disabledEdit}
                onlyNumbers
                innerInputStyle={classes.numberInput}
                buttonStyle={classes.numberInput}
                value={
                  revenueShare.escalationPrice === null
                    ? ''
                    : revenueShare.escalationPrice.toString()
                }
                onEditCb={(value) => {
                  if (value !== '') {
                    onUpdateBusinessCase({
                      businessUnitId,
                      revenueShare: {
                        escalationPrice: +value,
                      },
                    });
                  }
                }}
              />
            </td>
          </tr>
          <tr>
            <td>Walk-away price</td>
            <td>
              <EditableCell
                isInput
                suffix={'%'}
                isRevenue
                disabled={disabledEdit}
                onlyNumbers
                innerInputStyle={classes.numberInput}
                buttonStyle={classes.numberInput}
                value={
                  priceDifferentialToTarget.walkAwayPrice === null
                    ? ''
                    : priceDifferentialToTarget.walkAwayPrice.toString()
                }
                onEditCb={(value) => {
                  if (value !== '') {
                    onUpdateBusinessCase({
                      businessUnitId,
                      priceDifferentialToTarget: {
                        walkAwayPrice: +value,
                      },
                    });
                  }
                }}
              />
            </td>
            <td>
              <EditableCell
                isInput
                suffix={'%'}
                isRevenue
                disabled={disabledEdit}
                onlyNumbers
                innerInputStyle={classes.numberInput}
                buttonStyle={classes.numberInput}
                value={
                  revenueShare.walkAwayPrice === null ? '' : revenueShare.walkAwayPrice.toString()
                }
                onEditCb={(value) => {
                  if (value !== '') {
                    onUpdateBusinessCase({
                      businessUnitId,
                      revenueShare: {
                        walkAwayPrice: +value,
                      },
                    });
                  }
                }}
              />
            </td>
          </tr>
          <tr>
            <td style={{ height: 40 }} colSpan={3} />
          </tr>
          <tr>
            <td>Price elasticity</td>
            <td colSpan={2}>
              <EditableCell
                isInput
                allowNegative
                isRevenue
                disabled={disabledEdit}
                onlyNumbers
                innerInputStyle={`${classes.numberInput} ${classes.numberInputTwoCells}`}
                buttonStyle={`${classes.numberInput} ${classes.numberInputTwoCells}`}
                value={priceElasticity === null ? '' : priceElasticity.toString()}
                onEditCb={(value) => {
                  if (value !== '') {
                    onUpdateBusinessCase({
                      businessUnitId,
                      priceElasticity: +value,
                    });
                  }
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
});

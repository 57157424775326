import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  segment: {
    marginRight: 10,
    marginTop: 20,
    flex: 1,
    height: 160,
    overflow: 'auto',
    paddingRight: 20,
  },
  segmentItem: {
    marginBottom: 5,
    display: 'flex',
    alignItems: 'center',
    height: 50,
  },
  segmentItemLine: {
    height: '100%',
    width: 5,
    content: '',
    marginRight: 8,
  },
  segmentItemText: {
    fontSize: 12,
    lineHeight: '14px',
    fontWeight: 700,
    marginBottom: 8,
  },
  segmentItemBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  segmentItemBlockArrow: {
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 5,
    width: 24,
    height: 24,
  },
}));

export default useStyles;

import ProjectsIcon from '../assets/icons/projects.svg';
import HomeIcon from '../assets/icons/home.svg';
import SliderIcon from '../assets/icons/slider.svg';
import TableIcon from '../assets/icons/table.svg';
import RolloutIcon from '../assets/icons/clipboard-check.svg';
import Surveys from '../assets/icons/surveys.svg';
import { ScreenName } from '../types';

export const adminMenuLinks = (isDisabledNavigation: boolean, isAllowSurveyResult?: boolean) => {
  return [
    {
      id: 0,
      url: '/projects',
      text: 'Projects',
      icon: ProjectsIcon,
    },
    {
      id: 1,
      url: '/home/status-overview',
      text: 'Home',
      icon: HomeIcon,
      disabled: isDisabledNavigation,
    },
    {
      id: 2,
      icon: SliderIcon,
      text: 'Configure levels',
      disabled: isDisabledNavigation,
      nestedLinks: [
        {
          id: 1,
          text: 'Setup',
          sublinks: [
            {
              id: 1,
              text: 'Segments',
              url: '/configure-levels/touchable-revenue/business-units',
            },
            {
              id: 2,
              text: 'Account manager list',
              url: '/configure-levels/touchable-revenue/account-manager-list',
            },
            {
              id: 3,
              text: 'Customer registry',
              url: '/configure-levels/touchable-revenue/customer-registry',
            },
          ],
        },
        {
          id: 2,
          text: 'Touchable revenue',
          sublinks: [
            {
              id: 1,
              text: 'Survey setting',
              url: '/configure-levels/touchable-revenue/survey-setting',
            },
            {
              id: 2,
              text: 'Survey status',
              url: '/configure-levels/touchable-revenue/survey-status',
            },
            {
              id: 3,
              text: 'Survey result',
              url: '/configure-levels/touchable-revenue/survey-result',
            },
            {
              id: 4,
              text: 'Campaign revenue base',
              url: '/configure-levels/touchable-revenue/revenue-base',
            },
          ],
        },
        {
          id: 3,
          text: 'Campaign target',
          disabled: !isAllowSurveyResult,
          sublinks: [
            {
              id: 1,
              text: 'Campaign target',
              url: '/configure-levels/campaign-target/target-given',
            },
            {
              id: 2,
              text: 'Target setting',
              url: '/configure-levels/campaign-target/target-setting',
            },
          ],
        },
        {
          id: 4,
          text: 'Customer-specific targets',
          disabled: !isAllowSurveyResult,
          sublinks: [
            {
              id: 1,
              text: 'EOI indicator weighting',
              url: '/configure-levels/customer-specific-targets/eoi-weighting',
            },
            {
              id: 2,
              text: 'Business case assumptions',
              url: '/configure-levels/customer-specific-targets/business-case-assumptions',
            },
            {
              id: 3,
              text: 'Target finetuning',
              url: '/configure-levels/customer-specific-targets/target-finetuning',
            },
            {
              id: 4,
              text: 'Customer targets',
              url: '/configure-levels/customer-specific-targets/customer-targets',
            },
            {
              id: 5,
              text: 'Campaign impact',
              url: '/configure-levels/customer-specific-targets/campaign-impact',
            },
            {
              id: 6,
              text: 'Final customer targets',
              url: '/configure-levels/customer-specific-targets/final-customer-targets',
            },
          ],
        },
      ],
    },
    {
      id: 3,
      icon: TableIcon,
      text: 'Prepare implementation',
      disabled: isDisabledNavigation,
      nestedLinks: [
        {
          id: 1,
          text: 'Support material',
          sublinks: [
            {
              id: 1,
              text: 'Battlecards status',
              url: '/prepare-implementation/support-material/battlecards-status',
            },
            {
              id: 2,
              url: '/prepare-implementation/support-material/battlecards',
              text: 'Battle card page',
              disabled: !isAllowSurveyResult,
            },
          ],
        },
      ],
    },
    {
      id: 4,
      text: 'Rollout initiative',
      icon: RolloutIcon,
      disabled: isDisabledNavigation,
      nestedLinks: [
        {
          id: 1,
          text: 'Monitoring reports',
          sublinks: [
            {
              id: 1,
              url: '/rollout-initiative/monitoring-reports/monitoring',
              text: 'Monitoring',
            },
            {
              id: 2,
              url: '/rollout-initiative/monitoring-reports/negotiation-process',
              text: 'Negotiation process',
            },
            {
              id: 3,
              url: '/rollout-initiative/monitoring-reports/negotiation-status',
              text: 'Negotiation-status',
            },
            {
              id: 4,
              url: '/rollout-initiative/monitoring-reports/monitoring-survey',
              text: 'Monitoring survey',
            },
            {
              id: 5,
              url: '/rollout-initiative/monitoring-reports/performance-breakdown',
              text: 'Performance breakdown',
            },
          ],
        },
      ],
    },
  ];
};

export const getAccountManagerMenuLinks = (availableScreens?: ScreenName[]) => {
  return [
    {
      id: 0,
      text: 'Survey',
      icon: Surveys,
      disabled: false,
      open: true,
      nestedLinks: [
        {
          id: 0,
          url: '/survey/initial-survey',
          text: 'Initial survey',
          hide:
            availableScreens &&
            !availableScreens.find((screen) => screen.screenName === 'SURVEY_RESULT'),
        },
        {
          id: 1,
          url: '/survey/monitoring-survey',
          text: 'Monitoring survey',
          hide:
            availableScreens &&
            !availableScreens.find((screen) => screen.screenName === 'SECOND_SURVEY'),
        },
      ],
    },
  ];
};

export const battleCardsCompletingLinks = [
  {
    id: 0,
    text: 'Battlecards',
    icon: TableIcon,
    url: '/battlecards',
  },
];

import React, { useCallback, useEffect, useMemo } from 'react';
import { Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getCampaignImpact } from 'src/modules/specificTargets/actions';
import { Store } from 'src/types';

import { useNavigate } from 'react-router-dom';

import { Loader } from 'src/components/common/Loader';
import { CampaignImpactTable } from './components/CampaignImpactTable';
import { NoDataPlaceholder } from 'src/components/common/NoDataPlaceholder';
import { ButtonBase } from 'src/components/common/ButtonBase';
import { Stepper } from '../../../../common/Stepper';
import { StepNames } from '../../../../../constants/stepNames';

export const CampaignImpact: React.FC = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { campaignImpact, loading } = useSelector((state: Store) => state.specificTargets);

  const goNext = useCallback(() => {
    navigate('/configure-levels/customer-specific-targets/final-customer-targets ');
  }, [navigate]);

  const goBack = useCallback(() => {
    navigate('/configure-levels/customer-specific-targets/customer-targets');
  }, [navigate]);

  const EnhancedTableContainer = useMemo(() => {
    if (loading) {
      return <Loader isLoading={true} marginTop={40} />;
    }

    return !campaignImpact ? (
      <NoDataPlaceholder text="No data yet. Please try again later." />
    ) : (
      <CampaignImpactTable {...campaignImpact} />
    );
  }, [campaignImpact, loading]);

  useEffect(() => {
    dispatch(getCampaignImpact.request());
  }, [dispatch]);

  return (
    <div>
      <Stepper
        steps={[
          {
            name: 'Touchable revenue',
            activeIndex: null,
            allCompleted: true,
          },
          {
            name: 'Campaign target',
            activeIndex: null,
            allCompleted: true,
          },
          {
            name: 'Customer-specific targets',
            activeIndex: 4,
          },
        ]}
        type={StepNames.ALL}
      />

      {EnhancedTableContainer}

      <Stack spacing={6} direction="row" marginTop={loading ? 60 : 8} justifyContent="flex-end">
        <ButtonBase
          variant="outlined"
          title="Customer targets"
          onClick={goBack}
          icon="goBack"
          iconLeft={true}
        />
        <ButtonBase
          variant="contained"
          title="Final customer targets"
          onClick={goNext}
          icon="next"
        />
      </Stack>
    </div>
  );
};

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  itemContainer: {
    marginBottom: '16px',
    position: 'relative',
    width: '100%',
  },
  dragIcon: {
    position: 'absolute',
    right: 40,
    top: '10px',
  },
  deleteIcon: {
    position: 'absolute',
    right: '4px',
    top: '12px',
  },
  input: {
    width: '80%',
  },
}));

export default useStyles;

import { createAction } from 'deox';
import {
  TemplateMailResponse,
  LoginRequestBody,
  LoginResponse,
  LoginSSOResponse,
  UpdateTemplateMailRequestParams,
  TemplateMailRequestParams,
} from 'src/types';
import { ResponseError } from '../Errors/actions';
import { ROLES } from '../../constants/ROLES';

export const resetStore = createAction('app/RESET_STORE');

export const login = {
  request: createAction(
    'login/LOGIN_REQUEST',
    (resolve) => (payload: LoginRequestBody) => resolve(payload),
  ),
  success: createAction(
    'login/LOGIN_SUCCESS',
    (resolve) => (payload: LoginResponse) => resolve(payload),
  ),
  fail: createAction('login/LOGIN_FAIL', (resolve) => (error: ResponseError) => resolve(error)),
};

export const loginSSO = {
  request: createAction(
    'login/LOGIN_SSO_REQUEST',
    (resolve) => (payload: string) => resolve(payload),
  ),
  success: createAction(
    'login/LOGIN_SSO_SUCCESS',
    (resolve) => (payload: LoginSSOResponse) => resolve(payload),
  ),
  fail: createAction('login/LOGIN_FAIL', (resolve) => (error: ResponseError) => resolve(error)),
};

export const changeRoleForRedirectToUnauthorized = createAction(
  'app/CHANGE_ROLE',
  (resolve) => (payload: ROLES) => resolve(payload),
);
export const saveManagerLoginLink = createAction(
  'app/SAVE_MANAGER_LOGIN_LINK',
  (resolve) => (payload: string) => resolve(payload),
);

export const removeToken = createAction('app/REMOVE_TOKEN');

export const getTemplateMail = {
  request: createAction(
    'app/GET_TEMPLATE_MAIL_REQUEST',
    (resolve) => (payload: TemplateMailRequestParams) => resolve(payload),
  ),
  success: createAction(
    'app/GET_TEMPLATE_MAIL_SUCCESS',
    (resolve) => (payload: TemplateMailResponse) => resolve(payload),
  ),
  fail: createAction(
    'app/GET_TEMPLATE_MAIL_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const clearTemplateForEmail = createAction('app/CLEAR_TEMPLATE_TYPE');

export const updateTemplateMail = {
  request: createAction(
    'app/UPDATE_TEMPLATE_MAIL_REQUEST',
    (resolve) => (payload: UpdateTemplateMailRequestParams) => resolve(payload),
  ),
  success: createAction(
    'app/UPDATE_TEMPLATE_MAIL_SUCCESS',
    (resolve) => (payload: TemplateMailResponse) => resolve(payload),
  ),
  fail: createAction(
    'app/UPDATE_TEMPLATE_MAIL_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

import React, { useContext, FC } from 'react';

import { DeleteButton } from 'src/components/common/DeleteButton';
import { ReactComponent as WarningIcon } from 'src/assets/icons/warning.svg';
import { SurveySettingsCategories } from 'src/constants/SurveySettingCategories';
import { EditableCell } from 'src/components/common/EditableCell';
import { SurveysProvider } from '../../../SurveysProvider';
import useStyles from '../../ContractCommitmentItem/useStyles';

type OtherOptionsFreeTextItemProps = {
  id: number;
  onChangeValueCb: (value: string, id: number) => void;
  error: boolean;
  option: string;
};

export const OtherOptionsFreeTextItem: FC<OtherOptionsFreeTextItemProps> = ({
  id,
  onChangeValueCb,
  error,
  option,
}) => {
  const classes = useStyles();

  const { onDeleteOption, handleCleanError, disabled } = useContext(SurveysProvider);

  const onChange = (value: string) => {
    if (error) {
      handleCleanError(id, 'other-option');
    }
    if (value.slice(-1) === ' ') {
      return;
    }
    if (value !== option) {
      onChangeValueCb(value, id);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.inputContainer}>
        {error && <WarningIcon className={classes.listItemErrorIcon} />}

        <EditableCell
          isInput
          buttonStyle={`${classes.input} ${disabled && classes.inputDisabled} ${
            error && classes.listItemErrorInput
          }`}
          innerInputStyle={classes.input}
          disabled={disabled}
          value={option}
          onEditCb={onChange}
        />
      </div>

      <DeleteButton
        disabled={disabled}
        styles={classes.deleteButton}
        onClick={() => {
          if (!disabled) {
            onDeleteOption(id, SurveySettingsCategories.otherOptionsText);
          }
        }}
      />
    </div>
  );
};

import React, { useEffect, useMemo } from 'react';
import { Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getSettingsOverview } from 'src/modules/home/actions';
import { Store } from 'src/types';
import { Loader } from 'src/components/common/Loader';
import { SettingsOverviewTable } from './components/SettingsOverviewTable';
import { ButtonBase } from '../../../../common/ButtonBase';
import useStyles from './useStyles';
import { useNavigate } from 'react-router-dom';

export const SettingsOverview: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { settings, loading } = useSelector((state: Store) => state.home);

  useEffect(() => {
    dispatch(getSettingsOverview.request());
  }, [dispatch]);

  const handleGoTo = () => {
    navigate('/home/status-overview');
  };

  const EnhancedTable = useMemo(() => {
    return loading || !settings ? (
      <Loader isLoading={true} marginTop={40} />
    ) : (
      <>
        <SettingsOverviewTable {...settings} />
        <div className={classes.buttonWrapper}>
          <ButtonBase
            inlineStyle={{ paddingRight: 5 }}
            title=""
            icon="goBack"
            variant="outlined"
            onClick={handleGoTo}
          />
        </div>
      </>
    );
  }, [loading, settings]);

  return (
    <>
      <Typography variant="h2">Settings overview</Typography>
      {EnhancedTable}
    </>
  );
};

import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  tableContainer: {
    overflowY: 'auto',
    marginTop: 34,
    maxHeight: 'calc(100vh - 250px)',
    boxShadow: '0px 2px 10px rgba(199, 201, 201, 0.5)',
    borderRadius: 10,
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    overflow: 'hidden',
    '& thead': {
      borderBottom: `1px solid ${COLORS.gray2}`,
    },
    '& thead tr:first-child th:first-child': {
      textAlign: 'start',
      paddingLeft: 16,
    },
    '& tbody tr td:first-child div': {
      textAlign: 'start',
      paddingLeft: 16,
    },
    '& th': {
      padding: 0,
      height: 52,
      backgroundColor: COLORS.blue12,
      color: COLORS.black2,
      fontSize: 18,
    },
    '& td': {
      height: 56,
      fontSize: 18,
      color: COLORS.black4,
      '& div': {
        textAlign: 'center',
      },
    },
    '& tr:nth-child(even)': {
      backgroundColor: COLORS.blue13,
      '& button': {
        backgroundColor: COLORS.blue13,
      },
    },
    '& tbody tr:last-child': {
      borderBottom: `6px solid ${COLORS.gray4}`,
    },
  },
  topCell: {
    height: 45,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    position: 'relative',
    borderBottomWidth: `1px`,
    borderBottomColor: COLORS.gray16,
    borderBottomStyle: 'solid',
    '&::after': {
      content: '""',
      position: 'absolute',
      height: 113,
      width: 1,
      backgroundColor: COLORS.gray16,
      left: 0,
      top: -12,
    },
  },
}));

export default useStyles;

import React, { memo, useState } from 'react';
import useStyles from '../DeleteButton/useStyles';
import { ReactComponent as EditIcon } from 'src/assets/icons/edit.svg';
import { COLORS } from 'src/constants/COLORS';

type Props = {
  styles?: string;
  onClick: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  disabled?: boolean;
};

export const EditButton: React.FC<Props> = memo(function EditButton({ styles, onClick, disabled }) {
  const [isMouseOver, setIsMouseOver] = useState(false);

  const classes = useStyles();

  return (
    <EditIcon
      onClick={onClick}
      onMouseOver={() => {
        setIsMouseOver(true);
      }}
      onMouseLeave={() => {
        setIsMouseOver(false);
      }}
      className={`${classes.icon} ${disabled && classes.iconDisabled} ${styles && styles}`}
      fill={disabled ? COLORS.gray3 : isMouseOver ? COLORS.red1 : COLORS.black4}
    />
  );
});

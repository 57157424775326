import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  table: {
    emptyCells: 'show',
    borderCollapse: 'separate',
    borderSpacing: '0 10px',
    marginTop: 134,
    minWidth: '55%',
    '& th': {
      backgroundColor: COLORS.blue12,
      height: 42,
      fontSize: 18,
      color: COLORS.black1,
      fontWeight: 400,
      minWidth: 140,
    },
    '& tbody tr:nth-child(even)': {
      backgroundColor: COLORS.blue13,
    },
    '& th:first-child': {
      width: 170,
      backgroundColor: 'transparent',
    },
    '& tbody td': {
      height: 60,
      textAlign: 'center',
    },
    '& tbody tr td:first-child': {
      paddingLeft: 10,
      width: 170,
      textAlign: 'start',
    },
  },
  numberInputContainer: {
    padding: '0 5px',
    display: 'flex',
    justifyContent: 'center',
  },
  numberInput: {
    width: 125,
    height: 40,
    fontSize: 16,
    textAlign: 'center',
    border: `1px solid ${COLORS.gray26}`,
    borderRadius: 8,
  },
}));

export default useStyles;

import React, { useCallback, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Store } from 'src/types';

import useStyles from './useStyles';
import { SurveySettingBox } from 'src/components/common/SurveySettingBox/inxex';
import { ButtonBase } from 'src/components/common/ButtonBase';
import { COLORS } from 'src/constants/COLORS';
import { ContractStatusOptions } from './components/ContractStatusOptions';
import { EaseOfIncreaseDrivers } from './components/EaseOfIncreaseDrivers';
import { ContractCommitments } from './components/ContractCommitments';
import { PlanBOptions } from './components/PlanBOptions';

import { SurveysProvider } from './SurveysProvider';

import {
  deleteSurveySettingsOption as deleteSurveySettingsOptionAction,
  getSurveysSettings,
} from 'src/modules/surveys/actions';
import { toast } from 'react-toastify';
import { ForbidEditCheckbox } from 'src/components/common/ForbidEditCheckbox';
import { ScreenNames } from 'src/constants/ScreenNames';
import { GeneralSurveySetting } from './components/GeneralSurveySetting';
import { Stepper } from '../../../../common/Stepper';
import { StepNames } from '../../../../../constants/stepNames';
import OtherOptionsDropdown from './components/OtherOptionsDropdown';
import OtherOptionsFreeText from './components/OtherOptionsFreeText';

export type ErrorOption = {
  category: string;
  ids: number[];
};

export const SurveySetting: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [errorInCategories, setErrorInCategories] = useState<ErrorOption[]>([]);

  const { projectId } = useSelector((state: Store) => state.projects);

  const navigate = useNavigate();

  const { surveySettings, loading } = useSelector((state: Store) => state.surveys);
  const { disableEditPage } = useSelector((state: Store) => state.home);

  const validate = () => {
    if (surveySettings) {
      const emptyFields = Object.entries(surveySettings).reduce((prev: ErrorOption[], item) => {
        if (Array.isArray(item[1])) {
          const emptyIds = item[1]
            .filter((option) => option.option.length === 0)
            .map((emptyField) => emptyField.id);

          if (emptyIds.length > 0) {
            return [
              ...prev,
              {
                category: item[0],
                ids: emptyIds,
              },
            ];
          }
        }

        return [...prev];
      }, []);

      setErrorInCategories(emptyFields);

      return emptyFields.length > 0;
    }
  };

  const cleanErrors = (fieldId: number, category: string) => {
    const newArray = errorInCategories.map((item) =>
      item.category === category
        ? {
            category,
            ids: item.ids.filter((id) => id !== fieldId),
          }
        : item,
    );

    setErrorInCategories(newArray);
  };

  const checkForEmptyFields = () => {
    const hasEmptyFields = validate();

    if (hasEmptyFields) {
      toast.error('Some fields are incorrect or empty.');

      return false;
    }

    return true;
  };

  const goNext = () => {
    const isValid = checkForEmptyFields();

    if (isValid) {
      navigate('/configure-levels/touchable-revenue/survey-status');
    }
  };

  const goBack = () => {
    navigate('/configure-levels/touchable-revenue/customer-registry');
  };

  const deleteSurveysSettingsOption = useCallback(
    (optionId, category) => {
      dispatch(deleteSurveySettingsOptionAction.request({ requestParams: { optionId }, category }));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(
      getSurveysSettings.request({ requestPayload: { id: projectId }, needShowLoader: true }),
    );
  }, [dispatch, projectId]);

  const otherOptions = surveySettings?.otherOptions ?? [];

  const { freeTextOptions, dropdownOptions } = otherOptions.reduce(
    (acc, option) => {
      if (Array.isArray(option.items)) {
        acc.dropdownOptions.push(option);
      } else {
        acc.freeTextOptions.push(option);
      }
      return acc;
    },
    { freeTextOptions: [] as any, dropdownOptions: [] as any },
  );

  return (
    <SurveysProvider.Provider
      value={{
        onDeleteOption: deleteSurveysSettingsOption,
        handleCleanError: cleanErrors,
        disabled: disableEditPage || loading,
      }}>
      <div>
        <Stepper
          steps={[
            {
              name: 'Touchable revenue',
              activeIndex: 0,
            },
            {
              name: 'Campaign target',
              activeIndex: null,
            },
            {
              name: 'Customer-specific targets',
              activeIndex: null,
            },
          ]}
          type={StepNames.ALL}
        />
        {surveySettings && (
          <GeneralSurveySetting
            isDisabled={disableEditPage}
            showLastPriceIncreaseDate={!!surveySettings.showLastPriceIncreaseDate}
            allowSegmentSpecificInput={!!surveySettings.allowSegmentSpecificInput}
            showContractExpirationDate={!!surveySettings.showContractExpirationDate}
          />
        )}

        <div className={classes.container}>
          <SurveySettingBox loading={loading} title="Contract status options">
            <ContractStatusOptions
              idsWithError={errorInCategories.find(
                (item) => item.category === 'contractStatusOptions',
              )}
              contractStatusOptions={
                surveySettings ? surveySettings.contractStatusOptions : undefined
              }
            />
          </SurveySettingBox>
          <SurveySettingBox loading={loading} title="Ease of increase driver">
            <EaseOfIncreaseDrivers
              idsWithError={errorInCategories.find(
                (item) => item.category === 'easeOfIncreaseDrivers',
              )}
              easeOfIncreaseDrivers={
                surveySettings ? surveySettings.easeOfIncreaseDrivers : undefined
              }
            />
          </SurveySettingBox>
          <SurveySettingBox loading={loading} title="Contract commitment breach options">
            <ContractCommitments
              idsWithError={errorInCategories.find(
                (item) => item.category === 'contractCommitments',
              )}
              contractCommitments={surveySettings ? surveySettings.contractCommitments : undefined}
            />
          </SurveySettingBox>
          <SurveySettingBox loading={loading} title="Plan B options">
            <PlanBOptions
              idsWithError={errorInCategories.find((item) => item.category === 'planBOptions')}
              planBOptions={surveySettings ? surveySettings.planBOptions : undefined}
            />
          </SurveySettingBox>
          <SurveySettingBox title={'Other [dropdown]'} loading={loading}>
            <OtherOptionsDropdown dropdownOptions={surveySettings ? dropdownOptions : undefined} />
          </SurveySettingBox>
          <SurveySettingBox title={'Other [free text]'} loading={loading}>
            <OtherOptionsFreeText freeTextOptions={surveySettings ? freeTextOptions : undefined} />
          </SurveySettingBox>
        </div>
        <Stack spacing={4} direction="row" marginTop={8} justifyContent="space-between">
          <ForbidEditCheckbox
            showCheckbox={
              surveySettings
                ? surveySettings.contractCommitments.length > 0 ||
                  surveySettings.contractStatusOptions.length > 0 ||
                  surveySettings.planBOptions.length > 0 ||
                  surveySettings.easeOfIncreaseDrivers.length > 0
                : false
            }
            checkForEmptyFields={checkForEmptyFields}
            screenName={ScreenNames.ACCOUNT_MANAGER_SURVEY_SETTINGS}
            checkboxValue={disableEditPage}
          />
          <Stack direction="row" spacing={4}>
            <ButtonBase
              iconColor={COLORS.red1}
              variant="outlined"
              title="Customer registry"
              onClick={goBack}
              icon="goBack"
              iconLeft={true}
            />
            <ButtonBase
              iconColor={COLORS.red1}
              variant="contained"
              title="Survey status"
              onClick={goNext}
              icon="next"
            />
          </Stack>
        </Stack>
      </div>
    </SurveysProvider.Provider>
  );
};

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { IconButton, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CustomizeEmailIcon } from 'src/assets/icons/customize-email.svg';
import { useNavigate } from 'react-router-dom';

import useStyles from './useStyles';

import { DEFAULT_COUNT_OF_ITEMS } from 'src/constants/dafaultCountOfItems';

import {
  getSurveyStatuses as getSurveyStatusesAction,
  sendSurveyToAccountManager,
  sendSurveyToAll,
} from 'src/modules/TouchableRevenue/actions';

import { Store } from 'src/types';

import { COLORS } from 'src/constants/COLORS';

import { ButtonBase } from 'src/components/common/ButtonBase';
import { Pagination } from 'src/components/common/Pagination';
import { Loader } from 'src/components/common/Loader';
import { NoDataPlaceholder } from 'src/components/common/NoDataPlaceholder';
import { SurveyStatusTable } from 'src/components/common/SurveyStatusTable';
import { toast } from 'react-toastify';
import { StepNames } from '../../../../../constants/stepNames';
import { Stepper } from '../../../../common/Stepper';
import { MailTemplateModal } from '../../../../common/MailTemplateModal';
import { getTemplateMail } from 'src/modules/app/actions';

const TEMPLATE_TYPE = 'SURVEY';

export const SurveyStatus: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_COUNT_OF_ITEMS);
  const [isOpenMailTemplateModal, setIsOpenMailTemplateModal] = useState<boolean>(false);

  const { loading, surveyStatuses, loadingButtons } = useSelector(
    (state: Store) => state.touchableRevenue,
  );

  const { surveyResultAccessing } = useSelector((state: Store) => state.surveys);

  const onSendSurveyToAll = () => {
    dispatch(
      sendSurveyToAll.request({
        surveyNumber: 1,
      }),
    );
  };

  const getSurveyStatuses = useCallback(
    ({
      sortBy,
      pageNumber,
      itemsOnPage,
    }: {
      sortBy: string;
      itemsOnPage: number;
      pageNumber: number;
    }) => {
      dispatch(
        getSurveyStatusesAction.request({
          pageNumber,
          itemsOnPage,
          sortBy,
          sortOrder: 'asc',
        }),
      );
    },
    [dispatch],
  );

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleChangeRowsPerPage = (value: number) => {
    setRowsPerPage(value);
    setPage(0);
  };

  const onSendSurveyToAccManager = useCallback(
    (accManagerId: number) => {
      dispatch(
        sendSurveyToAccountManager.request({
          body: {
            accountManagerId: accManagerId,
          },
          params: {
            surveyNumber: 1,
          },
        }),
      );
    },
    [dispatch],
  );

  const goNext = () => {
    if (surveyResultAccessing?.allowedSurveyResultPage) {
      navigate('/configure-levels/touchable-revenue/survey-result');
    } else {
      toast.warn('Survey result is not available yet. Please wait.');
    }
  };

  const goBack = () => {
    navigate('/configure-levels/touchable-revenue/survey-setting');
  };

  const goToPage = useCallback((page: number) => {
    setPage(page);
  }, []);

  const EnhancedTable = useMemo(() => {
    return !surveyStatuses || (surveyStatuses && surveyStatuses.data.length === 0) ? (
      <NoDataPlaceholder text="No data yet. Please try again later." />
    ) : (
      <SurveyStatusTable
        onSendSurvey={onSendSurveyToAccManager}
        surveyStatus={surveyStatuses.data}
        allowSendSurveys={surveyResultAccessing?.allowedSendToAll}
      />
    );
  }, [onSendSurveyToAccManager, surveyResultAccessing?.allowedSendToAll, surveyStatuses]);

  useEffect(() => {
    getSurveyStatuses({
      sortBy: '',
      pageNumber: page === 0 ? page : page - 1,
      itemsOnPage: rowsPerPage,
    });
  }, [getSurveyStatuses, page, rowsPerPage]);

  const handleOpenMailTemplateModal = () => {
    setIsOpenMailTemplateModal(true);
    dispatch(
      getTemplateMail.request({
        templateType: TEMPLATE_TYPE,
      }),
    );
  };

  const handleCloseMailTemplateModal = () => {
    setIsOpenMailTemplateModal(false);
  };

  return (
    <Stack className={classes.container}>
      <Stepper
        steps={[
          {
            name: 'Touchable revenue',
            activeIndex: 1,
          },
          {
            name: 'Campaign target',
            activeIndex: null,
          },
          {
            name: 'Customer-specific targets',
            activeIndex: null,
          },
        ]}
        type={StepNames.ALL}
      />
      {isOpenMailTemplateModal && (
        <MailTemplateModal
          open={isOpenMailTemplateModal}
          templateType={TEMPLATE_TYPE}
          onClose={handleCloseMailTemplateModal}
        />
      )}
      <Stack
        spacing={4}
        direction="row"
        marginTop={4}
        justifyContent="flex-end"
        alignItems={'center'}>
        {surveyStatuses && surveyStatuses.data.length > 0 && (
          <ButtonBase
            inlineStyle={{ width: 165 }}
            isLoading={loadingButtons}
            containerStyle={classes.sendAllButtonContainer}
            onClick={onSendSurveyToAll}
            disabled={!surveyResultAccessing?.allowedSendToAll}
            variant="outlined"
            title="Send survey to all"
            loadingColor="secondary"
          />
        )}
        <IconButton onClick={handleOpenMailTemplateModal}>
          <CustomizeEmailIcon fill={COLORS.black4} />
        </IconButton>
      </Stack>

      {loading && (
        <div className={classes.loaderContainer}>
          <Loader isLoading={true} />
        </div>
      )}

      {EnhancedTable}

      {surveyStatuses && (
        <Pagination
          totalPages={surveyStatuses.pageable.totalPages}
          setRowPerPage={handleChangeRowsPerPage}
          setPage={handleChangePage}
          page={page}
          rowPerPage={rowsPerPage}
          marginTop={4}
          goToPage={goToPage}
        />
      )}

      <Stack spacing={4} direction="row" marginTop={4} justifyContent="flex-end">
        <ButtonBase
          iconColor={COLORS.red1}
          variant="outlined"
          iconLeft={true}
          title="Survey setting"
          onClick={goBack}
          icon="goBack"
        />
        <ButtonBase
          iconColor={COLORS.red1}
          variant="contained"
          title="Survey result"
          onClick={goNext}
          icon="next"
        />
      </Stack>
    </Stack>
  );
};

import React, { useCallback, useMemo } from 'react';
import { CampaignImpact, GetCampaignImpactResponse } from 'src/types';
import useStyles from './useStyles';
import { formatNumberWithCommas } from '../../../../../../../helpers/formatNumberWithCommas';

type Props = GetCampaignImpactResponse;

export const CampaignImpactTable: React.FC<Props> = ({ withoutExceptions, withExceptions }) => {
  const classes = useStyles();

  const TableHeaderCells = useMemo(() => {
    return withoutExceptions.length > 0
      ? withoutExceptions.map((item) => (
          <th key={item.businessUnitName}>
            <div>{item.businessUnitName}</div>
          </th>
        ))
      : null;
  }, [withoutExceptions]);

  const renderGrossImpact = useCallback(
    (impacts: CampaignImpact[]) =>
      impacts.map((item) => (
        <td key={item.businessUnitName}>
          <div>{formatNumberWithCommas(item.grossImpact)}</div>
        </td>
      )),
    [],
  );

  const renderNegotiationErosion = useCallback(
    (impacts: CampaignImpact[]) =>
      impacts.map((item) => (
        <td key={item.businessUnitName}>
          <div>{formatNumberWithCommas(item.negotiationErosion)}</div>
        </td>
      )),
    [],
  );

  const renderChurn = useCallback(
    (impacts: CampaignImpact[]) =>
      impacts.map((item) => (
        <td key={item.businessUnitName}>
          <div>{formatNumberWithCommas(item.churn)}</div>
        </td>
      )),
    [],
  );

  const renderNetImpact = useCallback(
    (impacts: CampaignImpact[]) =>
      impacts.map((item) => (
        <td key={item.businessUnitName}>
          <div className={classes.innerContainer}>
            <div>{formatNumberWithCommas(item.netImpact)}</div>
            {item.netImpactDelta !== null && <div>({item.netImpactDelta})</div>}
          </div>
        </td>
      )),
    [classes.innerContainer],
  );

  const renderTargetImpact = useCallback(
    (impacts: CampaignImpact[]) =>
      impacts.map((item) => (
        <td key={item.businessUnitName}>
          <div className={classes.innerContainer}>
            <div>{formatNumberWithCommas(item.targetImpact)}</div>
            {item.targetImpactDelta !== null && <div>({item.targetImpactDelta})</div>}
          </div>
        </td>
      )),
    [classes.innerContainer],
  );

  return (
    <div className={classes.container}>
      <table className={classes.table}>
        <thead>
          <tr className={classes.theadRow}>
            <th colSpan={2} />
            {TableHeaderCells}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowSpan={5}>
              <div>W/o exceptions</div>
            </td>
            <td>
              <div>Gross impact</div>
            </td>
            {renderGrossImpact(withoutExceptions)}
          </tr>
          <tr>
            <td>
              <div>Negotiation erosion</div>
            </td>
            {renderNegotiationErosion(withoutExceptions)}
          </tr>
          <tr>
            <td>
              <div>Churn</div>
            </td>
            {renderChurn(withoutExceptions)}
          </tr>
          <tr>
            <td>
              <div>Net impact</div>
            </td>
            {renderNetImpact(withoutExceptions)}
          </tr>
          <tr>
            <td>
              <div>Target impact</div>
            </td>
            {renderTargetImpact(withoutExceptions)}
          </tr>
          <tr>
            <td colSpan={withoutExceptions.length + 2} />
          </tr>
          <tr>
            <td rowSpan={5}>
              <div>With exception</div>
            </td>
            <td>
              <div>Gross impact</div>
            </td>
            {renderGrossImpact(withExceptions)}
          </tr>
          <tr>
            <td>
              <div>Negotiation erosion</div>
            </td>
            {renderNegotiationErosion(withExceptions)}
          </tr>
          <tr>
            <td>
              <div>Churn</div>
            </td>
            {renderChurn(withExceptions)}
          </tr>
          <tr>
            <td>
              <div>Net impact</div>
            </td>
            {renderNetImpact(withExceptions)}
          </tr>
          <tr>
            <td>
              <div>Target impact</div>
            </td>
            {renderTargetImpact(withExceptions)}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: COLORS.white1,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '340px',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    border: 'none',
    padding: '24px',
    outline: 'none',
    borderRadius: 8,
  },
  button: {
    marginTop: 15,
    '& button': {
      width: '100%',
    },
  },
  text: {
    fontSize: 18,
    color: COLORS.black2,
  },
}));

export default useStyles;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IconButton, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { DEFAULT_COUNT_OF_ITEMS } from 'src/constants/dafaultCountOfItems';
import { ReactComponent as CustomizeEmailIcon } from 'src/assets/icons/customize-email.svg';

import useStyles from './useStyles';

import { Store } from 'src/types';

import { COLORS } from 'src/constants/COLORS';

import { ButtonBase } from 'src/components/common/ButtonBase';
import { Pagination } from 'src/components/common/Pagination';
import { Loader } from 'src/components/common/Loader';
import { NoDataPlaceholder } from 'src/components/common/NoDataPlaceholder';
// eslint-disable-next-line max-len
import {
  getBattleCardsMailStatuses as getBattleCardsMailStatusesAction,
  sendBattleCardsMailStatusesToAll,
  sendBattleCardsToAccountManager,
} from '../../../../../modules/battleCards/actions';
import { BattleCardsStatusTable } from 'src/components/common/BattleCardsStatusTable';
import { MailTemplateModal } from '../../../../common/MailTemplateModal';
import { getTemplateMail } from '../../../../../modules/app/actions';

const TEMPLATE_TYPE = 'BATTLE_CARD';

const BattleCardsStatus: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_COUNT_OF_ITEMS);

  const [isOpenMailTemplateModal, setIsOpenMailTemplateModal] = useState(false);

  const { loading, battleCardsMailStatuses, loadingButtons } = useSelector(
    (state: Store) => state.battleCards,
  );

  const onSendBattleCardsStatusesToAll = () => {
    dispatch(
      sendBattleCardsMailStatusesToAll.request({
        battleCardsNumber: 1,
      }),
    );
  };

  const getBattleCardsMailStatuses = useCallback(
    ({
      sortBy,
      pageNumber,
      itemsOnPage,
    }: {
      sortBy: string;
      itemsOnPage: number;
      pageNumber: number;
    }) => {
      dispatch(
        getBattleCardsMailStatusesAction.request({
          pageNumber,
          itemsOnPage,
          sortBy,
          sortOrder: 'asc',
        }),
      );
    },
    [dispatch],
  );

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleChangeRowsPerPage = (value: number) => {
    setRowsPerPage(value);
    setPage(0);
  };

  const onSendBattleCardsToAccManager = useCallback(
    (accManagerId: number) => {
      dispatch(
        sendBattleCardsToAccountManager.request({
          battleCardsNumber: 1,
          accountManagerId: accManagerId,
        }),
      );
    },
    [dispatch],
  );

  const goNext = () => {
    navigate('/prepare-implementation/support-material/battlecards');
  };

  const goBack = () => {
    navigate('/configure-levels/customer-specific-targets/final-customer-targets');
  };

  const goToPage = useCallback((page: number) => {
    setPage(page);
  }, []);

  const handleOpenMailTemplateModal = () => {
    setIsOpenMailTemplateModal(true);
    dispatch(
      getTemplateMail.request({
        templateType: TEMPLATE_TYPE,
      }),
    );
  };

  const handleCloseMailTemplateModal = () => {
    setIsOpenMailTemplateModal(false);
  };

  const EnhancedTable = useMemo(() => {
    return !battleCardsMailStatuses ||
      (battleCardsMailStatuses && battleCardsMailStatuses.data.length === 0) ? (
      <NoDataPlaceholder text="No data yet. Please try again later." />
    ) : (
      <BattleCardsStatusTable
        onSendBattleCards={onSendBattleCardsToAccManager}
        battleCardsStatus={battleCardsMailStatuses.data}
      />
    );
  }, [onSendBattleCardsToAccManager, battleCardsMailStatuses]);

  useEffect(() => {
    getBattleCardsMailStatuses({
      sortBy: '',
      pageNumber: page === 0 ? page : page - 1,
      itemsOnPage: rowsPerPage,
    });
  }, [getBattleCardsMailStatuses, page, rowsPerPage]);

  return (
    <div className={classes.container}>
      <Stack
        spacing={4}
        direction="row"
        marginTop={4}
        justifyContent="flex-end"
        alignItems={'center'}>
        {battleCardsMailStatuses && battleCardsMailStatuses.data.length > 0 && (
          <ButtonBase
            inlineStyle={{ width: 165 }}
            isLoading={loadingButtons}
            containerStyle={classes.sendAllButtonContainer}
            onClick={onSendBattleCardsStatusesToAll}
            variant="outlined"
            title="Send to all"
            loadingColor="secondary"
          />
        )}
        <IconButton onClick={handleOpenMailTemplateModal}>
          <CustomizeEmailIcon fill={COLORS.black4} />
        </IconButton>
      </Stack>

      {loading && (
        <div className={classes.loaderContainer}>
          <Loader isLoading={true} />
        </div>
      )}

      {EnhancedTable}

      {battleCardsMailStatuses && (
        <Pagination
          totalPages={battleCardsMailStatuses.pageable.totalPages}
          setRowPerPage={handleChangeRowsPerPage}
          setPage={handleChangePage}
          page={page}
          rowPerPage={rowsPerPage}
          marginTop={4}
          goToPage={goToPage}
        />
      )}

      <Stack spacing={4} direction="row" marginTop={4} justifyContent="flex-end">
        <ButtonBase
          iconColor={COLORS.red1}
          variant="outlined"
          iconLeft={true}
          title="Final customer targets"
          onClick={goBack}
          icon="goBack"
        />
        <ButtonBase
          iconColor={COLORS.red1}
          variant="contained"
          title="Battlecards"
          onClick={goNext}
          icon="next"
        />
        {isOpenMailTemplateModal && (
          <MailTemplateModal
            open={isOpenMailTemplateModal}
            templateType={TEMPLATE_TYPE}
            onClose={handleCloseMailTemplateModal}
          />
        )}
      </Stack>
    </div>
  );
};

export default BattleCardsStatus;

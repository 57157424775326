import React, { useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import { Box, Tab } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import { Outlet } from 'react-router-dom';
import { COLORS } from 'src/constants/COLORS';

const MONITORING_LINKS = [
  '/rollout-initiative/monitoring-reports/monitoring',
  '/rollout-initiative/monitoring-reports/negotiation-process',
  '/rollout-initiative/monitoring-reports/negotiation-status',
  '/rollout-initiative/monitoring-reports/monitoring-survey',
  '/rollout-initiative/monitoring-reports/performance-breakdown',
];

interface LinkTabProps {
  label?: string;
  href: string;
}

function LinkTab(props: LinkTabProps) {
  return (
    <Tab
      sx={{ color: COLORS.black1, fontWeight: 400, textTransform: 'none', fontSize: 16 }}
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

export const KpisTabs: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    switch (newValue) {
      case 0:
        navigate(MONITORING_LINKS[0]);
        setValue(0);
        break;
      case 1:
        navigate(MONITORING_LINKS[1]);
        setValue(1);
        break;
      case 2:
        navigate(MONITORING_LINKS[2]);
        setValue(2);
        break;
      case 3:
        navigate(MONITORING_LINKS[3]);
        setValue(3);
        break;
      case 4:
        navigate(MONITORING_LINKS[4]);
        setValue(4);
        break;
    }
  };

  useEffect(() => {
    switch (pathname) {
      case MONITORING_LINKS[0]:
        return setValue(0);
      case MONITORING_LINKS[1]:
        return setValue(1);
      case MONITORING_LINKS[2]:
        return setValue(2);
      case MONITORING_LINKS[3]:
        return setValue(3);
      case MONITORING_LINKS[4]:
        return setValue(4);
    }
  }, [pathname]);

  return (
    <div>
      <Tabs
        indicatorColor="secondary"
        value={value}
        sx={{ margin: '-20px -2.5% 0', padding: '0 2%', bgcolor: COLORS.gray4 }}
        onChange={handleChange}>
        <LinkTab label="Monitoring" href="/rollout-initiative/monitoring-reports/monitoring" />
        <LinkTab
          label="Negotiation process"
          href="/rollout-initiative/monitoring-reports/negotiation-process"
        />
        <LinkTab
          label="Negotiation status"
          href="/rollout-initiative/monitoring-reports/negotiation-status"
        />
        <LinkTab
          label="Monitoring survey"
          href="/rollout-initiative/monitoring-reports/monitoring-survey"
        />
        <LinkTab
          label="Performance breakdown"
          href="/rollout-initiative/monitoring-reports/performance-breakdown"
        />
      </Tabs>
      <Box sx={{ mt: 4 }}>
        <Outlet />
      </Box>
    </div>
  );
};

import React, { memo, useCallback, useContext, useMemo } from 'react';
import { Dropdown } from 'src/components/common/Dropdown';
import useStyles from '../TableBodyRow/useStyles';
import { EmptyCell } from '../EmptyCell';
import { SurveyResultProvider, SurveyResultProviderTypes } from '../../SurveyResultProvider';
import { COLORS } from '../../../../../../../constants/COLORS';
import { InputBase } from '../../../../../../common/InputBase';
import { useDebouncedCallback } from 'use-debounce';
import {
  ChosenOtherOption,
  OtherDropdownAndFreeText,
  SurveyResultSetOtherOptionRequestBody,
} from '../../../../../../../types';

type Props = {
  chosenOtherOptions: ChosenOtherOption[];
  otherOptions: OtherDropdownAndFreeText[];
  setOtherOption: (body: SurveyResultSetOtherOptionRequestBody) => void;
  customerId: number;
  businessUnitId?: number;
  isDisabled?: boolean;
  isEven: boolean;
  isGroup: boolean;
};

export const OtherColumns: React.FC<Props> = memo(function OtherColumns({
  chosenOtherOptions,
  otherOptions,
  customerId,
  setOtherOption,
  isDisabled,
  businessUnitId,
  isEven,
  isGroup,
}) {
  const classes = useStyles();

  const { disableEditPage, surveyResultOtherOptions } = useContext(
    SurveyResultProvider,
  ) as SurveyResultProviderTypes;

  const onSelectOtherOption = useCallback(
    (id, optionId) => {
      if (businessUnitId) {
        setOtherOption({
          businessUnitData: {
            itemId: Number(id),
            optionId,
            customerId,
            businessUnitId,
            isGroup,
          },
        });
      } else {
        setOtherOption({
          customerData: {
            itemId: Number(id),
            optionId,
            customerId,
            isGroup,
          },
        });
      }
    },
    [businessUnitId, customerId, isGroup, setOtherOption],
  );

  const onSelectOtherTextOption = useDebouncedCallback((id, value) => {
    if (businessUnitId) {
      setOtherOption({
        businessUnitData: {
          optionId: id,
          customerId,
          businessUnitId,
          isGroup,
          textValue: value,
        },
      });
    } else {
      setOtherOption({
        customerData: {
          optionId: id,
          customerId,
          isGroup,
          textValue: value,
        },
      });
    }
  }, 400);

  const ListOtherDropdowns = useMemo(() => {
    if (!isDisabled) {
      return chosenOtherOptions.map((option, index, array) => {
        const foundedOtherOptions = otherOptions.find((item) => item.id === option?.optionId);

        if (Array.isArray(foundedOtherOptions?.items)) {
          return (
            <td
              style={{
                backgroundColor: isEven ? COLORS.white1 : COLORS.blue13,
                borderRight: index === array.length - 1 ? `1px solid ${COLORS.gray3}` : '',
              }}
              key={option.optionId}>
              <Dropdown
                disabled={disableEditPage}
                onSelectCb={(id) => {
                  onSelectOtherOption(id, option.optionId);
                }}
                id={option.optionId.toString()}
                dropdownItems={[]}
                dropdownItemsWithObjects={foundedOtherOptions?.items?.map((item) => ({
                  value: item.name,
                  id: item.id,
                }))}
                placeholder="Please select"
                defaultItem={option.itemName}
                dropdownListStyles={classes.dropdownList}
                width={'100%'}
                height={40}
              />
            </td>
          );
        } else {
          return (
            <td
              style={{
                backgroundColor: isEven ? COLORS.white1 : COLORS.blue13,
                borderRight: index === array.length - 1 ? `1px solid ${COLORS.gray3}` : '',
              }}
              key={option.optionId}>
              <InputBase
                disabled={disableEditPage}
                onChangeCb={(value: string) =>
                  onSelectOtherTextOption(foundedOtherOptions?.id, value)
                }
                inputStyles={classes.otherInput}
                value={option.textValue}
              />
            </td>
          );
        }
      });
    } else {
      return surveyResultOtherOptions.map((item) => (
        <EmptyCell isEven={isEven} key={item.option}>
          Empty
        </EmptyCell>
      ));
    }
  }, [
    chosenOtherOptions,
    classes.dropdownList,
    disableEditPage,
    otherOptions,
    isDisabled,
    isEven,
    onSelectOtherOption,
    surveyResultOtherOptions,
  ]);

  return <>{ListOtherDropdowns}</>;
});

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useStyles from './useStyles';
import { Stack } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { useNavigate } from 'react-router-dom';

import {
  checkWeightingsOfBusinessUnits,
  getBUAbbreviations,
  getEOIWeighting as getEOIWeightingAction,
  updateEOIWeighting,
} from 'src/modules/specificTargets/actions';
import { Store } from 'src/types';

import { COLORS } from 'src/constants/COLORS';
import { EOIWeight } from './components/EOIWeight';
import { Loader } from 'src/components/common/Loader';
import { Dropdown } from 'src/components/common/Dropdown';
import { ButtonBase } from 'src/components/common/ButtonBase';
import { NoDataPlaceholder } from 'src/components/common/NoDataPlaceholder';
import { ForbidEditCheckbox } from 'src/components/common/ForbidEditCheckbox';
import { ScreenNames } from 'src/constants/ScreenNames';
import { StepNames } from '../../../../../constants/stepNames';
import { Stepper } from '../../../../common/Stepper';

export const EOIWeighting: React.FC = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [selectedBU, setSelectedBU] = useState('');

  const { BUAbbreviations, EOIWeightings, loading } = useSelector(
    (state: Store) => state.specificTargets,
  );

  const { disableEditPage } = useSelector((state: Store) => state.home);

  const updateEOIWeight = useDebouncedCallback((indicatorId, weighting) => {
    dispatch(
      updateEOIWeighting.request({
        indicatorId,
        weighting,
        businessUnitAbbreviation: selectedBU,
      }),
    );
  }, 300);

  const EnhancedChartListContainer = useMemo(() => {
    if (loading) {
      return <Loader isLoading={true} marginTop={55} />;
    }

    if (EOIWeightings.length === 0 && !loading) {
      return <NoDataPlaceholder text="No data yet. Please try again later." />;
    }

    return (
      <div className={classes.listContainer}>
        {EOIWeightings.map((item) => (
          <EOIWeight
            disableEdit={disableEditPage}
            updateEOIWeight={updateEOIWeight}
            key={item.indicatorId}
            {...item}
          />
        ))}
      </div>
    );
  }, [EOIWeightings, classes.listContainer, disableEditPage, loading, updateEOIWeight]);

  const goNext = useCallback(() => {
    dispatch(
      checkWeightingsOfBusinessUnits.request({
        onSuccessCb: () => {
          navigate('/configure-levels/customer-specific-targets/business-case-assumptions');
        },
      }),
    );
  }, [dispatch, navigate]);

  const goBack = useCallback(() => {
    navigate('/configure-levels/campaign-target/target-setting');
  }, [navigate]);

  useEffect(() => {
    dispatch(getBUAbbreviations.request());
  }, [dispatch]);

  useEffect(() => {
    if (selectedBU) {
      dispatch(
        getEOIWeightingAction.request({
          businessUnitAbbreviation: selectedBU,
        }),
      );
    }
  }, [dispatch, selectedBU]);

  useEffect(() => {
    if (BUAbbreviations.length > 0) {
      setSelectedBU(BUAbbreviations[0]);
    }
  }, [BUAbbreviations]);

  return (
    <div>
      <Stepper
        steps={[
          {
            name: 'Touchable revenue',
            activeIndex: null,
            allCompleted: true,
          },
          {
            name: 'Campaign target',
            activeIndex: null,
            allCompleted: true,
          },
          {
            name: 'Customer-specific targets',
            activeIndex: 0,
          },
        ]}
        type={StepNames.ALL}
      />
      <div className={classes.container}>
        {BUAbbreviations.length > 0 && (
          <Dropdown
            onSelectCb={(selectedItem: string) => {
              setSelectedBU(selectedItem);
            }}
            needDisableDefault={false}
            width={220}
            dropdownItems={BUAbbreviations}
            defaultItem={BUAbbreviations[0]}
          />
        )}
      </div>
      {EnhancedChartListContainer}

      <Stack spacing={6} direction="row" marginTop={loading ? 40 : 8} justifyContent="between-end">
        <ForbidEditCheckbox
          showCheckbox={EOIWeightings.length > 0}
          checkboxValue={disableEditPage}
          screenName={ScreenNames.EASE_OF_INCREASE_INDICATOR_WEIGHTING}
        />
        <Stack width="100%" direction="row" spacing={4} justifyContent="flex-end">
          <ButtonBase
            iconColor={COLORS.red1}
            variant="outlined"
            title="Target setting"
            onClick={goBack}
            icon="goBack"
            iconLeft={true}
          />
          <ButtonBase
            iconColor={COLORS.red1}
            variant="contained"
            title="Business case assumptions"
            onClick={goNext}
            icon="next"
          />
        </Stack>
      </Stack>
    </div>
  );
};

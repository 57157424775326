import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
  },
  sendAllButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  tableRowBorder: {
    border: `1px solid ${COLORS.gray4}`,
  },
  loaderContainer: {
    display: 'flex',
    height: '50vh',
    width: '100%',
    justifyContent: 'center',
    zIndex: 2,
    position: 'absolute',
  },
  tableColumn: {
    fontSize: '12.8px',
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    border: '1px solid',
    padding: '0 17px',
    cursor: 'pointer',
    transition: '0.2s ease',
  },
  redBtn: {
    color: COLORS.red1,
    borderColor: COLORS.red1,
    '&:hover': {
      backgroundColor: COLORS.red1,
      color: COLORS.white1,
    },
  },
  blueBtn: {
    color: COLORS.blue2,
    borderColor: COLORS.blue2,
    '&:hover': {
      backgroundColor: COLORS.blue2,
      color: COLORS.white1,
    },
  },
  btnDisabled: {
    color: COLORS.gray9,
    backgroundColor: COLORS.gray10,
    borderColor: COLORS.gray10,
    cursor: 'default',
    '&:hover': {
      backgroundColor: COLORS.gray10,
      color: COLORS.gray9,
    },
  },
  tableRow: {
    display: 'grid',
    gridTemplateColumns: '1.4fr 1fr 1fr',
    columnGap: '6px',
    padding: '0 15px',
    height: '60px',
    backgroundColor: COLORS.white1,
  },
  statusText: {
    marginLeft: '10px',
  },
}));

export default useStyles;

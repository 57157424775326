import React, { memo, useContext } from 'react';
import useStyles from '../../useStyles';
import { EaseOfIncreaseDrivers } from 'src/types';

import { ReactComponent as SettingsIcon } from 'src/assets/icons/settings.svg';
import { SurveysProvider } from '../../SurveysProvider';
import { DeleteButton } from 'src/components/common/DeleteButton';
import { ReactComponent as WarningIcon } from 'src/assets/icons/warning.svg';
import { SurveySettingsCategories } from 'src/constants/SurveySettingCategories';
import { EditableCell } from 'src/components/common/EditableCell';

import { COLORS } from 'src/constants/COLORS';

type Props = EaseOfIncreaseDrivers & {
  onChangeEaseOfIncreaseDriverOption: (value: string, surveyId: number) => void;
  handleModalOpen: () => void;
  onSelectEOIDrivers: (EOIDriver: EaseOfIncreaseDrivers) => void;
  error: boolean;
};

export const EaseOfIncreaseDriverItem: React.FC<Props> = memo(function EaseOfIncreaseDriverItem({
  id,
  option,
  priceIncreaseTimeLevels,
  onChangeEaseOfIncreaseDriverOption,
  handleModalOpen,
  onSelectEOIDrivers,
  error,
}) {
  const classes = useStyles();
  const { onDeleteOption, handleCleanError, disabled } = useContext(SurveysProvider);

  const onChange = (value: string) => {
    if (error) {
      handleCleanError(id, 'easeOfIncreaseDrivers');
    }

    if (value.slice(-1) === ' ') {
      return;
    }

    if (value !== option) {
      onChangeEaseOfIncreaseDriverOption(value, id);
    }
  };

  const onClickSettings = () => {
    if (!disabled) {
      onSelectEOIDrivers({
        id,
        option,
        priceIncreaseTimeLevels,
      });

      handleModalOpen();
    }
  };

  return (
    <div className={classes.listItem}>
      <div className={classes.eoiInputContainer}>
        {error && <WarningIcon className={classes.listItemErrorIconEOI} />}

        <EditableCell
          isInput
          buttonStyle={`${classes.input} ${disabled && classes.inputDisabled} ${
            error && classes.listItemErrorInput
          }`}
          innerInputStyle={classes.input}
          disabled={disabled}
          value={option}
          onEditCb={onChange}
        />

        {/*<input*/}
        {/*  disabled={disabled}*/}
        {/*  className={`${classes.input} ${disabled && classes.inputDisabled}  ${*/}
        {/*    error && classes.listItemErrorInput*/}
        {/*  }`}*/}
        {/*  value={inputValue}*/}
        {/*  onChange={handleChange}*/}
        {/*/>*/}
      </div>

      <SettingsIcon
        onClick={onClickSettings}
        fill={disabled ? COLORS.gray3 : COLORS.black4}
        className={`${classes.settingsIcon} ${disabled && classes.settingIconDisabled}`}
      />

      <DeleteButton
        disabled={disabled}
        styles={classes.deleteButton}
        onClick={() => {
          if (!disabled) {
            onDeleteOption(id, SurveySettingsCategories.easeOfIncreaseDrivers);
          }
        }}
      />
    </div>
  );
});

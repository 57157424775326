import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';
import { Dropdown } from 'src/components/common/Dropdown';
import { COLORS } from 'src/constants/COLORS';
import { useDispatch, useSelector } from 'react-redux';
import { ShareOfRevenueChart } from 'src/components/common/ShareOfRevenueChart';
import { ButtonBase } from 'src/components/common/ButtonBase';
import { RevenueDividingChart } from 'src/components/common/RevenueDividingChart';

import {
  getAccountManagerGroups,
  getAccountManagerNames,
  getRevenueBase,
} from 'src/modules/TouchableRevenue/actions';
import { Store } from 'src/types';
import { Stepper } from '../../../../common/Stepper';
import { StepNames } from '../../../../../constants/stepNames';
import { getBUAbbreviations } from '../../../../../modules/specificTargets/actions';

export const RevenueBase: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [segment, setSegment] = useState('All');
  const [accountManager, setAccountManager] = useState('All');
  const [accountMangerGroup, setAccountManagerGroup] = useState('All');

  const { accountManagerNames, loading, revenueBaseCharts, accountManagerGroups } = useSelector(
    (state: Store) => state.touchableRevenue,
  );

  const { BUAbbreviations } = useSelector((state: Store) => state.specificTargets);

  const goNext = useCallback(() => {
    navigate('/configure-levels/campaign-target/target-given');
  }, [navigate]);

  const goBack = useCallback(() => {
    navigate('/configure-levels/touchable-revenue/survey-result');
  }, [navigate]);

  useEffect(() => {
    dispatch(getAccountManagerNames.request());
    dispatch(getAccountManagerGroups.request());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getRevenueBase.request({
        segment,
        accountManager,
        group: accountMangerGroup,
      }),
    );
  }, [accountManager, accountMangerGroup, dispatch, segment]);

  useEffect(() => {
    dispatch(getBUAbbreviations.request());
  }, [dispatch]);

  return (
    <div>
      <Stepper
        steps={[
          {
            name: 'Touchable revenue',
            activeIndex: 3,
          },
          {
            name: 'Campaign target',
            activeIndex: null,
          },
          {
            name: 'Customer-specific targets',
            activeIndex: null,
          },
        ]}
        type={StepNames.ALL}
      />

      <Stack columnGap="13px" flexDirection="row" marginTop={6}>
        <Dropdown
          onSelectCb={(selectedItem: string) => {
            setSegment(selectedItem);
          }}
          label="Segment"
          needDisableDefault={false}
          width={220}
          dropdownItems={['All', ...BUAbbreviations]}
          defaultItem={'All'}
        />
        <Dropdown
          onSelectCb={(selectedItem: string) => {
            setAccountManager(selectedItem);
          }}
          label="Account manager"
          needDisableDefault={false}
          width={220}
          dropdownItems={['All', ...accountManagerNames]}
          defaultItem={'All'}
        />
        <Dropdown
          onSelectCb={(selectedItem: string) => {
            setAccountManagerGroup(selectedItem);
          }}
          needDisableDefault={false}
          label="Account manager group"
          width={220}
          dropdownItems={['All', ...accountManagerGroups]}
          defaultItem={'All'}
        />
      </Stack>
      <Stack flexDirection="row" columnGap={20} marginTop={6} position="relative">
        <ShareOfRevenueChart
          isShareOfCustomersEmpty={
            revenueBaseCharts ? revenueBaseCharts.isShareOfCustomersEmpty : true
          }
          isShareOfRevenueEmpty={revenueBaseCharts ? revenueBaseCharts.isShareOfRevenueEmpty : true}
          chartsData={revenueBaseCharts ? revenueBaseCharts.shareOfRevenue : null}
          loading={loading}
        />
        <RevenueDividingChart
          chartsData={revenueBaseCharts ? revenueBaseCharts.revenueDividing : null}
          loading={loading}
        />
      </Stack>

      <Stack spacing={4} direction="row" marginTop={4} justifyContent="flex-end">
        <ButtonBase
          iconColor={COLORS.red1}
          variant="outlined"
          title="Survey result"
          onClick={goBack}
          icon="goBack"
          iconLeft={true}
        />
        <ButtonBase
          iconColor={COLORS.red1}
          variant="contained"
          title="Campaign target"
          onClick={goNext}
          icon="next"
        />
      </Stack>
    </div>
  );
};

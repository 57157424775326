import React from 'react';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

type Props = {
  isLoading: boolean;
  marginTop?: number;
  marginBottom?: number;
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'inherit';
  size?: number;
};

export const Loader: React.FC<Props> = ({
  isLoading,
  marginTop,
  marginBottom,
  size = 45,
  color = 'secondary',
}) => {
  return isLoading ? (
    <Stack
      marginTop={marginTop}
      marginBottom={marginBottom}
      spacing={2}
      justifyContent="center"
      alignItems="center"
      direction="row">
      <CircularProgress size={size} color={color} />
    </Stack>
  ) : null;
};

import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    borderRadius: 10,
    paddingTop: 64,
    overflow: 'hidden',
    boxShadow: '2px 4px 10px rgba(199, 201, 201, 0.5)',
    '& th': {
      height: 48,
      backgroundColor: COLORS.blue12,
      color: COLORS.black4,
      fontSize: 18,
      textAlign: 'left',
      paddingLeft: 16,
      paddingRight: 16,
    },
    '& td': {
      height: 56,
      paddingLeft: 16,
      fontSize: 16,
      width: '33%',
      color: COLORS.black4,
      '& button': {
        backgroundColor: COLORS.white1,
      },
    },
    '& tr:nth-child(even)': {
      backgroundColor: COLORS.blue13,
      '& button': {
        backgroundColor: COLORS.blue13,
      },
    },
    '& tr:not(:last-child)': {
      borderBottom: `1px solid ${COLORS.gray4}`,
    },
    '& tbody tr:last-child': {
      borderBottom: `6px solid ${COLORS.gray4}`,
    },
  },
  tableHeaderCell: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  wrapper: {
    marginTop: '20px',
    overflowY: 'auto',
    boxShadow: '2px 4px 10px rgba(199, 201, 201, 0.5)',
  },
}));

export default useStyles;

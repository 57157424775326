import { createReducer } from 'deox';
import { produce } from 'immer';

import { MonitoringState } from './types';

import {
  getNegotiationProcess,
  getNegotiationStatus,
  updateConfigOfSecondSurvey,
  getMonitoringSurvey,
  getConfigOfSecondSurvey,
  getMonitoringInfoScreen,
  getPerformanceBreakdown,
  getMonitoringDates,
} from './actions';
import { GetNegotiationStatusResponse, GetPerformanceBreakdownResponse } from '../../types';

const initialState: MonitoringState = {
  loading: false,
  buttonLoading: false,
  modalLoading: false,
  negotiationProcess: null,
  negotiationStatus: {} as GetNegotiationStatusResponse,
  performanceBreakdown: {} as GetPerformanceBreakdownResponse,
  monitoring: null,
  configOfSecondSurvey: null,
  monitoringInfoScreen: null,
  monitoringDates: [],
};

export const monitoringReducer = createReducer(initialState, (handleAction) => [
  handleAction(updateConfigOfSecondSurvey.request, (state) =>
    produce(state, (draft) => {
      draft.buttonLoading = true;
    }),
  ),
  handleAction(updateConfigOfSecondSurvey.success, (state) =>
    produce(state, (draft) => {
      draft.buttonLoading = false;
    }),
  ),
  handleAction(updateConfigOfSecondSurvey.fail, (state) =>
    produce(state, (draft) => {
      draft.buttonLoading = false;
    }),
  ),
  handleAction(getNegotiationProcess.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(getNegotiationProcess.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.negotiationProcess = payload;
    }),
  ),
  handleAction(getNegotiationProcess.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  //
  handleAction(getMonitoringSurvey.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(getMonitoringSurvey.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.monitoring = payload;
    }),
  ),
  handleAction(getMonitoringSurvey.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(getConfigOfSecondSurvey.request, (state) =>
    produce(state, (draft) => {
      draft.modalLoading = true;
    }),
  ),
  handleAction(getConfigOfSecondSurvey.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.configOfSecondSurvey = payload;
      draft.modalLoading = false;
    }),
  ),
  handleAction(getConfigOfSecondSurvey.fail, (state) =>
    produce(state, (draft) => {
      draft.modalLoading = false;
    }),
  ),
  //monitoring info below
  handleAction(getMonitoringInfoScreen.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(getMonitoringInfoScreen.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.monitoringInfoScreen = payload;
    }),
  ),
  handleAction(getMonitoringInfoScreen.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(getNegotiationStatus.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(getNegotiationStatus.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.negotiationStatus = payload;
    }),
  ),
  handleAction(getNegotiationStatus.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(getPerformanceBreakdown.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(getPerformanceBreakdown.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.performanceBreakdown = payload;
    }),
  ),
  handleAction(getPerformanceBreakdown.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(getMonitoringDates.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(getMonitoringDates.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.monitoringDates = payload;
    }),
  ),
  handleAction(getMonitoringDates.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
]);

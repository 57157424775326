import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    overflowY: 'hidden',
    borderRadius: 8,
    boxShadow: '2px 4px 10px rgba(199, 201, 201, 0.5)',
  },

  table: {
    color: COLORS.black1,
    borderSpacing: 0,
    borderRadius: 8,
    boxShadow: '2px 4px 10px rgba(199, 201, 201, 0.5)',
    borderCollapse: 'collapse',
    '& thead th p': {
      width: 115,
      minHeight: 61,
      maxHeight: 61,
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
    },
    '& tbody td div': {
      width: 115,
      height: 51,
    },
    '& thead tr:first-child': {
      height: 51,
      '& th': {
        width: 120,
        backgroundColor: COLORS.blue14,
        textAlign: 'left',
        paddingLeft: 24,
      },
      '& th:first-child': {
        backgroundColor: COLORS.white1,
        borderRight: `1px solid ${COLORS.gray30}`,
        borderTopLeftRadius: 8,
      },
      '& th:last-child': {
        borderTopRightRadius: 8,
        textAlign: 'left',
        paddingLeft: 24,
      },
    },
    '& thead tr:nth-child(2) th:nth-child(2), & thead tr:nth-child(3) th:nth-child(2)': {
      borderRight: `1px solid ${COLORS.gray30}`,
    },
    '& thead tr:nth-child(2), thead tr:nth-child(3)': {
      '& th': {
        padding: '0 24px',
        minWidth: 120,
      },
      '& th:nth-child(-n+2)': {
        backgroundColor: COLORS.pink8,
        minWidth: 120,
        textAlign: 'left',
      },
      '& th:nth-child(n+3)': {
        backgroundColor: COLORS.blue12,
        textAlign: 'left',
      },
    },
    '& th': {
      fontSize: 16,
      fontWeight: 700,
      color: COLORS.black1,
    },
    '& tbody tr:nth-child(even) td:nth-child(-n+2)': {
      backgroundColor: COLORS.pink8,
    },
    '& tbody tr:nth-child(even) td:nth-child(n+3)': {
      backgroundColor: COLORS.blue12,
    },
  },
  secondTableFirstColumn: {
    backgroundColor: `${COLORS.blue14} !important`,
    borderRight: `1px solid ${COLORS.gray30}`,
  },
  secondTableSecondRow: {
    position: 'relative',
    backgroundColor: `${COLORS.blue12} !important`,
  },
  borderRightAbsolute: {
    '&:after': {
      content: '""',
      position: 'absolute',
      right: -1,
      width: 1,
      height: 500,
      backgroundColor: COLORS.gray30,
    },
  },
  secondTableSecondRowBorder: {
    borderRight: `1px solid ${COLORS.gray30}`,
  },
  additionalRightColumn: {
    position: 'relative',
    width: 96,

    textAlign: 'center',
    fontSize: 16,
    fontWeight: 700,
    color: COLORS.black2,
  },
  tableSeparatorContainer: {
    width: 26,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '500px',
  },
  additionalFieldsFirstTwoCells: {
    backgroundColor: `${COLORS.gray31} !important`,
  },
  additionalFieldsLast: {
    backgroundColor: `${COLORS.purple2} !important`,
  },
  borderRightAdditionalFields: {
    width: 1,
    height: 500,
    backgroundColor: COLORS.gray30,
    position: 'absolute',
    right: -1,
  },
  tableTwo: {
    '& tbody tr:nth-child(even) td': {
      backgroundColor: `${COLORS.blue12} !important`,
    },
    '& tbody tr:nth-child(even) td:nth-last-child(-n+3)': {
      backgroundColor: `${COLORS.gray28} !important`,
    },
    '& tbody tr:nth-child(even) td:last-child': {
      backgroundColor: `${COLORS.purple2} !important`,
    },
    // '& thead tr:nth-child(2) th:nth-child(2), & thead tr:nth-child(3) th:nth-child(2)': {
    //   borderRight: `0`,
    // },
  },
}));

export default useStyles;

import React from 'react';
import { Grid, Tooltip } from '@mui/material';

import useStyles from './useStyles';
import { useLocation } from 'react-router-dom';

import Logo from 'src/assets/images/main-logo.png';
import { useSelector } from 'react-redux';
import { Store } from 'src/types';
import { ROLES } from '../../../constants/ROLES';
import withStyles from '@mui/styles/withStyles';

const styles = {
  tooltip: {
    minWidth: '365px',
    padding: '10px',
  },
};

const CustomTooltip = withStyles(styles)(Tooltip);

export const MainHeader: React.FC = () => {
  const classes = useStyles();
  const { pathname } = useLocation();

  const getTitleByPathName = () => {
    const path = pathname.split('/');

    return path[1].split('-').join(' ');
  };
  const { projectName } = useSelector((state: Store) => state.projects);
  const { currentRole } = useSelector((state: Store) => state.app);

  return (
    <Grid className={classes.header}>
      <div className={classes.headerContainer}>
        <CustomTooltip
          placement={'right-end'}
          title={
            <span style={{ fontSize: '16px' }}>(Rate Adjustment and Increase Support Engine)</span>
          }>
          <h1 className={`${classes.title} ${classes.secondTitle}`}>
            Simon-Kucher <span>&nbsp;RAISE</span>
          </h1>
        </CustomTooltip>
        <span className={classes.verticalLine} />
        <h1 className={`${classes.title} ${classes.categoryName} ${classes.titleBold}`}>
          {getTitleByPathName()}
        </h1>
        {currentRole === ROLES.ROLE_ADMIN && (
          <>
            <span className={classes.verticalLine} />
            <h1 className={`${classes.title} ${classes.categoryName} ${classes.titleBold}`}>
              {projectName}
            </h1>
          </>
        )}
      </div>
      <img alt="company logo" className={classes.image} src={Logo} />
    </Grid>
  );
};

import React, { useCallback, useEffect, useMemo } from 'react';
import { Stack } from '@mui/material';
import { ButtonBase } from 'src/components/common/ButtonBase';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  downloadFinalCustomerTargetsExcel,
  getBUAbbreviations,
  getCustomerTargets as getCustomerTargetsAction,
  updateExpandedStatusOfCustomerTargetsTable,
} from 'src/modules/specificTargets/actions';
import { Store } from 'src/types';
import { Loader } from 'src/components/common/Loader';
import { NoDataPlaceholder } from 'src/components/common/NoDataPlaceholder';
import { CustomerTargetsTable } from 'src/components/common/CustomerTargetsTable/CustomerTargetsTable';
import { ForbidEditCheckbox } from 'src/components/common/ForbidEditCheckbox';
import { ScreenNames } from 'src/constants/ScreenNames';
import { Pagination } from '../../../../common/Pagination';
import { StepNames } from '../../../../../constants/stepNames';
import { Stepper } from '../../../../common/Stepper';

export const FinalCustomerTargets: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleChangeRowsPerPage = (value: number) => {
    setRowsPerPage(value);
    setPage(0);
  };

  const goToPage = useCallback((page: number) => {
    setPage(page);
  }, []);

  const { loading, customerTargets, BUAbbreviations, expandedTableStatus } = useSelector(
    (state: Store) => state.specificTargets,
  );

  const getCustomerTargets = useCallback(
    ({ pageNumber, itemsOnPage }: { itemsOnPage: number; pageNumber: number }) => {
      dispatch(
        getCustomerTargetsAction.request({
          data: { pageNumber, itemsOnPage },
        }),
      );
    },
    [dispatch],
  );

  const { disableEditPage } = useSelector((state: Store) => state.home);

  const goBack = useCallback(() => {
    navigate('/configure-levels/customer-specific-targets/campaign-impact');
  }, [navigate]);

  const goNext = useCallback(() => {
    navigate('/prepare-implementation/support-material/battlecards-status');
  }, [navigate]);

  const toggleBuVisible = useCallback(() => {
    dispatch(
      updateExpandedStatusOfCustomerTargetsTable.request({ expanded: !expandedTableStatus }),
    );
  }, [expandedTableStatus, dispatch]);

  const onDownloadExcel = useCallback(() => {
    dispatch(downloadFinalCustomerTargetsExcel.request());
  }, [dispatch]);

  const goToBattleCards = () => {
    navigate('/prepare-implementation/support-material/battlecards');
  };

  const EnhancedTableContainer = useMemo(() => {
    if (loading) {
      return <Loader isLoading={true} marginTop={50} />;
    }

    if (!customerTargets || (customerTargets && customerTargets.data.length === 0)) {
      return <NoDataPlaceholder />;
    }

    return (
      <>
        <CustomerTargetsTable
          disableEditable={true}
          buVisible={expandedTableStatus}
          businessUnitAbbreviations={BUAbbreviations}
          customerTargets={customerTargets.data}
          toggleBuVisible={toggleBuVisible}
          page={page - 1}
          rowPerPage={rowsPerPage}
        />
        <Pagination
          totalPages={customerTargets.pageable.totalPages}
          setRowPerPage={handleChangeRowsPerPage}
          setPage={handleChangePage}
          page={page}
          rowPerPage={rowsPerPage}
          marginTop={4}
          goToPage={goToPage}
        />
      </>
    );
  }, [
    BUAbbreviations,
    customerTargets,
    expandedTableStatus,
    goToPage,
    loading,
    page,
    rowsPerPage,
    toggleBuVisible,
  ]);

  useEffect(() => {
    getCustomerTargets({
      pageNumber: page === 0 ? page : page - 1,
      itemsOnPage: rowsPerPage,
    });
  }, [dispatch, getCustomerTargets, page, rowsPerPage]);

  useEffect(() => {
    dispatch(getBUAbbreviations.request());
  }, [dispatch]);

  return (
    <div>
      <Stepper
        steps={[
          {
            name: 'Touchable revenue',
            activeIndex: null,
            allCompleted: true,
          },
          {
            name: 'Campaign target',
            activeIndex: null,
            allCompleted: true,
          },
          {
            name: 'Customer-specific targets',
            activeIndex: 5,
          },
        ]}
        type={StepNames.ALL}
      />
      <Stack marginTop={4} direction="row" columnGap={3}>
        <ButtonBase title="Download Excel" variant="outlined" onClick={onDownloadExcel} />
        <ButtonBase title="Preview Battlecards" variant="outlined" onClick={goToBattleCards} />
      </Stack>

      {EnhancedTableContainer}

      <Stack
        spacing={6}
        direction="row"
        marginTop={loading ? 35 : 8}
        justifyContent="space-between">
        <ForbidEditCheckbox
          dialogText="Are you sure? After this action mail with battlecards will be send to account managers."
          showCheckbox={customerTargets ? customerTargets.data.length > 0 : false}
          checkboxValue={disableEditPage}
          screenName={ScreenNames.FINAL_CUSTOMER_TARGETS}
        />
        <Stack spacing={6} direction="row" marginTop={loading ? 60 : 8} justifyContent="flex-end">
          <ButtonBase
            variant="outlined"
            title="Campaign impact"
            onClick={goBack}
            icon="goBack"
            iconLeft={true}
          />
          <ButtonBase variant="contained" title="Battlecards status" onClick={goNext} icon="next" />
        </Stack>
      </Stack>
    </div>
  );
};

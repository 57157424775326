import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  dropdownWrapper: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  rowPerPageButton: {
    marginLeft: '8px',
    border: `1px solid ${COLORS.gray3}`,
    borderRadius: '3px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 8px',
    cursor: 'pointer',
  },
  arrowDown: {
    marginLeft: '5.5px',
  },
  arrowUp: {
    transform: 'rotate(180deg)',
  },
  goToInput: {
    width: '33px',
    marginLeft: '8px',
    border: `1px solid ${COLORS.gray3}`,
    outline: 'none',
    borderRadius: '3px',
    padding: '4px 5px',
    backgroundColor: COLORS.gray2,
    '&:focus': {
      borderColor: COLORS.gray3,
    },
  },
  listWrapper: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: COLORS.white1,
    bottom: -90,
    right: 0,
    width: '51px',
    borderRadius: '2px',
  },
  listItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '30px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.03)',
      boxShadow: '1px 2px 5px rgba(0,0,0,0.1)',
    },
  },
}));

export default useStyles;

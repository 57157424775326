import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '7px',
    position: 'relative',
  },
  leftInput: {
    width: '255px',
  },
  rightInput: {
    width: '127px',
  },
  input: {
    border: `1px solid ${COLORS.black5}`,
    height: '40px',
    outline: 'none',
    padding: '0 14px',
    fontSize: '14px',
    color: COLORS.black1,
  },
  deleteButton: {
    position: 'absolute',
    right: '-20px',
  },
}));

export default useStyles;

import React, { memo } from 'react';
import { Stack, Typography } from '@mui/material';
import { EditButton } from 'src/components/common/EditButton';
import { DeleteButton } from 'src/components/common/DeleteButton';
import { Project } from 'src/types';
import useStyles from './useStyles';
import dayjs from 'dayjs';

import { ProjectCreationStatus } from 'src/constants/STATUSES';
import { ColoredRound } from 'src/components/common/ColoredRound';

type Props = Project & {
  onEdit: (id: number, name: string) => void;
  onDelete: (id: number, name: string) => void;
  onSelectCurrentProject: (name: string, id: number) => void;
  projectCreationStatus: ProjectCreationStatus;
};

export const ProjectCard: React.FC<Props> = memo(function ProjectCard({
  onEdit,
  onDelete,
  name,
  lastModifiedDate,
  id,
  lastModifiedBy,
  onSelectCurrentProject,
  projectCreationStatus,
}) {
  const classes = useStyles();

  return (
    <div
      className={classes.card}
      onClick={() => {
        onSelectCurrentProject(name, id);
      }}>
      <Stack direction="row" justifyContent="space-between">
        <EditButton
          onClick={(e) => {
            e.stopPropagation();

            onEdit(id, name);
          }}
        />
        <DeleteButton
          onClick={(e) => {
            e.stopPropagation();

            onDelete(id, name);
          }}
        />
      </Stack>
      <Typography sx={{ textAlign: 'center', wordWrap: 'break-word' }} variant="h4">
        {name}
      </Typography>
      <div>
        <p className={classes.bottomText}>
          Last modified date: {lastModifiedDate ? dayjs(lastModifiedDate).format('LLL') : ''}
        </p>
        <p className={classes.bottomText}>Last modified by: {lastModifiedBy}</p>
        <div className={classes.statusContainer}>
          <p className={classes.bottomText}>
            Status:{'  '}
            {`${
              projectCreationStatus === ProjectCreationStatus.CREATED ? 'Created' : 'In progress'
            }`}
          </p>
          <ColoredRound status={projectCreationStatus} />
        </div>
      </div>
    </div>
  );
});

import React, { memo, useMemo } from 'react';
import { PieChart, Pie, Tooltip, ResponsiveContainer, Cell } from 'recharts';

import useStyles from './useStyles';
import { COLORS } from 'src/constants/COLORS';
import { Loader } from 'src/components/common/Loader';
import { ShareOfRevenue } from 'src/types';
import { numberSeparator } from '../../../helpers/numberSeparator';

import { ReactComponent as ShareOfRevenueIcon } from 'src/assets/icons/share-of-revenue.svg';
import { ReactComponent as ShareOfCustomersIcon } from 'src/assets/icons/share-of-customers.svg';

const CHART_COLORS = [COLORS.green4, COLORS.orange1];

//eslint-disable-next-line
const CustomTooltip = ({ active, payload, label, styles }: any) => {
  if (active) {
    return (
      <div className={styles}>
        {payload[0].payload.absoluteValue ? (
          <p>{`${payload[0].name} : ${payload[0].payload.absoluteValue}`}</p>
        ) : (
          <p>n/a</p>
        )}
      </div>
    );
  }

  return null;
};

type Props = {
  loading: boolean;
  chartsData: ShareOfRevenue | null;
  isShareOfCustomersEmpty: boolean;
  isShareOfRevenueEmpty: boolean;
};

export const ShareOfRevenueChart: React.FC<Props> = memo(function ShareOfRevenueChart({
  loading,
  chartsData,
  isShareOfRevenueEmpty,
  isShareOfCustomersEmpty,
}) {
  const classes = useStyles();

  const data01 = useMemo(() => {
    return chartsData
      ? [
          {
            name: 'Touchable',
            value: chartsData.touchableRevenue,
            absoluteValue: chartsData.touchableRevenueAbsoluteValue,
          },
          {
            name: 'Untouchable',
            value: chartsData.untouchableRevenue,
            absoluteValue: chartsData.untouchableRevenueAbsoluteValue,
          },
        ]
      : [];
  }, [chartsData]);

  const data02 = useMemo(() => {
    return chartsData
      ? [
          {
            name: 'Touchable',
            value: chartsData.touchableCustomers,
            absoluteValue: chartsData.touchableCustomersAbsoluteValue,
          },
          {
            name: 'Untouchable',
            value: chartsData.untouchableCustomers,
            absoluteValue: chartsData.untouchableCustomersAbsoluteValue,
          },
        ]
      : [];
  }, [chartsData]);

  const empty = useMemo(() => {
    return [{ name: 'Empty', value: 100 }];
  }, []);

  const EnhancedChartComponent = useMemo(
    () => (
      <div className={classes.container}>
        {!loading && (
          <>
            <h3 className={classes.title}>Touchable and untouchable ratio</h3>
            <div className={classes.labelsContainer}>
              <div className={classes.labelRow}>
                <div className={classes.label}>
                  <ShareOfRevenueIcon />
                  <span>Share of revenue</span>
                </div>
                <div className={`${classes.label} ${classes.secondLabel}`}>
                  <div
                    style={{ backgroundColor: COLORS.green4 }}
                    className={classes.labelColorBox}
                  />
                  <span>Touchable</span>
                </div>
              </div>
              <div className={classes.labelRow}>
                <div className={classes.label}>
                  <ShareOfCustomersIcon />
                  <span>Share of customers</span>
                </div>
                <div className={`${classes.label} ${classes.secondLabel}`}>
                  <div
                    style={{ backgroundColor: COLORS.orange1 }}
                    className={classes.labelColorBox}
                  />
                  <span>Untouchable</span>
                </div>
              </div>
            </div>
          </>
        )}

        <div className={classes.chartContainer}>
          <ResponsiveContainer width="100%" height="100%">
            {loading || !chartsData ? (
              <Loader marginTop={30} isLoading={true} />
            ) : (
              <>
                <PieChart width={300} height={300}>
                  <Pie
                    dataKey="value"
                    data={isShareOfRevenueEmpty ? empty : data01}
                    cx="50%"
                    cy="50%"
                    innerRadius={110}
                    outerRadius={150}>
                    {data01.map((entry, index) => (
                      <Cell
                        key={entry.name}
                        fill={
                          isShareOfRevenueEmpty
                            ? COLORS.gray1
                            : CHART_COLORS[index % CHART_COLORS.length]
                        }
                      />
                    ))}
                  </Pie>
                  <Pie
                    dataKey="value"
                    data={isShareOfCustomersEmpty ? empty : data02}
                    cx="50%"
                    cy="50%"
                    innerRadius={55}
                    outerRadius={90}>
                    {data02.map((entry, index) => (
                      <Cell
                        key={entry.name}
                        fill={
                          isShareOfCustomersEmpty
                            ? COLORS.gray1
                            : CHART_COLORS[index % CHART_COLORS.length]
                        }
                      />
                    ))}
                  </Pie>
                  <Tooltip content={<CustomTooltip styles={classes.tooltipContainer} />} />
                </PieChart>
              </>
            )}
          </ResponsiveContainer>
        </div>
        {!loading && (
          <div className={classes.chartDetailContainer}>
            <div className={classes.chartDetailRowHeader}>
              <span className={classes.chartDetailLabel}>Touchable</span>
              <span className={classes.chartDetailLabel}>Untouchable</span>
            </div>
            <div className={classes.chartDetailRow}>
              <div className={classes.chartDetailLabel}>Revenue</div>
              <div className={classes.chartDetailRevenue}>
                {chartsData ? numberSeparator(chartsData.touchableRevenueAbsoluteValue) : 'XXX'}
              </div>
              <div className={classes.chartDetailRevenue}>
                {chartsData ? numberSeparator(chartsData.untouchableRevenueAbsoluteValue) : 'XXX'}
              </div>
            </div>
            <div className={classes.chartDetailRow}>
              <div className={classes.chartDetailLabel}>Customers</div>
              {chartsData && chartsData.areCustomersNotANumber ? (
                <>
                  <span className={classes.chartDetailCustomers}>NaN</span>
                  <span className={classes.chartDetailCustomers}>NaN</span>
                </>
              ) : (
                <>
                  <div className={classes.chartDetailCustomers}>
                    {chartsData
                      ? chartsData.touchableCustomersAbsoluteValue
                        ? numberSeparator(chartsData.touchableCustomersAbsoluteValue)
                        : 'n/a'
                      : 'XXX'}
                  </div>
                  <div className={classes.chartDetailCustomers}>
                    {chartsData
                      ? chartsData.untouchableCustomersAbsoluteValue
                        ? numberSeparator(chartsData.untouchableCustomersAbsoluteValue)
                        : 'n/a'
                      : 'XXX'}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    ),
    [
      chartsData,
      classes,
      data01,
      data02,
      empty,
      isShareOfCustomersEmpty,
      isShareOfRevenueEmpty,
      loading,
    ],
  );

  return <>{EnhancedChartComponent}</>;
});

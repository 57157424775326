import React, { memo, useCallback, useContext } from 'react';
import { Stack, Typography } from '@mui/material';

import useStyles from '../../useStyles';
import { ContractStatusOption } from 'src/types';
import { updateSurveySettingTouchable, updateSurveysSettings } from 'src/modules/surveys/actions';
import { useDispatch } from 'react-redux';
import { ContractStatusItem } from '../ContractStatusItem';
import { ErrorOption } from '../../index';
import { SurveysProvider } from '../../SurveysProvider';
import { TextButton } from '../../../../../../common/TextButton';

type Props = {
  contractStatusOptions?: ContractStatusOption[];
  idsWithError?: ErrorOption;
};

export const ContractStatusOptions: React.FC<Props> = memo(function ContractStatusOptions({
  contractStatusOptions,
  idsWithError,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { disabled } = useContext(SurveysProvider);

  const addNewField = useCallback(() => {
    dispatch(
      updateSurveysSettings.request({
        body: {
          contractStatusOptions: [
            {
              option: '',
              touchable: true,
            },
          ],
        },
        needUpdateState: true,
      }),
    );
  }, [dispatch]);

  const onChangeContractStatusOption = useCallback(
    (value: string, id: number, touchable: boolean) => {
      dispatch(
        updateSurveysSettings.request({
          body: {
            contractStatusOptions: [
              {
                option: value,
                id,
                touchable,
              },
            ],
          },
          needUpdateState: true,
        }),
      );
    },
    [dispatch],
  );

  const updateContractStatusOptionTouchable = useCallback(
    (optionId, touchable) => {
      dispatch(
        updateSurveySettingTouchable.request({
          optionId,
          touchable,
        }),
      );
    },
    [dispatch],
  );

  return (
    <div className={classes.innerContainer}>
      {contractStatusOptions && contractStatusOptions.length > 0 && (
        <div className={classes.contractStatusOptions}>
          <Typography variant="h5">Options</Typography>
          <Typography variant="h5">Touchable</Typography>
        </div>
      )}

      {contractStatusOptions && contractStatusOptions.length > 0 && (
        <div className={classes.inputsList}>
          {contractStatusOptions.map((item) => (
            <ContractStatusItem
              onChangeContractStatusOptionSwitch={updateContractStatusOptionTouchable}
              onChangeContractStatusOption={onChangeContractStatusOption}
              key={item.id}
              error={idsWithError ? idsWithError.ids.includes(item.id) : false}
              {...item}
            />
          ))}
        </div>
      )}
      {!disabled && (
        <Stack direction="row" marginTop={4} justifyContent="center">
          <TextButton onClick={addNewField} title="Add option" />
        </Stack>
      )}
    </div>
  );
});

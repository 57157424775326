import React, { useCallback, useContext, FC } from 'react';
import useStyles from './useStyles';
import { OtherOptionsFreeTextItem } from './OtherOptionsFreeTextItem';
import { Stack } from '@mui/material';
import { TextButton } from '../../../../../../common/TextButton';
import { SurveysProvider } from '../../SurveysProvider';
import {
  createSurveySettingsOtherOption,
  updateSurveySettingsOtherOption,
} from '../../../../../../../modules/surveys/actions';
import { useDispatch } from 'react-redux';
import { OtherDropdownAndFreeText } from '../../../../../../../types';

type OtherOptionsFreeTextProps = {
  freeTextOptions: OtherDropdownAndFreeText[];
};

const OtherOptionsFreeText: FC<OtherOptionsFreeTextProps> = ({ freeTextOptions }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { disabled } = useContext(SurveysProvider);

  const onAddNewOtherOption = useCallback(() => {
    dispatch(
      createSurveySettingsOtherOption.request({
        option: ``,
        optionType: 'TEXT',
      }),
    );
  }, [dispatch]);

  const handleChange = (value: string, id: number) => {
    dispatch(
      updateSurveySettingsOtherOption.request({
        option: value,
        optionId: id,
      }),
    );
  };

  return (
    <div className={classes.container}>
      {freeTextOptions?.map((option) => {
        return (
          <OtherOptionsFreeTextItem
            key={option.id}
            onChangeValueCb={handleChange}
            error={false}
            {...option}
          />
        );
      })}
      {!disabled && (
        <Stack>
          <TextButton onClick={onAddNewOtherOption} title="Add option" />
        </Stack>
      )}
    </div>
  );
};

export default OtherOptionsFreeText;

import { createReducer } from 'deox';
import { produce } from 'immer';

import { HomeState } from './types';
import {
  getSettingsOverview,
  getProgressShowModal,
  setProgressShowModal,
  getProgressCheckboxValue,
  setProgressCheckboxValue,
  getProgressHomepageCards,
} from './actions';

const initialState: HomeState = {
  loading: false,
  settings: null,
  homepageCards: [],
  progressShowModal: false,
  disableEditPage: false,
};

export const homeReducer = createReducer(initialState, (handleAction) => [
  handleAction(getSettingsOverview.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(getSettingsOverview.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;

      draft.settings = payload;
    }),
  ),
  handleAction(getSettingsOverview.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(getProgressShowModal.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.progressShowModal = payload;
    }),
  ),
  handleAction(setProgressShowModal.success, (state) =>
    produce(state, (draft) => {
      draft.progressShowModal = false;
    }),
  ),
  handleAction(getProgressCheckboxValue.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.disableEditPage = payload;
    }),
  ),
  handleAction(setProgressCheckboxValue.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.disableEditPage = payload;
    }),
  ),
  //
  handleAction(getProgressHomepageCards.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(getProgressHomepageCards.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.homepageCards = payload;
    }),
  ),
  handleAction(getProgressHomepageCards.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
]);

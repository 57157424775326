import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  negotiationPower: {
    marginTop: 24,
  },
  chart: {
    width: '100%',
    height: 33,
    borderRadius: 8,
    display: 'flex',
    position: 'relative',
  },
  item: {
    height: '100%',
    flex: 1,
    '&:first-child': {
      borderRadius: '8px 0 0 8px',
    },
    '&:nth-child(10)': {
      borderRadius: '0 8px 8px 0',
    },
  },
  indicator: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    top: '-70%',
  },
  indicatorLine: {
    height: 76,
    width: 2,
    backgroundColor: COLORS.gray26,
    position: 'absolute',
  },
  indicatorText: {
    width: 200,
    marginLeft: 8,
    position: 'absolute',
    fontSize: 16,
    lineHeight: '19px',
    fontWeight: 700,
  },
  bottom: {
    marginTop: 16,
    display: 'flex',
    justifyContent: 'space-between',
  },
  bottomItem: {
    marginTop: 8,
    position: 'relative',
    flex: 1,
    '&:nth-child(1),&:nth-child(5),&:nth-child(10)': {
      '&::after': {
        content: "''",
        position: 'absolute',
        left: 0,
        top: -8,
        width: '100%',
        height: 1,
        backgroundColor: COLORS.gray27,
      },
    },
  },
  bottomItemValue: {
    fontSize: 16,
    lineHeight: '19px',
    fontWeight: 700,
    color: COLORS.gray26,
  },
  bottomItemText: {
    marginTop: 5,
    fontSize: 12,
    lineHeight: '14px',
  },
}));

export default useStyles;

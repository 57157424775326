import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  container: {
    border: `1px solid ${COLORS.black6}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    paddingLeft: 1,
    cursor: 'pointer',
    transition: '0.3s ease-out',
    '&:hover': {
      borderColor: COLORS.red5,
      '& svg': {
        fill: COLORS.red5,
      },
    },
    '&:active': {
      borderColor: COLORS.pink4,
      '& svg': {
        fill: COLORS.pink4,
      },
    },
    '& svg': {
      transition: '0.3s ease-out',
      '&:active': {
        fill: COLORS.pink4,
      },
    },
  },
  disabled: {
    borderColor: COLORS.gray23,
  },
}));

export default useStyles;

import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { ROLES } from 'src/constants/ROLES';
import { Store } from 'src/types';

import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';

import { MainLayout } from 'src/components/layouts/MainLayout';
import { HomeLayout } from 'src/components/layouts/HomeLayout';

import { StatusOverview } from 'src/components/layouts/HomeLayout/components/StatusOverview';
import { SettingsOverview } from 'src/components/layouts/HomeLayout/components/SettingsOverview';
import { ConfigureLevelsLayout } from 'src/components/layouts/ConfigureLevelsLayout';
import { BusinessUnits } from 'src/components/layouts/ConfigureLevelsLayout/components/BusinessUnits';
import { AccountManagerList } from 'src/components/layouts/ConfigureLevelsLayout/components/AccountManagerList';
import { CustomerRegistry } from 'src/components/layouts/ConfigureLevelsLayout/components/CustomerRegistry';
import { SurveySetting } from 'src/components/layouts/ConfigureLevelsLayout/components/SureveySetting';
import { SurveyStatus } from 'src/components/layouts/ConfigureLevelsLayout/components/SurveyStatus';
import { SurveyResult } from 'src/components/layouts/ConfigureLevelsLayout/components/SurveyResult';
import { RevenueBase } from 'src/components/layouts/ConfigureLevelsLayout/components/RevenueBase';
import { CampaignTarget } from 'src/components/layouts/ConfigureLevelsLayout/components/CamaignTarget';
import { PricePotentialMatrix } from 'src/components/layouts/ConfigureLevelsLayout/components/PricePotentialMatrix';
import { TargetSetting } from 'src/components/layouts/ConfigureLevelsLayout/components/TargetSetting';
import { EOIWeighting } from 'src/components/layouts/ConfigureLevelsLayout/components/EOIWeighting';
import { BusinessCaseAssumptions } from 'src/components/layouts/ConfigureLevelsLayout/components/BusinessCaseAssumptions';
import { TargetFineTuning } from 'src/components/layouts/ConfigureLevelsLayout/components/TargetFineTuning';
import { CustomerTargets } from 'src/components/layouts/ConfigureLevelsLayout/components/CustomerTargets';
import { CampaignImpact } from 'src/components/layouts/ConfigureLevelsLayout/components/CampaignImpact';
import { FinalCustomerTargets } from 'src/components/layouts/ConfigureLevelsLayout/components/FinalCustomerTargets';
import { ProjectsLayout } from '../components/layouts/ProjectsLayout';
import { Projects } from 'src/components/layouts/ProjectsLayout/pages/Projects';

import { Login } from 'src/components/pages/Login';
import { SurveysLayout } from 'src/components/layouts/SurveysLayout';
import { SurveyCompletingResult } from 'src/components/layouts/SurveysLayout/pages/SurveyCompletingResult';
import { PrepareImplementationLayout } from '../components/layouts/PrepareImplementationLayout';
import { NotFound } from '../components/Views/NotFound';
import { KpisTabs } from '../components/layouts/PrepareImplementationLayout/pages/KpisTabs';
import { NegotiationProcess } from '../components/layouts/PrepareImplementationLayout/pages/NegotiationProcess';
import { Monitoring } from '../components/layouts/PrepareImplementationLayout/pages/Monitoring';
import { NegotiationStatus } from '../components/layouts/PrepareImplementationLayout/pages/NegotiationStatus';
import { SecondSurvey } from '../components/layouts/SurveysLayout/pages/SecondSurvey';
import { loginSSO as loginSSOAction } from 'src/modules/app/actions';
import PerformanceBreakdown from '../components/layouts/PrepareImplementationLayout/pages/PerformanceBreakdown';
import { MonitoringSurvey } from '../components/layouts/PrepareImplementationLayout/pages/MonitoringsSurvey';
import Unauthorized from '../components/Views/Unauthorized/Unauthorized';
import customHistory from '../helpers/history';
import { getProjects } from '../modules/projects/actions';
import { getSurveyResultAccess } from '../modules/surveys/actions';
import BattleCards from 'src/components/layouts/PrepareImplementationLayout/pages/BattleCards';
import BattleCard from '../components/layouts/PrepareImplementationLayout/pages/BattleCard';
import BattleCardsStatus from 'src/components/layouts/ConfigureLevelsLayout/components/BattleCardsStatus';

export const AppRouter: React.FC = () => {
  const dispatch = useDispatch();

  const { currentRole, isBattleCards, screenNames, token, managerLoginLink } = useSelector(
    (state: Store) => state.app,
  );

  const authCode = new URLSearchParams(window.location.search).get('code');

  useEffect(() => {
    if (
      !authCode &&
      !window.location.href.includes('login') &&
      currentRole !== ROLES.ROLE_ACCOUNT_MANAGER
    ) {
      dispatch(
        getProjects.request({
          pageNumber: 0,
          itemsOnPage: 100,
        }),
      );
    }
  }, [authCode]);

  useEffect(() => {
    if (currentRole === ROLES.NO_ROLE) {
      customHistory.push('/unauthorized');
    }
  }, [currentRole]);

  useEffect(() => {
    if (authCode) {
      dispatch(loginSSOAction.request(authCode));
    }
  }, [authCode, dispatch]);

  useEffect(() => {
    if (currentRole === ROLES.ROLE_ADMIN) {
      dispatch(getSurveyResultAccess.request());
    }
  }, [currentRole, dispatch]);

  const renderRoutes = () => {
    if (currentRole === ROLES.ROLE_ACCOUNT_MANAGER) {
      if (isBattleCards) {
        return (
          <Route
            path="/"
            element={
              <PrivateRoute isAuthenticated={!!token}>
                <MainLayout
                  isBattleCards
                  currentRole={currentRole}
                  managerLoginLink={managerLoginLink}
                />
              </PrivateRoute>
            }>
            <Route path="/" element={<Navigate replace to="/battlecards" />} />
            <Route path="/battlecards" element={<SurveysLayout />}>
              <Route index element={<BattleCards isBattleCards={isBattleCards} />} />
              <Route
                path="/battlecards/:id"
                element={<BattleCard isBattleCards={isBattleCards} />}
              />
            </Route>
          </Route>
        );
      }

      const showSurveyResult = screenNames.find((screen) => screen.screenName === 'SURVEY_RESULT');
      const showSecondSurvey = screenNames.find((screen) => screen.screenName === 'SECOND_SURVEY');

      const accManagerScreenToOpen = () => {
        let screenPath = '';

        if (showSecondSurvey) {
          screenPath = 'survey/monitoring-survey';
        }

        if (showSurveyResult) {
          screenPath = 'survey/initial-survey';
        }

        return screenPath;
      };

      return (
        <Route
          path="/"
          element={
            <PrivateRoute isAuthenticated={!!token}>
              <MainLayout
                availableScreens={screenNames}
                currentRole={currentRole}
                managerLoginLink={managerLoginLink}
              />
            </PrivateRoute>
          }>
          <Route path="/" element={<Navigate to={accManagerScreenToOpen()} />} />
          <Route path="survey" element={<SurveysLayout />}>
            {showSurveyResult && (
              <Route path="initial-survey" element={<SurveyCompletingResult />} />
            )}
            {showSecondSurvey && <Route path="monitoring-survey" element={<SecondSurvey />} />}
          </Route>
        </Route>
      );
    }

    if (currentRole === ROLES.ROLE_ADMIN) {
      return (
        <Route
          path="/"
          element={
            <PrivateRoute isAuthenticated={!!token}>
              <MainLayout currentRole={currentRole} />
            </PrivateRoute>
          }>
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="/" element={<Navigate replace to="projects" />} />
          <Route path="projects" element={<ProjectsLayout />}>
            <Route index element={<Projects />} />
          </Route>
          <Route path="home" element={<HomeLayout />}>
            <Route path="status-overview" element={<StatusOverview />} />
            <Route path="settings-overview" element={<SettingsOverview />} />
          </Route>
          <Route path="configure-levels" element={<ConfigureLevelsLayout />}>
            <Route path="touchable-revenue">
              <Route path="business-units" element={<BusinessUnits />} />
              <Route path="account-manager-list" element={<AccountManagerList />} />
              <Route path="customer-registry" element={<CustomerRegistry />} />
              <Route path="survey-setting" element={<SurveySetting />} />
              <Route path="survey-status" element={<SurveyStatus />} />
              <Route path="survey-result" element={<SurveyResult />} />
              <Route path="revenue-base" element={<RevenueBase />} />
            </Route>
            <Route path="campaign-target">
              <Route path="target-given" element={<CampaignTarget />} />
              <Route path="price-potential" element={<PricePotentialMatrix />} />
              <Route path="target-setting" element={<TargetSetting />} />
            </Route>
            <Route path="customer-specific-targets">
              <Route path="eoi-weighting" element={<EOIWeighting />} />
              <Route path="business-case-assumptions" element={<BusinessCaseAssumptions />} />
              <Route path="target-finetuning" element={<TargetFineTuning />} />
              <Route path="customer-targets" element={<CustomerTargets />} />
              <Route path="campaign-impact" element={<CampaignImpact />} />
              <Route path="final-customer-targets" element={<FinalCustomerTargets />} />
            </Route>
          </Route>
          <Route path="prepare-implementation" element={<PrepareImplementationLayout />}>
            <Route path="support-material">
              <Route path="battlecards-status" element={<BattleCardsStatus />} />
              <Route path="battlecards">
                <Route index element={<BattleCards />} />
                <Route path=":id" element={<BattleCard />} />
              </Route>
            </Route>
          </Route>
          <Route path="rollout-initiative" element={<PrepareImplementationLayout />}>
            <Route path="monitoring-reports" element={<KpisTabs />}>
              <Route path="monitoring" element={<Monitoring />} />
              <Route path="negotiation-process" element={<NegotiationProcess />} />
              <Route path="negotiation-status" element={<NegotiationStatus />} />
              <Route path="monitoring-survey" element={<MonitoringSurvey />} />
              <Route path="performance-breakdown" element={<PerformanceBreakdown />} />
            </Route>
          </Route>
        </Route>
      );
    }
    if (currentRole === ROLES.NO_ROLE) {
      return (
        <Route
          path="/"
          element={
            <MainLayout currentRole={currentRole}>
              <Unauthorized />
            </MainLayout>
          }>
          <Route path="/unauthorized" element={<Unauthorized />} />
        </Route>
      );
    }
  };

  const chosePath = () => {
    if (currentRole === ROLES.ROLE_ACCOUNT_MANAGER) {
      if (isBattleCards) {
        return '/battlecards';
      }

      return '/';
    }

    if (currentRole === ROLES.ROLE_ADMIN) {
      return '/projects';
    }

    return '/';
  };

  return (
    <Routes>
      <Route
        path="/login"
        element={
          <PublicRoute path={chosePath()} isAuthenticated={!!token}>
            <Login />
          </PublicRoute>
        }
      />
      {renderRoutes()}
      <Route path="*" element={<NotFound isAuthenticated={!!token} currentRole={currentRole} />} />
    </Routes>
  );
};

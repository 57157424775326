import React, { CSSProperties, memo } from 'react';
import Button from '@mui/material/Button';

import { ReactComponent as DownloadIcon } from 'src/assets/icons/download.svg';
import { ReactComponent as UploadIcon } from 'src/assets/icons/ant-design_upload-outlined.svg';
import { ReactComponent as ArrowRight } from 'src/assets/icons/arrow-right.svg';
import { ReactComponent as ArrowLeft } from 'src/assets/icons/arrow-left.svg';

import { OverridableStringUnion } from '@mui/types';
import {
  ButtonPropsColorOverrides,
  ButtonPropsVariantOverrides,
} from '@mui/material/Button/Button';
import { Loader } from 'src/components/common/Loader';
import { COLORS } from 'src/constants/COLORS';

const Icons = {
  download: DownloadIcon,
  upLoad: UploadIcon,
  next: ArrowRight,
  goBack: ArrowLeft,
};

export type ButtonProps = {
  title: string;
  onClick?: () => void;
  variant?: OverridableStringUnion<'text' | 'outlined' | 'contained', ButtonPropsVariantOverrides>;
  icon?: keyof typeof Icons;
  iconColor?: string;
  color?: OverridableStringUnion<
    'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
    ButtonPropsColorOverrides
  >;
  padding?: string;
  upload?: boolean;
  component?: 'button' | 'span';
  containerStyle?: string;
  isLoading?: boolean;
  disabled?: boolean;
  inlineStyle?: CSSProperties;
  loadingColor?: 'info' | 'secondary';
  iconLeft?: boolean;
};

export const ButtonBase: React.FC<ButtonProps> = memo(function ButtonBase({
  title,
  icon,
  color = 'primary',
  iconColor,
  variant,
  onClick,
  padding,
  component = 'button',
  containerStyle,
  isLoading,
  disabled,
  inlineStyle,
  loadingColor = 'info',
  iconLeft,
}) {
  const Icon = icon && Icons[icon];

  return (
    <div className={containerStyle}>
      <Button
        disabled={disabled}
        component={component}
        color={color}
        onClick={disabled ? undefined : onClick}
        focusRipple
        sx={{
          ':active': {
            borderColor: 'transparent',
          },
          ...inlineStyle,
          padding,
        }}
        variant={variant}
        startIcon={iconLeft && Icon && <Icon fill={disabled ? COLORS.gray3 : iconColor} />}
        endIcon={Icon && !iconLeft && <Icon fill={disabled ? COLORS.gray3 : iconColor} />}>
        {isLoading ? (
          <div>
            <Loader color={loadingColor} size={18} isLoading={true} />
          </div>
        ) : (
          title
        )}
      </Button>
    </div>
  );
});

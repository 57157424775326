import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  container: {
    borderRadius: '8px',
    width: '24%',
    minWidth: '290px',
    backgroundColor: COLORS.white1,
    minHeight: '518px',
    boxShadow: '0px 2px 10px rgba(199, 201, 201, 0.5)',
  },
  header: {
    padding: '0 20px',
    height: '65px',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    backgroundColor: COLORS.gray4,
    fontWeight: 700,
    fontSize: '18px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${COLORS.gray7}`,
  },
  contentContainer: {
    overflowY: 'auto',
    maxHeight: '500px',
    scrollbarWidth: 'thin',
  },
  loaderContainer: {
    height: '400px',
    paddingTop: 120,
  },
}));

export default useStyles;

import React, { memo, useCallback, useContext, useEffect, useState } from 'react';
import { PricePotentialCostDriver } from 'src/types';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { useDebouncedCallback } from 'use-debounce';
import { useDispatch } from 'react-redux';

import { COLORS } from 'src/constants/COLORS';

import { ReactComponent as DragIcon } from 'src/assets/icons/drag-n-drop.svg';

import useStyles from './useStyles';

import { InputBase } from 'src/components/common/InputBase';
import { DeleteButton } from 'src/components/common/DeleteButton';

import { deleteCostDriver } from 'src/modules/campaignTarget/actions';
import { PricePotentialProvider } from '../../PricePotentialProvider';
import { TextButton } from '../../../TextButton';

type Props = {
  costDrivers: PricePotentialCostDriver[];
  onAddNewCostDriver: (position: number, costDriverName: string) => void;
  onEditCostDriver: (
    costDrivers: { position: number; costDriverName: string; id: number }[],
  ) => void;
};

const CostDriver: React.FC<{
  driver: PricePotentialCostDriver;
  index: number;
  disabled: boolean;
  onEditCostDriver: (
    costDrivers: { position: number; costDriverName: string; id: number }[],
  ) => void;
}> = ({ driver, index, onEditCostDriver, disabled }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const debouncedOnChange = useDebouncedCallback((value) => {
    onEditCostDriver([
      {
        position: index,
        costDriverName: value,
        id: driver.id,
      },
    ]);
  }, 500);

  const onDeleteCostDriver = useCallback(() => {
    if (!disabled) {
      dispatch(deleteCostDriver.request({ costDriverId: driver.id }));
    }
  }, [disabled, dispatch, driver.id]);

  return (
    <Draggable isDragDisabled={disabled} draggableId={driver.id.toString()} index={index}>
      {(provided) => (
        <div className={classes.itemContainer} ref={provided.innerRef} {...provided.draggableProps}>
          <div {...provided.dragHandleProps}>
            <InputBase
              disabled={disabled}
              onChangeCb={debouncedOnChange}
              value={driver.costDriverName}
              inputStyles={classes.input}
            />
            <DragIcon fill={disabled ? COLORS.gray3 : '#828282'} className={classes.dragIcon} />
          </div>

          <DeleteButton
            disabled={disabled}
            onClick={onDeleteCostDriver}
            styles={classes.deleteIcon}
          />
        </div>
      )}
    </Draggable>
  );
};

const reorder = (list: PricePotentialCostDriver[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const CostDriversDnD: React.FC<Props> = memo(function CostDriversDnD({
  costDrivers,
  onAddNewCostDriver,
  onEditCostDriver,
}) {
  const [state, setState] = useState({ list: costDrivers });
  const { disableEditPage } = useContext(PricePotentialProvider);

  useEffect(() => {
    setState({
      list: costDrivers,
    });
  }, [costDrivers]);

  function onDragEnd(result: DropResult) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const list = reorder(state.list, result.source.index, result.destination.index);

    onEditCostDriver(list);

    setState({ list });
  }

  const addCostDriver = () => {
    if (!disableEditPage) {
      onAddNewCostDriver(costDrivers.length, '[Cost driver]');
    }
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {state.list.map((driver, index: number) => (
                <CostDriver
                  onEditCostDriver={onEditCostDriver}
                  driver={driver}
                  index={index}
                  disabled={disableEditPage}
                  key={driver.id}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {!disableEditPage && <TextButton onClick={addCostDriver} title="Add cost driver" />}
    </>
  );
});

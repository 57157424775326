import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    backgroundColor: COLORS.white1,
    flexDirection: 'column',
    padding: '16px 24px 24px',
    width: 200,
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    borderRadius: 8,
    marginBottom: 20,
  },
  form: {
    marginLeft: '30px',
    marginTop: '-6px',
  },
  title: {
    fontWeight: 700,
    fontSize: 16,
    marginBottom: 16,
  },
}));

export default useStyles;

import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMonitoringSurvey as getMonitoringSurveyAction } from 'src/modules/monitoring/actions';
import { DEFAULT_COUNT_OF_ITEMS } from 'src/constants/dafaultCountOfItems';
import { NoDataPlaceholder } from 'src/components/common/NoDataPlaceholder';
import { Pagination } from 'src/components/common/Pagination';
import { Store } from 'src/types';
import { MonitoringSurveyTable } from './MonirotingSurveyTable';
import { Loader } from '../../../../common/Loader';

export const MonitoringSurvey: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_COUNT_OF_ITEMS);

  const { monitoring, loading } = useSelector((state: Store) => state.monitoring);

  const dispatch = useDispatch();

  const goToPage = useCallback((page: number) => {
    setPage(page);
  }, []);

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleChangeRowsPerPage = (value: number) => {
    setRowsPerPage(value);
    setPage(0);
  };

  const getMonitoringSurvey = useCallback(
    ({
      sortBy,
      pageNumber,
      itemsOnPage,
    }: {
      sortBy: string;
      itemsOnPage: number;
      pageNumber: number;
    }) => {
      dispatch(
        getMonitoringSurveyAction.request({
          pageNumber,
          itemsOnPage,
          sortBy,
          sortOrder: 'asc',
        }),
      );
    },
    [dispatch],
  );

  const EnhancedMonitoringSurveyContainer = useMemo(() => {
    if (loading) {
      return <Loader isLoading={true} marginTop={40} />;
    }

    if (!monitoring || (monitoring && monitoring.data.length === 0)) {
      return <NoDataPlaceholder />;
    }

    return (
      <>
        <MonitoringSurveyTable data={monitoring.data} />

        <Pagination
          totalPages={monitoring.pageable.totalPages}
          setRowPerPage={handleChangeRowsPerPage}
          setPage={handleChangePage}
          page={page}
          rowPerPage={rowsPerPage}
          marginTop={4}
          goToPage={goToPage}
        />
      </>
    );
  }, [loading, monitoring, page, rowsPerPage, goToPage]);

  useEffect(() => {
    getMonitoringSurvey({
      sortBy: 'account_manager',
      pageNumber: page === 0 ? page : page - 1,
      itemsOnPage: rowsPerPage,
    });
  }, [getMonitoringSurvey, page, rowsPerPage]);

  return <div>{EnhancedMonitoringSurveyContainer}</div>;
};

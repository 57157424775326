import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '51px',
    display: 'flex',
    gap: '20px',
    justifyContent: 'space-between',
    overflowY: 'scroll',
  },
  innerContainer: {
    paddingBottom: '15px',
  },
  contractStatusOptions: {
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 14% 0 24px',
  },
  input: {
    border: `1px solid ${COLORS.gray26}`,
    borderRadius: 8,
    height: '40px',
    width: '100%',
    outline: 'none',
    padding: '0 16px',
    fontSize: '14px',
    color: COLORS.black1,
    '&::placeholder': {
      color: COLORS.gray22,
    },
    '&:placeholder': {
      color: COLORS.gray25,
    },
    '&:hover': {
      borderColor: COLORS.blue2,
    },
    display: 'block',
    verticalAlign: 'top',
  },
  inputDisabled: {
    borderColor: COLORS.gray3,
    backgroundColor: COLORS.white2,
    color: COLORS.gray3,
  },
  inputWithSettings: {
    width: '90%',
  },
  settingsIcon: {
    cursor: 'pointer',
    marginLeft: '8px',
  },
  settingIconDisabled: {
    cursor: 'auto',
  },
  inputWrapper: {
    padding: '0 20px',
    marginTop: '20px',
  },
  inputContainer: {
    position: 'relative',
    width: '70%',
  },
  eoiInputContainer: {
    position: 'relative',
    width: '80%',
  },
  inputsList: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '20px',
    padding: '0 5px 0 10px',
    marginTop: '20px',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  listItemErrorIcon: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
  listItemErrorIconEOI: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
  listItemErrorInput: {
    borderColor: COLORS.red1,
    boxShadow: `0px 0px 6px #FF5982`,
  },
  modalContainer: {
    backgroundColor: COLORS.white1,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '450px',
    minHeight: '480px',
    maxHeight: '500px',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    border: 'none',
    padding: '24px 33px 46px 19px',
    outline: 'none',
    borderRadius: 8,
  },
  modalTitle: {
    fontSize: '18px',
    margin: '13px 0 18px',
    textAlign: 'center',
    color: COLORS.black2,
  },
  deleteButton: {
    position: 'absolute',
    right: 10,
  },
  switchWrapper: {
    paddingBottom: '4px',
  },
}));

export default useStyles;

import React, { memo, useCallback, useContext, useMemo } from 'react';
import { ChosenContractCommitmentOption, SurveyResultSetContractCommitment } from 'src/types';
import { Checkbox } from 'src/components/common/Checkbox';
import { EmptyCell } from '../EmptyCell';

import { v4 as uuidv4 } from 'uuid';
import { SurveyResultProvider, SurveyResultProviderTypes } from '../../SurveyResultProvider';
import { COLORS } from '../../../../../../../constants/COLORS';

type Props = {
  chosenContractCommitmentOptions: ChosenContractCommitmentOption[];
  setContractCommitments: (body: SurveyResultSetContractCommitment) => void;
  customerId: number;
  businessUnitId?: number;
  isDisabled?: boolean;
  isEven: boolean;
  isGroup: boolean;
};

export const ContractCommitmentsColumns: React.FC<Props> = memo(
  function ContractCommitmentsColumns({
    chosenContractCommitmentOptions,
    customerId,
    setContractCommitments,
    businessUnitId,
    isDisabled,
    isEven,
    isGroup,
  }) {
    const { disableEditPage, surveyResultContractCommitmentOptions } = useContext(
      SurveyResultProvider,
    ) as SurveyResultProviderTypes;

    const onCheckContractCommitment = useCallback(
      (isChecked, optionId) => {
        if (businessUnitId) {
          setContractCommitments({
            businessUnitData: {
              customerId,
              optionId,
              isChosen: isChecked,
              businessUnitId,
              isGroup,
            },
          });
        } else {
          setContractCommitments({
            customerData: {
              customerId,
              optionId,
              isChosen: isChecked,
              isGroup,
            },
          });
        }
      },
      [businessUnitId, customerId, isGroup, setContractCommitments],
    );

    const EnhancedCellsComponent = useMemo(() => {
      if (isDisabled) {
        return [{ optionId: uuidv4() }, ...surveyResultContractCommitmentOptions].map((item) => (
          <EmptyCell isEven={isEven} key={item.optionId}>
            Empty
          </EmptyCell>
        ));
      } else {
        return (
          <>
            {chosenContractCommitmentOptions.map((item, index, array) => (
              <td
                style={{
                  backgroundColor: isEven ? COLORS.white1 : COLORS.blue13,
                  borderRight: index === array.length - 1 ? `1px solid ${COLORS.gray3}` : '',
                }}
                key={item.optionId}>
                <Checkbox
                  disabled={disableEditPage}
                  onChangeCb={(isChecked) => {
                    onCheckContractCommitment(isChecked, item.optionId);
                  }}
                  isChecked={item.isChosen ? item.isChosen : false}
                />
              </td>
            ))}
          </>
        );
      }
    }, [
      chosenContractCommitmentOptions,
      disableEditPage,
      isDisabled,
      isEven,
      onCheckContractCommitment,
      surveyResultContractCommitmentOptions,
    ]);

    return <>{EnhancedCellsComponent}</>;
  },
);

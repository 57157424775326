import React, { FC } from 'react';
import useStyles from './useStyles';
import { PricingTarget } from '../../../../../../types';

type PricingTargetsProps = {
  pricingTargets?: PricingTarget[];
};
const PricingTargets: FC<PricingTargetsProps> = ({ pricingTargets }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <table className={classes.table}>
        <thead className={classes.tHeader}>
          <tr className={classes.tHeaderRow}>
            <td></td>
            {pricingTargets?.map((item: PricingTarget) => (
              <td key={item.businessUnitId}>{item.businessUnitAbbreviation}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr className={classes.tBodyRow}>
            <td>Going in</td>
            {pricingTargets?.map((item: PricingTarget) => (
              <td key={item.businessUnitId}>{item.goingInPriceIncrease}%</td>
            ))}
          </tr>
          <tr className={classes.tBodyRow}>
            <td>Target</td>
            {pricingTargets?.map((item: PricingTarget) => (
              <td key={item.businessUnitId}>{item.targetPriceIncrease}%</td>
            ))}
          </tr>
          <tr className={classes.tBodyRow}>
            <td>Escalation price increase</td>
            {pricingTargets?.map((item: PricingTarget) => (
              <td key={item.businessUnitId}>{item.escalationPriceIncrease}%</td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PricingTargets;

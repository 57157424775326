import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Stack } from '@mui/material';
import { ButtonBase } from 'src/components/common/ButtonBase';
import { Dropdown } from 'src/components/common/Dropdown';

import { COLORS } from 'src/constants/COLORS';
import useStyles from './useStyles';

import {
  downloadSurveyResultExcel,
  getAccountManagerNames,
} from 'src/modules/TouchableRevenue/actions';
import {
  checkSurveyResultFieldsAreFilled,
  getSurveyResult as getSurveyResultAction,
  setContractCommitment as setContractCommitmentAction,
  setEOIOption as setEOIOptionAction,
  setOtherOption as setOtherOptionAction,
  setPlanBOption as setPlanBOptionAction,
  setSurveyResultAdditionalFields,
  setTouchableOption as setTouchableOptionAction,
  surveyResultCopyOptionsFromFirstBUToOther,
} from 'src/modules/surveys/actions';

import { Store } from 'src/types';

import { SurveyResultTable } from './components/SurveyResultTable';
import { ForbidEditCheckbox } from 'src/components/common/ForbidEditCheckbox';
import { Loader } from 'src/components/common/Loader';
import { NoDataPlaceholder } from 'src/components/common/NoDataPlaceholder';
import { ScreenNames } from 'src/constants/ScreenNames';
import { SurveyResultProvider } from './SurveyResultProvider';
import { Pagination } from 'src/components/common/Pagination';
import { StepNames } from '../../../../../constants/stepNames';
import { Stepper } from '../../../../common/Stepper';

export const SurveyResult: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { accountManagerNames } = useSelector((state: Store) => state.touchableRevenue);
  const { disableEditPage } = useSelector((state: Store) => state.home);
  const { surveyResult, loading } = useSelector((state: Store) => state.surveys);

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  const [selectedAccountManager, setSelectedAccountManager] = useState('All');

  const [visibleFields, setVisibleFields] = useState({
    general: false,
    eoi: false,
    contract: false,
    planB: false,
    other: false,
  });

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleChangeRowsPerPage = (value: number) => {
    setRowsPerPage(value);
    setPage(0);
  };

  const goToPage = useCallback((page: number) => {
    setPage(page);
  }, []);

  const getSurveyResult = useCallback(
    ({ pageNumber, itemsOnPage }: { itemsOnPage: number; pageNumber: number }) => {
      dispatch(
        getSurveyResultAction.request({
          accountManager: selectedAccountManager,
          pageNumber,
          itemsOnPage,
        }),
      );
    },
    [dispatch, selectedAccountManager],
  );

  const goNext = useCallback(() => {
    dispatch(
      checkSurveyResultFieldsAreFilled.request({
        onSuccessCb: () => {
          navigate('/configure-levels/touchable-revenue/revenue-base');
        },
      }),
    );
  }, [dispatch, navigate]);

  const goBack = useCallback(() => {
    navigate('/configure-levels/touchable-revenue/survey-status');
  }, [navigate]);

  const checkForEmptyFields = () => {
    //
    return true;
  };

  const setTouchableOption = useCallback(
    (body) => {
      dispatch(
        setTouchableOptionAction.request({
          params: {
            accountManager: selectedAccountManager,
          },
          body,
        }),
      );
    },
    [dispatch, selectedAccountManager],
  );

  const setEOIOption = useCallback(
    (body) => {
      dispatch(
        setEOIOptionAction.request({
          params: {
            accountManager: selectedAccountManager,
          },
          body,
        }),
      );
    },
    [dispatch, selectedAccountManager],
  );

  const setOtherOption = useCallback(
    (body) => {
      dispatch(
        setOtherOptionAction.request({
          params: {
            accountManager: selectedAccountManager,
          },
          body,
        }),
      );
    },
    [dispatch, selectedAccountManager],
  );

  const setContractCommitments = useCallback(
    (body) => {
      dispatch(
        setContractCommitmentAction.request({
          params: {
            accountManager: selectedAccountManager,
          },
          body,
        }),
      );
    },
    [dispatch, selectedAccountManager],
  );

  const setAdditionalFields = useCallback(
    (body) => {
      dispatch(
        setSurveyResultAdditionalFields.request({
          params: {
            accountManager: selectedAccountManager,
          },
          body,
        }),
      );
    },
    [dispatch, selectedAccountManager],
  );

  const setPlanBOption = useCallback(
    (body) => {
      dispatch(
        setPlanBOptionAction.request({
          params: {
            accountManager: selectedAccountManager,
          },
          body,
        }),
      );
    },
    [dispatch, selectedAccountManager],
  );

  const copyBuValueGeneralInfo = useCallback(
    (requestConfig) => {
      dispatch(
        surveyResultCopyOptionsFromFirstBUToOther.request({
          ...requestConfig,
          accountManager: selectedAccountManager,
          pageNumber: page === 0 ? page : page - 1,
          itemsOnPage: rowsPerPage,
        }),
      );
    },
    [dispatch],
  );

  const handleChangeVisibleFields = (object: any) => {
    setVisibleFields((prevState) => ({
      ...prevState,
      ...object,
    }));
  };

  const EnhancedContainer = useMemo(() => {
    if (!surveyResult || surveyResult.customersData.length === 0) {
      return <NoDataPlaceholder text="No data yet. Please try again later." />;
    }

    return (
      <>
        <SurveyResultProvider.Provider
          value={{
            disableEditPage,
            surveyResultEOIOptions: surveyResult.options.easeOfIncreaseOptions,
            surveyResultContractCommitmentOptions: surveyResult.options.contractCommitmentOptions,
            surveyResultPlanBOptions: surveyResult.options.planBOptions,
            surveyResultTouchableOptions: surveyResult.options.touchableOptions,
            surveyResultBusinessUnits: surveyResult.businessUnits,
            surveyResultCustomersData: surveyResult.customersData,
            additionalFields: surveyResult.additionalFields,
            surveyResultOtherOptions: surveyResult.options.otherOptions,
          }}>
          <SurveyResultTable
            copyBuValueGeneralInfo={copyBuValueGeneralInfo}
            setAdditionalFields={setAdditionalFields}
            setPlanBOption={setPlanBOption}
            setEOIOption={setEOIOption}
            setOtherOption={setOtherOption}
            setContractCommitments={setContractCommitments}
            setTouchableOption={setTouchableOption}
            reloadRows={loading}
            onChangeVisibleFields={handleChangeVisibleFields}
            allowSegmentSpecificInput={surveyResult?.additionalFields.allowSegmentSpecificInput}
          />
        </SurveyResultProvider.Provider>
        <Pagination
          totalPages={surveyResult.pageable.totalPages}
          setRowPerPage={handleChangeRowsPerPage}
          setPage={handleChangePage}
          page={page}
          rowPerPage={rowsPerPage}
          marginTop={4}
          goToPage={goToPage}
        />
      </>
    );
  }, [
    surveyResult,
    disableEditPage,
    copyBuValueGeneralInfo,
    setAdditionalFields,
    setPlanBOption,
    setEOIOption,
    setContractCommitments,
    setTouchableOption,
    loading,
    page,
    rowsPerPage,
    goToPage,
  ]);

  useEffect(() => {
    dispatch(getAccountManagerNames.request());
  }, [dispatch]);

  useEffect(() => {
    getSurveyResult({ pageNumber: page === 0 ? page : page - 1, itemsOnPage: rowsPerPage });
  }, [getSurveyResult, page, rowsPerPage]);

  const onDownloadExcel = useCallback(() => {
    const params = {
      accountManager: selectedAccountManager,
      general: visibleFields.general,
      eoi: visibleFields.eoi,
      contract: visibleFields.contract,
      planB: visibleFields.planB,
      other: visibleFields.other,
    };
    dispatch(downloadSurveyResultExcel.request(params));
  }, [dispatch, selectedAccountManager, visibleFields]);

  return (
    <div className={classes.container}>
      <Stepper
        steps={[
          {
            name: 'Touchable revenue',
            activeIndex: 2,
          },
          {
            name: 'Campaign target',
            activeIndex: null,
          },
          {
            name: 'Customer-specific targets',
            activeIndex: null,
          },
        ]}
        type={StepNames.ALL}
      />
      <Stack marginTop={2} spacing={4} direction="row" alignItems={'end'}>
        <Dropdown
          label="Account manager"
          width={200}
          dropdownArrowColor={COLORS.black1}
          dropdownItems={['All', ...accountManagerNames]}
          defaultItem="All"
          onSelectCb={(value) => {
            setSelectedAccountManager(value);
          }}
        />
        <ButtonBase title="Download Excel" variant="outlined" onClick={onDownloadExcel} />
      </Stack>

      {loading && (
        <div className={classes.loaderContainer}>
          <Loader isLoading={true} />
        </div>
      )}

      {EnhancedContainer}

      <Stack spacing={6} width="100%" direction="row" marginTop={4} justifyContent="space-between">
        {surveyResult && (
          <ForbidEditCheckbox
            showCheckbox={surveyResult.customersData.length > 0}
            checkForEmptyFields={checkForEmptyFields}
            screenName={ScreenNames.SURVEY_RESULT}
            checkboxValue={disableEditPage}
          />
        )}
        <Stack width="100%" direction="row" spacing={4} justifyContent="flex-end">
          <ButtonBase
            iconColor={COLORS.red1}
            variant="outlined"
            title="Survey status"
            onClick={goBack}
            icon="goBack"
            iconLeft={true}
          />
          <ButtonBase
            iconColor={COLORS.red1}
            variant="contained"
            title="Campaign revenue base"
            onClick={goNext}
            icon="next"
          />
        </Stack>
      </Stack>
    </div>
  );
};

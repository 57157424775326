import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../../../../constants/COLORS';

const useStyles = makeStyles(() => ({
  container: {
    flex: 2,
  },
  title: {
    fontSize: 16,
    lineHeight: '19px',
    fontWeight: 700,
    color: COLORS.gray26,
  },
  main: {
    display: 'flex',
  },
  chart: {
    flex: 7,
    marginTop: 20,
    height: 160,
    width: '100%',
    position: 'relative',
  },
  xAxisLine: {
    position: 'absolute',
    width: '100%',
    height: 2,
    backgroundColor: COLORS.gray21,
    bottom: 0,
  },
  yAxisLine: {
    position: 'absolute',
    width: 2,
    height: '100%',
    backgroundColor: COLORS.gray21,
    bottom: 0,
  },
  chartLabels: {
    display: 'flex',
    marginTop: 10,
  },
  chartLabel: {
    flex: 1,
    textAlign: 'center',
    fontSize: 12,
    lineHeight: '14px',
    fontWeight: 700,
  },
  labelValue: {
    fontSize: 12,
    width: 60,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  customTooltip: {
    backgroundColor: '#404055',
    borderRadius: 8,
    color: '#fff',
    padding: '12px 16px 16px 16px',
    minWidth: 140,
  },
  customTooltipItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tooltipText: {
    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
}));

export default useStyles;

import React, { useCallback, useEffect, useMemo } from 'react';
import { Stack } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './useStyles';
import { DEFAULT_COUNT_OF_ITEMS } from 'src/constants/dafaultCountOfItems';

import { COLORS } from 'src/constants/COLORS';

import { ButtonBase } from 'src/components/common/ButtonBase';
import { Pagination } from 'src/components/common/Pagination';
import { UploadButton } from 'src/components/common/UploadButton';

import {
  downloadBusinessUnitsExcel,
  exportBusinessUnitsExcel,
  getBusinessUnits as getBusinessUnitsAction,
} from 'src/modules/TouchableRevenue/actions';

import { Store } from 'src/types';
import { Loader } from 'src/components/common/Loader';
import { NoDataPlaceholder } from 'src/components/common/NoDataPlaceholder';
import { ForbidEditCheckbox } from 'src/components/common/ForbidEditCheckbox';

import { ScreenNames } from 'src/constants/ScreenNames';
import { BusinessUnitsTable } from './components/BusinessUnitsTable';
import { Stepper } from '../../../../common/Stepper';
import { StepNames } from '../../../../../constants/stepNames';

export const BusinessUnits: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { businessUnits, loading } = useSelector((state: Store) => state.touchableRevenue);

  const { disableEditPage } = useSelector((state: Store) => state.home);

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_COUNT_OF_ITEMS);

  const navigate = useNavigate();

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleChangeRowsPerPage = (value: number) => {
    setRowsPerPage(value);
    setPage(0);
  };

  const onDownloadExcel = useCallback(() => {
    dispatch(downloadBusinessUnitsExcel.request());
  }, [dispatch]);

  const onUploadExcel = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const fileList = e.target.files;

      if (fileList) {
        dispatch(
          exportBusinessUnitsExcel.request({
            file: fileList[0],
            params: {
              itemsOnPage: rowsPerPage,
              sortBy: 'name',
            },
          }),
        );

        e.target.value = '';
      }
    },
    [dispatch, rowsPerPage],
  );

  const goNext = useCallback(() => {
    navigate('/configure-levels/touchable-revenue/account-manager-list');
  }, [navigate]);

  const getBusinessUnits = useCallback(
    ({
      sortBy,
      pageNumber,
      itemsOnPage,
    }: {
      sortBy: string;
      itemsOnPage: number;
      pageNumber: number;
    }) => {
      dispatch(
        getBusinessUnitsAction.request({
          pageNumber,
          itemsOnPage,
          sortBy,
          sortOrder: 'asc',
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    getBusinessUnits({
      sortBy: 'revenue',
      pageNumber: page === 0 ? page : page - 1,
      itemsOnPage: rowsPerPage,
    });
  }, [getBusinessUnits, page, rowsPerPage]);

  const goToPage = useCallback((page: number) => {
    setPage(page);
  }, []);

  const EnhancedContentContainer = useMemo(() => {
    if (!businessUnits || (businessUnits && businessUnits.data.length === 0)) {
      return <NoDataPlaceholder />;
    }

    return (
      <>
        <BusinessUnitsTable disableEditing={disableEditPage} data={businessUnits.data} />

        <Pagination
          totalPages={businessUnits.pageable.totalPages}
          setRowPerPage={handleChangeRowsPerPage}
          setPage={handleChangePage}
          page={page}
          rowPerPage={rowsPerPage}
          marginTop={4}
          goToPage={goToPage}
        />
      </>
    );
  }, [businessUnits, disableEditPage, goToPage, page, rowsPerPage]);

  return (
    <div className={classes.container}>
      <Stack
        direction="row"
        columnGap={3}
        marginTop={4}
        justifyContent="center"
        alignItems="center">
        <ButtonBase title="Download Excel template" variant="outlined" onClick={onDownloadExcel} />
        <UploadButton
          disabled={loading || disableEditPage}
          onChange={onUploadExcel}
          variant="outlined"
          title="Upload from Excel"
        />
      </Stack>
      <Stepper steps={{ name: 'Setup', activeIndex: 0 }} type={StepNames.SETUP} />

      {loading && (
        <div className={classes.loaderContainer}>
          <Loader isLoading={true} />
        </div>
      )}

      {EnhancedContentContainer}

      <Stack direction="row" marginTop={8} justifyContent="space-between">
        <ForbidEditCheckbox
          showCheckbox={businessUnits ? businessUnits.data.length > 0 : false}
          checkboxValue={disableEditPage}
          screenName={ScreenNames.SETTINGS_BUSINESS_UNITS}
        />
        <ButtonBase
          iconColor={COLORS.red1}
          variant="contained"
          title="Account manager list"
          onClick={goNext}
          icon="next"
        />
      </Stack>
    </div>
  );
};

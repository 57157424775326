import React, { useState, FC, useRef, useEffect } from 'react';
import { ReactComponent as CheckCircle } from '../../../../assets/icons/check-circle.svg';
import { ReactComponent as EditPencilIcon } from '../../../../assets/icons/edit-pencil.svg';
import useStyles from './useStyles';
import {
  MonitoringInfoTargetPriceIncrease,
  Pageable,
  UpdateMonitoringScreenByAdminParams,
} from 'src/types';

export type GetMonitoringInfoScreeResponse = {
  comment: string;
  expectedOutOfRealizedIncreases?: MonitoringInfoTargetPriceIncrease[];
  id?: number;
  name: string;
  pageable?: Pageable;
  status: string;
  targetPriceIncreases?: MonitoringInfoTargetPriceIncrease[];
};

type Props = {
  customer?: GetMonitoringInfoScreeResponse;
  onLocalUpdate?: (comment: string) => void;
  disabled: boolean;
  onUpdateMonitoringByAdmin?: (data: UpdateMonitoringScreenByAdminParams) => void;
};

const CommentEdit: FC<Props> = ({
  customer,
  onLocalUpdate,
  disabled,
  onUpdateMonitoringByAdmin,
}) => {
  const classes = useStyles();

  //eslint-disable-next-line
  const textareaRef = useRef<any>(null);

  const [isEditComment, setIsEditComment] = useState(false);
  const [commentValue, setCommentValue] = useState<string>(customer?.comment || '');

  const handleOpenEditComment = () => {
    if (onLocalUpdate && disabled) {
      return;
    }

    setIsEditComment(true);
  };

  const handleSetCommentValue = (value: string) => {
    setCommentValue(value);
  };

  const handleChangeComment = () => {
    if (!onLocalUpdate && onUpdateMonitoringByAdmin) {
      onUpdateMonitoringByAdmin({
        comment: commentValue,
        id: customer?.id,
      });
    } else {
      onLocalUpdate && onLocalUpdate(commentValue);
    }

    setIsEditComment(false);
  };

  useEffect(() => {
    if (isEditComment) {
      textareaRef.current.focus();
    }
  }, [isEditComment]);

  return (
    <div className={classes.comment}>
      {isEditComment ? (
        <div className={classes.commentEdit}>
          <textarea
            ref={textareaRef}
            value={commentValue}
            onBlur={handleChangeComment}
            onChange={(e) => handleSetCommentValue(e.target.value)}
          />
          <div onClick={handleChangeComment} className={classes.checkCircle}>
            <CheckCircle />
          </div>
        </div>
      ) : (
        <>
          <div className={classes.commentDisplay}>{commentValue}</div>
          <EditPencilIcon onClick={handleOpenEditComment} />
        </>
      )}
    </div>
  );
};

export default CommentEdit;

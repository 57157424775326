import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  contentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 10,
    columnGap: 24,
  },
}));

export default useStyles;

import { createReducer } from 'deox';
import { produce } from 'immer';

import { SpecificTargetsState } from './types';

import {
  getEOIWeighting,
  getBUAbbreviations,
  getBusinessCaseAssumptions,
  updateBusinessCaseAssumption,
  getTargetFineTuning,
  updateTargetFineTuning,
  getCustomerTargets,
  getCustomerTargetsExpandedStatus,
  updateExpandedStatusOfCustomerTargetsTable,
  getCampaignImpact,
  getFinalCustomerTargets,
  updateCustomerTargetCell,
  updateCustomerTargetBusinessUnitCell,
  updateEOIWeighting,
} from './actions';

import { getSurveysCompleting } from 'src/modules/surveys/actions';

const initialState: SpecificTargetsState = {
  loading: false,
  chartLoading: false,
  EOIWeightings: [],
  BUAbbreviations: [],
  businessCaseAssumptions: [],
  customerTargets: null,
  finalCustomerTargets: [],
  campaignImpact: null,
  targetFineTuning: null,
  expandedTableStatus: false,
  buttonLoading: false,
};

export const specificTargetsReducer = createReducer(initialState, (handleAction) => [
  handleAction(getEOIWeighting.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(getEOIWeighting.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.EOIWeightings = payload.easeOfIncreaseWeights;

      draft.loading = false;
    }),
  ),
  handleAction(getEOIWeighting.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(getBUAbbreviations.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.BUAbbreviations = payload;
    }),
  ),
  handleAction(updateCustomerTargetCell.success, (state, { payload }) =>
    produce(state, (draft) => {
      if (state.customerTargets) {
        draft.customerTargets = {
          ...state.customerTargets,
          data: state.customerTargets.data.map((customer) => {
            return customer.customerId === payload.customerId ? payload : customer;
          }),
        };
      }
    }),
  ),
  handleAction(updateCustomerTargetBusinessUnitCell.success, (state, { payload }) =>
    produce(state, (draft) => {
      if (state.customerTargets) {
        draft.customerTargets = {
          ...state.customerTargets,
          data: state.customerTargets.data.map((customer) => {
            return customer.customerId === payload.customerId ? payload : customer;
          }),
        };
      }
    }),
  ),
  handleAction(updateEOIWeighting.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.EOIWeightings = state.EOIWeightings.map((eoi) =>
        eoi.indicatorId === payload.indicatorId
          ? {
              ...eoi,
              weighting: payload.weighting,
            }
          : eoi,
      );
    }),
  ),
  handleAction(getBusinessCaseAssumptions.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(getBusinessCaseAssumptions.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.businessCaseAssumptions = payload.businessCaseAssumptions;

      draft.loading = false;
    }),
  ),
  handleAction(getBusinessCaseAssumptions.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(updateBusinessCaseAssumption.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.businessCaseAssumptions = payload.businessCaseAssumptions;
    }),
  ),
  handleAction(getTargetFineTuning.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(getTargetFineTuning.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.targetFineTuning = payload;

      draft.loading = false;
    }),
  ),
  handleAction(getTargetFineTuning.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  //
  handleAction(updateTargetFineTuning.request, (state) =>
    produce(state, (draft) => {
      draft.chartLoading = true;
    }),
  ),
  handleAction(updateTargetFineTuning.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.targetFineTuning = payload;

      draft.chartLoading = false;
    }),
  ),
  handleAction(updateTargetFineTuning.fail, (state) =>
    produce(state, (draft) => {
      draft.chartLoading = false;
    }),
  ),
  //
  handleAction(getCustomerTargets.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(getCustomerTargets.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.customerTargets = payload;
    }),
  ),
  handleAction(getCustomerTargets.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(getCustomerTargetsExpandedStatus.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.expandedTableStatus = payload;
    }),
  ),
  handleAction(updateExpandedStatusOfCustomerTargetsTable.request, (state, { payload }) =>
    produce(state, (draft) => {
      draft.expandedTableStatus = payload.expanded;
    }),
  ),
  // Campaign impact
  handleAction(getCampaignImpact.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(getCampaignImpact.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.campaignImpact = payload;
    }),
  ),
  handleAction(getCampaignImpact.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  //final customer target
  handleAction(getFinalCustomerTargets.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(getFinalCustomerTargets.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.finalCustomerTargets = payload;
    }),
  ),
  handleAction(getFinalCustomerTargets.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(getSurveysCompleting.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.BUAbbreviations = payload.businessUnitAbbreviations;
    }),
  ),
]);

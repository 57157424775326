import React, { useMemo, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import { Link, useNavigate } from 'react-router-dom';
import useStyles from './useStyles';
import { NestedLink } from '../../index';

import { COLORS } from 'src/constants/COLORS';
import ArrowUpIcon from '../../../../../assets/icons/arrow-up.svg';

type Props = {
  pathName: string;
  nestedLink: NestedLink;
  index: number;
};

export const NestedItem: React.FC<Props> = ({ pathName, nestedLink, index }) => {
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  const classes = useStyles();

  const toggleMenu = () => {
    if (nestedLink.url) {
      navigate(nestedLink.url);
      return;
    }

    setIsOpen((prev) => !prev);
  };

  const checkPathName = (pathUrl: string) => {
    return pathUrl === pathName;
  };

  //eslint-disable-next-line
  const renderThumb = ({ style, ...props }: any) => {
    return <div style={{ ...style, backgroundColor: COLORS.gray3, borderRadius: 10 }} {...props} />;
  };

  const isTheSameRoute = useMemo(() => {
    if (nestedLink.url) {
      const first = pathName.split('/')[2];
      const second = nestedLink.url.split('/')[2];

      return first === second;
    }
  }, [nestedLink.url, pathName]);

  return (
    <>
      <ListItemButton
        className={classes.listItemButton}
        onClick={toggleMenu}
        disableGutters
        sx={{ pl: 4, pt: 0, pb: 0 }}>
        <div className={classes.numberCircle}>{index}</div>
        <a
          className={`${classes.link} ${classes.sublink} ${classes.collapse__link} ${
            isTheSameRoute && classes.link__selected
          }`}>
          {nestedLink.text}
        </a>
        {nestedLink && nestedLink?.sublinks && (
          <div className={classes.arrowContainer}>
            <img
              className={`${classes.arrowIcon} ${isOpen && classes.arrowIconSelected}`}
              alt="open dropdown menu"
              src={ArrowUpIcon}
            />
          </div>
        )}
      </ListItemButton>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <Scrollbars
          renderThumbVertical={renderThumb}
          thumbMinSize={30}
          universal={true}
          autoHeight
          autoHeightMin={0}
          autoHeightMax={220}
          style={{ width: '100%' }}>
          <List component="div" disablePadding>
            {nestedLink &&
              nestedLink.sublinks?.map((sublink) => (
                <ListItemButton className={classes.listItemButton} key={sublink.id}>
                  <Link
                    onClick={(e) => {
                      e.preventDefault();

                      navigate(sublink.url);
                    }}
                    className={`${classes.link} ${classes.sublink} ${classes.nestedSublink} ${
                      checkPathName(sublink.url) ? classes.link__selected : ''
                    } ${classes.collapse__link}`}
                    to={sublink.url}>
                    {sublink.text}
                  </Link>
                </ListItemButton>
              ))}
          </List>
        </Scrollbars>
      </Collapse>
    </>
  );
};

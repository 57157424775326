import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    overflowX: 'auto',
    overflowY: 'auto',
    height: 'calc(100vh - 400px)',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: 15,
    '& th': {
      border: `1px solid ${COLORS.gray16}`,
      fontSize: 16,
    },

    '& tbody tr:nth-child(even)': {
      backgroundColor: COLORS.blue13,
      '& button': {
        backgroundColor: COLORS.blue13,
      },
    },

    '& tr:nth-child(1) th:nth-child(-n+7), tr:nth-child(1) th:last-child': {
      border: 'none',
    },
    '& tr:nth-child(1) th:nth-child(8)': {
      backgroundColor: COLORS.blue12,
    },
    '& tr:nth-child(1) th:nth-child(8) > div': {
      height: 40,
    },
    '& td': {
      fontSize: 14,
    },
    '& td > div, th > div': {
      padding: '0 10px',
      height: 60,
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
      color: COLORS.black2,
    },
    '& tbody tr': {
      backgroundColor: COLORS.white1,
      border: `1px solid ${COLORS.gray12}`,
    },
  },
  lastCell: {
    backgroundColor: COLORS.blue12,
    minWidth: 130,
  },
  buAbbreviationRow: {
    '& th > div': {
      height: 40,
    },
    '& th:nth-child(-n+7)': {
      border: 'none',
    },
    '& th:nth-child(n+8)': {
      backgroundColor: COLORS.blue12,
    },
  },
  calculatedPriceCells: {
    backgroundColor: COLORS.blue12,
    minWidth: 130,
  },
  minusIcon: {
    display: 'flex',
    width: 16,
    height: 16,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    border: `1px solid ${COLORS.red1}`,
    '& > div': {
      width: '8px',
      height: '1px',
      backgroundColor: COLORS.red1,
    },
  },
}));

export default useStyles;

import React, { memo, useState, FC } from 'react';
import { OtherDropdownItems } from 'src/types';

import useStyles from './useStyles';

import { ReactComponent as DeleteIcon } from 'src/assets/icons/delete-basket.svg';
import { DeleteButton } from 'src/components/common/DeleteButton';
import { InputBase } from '../../../../../../../../common/InputBase';

type Props = OtherDropdownItems & {
  onChangeNameCb: (value: string, id: number) => void;
  deleteItem: (params: { id?: number; temporaryId?: number }) => void;
};

export const Item: FC<Props> = memo(function Item({
  id,
  name,
  temporaryId,
  onChangeNameCb,
  deleteItem,
}) {
  const classes = useStyles();

  const [increaseName, setIncreaseName] = useState(name);

  const handleChangeName = (value: string) => {
    setIncreaseName(value);

    if (id) {
      onChangeNameCb(value, id);
    } else if (temporaryId) {
      onChangeNameCb(value, temporaryId);
    }
  };

  return (
    <div className={classes.container}>
      <InputBase
        value={increaseName}
        onChangeCb={handleChangeName}
        inputStyles={`${classes.leftInput} ${classes.input}`}
      />
      <DeleteButton
        styles={classes.deleteButton}
        onClick={() => {
          deleteItem({ id, temporaryId });
        }}>
        <DeleteIcon />
      </DeleteButton>
    </div>
  );
});

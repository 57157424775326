import { takeLatest, put } from 'redux-saga/effects';
import { API } from 'src/api';
import {
  editPricePotentialIndicator,
  getPricePotential,
  getTargetForBusinessUnits,
  setTargetForBusinessUnits,
  updateCostDriver,
  updatePricePotentialCategories,
  deleteCostDriver,
  deletePricePotentialCategory,
  deletePricePotentialIndicator,
  getPricePotentialMatrix,
  updatePricePotentialAdditionalFields,
  updatePricePotentialCostDriver,
  updatePricePotentialMatrixIndicator,
  updatePricePotentialMatrixWeight,
  updateTargetForTargetSettings,
  getTargetSettings,
  updateApproachCampaignTarget,
  getApproachForCampaignTarget,
} from './actions';
import { processRequestError } from 'src/modules/Errors/actions';
import { ActionType } from 'deox';
import { select } from 'typed-redux-saga';
import { Store } from '../../types';

function* getTargetsForBusinessUnitsSaga() {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.getTargetsForBusinessUnits({ projectId });

    yield put(getTargetForBusinessUnits.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: getTargetForBusinessUnits.fail }));
  }
}

function* setTargetForBusinessUnitsSaga({
  payload,
}: ActionType<typeof setTargetForBusinessUnits.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.setTargetForBusinessUnits(payload, { projectId });

    yield put(setTargetForBusinessUnits.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: setTargetForBusinessUnits.fail }));
  }
}

function* getPricePotentialSaga() {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.getPricePotential({ projectId });

    yield put(getPricePotential.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: getPricePotential.fail }));
  }
}

function* getApproachForCampaignTargetSaga() {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.getApproachForCampaignTarget({ projectId });

    yield put(getApproachForCampaignTarget.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: getApproachForCampaignTarget.fail }));
  }
}

function* updatePricePotentialCategoriesSaga({
  payload,
}: ActionType<typeof updatePricePotentialCategories.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.updatePricePotential(payload, { projectId });

    yield put(updatePricePotentialCategories.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: updatePricePotentialCategories.fail }));
  }
}

function* editPricePotentialIndicatorSaga({
  payload,
}: ActionType<typeof editPricePotentialIndicator.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.updatePricePotential(payload, { projectId });

    yield put(editPricePotentialIndicator.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: editPricePotentialIndicator.fail }));
  }
}

function* updateCostDriverSaga({ payload }: ActionType<typeof updateCostDriver.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.updatePricePotential(payload.requestBody, { projectId });

    if (payload.needUpdateReducer) {
      yield put(updateCostDriver.success(data));
    }
  } catch (error) {
    yield put(processRequestError({ error, failAction: updateCostDriver.fail }));
  }
}

function* deleteCostDriverSaga({ payload }: ActionType<typeof deleteCostDriver.request>) {
  try {
    yield API.deleteCostDriver(payload);

    yield put(deleteCostDriver.success(payload));
  } catch (error) {
    yield put(processRequestError({ error, failAction: deleteCostDriver.fail }));
  }
}

function* deletePricePotentialCategorySaga({
  payload,
}: ActionType<typeof deletePricePotentialCategory.request>) {
  try {
    yield API.deletePricePotentialCategory(payload);

    yield put(deletePricePotentialCategory.success(payload));
  } catch (error) {
    yield put(processRequestError({ error, failAction: deletePricePotentialCategory.fail }));
  }
}

function* deletePricePotentialIndicatorSaga({
  payload,
}: ActionType<typeof deletePricePotentialIndicator.request>) {
  try {
    yield API.deletePricePotentialIndicator({ indicatorId: payload.indicatorId });

    yield put(deletePricePotentialIndicator.success(payload));
  } catch (error) {
    yield put(processRequestError({ error, failAction: deletePricePotentialIndicator.fail }));
  }
}

function* getPricePotentialMatrixSaga() {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.getPricePotentialMatrix({ projectId });

    yield put(getPricePotentialMatrix.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: getPricePotentialMatrix.fail }));
  }
}

function* updatePricePotentialAdditionalFieldsSaga({
  payload,
}: ActionType<typeof updatePricePotentialAdditionalFields.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.updatePricePotentialAdditionalFields({
      ...payload,
      params: { projectId },
    });

    yield put(
      updatePricePotentialAdditionalFields.success({
        responseData: data,
        requestPayload: payload.body,
      }),
    );
  } catch (error) {
    yield put(
      processRequestError({ error, failAction: updatePricePotentialAdditionalFields.fail }),
    );
  }
}

function* updatePricePotentialCostDriverSaga({
  payload,
}: ActionType<typeof updatePricePotentialCostDriver.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.updatePricePotentialMatrixCostDriver({
      ...payload,
      params: { projectId },
    });

    yield put(updatePricePotentialCostDriver.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: updatePricePotentialCostDriver.fail }));
  }
}

function* updatePricePotentialMatrixIndicatorSaga({
  payload,
}: ActionType<typeof updatePricePotentialMatrixIndicator.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.updatePricePotentialMatrixIndicator({
      ...payload,
      params: { projectId },
    });

    yield put(
      updatePricePotentialMatrixIndicator.success({
        ...data,
        indicatorId: payload.body.indicatorId,
        percentage: payload.body.percentage,
        categoryId: payload.body.categoryId,
      }),
    );
  } catch (error) {
    yield put(processRequestError({ error, failAction: updatePricePotentialMatrixIndicator.fail }));
  }
}

function* updatePricePotentialMatrixWeightSaga({
  payload,
}: ActionType<typeof updatePricePotentialMatrixWeight.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.updatePricePotentialMatrixWeight({
      ...payload,
      params: { projectId },
    });

    yield put(
      updatePricePotentialMatrixWeight.success({ responseData: data, requestParams: payload }),
    );
  } catch (error) {
    yield put(processRequestError({ error, failAction: updatePricePotentialMatrixWeight.fail }));
  }
}

function* getTargetSettingsSaga() {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.getTargetSettings({ projectId });

    yield put(getTargetSettings.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: getTargetSettings.fail }));
  }
}

function* updateTargetForTargetSettingsSaga({
  payload,
}: ActionType<typeof updateTargetForTargetSettings.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.updateTargetForTargetSettings(payload, { projectId });

    yield put(updateTargetForTargetSettings.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: updateTargetForTargetSettings.fail }));
  }
}

function* updateApproachCampaignTargetSaga({
  payload,
}: ActionType<typeof updateApproachCampaignTarget.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    yield API.updateApproachCampaignTarget({ ...payload, projectId });

    yield put(updateApproachCampaignTarget.success(payload));
  } catch (error) {
    yield put(processRequestError({ error, failAction: updateApproachCampaignTarget.fail }));
  }
}

export function* watchCampaignTargets() {
  yield takeLatest(getTargetForBusinessUnits.request, getTargetsForBusinessUnitsSaga);
  yield takeLatest(setTargetForBusinessUnits.request, setTargetForBusinessUnitsSaga);
  yield takeLatest(getPricePotential.request, getPricePotentialSaga);
  yield takeLatest(updatePricePotentialCategories.request, updatePricePotentialCategoriesSaga);
  yield takeLatest(editPricePotentialIndicator.request, editPricePotentialIndicatorSaga);
  yield takeLatest(updateCostDriver.request, updateCostDriverSaga);
  yield takeLatest(deleteCostDriver.request, deleteCostDriverSaga);
  yield takeLatest(deletePricePotentialCategory.request, deletePricePotentialCategorySaga);
  yield takeLatest(deletePricePotentialIndicator.request, deletePricePotentialIndicatorSaga);
  yield takeLatest(getPricePotentialMatrix.request, getPricePotentialMatrixSaga);
  yield takeLatest(
    updatePricePotentialAdditionalFields.request,
    updatePricePotentialAdditionalFieldsSaga,
  );
  yield takeLatest(updatePricePotentialCostDriver.request, updatePricePotentialCostDriverSaga);
  yield takeLatest(
    updatePricePotentialMatrixIndicator.request,
    updatePricePotentialMatrixIndicatorSaga,
  );
  yield takeLatest(updatePricePotentialMatrixWeight.request, updatePricePotentialMatrixWeightSaga);
  yield takeLatest(getTargetSettings.request, getTargetSettingsSaga);
  yield takeLatest(updateTargetForTargetSettings.request, updateTargetForTargetSettingsSaga);
  yield takeLatest(updateApproachCampaignTarget.request, updateApproachCampaignTargetSaga);
  yield takeLatest(getApproachForCampaignTarget.request, getApproachForCampaignTargetSaga);
}

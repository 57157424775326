import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  listItemButton: {
    width: 240,
    height: 52,
    display: 'flex',
  },
  menu__open: {
    color: '#FFFFFF !important',
  },
  numberCircle: {
    left: 15,
    position: 'absolute',
    border: `1px solid ${COLORS.white1}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 10,
    color: COLORS.white1,
    borderRadius: '100%',
    width: 18,
    height: 18,
  },
  link: {
    width: 240 - 32,
    whiteSpace: 'normal',
    textDecoration: 'none',
    color: '#EAEBEB',
    fontSize: 16,
    lineHeight: '24px',
    boxSizing: 'border-box',
    borderRadius: 8,
  },
  collapse__link: {
    paddingLeft: '26px',
  },
  link__selected: {
    color: '#FFFFFF',
    fontWeight: 700,
  },
  sublink: {
    height: 52,
    display: 'flex',
    alignItems: 'center',
  },
  nestedSublink: {
    display: 'flex',
    paddingLeft: 47,
  },
  icon: {
    marginRight: 16,
  },
  divider: {
    backgroundColor: '#FFFFFF',
  },
  arrowIcon: {
    transform: 'rotate(180deg)',
  },
  arrowIconSelected: {
    transform: 'rotate(0deg)',
  },
  arrowContainer: {
    width: 33,
  },
}));

export default useStyles;

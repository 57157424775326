import React from 'react';
import { SurveyStatus } from 'src/types';
import { SurveyStatusRow } from './components/SurveyStatusRow';
import useStyles from './useStyles';
import { GrowWrapperComponent } from '../GrowWrapperComponent';

type Props = {
  surveyStatus: SurveyStatus[];
  onSendSurvey: (accountManagerId: number) => void;
  allowSendSurveys?: boolean;
};

export const SurveyStatusTable: React.FC<Props> = ({
  surveyStatus,
  onSendSurvey,
  allowSendSurveys,
}) => {
  const classes = useStyles();

  return (
    <GrowWrapperComponent>
      <div
        style={{ height: surveyStatus.length > 8 ? 'calc(100vh - 455px)' : 'max-content' }}
        className={classes.wrapper}>
        <table className={classes.table}>
          <thead>
            <tr>
              <th>
                <div>Account Manager</div>
              </th>
              <th>
                <div>Status</div>
              </th>
              <th>
                <div>Action</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {surveyStatus.map((item) => (
              <SurveyStatusRow
                allowSendSurveys={allowSendSurveys}
                onSendSurvey={onSendSurvey}
                key={item.accountManagerId}
                {...item}
              />
            ))}
          </tbody>
        </table>
      </div>
    </GrowWrapperComponent>
  );
};

import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../../constants/COLORS';

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: COLORS.white1,
    padding: 24,
    borderRadius: 8,
    boxShadow: '0px 2px 10px rgba(199, 201, 201, 0.5)',
  },
  dropdownButton: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: COLORS.gray11,
      borderRadius: 0,
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: COLORS.black2,
    },
  },
  datePicker: {
    overflow: 'hidden',
    padding: '0 10px',
    width: '142px',
    alignSelf: 'flex-end',
    height: 40,
    '& input:focus': {
      border: `1px solid ${COLORS.blue11}`,
    },
  },
}));

export default useStyles;

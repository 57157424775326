import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  container: {
    overflowX: 'auto',
    overflowY: 'auto',
    height: 'calc(100vh - 480px)',
    boxShadow: '0px 2px 10px rgba(199, 201, 201, 0.5)',
    borderRadius: '8px',
    marginTop: 56,
  },
  table: {
    borderCollapse: 'collapse',
    width: '100%',
    '& tbody tr:nth-child(even)': {
      backgroundColor: COLORS.blue13,
      '& button': {
        backgroundColor: COLORS.blue13,
      },
    },
  },
  tHeader: {
    backgroundColor: COLORS.blue12,
  },
  tHeaderRow: {
    height: 60,
    '& td': {
      verticalAlign: 'bottom',
    },
    '& td:last-child div': {
      paddingRight: 10,
    },
    '& div': {
      fontWeight: '700',
      color: COLORS.black2,
      paddingLeft: 15,
      fontSize: 16,
      paddingBottom: 18,
    },
  },
  dropdownButtonStyle: {
    fontSize: '14px',
  },
  tBodyRow: {
    backgroundColor: COLORS.white1,
    borderBottom: `1px solid ${COLORS.gray5}`,
    height: 50,
    minHeight: 74,
    '& tr:last-child': {
      border: 'none',
    },
    '& td': {
      paddingLeft: 15,
      fontSize: 16,
      color: COLORS.black1,
    },
  },
  businessUnitDisable: {
    backgroundColor: COLORS.white2,
    borderRight: `1px solid ${COLORS.gray18}`,
  },
  revenueRow: {
    '& div': {
      position: 'relative',
      textAlign: 'center',
      top: 10,
      fontSize: 18,
      paddingLeft: 10,
      color: COLORS.black1,
      fontWeight: 700,
      '& + span': {
        position: 'relative',
        display: 'block',
        width: 'calc(100%)',
        left: -1,
        top: 15,
        height: 1,
        backgroundColor: COLORS.blue7,
      },
      '&::before': {
        content: '""',
        display: 'block',
        width: 1,
        height: 83,
        left: -1,
        top: -11,
        backgroundColor: COLORS.blue7,
        position: 'absolute',
      },
      '&::after': {
        content: '""',
        display: 'block',
        width: 1,
        height: 83,
        right: -1,
        top: -11,
        backgroundColor: COLORS.blue7,
        position: 'absolute',
      },
    },
  },
  innerInputStyle: {
    width: 130,
  },
  nameCell: {
    minWidth: 130,
  },
  customerIdCell: {
    minWidth: 120,
  },
  parentCell: {
    minWidth: 120,
  },
  parentInputCell: {
    width: 120,
  },
  parentIdCell: {
    minWidth: 100,
  },
  parentIdInputCell: {
    width: 100,
  },
  revenueCell: {
    width: 100,
  },
  revenueInput: {
    width: 80,
  },
  revenueText: {
    fontSize: 16,
    height: 20,
    minWidth: 250,
  },
}));

export default useStyles;

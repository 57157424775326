import React, { useEffect, useState, FC } from 'react';
import { getNegotiationStatus } from 'src/modules/monitoring/actions';
import useStyles from './useStyles';
import { useDispatch, useSelector } from 'react-redux';
import { COLORS } from '../../../../../constants/COLORS';
import { Store } from '../../../../../types';
import { getBUAbbreviations } from '../../../../../modules/specificTargets/actions';
import { getAccountManagerNames } from '../../../../../modules/TouchableRevenue/actions';
import { ReactComponent as CheckCircleIcon } from '../../../../../assets/icons/check-circle.svg';
import { RevenueImpactChart } from './RevenueImpactChart';
import NegotiationStatusChart from './NegotiationStatusChart';
import MultipleDropdown from '../../../../common/MultipleDropdown';
import { Loader } from 'src/components/common/Loader';
import { getMultipleDropdownValues } from '../../../../../helpers/getMultipleDropdownValues';
import { Dropdown } from '../../../../common/Dropdown';

const indicators = [
  { id: 1, color: '#00A887', text: 'Exceptional' },
  { id: 2, color: '#4CC2AB', text: 'High' },
  { id: 3, color: '#E5BF18', text: 'Average' },
  { id: 4, color: '#FF5982', text: 'Low' },
  { id: 5, color: '#FF9BB4', text: 'Poor' },
];

const getBackgroundColor = (value: string) => {
  switch (value) {
    case 'EXCEPTIONAL':
      return '#00A887';
    case 'HIGH':
      return '#4CC2AB';
    case 'AVERAGE':
      return '#E5BF18';
    case 'LOW':
      return '#FF5982';
    case 'POOR':
      return '#FF9BB4';
    default:
      return 'transparent';
  }
};

export const NegotiationStatus: FC = () => {
  const classes = useStyles();

  const [segmentsArr, setSegmentsArr] = React.useState(['All']);
  const [accountManagersArr, setAccountManagersArr] = React.useState(['All']);

  const {
    specificTargets: { BUAbbreviations },
    touchableRevenue: { accountManagerNames },
    monitoring: {
      negotiationStatus: { negotiationResults },
      loading,
    },
  } = useSelector((state: Store) => state);

  const dispatch = useDispatch();

  const [body, setBody] = useState({
    businessUnitAbbreviations: ['All'],
    accountManagers: ['All'],
    scope: 'ALL',
  });

  useEffect(() => {
    dispatch(getBUAbbreviations.request());
    dispatch(getAccountManagerNames.request());
  }, [dispatch]);

  useEffect(() => {
    const controller = new AbortController();
    dispatch(
      getNegotiationStatus.request({
        body,
        signal: controller.signal,
      }),
    );
    return () => {
      controller.abort();
    };
  }, [dispatch, body]);

  //eslint-disable-next-line
  const handleSegmentsChange = (event: any) => {
    getMultipleDropdownValues(event.target.value, setSegmentsArr);
  };

  useEffect(() => {
    setBody({ ...body, businessUnitAbbreviations: segmentsArr });
  }, [segmentsArr]);

  const handleAccountManagerChange = (event: any) => {
    getMultipleDropdownValues(event.target.value, setAccountManagersArr);
    setBody({ ...body, accountManagers: event.target.value });
  };

  const handleScope = (value: string) => {
    setBody({ ...body, scope: value.toUpperCase() });
  };

  useEffect(() => {
    setBody({ ...body, accountManagers: accountManagersArr });
  }, [accountManagersArr]);

  return (
    <>
      <div className={classes.dropdowns}>
        <div className={classes.dropdown}>
          <MultipleDropdown
            value={segmentsArr}
            array={['All', ...BUAbbreviations]}
            onChange={handleSegmentsChange}
            label="Segments"
            dropdownArrowColor={COLORS.black1}
          />
        </div>
        <div className={classes.dropdown}>
          <MultipleDropdown
            value={accountManagersArr}
            array={['All', ...accountManagerNames]}
            onChange={handleAccountManagerChange}
            label="Account managers"
            dropdownArrowColor={COLORS.black1}
          />
        </div>
        <div className={classes.dropdown}>
          <Dropdown
            onSelectCb={(selectedItem: string) => {
              handleScope(selectedItem);
            }}
            needDisableDefault={false}
            label="Scope (relevant for left side)"
            width={220}
            dropdownItems={['All', 'Finished']}
            defaultItem={'All'}
          />
        </div>
      </div>
      <div className={classes.negotiationStatus}>
        <div className={classes.result}>
          <h1 className={classes.title}>Negotiation results</h1>
          {loading ? (
            <Loader isLoading={loading} marginTop={35} />
          ) : (
            <>
              <div className={classes.increasesBlock}>
                <div className={classes.increasesTitle}>Price increases</div>
                <div className={classes.increasesMain}>
                  <div className={classes.increasesMainLeft}>
                    <div className={classes.increasesMainText}>Achieved</div>
                    <div className={classes.increasesMainValue}>
                      {negotiationResults?.priceIncreasesAchieved}%{' '}
                      <span>
                        (Avg. {negotiationResults?.priceIncreaseAverage > 0 ? '+' : ''}
                        {negotiationResults?.priceIncreaseAverage}% pts)
                      </span>
                    </div>
                    <div className={classes.achievedLine} />
                  </div>
                  <div className={classes.increasesMainRight}>
                    <div className={classes.increasesMainText}>Target</div>
                    <div className={classes.increasesMainValue}>
                      {negotiationResults?.priceIncreasesTarget}%{' '}
                      <span>
                        (Avg. {negotiationResults?.priceIncreaseTargetAverage > 0 ? '+' : ''}
                        {negotiationResults?.priceIncreaseTargetAverage}% pts)
                      </span>
                    </div>
                    <div className={classes.targetLine} />
                  </div>
                </div>
                <div
                  className={classes.increasesPerformance}
                  style={{
                    backgroundColor: getBackgroundColor(
                      negotiationResults?.priceIncreasesPerformance,
                    ),
                  }}>
                  <CheckCircleIcon />
                  <div>
                    {negotiationResults?.priceIncreasesPerformance.charAt(0) +
                      negotiationResults?.priceIncreasesPerformance.substring(1).toLowerCase()}{' '}
                    performance
                  </div>
                </div>
                <div className={classes.increasesIndicators}>
                  <div className={classes.increasesIndicatorsTitle}> Performance indicators</div>
                  <div className={classes.increasesIndicatorsItems}>
                    {indicators.map((item) => (
                      <div className={classes.increasesIndicatorsItem} key={item.id}>
                        <div
                          style={{
                            backgroundColor: `${item.color}`,
                          }}
                          className={classes.increasesIndicatorsItemCircle}
                        />
                        <div className={classes.increasesIndicatorsItemText}>{item.text}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className={classes.impactBlock}>
                <div className={classes.impactTitle}>Annual revenue impact</div>
                <div className={classes.impactChart}>
                  <RevenueImpactChart />
                </div>
                {/*<div*/}
                {/*  className={classes.impactPerformance}*/}
                {/*  style={{*/}
                {/*    backgroundColor: getBackgroundColor(*/}
                {/*      negotiationResults?.annualRevenueImpactPerformance,*/}
                {/*    ),*/}
                {/*  }}>*/}
                {/*  <CheckCircleIcon />*/}
                {/*  <div>Exceptional performance</div>*/}
                {/*</div>*/}
                {/*<div className={classes.impactIndicators}>*/}
                {/*  <div className={classes.impactIndicatorsTitle}> Performance indicators</div>*/}
                {/*  <div className={classes.impactIndicatorsItems}>*/}
                {/*    {indicators.map((item) => (*/}
                {/*      <div className={classes.impactIndicatorsItem} key={item.id}>*/}
                {/*        <div*/}
                {/*          style={{*/}
                {/*            backgroundColor: `${item.color}`,*/}
                {/*          }}*/}
                {/*          className={classes.impactIndicatorsItemCircle}*/}
                {/*        />*/}
                {/*        <div className={classes.impactIndicatorsItemText}>{item.text}</div>*/}
                {/*      </div>*/}
                {/*    ))}*/}
                {/*  </div>*/}
                {/*</div>*/}
              </div>
            </>
          )}
        </div>

        <div className={classes.status}>
          <h1 className={classes.title}>Negotiations status</h1>

          {loading ? <Loader isLoading={loading} marginTop={35} /> : <NegotiationStatusChart />}
        </div>
      </div>
    </>
  );
};

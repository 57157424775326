import { all } from 'redux-saga/effects';
import { watchHome } from 'src/modules/home/sagas';
import { watchTouchableRevenue } from 'src/modules/TouchableRevenue/sagas';
import { watchErrors } from 'src/modules/Errors/sagas';
import { watchCampaignTargets } from 'src/modules/campaignTarget/sagas';
import { watchSurveys } from 'src/modules/surveys/sagas';
import { watchSpecificTargets } from 'src/modules/specificTargets/sagas';
import { watchProjects } from 'src/modules/projects/sagas';
import { watchApp } from 'src/modules/app/sagas';
import { watchBattleCards } from 'src/modules/battleCards/sagas';
import { watchMonitoring } from 'src/modules/monitoring/sagas';

export default function* rootSaga() {
  yield all([
    watchHome(),
    watchTouchableRevenue(),
    watchErrors(),
    watchCampaignTargets(),
    watchSurveys(),
    watchSpecificTargets(),
    watchProjects(),
    watchApp(),
    watchBattleCards(),
    watchMonitoring(),
  ]);
}

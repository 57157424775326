import { createAction } from 'deox';
import { ResponseError } from '../Errors/actions';
import {
  GetBattleCardsCustomersRequestParams,
  GetBattleCardsCustomersResponse,
  GetBattleCardRequestParams,
  GetBattleCardResponse,
  UpdateObjectionHandlingRequestParams,
  CreateObjectionHandlingRequestParams,
  CreateObjectionHandlingResponse,
  DeleteObjectionHandlingRequestParams,
  DeleteObjectionHandlingResponse,
  DeleteValueArgumentationResponse,
  DeleteValueArgumentationRequestParams,
  CreateValueArgumentationRequestParams,
  CreateValueArgumentationResponse,
  UpdateValueArgumentationRequestParams,
  CreateConcessionsMatrixRequestParams,
  CreateUpdatePriceIncreaseBridgeRequestParams,
  DeletePriceIncreaseBridgeRequestParams,
  PriceIncreaseBridgeResponse,
  GetBattleCardsMailStatusesRequestParams,
  BattleCardsMailStatusesResponse,
  SendBattleCardsMailStatusesToAllRequestParams,
  SendBattleCardsToAccountManagerRequestParams,
} from 'src/types';

export const getBattleCardsCustomersForAdmin = {
  request: createAction(
    'battleCards/GET_BATTLE_CARDS_CUSTOMERS_FOR_ADMIN_REQUEST',
    (resolve) => (payload: GetBattleCardsCustomersRequestParams) => resolve(payload),
  ),
  success: createAction(
    'battleCards/GET_BATTLE_CARDS_CUSTOMERS_FOR_ADMIN_SUCCESS',
    (resolve) => (payload: GetBattleCardsCustomersResponse) => resolve(payload),
  ),
  fail: createAction(
    'battleCards/GET_BATTLE_CARDS_CUSTOMERS_FOR_ADMIN_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};
export const getBattleCardsCustomersForAccountManager = {
  request: createAction(
    'battleCards/GET_BATTLE_CARDS_CUSTOMERS_FOR_ACCOUNT_MANAGER_REQUEST',
    (resolve) => (payload: GetBattleCardsCustomersRequestParams) => resolve(payload),
  ),
  success: createAction(
    'battleCards/GET_BATTLE_CARDS_CUSTOMERS_FOR_ACCOUNT_MANAGER_SUCCESS',
    (resolve) => (payload: GetBattleCardsCustomersResponse) => resolve(payload),
  ),
  fail: createAction(
    'battleCards/GET_BATTLE_CARDS_CUSTOMERS_FOR_ACCOUNT_MANAGER_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const getBattleCard = {
  request: createAction(
    'battleCards/GET_BATTLE_CARD_REQUEST',
    (resolve) => (payload: GetBattleCardRequestParams) => resolve(payload),
  ),
  success: createAction(
    'battleCards/GET_BATTLE_CARD_SUCCESS',
    (resolve) => (payload: GetBattleCardResponse) => resolve(payload),
  ),
  fail: createAction(
    'battleCards/GET_BATTLE_CARD_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const getBattleCardForAccountManager = {
  request: createAction(
    'battleCards/GET_BATTLE_CARD_FOR_ACCOUNT_MANAGER_REQUEST',
    (resolve) => (payload: GetBattleCardRequestParams) => resolve(payload),
  ),
  success: createAction(
    'battleCards/GET_BATTLE_CARD_FOR_ACCOUNT_MANAGER_SUCCESS',
    (resolve) => (payload: GetBattleCardResponse) => resolve(payload),
  ),
  fail: createAction(
    'battleCards/GET_BATTLE_CARD_FOR_ACCOUNT_MANAGER_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const updateObjectionHandling = {
  request: createAction(
    'battleCards/UPDATE_OBJECTION_HANDLING_REQUEST',
    (resolve) => (payload: UpdateObjectionHandlingRequestParams) => resolve(payload),
  ),
  success: createAction(
    'battleCards/UPDATE_OBJECTION_HANDLING_SUCCESS',
    (resolve) => () => resolve(),
  ),
  fail: createAction(
    'battleCards/UPDATE_OBJECTION_HANDLING_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const updateObjectionHandlingForAccountManager = {
  request: createAction(
    'battleCards/UPDATE_OBJECTION_HANDLING_FOR_ACCOUNT_MANAGER_REQUEST',
    (resolve) => (payload: UpdateObjectionHandlingRequestParams) => resolve(payload),
  ),
  success: createAction(
    'battleCards/UPDATE_OBJECTION_HANDLING_FOR_ACCOUNT_MANAGER_SUCCESS',
    (resolve) => () => resolve(),
  ),
  fail: createAction(
    'battleCards/UPDATE_OBJECTION_HANDLING_FOR_ACCOUNT_MANAGER_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const createObjectionHandling = {
  request: createAction(
    'battleCards/CREATE_OBJECTION_HANDLING_REQUEST',
    (resolve) => (payload: CreateObjectionHandlingRequestParams) => resolve(payload),
  ),
  success: createAction(
    'battleCards/CREATE_OBJECTION_HANDLING_SUCCESS',
    (resolve) => (payload: CreateObjectionHandlingResponse) => resolve(payload),
  ),
  fail: createAction(
    'battleCards/CREATE_OBJECTION_HANDLING_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const createObjectionHandlingForAccountManager = {
  request: createAction(
    'battleCards/CREATE_OBJECTION_HANDLING_FOR_ACCOUNT_MANAGER_REQUEST',
    (resolve) => (payload: CreateObjectionHandlingRequestParams) => resolve(payload),
  ),
  success: createAction(
    'battleCards/CREATE_OBJECTION_HANDLING_FOR_ACCOUNT_MANAGER_SUCCESS',
    (resolve) => (payload: CreateObjectionHandlingResponse) => resolve(payload),
  ),
  fail: createAction(
    'battleCards/CREATE_OBJECTION_HANDLING_FOR_ACCOUNT_MANAGER_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const deleteObjectionHandling = {
  request: createAction(
    'battleCards/DELETE_OBJECTION_HANDLING_REQUEST',
    (resolve) => (payload: DeleteObjectionHandlingRequestParams) => resolve(payload),
  ),
  success: createAction(
    'battleCards/DELETE_OBJECTION_HANDLING_SUCCESS',
    (resolve) => (payload: DeleteObjectionHandlingResponse) => resolve(payload),
  ),
  fail: createAction(
    'battleCards/DELETE_OBJECTION_HANDLING_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};
export const deleteObjectionHandlingForAccountManager = {
  request: createAction(
    'battleCards/DELETE_OBJECTION_HANDLING_FOR_ACCOUNT_MANAGER_REQUEST',
    (resolve) => (payload: DeleteObjectionHandlingRequestParams) => resolve(payload),
  ),
  success: createAction(
    'battleCards/DELETE_OBJECTION_HANDLING_FOR_ACCOUNT_MANAGER_SUCCESS',
    (resolve) => (payload: DeleteObjectionHandlingResponse) => resolve(payload),
  ),
  fail: createAction(
    'battleCards/DELETE_OBJECTION_HANDLING_FOR_ACCOUNT_MANAGER_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const updateValueArgumentation = {
  request: createAction(
    'battleCards/UPDATE_VALUE_ARGUMENTATION_REQUEST',
    (resolve) => (payload: UpdateValueArgumentationRequestParams) => resolve(payload),
  ),
  success: createAction(
    'battleCards/UPDATE_VALUE_ARGUMENTATION_SUCCESS',
    (resolve) => () => resolve(),
  ),
  fail: createAction(
    'battleCards/UPDATE_VALUE_ARGUMENTATION_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};
export const updateValueArgumentationForAccountManager = {
  request: createAction(
    'battleCards/UPDATE_VALUE_ARGUMENTATION_FOR_ACCOUNT_MANAGER_REQUEST',
    (resolve) => (payload: UpdateValueArgumentationRequestParams) => resolve(payload),
  ),
  success: createAction(
    'battleCards/UPDATE_VALUE_ARGUMENTATION_FOR_ACCOUNT_MANAGER_SUCCESS',
    (resolve) => () => resolve(),
  ),
  fail: createAction(
    'battleCards/UPDATE_VALUE_ARGUMENTATION_FOR_ACCOUNT_MANAGER_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const createValueArgumentation = {
  request: createAction(
    'battleCards/CREATE_VALUE_ARGUMENTATION_REQUEST',
    (resolve) => (payload: CreateValueArgumentationRequestParams) => resolve(payload),
  ),
  success: createAction(
    'battleCards/CREATE_VALUE_ARGUMENTATION_SUCCESS',
    (resolve) => (payload: CreateValueArgumentationResponse) => resolve(payload),
  ),
  fail: createAction(
    'battleCards/CREATE_VALUE_ARGUMENTATION_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};
export const createValueArgumentationForAccountManager = {
  request: createAction(
    'battleCards/CREATE_VALUE_ARGUMENTATION_FOR_ACCOUNT_MANAGER_REQUEST',
    (resolve) => (payload: CreateValueArgumentationRequestParams) => resolve(payload),
  ),
  success: createAction(
    'battleCards/CREATE_VALUE_ARGUMENTATION_FOR_ACCOUNT_MANAGER_SUCCESS',
    (resolve) => (payload: CreateValueArgumentationResponse) => resolve(payload),
  ),
  fail: createAction(
    'battleCards/CREATE_VALUE_ARGUMENTATION_FOR_ACCOUNT_MANAGER_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const deleteValueArgumentation = {
  request: createAction(
    'battleCards/DELETE_VALUE_ARGUMENTATION_REQUEST',
    (resolve) => (payload: DeleteValueArgumentationRequestParams) => resolve(payload),
  ),
  success: createAction(
    'battleCards/DELETE_VALUE_ARGUMENTATION_SUCCESS',
    (resolve) => (payload: DeleteValueArgumentationResponse) => resolve(payload),
  ),
  fail: createAction(
    'battleCards/DELETE_VALUE_ARGUMENTATION_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const deleteValueArgumentationForAccountManager = {
  request: createAction(
    'battleCards/DELETE_VALUE_ARGUMENTATION_FOR_ACCOUNT_MANAGER_REQUEST',
    (resolve) => (payload: DeleteValueArgumentationRequestParams) => resolve(payload),
  ),
  success: createAction(
    'battleCards/DELETE_VALUE_ARGUMENTATION_FOR_ACCOUNT_MANAGER_SUCCESS',
    (resolve) => (payload: DeleteValueArgumentationResponse) => resolve(payload),
  ),
  fail: createAction(
    'battleCards/DELETE_VALUE_ARGUMENTATION_FOR_ACCOUNT_MANAGER_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const createConcessionsMatrix = {
  request: createAction(
    'battleCards/CREATE_CONCESSIONS_MATRIX_REQUEST',
    (resolve) => (payload: CreateConcessionsMatrixRequestParams) => resolve(payload),
  ),
  success: createAction(
    'battleCards/CREATE_CONCESSIONS_MATRIX_SUCCESS',
    (resolve) => () => resolve(),
  ),
  fail: createAction(
    'battleCards/CREATE_CONCESSIONS_MATRIX_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};
export const createConcessionsMatrixForAccountManager = {
  request: createAction(
    'battleCards/CREATE_CONCESSIONS_MATRIX_FOR_ACCOUNT_MANAGER_REQUEST',
    (resolve) => (payload: CreateConcessionsMatrixRequestParams) => resolve(payload),
  ),
  success: createAction(
    'battleCards/CREATE_CONCESSIONS_MATRIX_FOR_ACCOUNT_MANAGER_SUCCESS',
    (resolve) => () => resolve(),
  ),
  fail: createAction(
    'battleCards/CREATE_CONCESSIONS_MATRIX_FOR_ACCOUNT_MANAGER_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const updatePriceIncreaseBridgeContractTerm = {
  request: createAction(
    'battleCards/UPDATE_PRICE_INCREASE_BRIDGE_CONTRACT_TERM_REQUEST',
    (resolve) => (payload: CreateUpdatePriceIncreaseBridgeRequestParams) => resolve(payload),
  ),
  success: createAction(
    'battleCards/UPDATE_PRICE_INCREASE_BRIDGE_CONTRACT_TERM_SUCCESS',
    (resolve) => (payload: PriceIncreaseBridgeResponse) => resolve(payload),
  ),
  fail: createAction(
    'battleCards/UPDATE_PRICE_INCREASE_BRIDGE_CONTRACT_TERM_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};
export const updatePriceIncreaseBridgeContractTermForAccountManager = {
  request: createAction(
    'battleCards/UPDATE_PRICE_INCREASE_BRIDGE_CONTRACT_TERM_FOR_ACCOUNT_MANAGER_REQUEST',
    (resolve) => (payload: CreateUpdatePriceIncreaseBridgeRequestParams) => resolve(payload),
  ),
  success: createAction(
    'battleCards/UPDATE_PRICE_INCREASE_BRIDGE_CONTRACT_TERM_FOR_ACCOUNT_MANAGER_SUCCESS',
    (resolve) => (payload: PriceIncreaseBridgeResponse) => resolve(payload),
  ),
  fail: createAction(
    'battleCards/UPDATE_PRICE_INCREASE_BRIDGE_CONTRACT_FOR_ACCOUNT_MANAGER_TERM_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};
export const updatePriceIncreaseBridgePlanB = {
  request: createAction(
    'battleCards/UPDATE_PRICE_INCREASE_BRIDGE_PLAN_B_REQUEST',
    (resolve) => (payload: CreateUpdatePriceIncreaseBridgeRequestParams) => resolve(payload),
  ),
  success: createAction(
    'battleCards/UPDATE_PRICE_INCREASE_BRIDGE_PLAN_B_SUCCESS',
    (resolve) => (payload: PriceIncreaseBridgeResponse) => resolve(payload),
  ),
  fail: createAction(
    'battleCards/UPDATE_PRICE_INCREASE_BRIDGE_PLAN_B_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};
export const updatePriceIncreaseBridgePlanBForAccountManager = {
  request: createAction(
    'battleCards/UPDATE_PRICE_INCREASE_BRIDGE_PLAN_B_FOR_ACCOUNT_MANAGER_REQUEST',
    (resolve) => (payload: CreateUpdatePriceIncreaseBridgeRequestParams) => resolve(payload),
  ),
  success: createAction(
    'battleCards/UPDATE_PRICE_INCREASE_BRIDGE_PLAN_B_FOR_ACCOUNT_MANAGER_SUCCESS',
    (resolve) => (payload: PriceIncreaseBridgeResponse) => resolve(payload),
  ),
  fail: createAction(
    'battleCards/UPDATE_PRICE_INCREASE_BRIDGE_PLAN_B_FOR_ACCOUNT_MANAGER_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const createPriceIncreaseBridgeContractTerm = {
  request: createAction(
    'battleCards/CREATE_PRICE_INCREASE_BRIDGE_CONTRACT_TERM_REQUEST',
    (resolve) => (payload: CreateUpdatePriceIncreaseBridgeRequestParams) => resolve(payload),
  ),
  success: createAction(
    'battleCards/CREATE_PRICE_INCREASE_BRIDGE_CONTRACT_TERM_SUCCESS',
    (resolve) => (payload: PriceIncreaseBridgeResponse) => resolve(payload),
  ),
  fail: createAction(
    'battleCards/CREATE_PRICE_INCREASE_BRIDGE_CONTRACT_TERM_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};
export const createPriceIncreaseBridgeContractTermForAccountManager = {
  request: createAction(
    'battleCards/CREATE_PRICE_INCREASE_BRIDGE_CONTRACT_TERM_FOR_ACCOUNT_MANAGER_REQUEST',
    (resolve) => (payload: CreateUpdatePriceIncreaseBridgeRequestParams) => resolve(payload),
  ),
  success: createAction(
    'battleCards/CREATE_PRICE_INCREASE_BRIDGE_CONTRACT_TERM_FOR_ACCOUNT_MANAGER_SUCCESS',
    (resolve) => (payload: PriceIncreaseBridgeResponse) => resolve(payload),
  ),
  fail: createAction(
    'battleCards/CREATE_PRICE_INCREASE_BRIDGE_CONTRACT_TERM_FOR_ACCOUNT_MANAGER_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const createPriceIncreaseBridgePlanB = {
  request: createAction(
    'battleCards/CREATE_PRICE_INCREASE_BRIDGE_PLAN_B_REQUEST',
    (resolve) => (payload: CreateUpdatePriceIncreaseBridgeRequestParams) => resolve(payload),
  ),
  success: createAction(
    'battleCards/CREATE_PRICE_INCREASE_BRIDGE_PLAN_B_SUCCESS',
    (resolve) => (payload: PriceIncreaseBridgeResponse) => resolve(payload),
  ),
  fail: createAction(
    'battleCards/CREATE_PRICE_INCREASE_BRIDGE_PLAN_B_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};
export const createPriceIncreaseBridgePlanBForAccountManager = {
  request: createAction(
    'battleCards/CREATE_PRICE_INCREASE_BRIDGE_PLAN_B_FOR_ACCOUNT_MANAGER_REQUEST',
    (resolve) => (payload: CreateUpdatePriceIncreaseBridgeRequestParams) => resolve(payload),
  ),
  success: createAction(
    'battleCards/CREATE_PRICE_INCREASE_BRIDGE_PLAN_B_FOR_ACCOUNT_MANAGER_SUCCESS',
    (resolve) => (payload: PriceIncreaseBridgeResponse) => resolve(payload),
  ),
  fail: createAction(
    'battleCards/CREATE_PRICE_INCREASE_BRIDGE_PLAN_B_FOR_ACCOUNT_MANAGER_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const deletePriceIncreaseBridgeContractTerm = {
  request: createAction(
    'battleCards/DELETE_PRICE_INCREASE_BRIDGE_CONTRACT_TERM_REQUEST',
    (resolve) => (payload: DeletePriceIncreaseBridgeRequestParams) => resolve(payload),
  ),
  success: createAction(
    'battleCards/DELETE_PRICE_INCREASE_BRIDGE_CONTRACT_TERM_SUCCESS',
    (resolve) => (payload: PriceIncreaseBridgeResponse) => resolve(payload),
  ),
  fail: createAction(
    'battleCards/DELETE_PRICE_INCREASE_BRIDGE_CONTRACT_TERM_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const deletePriceIncreaseBridgeContractTermForAccountManager = {
  request: createAction(
    'battleCards/DELETE_PRICE_INCREASE_BRIDGE_CONTRACT_TERM_FOR_ACCOUNT_MANAGER_REQUEST',
    (resolve) => (payload: DeletePriceIncreaseBridgeRequestParams) => resolve(payload),
  ),
  success: createAction(
    'battleCards/DELETE_PRICE_INCREASE_BRIDGE_CONTRACT_TERM_FOR_ACCOUNT_MANAGER_SUCCESS',
    (resolve) => (payload: PriceIncreaseBridgeResponse) => resolve(payload),
  ),
  fail: createAction(
    'battleCards/DELETE_PRICE_INCREASE_BRIDGE_CONTRACT_TERM_FOR_ACCOUNT_MANAGER_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const deletePriceIncreaseBridgePlanB = {
  request: createAction(
    'battleCards/DELETE_PRICE_INCREASE_BRIDGE_PLAN_B_REQUEST',
    (resolve) => (payload: DeletePriceIncreaseBridgeRequestParams) => resolve(payload),
  ),
  success: createAction(
    'battleCards/DELETE_PRICE_INCREASE_BRIDGE_PLAN_B_SUCCESS',
    (resolve) => (payload: PriceIncreaseBridgeResponse) => resolve(payload),
  ),
  fail: createAction(
    'battleCards/DELETE_PRICE_INCREASE_BRIDGE_PLAN_B_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const deletePriceIncreaseBridgePlanBForAccountManager = {
  request: createAction(
    'battleCards/DELETE_PRICE_INCREASE_BRIDGE_PLAN_B_FOR_ACCOUNT_MANAGER_REQUEST',
    (resolve) => (payload: DeletePriceIncreaseBridgeRequestParams) => resolve(payload),
  ),
  success: createAction(
    'battleCards/DELETE_PRICE_INCREASE_BRIDGE_PLAN_B_FOR_ACCOUNT_MANAGER_SUCCESS',
    (resolve) => (payload: PriceIncreaseBridgeResponse) => resolve(payload),
  ),
  fail: createAction(
    'battleCards/DELETE_PRICE_INCREASE_BRIDGE_PLAN_B_FOR_ACCOUNT_MANAGER_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const getBattleCardsMailStatuses = {
  request: createAction(
    'battleCards/GET_BATTLE_CARDS_MAIL_STATUSES_REQUEST',
    (resolve) => (payload: GetBattleCardsMailStatusesRequestParams) => resolve(payload),
  ),
  success: createAction(
    'battleCards/GET_BATTLE_CARDS_MAIL_STATUSES_SUCCESS',
    (resolve) => (payload: BattleCardsMailStatusesResponse) => resolve(payload),
  ),
  fail: createAction(
    'battleCards/GET_BATTLE_CARDS_MAIL_STATUSES_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const sendBattleCardsMailStatusesToAll = {
  request: createAction(
    'battleCards/SEND_BATTLE_CARDS_MAIL_STATUSES_TO_ALL_REQUEST',
    (resolve) => (payload: SendBattleCardsMailStatusesToAllRequestParams) => resolve(payload),
  ),
  success: createAction(
    'battleCards/SEND_BATTLE_CARDS_MAIL_STATUSES_TO_ALL_SUCCESS',
    (resolve) => (payload: { isSecondBattleCardsMailStatus: boolean }) => resolve(payload),
  ),
  fail: createAction(
    'battleCards/SEND_BATTLE_CARDS_MAIL_STATUSES_TO_ALL_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const sendBattleCardsToAccountManager = {
  request: createAction(
    'battleCards/SEND_BATTLE_CARDS_TO_ACCOUNT_MANAGER_REQUEST',
    (resolve) => (payload: SendBattleCardsToAccountManagerRequestParams) => resolve(payload),
  ),
  success: createAction(
    'battleCards/SEND_BATTLE_CARDS_TO_ACCOUNT_MANAGER_SUCCESS',
    (resolve) => (payload: { accountManagerId: number; isSecondBattleCards?: boolean }) =>
      resolve(payload),
  ),
  fail: createAction(
    'battleCards/SEND_BATTLE_CARDS_TO_ACCOUNT_MANAGER_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

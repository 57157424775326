import React, { memo, useState } from 'react';
import { Stack, Pagination as MUIPagination, Typography, Box } from '@mui/material';
import Fade from '@mui/material/Fade';

import useStyles from './useStyles';

import { ReactComponent as ArrowDown } from 'src/assets/icons/red-arrow-down.svg';
import { COLORS } from 'src/constants/COLORS';
import NumberFormat, { NumberFormatValues } from 'react-number-format';

type Props = {
  marginTop?: number;
  justifyContent?: 'string';
  page: number;
  totalPages: number;
  rowPerPage: number;
  setRowPerPage: (value: number) => void;
  setPage: (event: React.ChangeEvent<unknown>, value: number) => void;
  goToPage: (page: number) => void;
};

export const Pagination: React.FC<Props> = memo(function Pagination({
  justifyContent = 'space-between',
  marginTop,
  page,
  rowPerPage,
  setPage,
  setRowPerPage,
  totalPages,
  goToPage,
}) {
  const classes = useStyles();

  const [pagesPerTableVisible, setPagesPerTableVisible] = useState(false);
  const [goToValue, setGoToValue] = useState(1);

  const handleClick = () => {
    if (!pagesPerTableVisible) {
      setPagesPerTableVisible(true);
    }

    if (pagesPerTableVisible) {
      setPagesPerTableVisible(false);
    }
  };

  const handleClickItem = (e: React.MouseEvent<HTMLElement>) => {
    // eslint-disable-next-line
    setRowPerPage(+(e.target as any).textContent);

    setPagesPerTableVisible(false);
  };

  const goToChosenPage = (e: React.KeyboardEvent) => {
    const { key } = e;

    if (key === 'Enter' && goToValue <= totalPages) {
      goToPage(goToValue);
    }
  };

  const handeGoToValue = (values: NumberFormatValues) => {
    setGoToValue(+values.value);
  };

  return totalPages > 0 ? (
    <Stack flexDirection="row" justifyContent={justifyContent} marginTop={marginTop}>
      <div className={classes.dropdownWrapper}>
        <Typography variant="subtitle2">Rows per page</Typography>
        <div className={classes.rowPerPageButton} onClick={handleClick}>
          <Typography variant="subtitle2">{rowPerPage}</Typography>
          <ArrowDown
            className={`${classes.arrowDown} ${pagesPerTableVisible && classes.arrowUp}`}
            fill={COLORS.red1}
          />
        </div>
        {pagesPerTableVisible && (
          <Fade in={pagesPerTableVisible} {...(pagesPerTableVisible ? { timeout: 300 } : {})}>
            <ul className={classes.listWrapper} onClick={handleClick}>
              <li className={classes.listItem} onClick={handleClickItem}>
                15
              </li>
              <li className={classes.listItem} onClick={handleClickItem}>
                35
              </li>
              <li className={classes.listItem} onClick={handleClickItem}>
                50
              </li>
            </ul>
          </Fade>
        )}
      </div>
      <Box flexDirection="row" display="flex" alignItems="center">
        <MUIPagination
          defaultPage={5}
          onChange={setPage}
          page={page === 0 ? 1 : page}
          count={totalPages}
        />
        <Typography>Go to</Typography>
        <NumberFormat
          displayType="input"
          allowNegative={false}
          isAllowed={(values: NumberFormatValues) => {
            return values.value.slice(-1) !== '.';
          }}
          type="text"
          onValueChange={handeGoToValue}
          onKeyDown={goToChosenPage}
          className={classes.goToInput}
        />
      </Box>
    </Stack>
  ) : null;
});

import React, { FC } from 'react';
import useStyles from './useStyles';
import { GrowWrapperComponent } from 'src/components/common/GrowWrapperComponent';
import { ButtonBase } from '../../../../../common/ButtonBase';
import { BattleCardsCustomer } from '../../../../../../types';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatNumberWithCommas } from '../../../../../../helpers/formatNumberWithCommas';

type BattleCardsTableProps = {
  isBattleCards?: boolean;
  customers: BattleCardsCustomer[];
};

export const BattleCardsTable: FC<BattleCardsTableProps> = ({ isBattleCards, customers }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleOpenCard = (id: string) => {
    navigate(`${pathname}/${id}`);
  };

  return (
    <GrowWrapperComponent>
      <div className={classes.container}>
        <table className={classes.table}>
          <thead className={classes.tHeader}>
            <tr className={classes.tHeaderRow}>
              <td>Customer</td>
              <td>Customer ID</td>
              <td>Parent</td>
              <td>Parent ID</td>
              {!isBattleCards && <td>Account manager</td>}
              <td>Revenue</td>
              <td>Battlecard</td>
            </tr>
          </thead>
          <tbody>
            {customers.map((item: BattleCardsCustomer) => (
              <tr key={item.id} className={classes.tBodyRow}>
                <td>{item.name}</td>
                <td>{item.id}</td>
                <td>{item.parent}</td>
                <td>{item.parentId}</td>
                {!isBattleCards && <td>{item.accountManager}</td>}
                <td>{formatNumberWithCommas(item.revenue)}</td>
                <td>
                  <ButtonBase
                    title="Open"
                    variant="outlined"
                    onClick={() => handleOpenCard(item.id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </GrowWrapperComponent>
  );
};

import React from 'react';
import { Modal } from '@mui/material';
import { ButtonBase } from 'src/components/common/ButtonBase';
import useStyles from './useStyles';

type Props = {
  open: boolean;
  onClose: () => void;
};

export const InfoModal: React.FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose}>
      <div className={classes.container}>
        <p className={classes.text}>The file upload may take some time.</p>
        <ButtonBase
          containerStyle={classes.button}
          variant="contained"
          color="secondary"
          title="Ok"
          onClick={onClose}
        />
      </div>
    </Modal>
  );
};

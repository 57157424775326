import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: COLORS.white1,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '600px',
    minHeight: '90%',
    maxHeight: '90%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    border: 'none',
    padding: '15px',
    outline: 'none',
    borderRadius: 8,
  },
  loader: {
    flex: '1 1 100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: '18px',
    fontWeight: 700,
    margin: '13px 0 18px',
    textAlign: 'center',
    color: COLORS.black2,
  },
  item: {
    marginBottom: 20,
  },
  itemSecondary: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 30,
  },
  itemInnerLeft: {
    flex: '1 1 60%',
  },
  innerRight: {
    flex: '1 1 40%',
  },
  itemTitle: {
    fontSize: 14,
    fontWeight: 700,
    marginBottom: 5,
  },
  itemText: {
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    height: 40,
  },
  label: {
    fontSize: 14,
    fontWeight: 700,
    marginBottom: 5,
    '& span': {
      color: COLORS.red1,
    },
  },
  input: {
    fontSize: '14px',
    width: '100%',
  },

  textarea: {
    border: `1px solid ${COLORS.gray26}`,
    borderRadius: 8,
    height: '80px',
    width: '100%',
    outline: 'none',
    padding: '10px 16px',
    fontSize: '16px',
    color: COLORS.black1,
    '&::placeholder': {
      color: COLORS.gray22,
    },
    '&:placeholder': {
      color: COLORS.gray25,
    },
    '&:hover': {
      borderColor: COLORS.blue2,
    },
    '&:focus': {
      borderColor: COLORS.blue11,
    },
    resize: 'none',
  },
  borderColorRed: {
    borderColor: COLORS.red1,
    '&:hover': {
      borderColor: COLORS.red1,
    },
    '&:focus': {
      borderColor: COLORS.red1,
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export default useStyles;

import React, { memo, useCallback, useContext, useMemo } from 'react';
import { Checkbox } from 'src/components/common/Checkbox';
import { ChosenPlanBOption, SurveyResultSetPlanBOptions } from 'src/types';
import { EmptyCell } from '../EmptyCell';
import { SurveyResultProvider, SurveyResultProviderTypes } from '../../SurveyResultProvider';
import { COLORS } from '../../../../../../../constants/COLORS';

type Props = {
  chosenPlanBOptions: ChosenPlanBOption[];
  setPlanBOption: (body: SurveyResultSetPlanBOptions) => void;
  customerId: number;
  businessUnitId?: number;
  isDisabled?: boolean;
  isEven: boolean;
  isGroup: boolean;
};

export const PlanBColumns: React.FC<Props> = memo(function PlanBColumns({
  chosenPlanBOptions,
  setPlanBOption,
  customerId,
  businessUnitId,
  isDisabled,
  isEven,
  isGroup,
}) {
  const { disableEditPage, surveyResultPlanBOptions } = useContext(
    SurveyResultProvider,
  ) as SurveyResultProviderTypes;

  const onCheck = useCallback(
    (isChecked, optionId) => {
      if (businessUnitId) {
        setPlanBOption({
          businessUnitData: {
            customerId,
            optionId,
            isChosen: isChecked,
            businessUnitId,
            isGroup,
          },
        });
      } else {
        setPlanBOption({
          customerData: {
            customerId,
            optionId,
            isChosen: isChecked,
            isGroup,
          },
        });
      }
    },
    [businessUnitId, customerId, isGroup, setPlanBOption],
  );

  const EnhancedCellsComponent = useMemo(() => {
    if (isDisabled) {
      return surveyResultPlanBOptions.map((item) => (
        <EmptyCell isEven={isEven} key={item.optionId}>
          Empty
        </EmptyCell>
      ));
    }

    return chosenPlanBOptions.map((option, index, array) => (
      <td
        style={{
          width: '80px',
          backgroundColor: isEven ? COLORS.white1 : COLORS.blue13,
          borderRight: index === array.length - 1 ? `1px solid ${COLORS.gray3}` : '',
        }}
        key={option.optionId}>
        <Checkbox
          disabled={disableEditPage}
          onChangeCb={(isChecked) => {
            onCheck(isChecked, option.optionId);
          }}
          isChecked={!!option.isChosen}
        />
      </td>
    ));
  }, [chosenPlanBOptions, disableEditPage, isDisabled, isEven, onCheck, surveyResultPlanBOptions]);

  return <>{EnhancedCellsComponent}</>;
});

import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../../../constants/COLORS';

const useStyles = makeStyles(() => ({
  negotiationStatusChart: {
    padding: 20,
    maxWidth: 750,
    height: 500,
    position: 'relative',
  },
  xAxisLine: {
    position: 'absolute',
    width: '95%',
    height: 7,
    backgroundColor: COLORS.gray21,
    bottom: 49,
  },
  xAxisLineLabel: {
    display: 'flex',
  },
  itemLabel: {
    border: `1px solid ${COLORS.gray21}`,
    textAlign: 'center',
    width: 110,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 14,
    padding: 2,
    '&:first-child': {
      border: 'none',
      width: 110,
      marginLeft: '4%',
    },
    '&:nth-child(2)': {
      marginLeft: '3.5%',
    },
    '&:nth-child(3)': {
      marginLeft: '3.5%',
    },
    '&:nth-child(4)': {
      marginLeft: '3.5%',
    },
    '&:nth-child(5)': {
      marginLeft: '3%',
    },
  },
}));

export default useStyles;

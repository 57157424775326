import { takeLatest, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { ActionType } from 'deox';
import { processRequestError } from '../Errors/actions';
import { select } from 'typed-redux-saga';
import { Store } from 'src/types';

import {
  getBattleCardsCustomersForAdmin,
  getBattleCardsCustomersForAccountManager,
  getBattleCard,
  updateObjectionHandling,
  createObjectionHandling,
  deleteObjectionHandling,
  updateValueArgumentation,
  updateValueArgumentationForAccountManager,
  createValueArgumentation,
  deleteValueArgumentation,
  getBattleCardForAccountManager,
  createValueArgumentationForAccountManager,
  deleteValueArgumentationForAccountManager,
  updateObjectionHandlingForAccountManager,
  createObjectionHandlingForAccountManager,
  deleteObjectionHandlingForAccountManager,
  createConcessionsMatrix,
  createConcessionsMatrixForAccountManager,
  updatePriceIncreaseBridgeContractTerm,
  updatePriceIncreaseBridgePlanB,
  createPriceIncreaseBridgeContractTerm,
  createPriceIncreaseBridgePlanB,
  deletePriceIncreaseBridgeContractTerm,
  deletePriceIncreaseBridgePlanB,
  updatePriceIncreaseBridgeContractTermForAccountManager,
  updatePriceIncreaseBridgePlanBForAccountManager,
  createPriceIncreaseBridgeContractTermForAccountManager,
  createPriceIncreaseBridgePlanBForAccountManager,
  deletePriceIncreaseBridgeContractTermForAccountManager,
  deletePriceIncreaseBridgePlanBForAccountManager,
  getBattleCardsMailStatuses,
  sendBattleCardsMailStatusesToAll,
  sendBattleCardsToAccountManager,
} from './actions';
import { toast } from 'react-toastify';

function* getBattleCardsCustomersForAdminSaga({
  payload,
}: ActionType<typeof getBattleCardsCustomersForAdmin.request>) {
  const { projectId } = yield select((state: Store) => state.projects);
  try {
    const { data } = yield API.getBattleCardsCustomersForAdmin({ projectId, ...payload });

    yield put(getBattleCardsCustomersForAdmin.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: getBattleCardsCustomersForAdmin.fail }));
  }
}

function* getBattleCardsCustomersForAccountManagerSaga({
  payload,
}: ActionType<typeof getBattleCardsCustomersForAccountManager.request>) {
  try {
    const { data } = yield API.getBattleCardsCustomersForAccountManager(payload);

    yield put(getBattleCardsCustomersForAccountManager.success(data));
  } catch (error) {
    yield put(
      processRequestError({ error, failAction: getBattleCardsCustomersForAccountManager.fail }),
    );
  }
}

function* getBattleCardSaga({ payload }: ActionType<typeof getBattleCard.request>) {
  const { projectId } = yield select((state: Store) => state.projects);
  try {
    const { data } = yield API.getBattleCard({ projectId, ...payload });

    yield put(getBattleCard.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: getBattleCard.fail }));
  }
}

function* getBattleCardForAccountManagerSaga({
  payload,
}: ActionType<typeof getBattleCardForAccountManager.request>) {
  try {
    const { data } = yield API.getBattleCardForAccountManager(payload);

    yield put(getBattleCardForAccountManager.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: getBattleCardForAccountManager.fail }));
  }
}

function* updateObjectionHandlingSaga({
  payload,
}: ActionType<typeof updateObjectionHandling.request>) {
  const { projectId } = yield select((state: Store) => state.projects);
  try {
    yield API.updateObjectionHandling({
      ...payload,
      params: { ...payload.params, projectId },
    });

    yield put(updateObjectionHandling.success());
  } catch (error) {
    yield put(processRequestError({ error, failAction: updateObjectionHandling.fail }));
  }
}

function* updateObjectionHandlingForAccountManagerSaga({
  payload,
}: ActionType<typeof updateObjectionHandlingForAccountManager.request>) {
  try {
    yield API.updateObjectionHandlingForAccountManager(payload);

    yield put(updateObjectionHandlingForAccountManager.success());
  } catch (error) {
    yield put(
      processRequestError({ error, failAction: updateObjectionHandlingForAccountManager.fail }),
    );
  }
}

function* createObjectionHandlingSaga({
  payload,
}: ActionType<typeof createObjectionHandling.request>) {
  const { projectId } = yield select((state: Store) => state.projects);
  try {
    const { data } = yield API.createObjectionHandling({
      ...payload,
      params: { ...payload.params, projectId },
    });

    yield put(createObjectionHandling.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: createObjectionHandling.fail }));
  }
}

function* createObjectionHandlingForAccountManagerSaga({
  payload,
}: ActionType<typeof createObjectionHandlingForAccountManager.request>) {
  try {
    const { data } = yield API.createObjectionHandlingForAccountManager(payload);

    yield put(createObjectionHandlingForAccountManager.success(data));
  } catch (error) {
    yield put(
      processRequestError({ error, failAction: createObjectionHandlingForAccountManager.fail }),
    );
  }
}

function* deleteObjectionHandlingSaga({
  payload,
}: ActionType<typeof deleteObjectionHandling.request>) {
  const { projectId } = yield select((state: Store) => state.projects);
  try {
    const { data } = yield API.deleteObjectionHandling({ ...payload, projectId });

    yield put(deleteObjectionHandling.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: deleteObjectionHandling.fail }));
  }
}

function* deleteObjectionHandlingForAccountManagerSaga({
  payload,
}: ActionType<typeof deleteObjectionHandlingForAccountManager.request>) {
  try {
    const { data } = yield API.deleteObjectionHandlingForAccountManager(payload);

    yield put(deleteObjectionHandlingForAccountManager.success(data));
  } catch (error) {
    yield put(
      processRequestError({ error, failAction: deleteObjectionHandlingForAccountManager.fail }),
    );
  }
}

function* updateValueArgumentationSaga({
  payload,
}: ActionType<typeof updateValueArgumentation.request>) {
  const { projectId } = yield select((state: Store) => state.projects);
  try {
    yield API.updateValueArgumentation({
      ...payload,
      params: { ...payload.params, projectId },
    });
    yield put(updateValueArgumentation.success());
  } catch (error) {
    yield put(processRequestError({ error, failAction: updateValueArgumentation.fail }));
  }
}

function* updateValueArgumentationForAccountManagerSaga({
  payload,
}: ActionType<typeof updateValueArgumentationForAccountManager.request>) {
  try {
    yield API.updateValueArgumentationForAccountManager(payload);

    yield put(updateValueArgumentationForAccountManager.success());
  } catch (error) {
    yield put(
      processRequestError({
        error,
        failAction: updateValueArgumentationForAccountManager.fail,
      }),
    );
  }
}

function* createValueArgumentationSaga({
  payload,
}: ActionType<typeof createValueArgumentation.request>) {
  const { projectId } = yield select((state: Store) => state.projects);
  try {
    const { data } = yield API.createValueArgumentation({
      ...payload,
      params: { ...payload.params, projectId },
    });

    yield put(createValueArgumentation.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: createValueArgumentation.fail }));
  }
}

function* createValueArgumentationForAccountManagerSaga({
  payload,
}: ActionType<typeof createValueArgumentationForAccountManager.request>) {
  try {
    const { data } = yield API.createValueArgumentationForAccountManager(payload);

    yield put(createValueArgumentationForAccountManager.success(data));
  } catch (error) {
    yield put(
      processRequestError({
        error,
        failAction: createValueArgumentationForAccountManager.fail,
      }),
    );
  }
}

function* deleteValueArgumentationSaga({
  payload,
}: ActionType<typeof deleteValueArgumentation.request>) {
  const { projectId } = yield select((state: Store) => state.projects);
  try {
    const { data } = yield API.deleteValueArgumentation({ ...payload, projectId });

    yield put(deleteValueArgumentation.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: deleteValueArgumentation.fail }));
  }
}

function* deleteValueArgumentationForAccountManagerSaga({
  payload,
}: ActionType<typeof deleteValueArgumentationForAccountManager.request>) {
  try {
    const { data } = yield API.deleteValueArgumentationForAccountManager(payload);

    yield put(deleteValueArgumentationForAccountManager.success(data));
  } catch (error) {
    yield put(
      processRequestError({
        error,
        failAction: deleteValueArgumentationForAccountManager.fail,
      }),
    );
  }
}

function* createConcessionsMatrixSaga({
  payload,
}: ActionType<typeof createConcessionsMatrix.request>) {
  const { projectId } = yield select((state: Store) => state.projects);
  try {
    yield API.createConcessionsMatrix({
      ...payload,
      params: { ...payload.params, projectId },
    });

    yield put(createConcessionsMatrix.success());
  } catch (error) {
    yield put(
      processRequestError({
        error,
        failAction: createConcessionsMatrix.fail,
      }),
    );
  }
}

function* createConcessionsMatrixForAccountManagerSaga({
  payload,
}: ActionType<typeof createConcessionsMatrixForAccountManager.request>) {
  try {
    yield API.createConcessionsMatrixForAccountManager(payload);

    yield put(createConcessionsMatrixForAccountManager.success());
  } catch (error) {
    yield put(
      processRequestError({
        error,
        failAction: createConcessionsMatrix.fail,
      }),
    );
  }
}

function* updatePriceIncreaseBridgeContractTermSaga({
  payload,
}: ActionType<typeof updatePriceIncreaseBridgeContractTerm.request>) {
  const { projectId } = yield select((state: Store) => state.projects);
  try {
    const { data } = yield API.updatePriceIncreaseBridgeContractTerm({
      ...payload,
      params: { ...payload.params, projectId },
    });

    yield put(updatePriceIncreaseBridgeContractTerm.success(data));
  } catch (error) {
    yield put(
      processRequestError({
        error,
        failAction: updatePriceIncreaseBridgeContractTerm.fail,
      }),
    );
  }
}

function* updatePriceIncreaseBridgeContractTermForAccountManagerSaga({
  payload,
}: ActionType<typeof updatePriceIncreaseBridgeContractTermForAccountManager.request>) {
  try {
    const { data } = yield API.updatePriceIncreaseBridgeContractTermForAccountManager({
      ...payload,
      params: { ...payload.params },
    });

    yield put(updatePriceIncreaseBridgeContractTermForAccountManager.success(data));
  } catch (error) {
    yield put(
      processRequestError({
        error,
        failAction: updatePriceIncreaseBridgeContractTermForAccountManager.fail,
      }),
    );
  }
}

function* updatePriceIncreaseBridgePlanBSaga({
  payload,
}: ActionType<typeof updatePriceIncreaseBridgePlanB.request>) {
  const { projectId } = yield select((state: Store) => state.projects);
  try {
    const { data } = yield API.updatePriceIncreaseBridgePlanB({
      ...payload,
      params: { ...payload.params, projectId },
    });

    yield put(updatePriceIncreaseBridgePlanB.success(data));
  } catch (error) {
    yield put(
      processRequestError({
        error,
        failAction: updatePriceIncreaseBridgePlanB.fail,
      }),
    );
  }
}

function* updatePriceIncreaseBridgePlanBForAccountManagerSaga({
  payload,
}: ActionType<typeof updatePriceIncreaseBridgePlanBForAccountManager.request>) {
  try {
    const { data } = yield API.updatePriceIncreaseBridgePlanBForAccountManager({
      ...payload,
      params: { ...payload.params },
    });

    yield put(updatePriceIncreaseBridgePlanBForAccountManager.success(data));
  } catch (error) {
    yield put(
      processRequestError({
        error,
        failAction: updatePriceIncreaseBridgePlanBForAccountManager.fail,
      }),
    );
  }
}

function* createPriceIncreaseBridgeContractTermSaga({
  payload,
}: ActionType<typeof createPriceIncreaseBridgeContractTerm.request>) {
  const { projectId } = yield select((state: Store) => state.projects);
  try {
    const { data } = yield API.createPriceIncreaseBridgeContractTerm({
      ...payload,
      params: { ...payload.params, projectId },
    });

    yield put(createPriceIncreaseBridgeContractTerm.success(data));
  } catch (error) {
    yield put(
      processRequestError({
        error,
        failAction: createPriceIncreaseBridgeContractTerm.fail,
      }),
    );
  }
}

function* createPriceIncreaseBridgeContractTermForAccountManagerSaga({
  payload,
}: ActionType<typeof createPriceIncreaseBridgeContractTermForAccountManager.request>) {
  try {
    const { data } = yield API.createPriceIncreaseBridgeContractTermForAccountManager({
      ...payload,
      params: { ...payload.params },
    });

    yield put(createPriceIncreaseBridgeContractTermForAccountManager.success(data));
  } catch (error) {
    yield put(
      processRequestError({
        error,
        failAction: createPriceIncreaseBridgeContractTermForAccountManager.fail,
      }),
    );
  }
}

function* createPriceIncreaseBridgePlanBSaga({
  payload,
}: ActionType<typeof createPriceIncreaseBridgePlanB.request>) {
  const { projectId } = yield select((state: Store) => state.projects);
  try {
    const { data } = yield API.createPriceIncreaseBridgePlanB({
      ...payload,
      params: { ...payload.params, projectId },
    });

    yield put(createPriceIncreaseBridgePlanB.success(data));
  } catch (error) {
    yield put(
      processRequestError({
        error,
        failAction: createPriceIncreaseBridgePlanB.fail,
      }),
    );
  }
}

function* createPriceIncreaseBridgePlanBForAccountManagerSaga({
  payload,
}: ActionType<typeof createPriceIncreaseBridgePlanBForAccountManager.request>) {
  try {
    const { data } = yield API.createPriceIncreaseBridgePlanBForAccountManager({
      ...payload,
      params: { ...payload.params },
    });

    yield put(createPriceIncreaseBridgePlanBForAccountManager.success(data));
  } catch (error) {
    yield put(
      processRequestError({
        error,
        failAction: createPriceIncreaseBridgePlanBForAccountManager.fail,
      }),
    );
  }
}

function* deletePriceIncreaseBridgeContractTermSaga({
  payload,
}: ActionType<typeof deletePriceIncreaseBridgeContractTerm.request>) {
  const { projectId } = yield select((state: Store) => state.projects);
  try {
    const { data } = yield API.deletePriceIncreaseBridgeContractTerm({ ...payload, projectId });

    yield put(deletePriceIncreaseBridgeContractTerm.success(data));
  } catch (error) {
    yield put(
      processRequestError({
        error,
        failAction: deletePriceIncreaseBridgeContractTerm.fail,
      }),
    );
  }
}

function* deletePriceIncreaseBridgeContractTermForAccountManagerSaga({
  payload,
}: ActionType<typeof deletePriceIncreaseBridgeContractTermForAccountManager.request>) {
  try {
    const { data } = yield API.deletePriceIncreaseBridgeContractTermForAccountManager(payload);

    yield put(deletePriceIncreaseBridgeContractTermForAccountManager.success(data));
  } catch (error) {
    yield put(
      processRequestError({
        error,
        failAction: deletePriceIncreaseBridgeContractTermForAccountManager.fail,
      }),
    );
  }
}

function* deletePriceIncreaseBridgePlanBSaga({
  payload,
}: ActionType<typeof deletePriceIncreaseBridgePlanB.request>) {
  const { projectId } = yield select((state: Store) => state.projects);
  try {
    const { data } = yield API.deletePriceIncreaseBridgePlanB({ ...payload, projectId });

    yield put(deletePriceIncreaseBridgePlanB.success(data));
  } catch (error) {
    yield put(
      processRequestError({
        error,
        failAction: deletePriceIncreaseBridgePlanB.fail,
      }),
    );
  }
}

function* deletePriceIncreaseBridgePlanBForAccountManagerSaga({
  payload,
}: ActionType<typeof deletePriceIncreaseBridgePlanBForAccountManager.request>) {
  try {
    const { data } = yield API.deletePriceIncreaseBridgePlanBForAccountManager(payload);

    yield put(deletePriceIncreaseBridgePlanBForAccountManager.success(data));
  } catch (error) {
    yield put(
      processRequestError({
        error,
        failAction: deletePriceIncreaseBridgePlanBForAccountManager.fail,
      }),
    );
  }
}

function* getBattleCardsMailStatusesSaga({
  payload,
}: ActionType<typeof getBattleCardsMailStatuses.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.getBattleCardsMailStatuses({ ...payload, projectId });

    yield put(getBattleCardsMailStatuses.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: getBattleCardsMailStatuses.fail }));
  }
}

function* sendBattleCardsMailStatusesToAllSaga({
  payload,
}: ActionType<typeof sendBattleCardsMailStatusesToAll.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  const { battleCardsNumber } = payload;

  try {
    const { data } = yield API.sendBattleCardsMailStatusesToAll({ projectId });

    yield put(
      sendBattleCardsMailStatusesToAll.success({
        isSecondBattleCardsMailStatus: battleCardsNumber === 2,
      }),
    );

    toast.success(data);
  } catch (error) {
    yield put(processRequestError({ error, failAction: sendBattleCardsMailStatusesToAll.fail }));
  }
}

function* sendBattleCardsToAccountManagerSaga({
  payload,
}: ActionType<typeof sendBattleCardsToAccountManager.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  const { accountManagerId, battleCardsNumber } = payload;

  try {
    const { data } = yield API.sendBattleCardsToAccountManager({ accountManagerId, projectId });

    yield put(
      sendBattleCardsToAccountManager.success({
        accountManagerId: accountManagerId,
        isSecondBattleCards: battleCardsNumber === 2,
      }),
    );

    toast.success(data);
  } catch (error) {
    yield put(processRequestError({ error, failAction: sendBattleCardsToAccountManager.fail }));
  }
}

export function* watchBattleCards() {
  yield takeLatest(getBattleCardsCustomersForAdmin.request, getBattleCardsCustomersForAdminSaga);
  yield takeLatest(
    getBattleCardsCustomersForAccountManager.request,
    getBattleCardsCustomersForAccountManagerSaga,
  );
  yield takeLatest(getBattleCard.request, getBattleCardSaga);
  yield takeLatest(getBattleCardForAccountManager.request, getBattleCardForAccountManagerSaga);
  yield takeLatest(updateObjectionHandling.request, updateObjectionHandlingSaga);
  yield takeLatest(
    updateObjectionHandlingForAccountManager.request,
    updateObjectionHandlingForAccountManagerSaga,
  );
  yield takeLatest(createObjectionHandling.request, createObjectionHandlingSaga);
  yield takeLatest(
    createObjectionHandlingForAccountManager.request,
    createObjectionHandlingForAccountManagerSaga,
  );
  yield takeLatest(deleteObjectionHandling.request, deleteObjectionHandlingSaga);
  yield takeLatest(
    deleteObjectionHandlingForAccountManager.request,
    deleteObjectionHandlingForAccountManagerSaga,
  );
  yield takeLatest(updateValueArgumentation.request, updateValueArgumentationSaga);
  yield takeLatest(
    updateValueArgumentationForAccountManager.request,
    updateValueArgumentationForAccountManagerSaga,
  );
  yield takeLatest(createValueArgumentation.request, createValueArgumentationSaga);
  yield takeLatest(
    createValueArgumentationForAccountManager.request,
    createValueArgumentationForAccountManagerSaga,
  );
  yield takeLatest(deleteValueArgumentation.request, deleteValueArgumentationSaga);
  yield takeLatest(
    deleteValueArgumentationForAccountManager.request,
    deleteValueArgumentationForAccountManagerSaga,
  );
  yield takeLatest(createConcessionsMatrix.request, createConcessionsMatrixSaga);
  yield takeLatest(
    createConcessionsMatrixForAccountManager.request,
    createConcessionsMatrixForAccountManagerSaga,
  );
  yield takeLatest(
    updatePriceIncreaseBridgeContractTerm.request,
    updatePriceIncreaseBridgeContractTermSaga,
  );
  yield takeLatest(
    updatePriceIncreaseBridgeContractTermForAccountManager.request,
    updatePriceIncreaseBridgeContractTermForAccountManagerSaga,
  );
  yield takeLatest(updatePriceIncreaseBridgePlanB.request, updatePriceIncreaseBridgePlanBSaga);
  yield takeLatest(
    updatePriceIncreaseBridgePlanBForAccountManager.request,
    updatePriceIncreaseBridgePlanBForAccountManagerSaga,
  );
  yield takeLatest(
    createPriceIncreaseBridgeContractTerm.request,
    createPriceIncreaseBridgeContractTermSaga,
  );
  yield takeLatest(
    createPriceIncreaseBridgeContractTermForAccountManager.request,
    createPriceIncreaseBridgeContractTermForAccountManagerSaga,
  );
  yield takeLatest(createPriceIncreaseBridgePlanB.request, createPriceIncreaseBridgePlanBSaga);
  yield takeLatest(
    createPriceIncreaseBridgePlanBForAccountManager.request,
    createPriceIncreaseBridgePlanBForAccountManagerSaga,
  );
  yield takeLatest(
    deletePriceIncreaseBridgeContractTerm.request,
    deletePriceIncreaseBridgeContractTermSaga,
  );
  yield takeLatest(
    deletePriceIncreaseBridgeContractTermForAccountManager.request,
    deletePriceIncreaseBridgeContractTermForAccountManagerSaga,
  );
  yield takeLatest(deletePriceIncreaseBridgePlanB.request, deletePriceIncreaseBridgePlanBSaga);
  yield takeLatest(
    deletePriceIncreaseBridgePlanBForAccountManager.request,
    deletePriceIncreaseBridgePlanBForAccountManagerSaga,
  );
  yield takeLatest(getBattleCardsMailStatuses.request, getBattleCardsMailStatusesSaga);
  yield takeLatest(sendBattleCardsMailStatusesToAll.request, sendBattleCardsMailStatusesToAllSaga);
  yield takeLatest(sendBattleCardsToAccountManager.request, sendBattleCardsToAccountManagerSaga);
}

import React, { memo, useState } from 'react';
import { PriceIncreaseTimeLevels } from 'src/types';

import useStyles from './useStyles';
import { checkIsNumber } from 'src/helpers/checkIsNumber';

import { ReactComponent as DeleteIcon } from 'src/assets/icons/delete-basket.svg';
import { DeleteButton } from 'src/components/common/DeleteButton';
import { InputBase } from '../../../../../../common/InputBase';

type Props = PriceIncreaseTimeLevels & {
  onChangeScoreCb: (value: string, id: number) => void;
  onChangeLevelCb: (value: string, id: number) => void;
  deletePriceIncreaseTimeLevelOption: (params: { id?: number; temporaryId?: number }) => void;
};

export const PriceIncreaseTimeLevelItem: React.FC<Props> = memo(
  function PriceIncreaseTimeLevelItem({
    id,
    level,
    score,
    onChangeScoreCb,
    onChangeLevelCb,
    temporaryId,
    deletePriceIncreaseTimeLevelOption,
  }) {
    const classes = useStyles();

    const [increaseLevel, setIncreaseLevel] = useState(level);
    const [increaseScore, setIncreaseScore] = useState(String(score));

    const handleChangeLevel = (value: string) => {
      setIncreaseLevel(value);

      if (id) {
        onChangeLevelCb(value, id);
      } else if (temporaryId) {
        onChangeLevelCb(value, temporaryId);
      }
    };

    const handleChangeScore = (value: string) => {
      const isNumber = checkIsNumber(value);

      if (isNumber) {
        setIncreaseScore(value);

        if (id) {
          onChangeScoreCb(value, id);
        } else if (temporaryId) {
          onChangeScoreCb(value, temporaryId);
        }
      }
    };

    return (
      <div className={classes.container}>
        <InputBase
          value={increaseLevel}
          onChangeCb={handleChangeLevel}
          inputStyles={`${classes.leftInput} ${classes.input}`}
        />
        <InputBase
          value={increaseScore}
          onChangeCb={handleChangeScore}
          inputStyles={`${classes.rightInput} ${classes.input}`}
        />
        <DeleteButton
          styles={classes.deleteButton}
          onClick={() => {
            deletePriceIncreaseTimeLevelOption({ id, temporaryId });
          }}>
          <DeleteIcon />
        </DeleteButton>
      </div>
    );
  },
);

import { createContext } from 'react';
import {
  ContractCommitmentOption,
  EaseOfIncreaseOption,
  SurveyResultBusinessUnit,
  SurveyResultCustomerData,
  SurveyResultPlanBOption,
  TouchableOption,
  OtherDropdownAndFreeText,
} from 'src/types';

export type SurveyResultProviderTypes = {
  disableEditPage: boolean;
  surveyResultTouchableOptions: TouchableOption[];
  surveyResultEOIOptions: EaseOfIncreaseOption[];
  surveyResultContractCommitmentOptions: ContractCommitmentOption[];
  surveyResultPlanBOptions: SurveyResultPlanBOption[];
  surveyResultBusinessUnits: SurveyResultBusinessUnit[];
  surveyResultCustomersData: SurveyResultCustomerData[];
  additionalFields?: {
    allowSegmentSpecificInput: boolean;
    showContractExpirationDate: boolean;
    showLastPriceIncreaseDate: boolean;
  };
  surveyResultOtherOptions: OtherDropdownAndFreeText[];
};

export const SurveyResultProvider = createContext<SurveyResultProviderTypes | null>(null);

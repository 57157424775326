import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../../../../../constants/COLORS';

const useStyles = makeStyles(() => ({
  inputWrapper: {
    marginBottom: 21,
    '&:nth-child(3)': {
      marginBottom: 10,
    },
  },
  buttonWrapper: {
    textAlign: 'center',
  },
  input: {
    border: `1px solid ${COLORS.gray26}`,
    padding: '5px 10px 5px 16px',
    borderRadius: 8,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: '24px',
    boxSizing: 'border-box',
    width: '100%',
    outline: 'none',
    fontSize: '12px',
    color: COLORS.black1,
    '&:placeholder': {
      color: COLORS.gray23,
      fontSize: 12,
    },
    '&:hover': {
      borderColor: COLORS.blue2,
      height: '24px',
    },
    '&:focus': {
      height: '24px',
      padding: '6px 10px 5px 16px',
      width: '100%',
    },
    display: 'block',
    verticalAlign: 'top',
  },
  inputTall: {
    height: 51,
    padding: '8px 20px 29px 16px',
    whiteSpace: 'pre-wrap',
    '&:hover': {
      height: 51,
      padding: '8px 20px 29px 16px',
    },
    '&:focus': {
      height: 51,
      padding: '9px 20px 29px 16px',
      whiteSpace: 'pre-wrap',
    },
  },
}));

export default useStyles;

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  monitoringWrapper: {
    paddingBottom: 70,
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: 40,
    gap: 20,
  },
}));

export default useStyles;

import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  casesContainer: {
    display: 'flex',
    overflowX: 'auto',
    columnGap: 60,
    marginTop: 30,
    position: 'relative',
  },
  bottomLine: {
    position: 'absolute',
    left: 101,
    bottom: 94,
    height: 1,
    backgroundColor: COLORS.gray11,
  },
  bottomLineDisabled: {
    backgroundColor: COLORS.gray15,
  },
  loaderContainer: {
    position: 'absolute',
    width: '100%',
    height: '55%',
    zIndex: 1,
  },
  container: {
    position: 'relative',
  },
}));

export default useStyles;

import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  modal: {
    backgroundColor: COLORS.gray2,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
    minHeight: '320px',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    border: 'none',
    outline: 'none',
    borderRadius: 10,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: COLORS.white1,
    padding: '18px',
    fontSize: '14px',
    color: COLORS.black1,
    fontWeight: 700,
  },
  body: {
    padding: '18px',
  },
  input: {
    outline: 'none',
    width: '100%',
    height: 40,
    border: `1px solid ${COLORS.gray16}`,
    fontSize: 14,
    color: COLORS.black2,
    padding: '0 12px',
    '&::placeholder': {
      color: COLORS.gray20,
    },
  },
}));

export default useStyles;

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
  },
  loaderContainer: {
    display: 'flex',
    height: '40vh',
    width: '100%',
    justifyContent: 'center',
    zIndex: 2,
    position: 'absolute',
  },
}));

export default useStyles;

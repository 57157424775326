import React, { useEffect } from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import { useNavigate } from 'react-router-dom';
import useStyles from './useStyles';
import { Typography } from '@mui/material';
import Divider from '@mui/material/Divider';

import ArrowUpIcon from 'src/assets/icons/arrow-up.svg';
import { useSelector } from 'react-redux';
import { Store } from 'src/types';
import { ROLES } from 'src/constants/ROLES';
import { NestedLink } from '../../index';
import { NestedItem } from '../NestedItem';

type Props = {
  name: string;
  isDrawerOpen: boolean;
  icon: string;
  pathName: string;
  url?: string;
  isDropdownOpen?: boolean;
  disabled?: boolean;
  nestedLinks?: NestedLink[];
  role: ROLES;
};

export const NavItem: React.FC<Props> = ({
  pathName,
  isDrawerOpen,
  icon,
  name,
  nestedLinks,
  url,
  disabled,
  isDropdownOpen,
  role,
}) => {
  const [open, setOpen] = React.useState(false);

  const { projectId } = useSelector((state: Store) => state.projects);

  const classes = useStyles();

  const navigate = useNavigate();

  const handleClick = () => {
    if (!disabled) {
      setOpen(!open);
    }
  };

  useEffect(() => {
    if (!isDrawerOpen) {
      setOpen(false);
    }
  }, [isDrawerOpen]);

  useEffect(() => {
    if (isDropdownOpen) {
      setOpen(true);
    }
  }, [isDropdownOpen]);

  useEffect(() => {
    if (!projectId && open && role === ROLES.ROLE_ADMIN) {
      setOpen(false);
    }
  }, [open, projectId, role]);

  if (!nestedLinks) {
    return (
      <ListItemButton
        onClick={() => {
          if (url && !disabled) {
            navigate(url);
          }
        }}
        className={classes.listItemButton}>
        <img alt="home" className={classes.icon} src={icon} />
        <a className={`${classes.link} ${pathName === url && classes.link__selected}`}>{name}</a>
      </ListItemButton>
    );
  }

  return (
    <div className={open ? classes.container__active : undefined}>
      <ListItemButton className={classes.listItemButton} onClick={handleClick}>
        <img alt="menu icon" className={classes.icon} src={icon} />

        <Typography
          component="h3"
          variant="h3"
          className={`${classes.link} ${open && classes.menu__open}`}>
          {name}
        </Typography>
        <img
          className={`${classes.arrowIcon} ${open && classes.arrowIconSelected}`}
          alt="open dropdown menu"
          src={ArrowUpIcon}
        />
      </ListItemButton>
      {open && <Divider className={classes.divider} light />}

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {nestedLinks.map(
            (item, index) =>
              !item.hide && (
                <NestedItem index={index + 1} nestedLink={item} pathName={pathName} key={item.id} />
              ),
          )}
        </List>
      </Collapse>
    </div>
  );
};

import React, { memo, useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';

import useStyles from '../ContractCommitments/useStyles';
import { PlanBOption } from 'src/types';
import { Stack } from '@mui/material';
import { updateSurveysSettings } from 'src/modules/surveys/actions';
import { PlanBItem } from '../PlanBItem';
import { ErrorOption } from '../../index';
import { SurveysProvider } from '../../SurveysProvider';
import { TextButton } from '../../../../../../common/TextButton';

type Props = {
  planBOptions?: PlanBOption[];
  idsWithError?: ErrorOption;
};

export const PlanBOptions: React.FC<Props> = memo(function PlanBOptions({
  planBOptions,
  idsWithError,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { disabled } = useContext(SurveysProvider);

  const onAddNewContractCommitmentOption = useCallback(() => {
    dispatch(
      updateSurveysSettings.request({
        body: {
          planBOptions: [
            {
              option: '',
            },
          ],
        },
        needUpdateState: true,
      }),
    );
  }, [dispatch]);

  const onChangeContractCommitmentOption = useCallback(
    (option, id) => {
      dispatch(
        updateSurveysSettings.request({
          body: {
            planBOptions: [
              {
                id,
                option,
              },
            ],
          },
          needUpdateState: true,
        }),
      );
    },
    [dispatch],
  );

  return (
    <div className={classes.container}>
      {planBOptions &&
        planBOptions.map((planB) => (
          <PlanBItem
            error={idsWithError ? idsWithError.ids.includes(planB.id) : false}
            onChangeValueCb={onChangeContractCommitmentOption}
            key={planB.id}
            {...planB}
          />
        ))}
      {!disabled && (
        <Stack>
          <TextButton onClick={onAddNewContractCommitmentOption} title="Add option" />
        </Stack>
      )}
    </div>
  );
});

//eslint-disable-next-line
export const getMultipleDropdownValues = (value: any, setFn: any) => {
  if (value.includes('All') && value.length === 1) {
    const result = value.filter((el: string) => el === 'All');
    setFn(result);
  } else if (value.includes('All') && value.length > 1 && value[value.length - 1] !== 'All') {
    const result = value.filter((el: string) => el !== 'All');
    setFn(result);
  } else if (value[value.length - 1] === 'All') {
    const result = value.filter((el: string) => el === 'All');
    setFn(result);
  } else {
    setFn(value);
  }
};

import { takeLatest, put } from 'redux-saga/effects';
import { API } from 'src/api';

import {
  getSettingsOverview,
  setProgressCheckboxValue,
  getProgressShowModal,
  setProgressShowModal,
  getProgressCheckboxValue,
  getProgressHomepageCards,
} from './actions';
import { processRequestError } from 'src/modules/Errors/actions';
import { ActionType } from 'deox';
import { select } from 'typed-redux-saga';
import { Store } from 'src/types';

function* getSettingsOverviewSaga() {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.getSettingsOverview({ projectId });

    yield put(getSettingsOverview.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: getSettingsOverview.fail }));
  }
}

function* setProgressCheckboxValueSaga({
  payload,
}: ActionType<typeof setProgressCheckboxValue.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  const { onSuccessCb, body, onFailCb } = payload;

  try {
    yield API.setProgressCheckboxValue({ ...body, projectId });

    yield put(setProgressCheckboxValue.success(body.checkboxValue));

    onSuccessCb && body.checkboxValue && onSuccessCb();
  } catch (error) {
    yield put(processRequestError({ error, failAction: setProgressCheckboxValue.fail }));

    onFailCb && onFailCb();
  }
}

function* getProgressCheckboxValueSaga({
  payload,
}: ActionType<typeof getProgressCheckboxValue.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.getProgressCheckboxValue({ ...payload, projectId });

    yield put(getProgressCheckboxValue.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: getProgressCheckboxValue.fail }));
  }
}

function* getProgressShowModalSaga() {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.getProgressShowModal({ projectId });

    yield put(getProgressShowModal.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: getProgressShowModal.fail }));
  }
}

function* setProgressShowModalSaga({ payload }: ActionType<typeof setProgressShowModal.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    yield API.setProgressShowModal({ ...payload, projectId });

    yield put(setProgressShowModal.success());
  } catch (error) {
    yield put(processRequestError({ error, failAction: setProgressShowModal.fail }));
  }
}

function* getProgressHomepageCardsSaga() {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.getProgressHomepageCards({ projectId });

    yield put(getProgressHomepageCards.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: getProgressHomepageCards.fail }));
  }
}

export function* watchHome() {
  yield takeLatest(getSettingsOverview.request, getSettingsOverviewSaga);
  yield takeLatest(setProgressCheckboxValue.request, setProgressCheckboxValueSaga);
  yield takeLatest(getProgressShowModal.request, getProgressShowModalSaga);
  yield takeLatest(setProgressShowModal.request, setProgressShowModalSaga);
  yield takeLatest(getProgressCheckboxValue.request, getProgressCheckboxValueSaga);
  yield takeLatest(getProgressHomepageCards.request, getProgressHomepageCardsSaga);
}

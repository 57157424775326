import React, { memo, useCallback, useMemo } from 'react';
import { BusinessUnit, UpdateBusinessUnitsTable } from 'src/types';
import { useDispatch } from 'react-redux';

import useStyles from './useStyles';
import { ReactComponent as SortIcon } from 'src/assets/icons/sort.svg';
import { EditableCell } from 'src/components/common/EditableCell';
import { updateBusinessUnitsTable } from 'src/modules/TouchableRevenue/actions';
import { GrowWrapperComponent } from 'src/components/common/GrowWrapperComponent';

type Props = {
  data: BusinessUnit[];
  disableEditing: boolean;
};

export const BusinessUnitsTable: React.FC<Props> = memo(function BusinessUnitsTable({
  data,
  disableEditing,
}) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const onUpdateCell = useCallback(
    (values: UpdateBusinessUnitsTable) => {
      dispatch(updateBusinessUnitsTable.request(values));
    },
    [dispatch],
  );

  const businessUnitsTableHeaders = useMemo(
    () => [
      {
        id: 0,
        title: 'Segment',
      },
      {
        id: 1,
        title: 'Abbreviation',
      },
      {
        id: 2,
        title: 'Revenue',
      },
    ],
    [],
  );

  return (
    <GrowWrapperComponent>
      <div
        style={{ height: data.length > 8 ? 'calc(100vh - 455px)' : 'max-content' }}
        className={classes.wrapper}>
        <table className={classes.table}>
          <thead>
            <tr>
              {businessUnitsTableHeaders.map((item) => (
                <th key={item.id}>
                  <div className={classes.tableHeaderCell}>
                    {item.title}
                    <SortIcon />
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row) => (
              <tr key={row.id}>
                <EditableCell
                  disabled={disableEditing}
                  onEditCb={(value) => {
                    onUpdateCell({
                      id: row.id,
                      name: value.trim(),
                    });
                  }}
                  editable
                  value={row.name}
                />
                <EditableCell
                  disabled={disableEditing}
                  onEditCb={(value) => {
                    onUpdateCell({
                      id: row.id,
                      abbreviation: value.trim(),
                    });
                  }}
                  editable
                  value={row.abbreviation}
                />
                <EditableCell
                  disabled={disableEditing}
                  onlyNumbers={true}
                  onEditCb={(value) => {
                    onUpdateCell({
                      id: row.id,
                      revenue: +value,
                    });
                  }}
                  editable
                  value={row.revenue.toString()}
                  isRevenue={true}
                />
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </GrowWrapperComponent>
  );
});

import React from 'react';
import useStyles from './useStyles';

type Props = {
  onClick: () => void;
  title: string;
};

export const TextButton: React.FC<Props> = ({ onClick, title }) => {
  const classes = useStyles();

  return (
    <button className={classes.button} onClick={onClick}>
      {title}
    </button>
  );
};

import React, { memo } from 'react';
import { PricePotentialMatrixCategory, PricePotentialMatrixIndicator } from 'src/types';
import useStyles from './useStyles';
import { EditableCell } from 'src/components/common/EditableCell';

type Props = {
  categories: PricePotentialMatrixCategory[];
  businessUnitId: number;
  weightScore: number;
  pricePotential: number;
  totalTarget: number;
  updateIndicator: (body: {
    businessUnitId: number;
    indicatorId: number;
    percentage: number;
    categoryId: number;
  }) => void;
  disabled: boolean;
};

export const MatrixSecondPartRow: React.FC<Props> = memo(function MatrixSecondPartRow({
  categories,
  updateIndicator,
  businessUnitId,
  weightScore,
  pricePotential,
  totalTarget,
  disabled,
}) {
  const classes = useStyles();

  const allIndicators = categories.reduce(
    (prev: PricePotentialMatrixIndicator[], value: PricePotentialMatrixCategory) => {
      const indicators = value.indicators.map((indicator) => ({
        ...indicator,
        categoryId: value.categoryId,
      }));

      return [...prev, ...indicators];
    },
    [],
  );

  return (
    <tr className={classes.row}>
      {allIndicators.map((indicator) => (
        <EditableCell
          key={indicator.indicatorId}
          disabled={disabled}
          buttonStyle={classes.numberInput}
          innerInputStyle={classes.numberInput}
          value={indicator.percentage !== null ? indicator.percentage.toString() : '0'}
          onEditCb={(value) => {
            if (indicator.percentage !== +value) {
              updateIndicator({
                businessUnitId,
                indicatorId: indicator.indicatorId,
                percentage: +value,
                categoryId: indicator.categoryId,
              });
            }
          }}
        />
      ))}
      <td className={classes.additionalFields}>
        <div>{weightScore}</div>
      </td>
      <td className={classes.additionalFields}>
        <div>{pricePotential}%</div>
      </td>
      <td className={classes.additionalFields}>
        <div>{totalTarget}&#37;</div>
      </td>
    </tr>
  );
});

import React, { FC, useEffect, useState } from 'react';
import { EditableCell } from '../../../../../../../common/EditableCell';
import { DeleteButton } from '../../../../../../../common/DeleteButton';
import { ReactComponent as DeleteIcon } from 'src/assets/icons/delete-basket.svg';
import useStyles from './useStyles';
import { useDispatch } from 'react-redux';
import {
  deletePriceIncreaseBridgeContractTerm,
  deletePriceIncreaseBridgeContractTermForAccountManager,
  deletePriceIncreaseBridgePlanB,
  deletePriceIncreaseBridgePlanBForAccountManager,
  updatePriceIncreaseBridgeContractTerm,
  updatePriceIncreaseBridgeContractTermForAccountManager,
  updatePriceIncreaseBridgePlanB,
  updatePriceIncreaseBridgePlanBForAccountManager,
} from '../../../../../../../../modules/battleCards/actions';
import { BreachOfContractTermType, PlanBType } from '../../../../../../../../types';

type InputColumnProps = {
  item: PlanBType | BreachOfContractTermType;
  option: string;
  customerId?: string;
  isBattleCards: boolean;
};

const InputColumn: FC<InputColumnProps> = ({ item, option, customerId, isBattleCards }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [itemData, setItemData] = useState<PlanBType | BreachOfContractTermType>(item);

  useEffect(() => {
    if (item.id) {
      setItemData(item);
    }
  }, [item]);

  const handleUpdate = (val: string, field: string) => {
    const value = val.split('-')[0];
    const payload = {
      params: {
        customerId: customerId,
      },
      body: {
        ...item,
        [field]: field === 'value' ? +value : value,
      },
    };
    setItemData({ ...itemData, [field]: value });
    if (option === 'contract-term') {
      if (isBattleCards) {
        dispatch(updatePriceIncreaseBridgeContractTermForAccountManager.request(payload));
      } else {
        dispatch(updatePriceIncreaseBridgeContractTerm.request(payload));
      }
    }
    if (option === 'plan-b') {
      if (isBattleCards) {
        dispatch(updatePriceIncreaseBridgePlanBForAccountManager.request(payload));
      } else {
        dispatch(updatePriceIncreaseBridgePlanB.request(payload));
      }
    }
  };

  const handleDelete = (fieldId: number) => {
    if (option === 'contract-term') {
      if (isBattleCards) {
        dispatch(
          deletePriceIncreaseBridgeContractTermForAccountManager.request({
            customerId: customerId,
            fieldId: fieldId,
          }),
        );
      } else {
        dispatch(
          deletePriceIncreaseBridgeContractTerm.request({
            customerId: customerId,
            fieldId: fieldId,
          }),
        );
      }
    }
    if (option === 'plan-b') {
      if (isBattleCards) {
        dispatch(
          deletePriceIncreaseBridgePlanBForAccountManager.request({
            customerId: customerId,
            fieldId: fieldId,
          }),
        );
      } else {
        dispatch(
          deletePriceIncreaseBridgePlanB.request({ customerId: customerId, fieldId: fieldId }),
        );
      }
    }
  };

  const getPlanBValue = (itemDataValue: any) => {
    if (option === 'plan-b' && +itemDataValue > 0) {
      return `-${+itemDataValue}`;
    }
    return +itemDataValue > 0 && +itemDataValue;
  };

  return (
    <>
      <div className={classes.inputWrapper}>
        <EditableCell
          isInput
          buttonStyle={classes.input}
          innerInputStyle={classes.input}
          value={itemData?.name}
          onEditCb={(value) => handleUpdate(value, 'name')}
          placeholder="Enter text"
          disabled={!itemData?.canDelete}
        />
      </div>
      <div className={classes.inputWrapper}>
        <EditableCell
          isInput
          buttonStyle={classes.input}
          innerInputStyle={classes.input}
          value={getPlanBValue(itemData?.value) as any}
          onEditCb={(value) => handleUpdate(value, 'value')}
          placeholder="Value"
        />
      </div>
      <div className={classes.inputWrapper}>
        <EditableCell
          isInput
          buttonStyle={`${classes.input} ${classes.inputTall}`}
          innerInputStyle={`${classes.input} ${classes.inputTall}`}
          value={itemData?.argumentation}
          onEditCb={(value) => handleUpdate(value, 'argumentation')}
          placeholder="Enter text"
        />
      </div>
      {itemData?.canDelete && (
        <div className={classes.buttonWrapper}>
          <DeleteButton onClick={() => handleDelete(itemData?.id)}>
            <DeleteIcon />
          </DeleteButton>
        </div>
      )}
    </>
  );
};

export default InputColumn;

import React, { memo } from 'react';
import { IndicatorWeighting } from 'src/types';
import { Typography } from '@mui/material';
import useStyles from './useStyles';

import { BarChart, Bar, XAxis, YAxis, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import { COLORS } from 'src/constants/COLORS';
import { EditableCell } from '../../../../../../common/EditableCell';

//eslint-disable-next-line
const CustomTooltip = ({ active, payload, label, styles }: any) => {
  if (active) {
    return (
      <div className={styles}>
        <p>{`${label}`}</p>
        <p>{`Chosen number : ${payload?.[0].value}`}</p>
      </div>
    );
  }

  return null;
};

type Props = {
  indicatorId: number;
  indicatorName: string;
  weighting: number;
  indicatorWeightings: IndicatorWeighting[];
  disableEdit: boolean;
  updateEOIWeight: (indicatorId: number, weighting: number) => void;
};

export const EOIWeight: React.FC<Props> = memo(function Indicator({
  indicatorName,
  weighting,
  indicatorWeightings,
  indicatorId,
  updateEOIWeight,
  disableEdit,
}) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.topContainer}>
        <Typography>{indicatorName}</Typography>

        <EditableCell
          disabled={disableEdit}
          editable
          suffix="%"
          isInput
          onlyNumbers
          buttonStyle={classes.numberInput}
          innerInputStyle={classes.numberInput}
          value={weighting ? weighting.toString() : '0'}
          onEditCb={(value) => {
            if (value) {
              updateEOIWeight(indicatorId, +value);
            }
          }}
        />
      </div>
      <div className={classes.chartContainer}>
        <ResponsiveContainer width="100%" height={280}>
          <BarChart data={indicatorWeightings} margin={{ top: 25, right: 0, left: 0, bottom: 25 }}>
            <XAxis
              padding={{ left: 10, right: 10 }}
              angle={-50}
              dx={-4}
              dy={40}
              height={70}
              minTickGap={-200}
              dataKey="option"
              tickFormatter={(value) => {
                const valueString = value.toString();

                if (valueString.length > 12) {
                  return value.toString().substring(0, 12) + '...';
                }

                return value;
              }}
            />
            <YAxis />
            <Tooltip content={<CustomTooltip styles={classes.tooltipContainer} />} />
            <Bar dataKey="chosenNumber" barSize={40} fontFamily="sans-serif">
              {indicatorWeightings.map((entry) => (
                <Cell key={entry.optionId} fill={COLORS.blue8} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
});

import { createAction } from 'deox';
import {
  CustomerTarget,
  GetBusinessCaseAssumptionsResponse,
  GetCampaignImpactResponse,
  GetCustomerTargetsRequestParams,
  GetCustomerTargetsResponse,
  GetEOIWeighting,
  GetEOIWeightingResponse,
  GetFinalCustomerTargetsResponse,
  GetTargetFineTuningRequestParams,
  GetTargetFineTuningResponse,
  UpdateBusinessCaseAssumptions,
  UpdateCustomerTargetCell,
  UpdateEOIWeightingRequestBody,
  UpdateTargetFineTuningRequestConfig,
  UpdateTargetFineTuningResponse,
} from 'src/types';
import { ResponseError } from '../Errors/actions';

export const getEOIWeighting = {
  request: createAction(
    'specificTargets/GET_EOI_WEIGHTING_REQUEST',
    (resolve) => (payload: GetEOIWeighting) => resolve(payload),
  ),
  success: createAction(
    'specificTargets/GET_EOI_WEIGHTING_SUCCESS',
    (resolve) => (payload: GetEOIWeightingResponse) => resolve(payload),
  ),
  fail: createAction(
    'specificTargets/GET_EOI_WEIGHTING_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const checkWeightingsOfBusinessUnits = {
  request: createAction(
    'specificTargets/CHECK_WEIGHTING_OF_BU_REQUEST',
    (resolve) => (payload: { onSuccessCb: () => void }) => resolve(payload),
  ),
  success: createAction(
    'specificTargets/CHECK_WEIGHTING_OF_BU_SUCCESS',
    (resolve) => () => resolve(),
  ),
  fail: createAction(
    'specificTargets/CHECK_WEIGHTING_OF_BU_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};
export const getBUAbbreviations = {
  request: createAction(
    'specificTargets/GET_BU_ABBREVIATIONS_REQUEST',
    (resolve) => () => resolve(),
  ),
  success: createAction(
    'specificTargets/GET_BU_ABBREVIATIONS_SUCCESS',
    (resolve) => (payload: string[]) => resolve(payload),
  ),
  fail: createAction(
    'specificTargets/GET_BU_ABBREVIATIONS_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const updateEOIWeighting = {
  request: createAction(
    'specificTargets/UPDATE_EOI_WEIGHTING_REQUEST',
    (resolve) => (payload: UpdateEOIWeightingRequestBody) => resolve(payload),
  ),
  success: createAction(
    'specificTargets/UPDATE_WEIGHTING_SUCCESS',
    (resolve) => (payload: UpdateEOIWeightingRequestBody) => resolve(payload),
  ),
  fail: createAction(
    'specificTargets/UPDATE_WEIGHTING_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const getBusinessCaseAssumptions = {
  request: createAction(
    'specificTargets/GET_BUSINESS_CASE_ASSUMPTIONS_REQUEST',
    (resolve) => () => resolve(),
  ),
  success: createAction(
    'specificTargets/GET_BUSINESS_CASE_ASSUMPTIONS_SUCCESS',
    (resolve) => (payload: GetBusinessCaseAssumptionsResponse) => resolve(payload),
  ),
  fail: createAction(
    'specificTargets/GET_BUSINESS_CASE_ASSUMPTIONS_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const updateBusinessCaseAssumption = {
  request: createAction(
    'specificTargets/UPDATE_BUSINESS_CASE_ASSUMPTION_REQUEST',
    (resolve) => (payload: UpdateBusinessCaseAssumptions) => resolve(payload),
  ),
  success: createAction(
    'specificTargets/UPDATE_BUSINESS_CASE_ASSUMPTION_SUCCESS',
    (resolve) => (payload: GetBusinessCaseAssumptionsResponse) => resolve(payload),
  ),
  fail: createAction(
    'specificTargets/UPDATE_BUSINESS_CASE_ASSUMPTION_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const getTargetFineTuning = {
  request: createAction(
    'specificTargets/GET_TARGET_FINE_TUNING_REQUEST',
    (resolve) => (payload: GetTargetFineTuningRequestParams) => resolve(payload),
  ),
  success: createAction(
    'specificTargets/GET_TARGET_FINE_TUNING_SUCCESS',
    (resolve) => (payload: GetTargetFineTuningResponse) => resolve(payload),
  ),
  fail: createAction(
    'specificTargets/GET_TARGET_FINE_TUNING_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const updateTargetFineTuning = {
  request: createAction(
    'specificTargets/UPDATE_TARGET_FINE_TUNING_REQUEST',
    (resolve) => (payload: UpdateTargetFineTuningRequestConfig) => resolve(payload),
  ),
  success: createAction(
    'specificTargets/UPDATE_TARGET_FINE_TUNING_SUCCESS',
    (resolve) => (payload: UpdateTargetFineTuningResponse) => resolve(payload),
  ),
  fail: createAction(
    'specificTargets/UPDATE_TARGET_FINE_TUNING_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const exportCustomerTargetsExcel = {
  request: createAction(
    'specificTargets/EXPORT_CUSTOMER_TARGETS_EXCEL_REQUEST',
    (resolve) => (payload: { file: File; itemsOnPage: number; pageNumber: number }) =>
      resolve(payload),
  ),
  success: createAction(
    'specificTargets/EXPORT_CUSTOMER_TARGETS_EXCEL_SUCCESS',
    (resolve) => () => resolve(),
  ),
  fail: createAction(
    'specificTargets/EXPORT_CUSTOMER_TARGETS_EXCEL_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const downloadCustomerTargetsTemplate = {
  request: createAction(
    'specificTargets/DOWNLOAD_CUSTOMER_TARGETS_EXCEL_REQUEST',
    (resolve) => () => resolve(),
  ),
  success: createAction(
    'specificTargets/DOWNLOAD_CUSTOMER_TARGETS_EXCEL_SUCCESS',
    (resolve) => () => resolve(),
  ),
  fail: createAction(
    'specificTargets/DOWNLOAD_CUSTOMER_TARGETS_EXCEL_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const getCustomerTargets = {
  request: createAction(
    'specificTargets/GET_CUSTOMER_TARGETS_REQUEST',
    (resolve) => (payload: { data: GetCustomerTargetsRequestParams; callback?: () => void }) =>
      resolve(payload),
  ),
  success: createAction(
    'specificTargets/GET_CUSTOMER_TARGETS_SUCCESS',
    (resolve) => (payload: GetCustomerTargetsResponse) => resolve(payload),
  ),
  fail: createAction(
    'specificTargets/GET_CUSTOMER_TARGETS_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const updateCustomerTargetCell = {
  request: createAction(
    'specificTargets/UPDATE_CUSTOMER_TARGET_CELL_REQUEST',
    (resolve) => (payload: UpdateCustomerTargetCell) => resolve(payload),
  ),
  success: createAction(
    'specificTargets/UPDATE_CUSTOMER_TARGET_CELL_SUCCESS',
    (resolve) => (payload: CustomerTarget) => resolve(payload),
  ),
  fail: createAction(
    'specificTargets/UPDATE_CUSTOMER_TARGET_CELL_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const updateCustomerTargetBusinessUnitCell = {
  request: createAction(
    'specificTargets/UPDATE_CUSTOMER_TARGET_BUSINESS_UNIT_CELL_REQUEST',
    (resolve) => (payload: UpdateCustomerTargetCell) => resolve(payload),
  ),
  success: createAction(
    'specificTargets/UPDATE_CUSTOMER_TARGET_BUSINESS_UNIT_CELL_SUCCESS',
    (resolve) => (payload: CustomerTarget) => resolve(payload),
  ),
  fail: createAction(
    'specificTargets/UPDATE_CUSTOMER_TARGET_BUSINESS_UNIT_CELL_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const updateExpandedStatusOfCustomerTargetsTable = {
  request: createAction(
    'specificTargets/UPDATE_CUSTOMER_TARGETS_TABLE_EXPANDED_REQUEST',
    (resolve) => (payload: { expanded: boolean }) => resolve(payload),
  ),
  success: createAction(
    'specificTargets/UPDATE_CUSTOMER_TARGETS_TABLE_EXPANDED_SUCCESS',
    (resolve) => () => resolve(),
  ),
  fail: createAction(
    'specificTargets/UPDATE_CUSTOMER_TARGETS_TABLE_EXPANDED_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const getCustomerTargetsExpandedStatus = {
  request: createAction(
    'specificTargets/GET_CUSTOMER_TARGETS_EXPANDED_STATUS_REQUEST',
    (resolve) => () => resolve(),
  ),
  success: createAction(
    'specificTargets/GET_CUSTOMER_TARGETS_EXPANDED_STATUS_SUCCESS',
    (resolve) => (payload: boolean) => resolve(payload),
  ),
  fail: createAction(
    'specificTargets/GET_CUSTOMER_TARGETS_EXPANDED_STATUS_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const getCampaignImpact = {
  request: createAction(
    'specificTargets/GET_CAMPAIGN_IMPACT_REQUEST',
    (resolve) => () => resolve(),
  ),
  success: createAction(
    'specificTargets/GET_CAMPAIGN_IMPACT_SUCCESS',
    (resolve) => (payload: GetCampaignImpactResponse) => resolve(payload),
  ),
  fail: createAction(
    'specificTargets/GET_CAMPAIGN_IMPACT_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const downloadFinalCustomerTargetsExcel = {
  request: createAction(
    'specificTargets/DOWNLOAD_FINAL_CUSTOMER_TARGETS_REQUEST',
    (resolve) => () => resolve(),
  ),
  success: createAction(
    'specificTargets/DOWNLOAD_FINAL_CUSTOMER_TARGETS_SUCCESS',
    (resolve) => () => resolve(),
  ),
  fail: createAction(
    'specificTargets/DOWNLOAD_FINAL_CUSTOMER_TARGETS_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const getFinalCustomerTargets = {
  request: createAction(
    'specificTargets/GET_FINAL_CUSTOMER_TARGETS_REQUEST',
    (resolve) => () => resolve(),
  ),
  success: createAction(
    'specificTargets/GET_FINAL_CUSTOMER_TARGETS_SUCCESS',
    (resolve) => (payload: GetFinalCustomerTargetsResponse) => resolve(payload),
  ),
  fail: createAction(
    'specificTargets/GET_FINAL_CUSTOMER_TARGETS_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  container: {
    width: '40%',
    maxHeight: 355,
    minHeight: 355,
    padding: '24px 15px 12px',
    backgroundColor: COLORS.white1,
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    borderRadius: 8,
  },
  inputContainer: {
    display: 'grid',
    width: '340px',
    gridTemplateColumns: '1.5fr 2.7fr',
    alignItems: 'center',
    marginBottom: '20px',
  },
  label: {
    fontSize: '14px',
    color: COLORS.black1,
  },
  list: {
    overflowY: 'auto',
    maxHeight: 255,
  },
  title: {
    fontWeight: 700,
    fontSize: 16,
    paddingLeft: 155,
    marginBottom: 24,
  },
  numberInput: {
    border: `1px solid ${COLORS.gray26}`,
    borderRadius: 8,
    height: '40px',
    width: '200px',
    outline: 'none',
    padding: '0 16px',
    fontSize: 16,
    color: COLORS.black1,
    '&::placeholder': {
      color: COLORS.gray22,
    },
    '&:placeholder': {
      color: COLORS.gray25,
    },
    '&:hover': {
      borderColor: COLORS.blue2,
    },
    '&:focus': {
      borderColor: COLORS.blue11,
    },
  },
  input: {
    fontSize: 16,
  },
}));

export default useStyles;

import { createAction } from 'deox';
import {
  GetProgressCheckboxValueRequestParams,
  GetProgressHomePageCardsResponse,
  SetProgressCheckboxValueRequestBody,
  SetProgressShowModalRequestBody,
  SettingOverviewResponse,
} from 'src/types';
import { ResponseError } from 'src/modules/Errors/actions';

export const getSettingsOverview = {
  request: createAction('home/GET_SETTINGS_OVERVIEW_REQUEST'),
  success: createAction(
    'home/GET_SETTINGS_OVERVIEW_SUCCESS',
    (resolve) => (payload: SettingOverviewResponse) => resolve(payload),
  ),
  fail: createAction(
    'home/GET_SETTING_OVERVIEW_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const setProgressCheckboxValue = {
  request: createAction(
    'home/SET_PROGRESS_CHECKBOX_VALUE_REQUEST',
    (resolve) =>
      (payload: {
        body: SetProgressCheckboxValueRequestBody;
        onSuccessCb?: () => void;
        onFailCb?: () => void;
      }) =>
        resolve(payload),
  ),
  success: createAction(
    'home/SET_PROGRESS_CHECKBOX_VALUE_SUCCESS',
    (resolve) => (payload: boolean) => resolve(payload),
  ),
  fail: createAction(
    'home/SET_PROGRESS_CHECKBOX_VALUE_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const getProgressCheckboxValue = {
  request: createAction(
    'home/GET_PROGRESS_CHECKBOX_VALUE_REQUEST',
    (resolve) => (payload: GetProgressCheckboxValueRequestParams) => resolve(payload),
  ),
  success: createAction(
    'home/GET_PROGRESS_CHECKBOX_VALUE_SUCCESS',
    (resolve) => (payload: boolean) => resolve(payload),
  ),
  fail: createAction(
    'home/GET_PROGRESS_CHECKBOX_VALUE_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const getProgressShowModal = {
  request: createAction('home/GET_PROGRESS_SHOW_MODAL_REQUEST', (resolve) => () => resolve()),
  success: createAction(
    'home/GET_PROGRESS_SHOW_MODAL_SUCCESS',
    (resolve) => (payload: boolean) => resolve(payload),
  ),
  fail: createAction(
    'home/GET_PROGRESS_SHOW_MODAL_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const setProgressShowModal = {
  request: createAction(
    'home/SET_PROGRESS_SHOW_MODAL_REQUEST',
    (resolve) => (payload: SetProgressShowModalRequestBody) => resolve(payload),
  ),
  success: createAction('home/SET_PROGRESS_SHOW_MODAL_SUCCESS', (resolve) => () => resolve()),
  fail: createAction(
    'home/SET_PROGRESS_SHOW_MODAL_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const getProgressHomepageCards = {
  request: createAction('home/GET_PROGRESS_HOME_PAGE_CARDS_REQUEST', (resolve) => () => resolve()),
  success: createAction(
    'home/GET_PROGRESS_HOME_PAGE_CARDS_SUCCESS',
    (resolve) => (payload: GetProgressHomePageCardsResponse) => resolve(payload),
  ),
  fail: createAction(
    'home/GET_PROGRESS_HOME_PAGE_CARDS_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    backgroundColor: COLORS.white1,
    height: 60,
    padding: '0 20px',
    columnGap: '5%',
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default useStyles;

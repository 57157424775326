import React, { FC } from 'react';
import { GrowWrapperComponent } from '../../../../../common/GrowWrapperComponent';
import useStyles from './useStyles';
import TRow from './TRow';
import { TextButton } from '../../../../../common/TextButton';
import { useDispatch } from 'react-redux';
import {
  createValueArgumentation,
  createValueArgumentationForAccountManager,
  deleteValueArgumentation,
  deleteValueArgumentationForAccountManager,
  updateValueArgumentation,
  updateValueArgumentationForAccountManager,
} from '../../../../../../modules/battleCards/actions';
import { ValueArgumentationItem } from '../../../../../../types';

type ObjectionHandlingProps = {
  valueArgumentation?: ValueArgumentationItem[];
  customerId?: string;
  isBattleCards?: boolean;
};

const MAX_NUMBER_FIELDS = 6;

const ValueArgumentation: FC<ObjectionHandlingProps> = ({
  valueArgumentation,
  customerId,
  isBattleCards,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const handleUpdate = (obj: ValueArgumentationItem) => {
    const payload = {
      params: {
        customerId: customerId,
      },
      body: {
        id: obj?.id,
        valueArgumentation: obj?.valueArgumentation,
        evidenceOrBenefit: obj?.evidenceOrBenefit,
      },
    };
    if (isBattleCards) {
      dispatch(updateValueArgumentationForAccountManager.request(payload));
    } else {
      dispatch(updateValueArgumentation.request(payload));
    }
  };

  const handleAddField = () => {
    const payload = {
      params: {
        customerId: customerId,
      },
      body: {
        id: null,
        valueArgumentation: '',
        evidenceOrBenefit: '',
      },
    };
    if (isBattleCards) {
      dispatch(createValueArgumentationForAccountManager.request(payload));
    } else {
      dispatch(createValueArgumentation.request(payload));
    }
  };

  const handleDelete = (id: number) => {
    const params = {
      customerId: customerId,
      fieldId: id,
    };
    if (isBattleCards) {
      dispatch(deleteValueArgumentationForAccountManager.request(params));
    } else {
      dispatch(deleteValueArgumentation.request(params));
    }
  };

  return (
    <GrowWrapperComponent>
      <div className={classes.wrapper}>
        <table className={classes.table}>
          <thead>
            <tr>
              <th>Value argumentation</th>
              <th>Evidence/benefit</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {valueArgumentation?.map((item: ValueArgumentationItem) => (
              <TRow
                key={item.id}
                item={item}
                onUpdate={handleUpdate}
                onDelete={handleDelete}
                valueArgumentationLength={valueArgumentation.length}
              />
            ))}
          </tbody>
          <tfoot>
            {valueArgumentation && valueArgumentation!.length < MAX_NUMBER_FIELDS && (
              <div className={classes.buttonWrapper}>
                <TextButton title="Add fields" onClick={handleAddField} />
              </div>
            )}
          </tfoot>
        </table>
      </div>
    </GrowWrapperComponent>
  );
};

export default ValueArgumentation;

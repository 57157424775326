import React, { memo, useEffect, useState } from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import useStyles from './useStyles';
import { toast } from 'react-toastify';
import { useDebouncedCallback } from 'use-debounce';

import { ReactComponent as InfoIcon } from 'src/assets/icons/info.svg';

type Props = {
  minScore: number | null;
  maxScore: number | null;
  minRange: number | null;
  maxRange: number | null;
  updatePricePotentialAdditionalFields: (body: {
    minScore?: number;
    maxScore?: number;
    minRange?: number;
    maxRange?: number;
  }) => void;
  disabled: boolean;
};

export const AdditionalFieldsBox: React.FC<Props> = memo(function AdditionalFieldsBox({
  minScore,
  maxScore,
  minRange,
  maxRange,
  updatePricePotentialAdditionalFields,
  disabled,
}) {
  const classes = useStyles();

  const [scoreMin, setScoreMin] = useState({
    value: minScore ? minScore.toString() : 0,
    error: false,
  });
  const [scoreMax, setScoreMax] = useState({
    value: maxScore ? maxScore.toString() : 0,
    error: false,
  });

  const [rangeMin, setRangeMin] = useState({
    value: minRange ? minRange.toString() : 0,
    error: false,
  });
  const [rangeMax, setRangeMax] = useState({
    value: maxRange ? maxRange.toString() : 0,
    error: false,
  });

  const validateMin = (value: number, secondValue: number) => {
    if (value > 0 && value < secondValue) {
      return false;
    }

    return true;
  };

  const validateMax = (value: number, secondValue: number) => {
    if (value > 0 && value > secondValue) {
      return false;
    }

    return true;
  };

  const handleChangeScoreMin = useDebouncedCallback((values: NumberFormatValues) => {
    const { value } = values;

    const isInvalid = validateMin(+value, Number(scoreMax.value));

    setScoreMin(() => ({
      error: isInvalid,
      value,
    }));

    if (!isInvalid) {
      updatePricePotentialAdditionalFields({
        minScore: +value,
      });
    }
  }, 600);

  const handleChangeScoreMax = useDebouncedCallback((values: NumberFormatValues) => {
    const { value } = values;

    const isInvalid = validateMax(+value, Number(scoreMin.value));

    setScoreMax(() => ({
      error: isInvalid,
      value,
    }));

    if (!isInvalid) {
      updatePricePotentialAdditionalFields({
        maxScore: +value,
      });
    }
  }, 600);

  const handleChangeRangeMin = useDebouncedCallback((values: NumberFormatValues) => {
    const { value } = values;

    const isInvalid = validateMin(+value, Number(rangeMax.value));

    setRangeMin(() => ({
      error: isInvalid,
      value,
    }));

    if (!isInvalid) {
      updatePricePotentialAdditionalFields({
        minRange: +value,
      });
    }
  }, 600);

  const handleChangeRangeMax = useDebouncedCallback((values: NumberFormatValues) => {
    const { value } = values;

    const isInvalid = validateMax(+value, Number(rangeMin.value));

    setRangeMax(() => ({
      error: isInvalid,
      value,
    }));

    if (!isInvalid) {
      updatePricePotentialAdditionalFields({
        maxRange: +value,
      });
    }
  }, 600);

  useEffect(() => {
    if (scoreMin.error) {
      const isInvalid = validateMin(+scoreMin.value, +scoreMax.value);

      if (!isInvalid) {
        setScoreMin((prev) => ({
          ...prev,
          error: false,
        }));
      }
    }
  }, [scoreMax, scoreMin]);

  useEffect(() => {
    if (rangeMin.error) {
      const isInvalid = validateMin(+rangeMin.value, +rangeMax.value);

      if (!isInvalid) {
        setRangeMin((prev) => ({
          ...prev,
          error: false,
        }));
      }
    }
  }, [rangeMin, rangeMax]);

  useEffect(() => {
    if (scoreMax.error) {
      const isInvalid = validateMax(+scoreMax.value, +scoreMin.value);

      if (!isInvalid) {
        setScoreMax((prev) => ({
          ...prev,
          error: false,
        }));
      }
    }
  }, [scoreMax, scoreMin]);

  useEffect(() => {
    if (rangeMax.error) {
      const isInvalid = validateMax(+rangeMax.value, +rangeMin.value);

      if (!isInvalid) {
        setRangeMax((prev) => ({
          ...prev,
          error: false,
        }));
      }
    }
  }, [rangeMax, rangeMin]);

  useEffect(() => {
    if (rangeMin.error) {
      toast.error('Value Min should be more than 0 and less than Max');
    }
  }, [rangeMin]);

  useEffect(() => {
    if (rangeMax.error) {
      if (rangeMax.value > 100) {
        toast.error('Value Max should not be more than 100%.');
      } else {
        toast.error('Value Max should be more than value Min.');
      }
    }
  }, [rangeMax]);

  useEffect(() => {
    if (scoreMin.error) {
      toast.error('Value Min should be more than 0 and less than Max');
    }
  }, [scoreMin]);

  useEffect(() => {
    if (scoreMax.error) {
      toast.error('Range Max should be more than value Min.');
    }
  }, [scoreMax]);

  return (
    <div className={classes.additionalInputsContainer}>
      <div className={classes.additionalFieldsHeader}>
        <h3 className={classes.title}>Output settings</h3>
        <InfoIcon />
      </div>
      <div className={classes.additionalFieldsRow}>
        <span className={classes.subtitle}>Scores</span>
        <NumberFormat
          disabled={disabled || scoreMax.value === 0}
          type="text"
          isAllowed={(values: NumberFormatValues) => {
            return values.value.slice(-1) !== '.';
          }}
          allowEmptyFormatting={true}
          allowNegative={false}
          name="score-min"
          onValueChange={handleChangeScoreMin}
          className={`${classes.additionalFieldsInput} ${scoreMin.error && classes.fieldError}`}
          required
          defaultValue={minScore ? scoreMin.value : ''}
          placeholder="Min"
        />
        <NumberFormat
          disabled={disabled}
          isAllowed={(values: NumberFormatValues) => {
            return values.value.slice(-1) !== '.';
          }}
          name="score-max"
          allowNegative={false}
          type="text"
          onValueChange={handleChangeScoreMax}
          className={`${classes.additionalFieldsInput} ${scoreMax.error && classes.fieldError}`}
          required
          defaultValue={maxScore ? scoreMax.value : ''}
          placeholder="Max"
        />
      </div>
      <div className={classes.additionalFieldsRow}>
        <span className={classes.subtitle}>Range</span>
        <NumberFormat
          disabled={disabled || rangeMax.value === 0}
          isAllowed={(values: NumberFormatValues) => {
            return values.value.slice(-1) !== '.';
          }}
          type="text"
          allowNegative={false}
          onValueChange={handleChangeRangeMin}
          suffix="%"
          className={`${classes.additionalFieldsInput} ${rangeMin.error && classes.fieldError}`}
          required
          defaultValue={minRange ? rangeMin.value : ''}
          placeholder="Min"
        />
        <NumberFormat
          disabled={disabled}
          isAllowed={(values: NumberFormatValues) => {
            return values.value.slice(-1) !== '.';
          }}
          suffix="%"
          type="text"
          allowNegative={false}
          onValueChange={handleChangeRangeMax}
          className={`${classes.additionalFieldsInput} ${rangeMax.error && classes.fieldError}`}
          required
          defaultValue={maxRange ? rangeMax.value : ''}
          placeholder="Max"
        />
      </div>
    </div>
  );
});

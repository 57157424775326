import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../constants/COLORS';

const useStyles = makeStyles(() => ({
  button: {
    display: 'flex',
    fontWeight: 700,
    color: COLORS.red1,
    outline: 'none',
    border: 'none',
    backgroundColor: 'transparent',
    fontSize: 16,
    cursor: 'pointer',
    '&:hover': {
      color: COLORS.red5,
    },
    '&:active': {
      color: COLORS.pink5,
    },
  },
}));

export default useStyles;

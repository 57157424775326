import React, { useEffect, useState } from 'react';
import Grow from '@mui/material/Grow';

export const GrowWrapperComponent: React.FC = ({ children }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <Grow in={show} style={{ transformOrigin: '0 0 0' }} {...(show ? { timeout: 500 } : {})}>
      <div>{children}</div>
    </Grow>
  );
};

import React, { useCallback, useEffect } from 'react';
import { MainHeader } from 'src/components/Views/MainHeader';
import { ButtonBase } from 'src/components/common/ButtonBase';
import useStyles from './useStyles';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { login as loginAction, saveManagerLoginLink } from 'src/modules/app/actions';

import { Store } from 'src/types';
import { LoginInput } from 'src/components/common/LoginInput';
import { useController, useForm } from 'react-hook-form';
import { LoginFormValidation } from 'src/helpers/validation';
import { Checkbox } from '../../common/Checkbox';
import { saveSurveyCompletingCustomersDate } from '../../../modules/surveys/actions';

export type LoginFields = {
  email: string;
  password: string;
  rememberMe: boolean;
};

export const Login: React.FC = () => {
  const classes = useStyles();

  const { loading } = useSelector((state: Store) => state.app);

  const { control, handleSubmit, formState } = useForm<LoginFields>({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
      rememberMe: false,
    },
    resolver: yupResolver(LoginFormValidation),
  });

  const tenantIdBase64 = new URLSearchParams(window.location.search).get('i');
  const timeBase64 = new URLSearchParams(window.location.search).get('?t');

  const decodeBase64 = (str: string | null) => {
    if (str) {
      return window.atob(str);
    }

    return null;
  };

  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (data: { email: string; password: string; rememberMe: boolean }) => {
      dispatch(
        loginAction.request({
          email: data.email.split(' ').join(''),
          password: data.password.split(' ').join(''),
          rememberMe: data.rememberMe,
          tenantId: decodeBase64(tenantIdBase64),
        }),
      );
    },
    [dispatch, tenantIdBase64],
  );

  const {
    field: { value, onChange },
  } = useController({
    name: 'rememberMe',
    control,
  });

  useEffect(() => {
    const date = decodeBase64(timeBase64);

    if (date) {
      dispatch(saveSurveyCompletingCustomersDate(date));
    }
  }, [dispatch, timeBase64]);

  useEffect(() => {
    dispatch(saveManagerLoginLink(window.location.href));
  }, [dispatch]);

  return (
    <section className={classes.container}>
      <MainHeader />
      <main className={classes.main}>
        <h1 className={classes.title}>Login</h1>
        <h2 className={classes.subtitle}>Hey, Enter your details to get sign in to your account</h2>

        <div className={classes.inputsContainer}>
          <LoginInput placeholder="email" name="email" control={control} type="email" />
          <LoginInput placeholder="password" name="password" control={control} type="password" />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: 260,
            marginTop: 10,
          }}>
          <p>Remember me</p>
          <Checkbox
            isChecked={value}
            onChangeCb={(value) => {
              onChange(value);
            }}
          />
        </div>

        <ButtonBase
          disabled={!formState.isValid}
          onClick={handleSubmit(onSubmit)}
          isLoading={loading}
          containerStyle={classes.button}
          padding="0 30px"
          variant="contained"
          title="Login"
        />
      </main>
    </section>
  );
};

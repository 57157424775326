import React, { memo } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import useStyles from './useStyles';

import dayjs from 'dayjs';

type Props = {
  onChangeCb: (date: string) => void;
  defaultDate: string | null;
  disabled?: boolean;
  inputStyle?: string;
  label?: string;
};

export const DateTimePicker: React.FC<Props> = memo(function DateTimePicker({
  onChangeCb,
  defaultDate,
  disabled,
  inputStyle,
  label,
}) {
  const [value, setValue] = React.useState<Date | null | string>(
    defaultDate ? new Date(defaultDate) : '',
  );

  const classes = useStyles();

  const handleChange = (newValue: Date | null) => {
    setValue(newValue);

    if (dayjs(newValue).isValid()) {
      onChangeCb(dayjs(newValue).format());
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        disabled={disabled}
        inputFormat="DD/MM/YYYY"
        value={value}
        onChange={handleChange}
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <div className={classes.container}>
            <div className={classes.label}>{label}</div>
            <div className={`${classes.inputContainer} ${inputStyle && inputStyle}`}>
              <input
                className={`${classes.input} ${disabled && classes.disabled}`}
                ref={inputRef}
                {...inputProps}
              />
              {InputProps?.endAdornment}
            </div>
          </div>
        )}
      />
    </LocalizationProvider>
  );
});

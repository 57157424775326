import React, { useCallback, useMemo } from 'react';
import { Step } from 'src/types';
import { Stack } from '@mui/material';
import { StepNames } from 'src/constants/stepNames';

import { ReactComponent as SettingIcon } from 'src/assets/icons/settings-step.svg';
import useStyles from './useStyles';
import { COLORS } from 'src/constants/COLORS';
import { useLocation } from 'react-router-dom';

type Props = {
  steps: Step | Step[];
  type: StepNames;
};

export const Stepper: React.FC<Props> = ({ type, steps }) => {
  const classes = useStyles();
  const location = useLocation();

  const whereWeAre = useMemo(() => {
    const locationArray = location.pathname.split('/');

    // return the name of location where we are
    switch (locationArray[2]) {
      case 'campaign-target':
        return 'Campaign target';
      case 'touchable-revenue':
        return 'Touchable revenue';
      case 'customer-specific-targets':
        return 'Customer-specific targets';
    }
  }, [location.pathname]);

  const getColorOfCircle = useCallback((currentIndex: number, activeIndex: number) => {
    if (currentIndex < activeIndex) {
      return COLORS.gray25;
    }

    if (currentIndex === activeIndex) {
      return COLORS.red1;
    }

    return COLORS.white1;
  }, []);

  const getNumberOfCircles = useCallback((menuName: string) => {
    if (menuName === 'Touchable revenue') {
      return Array.from(Array(4).keys());
    }

    if (menuName === 'Campaign target') {
      return Array.from(Array(2).keys());
    }

    if (menuName === 'Customer-specific targets') {
      return Array.from(Array(6).keys());
    }

    return [];
  }, []);

  const renderSteps = useMemo(() => {
    let node;
    if (type === StepNames.SETUP && !Array.isArray(steps)) {
      node = (
        <div className={classes.container}>
          <SettingIcon className={classes.icon} />
          <div className={classes.innerContainer}>
            <h2 className={`${classes.title} `}>{steps.name}</h2>
            <Stack direction="row" spacing="16px" marginTop="4px">
              {[0, 1, 2].map((item) => (
                <div
                  key={item}
                  style={{
                    backgroundColor:
                      steps.activeIndex !== null ? getColorOfCircle(item, steps.activeIndex) : '',
                  }}
                  className={`${classes.circle} ${
                    item !== steps.activeIndex && classes.circleWithBorder
                  }`}
                />
              ))}
            </Stack>
          </div>
          <div className={classes.bottomLine} />
        </div>
      );
    }

    if (type === StepNames.ALL && Array.isArray(steps)) {
      node = steps.map((step, index) => (
        <div key={step.name} className={classes.container}>
          <div className={classes.numberRound}>{index + 1}</div>
          <div className={classes.innerContainer}>
            <h2 className={`${classes.title} ${whereWeAre === step.name && classes.titleSelected}`}>
              {step.name}
            </h2>
            <Stack direction="row" spacing="16px" marginTop="4px">
              {getNumberOfCircles(step.name).map((item) => (
                <div
                  key={item}
                  style={{
                    backgroundColor:
                      step.activeIndex !== null
                        ? getColorOfCircle(item, step.activeIndex)
                        : getColorOfCircle(step.allCompleted ? -1 : 10, 0),
                  }}
                  className={`${classes.circle} ${
                    item !== step.activeIndex && classes.circleWithBorder
                  }`}
                />
              ))}
            </Stack>
          </div>
          {whereWeAre === step.name && <div className={classes.bottomLine} />}
        </div>
      ));
    }

    return node;
  }, [
    classes.bottomLine,
    classes.circle,
    classes.circleWithBorder,
    classes.container,
    classes.icon,
    classes.innerContainer,
    classes.numberRound,
    classes.title,
    getColorOfCircle,
    getNumberOfCircles,
    steps,
    type,
    whereWeAre,
  ]);

  return (
    <Stack position="relative" direction="row" marginTop="24px" marginBottom="34px">
      {renderSteps}
      <div className={classes.bottomGrayLine} />
    </Stack>
  );
};

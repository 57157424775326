import { put, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { processRequestError } from './actions';
import customHistory from '../../helpers/history';
import { setCurrentProject } from '../projects/actions';

function* processRequestErrorSaga({
  payload: { error, failAction, fieldId, fieldName },
}: ReturnType<typeof processRequestError>) {
  const errorMessage = error?.response?.data?.message || error.message;
  if (error?.message !== 'canceled') {
    toast.error(errorMessage);
  }

  if (error?.response?.data?.message === 'CANNOT_FIND_PROJECT_BY_ID') {
    yield put(setCurrentProject({ id: 0, name: '' }));
    customHistory.push('/projects');
  }

  yield put(failAction({ message: errorMessage, name: fieldName, id: fieldId }));
}

export function* watchErrors() {
  yield takeEvery(processRequestError, processRequestErrorSaga);
}

import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  table: {
    width: '100%',
    borderSpacing: '0 20px',
    '& tr': {
      boxShadow: '0px 2px 10px rgba(199, 201, 201, 0.5)',
      borderRadius: 8,
    },
    '& tbody tr:nth-child(even) td': {
      backgroundColor: COLORS.blue13,
    },
    '& td, th': {
      height: 50,
      fontSize: 16,
      textAlign: 'left',
      paddingLeft: 15,
      '&:first-child': {
        borderBottomLeftRadius: 8,
        borderTopLeftRadius: 8,
        overflow: 'hidden',
      },
      '&:last-child': {
        borderBottomRightRadius: 8,
        borderTopRightRadius: 8,
        overflow: 'hidden',
      },
    },
    '& td:first-child': {
      fontWeight: 700,
    },
    '& th': {
      backgroundColor: COLORS.blue12,
      color: COLORS.black4,
    },
    '& td': {
      backgroundColor: COLORS.white1,
      color: COLORS.black1,
    },
  },
  button: {
    cursor: 'pointer',
  },
}));

export default useStyles;

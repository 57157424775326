import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../../constants/COLORS';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    columnGap: 16,
  },
  title: {
    fontSize: 16,
    fontWeight: 700,
    color: COLORS.black1,
    marginBottom: 16,
  },
}));

export default useStyles;

import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  additionalInputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 10,
    padding: '16px 24px 0px',
    color: COLORS.black1,
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    borderRadius: 8,
    height: 160,
  },
  additionalFieldsHeader: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 8,
    marginBottom: 12,
  },
  subtitle: {
    fontSize: 16,
    color: COLORS.black1,
  },
  title: {
    fontWeight: 700,
    color: COLORS.black1,
    fontSize: 16,
  },
  additionalFieldsRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: 10,
  },
  additionalFieldsInput: {
    width: 73,
    height: 32,
    border: `1px solid ${COLORS.gray26}`,
    outline: 'none',
    fontSize: 16,
    borderRadius: 8,
    fontFamily: 'inherit',
    textAlign: 'center',
    '&:disabled': {
      backgroundColor: COLORS.gray2,
      color: COLORS.gray3,
      borderColor: COLORS.gray15,
    },
    '&::placeholder': {
      fontSize: 16,
      color: COLORS.gray25,
    },
  },
  fieldError: {
    borderColor: COLORS.red1,
  },
}));

export default useStyles;

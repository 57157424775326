import React, { memo, useMemo } from 'react';
import { COLORS } from 'src/constants/COLORS';

import useStyles from './useStyles';
import { ProjectCreationStatus, SurveyStatuses, BattleCardsStatuses } from 'src/constants/STATUSES';

type Props = {
  status: 'not filled' | 'not sent' | 'filled' | string | null;
};

export const ColoredRound: React.FC<Props> = memo(function ColoredRound({ status }) {
  const classes = useStyles();

  const colorByStatus = useMemo(() => {
    switch (status) {
      case SurveyStatuses.SURVEY_SENT:
        return COLORS.yellow;
      case SurveyStatuses.NOT_FILLED:
        return COLORS.red2;
      case SurveyStatuses.SURVEY_COMPLETED:
        return COLORS.green4;
      case SurveyStatuses.PENDING:
        return COLORS.red2;
      case SurveyStatuses.NOT_SENT:
        return COLORS.red2;
      case SurveyStatuses.FILLED:
        return COLORS.green4;
      case SurveyStatuses.SENT_BUT_NOT_FILLED:
        return COLORS.yellow;
      case ProjectCreationStatus.CREATED:
        return COLORS.green4;
      case ProjectCreationStatus.IN_PROGRESS:
        return COLORS.red2;
      case BattleCardsStatuses.SENT:
        return COLORS.green4;
      case BattleCardsStatuses.NOT_SENT:
        return COLORS.red2;
    }
  }, [status]);

  return <div className={classes.container} style={{ backgroundColor: colorByStatus }} />;
});

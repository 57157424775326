import React, { memo, useMemo } from 'react';
import { CustomerTarget } from 'src/types';
import useStyles from './useStyles';

import { ReactComponent as PlusIcon } from 'src/assets/icons/plus-circle.svg';
import { IconButton } from '@mui/material';
import { CustomerTargetsRow } from '../CustomerTargetsRow';
import { GrowWrapperComponent } from '../../GrowWrapperComponent';

type Props = {
  customerTargets: CustomerTarget[];
  businessUnitAbbreviations: string[];
  buVisible: boolean;
  toggleBuVisible: () => void;
  disableEditable?: boolean;
  showRealizedPrice?: boolean;
  isRealizedPriceDisabled?: boolean;
  page?: number;
  rowPerPage?: number;
};

export const CustomerTargetsTable: React.FC<Props> = memo(function CustomerTargetsTable({
  customerTargets,
  buVisible,
  businessUnitAbbreviations,
  toggleBuVisible,
  disableEditable,
  showRealizedPrice,
  isRealizedPriceDisabled,
  page,
  rowPerPage,
}) {
  const classes = useStyles();

  const CalculatedPriceHeader = useMemo(
    () => (
      <>
        <th className={classes.calculatedPriceCells}>
          <div>Going-in price increase</div>
        </th>
        <th className={classes.calculatedPriceCells}>
          <div>Target price increase</div>
        </th>
        <th className={classes.calculatedPriceCells}>
          <div>Escalation price increase</div>
        </th>
        <th className={classes.calculatedPriceCells}>
          <div>Walk-Away Price Increase</div>
        </th>
        {showRealizedPrice && buVisible && (
          <th className={classes.calculatedPriceCells}>
            <div>Realized Price</div>
          </th>
        )}
      </>
    ),
    [buVisible, classes.calculatedPriceCells, showRealizedPrice],
  );

  const CalculatedPriceIncreaseTarget = useMemo(() => {
    return buVisible ? (
      businessUnitAbbreviations.map((abbr) => (
        <React.Fragment key={abbr}>{CalculatedPriceHeader}</React.Fragment>
      ))
    ) : (
      <>{CalculatedPriceHeader}</>
    );
  }, [CalculatedPriceHeader, buVisible, businessUnitAbbreviations]);

  const calculateColSpanForHeader = () => {
    if (buVisible && showRealizedPrice) {
      return businessUnitAbbreviations.length * 4;
    }

    if (buVisible) {
      return businessUnitAbbreviations.length * 4;
    }

    return 4;
  };

  return (
    <GrowWrapperComponent>
      <div className={classes.container}>
        <table className={classes.table}>
          <thead>
            <tr>
              {Array.from(Array(7).keys()).map((number) => (
                <th key={number} />
              ))}
              <th colSpan={calculateColSpanForHeader()}>
                <div>
                  <div>Calculated price increase target</div>

                  {buVisible ? (
                    <IconButton onClick={toggleBuVisible} sx={{ ml: 2 }}>
                      <div className={classes.minusIcon}>
                        <div />
                      </div>
                    </IconButton>
                  ) : (
                    <IconButton onClick={toggleBuVisible} sx={{ ml: 2 }}>
                      <PlusIcon />
                    </IconButton>
                  )}
                </div>
              </th>
              <th />
            </tr>
            {buVisible && (
              <tr className={classes.buAbbreviationRow}>
                <th />
                <th />
                <th />
                <th />
                <th />
                <th />
                <th />
                {businessUnitAbbreviations.map((item) => (
                  <th key={item} colSpan={showRealizedPrice ? 4 : 4}>
                    <div>{item}</div>
                  </th>
                ))}
              </tr>
            )}
            <tr>
              <th>
                <div>Customer</div>
              </th>
              <th>
                <div>Customer ID</div>
              </th>
              <th>
                <div>Parent</div>
              </th>
              <th>
                <div>Parent ID</div>
              </th>
              <th>
                <div>Long tail customer</div>
              </th>
              <th>
                <div>Account manager</div>
              </th>
              <th>
                <div>Revenue</div>
              </th>
              {CalculatedPriceIncreaseTarget}
              <th className={classes.lastCell}>
                <div>Overwrite Impact effect</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {customerTargets.map((customerTarget) => (
              <CustomerTargetsRow
                isRealizedPriceDisabled={isRealizedPriceDisabled}
                showRealizedPrice={showRealizedPrice}
                disableEditable={disableEditable}
                buVisible={buVisible}
                {...customerTarget}
                key={customerTarget.customerId}
                page={page as number}
                rowPerPage={rowPerPage as number}
              />
            ))}
          </tbody>
        </table>
      </div>
    </GrowWrapperComponent>
  );
});

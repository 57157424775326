import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 330,
    paddingBottom: 20,
  },
  table: {
    emptyCells: 'show',
    borderSpacing: '0 12px',
    borderCollapse: 'separate',
    '& th:first-child': {
      width: 100,
    },
    '& td': {
      fontSize: 16,
      color: COLORS.black1,
    },
  },
  titleHeader: {
    maxWidth: 107,
    fontSize: 16,
  },
  numberInput: {
    fontSize: 16,
    width: 104,
    height: 40,
    border: `1px solid ${COLORS.gray26}`,
    borderRadius: 8,
    textAlign: 'center',
    '&:disabled': {
      color: COLORS.gray23,
      borderColor: COLORS.gray23,
      backgroundColor: COLORS.gray28,
    },
  },
  textNotEditable: {
    width: 104,
    height: 40,
    border: `1px solid ${COLORS.gray26}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: 400,
    borderRadius: 8,
  },
  numberInputTwoCells: {
    width: 220,
  },
  disableCell: {
    color: COLORS.gray23,
    borderColor: COLORS.gray23,
    backgroundColor: COLORS.gray28,
  },
}));

export default useStyles;

import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
  },
  tableRowBorder: {
    border: `1px solid ${COLORS.gray4}`,
  },
  loaderContainer: {
    display: 'flex',
    height: '40vh',
    width: '100%',
    justifyContent: 'center',
    zIndex: 2,
    position: 'absolute',
  },
}));

export default useStyles;

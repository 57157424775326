import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 22,
    color: COLORS.gray14,
  },
}));

export default useStyles;

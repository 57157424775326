import React, { FC, useState, useEffect } from 'react';
import { EditableCell } from '../../../../../../common/EditableCell';
import useStyles from './useStyles';
import { ObjectionHandlingItem } from '../../../../../../../types';
import { ReactComponent as DeleteIcon } from 'src/assets/icons/delete-basket.svg';
import { DeleteButton } from '../../../../../../common/DeleteButton';

type TRowProps = {
  item: ObjectionHandlingItem;
  onUpdate: (obj: any) => void;
  onDelete: (id: number) => void;
  objectionHandlingLength: number;
};

const MIN_NUMBER_FIELDS = 3;
const TRow: FC<TRowProps> = ({ item, onUpdate, onDelete, objectionHandlingLength }) => {
  const classes = useStyles();

  const { objection, counterArgumentation } = item;

  const [objectionValue, setObjectionValue] = useState<string>('');
  const [counterArgumentationValue, setCounterArgumentationValue] = useState<string>('');

  useEffect(() => {
    setObjectionValue(objection);
    setCounterArgumentationValue(counterArgumentation);
  }, [item]);

  const onUpdateObjection = (value: string) => {
    setObjectionValue(value);
    onUpdate({ id: item.id, objection: value });
  };
  const onUpdateCounterArgumentation = (value: string) => {
    setCounterArgumentationValue(value);
    onUpdate({ id: item.id, counterArgumentation: value });
  };

  return (
    <tr>
      <td>
        <EditableCell
          isInput
          buttonStyle={classes.input}
          innerInputStyle={classes.input}
          value={objectionValue}
          onEditCb={onUpdateObjection}
          placeholder="Enter text"
        />
      </td>
      <td>
        <EditableCell
          isInput
          buttonStyle={classes.input}
          innerInputStyle={classes.input}
          value={counterArgumentationValue}
          onEditCb={onUpdateCounterArgumentation}
          placeholder="Enter text"
        />
      </td>
      <td>
        {objectionHandlingLength > MIN_NUMBER_FIELDS && (
          <DeleteButton onClick={() => onDelete(item.id)}>
            <DeleteIcon />
          </DeleteButton>
        )}
      </td>
    </tr>
  );
};

export default TRow;

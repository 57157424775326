import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../../../../constants/COLORS';

const useStyles = makeStyles(() => ({
  fields: {
    marginTop: 27,
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    position: 'relative',
  },
  nameColumn: {
    position: 'absolute',
    left: -110,
  },
  name: {
    marginBottom: 21,
    marginTop: 3,
    height: 24,
  },
  fieldColumn: {
    width: '100%',
    flex: 1,
    minWidth: 0,
    margin: '0 5px',
  },
  addButtonWrapper: {
    width: '100%',
    textAlign: 'center',
    flex: 1,
    margin: '0 5px',
  },
  addButtonLongWrapper: {
    flex: 2,
  },
  addButton: {
    '& button': {
      height: 24,
      color: COLORS.red1,
      borderColor: COLORS.red1,
      fontSize: 12,
      width: '100%',
    },
  },
}));

export default useStyles;

import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    width: '100%',
    minWidth: '1200px',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100vh',
  },
  main: {
    marginTop: 60,
    padding: '20px 20px 30px',
    borderRadius: 8,
    width: 400,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: '0px 2px 10px rgba(199, 201, 201, 0.5)',
  },
  title: {
    fontSize: 24,
    fontWeight: 500,
    color: COLORS.black1,
    textAlign: 'center',
    marginBottom: 20,
  },
  subtitle: {
    fontSize: 16,
    width: '300px',
    fontWeight: 500,
    color: COLORS.black1,
    textAlign: 'center',
    marginBottom: 40,
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 20,
  },
  button: {
    marginTop: 20,
    '& button': {
      borderRadius: 4,
      width: 260,
    },
  },
}));

export default useStyles;

import React, { useCallback } from 'react';
import { Typography } from '@mui/material';
import { Switch } from 'src/components/common/Switch';
import useStyles from './useStyles';
import { dispatch } from 'src/store';
import { updateAdditionalFieldsOfSurveySettings } from 'src/modules/surveys/actions';
import { UpdateAdditionalFieldsOfSurveySettings } from 'src/types';

type SettingsWithSwitchProps = {
  name: string;
  //eslint-disable-next-line
  title: string;
  value: boolean;
  onUpdate: (body: UpdateAdditionalFieldsOfSurveySettings) => void;
  isDisabled: boolean;
};

const SettingsWithSwitch: React.FC<SettingsWithSwitchProps> = ({
  name,
  title,
  onUpdate,
  value,
  isDisabled,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.itemContainer}>
      <Typography>{title}</Typography>
      <Switch
        disabled={isDisabled}
        isChecked={value}
        onChangeCb={(value) => {
          onUpdate({
            [name]: value,
          });
        }}
      />
    </div>
  );
};

type Props = {
  showLastPriceIncreaseDate: boolean;
  showContractExpirationDate: boolean;
  allowSegmentSpecificInput: boolean;
  isDisabled: boolean;
};

export const GeneralSurveySetting: React.FC<Props> = ({
  showLastPriceIncreaseDate,
  showContractExpirationDate,
  allowSegmentSpecificInput,
  isDisabled,
}) => {
  const classes = useStyles();

  const onUpdateField = useCallback((body) => {
    dispatch(updateAdditionalFieldsOfSurveySettings.request(body));
  }, []);

  return (
    <div className={classes.container}>
      <SettingsWithSwitch
        isDisabled={isDisabled}
        name="showContractExpirationDate"
        title='"Contract expiration date" column'
        value={showContractExpirationDate}
        onUpdate={onUpdateField}
      />
      <SettingsWithSwitch
        isDisabled={isDisabled}
        name="showLastPriceIncreaseDate"
        title='"Last price increase date" column'
        value={showLastPriceIncreaseDate}
        onUpdate={onUpdateField}
      />
      <SettingsWithSwitch
        isDisabled={isDisabled}
        name="allowSegmentSpecificInput"
        title="Allow segment specific input"
        value={allowSegmentSpecificInput}
        onUpdate={onUpdateField}
      />
    </div>
  );
};

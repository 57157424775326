import React, { useCallback, useEffect, useMemo } from 'react';

import useStyles from './useStyles';
import { useDispatch, useSelector } from 'react-redux';
import { Store, TargetForBusinessUnit } from 'src/types';

import {
  getTargetForBusinessUnits,
  setEmptyFieldForTargetGiven,
  setTargetForBusinessUnits,
} from 'src/modules/campaignTarget/actions';
import { Loader } from 'src/components/common/Loader';
import { NoDataPlaceholder } from '../NoDataPlaceholder';
import { EditableCell } from '../EditableCell';

type Props = {
  validateTargetsForEmptyFields?: () => boolean;
};

type TargetGivenItemProps = {
  onEditCb: (id: number, name: string, target: number) => void;
  disabled?: boolean;
  setEmptyFieldIfNeeded: (targetId: number) => void;
};

const TargetGivenItem: React.FC<TargetForBusinessUnit & TargetGivenItemProps> = ({
  id,
  target,
  name,
  onEditCb,
  disabled,
  setEmptyFieldIfNeeded,
}) => {
  const classes = useStyles();

  const onChange = (value: number) => {
    onEditCb(id, name, value);
  };

  return (
    <div className={classes.inputContainer}>
      <label className={classes.label} htmlFor={`campaign-target-given-${id}`}>
        {name}:
      </label>
      <EditableCell
        innerInputStyle={classes.input}
        buttonStyle={classes.numberInput}
        onlyNumbers={true}
        isInput
        disabled={disabled}
        onEditCb={(value) => {
          if (value === '') {
            setEmptyFieldIfNeeded(id);
          }

          onChange(+value);
        }}
        editable
        value={target ? target.toString() : '0'}
      />
    </div>
  );
};

export const TargetGivenContainer: React.FC<Props> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { targetsForBusinessUnits, loading } = useSelector((state: Store) => state.campaignTarget);
  const { disableEditPage } = useSelector((state: Store) => state.home);

  const setEmptyFieldIfNeeded = useCallback(
    (targetId) => {
      dispatch(setEmptyFieldForTargetGiven({ targetId }));
    },
    [dispatch],
  );

  const setTargetForBusinessUnitCb = useCallback(
    (id: number, name: string, target: number) => {
      dispatch(
        setTargetForBusinessUnits.request({
          targets: [
            {
              id,
              name,
              target,
            },
          ],
        }),
      );
    },
    [dispatch],
  );

  const EnhancedContainer = useMemo(() => {
    if (loading) {
      return <Loader marginTop={24} isLoading={loading} />;
    }

    if (targetsForBusinessUnits && targetsForBusinessUnits.targets.length > 0) {
      return (
        <>
          <h3 className={classes.title}>Target</h3>

          <div className={classes.list}>
            {targetsForBusinessUnits.targets.map((target) => (
              <TargetGivenItem
                disabled={disableEditPage}
                onEditCb={setTargetForBusinessUnitCb}
                key={target.id}
                setEmptyFieldIfNeeded={setEmptyFieldIfNeeded}
                {...target}
              />
            ))}
          </div>
        </>
      );
    } else {
      return <NoDataPlaceholder text="No data yet. Please try again later." height="300px" />;
    }
  }, [
    classes.list,
    classes.title,
    disableEditPage,
    loading,
    setEmptyFieldIfNeeded,
    setTargetForBusinessUnitCb,
    targetsForBusinessUnits,
  ]);

  useEffect(() => {
    dispatch(getTargetForBusinessUnits.request());
  }, [dispatch]);

  return <div className={classes.container}>{EnhancedContainer}</div>;
};

import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../../../../constants/COLORS';

const useStyles = makeStyles(() => ({
  chart: {
    height: 300,
    position: 'relative',
  },
  xAxisLine: {
    position: 'absolute',
    width: '112%',
    height: 11,
    backgroundColor: COLORS.gray21,
    bottom: -11.5,
    left: -110,
  },
}));

export default useStyles;

import React from 'react';
import { MonitoringSurvey } from 'src/types';
import useStyles from './useStyles';

type Props = {
  data: MonitoringSurvey[];
};

export const MonitoringSurveyTable: React.FC<Props> = ({ data }) => {
  const classes = useStyles();

  return (
    <div className={classes.tableContainer}>
      <table className={classes.table}>
        <thead>
          <tr>
            <th rowSpan={2}>
              <div style={{ width: 144 }}>Acc Manager</div>
            </th>
            <th rowSpan={2}>
              <div style={{ width: 144 }}>Negotiations</div>
            </th>
            <th colSpan={3} style={{ width: '30%' }}>
              <div className={classes.topCell}>Customers%</div>
            </th>
            <th colSpan={3} style={{ width: '30%' }}>
              <div className={classes.topCell}>Revenue%</div>
            </th>
            <th colSpan={3} style={{ width: '30%' }}>
              <div className={classes.topCell}>Price increase%</div>
            </th>
          </tr>
          <tr>
            <th>Not initiated</th>
            <th>Ongoing</th>
            <th>Finished</th>

            <th>Not Initiated Revenue</th>
            <th>Ongoing Revenue</th>
            <th>Finished revenue</th>

            <th>Estimated</th>
            <th>Target</th>
            <th>Delta</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id}>
              <td>
                <div>{item.name}</div>
              </td>
              <td>
                <div>{item.negotiations}</div>
              </td>
              <td>
                <div>{item.notInitiatedCustomers}%</div>
              </td>
              <td>
                <div>{item.ongoingCustomers}%</div>
              </td>
              <td>
                <div>{item.finishedCustomers}%</div>
              </td>
              <td>
                <div>{item.notInitiatedRevenue}%</div>
              </td>
              <td>
                <div>{item.ongoingRevenue}%</div>
              </td>
              <td>
                <div>{item.finishedRevenue}%</div>
              </td>
              <td>
                <div>{item.estimated}%</div>
              </td>
              <td>
                <div>{item.target}%</div>
              </td>
              <td>
                <div>{item.delta}%</div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

/* eslint-disable */
import React, { memo, useCallback, useContext, useMemo, useState } from "react";
import useStyles from "./useStyles";

import { ReactComponent as ExpandedIcon } from "src/assets/icons/expanded.svg";
import { ReactComponent as NotExpanded } from "src/assets/icons/not-expanded.svg";

import { SurveyResultCopyOptionsFromFirstBUToOtherRequestConfig } from "src/types";

import { TableBodyRow } from "../TableBodyRow";

import { IconButton } from "@mui/material";
import { NoDataPlaceholder } from "src/components/common/NoDataPlaceholder";
import { SurveyResultProvider, SurveyResultProviderTypes } from "../../SurveyResultProvider";
import { GrowWrapperComponent } from "src/components/common/GrowWrapperComponent";
import { Popper } from "src/components/common/Popper";

type Props = {
  setTouchableOption: (body: any) => void;
  setPlanBOption: (body: any) => void;
  setAdditionalFields: (body: any) => void;
  setContractCommitments: (body: any) => void;
  setEOIOption: (body: any) => void;
  setOtherOption: (body: any) => void;
  copyBuValueGeneralInfo?: (config: SurveyResultCopyOptionsFromFirstBUToOtherRequestConfig) => void;
  reloadRows?: boolean;
  onChangeVisibleFields?: (object: any) => void;
  allowSegmentSpecificInput: boolean
};


export const SurveyResultTable: React.FC<Props> = memo(function SurveyResultTable({
                                                                                    setTouchableOption,
                                                                                    setPlanBOption,
                                                                                    setAdditionalFields,
                                                                                    setContractCommitments,
                                                                                    setEOIOption,
                                                                                    setOtherOption,
                                                                                    copyBuValueGeneralInfo,
                                                                                    reloadRows,
                                                                                    onChangeVisibleFields,
                                                                                    allowSegmentSpecificInput
                                                                                  }) {
  const classes = useStyles();

  const {
    surveyResultEOIOptions,
    surveyResultContractCommitmentOptions,
    surveyResultPlanBOptions,
    surveyResultBusinessUnits,
    surveyResultCustomersData,
    surveyResultTouchableOptions,
    additionalFields,
    surveyResultOtherOptions
  } = useContext(SurveyResultProvider) as SurveyResultProviderTypes;


  const [buVisible, setBuVisible] = useState({
    general: false,
    eoi: false,
    contract: false,
    planB: false,
    other: false
  });

  const isBusinessUnitsVisible = useMemo(() => {
    return buVisible.eoi || buVisible.general || buVisible.planB || buVisible.contract || buVisible.other;
  }, [buVisible.contract, buVisible.eoi, buVisible.general, buVisible.planB, buVisible.other]);

  const hideBUGeneralInformation = () => {
    if (additionalFields && !additionalFields.allowSegmentSpecificInput) {
      return;
    }

    setBuVisible((prevState) => ({
      ...prevState,
      general: false
    }));

    if (onChangeVisibleFields) {
      onChangeVisibleFields({ general: false });
    }
  };

  const hideBUEOI = () => {
    if (additionalFields && !additionalFields.allowSegmentSpecificInput) {
      return;
    }

    setBuVisible((prevState) => ({
      ...prevState,
      eoi: false
    }));

    if (onChangeVisibleFields) {
      onChangeVisibleFields({ eoi: false });
    }
  };

  const hideBUContractTerms = () => {
    if (additionalFields && !additionalFields.allowSegmentSpecificInput) {
      return;
    }

    setBuVisible((prevState) => ({
      ...prevState,
      contract: false
    }));

    if (onChangeVisibleFields) {
      onChangeVisibleFields({ contract: false });
    }
  };

  const hideBUPlanB = () => {
    if (additionalFields && !additionalFields.allowSegmentSpecificInput) {
      return;
    }

    setBuVisible((prevState) => ({
      ...prevState,
      planB: false
    }));

    if (onChangeVisibleFields) {
      onChangeVisibleFields({ planB: false });
    }
  };

  const hideOther = () => {
    if (additionalFields && !additionalFields.allowSegmentSpecificInput) {
      return;
    }

    setBuVisible((prevState) => ({
      ...prevState,
      other: false
    }));

    if (onChangeVisibleFields) {
      onChangeVisibleFields({ other: false });
    }
  };

  const showBusinessUnitsGeneralInformation = () => {
    if (additionalFields && !additionalFields.allowSegmentSpecificInput) {
      return;
    }

    setBuVisible((prevState) => ({
      ...prevState,
      general: true
    }));

    if (onChangeVisibleFields) {
      onChangeVisibleFields({ general: true });
    }

  };

  const showBusinessUnitsEOI = () => {
    if (additionalFields && !additionalFields.allowSegmentSpecificInput) {
      return;
    }

    setBuVisible((prevState) => ({
      ...prevState,
      eoi: true
    }));

    if (onChangeVisibleFields) {
      onChangeVisibleFields({ eoi: true });
    }
  };

  const showContractTerms = () => {
    if (additionalFields && !additionalFields.allowSegmentSpecificInput) {
      return;
    }

    setBuVisible((prevState) => ({
      ...prevState,
      contract: true
    }));

    if (onChangeVisibleFields) {
      onChangeVisibleFields({ contract: true });
    }
  };

  const showPlanB = () => {
    if (additionalFields && !additionalFields.allowSegmentSpecificInput) {
      return;
    }

    setBuVisible((prevState) => ({
      ...prevState,
      planB: true
    }));

    if (onChangeVisibleFields) {
      onChangeVisibleFields({ planB: true });
    }
  };

  const showOther = () => {
    if (additionalFields && !additionalFields.allowSegmentSpecificInput) {
      return;
    }

    setBuVisible((prevState) => ({
      ...prevState,
      other: true
    }));

    if (onChangeVisibleFields) {
      onChangeVisibleFields({ other: true });
    }
  };

  const getGeneralInformationRow = useCallback(
    () => (
      <>
        {additionalFields && additionalFields.showContractExpirationDate && (
          <td>
            <div>Contract expiration date</div>
          </td>
        )}
        {additionalFields && additionalFields.showLastPriceIncreaseDate && (
          <td>
            <div>Last price increase</div>
          </td>
        )}
        <td className={classes.headerRightBorder}>
          <div>Touchable?</div>
        </td>
      </>
    ),
    [additionalFields]
  );

  const getEaseOfIncreaseRow = useCallback(() => {
    return surveyResultEOIOptions.map((option, index, array) => (
      <td
        key={option.driverId}
        className={index === array.length - 1 ? classes.headerRightBorder : ""}>
        <div>{option.driverName}</div>
      </td>
    ));
  }, [surveyResultEOIOptions]);

  const getOtherOptionsRow = useCallback(() => {
    return surveyResultOtherOptions.map((option: any, index: any, array: any) => (
      <td
        key={option.id}
        className={index === array.length - 1 ? classes.headerRightBorder : ""}>
        <div>{option.option}</div>
      </td>
    ));
  }, [surveyResultOtherOptions]);

  const getContractTermsRow = useCallback(
    () => (
      <>
        {surveyResultContractCommitmentOptions.map((option, index, array) => (
          <td
            key={option.optionId}
            className={index === array.length - 1 ? classes.headerRightBorder : ""}>
            <div>{option.option}</div>
          </td>
        ))}
      </>
    ),
    [surveyResultContractCommitmentOptions]
  );

  const getPlanBRow = useCallback(
    () => (
      <>
        {surveyResultPlanBOptions.map((option, index, array) => (
          <td
            className={index === array.length - 1 ? classes.headerRightBorder : ""}
            key={option.optionId}>
            <div>{option.option}</div>
          </td>
        ))}
      </>
    ),
    [surveyResultPlanBOptions]
  );

  const calculatedColSpanForGeneralInfo = useMemo(() => {
    let colSpan = 3;

    if (additionalFields) {
      if (!additionalFields.showLastPriceIncreaseDate) {
        colSpan--;
      }

      if (!additionalFields.showContractExpirationDate) {
        colSpan--;
      }
    }

    return colSpan;
  }, [additionalFields]);

  const BusinessUnitsRow = useMemo(() => {
    return isBusinessUnitsVisible ? (
      <tr>
        {[0, 1, 2, 3, 4].map((item) => (
          <td
            key={item}
            className={`${classes.emptyCellGray} ${item === 4 && classes.headerRightBorder}`}
          />
        ))}
        {buVisible.general ? (
          surveyResultBusinessUnits.map((businessUnit) => (
            <td
              colSpan={calculatedColSpanForGeneralInfo}
              className={classes.headerRightBorder}
              key={businessUnit.businessUnitId}>
              {businessUnit.businessUnitName}
            </td>
          ))
        ) : (
          <td className={classes.headerRightBorder} colSpan={calculatedColSpanForGeneralInfo} />
        )}
        {buVisible.eoi
          ? surveyResultBusinessUnits.map((businessUnit) => (
            <td
              colSpan={surveyResultEOIOptions.length}
              className={classes.headerRightBorder}
              key={businessUnit.businessUnitId}>
              {businessUnit.businessUnitName}
            </td>
          ))
          : surveyResultEOIOptions.length > 0 && (
          <td className={classes.headerRightBorder} colSpan={surveyResultEOIOptions.length} />
        )}
        {buVisible.contract
          ? surveyResultBusinessUnits.map((businessUnit) => (
            <td
              colSpan={surveyResultContractCommitmentOptions.length}
              className={classes.headerRightBorder}
              key={businessUnit.businessUnitId}>
              {businessUnit.businessUnitName}
            </td>
          ))
          : surveyResultContractCommitmentOptions.length > 0 && (
          <td
            className={classes.headerRightBorder}
            colSpan={surveyResultContractCommitmentOptions.length}
          />
        )}
        {buVisible.planB
          ? surveyResultBusinessUnits.map((businessUnit) => (
            <td
              colSpan={surveyResultPlanBOptions.length}
              className={classes.headerRightBorder}
              key={businessUnit.businessUnitId}>
              {businessUnit.businessUnitName}
            </td>
          ))
          : surveyResultPlanBOptions.length > 0 && (
          <td className={classes.headerRightBorder} colSpan={surveyResultPlanBOptions.length} />
        )}
        {buVisible.other
          ? surveyResultBusinessUnits.map((businessUnit) => (
            <td
              colSpan={surveyResultOtherOptions.length}
              className={classes.headerRightBorder}
              key={businessUnit.businessUnitId}>
              {businessUnit.businessUnitName}
            </td>
          ))
          : surveyResultOtherOptions.length > 0 && (
          <td className={classes.headerRightBorder} colSpan={surveyResultOtherOptions.length} />
        )}
        <td />
      </tr>
    ) : null;
  }, [
    buVisible.contract,
    buVisible.eoi,
    buVisible.general,
    buVisible.planB,
    buVisible.other,
    calculatedColSpanForGeneralInfo,
    isBusinessUnitsVisible,
    surveyResultBusinessUnits,
    surveyResultContractCommitmentOptions.length,
    surveyResultEOIOptions.length,
    surveyResultPlanBOptions.length,
    surveyResultOtherOptions.length
  ]);

  const renderGeneralInformation = useCallback(() => {
    if (buVisible.general) {
      return surveyResultBusinessUnits.map((item) => (
        <React.Fragment key={item.businessUnitId}>{getGeneralInformationRow()}</React.Fragment>
      ));
    }

    return getGeneralInformationRow();
  }, [getGeneralInformationRow, buVisible.general, surveyResultBusinessUnits]);

  const renderEOIRow = useCallback(() => {
    if (buVisible.eoi) {
      return surveyResultBusinessUnits.map((item) => (
        <React.Fragment key={item.businessUnitId}>{getEaseOfIncreaseRow()}</React.Fragment>
      ));
    }

    return getEaseOfIncreaseRow();
  }, [getEaseOfIncreaseRow, buVisible.eoi, surveyResultBusinessUnits]);

  const renderOtherOptionsRow = useCallback(() => {
    if (buVisible.other) {
      return surveyResultBusinessUnits.map((item) => (
        <React.Fragment key={item.businessUnitId}>{getOtherOptionsRow()}</React.Fragment>
      ));
    }

    return getOtherOptionsRow();
  }, [getOtherOptionsRow, buVisible.other, surveyResultBusinessUnits]);

  const renderContractTerms = useCallback(() => {
    if (buVisible.contract) {
      return surveyResultBusinessUnits.map((item) => (
        <React.Fragment key={item.businessUnitId}>{getContractTermsRow()}</React.Fragment>
      ));
    }

    return getContractTermsRow();
  }, [getContractTermsRow, buVisible.contract, surveyResultBusinessUnits]);

  const renderPlanBOptions = useCallback(() => {

    if (buVisible.planB) {
      return surveyResultBusinessUnits.map((item) => (
        <React.Fragment key={item.businessUnitId}>{getPlanBRow()}</React.Fragment>
      ));
    }

    return getPlanBRow();
  }, [getPlanBRow, buVisible.planB, surveyResultBusinessUnits]);

  if (surveyResultCustomersData.length === 0) {
    return <NoDataPlaceholder text="No data yet. Please try again later." />;
  }


  return (
    <GrowWrapperComponent>
      <div
        style={{
          height: surveyResultCustomersData.length > 8 ? "calc(100vh - 455px)" : "max-content"
        }}
        className={classes.wrapper}>
        <table className={classes.table}>
          <thead className={classes.tableHeader}>
          <tr>
            <th colSpan={isBusinessUnitsVisible ? 5 : 4}>Customer data</th>

            <th
              className={classes.headerTop}
              colSpan={
                buVisible.general
                  ? surveyResultBusinessUnits.length * calculatedColSpanForGeneralInfo
                  : calculatedColSpanForGeneralInfo
              }
              rowSpan={1}>
              <div className={classes.headerTopTitle}>
                <div>General information</div>
                {allowSegmentSpecificInput && (
                  <div>{buVisible.general ? (
                    <div>
                      <IconButton onClick={hideBUGeneralInformation}>
                        <ExpandedIcon />
                      </IconButton>
                    </div>
                  ) : (
                    <Popper onConfirm={showBusinessUnitsGeneralInformation}>
                      <div>
                        <NotExpanded className={classes.icon} />
                      </div>
                    </Popper>
                  )}
                  </div>
                )}
              </div>
            </th>
            {surveyResultEOIOptions.length > 0 && (
              <th
                className={classes.headerTop}
                colSpan={
                  buVisible.eoi
                    ? surveyResultBusinessUnits.length * surveyResultEOIOptions.length
                    : surveyResultEOIOptions.length
                }
                rowSpan={1}>
                <div className={classes.headerTopTitle}>
                  <div>Ease of increase</div>
                  {allowSegmentSpecificInput && (
                    <div>
                      {buVisible.eoi ? (
                        <div>
                          <IconButton onClick={hideBUEOI}>
                            <ExpandedIcon className={classes.icon} />
                          </IconButton>
                        </div>
                      ) : (
                        <Popper onConfirm={showBusinessUnitsEOI}>
                          <div>
                            <NotExpanded className={classes.icon} />
                          </div>
                        </Popper>
                      )}
                    </div>
                  )}
                </div>
              </th>
            )}

            {surveyResultContractCommitmentOptions.length > 0 && (
              <th
                className={classes.headerTop}
                colSpan={
                  buVisible.contract
                    ? surveyResultBusinessUnits.length *
                    surveyResultContractCommitmentOptions.length
                    : surveyResultContractCommitmentOptions.length
                }
                rowSpan={1}>
                <div className={classes.headerTopTitle}>
                  <div>Breach of contract terms</div>
                  {allowSegmentSpecificInput && (
                    <div>
                      {buVisible.contract ? (
                        <div>
                          <IconButton onClick={hideBUContractTerms}>
                            <ExpandedIcon className={classes.icon} />
                          </IconButton>
                        </div>
                      ) : (
                        <Popper onConfirm={showContractTerms}>
                          <div>
                            <NotExpanded className={classes.icon} />
                          </div>
                        </Popper>
                      )}
                    </div>
                  )}
                </div>
              </th>
            )}

            {surveyResultPlanBOptions.length > 0 && (
              <th
                className={classes.headerTop}
                rowSpan={1}
                colSpan={
                  buVisible.planB
                    ? surveyResultBusinessUnits.length * surveyResultPlanBOptions.length
                    : surveyResultPlanBOptions.length
                }>
                <div className={classes.headerTopTitle}>
                  <div>Plan B options</div>
                  {allowSegmentSpecificInput && (
                    <div>
                      {buVisible.planB ? (
                        <div>
                          <IconButton onClick={hideBUPlanB}>
                            <ExpandedIcon className={classes.icon} />
                          </IconButton>
                        </div>
                      ) : (
                        <Popper onConfirm={showPlanB}>
                          <div>
                            <NotExpanded className={classes.icon} />
                          </div>
                        </Popper>
                      )}
                    </div>
                  )}
                </div>
              </th>
            )}
            {surveyResultOtherOptions.length > 0 && <th rowSpan={1} colSpan={
              buVisible.other
                ? surveyResultBusinessUnits.length * surveyResultOtherOptions.length
                : surveyResultOtherOptions.length
            } className={`${classes.commentField}`}>
              <div className={classes.headerTopTitle}>
                Other
                {allowSegmentSpecificInput && (
                  <div>
                    {buVisible.other ? (
                      <div>
                        <IconButton onClick={hideOther}>
                          <ExpandedIcon />
                        </IconButton>
                      </div>
                    ) : (
                      <Popper onConfirm={showOther}>
                        <div>
                          <NotExpanded className={classes.icon} />
                        </div>
                      </Popper>
                    )}
                  </div>
                )}
              </div>
            </th>}
            <th rowSpan={1} colSpan={1} className={`${classes.commentField}`}>
              <div className={classes.headerTopTitle}>Comment</div>
            </th>
          </tr>
          {BusinessUnitsRow}
          <tr>
            <th
              className={`${classes.tableHeaderColumnLeft} ${classes.headerCustomerColumn}`}
              rowSpan={isBusinessUnitsVisible ? 3 : 1}>
              Customer
            </th>
            <th
              className={`${classes.tableHeaderColumnLeft} ${classes.headerCustomerIdColumn}`}
              rowSpan={isBusinessUnitsVisible ? 3 : 1}>
              Customer ID
            </th>
            <th
              className={`${classes.tableHeaderColumnLeft}
                 ${classes.headerCustomerIdColumn} `}
              rowSpan={isBusinessUnitsVisible ? 3 : 1}>
              Parent account
            </th>
            <th
              className={`${classes.tableHeaderColumnLeft} ${classes.headerCustomerIdColumn} ${
                !isBusinessUnitsVisible && classes.headerRightBorder
              }`}
              rowSpan={isBusinessUnitsVisible ? 3 : 1}>
              Parent ID
            </th>
            {isBusinessUnitsVisible && (
              <th
                className={`${classes.tableHeaderColumnLeft} ${classes.headerCustomerIdColumn} ${classes.headerRightBorder}`}
                rowSpan={isBusinessUnitsVisible ? 3 : 1}>
                Sync selection
              </th>
            )}

            {renderGeneralInformation()}
            {renderEOIRow()}
            {renderContractTerms()}
            {renderPlanBOptions()}
            {renderOtherOptionsRow()}
            <th className={classes.emptyCell}></th>
          </tr>
          </thead>
          <tbody>
          {!reloadRows &&
            surveyResultCustomersData.map((customer, index) => (
              <TableBodyRow
                isBusinessUnitsVisible={isBusinessUnitsVisible}
                copyBuValueGeneralInfo={
                  isBusinessUnitsVisible ? copyBuValueGeneralInfo : undefined
                }
                index={index}
                buVisible={buVisible}
                setTouchableOption={setTouchableOption}
                setEOIOption={setEOIOption}
                setOtherOption={setOtherOption}
                setContractCommitments={setContractCommitments}
                setAdditionalFields={setAdditionalFields}
                setPlanBOption={setPlanBOption}
                touchableOptions={surveyResultTouchableOptions}
                eoiOptions={surveyResultEOIOptions}
                otherOptions={surveyResultOtherOptions}
                key={customer.isGroup ? `${customer.customerId}_isGroup` : customer.customerId}
                {...customer}
              />
            ))}
          </tbody>
        </table>
      </div>
    </GrowWrapperComponent>
  );
});

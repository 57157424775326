import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  main_section: {
    display: 'flex',
    minHeight: '100vh',
    overflowY: 'auto',
  },
  aside: {
    minWidth: '240px',
    width: '240px',
    background: '#404055',
    padding: '23px 18px 0px 16.5px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
  },
  main: {
    padding: '20px 20px 30px',
    width: '100%',
    minWidth: '1180px',
  },
  container: {
    display: 'flex',
    width: '100%',
    minWidth: '1200px',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: COLORS.white1,
    position: 'relative',
  },
  lock_icon: {
    width: 13,
    height: 16,
    display: 'block',
    cursor: 'pointer',
  },
  logoutButton: {
    display: 'flex',
    width: '100%',
    height: 52,
    alignItems: 'center',
    paddingLeft: 1,
    columnGap: 13,
    marginTop: 12,
    cursor: 'pointer',
  },
}));

export default useStyles;

import React, { memo } from 'react';
import { EditableCell } from 'src/components/common/EditableCell';
import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  cell: {
    fontSize: 14,
    color: COLORS.black2,
  },
  inputStyle: {
    fontSize: 14,
    color: COLORS.black2,
    maxWidth: 100,
  },
}));

type Props = {
  disableEditable?: boolean;
  goingInPriceIncrease: number;
  targetPriceIncrease: number;
  walkAwayPriceIncrease: number;
  escalationPriceIncrease: number;
  realizedPrice?: number | null;
  businessUnitId?: number;
  showRealizedPrice?: boolean;
  isRealizedPriceDisabled?: boolean;
  onEditRealizedPrice?: (data: { businessUnitId?: number; realizedPrice: number }) => void;
  onEdit: (body: {
    businessUnitId?: number;
    goingInPriceIncrease?: number;
    targetPriceIncrease?: number;
    walkAwayPriceIncrease?: number;
    escalationPriceIncrease?: number;
  }) => void;
};

export const CalculatedPriceCells: React.FC<Props> = memo(function CalculatedPriceCells({
  goingInPriceIncrease,
  businessUnitId,
  targetPriceIncrease,
  walkAwayPriceIncrease,
  onEdit,
  disableEditable,
  showRealizedPrice,
  realizedPrice,
  onEditRealizedPrice,
  isRealizedPriceDisabled,
  escalationPriceIncrease,
}) {
  const classes = useStyles();

  return (
    <>
      <EditableCell
        disabled={disableEditable}
        editable
        onlyNumbers
        innerInputStyle={classes.inputStyle}
        buttonStyle={classes.cell}
        suffix="%"
        value={goingInPriceIncrease ? goingInPriceIncrease.toString() : '0'}
        onEditCb={(value) => {
          if (value !== goingInPriceIncrease.toString()) {
            onEdit({ goingInPriceIncrease: +value, businessUnitId: businessUnitId });
          }
        }}
      />
      <EditableCell
        disabled={disableEditable}
        editable
        onlyNumbers
        buttonStyle={classes.cell}
        innerInputStyle={classes.inputStyle}
        suffix="%"
        value={targetPriceIncrease ? targetPriceIncrease.toString() : '0'}
        onEditCb={(value) => {
          if (value !== targetPriceIncrease.toString()) {
            onEdit({ targetPriceIncrease: +value, businessUnitId: businessUnitId });
          }
        }}
      />
      <EditableCell
        disabled={disableEditable}
        editable
        onlyNumbers
        buttonStyle={classes.cell}
        innerInputStyle={classes.inputStyle}
        suffix="%"
        value={escalationPriceIncrease ? escalationPriceIncrease.toString() : '0'}
        onEditCb={(value) => {
          if (value !== escalationPriceIncrease.toString()) {
            onEdit({ escalationPriceIncrease: +value, businessUnitId: businessUnitId });
          }
        }}
      />
      <EditableCell
        disabled={disableEditable}
        editable
        onlyNumbers
        buttonStyle={classes.cell}
        innerInputStyle={classes.inputStyle}
        suffix="%"
        value={walkAwayPriceIncrease ? walkAwayPriceIncrease.toString() : '0'}
        onEditCb={(value) => {
          if (value !== walkAwayPriceIncrease.toString()) {
            onEdit({ walkAwayPriceIncrease: +value, businessUnitId: businessUnitId });
          }
        }}
      />
      {showRealizedPrice && (
        <EditableCell
          disabled={isRealizedPriceDisabled}
          editable
          onlyNumbers
          buttonStyle={classes.cell}
          innerInputStyle={classes.inputStyle}
          value={realizedPrice ? realizedPrice.toString() : '0'}
          onEditCb={(value) => {
            if (realizedPrice && value !== realizedPrice.toString()) {
              onEditRealizedPrice &&
                onEditRealizedPrice({ realizedPrice: +value, businessUnitId: businessUnitId });
            }
          }}
        />
      )}
    </>
  );
});

import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  table: {
    boxShadow: '0px 2px 10px rgba(199, 201, 201, 0.5)',
    width: '100%',
    borderRadius: 10,
    overflow: 'hidden',
    borderCollapse: 'collapse',
    '& thead th': {
      backgroundColor: COLORS.blue12,
      color: COLORS.black2,
      fontWeight: 700,
      height: 65,
      textAlign: 'left',
      fontSize: 16,
    },
    '& tbody td': {
      height: 60,
      fontSize: 14,
      color: COLORS.black1,
    },
    '& tbody tr:nth-child(even)': {
      backgroundColor: COLORS.blue13,
    },
    '& tbody td, thead th': {
      paddingLeft: 20,
    },
  },
  wrapper: {
    marginTop: '20px',
    overflowY: 'auto',
    boxShadow: '2px 4px 10px rgba(199, 201, 201, 0.5)',
  },
}));

export default useStyles;

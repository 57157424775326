import React, { FC, useState, useEffect } from 'react';
import { EditableCell } from '../../../../../../common/EditableCell';
import useStyles from './useStyles';
import { ValueArgumentationItem } from '../../../../../../../types';
import { ReactComponent as DeleteIcon } from 'src/assets/icons/delete-basket.svg';
import { DeleteButton } from '../../../../../../common/DeleteButton';

type TRowProps = {
  item: ValueArgumentationItem;
  onUpdate: (obj: any) => void;
  onDelete: (id: number) => void;
  valueArgumentationLength: number;
};

const MIN_NUMBER_FIELDS = 3;

const TRow: FC<TRowProps> = ({ item, onUpdate, onDelete, valueArgumentationLength }) => {
  const classes = useStyles();

  const { valueArgumentation, evidenceOrBenefit } = item;

  const [valueArgumentationValue, setValueArgumentationValue] = useState<string>('');
  const [evidenceOrBenefitValue, setEvidenceOrBenefitValue] = useState<string>('');

  useEffect(() => {
    setValueArgumentationValue(valueArgumentation);
    setEvidenceOrBenefitValue(evidenceOrBenefit);
  }, []);

  const onChangeValueArgumentation = (value: string) => {
    setValueArgumentationValue(value);
    onUpdate({ id: item.id, valueArgumentation: value });
  };
  const onChangeEvidenceOrBenefit = (value: string) => {
    setEvidenceOrBenefitValue(value);
    onUpdate({ id: item.id, evidenceOrBenefit: value });
  };

  return (
    <tr>
      <td>
        <EditableCell
          isInput
          buttonStyle={classes.input}
          innerInputStyle={classes.input}
          value={valueArgumentationValue}
          onEditCb={onChangeValueArgumentation}
          placeholder="Enter text"
        />
      </td>
      <td>
        <EditableCell
          isInput
          buttonStyle={classes.input}
          innerInputStyle={classes.input}
          value={evidenceOrBenefitValue}
          onEditCb={onChangeEvidenceOrBenefit}
          placeholder="Enter text"
        />
      </td>
      <td>
        {valueArgumentationLength > MIN_NUMBER_FIELDS && (
          <DeleteButton onClick={() => onDelete(item.id)}>
            <DeleteIcon />
          </DeleteButton>
        )}
      </td>
    </tr>
  );
};

export default TRow;

import React, { memo, useCallback } from 'react';
import useStyles from './useStyles';
import { Control, useController } from 'react-hook-form';
import { LoginFields } from '../../pages/Login';

type Props = {
  placeholder: string;
  name: 'email' | 'password' | 'rememberMe';
  //eslint-disable-next-line
  control: Control<LoginFields, object>;
  type: string;
};

export const LoginInput: React.FC<Props> = memo(function LoginInput({
  control,
  name,
  placeholder,
  type,
}) {
  const classes = useStyles();

  const {
    field: { value, onChange },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
  });

  const handleTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      onChange(value);
    },
    [onChange],
  );

  return (
    <div>
      <input
        onChange={handleTextChange}
        value={typeof value === 'string' ? value : ''}
        placeholder={placeholder}
        className={classes.input}
        autoComplete="false"
        type={type}
      />
      {invalid && <p className={classes.labelError}>{error?.message}</p>}
    </div>
  );
});

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 27,
  },
  listContainer: {
    marginTop: 16,
    display: 'flex',
    gap: 20,
    flexWrap: 'wrap',
  },
}));

export default useStyles;

import React, { memo, useEffect } from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { COLORS } from 'src/constants/COLORS';
import useStyles from './useStyles';

import { CAMPAIGN_TARGETS } from 'src/constants/CAMPAIGN_TARGETS';
import { useDispatch } from 'react-redux';

import { getApproachForCampaignTarget } from 'src/modules/campaignTarget/actions';

type Props = {
  sectionSelected: string;
  handleChange: (value: string) => void;
};

export const CampaignTargetHeaderController: React.FC<Props> = memo(
  function CampaignTargetHeaderController({ sectionSelected, handleChange }) {
    const classes = useStyles();

    const dispatch = useDispatch();

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      handleChange((event.target as HTMLInputElement).value);
    };

    useEffect(() => {
      dispatch(getApproachForCampaignTarget.request());
    }, [dispatch]);

    return (
      <div className={classes.header}>
        <h3 className={classes.title}>Approach:</h3>
        <FormControl>
          <RadioGroup
            name="controlled-radio-buttons-group"
            value={sectionSelected}
            onChange={onChange}>
            <FormControlLabel
              value={CAMPAIGN_TARGETS.TARGET_GIVEN}
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: '16px',
                },
              }}
              control={
                <Radio
                  sx={{
                    color: COLORS.red1,
                    '&.Mui-checked': {
                      color: COLORS.red1,
                    },
                  }}
                />
              }
              label="Target given"
            />
            <FormControlLabel
              value={CAMPAIGN_TARGETS.PRICE_POTENTIAL}
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: '16px',
                },
              }}
              control={
                <Radio
                  sx={{
                    color: COLORS.red1,
                    '&.Mui-checked': {
                      color: COLORS.red1,
                    },
                  }}
                />
              }
              label="Price potential"
            />
          </RadioGroup>
        </FormControl>
      </div>
    );
  },
);

import React from 'react';
import useStyles from './useStyles';

const Unauthorized = () => {
  const classes = useStyles();

  return <div className={classes.unauthorized}>You don't have an access to this application</div>;
};

export default Unauthorized;

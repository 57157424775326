import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  row: {
    '& td': {
      fontSize: 16,
      color: COLORS.black6,
      padding: '0 24px',
    },
    '& td:nth-child(-n+2)': {
      minWidth: 115,
      textAlign: 'left',
    },
    '& td:nth-child(2)': {
      borderRight: `1px solid ${COLORS.gray30}`,
    },
  },
  numberInputContainer: {
    backgroundColor: COLORS.white1,
    textAlign: 'center',
  },
  numberInput: {
    border: `1px solid ${COLORS.gray26}`,
    borderRadius: 8,
    height: '40px',
    width: '74px',
    outline: 'none',
    fontSize: '16px',
    textAlign: 'center',
    color: COLORS.black1,
    '&::placeholder': {
      color: COLORS.gray22,
    },
    '&:placeholder': {
      color: COLORS.gray25,
    },
    '&:hover': {
      borderColor: COLORS.blue2,
    },
    '&:focus': {
      borderColor: COLORS.blue11,
    },
  },
}));

export default useStyles;

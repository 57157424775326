import React, { memo, useContext } from 'react';
import { ContractCommitment } from 'src/types';

import useStyles from './useStyles';
import { SurveysProvider } from '../../SurveysProvider';
import { DeleteButton } from 'src/components/common/DeleteButton';

import { ReactComponent as WarningIcon } from 'src/assets/icons/warning.svg';
import { SurveySettingsCategories } from '../../../../../../../constants/SurveySettingCategories';
import { EditableCell } from '../../../../../../common/EditableCell';

type Props = ContractCommitment & {
  onChangeValueCb: (value: string, id: number) => void;
  error: boolean;
};

export const ContractCommitmentItem: React.FC<Props> = memo(function ContractCommitmentItem({
  option,
  id,
  onChangeValueCb,
  error,
}) {
  const classes = useStyles();

  const { onDeleteOption, handleCleanError, disabled } = useContext(SurveysProvider);

  const onChange = (value: string) => {
    if (error) {
      handleCleanError(id, 'contractCommitments');
    }

    if (value.slice(-1) === ' ') {
      return;
    }

    if (value !== option) {
      onChangeValueCb(value, id);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.inputContainer}>
        {error && <WarningIcon className={classes.listItemErrorIcon} />}

        <EditableCell
          isInput
          buttonStyle={`${classes.input} ${disabled && classes.inputDisabled} ${
            error && classes.listItemErrorInput
          }`}
          innerInputStyle={classes.input}
          disabled={disabled}
          value={option}
          onEditCb={onChange}
        />
      </div>

      <DeleteButton
        disabled={disabled}
        styles={classes.deleteButton}
        onClick={() => {
          if (!disabled) {
            onDeleteOption(id, SurveySettingsCategories.contractCommitments);
          }
        }}
      />
    </div>
  );
});

import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../../../constants/COLORS';

const useStyles = makeStyles(() => ({
  revenueImpactChart: {
    position: 'relative',
  },
  xAxisLine: {
    position: 'absolute',
    width: '95%',
    height: 7,
    backgroundColor: COLORS.gray2,
    bottom: 0,
  },
  xAxisLineLabel: {
    position: 'absolute',
    display: 'flex',
    bottom: -40,
    width: '100%',
  },
  itemLabel: {
    textAlign: 'center',
    display: 'flex',
    flex: '1 1 100%',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 14,
    maxWidth: '115px',
  },
}));

export default useStyles;

import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { ReactComponent as ChevronDown } from 'src/assets/icons/chevron-down.svg';
import useStyles from './useStyles';
import { COLORS } from '../../../../../../../constants/COLORS';
import { DROPDOWN_MENU_MAX_HEIGHT } from 'src/constants';

type Props = {
  width?: number | string;
  height?: number;
  onSelectCb?: (selectedText: string) => void;
  dropdownItems: string[];
  dropdownArrowColor?: string;
  disabled?: boolean;
  defaultValue?: string;
};

export const Dropdown: React.FC<Props> = ({
  width = 142,
  height = 40,
  dropdownItems,
  onSelectCb,
  disabled,
  defaultValue,
}) => {
  const classes = useStyles();

  const [value, setValue] = React.useState('');

  const handleChange = (event: SelectChangeEvent<typeof value>) => {
    const {
      target: { value },
    } = event;
    setValue(value);

    onSelectCb && onSelectCb(value);
  };

  return (
    <div className={classes.wrapper}>
      <FormControl style={{ width, minWidth: 133 }} classes={{ root: `${classes.root}` }}>
        <Select
          disabled={disabled}
          style={{ width: '100%' }}
          sx={{ height }}
          IconComponent={(props) => {
            return <ChevronDown {...props} fill={disabled ? COLORS.gray23 : COLORS.gray26} />;
          }}
          value={value || defaultValue}
          onChange={handleChange}
          MenuProps={{
            classes: { paper: `${classes.select}` },
            PaperProps: {
              style: {
                maxHeight: DROPDOWN_MENU_MAX_HEIGHT,
                borderRadius: 8,
              },
            },
          }}>
          {dropdownItems.map((name, index) => (
            <MenuItem key={index} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

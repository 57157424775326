import { takeLatest, put } from 'redux-saga/effects';
import { API } from 'src/api';

import {
  getSurveysSettings,
  updateSurveysSettings,
  deleteSurveyPriceIncreaseTimeLevelOption,
  deleteSurveySettingsOption,
  getSurveyResult,
  setEOIOption,
  setTouchableOption,
  setContractCommitment,
  setSurveyResultAdditionalFields,
  setPlanBOption,
  updateSurveySettingTouchable,
  surveySettingsCreateNewEOI,
  surveySettingsUpdateEOIOption,
  surveySettingUpdateEOIModalInfo,
  getSurveysCompleting,
  setSurveysCompletingTouchableOption,
  setSurveysCompletingEOIOption,
  setSurveysCompletingContractCommitmentOption,
  setSurveysCompletingAdditionalFields,
  setSurveyCompletingPlanBOption,
  surveysCompletingSaveProgress,
  updateRealizedPrice,
  resetOverwriteImpactEffect,
  updateAdditionalFieldsOfSurveySettings,
  checkSurveyResultFieldsAreFilled,
  surveyResultCopyOptionsFromFirstBUToOther,
  getSecondSurvey,
  saveSecondSurveyOne,
  getSurveyResultAccess,
  createSurveySettingsOtherOption,
  updateSurveySettingsOtherOption,
  deleteSurveyOtherModalOption,
  surveySettingUpdateOtherModalInfo,
  setOtherOption,
  setSurveysCompletingOtherOption,
  surveyResultCopyOptionsFromFirstBUToOtherAccountManager,
} from './actions';
import { processRequestError } from 'src/modules/Errors/actions';

import { ActionType } from 'deox';
import { toast } from 'react-toastify';
import { select } from 'typed-redux-saga';
import { Store } from 'src/types';
import { getCustomerTargets } from '../specificTargets/actions';

function* getSurveyResultAccessSaga() {
  try {
    const { projectId } = yield select((state: Store) => state.projects);

    if (projectId !== 0) {
      const { data } = yield API.getSurveyResultAccess({ projectId });

      yield put(getSurveyResultAccess.success(data));
    }
  } catch (error) {
    yield put(processRequestError({ error, failAction: getSurveyResultAccess.fail }));
  }
}

function* surveyResultCopyOptionsFromFirstBUToOtherSaga({
  payload,
}: ActionType<typeof surveyResultCopyOptionsFromFirstBUToOther.request>) {
  try {
    const { projectId } = yield select((state: Store) => state.projects);

    const { surveyResult } = yield select((state: Store) => state.surveys);

    const firstBusinessUnitId = surveyResult.customersData[0].businessUnitsData[0].businessUnitId;

    yield API.surveyResultCopyOptionsFromFirstBUToOther({
      body: {
        ...payload.body,
        businessUnitId: firstBusinessUnitId,
      },
      params: { projectId },
    });

    yield put(surveyResultCopyOptionsFromFirstBUToOther.success(payload));
    const payloadForResult = {
      accountManager: payload.accountManager,
      pageNumber: payload.pageNumber,
      itemsOnPage: payload.itemsOnPage,
    };

    yield put(getSurveyResult.request(payloadForResult as any));
  } catch (error) {
    yield put(
      processRequestError({ error, failAction: surveyResultCopyOptionsFromFirstBUToOther.fail }),
    );
  }
}

function* surveyResultCopyOptionsFromFirstBUToOtherAccountManagerSaga({
  payload,
}: ActionType<typeof surveyResultCopyOptionsFromFirstBUToOtherAccountManager.request>) {
  try {
    const { surveyCompleting } = yield select((state: Store) => state.surveys);

    const firstBusinessUnitId =
      surveyCompleting.surveyResultData.customersData[0].businessUnitsData[0].businessUnitId;

    yield API.surveyResultCopyOptionsFromFirstBUToOtherAccountManager({
      body: {
        ...payload.body,
        businessUnitId: firstBusinessUnitId,
      },
    });

    yield put(surveyResultCopyOptionsFromFirstBUToOtherAccountManager.success(payload));

    const payloadForCompleting = {
      pageNumber: payload.pageNumber,
      itemsOnPage: payload.itemsOnPage,
    };

    yield put(getSurveysCompleting.request(payloadForCompleting as any));
  } catch (error) {
    yield put(
      processRequestError({ error, failAction: surveyResultCopyOptionsFromFirstBUToOther.fail }),
    );
  }
}

function* checkSurveyResultSaga({
  payload,
}: ActionType<typeof checkSurveyResultFieldsAreFilled.request>) {
  try {
    const { onSuccessCb } = payload;

    const { projectId } = yield select((state: Store) => state.projects);

    yield API.checkSurveyResultForEmptyFields({ projectId });

    onSuccessCb();
  } catch (error) {
    yield put(processRequestError({ error, failAction: checkSurveyResultFieldsAreFilled.fail }));
  }
}

function* getSurveysSettingsSaga({ payload }: ActionType<typeof getSurveysSettings.request>) {
  try {
    const { data } = yield API.getSurveysSettingsById(payload.requestPayload);

    yield put(getSurveysSettings.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: getSurveysSettings.fail }));
  }
}

function* updateAdditionalFieldsOfSurveySettingsSaga({
  payload,
}: ActionType<typeof updateAdditionalFieldsOfSurveySettings.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    yield API.updateAdditionalFieldsOfSurveySettings({ projectId, ...payload });

    yield put(updateAdditionalFieldsOfSurveySettings.success());
  } catch (error) {
    yield put(
      processRequestError({ error, failAction: updateAdditionalFieldsOfSurveySettings.fail }),
    );
  }
}

function* surveySettingUpdateEOIModalInfoSaga({
  payload,
}: ActionType<typeof surveySettingUpdateEOIModalInfo.request>) {
  const { requestPayload, onSuccessCb } = payload;
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    yield API.surveySettingUpdateEOIModalInfo(requestPayload);

    yield put(surveySettingUpdateEOIModalInfo.success());

    yield put(
      getSurveysSettings.request({
        requestPayload: {
          id: projectId,
        },
      }),
    );

    onSuccessCb && onSuccessCb();
    toast.success('Success');
  } catch (error) {
    yield put(processRequestError({ error, failAction: surveySettingUpdateEOIModalInfo.fail }));
  }
}

function* surveySettingUpdateOtherModalInfoSaga({
  payload,
}: ActionType<typeof surveySettingUpdateOtherModalInfo.request>) {
  const { requestPayload, onSuccessCb } = payload;
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    yield API.surveySettingUpdateOtherModalInfo(requestPayload);

    yield put(surveySettingUpdateOtherModalInfo.success());

    yield put(
      getSurveysSettings.request({
        requestPayload: {
          id: projectId,
        },
      }),
    );

    onSuccessCb && onSuccessCb();
    toast.success('Success');
  } catch (error) {
    yield put(processRequestError({ error, failAction: surveySettingUpdateOtherModalInfo.fail }));
  }
}

function* surveySettingsUpdateEOIOptionSaga({
  payload,
}: ActionType<typeof surveySettingsUpdateEOIOption.request>) {
  try {
    yield API.surveySettingsUpdateEOIOption(payload);

    yield put(surveySettingsUpdateEOIOption.success(payload));
  } catch (error) {
    yield put(processRequestError({ error, failAction: surveySettingsUpdateEOIOption.fail }));
  }
}

function* surveySettingsCreateEOISaga({
  payload,
}: ActionType<typeof surveySettingsCreateNewEOI.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.surveySettingsCreateNewEOI({ ...payload, projectId });

    yield put(surveySettingsCreateNewEOI.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: surveySettingsCreateNewEOI.fail }));
  }
}

function* createSurveySettingsOtherOptionSaga({
  payload,
}: ActionType<typeof createSurveySettingsOtherOption.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  const payloadRequest = {
    params: {
      projectId,
    },
    body: payload,
  };

  try {
    const { data } = yield API.createSurveySettingsOtherOption(payloadRequest);

    yield put(createSurveySettingsOtherOption.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: createSurveySettingsOtherOption.fail }));
  }
}

function* updateSurveySettingsOtherOptionSaga({
  payload,
}: ActionType<typeof updateSurveySettingsOtherOption.request>) {
  try {
    const { data } = yield API.updateSurveySettingsOtherOption(payload);

    yield put(updateSurveySettingsOtherOption.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: updateSurveySettingsOtherOption.fail }));
  }
}

function* updateSurveysSettingsSaga({ payload }: ActionType<typeof updateSurveysSettings.request>) {
  const { body, needUpdateState, onSuccessCb, onFailCb } = payload;
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.updateSurveysSettingsById({ ...body, projectId });

    if (needUpdateState) {
      yield put(updateSurveysSettings.success(data));
    }

    onSuccessCb && onSuccessCb();
  } catch (error) {
    onFailCb && onFailCb();

    yield put(processRequestError({ error, failAction: updateSurveysSettings.fail }));
  }
}

function* updateSurveySettingTouchableSaga({
  payload,
}: ActionType<typeof updateSurveySettingTouchable.request>) {
  try {
    yield API.updateSurveySettingTouchable(payload);

    yield put(updateSurveySettingTouchable.success());
  } catch (error) {
    yield put(processRequestError({ error, failAction: updateSurveySettingTouchable.fail }));
  }
}

function* deletePriceIncreaseTimeLevelOptionSaga({
  payload,
}: ActionType<typeof deleteSurveyPriceIncreaseTimeLevelOption.request>) {
  try {
    if (payload.id) {
      yield API.deleteSurveyPriceIncreaseTimeLevelOption({ optionId: payload.id });
    }

    yield put(deleteSurveyPriceIncreaseTimeLevelOption.success(payload));
  } catch (error) {
    yield put(
      processRequestError({ error, failAction: deleteSurveyPriceIncreaseTimeLevelOption.fail }),
    );
  }
}

function* deleteSurveyOtherModalOptionSaga({
  payload,
}: ActionType<typeof deleteSurveyOtherModalOption.request>) {
  try {
    if (payload.id) {
      yield API.deleteSurveyOtherModalOption({ itemId: payload.itemId, optionId: payload.id });
    }

    yield put(deleteSurveyOtherModalOption.success(payload));
  } catch (error) {
    yield put(processRequestError({ error, failAction: deleteSurveyOtherModalOption.fail }));
  }
}

function* deleteSurveysOptionSaga({
  payload,
}: ActionType<typeof deleteSurveySettingsOption.request>) {
  try {
    yield API.deleteSurveySettingsOption(payload.requestParams);

    yield put(deleteSurveySettingsOption.success());
  } catch (error) {
    yield put(processRequestError({ error, failAction: deleteSurveySettingsOption.fail }));
  }
}

function* getSurveyResultSaga({ payload }: ActionType<typeof getSurveyResult.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.getSurveyResult({ ...payload, projectId });

    yield put(getSurveyResult.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: getSurveyResult.fail }));
  }
}

function* setTouchableOptionSaga({ payload }: ActionType<typeof setTouchableOption.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { params, body } = payload;

    yield API.surveyResultSetTouchableOption(projectId, params, body);
  } catch (error) {
    yield put(processRequestError({ error, failAction: setTouchableOption.fail }));
  }
}

function* setEOIOptionSaga({ payload }: ActionType<typeof setEOIOption.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { params, body } = payload;

    yield API.surveyResultSetEOIOption(projectId, params, body);
  } catch (error) {
    yield put(processRequestError({ error, failAction: setEOIOption.fail }));
  }
}

function* setOtherOptionSaga({ payload }: ActionType<typeof setOtherOption.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { params, body } = payload;

    yield API.surveyResultSetOtherOption(projectId, params, body);
  } catch (error) {
    yield put(processRequestError({ error, failAction: setOtherOption.fail }));
  }
}

function* setContractCommitmentSaga({ payload }: ActionType<typeof setContractCommitment.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { params, body } = payload;

    yield API.surveyResultSetContractCommitment(projectId, params, body);
  } catch (error) {
    yield put(processRequestError({ error, failAction: setContractCommitment.fail }));
  }
}

function* setSurveyResultAdditionalFieldsSaga({
  payload,
}: ActionType<typeof setSurveyResultAdditionalFields.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { params, body } = payload;

    yield API.surveyResultSetAdditionalFields(projectId, params, body);
  } catch (error) {
    yield put(processRequestError({ error, failAction: setSurveyResultAdditionalFields.fail }));
  }
}

function* setPlanBOptionSaga({ payload }: ActionType<typeof setPlanBOption.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { params, body } = payload;

    yield API.surveyResultSetPlanBOption(projectId, params, body);
  } catch (error) {
    yield put(processRequestError({ error, failAction: setPlanBOption.fail }));
  }
}

function* getSurveyCompletingSaga({ payload }: ActionType<typeof getSurveysCompleting.request>) {
  try {
    const { data } = yield API.getSurveysCompleting({ ...payload });

    yield put(getSurveysCompleting.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: getSurveysCompleting.fail }));
  }
}

//survey completing

function* setSurveyCompetingTouchableOptionSaga({
  payload,
}: ActionType<typeof setSurveysCompletingTouchableOption.request>) {
  try {
    yield API.setSurveysCompletingTouchableOption(payload);
  } catch (error) {
    yield put(processRequestError({ error, failAction: setSurveysCompletingTouchableOption.fail }));
  }
}

function* setSurveyCompetingEOIOptionSaga({
  payload,
}: ActionType<typeof setSurveysCompletingEOIOption.request>) {
  try {
    yield API.setSurveysCompletingEOIOption(payload);
  } catch (error) {
    yield put(processRequestError({ error, failAction: setSurveysCompletingEOIOption.fail }));
  }
}

function* setSurveysCompletingOtherOptionSaga({
  payload,
}: ActionType<typeof setSurveysCompletingOtherOption.request>) {
  try {
    yield API.setSurveysCompletingOtherOption(payload);
  } catch (error) {
    yield put(processRequestError({ error, failAction: setSurveysCompletingOtherOption.fail }));
  }
}

function* setSurveyCompetingContractCommitmentSaga({
  payload,
}: ActionType<typeof setSurveysCompletingContractCommitmentOption.request>) {
  try {
    yield API.setSurveysCompletingContractCommitmentOption(payload);
  } catch (error) {
    yield put(
      processRequestError({ error, failAction: setSurveysCompletingContractCommitmentOption.fail }),
    );
  }
}

function* setSurveyCompetingAdditionalFieldsSaga({
  payload,
}: ActionType<typeof setSurveysCompletingAdditionalFields.request>) {
  try {
    yield API.setSurveysCompletingAdditionalFields(payload);
  } catch (error) {
    yield put(
      processRequestError({ error, failAction: setSurveysCompletingAdditionalFields.fail }),
    );
  }
}

function* setSurveyCompetingPlanBOptionSaga({
  payload,
}: ActionType<typeof setSurveyCompletingPlanBOption.request>) {
  try {
    yield API.setSurveyCompletingPlanBOption(payload);
  } catch (error) {
    yield put(processRequestError({ error, failAction: setSurveyCompletingPlanBOption.fail }));
  }
}

function* surveysCompletingSaveProgressSaga({
  payload,
}: ActionType<typeof surveysCompletingSaveProgress.request>) {
  try {
    yield API.surveysCompletingSaveProgress();

    yield put(surveysCompletingSaveProgress.success(payload));

    toast.success('Success');
  } catch (error) {
    yield put(processRequestError({ error, failAction: surveysCompletingSaveProgress.fail }));
  }
}

function* updateRealizedPriceSaga({ payload }: ActionType<typeof updateRealizedPrice.request>) {
  try {
    yield API.updateSecondFinalCustomerTargetsRealizePrice(payload);

    yield put(updateRealizedPrice.success());
  } catch (error) {
    yield put(processRequestError({ error, failAction: updateRealizedPrice.fail }));
  }
}

function* resetOverwriteImpactEffectSaga({
  payload,
}: ActionType<typeof resetOverwriteImpactEffect.request>) {
  try {
    yield API.resetOverwriteImpactEffect(payload);

    yield put(resetOverwriteImpactEffect.success());

    yield put(
      getCustomerTargets.request({
        data: { itemsOnPage: payload.rowPerPage, pageNumber: payload.page },
      }),
    );
  } catch (error) {
    yield put(processRequestError({ error, failAction: resetOverwriteImpactEffect.fail }));
  }
}

function* getSecondSurveySaga({ payload }: ActionType<typeof getSecondSurvey.request>) {
  try {
    const { surveyCompletingDate } = yield select((state: Store) => state.surveys);

    const { data } = yield API.getSecondSurveyData({ ...payload, date: surveyCompletingDate });

    yield put(getSecondSurvey.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: getSecondSurvey.fail }));
  }
}

function* saveSecondSurveyOneSaga({ payload }: ActionType<typeof saveSecondSurveyOne.request>) {
  try {
    yield API.saveSecondSurveyOne(payload);

    yield put(saveSecondSurveyOne.success());
  } catch (error) {
    yield put(processRequestError({ error, failAction: saveSecondSurveyOne.fail }));
  }
}

export function* watchSurveys() {
  yield takeLatest(getSurveysSettings.request, getSurveysSettingsSaga);
  yield takeLatest(updateSurveysSettings.request, updateSurveysSettingsSaga);
  yield takeLatest(
    deleteSurveyPriceIncreaseTimeLevelOption.request,
    deletePriceIncreaseTimeLevelOptionSaga,
  );
  yield takeLatest(deleteSurveyOtherModalOption.request, deleteSurveyOtherModalOptionSaga);
  yield takeLatest(deleteSurveySettingsOption.request, deleteSurveysOptionSaga);
  yield takeLatest(getSurveyResult.request, getSurveyResultSaga);
  yield takeLatest(setTouchableOption.request, setTouchableOptionSaga);
  yield takeLatest(setEOIOption.request, setEOIOptionSaga);
  yield takeLatest(setOtherOption.request, setOtherOptionSaga);
  yield takeLatest(setContractCommitment.request, setContractCommitmentSaga);
  yield takeLatest(setSurveyResultAdditionalFields.request, setSurveyResultAdditionalFieldsSaga);
  yield takeLatest(setPlanBOption.request, setPlanBOptionSaga);
  yield takeLatest(updateSurveySettingTouchable.request, updateSurveySettingTouchableSaga);
  yield takeLatest(surveySettingsCreateNewEOI.request, surveySettingsCreateEOISaga);
  yield takeLatest(createSurveySettingsOtherOption.request, createSurveySettingsOtherOptionSaga);
  yield takeLatest(updateSurveySettingsOtherOption.request, updateSurveySettingsOtherOptionSaga);
  yield takeLatest(surveySettingsUpdateEOIOption.request, surveySettingsUpdateEOIOptionSaga);
  yield takeLatest(surveySettingUpdateEOIModalInfo.request, surveySettingUpdateEOIModalInfoSaga);
  yield takeLatest(
    surveySettingUpdateOtherModalInfo.request,
    surveySettingUpdateOtherModalInfoSaga,
  );
  yield takeLatest(getSurveysCompleting.request, getSurveyCompletingSaga);
  // survey completing
  yield takeLatest(
    setSurveysCompletingAdditionalFields.request,
    setSurveyCompetingAdditionalFieldsSaga,
  );
  yield takeLatest(
    setSurveysCompletingContractCommitmentOption.request,
    setSurveyCompetingContractCommitmentSaga,
  );
  yield takeLatest(
    setSurveysCompletingTouchableOption.request,
    setSurveyCompetingTouchableOptionSaga,
  );
  yield takeLatest(setSurveysCompletingEOIOption.request, setSurveyCompetingEOIOptionSaga);
  yield takeLatest(setSurveysCompletingOtherOption.request, setSurveysCompletingOtherOptionSaga);
  yield takeLatest(setSurveyCompletingPlanBOption.request, setSurveyCompetingPlanBOptionSaga);
  yield takeLatest(surveysCompletingSaveProgress.request, surveysCompletingSaveProgressSaga);
  yield takeLatest(updateRealizedPrice.request, updateRealizedPriceSaga);
  yield takeLatest(resetOverwriteImpactEffect.request, resetOverwriteImpactEffectSaga);
  yield takeLatest(
    updateAdditionalFieldsOfSurveySettings.request,
    updateAdditionalFieldsOfSurveySettingsSaga,
  );
  yield takeLatest(checkSurveyResultFieldsAreFilled.request, checkSurveyResultSaga);
  yield takeLatest(
    surveyResultCopyOptionsFromFirstBUToOther.request,
    surveyResultCopyOptionsFromFirstBUToOtherSaga,
  );
  yield takeLatest(
    surveyResultCopyOptionsFromFirstBUToOtherAccountManager.request,
    surveyResultCopyOptionsFromFirstBUToOtherAccountManagerSaga,
  );
  yield takeLatest(getSecondSurvey.request, getSecondSurveySaga);
  yield takeLatest(saveSecondSurveyOne.request, saveSecondSurveyOneSaga);
  yield takeLatest(getSurveyResultAccess.request, getSurveyResultAccessSaga);
}

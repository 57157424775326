import { takeLatest, put } from 'redux-saga/effects';
import { API } from 'src/api';

import { processRequestError } from 'src/modules/Errors/actions';
import { ActionType } from 'deox';
import {
  addNewProject,
  getProjects,
  deleteProject,
  editProject,
  setCurrentProject,
} from './actions';
import { select } from 'typed-redux-saga';
import { Project, Store } from 'src/types';

function* getAllProjectsSaga({ payload }: ActionType<typeof getProjects.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.getAllProjects(payload);

    yield put(getProjects.success(data));

    const isCurrentProjectDeleted = data?.data?.every(
      (project: Project) => project.id !== projectId,
    );

    if (isCurrentProjectDeleted && data.data.length > 0) {
      yield put(setCurrentProject({ name: data.data[0].name, id: data.data[0].id }));
    }

    if (data.data.length === 0 && projectId !== 0) {
      yield put(setCurrentProject({ name: '', id: 0 }));
    }
  } catch (error) {
    yield put(processRequestError({ error, failAction: getProjects.fail }));
  }
}

function* addNewProjectSaga({ payload }: ActionType<typeof addNewProject.request>) {
  const { onSuccessCb, requestPayload } = payload;

  try {
    const { data } = yield API.addNewProject(requestPayload);

    yield put(addNewProject.success(data));

    onSuccessCb && onSuccessCb();
  } catch (error) {
    yield put(processRequestError({ error, failAction: addNewProject.fail }));
  }
}

function* deleteProjectSaga({ payload }: ActionType<typeof deleteProject.request>) {
  const { onSuccessCb, requestPayload } = payload;

  try {
    yield API.deleteProjectById(requestPayload);

    yield put(deleteProject.success({ projectId: requestPayload.projectId }));

    onSuccessCb && onSuccessCb();
  } catch (error) {
    yield put(processRequestError({ error, failAction: deleteProject.fail }));
  }
}

function* editProjectSaga({ payload }: ActionType<typeof editProject.request>) {
  const { onSuccessCb, requestPayload } = payload;

  try {
    yield API.editProject(requestPayload);

    yield put(
      editProject.success({
        id: requestPayload.id,
        newName: requestPayload.name,
      }),
    );

    onSuccessCb && onSuccessCb();
  } catch (error) {
    yield put(processRequestError({ error, failAction: editProject.fail }));
  }
}

export function* watchProjects() {
  yield takeLatest(getProjects.request, getAllProjectsSaga);
  yield takeLatest(addNewProject.request, addNewProjectSaga);
  yield takeLatest(deleteProject.request, deleteProjectSaga);
  yield takeLatest(editProject.request, editProjectSaga);
}

import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../../constants/COLORS';

const useStyles = makeStyles(() => ({
  statusContainer: {
    display: 'flex',
    columnGap: 10,
  },
  button: {
    width: 100,
    height: 32,
    backgroundColor: COLORS.white1,
    border: `1px solid ${COLORS.red1}`,
    color: COLORS.red1,
    fontSize: 14,
    transition: '0.3s ease',
    cursor: 'pointer',
    '&:disabled': {
      backgroundColor: COLORS.gray21,
      border: `1px solid ${COLORS.gray21}`,
      color: COLORS.red1,
      cursor: 'no-drop',
    },
    '&:disabled:hover': {
      backgroundColor: COLORS.gray21,
      color: COLORS.red1,
    },
    '&:hover': {
      backgroundColor: COLORS.red1,
      color: COLORS.white1,
    },
  },
}));

export default useStyles;

import React, { useCallback, useEffect } from 'react';
import { Stack } from '@mui/material';
import useStyles from './useStyles';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBusinessCaseAssumptions,
  updateBusinessCaseAssumption,
} from 'src/modules/specificTargets/actions';
import { Store } from 'src/types';
import { CaseItem } from './components/CaseItem';
import { useHorizontalScroll } from 'src/hooks/useHorizontalScroll';
import { useDebouncedCallback } from 'use-debounce';
import { useNavigate } from 'react-router-dom';

import { ButtonBase } from 'src/components/common/ButtonBase';
import { COLORS } from 'src/constants/COLORS';
import { Loader } from 'src/components/common/Loader';
import { NoDataPlaceholder } from 'src/components/common/NoDataPlaceholder';
import { ForbidEditCheckbox } from 'src/components/common/ForbidEditCheckbox';

import { ScreenNames } from 'src/constants/ScreenNames';
import { Stepper } from '../../../../common/Stepper';
import { StepNames } from '../../../../../constants/stepNames';
import { toast } from 'react-toastify';

export const BusinessCaseAssumptions: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const scrollRef = useHorizontalScroll();

  const { businessCaseAssumptions, loading } = useSelector((state: Store) => state.specificTargets);
  const { disableEditPage } = useSelector((state: Store) => state.home);

  const dispatch = useDispatch();

  const onUpdateBusinessCase = useDebouncedCallback((body) => {
    dispatch(updateBusinessCaseAssumption.request(body));
  }, 300);

  const columnGap = 60;
  const itemWidth = 330;
  const bottomLineWidth = businessCaseAssumptions.length * (columnGap + itemWidth);

  const goNext = useCallback(() => {
    if (businessCaseAssumptions.length > 0) {
      const hasEmptyFields = businessCaseAssumptions.some((item) => {
        if (item.priceElasticity === null) {
          return true;
        }

        if (
          item.priceDifferentialToTarget.goingInPrice === null ||
          item.priceDifferentialToTarget.escalationPrice === null ||
          item.priceDifferentialToTarget.walkAwayPrice === null
        ) {
          return true;
        }

        return (
          item.revenueShare.goingInPrice === null ||
          item.revenueShare.escalationPrice === null ||
          item.revenueShare.walkAwayPrice === null
        );
      });

      if (hasEmptyFields) {
        toast.warning('Some fields are empty');
        return;
      }

      navigate('/configure-levels/customer-specific-targets/target-finetuning');
    }
  }, [businessCaseAssumptions, navigate]);

  const goBack = useCallback(() => {
    navigate('/configure-levels/customer-specific-targets/eoi-weighting');
  }, [navigate]);

  useEffect(() => {
    dispatch(getBusinessCaseAssumptions.request());
  }, [dispatch]);

  return (
    <div className={classes.container}>
      <Stepper
        steps={[
          {
            name: 'Touchable revenue',
            activeIndex: null,
            allCompleted: true,
          },
          {
            name: 'Campaign target',
            activeIndex: null,
            allCompleted: true,
          },
          {
            name: 'Customer-specific targets',
            activeIndex: 1,
          },
        ]}
        type={StepNames.ALL}
      />
      {loading && (
        <div className={classes.loaderContainer}>
          <Loader isLoading={true} marginTop={40} />
        </div>
      )}

      {businessCaseAssumptions.length === 0 && (
        <NoDataPlaceholder text="No data yet. Please try again later." />
      )}

      <div ref={scrollRef} className={classes.casesContainer}>
        {businessCaseAssumptions.map((businessCase) => (
          <CaseItem
            disabledEdit={disableEditPage}
            onUpdateBusinessCase={onUpdateBusinessCase}
            key={businessCase.businessUnitId}
            {...businessCase}
          />
        ))}
        <div
          style={{ width: bottomLineWidth }}
          className={`${classes.bottomLine} ${disableEditPage && classes.bottomLineDisabled}`}
        />
      </div>

      <Stack spacing={6} direction="row" marginTop={10} justifyContent="space-between">
        <ForbidEditCheckbox
          showCheckbox={businessCaseAssumptions.length > 0}
          checkboxValue={disableEditPage}
          screenName={ScreenNames.BUSINESS_CASE_ASSUMPTIONS}
        />
        <Stack width="100%" direction="row" spacing={4} justifyContent="flex-end">
          <ButtonBase
            iconColor={COLORS.red1}
            variant="outlined"
            title="Indicator weighting"
            onClick={goBack}
            icon="goBack"
            iconLeft={true}
          />
          <ButtonBase
            iconColor={COLORS.red1}
            variant="contained"
            title="Target finetuning"
            onClick={goNext}
            icon="next"
          />
        </Stack>
      </Stack>
    </div>
  );
};

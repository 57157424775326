import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  input: {
    border: `1px solid ${COLORS.gray26}`,
    borderRadius: 8,
    height: '40px',
    width: '100%',
    outline: 'none',
    padding: '0 16px',
    fontSize: '14px',
    color: COLORS.black1,
    '&::placeholder': {
      color: COLORS.gray22,
    },
    '&:placeholder': {
      color: COLORS.gray25,
    },
    '&:hover': {
      borderColor: COLORS.blue2,
    },
    display: 'block',
    verticalAlign: 'top',
    borderColor: COLORS.black5,
    paddingLeft: 15,
    marginBottom: '20px',
  },
  container: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  deleteButton: {
    position: 'absolute',
    right: '0px',
    top: '12px',
  },
  listItemErrorIcon: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
  listItemErrorInput: {
    borderColor: COLORS.red1,
    boxShadow: `0px 0px 6px #FF5982`,
  },
  inputContainer: {
    position: 'relative',
    width: '90%',
  },
  inputDisabled: {
    borderColor: COLORS.gray3,
    backgroundColor: COLORS.white2,
    color: COLORS.gray3,
  },
}));

export default useStyles;

import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
  },
  tableRowBorder: {
    border: `1px solid ${COLORS.gray4}`,
  },
  loaderContainer: {
    display: 'flex',
    height: '40vh',
    width: '100%',
    justifyContent: 'center',
    zIndex: 2,
    position: 'absolute',
  },
  wrapper: {
    marginTop: '20px',
    overflowX: 'auto',
    overflowY: 'auto',
    height: 'calc(100vh - 410px)',
    boxShadow: '0px 2px 10px rgba(199, 201, 201, 0.5)',
  },
}));

export default useStyles;

import React from 'react';

const cleanPercentage = (percentage: number) => {
  const tooLow = !Number.isFinite(+percentage) || percentage < 0;
  const tooHigh = percentage > 100;
  return tooLow ? 0 : tooHigh ? 100 : +percentage;
};

const Circle = ({ color, pct }: { color: string; pct?: number }) => {
  const r = 17;
  const circ = 2 * Math.PI * r;

  let strokePct;

  if (pct) {
    strokePct = ((100 - pct) * circ) / 100;
  }

  return (
    <circle
      r={r}
      cx={25}
      cy={25}
      fill="transparent"
      stroke={strokePct !== circ ? color : ''} // remove colour as 0% sets full circumference
      strokeWidth={pct ? '10px' : '7px'}
      strokeDasharray={circ}
      strokeDashoffset={pct ? strokePct : 0}></circle>
  );
};

type Props = {
  percentage: number;
  color: string;
};

export const PieProgress: React.FC<Props> = ({ percentage, color }) => {
  const pct = cleanPercentage(percentage);
  return (
    <svg width={50} height={50}>
      <g transform={`rotate(-90 ${'25 25'})`}>
        <Circle color="#BDBDBD" />
        <Circle color={pct === 0 ? '#BDBDBD' : color} pct={pct} />
      </g>
    </svg>
  );
};

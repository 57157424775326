import React, { useCallback, useEffect, useState } from 'react';
import useStyles from './usStyles';
import { Box, Stack } from '@mui/material';
import { COLORS } from 'src/constants/COLORS';
import { useDispatch, useSelector } from 'react-redux';
import { getBUAbbreviations } from 'src/modules/specificTargets/actions';
import { getAccountManagerNames } from 'src/modules/TouchableRevenue/actions';
import { DateTimePicker } from 'src/components/common/DateTimePicker';

import { getNegotiationProcess } from 'src/modules/monitoring/actions';

import { Store } from 'src/types';
import { NegotiationProcessChart } from './components/NegotiationProcessChart';
import MultipleDropdown from 'src/components/common/MultipleDropdown';
import { getMultipleDropdownValues } from '../../../../../helpers/getMultipleDropdownValues';

export const NegotiationProcess: React.FC = () => {
  const [segment, setSegment] = useState(['All']);
  const [accountManager, setAccountManager] = useState(['All']);
  const [date, setDate] = useState<null | string>(null);

  //eslint-disable-next-line
  const handleSegmentChange = useCallback((event: any) => {
    getMultipleDropdownValues(event.target.value, setSegment);
  }, []);

  //eslint-disable-next-line
  const handleAccountManagerChange = useCallback((event: any) => {
    getMultipleDropdownValues(event.target.value, setAccountManager);
  }, []);

  const handeDateChange = useCallback((value) => {
    const index = value.indexOf('T');
    const newDate = value.slice(0, index);
    setDate(newDate);
  }, []);

  const {
    specificTargets: { BUAbbreviations },
    touchableRevenue: { accountManagerNames },
    monitoring: { negotiationProcess, loading },
  } = useSelector((state: Store) => state);

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBUAbbreviations.request());
    dispatch(getAccountManagerNames.request());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getNegotiationProcess.request({ segment, accountManager, targetLine: date }));
  }, [accountManager, date, dispatch, segment]);

  return (
    <div className={classes.container}>
      <Stack direction="row" columnGap="24px">
        <MultipleDropdown
          value={segment}
          array={['All', ...BUAbbreviations]}
          onChange={handleSegmentChange}
          label="Segments"
          dropdownArrowColor={COLORS.black1}
        />
        <MultipleDropdown
          value={accountManager}
          array={['All', ...accountManagerNames]}
          onChange={handleAccountManagerChange}
          label="Account manager"
          dropdownArrowColor={COLORS.black1}
        />
        {!loading && negotiationProcess && (
          <DateTimePicker
            label="Target line"
            onChangeCb={handeDateChange}
            defaultDate={date || negotiationProcess.targetLine}
            inputStyle={classes.datePicker}
          />
        )}
      </Stack>

      <Box>
        <NegotiationProcessChart loading={loading} data={negotiationProcess} />
      </Box>
    </div>
  );
};

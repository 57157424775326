import React, { FC } from 'react';
import Chart from './Chart';
import Fields from './Fields';
import useStyles from './useStyles';
import { PriceIncreaseBridgeResponse } from '../../../../../../types';

type PriceIncreaseBridgeProps = {
  priceIncreaseBridge: PriceIncreaseBridgeResponse;
  customerId?: string;
  isBattleCards: boolean;
};

const PriceIncreaseBridge: FC<PriceIncreaseBridgeProps> = ({
  priceIncreaseBridge,
  customerId,
  isBattleCards,
}) => {
  const classes = useStyles();

  const totalLength = [
    ...(priceIncreaseBridge?.breachOfContractTermOptions || []),
    ...(priceIncreaseBridge?.planBOptions || []),
  ].length;

  const getWidth = () => {
    if (totalLength > 9) {
      return 1700;
    }
    if (totalLength > 8) {
      return 1600;
    }
    if (totalLength > 7) {
      return 1500;
    }
    if (totalLength > 6) {
      return 1400;
    }
    if (totalLength > 5) {
      return 1300;
    }
    if (totalLength > 4) {
      return 1200;
    }
    return 'auto';
  };

  return (
    <div className={classes.container}>
      <div style={{ width: getWidth() }}>
        <Chart priceIncreaseBridge={priceIncreaseBridge} />
        <Fields
          priceIncreaseBridge={priceIncreaseBridge}
          customerId={customerId}
          isBattleCards={isBattleCards}
        />
      </div>
    </div>
  );
};

export default PriceIncreaseBridge;

import { createReducer } from 'deox';
import { produce } from 'immer';

import { ProjectsState } from './types';
import {
  addNewProject,
  getProjects,
  deleteProject,
  editProject,
  setCurrentProject,
} from './actions';

const initialState: ProjectsState = {
  projectId: 0,
  projectName: '',
  projects: [],
  loading: false,
  buttonLoading: false,
  pageable: null,
};

export const projectsReducer = createReducer(initialState, (handleAction) => [
  handleAction(getProjects.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(getProjects.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.projects = payload.data;
      draft.pageable = payload.pageable;
    }),
  ),
  handleAction(getProjects.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(addNewProject.request, (state) =>
    produce(state, (draft) => {
      draft.buttonLoading = true;
    }),
  ),
  handleAction(addNewProject.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.buttonLoading = false;
      draft.projects = [...state.projects, payload];
    }),
  ),
  handleAction(addNewProject.fail, (state) =>
    produce(state, (draft) => {
      draft.buttonLoading = false;
    }),
  ),
  //
  handleAction(deleteProject.request, (state) =>
    produce(state, (draft) => {
      draft.buttonLoading = true;
    }),
  ),
  handleAction(deleteProject.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.buttonLoading = false;
      draft.projects = state.projects.filter((project) => project.id !== payload.projectId);

      if (payload.projectId === state.projectId) {
        draft.projectId = 0;
        draft.projectName = '';
      }
    }),
  ),
  handleAction(deleteProject.fail, (state) =>
    produce(state, (draft) => {
      draft.buttonLoading = false;
    }),
  ),
  //
  handleAction(editProject.request, (state) =>
    produce(state, (draft) => {
      draft.buttonLoading = true;
    }),
  ),
  handleAction(editProject.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.buttonLoading = false;
      draft.projects = state.projects.map((project) =>
        project.id === payload.id
          ? {
              ...project,
              name: payload.newName,
            }
          : project,
      );
    }),
  ),
  handleAction(editProject.fail, (state) =>
    produce(state, (draft) => {
      draft.buttonLoading = false;
    }),
  ),
  handleAction(setCurrentProject, (state, { payload }) =>
    produce(state, (draft) => {
      draft.projectId = payload.id;
      draft.projectName = payload.name;
    }),
  ),
]);

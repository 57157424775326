import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../../../../constants/COLORS';

const useStyles = makeStyles(() => ({
  modalContainer: {
    backgroundColor: COLORS.white1,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '450px',
    minHeight: 600,
    maxHeight: 600,
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    border: 'none',
    padding: '24px 48px',
    outline: 'none',
  },
  title: {
    color: COLORS.black2,
    fontSize: 18,
    textAlign: 'center',
    fontWeight: 400,
  },
  frequencyContainer: {
    display: 'flex',
    alignItems: 'baseline',
    marginTop: 28,
    columnGap: 30,
    '& > div': {
      fontSize: 16,
      color: COLORS.black1,
    },
  },
  inputWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 32,
  },
  onTheWrapper: {
    display: 'flex',
    marginTop: 32,
    columnGap: 62,
  },
  monthlyDropdowns: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 20,
  },
  text: {
    fontSize: 16,
    color: COLORS.black1,
  },
  datePicker: {
    // border: `1px solid ${COLORS.gray11}`,
    // overflow: 'hidden',
    padding: '0 10px',
    width: 155,
    alignSelf: 'flex-end',
    marginLeft: 30,
    height: 40,
    '& input:focus': {
      // border: 'none',
    },
  },
  quantityInput: {
    marginLeft: 31,
    marginRight: 20,
    width: 115,
    border: `1px solid ${COLORS.gray11}`,
    height: '40px',
    outline: 'none',
    padding: '0 14px',
    fontSize: '16px',
    color: COLORS.black1,
    '&::placeholder': {
      color: COLORS.gray22,
    },
  },
  buttonContainer: {
    margin: '30px auto 0',
    '& button': {
      width: 130,
      fontWeight: 400,
    },
  },
  dropdownButton: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: COLORS.gray11,
      borderRadius: 0,
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: COLORS.black2,
    },
  },
  dropdownContainer: {
    width: 240,
  },
}));

export default useStyles;

import React, { useCallback } from 'react';
import useStyles from '../../../useStyles';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'src/types';

import { ReactComponent as CloseIcon } from 'src/assets/icons/Close.svg';
import { Stack } from '@mui/material';
import Modal from '@mui/material/Modal';
import { Item } from './Item';

import { ButtonBase } from 'src/components/common/ButtonBase';
import { TextButton } from '../../../../../../../common/TextButton';
import {
  deleteSurveyOtherModalOption,
  setSelectedOther,
  surveySettingUpdateOtherModalInfo,
} from '../../../../../../../../modules/surveys/actions';

type Props = {
  open: boolean;
  onCloseModal: () => void;
};

export const OtherOptionsDropdownModal: React.FC<Props> = ({ open, onCloseModal }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { selectedOther } = useSelector((state: Store) => state.surveys);

  const { updateSettingsLoading } = useSelector((state: Store) => state.surveys);

  const saveOther = useCallback(() => {
    if (selectedOther) {
      dispatch(
        surveySettingUpdateOtherModalInfo.request({
          requestPayload: {
            params: {
              optionId: selectedOther.id,
            },
            body: selectedOther.items.map(
              // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
              ({ temporaryId, ...keepAttrs }) => {
                return keepAttrs;
              },
            ),
          },
          onSuccessCb: () => {
            onCloseModal();
          },
        }),
      );
    }
  }, [dispatch, onCloseModal, selectedOther]);

  const onAddNewItem = useCallback(() => {
    if (selectedOther) {
      dispatch(
        setSelectedOther({
          ...selectedOther,
          items: [
            ...selectedOther.items,
            {
              name: '',
              temporaryId: Math.floor(Math.random() * 1000),
            },
          ],
        }),
      );
    }
  }, [dispatch, selectedOther]);

  const onChangeName = useCallback(
    (value: string, id: number) => {
      if (selectedOther) {
        dispatch(
          setSelectedOther({
            ...selectedOther,
            items: selectedOther.items.map((item) => {
              if (item.id && item.id === id) {
                return {
                  ...item,
                  name: value,
                };
              } else if (item.id === undefined && id === item.temporaryId) {
                return {
                  ...item,
                  name: value,
                };
              }

              return item;
            }),
          }),
        );
      }
    },
    [dispatch, selectedOther],
  );

  const deleteItem = useCallback(
    ({ id, temporaryId }) => {
      dispatch(
        deleteSurveyOtherModalOption.request({
          itemId: selectedOther?.id,
          id,
          temporaryId,
        }),
      );
    },
    [dispatch],
  );

  return (
    <div>
      <Modal open={open} onClose={onCloseModal}>
        <div className={classes.modalContainer}>
          <IconButton
            onClick={onCloseModal}
            aria-label="delete"
            sx={{ display: 'flex', alignSelf: 'flex-end' }}>
            <CloseIcon width={16} height={16} />
          </IconButton>
          <p className={classes.modalTitle}>{selectedOther && selectedOther.option}</p>
          {selectedOther &&
            selectedOther.items.map((item) => (
              <Item
                deleteItem={deleteItem}
                onChangeNameCb={onChangeName}
                key={item.id || item.temporaryId}
                {...item}
              />
            ))}
          <Stack marginTop={1} alignItems="center">
            <TextButton onClick={onAddNewItem} title="Add item" />
          </Stack>
          <Stack marginTop={6} alignItems="center">
            <ButtonBase
              onClick={saveOther}
              isLoading={updateSettingsLoading}
              variant="contained"
              title="Save"
            />
          </Stack>
        </div>
      </Modal>
    </div>
  );
};

import React, { memo, useCallback, useContext, useMemo } from 'react';
import {
  ChosenEaseOfIncreaseOption,
  EaseOfIncreaseOption,
  SurveyResultSetEOIOptionRequestBody,
} from 'src/types';
import { Dropdown } from 'src/components/common/Dropdown';
import useStyles from '../TableBodyRow/useStyles';
import { EmptyCell } from '../EmptyCell';
import { SurveyResultProvider, SurveyResultProviderTypes } from '../../SurveyResultProvider';
import { COLORS } from '../../../../../../../constants/COLORS';

type Props = {
  chosenEaseOfIncreaseOptions: ChosenEaseOfIncreaseOption[];
  eoiOptions: EaseOfIncreaseOption[];
  setEOIOption: (body: SurveyResultSetEOIOptionRequestBody) => void;
  customerId: number;
  businessUnitId?: number;
  isDisabled?: boolean;
  isEven: boolean;
  isGroup: boolean;
};

export const EOIColumns: React.FC<Props> = memo(function EOIColumns({
  chosenEaseOfIncreaseOptions,
  eoiOptions,
  customerId,
  setEOIOption,
  isDisabled,
  businessUnitId,
  isEven,
  isGroup,
}) {
  const classes = useStyles();

  const { surveyResultEOIOptions, disableEditPage } = useContext(
    SurveyResultProvider,
  ) as SurveyResultProviderTypes;

  const onSelectEOIOption = useCallback(
    (priceIncreaseTimeLevelId, driverId) => {
      if (businessUnitId) {
        setEOIOption({
          businessUnitData: {
            priceIncreaseTimeLevelId: Number(priceIncreaseTimeLevelId),
            driverId,
            customerId,
            businessUnitId,
            isGroup,
          },
        });
      } else {
        setEOIOption({
          customerData: {
            priceIncreaseTimeLevelId: Number(priceIncreaseTimeLevelId),
            driverId,
            customerId,
            isGroup,
          },
        });
      }
    },
    [businessUnitId, customerId, isGroup, setEOIOption],
  );

  const ListOfEOIDropdowns = useMemo(() => {
    if (!isDisabled) {
      return chosenEaseOfIncreaseOptions.map((option, index, array) => {
        const foundedEolOptions = eoiOptions.find((item) => item.driverId === option.driverId);

        if (foundedEolOptions) {
          return (
            <td
              style={{
                backgroundColor: isEven ? COLORS.white1 : COLORS.blue13,
                borderRight: index === array.length - 1 ? `1px solid ${COLORS.gray3}` : '',
              }}
              key={option.driverId}>
              <Dropdown
                disabled={disableEditPage}
                onSelectCb={(priceIncreaseTimeLevelId) => {
                  onSelectEOIOption(priceIncreaseTimeLevelId, option.driverId);
                }}
                id={option.driverId.toString()}
                dropdownItems={[]}
                dropdownItemsWithObjects={foundedEolOptions.priceIncreaseTimeLevelOptions.map(
                  (item) => ({
                    value: item.priceIncreaseTimeLevel,
                    id: item.priceIncreaseTimeLevelId,
                  }),
                )}
                placeholder="Please select"
                defaultItem={option.priceIncreaseTimeLevelName}
                dropdownListStyles={classes.dropdownList}
                width={'100%'}
                height={40}
              />
            </td>
          );
        }
      });
    } else {
      return surveyResultEOIOptions.map((item) => (
        <EmptyCell isEven={isEven} key={item.driverId}>
          Empty
        </EmptyCell>
      ));
    }
  }, [
    chosenEaseOfIncreaseOptions,
    classes.dropdownList,
    disableEditPage,
    eoiOptions,
    isDisabled,
    isEven,
    onSelectEOIOption,
    surveyResultEOIOptions,
  ]);

  return <>{ListOfEOIDropdowns}</>;
});

import { createAction } from 'deox';
import {
  DeleteCostDriverRequestParams,
  DeletePricePotentialCategory,
  GetPricePotentialMatrixResponse,
  GetPricePotentialResponse,
  GetTargetSettingsResponse,
  GetTargetsForBusinessUnitsResponse,
  SetTargetForBusinessUnitsRequestBody,
  SetTargetForBusinessUnitsResponse,
  UpdateAdditionalFieldsOnMatrix,
  UpdatePricePotentialAdditionalFieldsRequest,
  UpdatePricePotentialCostDrivers,
  UpdatePricePotentialMatrixIndicator,
  UpdatePricePotentialMatrixResponse,
  UpdatePricePotentialMatrixWeights,
  UpdatePricePotentialRequestBody,
  UpdatePricePotentialResponse,
  UpdatePricePotentialsMatrixWeight,
  UpdateTargetForTargetSettings,
  UpdateTargetForTargetSettingsResponse,
} from 'src/types';
import { ResponseError } from 'src/modules/Errors/actions';

export const getTargetForBusinessUnits = {
  request: createAction('campaignTarget/GET_TARGETS_FOR_BUSINESS_UNITS_REQUEST'),
  success: createAction(
    'campaignTarget/GET_TARGETS_FOR_BUSINESS_UNITS_SUCCESS',
    (resolve) => (payload: GetTargetsForBusinessUnitsResponse) => resolve(payload),
  ),
  fail: createAction(
    'campaignTarget/GET_TARGETS_FOR_BUSINESS_UNITS_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const setTargetForBusinessUnits = {
  request: createAction(
    'campaignTarget/SET_TARGETS_FOR_BUSINESS_UNITS_REQUEST',
    (resolve) => (payload: SetTargetForBusinessUnitsRequestBody) => resolve(payload),
  ),
  success: createAction(
    'campaignTarget/SET_TARGETS_FOR_BUSINESS_UNITS_SUCCESS',
    (resolve) => (payload: SetTargetForBusinessUnitsResponse) => resolve(payload),
  ),
  fail: createAction(
    'campaignTarget/SET_TARGETS_FOR_BUSINESS_UNITS_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const setEmptyFieldForTargetGiven = createAction(
  'campaignTarget/SET_EMPTY_FIELD_FOR_TARGET_GIVEN',
  (resolve) => (payload: { targetId: number }) => resolve(payload),
);

export const getPricePotential = {
  request: createAction('campaignTarget/GET_PRICE_POTENTIAL_REQUEST', (resolve) => () => resolve()),
  success: createAction(
    'campaignTarget/GET_PRICE_POTENTIAL_SUCCESS',
    (resolve) => (payload: GetPricePotentialResponse) => resolve(payload),
  ),
  fail: createAction(
    'campaignTarget/GET_PRICE_POTENTIAL_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const updatePricePotentialCategories = {
  request: createAction(
    'campaignTarget/UPDATE_PRICE_POTENTIAL_CATEGORIES_REQUEST',
    (resolve) => (payload: UpdatePricePotentialRequestBody) => resolve(payload),
  ),
  success: createAction(
    'campaignTarget/UPDATE_PRICE_POTENTIAL_CATEGORIES_SUCCESS',
    (resolve) => (payload: UpdatePricePotentialResponse) => resolve(payload),
  ),
  fail: createAction(
    'campaignTarget/UPDATE_PRICE_POTENTIAL_CATEGORIES_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const editPricePotentialIndicator = {
  request: createAction(
    'campaignTarget/EDIT_PRICE_POTENTIAL_INDICATOR_REQUEST',
    (resolve) => (payload: UpdatePricePotentialRequestBody) => resolve(payload),
  ),
  success: createAction(
    'campaignTarget/EDIT_PRICE_POTENTIAL_INDICATOR_SUCCESS',
    (resolve) => (payload: UpdatePricePotentialResponse) => resolve(payload),
  ),
  fail: createAction(
    'campaignTarget/EDIT_PRICE_POTENTIAL_INDICATOR_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const updateCostDriver = {
  request: createAction(
    'campaignTarget/UPDATE_COST_DRIVER_REQUEST',
    (resolve) =>
      (payload: { requestBody: UpdatePricePotentialRequestBody; needUpdateReducer?: boolean }) =>
        resolve(payload),
  ),
  success: createAction(
    'campaignTarget/UPDATE_COST_DRIVER_SUCCESS',
    (resolve) => (payload: UpdatePricePotentialResponse) => resolve(payload),
  ),
  fail: createAction(
    'campaignTarget/UPDATE_COST_DRIVER_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const deleteCostDriver = {
  request: createAction(
    'campaignTarget/DELETE_COST_DRIVER_REQUEST',
    (resolve) => (payload: DeleteCostDriverRequestParams) => resolve(payload),
  ),
  success: createAction(
    'campaignTarget/DELETE_COST_DRIVER_SUCCESS',
    (resolve) => (payload: DeleteCostDriverRequestParams) => resolve(payload),
  ),
  fail: createAction(
    'campaignTarget/DELETE_COST_DRIVER_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const deletePricePotentialCategory = {
  request: createAction(
    'campaignTarget/DELETE_PRICE_POTENTIAL_CATEGORY_REQUEST',
    (resolve) => (payload: DeletePricePotentialCategory) => resolve(payload),
  ),
  success: createAction(
    'campaignTarget/DELETE_PRICE_POTENTIAL_CATEGORY_SUCCESS',
    (resolve) => (payload: DeletePricePotentialCategory) => resolve(payload),
  ),
  fail: createAction(
    'campaignTarget/DELETE_PRICE_POTENTIAL_CATEGORY_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const getApproachForCampaignTarget = {
  request: createAction(
    'campaignTarget/GET_APPROACH_FRO_CAMPAIGN_TARGET_REQUEST',
    (resolve) => () => resolve(),
  ),
  success: createAction(
    'campaignTarget/GET_APPROACH_FRO_CAMPAIGN_TARGET_SUCCESS',
    (resolve) => (payload: string) => resolve(payload),
  ),
  fail: createAction(
    'campaignTarget/GET_APPROACH_FRO_CAMPAIGN_TARGET_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const deletePricePotentialIndicator = {
  request: createAction(
    'campaignTarget/DELETE_PRICE_POTENTIAL_INDICATOR_REQUEST',
    (resolve) => (payload: { indicatorId: number; categoryId: number }) => resolve(payload),
  ),
  success: createAction(
    'campaignTarget/DELETE_PRICE_POTENTIAL_INDICATOR_SUCCESS',
    (resolve) => (payload: { indicatorId: number; categoryId: number }) => resolve(payload),
  ),
  fail: createAction(
    'campaignTarget/DELETE_PRICE_POTENTIAL_INDICATOR_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const getPricePotentialMatrix = {
  request: createAction(
    'campaignTarget/GET_PRICE_POTENTIAL_MATRIX_REQUEST',
    (resolve) => () => resolve(),
  ),
  success: createAction(
    'campaignTarget/GET_PRICE_POTENTIAL_MATRIX_SUCCESS',
    (resolve) => (payload: GetPricePotentialMatrixResponse) => resolve(payload),
  ),
  fail: createAction(
    'campaignTarget/GET_PRICE_POTENTIAL_MATRIX_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const updatePricePotentialAdditionalFields = {
  request: createAction(
    'campaignTarget/UPDATE_PRICE_POTENTIAL_ADDITIONAL_FIELDS_REQUEST',
    (resolve) => (payload: UpdatePricePotentialAdditionalFieldsRequest) => resolve(payload),
  ),
  success: createAction(
    'campaignTarget/UPDATE_PRICE_POTENTIAL_ADDITIONAL_FIELDS_SUCCESS',
    (resolve) =>
      (payload: {
        responseData: UpdateAdditionalFieldsOnMatrix;
        requestPayload: {
          minScore?: number;
          maxScore?: number;
          minRange?: number;
          maxRange?: number;
        };
      }) =>
        resolve(payload),
  ),
  fail: createAction(
    'campaignTarget/UPDATE_PRICE_POTENTIAL_ADDITIONAL_FIELDS_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const updatePricePotentialCostDriver = {
  request: createAction(
    'campaignTarget/UPDATE_PRICE_POTENTIAL_COST_DRIVER_REQUEST',
    (resolve) => (payload: UpdatePricePotentialCostDrivers) => resolve(payload),
  ),
  success: createAction(
    'campaignTarget/UPDATE_PRICE_POTENTIAL_COST_DRIVER_SUCCESS',
    (resolve) => (payload: UpdatePricePotentialMatrixResponse) => resolve(payload),
  ),
  fail: createAction(
    'campaignTarget/UPDATE_PRICE_POTENTIAL_COST_DRIVER_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const updatePricePotentialMatrixIndicator = {
  request: createAction(
    'campaignTarget/UPDATE_PRICE_POTENTIAL_MATRIX_INDICATOR_REQUEST',
    (resolve) => (payload: UpdatePricePotentialMatrixIndicator) => resolve(payload),
  ),
  success: createAction(
    'campaignTarget/UPDATE_PRICE_POTENTIAL_MATRIX_INDICATOR_SUCCESS',
    (resolve) => (payload: UpdatePricePotentialMatrixResponse) => resolve(payload),
  ),
  fail: createAction(
    'campaignTarget/UPDATE_PRICE_POTENTIAL_MATRIX_INDICATOR_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const updatePricePotentialMatrixWeight = {
  request: createAction(
    'campaignTarget/UPDATE_PRICE_POTENTIAL_MATRIX_WEIGHT_REQUEST',
    (resolve) => (payload: UpdatePricePotentialMatrixWeights) => resolve(payload),
  ),
  success: createAction(
    'campaignTarget/UPDATE_PRICE_POTENTIAL_MATRIX_WEIGHT_SUCCESS',
    (resolve) =>
      (payload: {
        responseData: UpdatePricePotentialsMatrixWeight;
        requestParams: UpdatePricePotentialMatrixWeights;
      }) =>
        resolve(payload),
  ),
  fail: createAction(
    'campaignTarget/UPDATE_PRICE_POTENTIAL_MATRIX_WEIGHT_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const getTargetSettings = {
  request: createAction('campaignTarget/GET_TARGET_SETTINGS_REQUEST', (resolve) => () => resolve()),
  success: createAction(
    'campaignTarget/GET_TARGET_SETTINGS_SUCCESS',
    (resolve) => (payload: GetTargetSettingsResponse) => resolve(payload),
  ),
  fail: createAction(
    'campaignTarget/GET_TARGET_SETTINGS_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const updateTargetForTargetSettings = {
  request: createAction(
    'campaignTarget/UPDATE_TARGET_FOR_TARGET_SETTINGS_REQUEST',
    (resolve) => (payload: UpdateTargetForTargetSettings) => resolve(payload),
  ),
  success: createAction(
    'campaignTarget/UPDATE_TARGET_FOR_TARGET_SETTINGS_SUCCESS',
    (resolve) => (payload: UpdateTargetForTargetSettingsResponse) => resolve(payload),
  ),
  fail: createAction(
    'campaignTarget/UPDATE_TARGET_FOR_TARGET_SETTINGS_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const updateApproachCampaignTarget = {
  request: createAction(
    'campaignTarget/UPDATE_APPROACH_CAMPAIGN_TARGET_REQUEST',
    (resolve) => (payload: { approach: string }) => resolve(payload),
  ),
  success: createAction(
    'campaignTarget/UPDATE_APPROACH_CAMPAIGN_TARGET_SUCCESS',
    (resolve) => (payload: { approach: string }) => resolve(payload),
  ),
  fail: createAction(
    'campaignTarget/UPDATE_APPROACH_CAMPAIGN_TARGET_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

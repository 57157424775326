import React, { memo, useCallback, useMemo } from 'react';
import { AccountManager, UpdateAccountManagersTable } from 'src/types';

import useStyles from '../../../BusinessUnits/components/BusinessUnitsTable/useStyles';
import { useDispatch } from 'react-redux';

import { ReactComponent as SortIcon } from 'src/assets/icons/sort.svg';
import { EditableCell } from 'src/components/common/EditableCell';
import { GrowWrapperComponent } from 'src/components/common/GrowWrapperComponent';

import { updateAccountMangersTable } from 'src/modules/TouchableRevenue/actions';

type Props = {
  data: AccountManager[];
  disableEditing: boolean;
};

export const AccountManagersTable: React.FC<Props> = memo(function AccountManagersTable({
  data,
  disableEditing,
}) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const onUpdateCell = useCallback(
    (values: UpdateAccountManagersTable) => {
      dispatch(updateAccountMangersTable.request(values));
    },
    [dispatch],
  );

  const accountManagersTableHeaders = useMemo(
    () => [
      {
        id: 0,
        title: 'Account manager',
      },
      {
        id: 1,
        title: 'Email',
      },
      {
        id: 2,
        title: 'Group',
      },
    ],
    [],
  );

  return (
    <GrowWrapperComponent>
      <div className={classes.wrapper}>
        <table className={classes.table}>
          <thead>
            <tr>
              {accountManagersTableHeaders.map((item) => (
                <th key={item.id}>
                  <div className={classes.tableHeaderCell}>
                    {item.title}
                    <SortIcon />
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row) => (
              <tr key={row.id}>
                <EditableCell
                  disabled={disableEditing}
                  onEditCb={(value) => {
                    onUpdateCell({
                      id: row.id,
                      name: value.trim(),
                    });
                  }}
                  editable
                  value={row.name}
                />
                <EditableCell
                  disabled={disableEditing}
                  onEditCb={(value) => {
                    onUpdateCell({
                      id: row.id,
                      email: value.trim(),
                    });
                  }}
                  editable
                  value={row.email}
                />
                <EditableCell
                  disabled={disableEditing}
                  onEditCb={(value) => {
                    onUpdateCell({
                      id: row.id,
                      group: value.trim(),
                    });
                  }}
                  editable
                  value={row.group}
                />
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </GrowWrapperComponent>
  );
});

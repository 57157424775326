import React, { FC } from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';
import useStyles from './useStyles';
import { COLORS } from 'src/constants/COLORS';
import { DROPDOWN_MENU_MAX_HEIGHT } from 'src/constants';
import { ReactComponent as ChevronDown } from '../../../assets/icons/chevron-down.svg';

type MultipleDropdownProps = {
  value: string[];
  //eslint-disable-next-line
  onChange?: (e: any) => void;
  array: string[];
  label: string;
  disabled?: boolean;
  dropdownArrowColor: string;
};

const MultipleDropdown: FC<MultipleDropdownProps> = ({
  value,
  onChange,
  array,
  label,
  disabled,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.multipleDropdown}>
      {label && <div className={classes.label}>{label}</div>}
      <FormControl style={{ width: 220 }} classes={{ root: `${classes.root}` }}>
        <Select
          sx={{ height: 40 }}
          value={value}
          onChange={onChange}
          multiple
          IconComponent={(props) => {
            return <ChevronDown {...props} fill={disabled ? COLORS.gray23 : COLORS.gray26} />;
          }}
          MenuProps={{
            classes: { paper: `${classes.select}` },
            PaperProps: {
              style: {
                maxHeight: DROPDOWN_MENU_MAX_HEIGHT,
                borderRadius: 8,
              },
            },
          }}>
          {array.map((item) => {
            return (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default MultipleDropdown;

import React from 'react';
import { Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';

export const PrepareImplementationLayout: React.FC = () => {
  return (
    <Grid>
      <Outlet />
    </Grid>
  );
};

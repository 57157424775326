import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../constants/COLORS';

const useStyles = makeStyles(() => ({
  comment: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      marginLeft: 10,
      cursor: 'pointer',
    },
    position: 'relative',
  },
  commentDisplay: {
    width: 140,
    overflowWrap: 'break-word',
    fontSize: 16,
    lineHeight: '19px',
  },
  commentEdit: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& textarea': {
      border: `1px solid ${COLORS.gray21}`,
      outline: 'none',
      maxWidth: 139,
      height: 55,
      fontSize: 15,
      resize: 'none',
    },
  },
  checkCircle: {
    '& svg': {
      borderRadius: '50%',
      backgroundColor: COLORS.green4,
    },
  },
}));

export default useStyles;

import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  input: {
    border: `1px solid ${COLORS.gray16}`,
    borderRadius: 4,
    fontSize: 14,
    padding: '0 10px',
    width: 260,
    height: 40,
    outline: 'none',
    backgroundColor: COLORS.white1,
  },
  labelError: {
    color: 'red',
    fontSize: 12,
  },
}));

export default useStyles;

import React from 'react';

import { Box } from '@mui/material';
import useStyles from './useStyles';

type Props = {
  text?: string;
  height?: string;
};

export const NoDataPlaceholder: React.FC<Props> = ({
  text = 'Please complete survey.',
  height = '400px',
}) => {
  const classes = useStyles();

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%%',
        height,
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <p className={classes.title}>{text}</p>
    </Box>
  );
};

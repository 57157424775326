import React from 'react';
import {
  AreaChart,
  Area,
  CartesianGrid,
  ResponsiveContainer,
  LabelList,
  Tooltip,
  LabelProps,
} from 'recharts';
import { useSelector } from 'react-redux';
import { Store } from '../../../../../../types';
import useStyles from './useStyles';

const BreakdownChart = () => {
  const classes = useStyles();

  const {
    monitoring: { performanceBreakdown },
  } = useSelector((state: Store) => state);

  const { percentageCustomers, percentageRevenue } = performanceBreakdown;

  const data = [
    {
      name: 'Percentage customers',
      ['walk-away range']: percentageCustomers?.walkAwayRange,
      ['escalation range']: percentageCustomers?.escalationRange,
      ['target range']: percentageCustomers?.targetRange,
      ['going-in range']: percentageCustomers?.goingInRange,
    },
    {
      name: 'Percentage customers',
      ['walk-away range']: percentageCustomers?.walkAwayRange,
      ['escalation range']: percentageCustomers?.escalationRange,
      ['target range']: percentageCustomers?.targetRange,
      ['going-in range']: percentageCustomers?.goingInRange,
    },
    {
      name: 'Percentage revenue',
      ['walk-away range']: percentageRevenue?.walkAwayRange,
      ['escalation range']: percentageRevenue?.escalationRange,
      ['target range']: percentageRevenue?.targetRange,
      ['going-in range']: percentageRevenue?.goingInRange,
    },
    {
      name: 'Percentage revenue',
      ['walk-away range']: percentageRevenue?.walkAwayRange,
      ['escalation range']: percentageRevenue?.escalationRange,
      ['target range']: percentageRevenue?.targetRange,
      ['going-in range']: percentageRevenue?.goingInRange,
    },
  ];

  const maxLabelValue = 4;
  const percentageRevenueDx = 435;
  const percentageCustomersDx = 90;

  const getDyValue = (value: number) => {
    if (value < 6) {
      return '4%';
    }
    return `${value / 2}%`;
  };

  const customLabelWalkAway = (entry: any) => {
    const { value, y } = entry;
    if (value > 0) {
      return (
        <g>
          {percentageCustomers?.walkAwayRange === value && value > maxLabelValue && (
            <text
              fontWeight={700}
              y={y}
              fill="#fff"
              textAnchor="middle"
              dx={percentageCustomersDx}
              dy={getDyValue(value)}>
              {`${value}%`}
            </text>
          )}
          {percentageRevenue?.walkAwayRange === value && value > maxLabelValue && (
            <text
              fontWeight={700}
              y={y}
              fill="#fff"
              textAnchor="middle"
              dx={percentageRevenueDx}
              dy={getDyValue(value)}>
              {`${value}%`}
            </text>
          )}
        </g>
      );
    }
    return <></>;
  };

  const customLabelEscalationRange = (entry: any) => {
    const { value, y } = entry;

    if (value > 0) {
      return (
        <g>
          {percentageCustomers?.escalationRange === value && value > maxLabelValue && (
            <text
              fontWeight={700}
              y={y}
              fill="#fff"
              textAnchor="middle"
              dx={percentageCustomersDx}
              dy={getDyValue(value)}>
              {`${value}%`}
            </text>
          )}
          {percentageRevenue?.escalationRange === value && value > maxLabelValue && (
            <text
              fontWeight={700}
              y={y}
              fill="#fff"
              textAnchor="middle"
              dx={percentageRevenueDx}
              dy={getDyValue(value)}>
              {`${value}%`}
            </text>
          )}
        </g>
      );
    }
    return <></>;
  };

  const customLabelTargetRange = (entry: any) => {
    const { value, y } = entry;

    if (value > 0) {
      return (
        <g>
          {percentageCustomers?.targetRange === value && value > maxLabelValue && (
            <text
              fontWeight={700}
              y={y}
              fill="#fff"
              textAnchor="middle"
              dx={percentageCustomersDx}
              dy={getDyValue(value)}>
              {`${value}%`}
            </text>
          )}
          {percentageRevenue?.targetRange === value && value > maxLabelValue && (
            <text
              fontWeight={700}
              y={y}
              fill="#fff"
              textAnchor="middle"
              dx={percentageRevenueDx}
              dy={getDyValue(value)}>
              {`${value}%`}
            </text>
          )}
        </g>
      );
    }
    return <></>;
  };

  const customLabelGoingInRange = (entry: LabelProps) => {
    const { value, y } = entry;
    if (value && value > 0) {
      return (
        <g>
          {percentageCustomers?.goingInRange === value && value > maxLabelValue && (
            <text
              fontWeight={700}
              y={y}
              fill="#fff"
              textAnchor="middle"
              dx={percentageCustomersDx}
              dy={getDyValue(value)}>
              {`${value}%`}
            </text>
          )}
          {percentageRevenue?.goingInRange === value && value > maxLabelValue && (
            <text
              fontWeight={700}
              y={y}
              fill="#fff"
              textAnchor="middle"
              dx={percentageRevenueDx}
              dy={getDyValue(value)}>
              {`${value}%`}
            </text>
          )}
        </g>
      );
    }
    return <></>;
  };
  //eslint-disable-next-line
  const CustomTooltip = (props: any) => {
    const { active, payload } = props;

    if (active && payload && payload.length) {
      return (
        <div className={classes.customTooltip}>
          <div className={classes.customTooltipName}>{payload[0]?.payload?.name}</div>
          {payload?.map((el: any) => {
            if (el.value > 0) {
              return (
                <div key={el.name} className={classes.customTooltipItem}>
                  <div className={classes.tooltipText}>{`${el.name}:`}</div>
                  <div>{`${el.value}%`}</div>
                </div>
              );
            }
          })}
        </div>
      );
    }
    return null;
  };

  const legendData = [
    {
      backgroundColor: '#006B92',
      name: 'Going in range',
      value: +percentageCustomers?.goingInRange || +percentageRevenue?.goingInRange,
    },
    {
      backgroundColor: '#E5BF18',
      name: 'Target range',
      value: +percentageCustomers?.targetRange || +percentageRevenue?.targetRange,
    },
    {
      backgroundColor: '#992C2C',
      name: 'Escalation range',
      value: +percentageCustomers?.escalationRange || +percentageRevenue?.escalationRange,
    },
    {
      backgroundColor: '#E3834D',
      name: 'Walk-away range',
      value: +percentageCustomers?.walkAwayRange || +percentageRevenue?.walkAwayRange,
    },
  ];

  return (
    <div className={classes.breakdownChart}>
      <div className={classes.chartContainer}>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            width={500}
            height={450}
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}>
            <Tooltip content={<CustomTooltip />} />
            <Area
              isAnimationActive={false}
              type="monotone"
              dataKey={'walk-away range'}
              stackId="1"
              // stroke="#E3834D"
              fill="#E3834D"
              fillOpacity={1}>
              <LabelList dataKey={'walk-away range'} content={customLabelWalkAway} />
              <LabelList dataKey={'walk-away range'} content={customLabelWalkAway} />
            </Area>
            <Area
              isAnimationActive={false}
              type="monotone"
              dataKey={'escalation range'}
              stackId="1"
              // stroke="#992C2C"
              fill="#992C2C"
              fillOpacity={1}>
              <LabelList dataKey={'escalation range'} content={customLabelEscalationRange} />
              <LabelList dataKey={'escalation range'} content={customLabelEscalationRange} />
            </Area>
            <Area
              isAnimationActive={false}
              type="monotone"
              dataKey={'target range'}
              stackId="1"
              // stroke="#E5BF18"
              fill="#E5BF18"
              fillOpacity={1}>
              <LabelList dataKey={'target range'} content={customLabelTargetRange} />
              <LabelList dataKey={'target range'} content={customLabelTargetRange} />
            </Area>
            <Area
              isAnimationActive={false}
              type="monotone"
              dataKey={'going-in range'}
              stackId="1"
              // stroke="#006B92"
              fill="#006B92"
              fillOpacity={1}>
              <LabelList dataKey={'going-in range'} content={customLabelGoingInRange} />
              <LabelList dataKey={'going-in range'} content={customLabelGoingInRange} />
            </Area>
            <LabelList dataKey={'going-in range'} position="top" />
            <CartesianGrid horizontal={false} stroke="#fff" strokeWidth={1} />
          </AreaChart>
        </ResponsiveContainer>
        <div className={classes.textCustomers}>Percentage customers</div>
        <div className={classes.textRevenues}>Percentage revenue</div>
        {/*<div className={classes.line} />*/}
      </div>
      <div className={classes.chartLegend}>
        {legendData.map((item) => {
          if (item.value > 0) {
            return (
              <div key={item.name} className={classes.chartLegendItem}>
                <div
                  className={classes.chartLegendItemSquare}
                  style={{ backgroundColor: item.backgroundColor }}
                />
                <div>{item.name}</div>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default BreakdownChart;

import React, { memo, FC } from 'react';
import useStyles from './useStyles';
import { Typography } from '@mui/material';

import whiteEllipse from 'src/assets/images/whiteEllipse.png';
import whiteEllipseReversed from 'src/assets/images/whiteEllipseReversed.png';

import { ReactComponent as YAxisIconNew } from 'src/assets/icons/yAxisNew.svg';
import { ReactComponent as WhiteArrowRight } from 'src/assets/icons/white-arrow-right.svg';

type Props = {
  title: string;
  textInsideCircle: string;
  isRight?: boolean;
  bottomText: string;
};

export const ConcessionBox: FC<Props> = memo(function ConcessionBox({
  title,
  textInsideCircle,
  isRight,
  bottomText,
}) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography sx={{ marginBottom: 3, fontSize: 16 }} variant="h5">
        {title}
      </Typography>
      <div className={classes.boxContainer}>
        <div style={{ backgroundColor: '#4C97B2' }} className={classes.boxSquare}>
          {!isRight && <div className={classes.boxSquareCircle}>2</div>}
        </div>
        <div style={{ backgroundColor: '#197A9D' }} className={classes.boxSquare}>
          <div className={classes.boxSquareCircle}>3</div>
        </div>
        <div style={{ backgroundColor: '#C4D7DE' }} className={classes.boxSquare}>
          <div className={classes.boxSquareCircle}>1</div>
        </div>
        <div style={{ backgroundColor: '#99C4D3' }} className={classes.boxSquare}>
          {isRight && <div className={classes.boxSquareCircle}>2</div>}
        </div>
        <div className={classes.centeredCircle}>
          <p className={classes.textInCircle}>{textInsideCircle}</p>
          {isRight ? (
            <img alt="ellipse" className={classes.ellipse} src={whiteEllipseReversed} />
          ) : (
            <img alt="ellipse" className={classes.ellipseReversed} src={whiteEllipse} />
          )}
        </div>
        <div className={classes.axisContainer}>
          <YAxisIconNew />
          <div className={classes.xAxis}>
            <WhiteArrowRight className={classes.whiteArrowRight} />
          </div>
        </div>
        <p className={classes.highText1}>High</p>
        <p className={classes.lowText}>Low</p>
        <p className={classes.highText2}>High</p>
        <p className={classes.bottomText}>{bottomText}</p>
      </div>
    </div>
  );
});

import { takeLatest, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { login, loginSSO, removeToken, getTemplateMail, updateTemplateMail } from './actions';
import { processRequestError } from 'src/modules/Errors/actions';
import { ActionType } from 'deox';

import customHistory from '../../helpers/history';
import { select } from 'typed-redux-saga';
import { Store } from '../../types';

function* loginSaga({ payload }: ActionType<typeof login.request>) {
  try {
    const { data } = yield API.login(payload);

    yield put(login.success(data));
    customHistory.push('/');
  } catch (error) {
    yield put(processRequestError({ error, failAction: login.fail }));
  }
}

function* loginSSOSaga({ payload }: ActionType<typeof loginSSO.request>) {
  try {
    const { data } = yield API.loginSSO(payload);

    yield put(removeToken());

    yield put(loginSSO.success(data));
    if (data.token) {
      customHistory.push('/projects');
    }
  } catch (error) {
    yield put(processRequestError({ error, failAction: loginSSO.fail }));
  }
}

function* getTemplateMailSaga({ payload }: ActionType<typeof getTemplateMail.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  const params = {
    templateType: payload.templateType,
    projectId,
  };
  try {
    const { data } = yield API.getTemplateMail(params);
    yield put(getTemplateMail.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: getTemplateMail.fail }));
  }
}

function* updateTemplateForMailSaga({ payload }: ActionType<typeof updateTemplateMail.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  const payloadRequest = {
    params: {
      templateType: payload.params.templateType,
      projectId,
    },
    body: payload.body,
  };

  try {
    const { data } = yield API.updateTemplateMail(payloadRequest);
    yield put(updateTemplateMail.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: updateTemplateMail.fail }));
  }
}

export function* watchApp() {
  yield takeLatest(login.request, loginSaga);
  yield takeLatest(loginSSO.request, loginSSOSaga);
  yield takeLatest(getTemplateMail.request, getTemplateMailSaga);
  yield takeLatest(updateTemplateMail.request, updateTemplateForMailSaga);
}

import React, { memo, useState, useRef, useEffect, CSSProperties } from 'react';
import { InputBase } from '../InputBase';
import useStyles from './useStyles';
import { numberSeparator } from '../../../helpers/numberSeparator';

type Props = {
  editable?: boolean;
  value: string;
  onEditCb: (value: string) => undefined | boolean | void;
  onlyNumbers?: boolean;
  disabled?: boolean;
  disableEditing?: boolean;
  innerContainerStyle?: string;
  innerInputStyle?: string;
  buttonStyle?: string;
  isInput?: boolean;
  cellStyle?: string;
  suffix?: string;
  inlineStyle?: CSSProperties;
  isRevenue?: boolean;
  allowNegative?: boolean;
  placeholder?: string;
};

export const EditableCell: React.FC<Props> = memo(function EditableCell({
  value,
  onEditCb,
  onlyNumbers,
  innerContainerStyle,
  innerInputStyle,
  buttonStyle,
  disabled,
  isInput,
  cellStyle,
  suffix,
  inlineStyle,
  disableEditing,
  isRevenue,
  placeholder,
}) {
  const [editing, setEditing] = useState(false);
  const [editedValue, setEditedValue] = useState('');

  const classes = useStyles();

  //eslint-disable-next-line
  const inputRef = useRef<any>(null);

  const toggleEdit = () => {
    if (disableEditing) {
      return;
    }

    setEditing(!editing);
  };

  const save = (fieldValue: string) => {
    toggleEdit();

    const hasError = onEditCb(fieldValue);

    if (hasError) {
      return;
    }

    setEditedValue(fieldValue);
  };

  useEffect(() => {
    setEditedValue(value);
  }, [value]);

  const getButtonValue = (value: string) => {
    if (isRevenue && value !== '') {
      return numberSeparator(+value);
    }

    return value;
  };

  const childNode =
    editing && !disabled ? (
      <InputBase
        inputStyles={`${innerInputStyle && innerInputStyle} ${classes.input} `}
        value={editedValue ? editedValue : value}
        inputRef={inputRef}
        onlyNumbers={onlyNumbers}
        onPressEnter={save}
        onBlur={save}
        placeholder={placeholder}
        inlineStyle={inlineStyle}
      />
    ) : (
      <button
        style={inlineStyle}
        disabled={disabled}
        className={`${classes.button} ${buttonStyle && buttonStyle}`}
        onClick={toggleEdit}
        onFocus={toggleEdit}>
        {placeholder && !editedValue && <span className={classes.placeholder}>{placeholder}</span>}
        {editedValue ? getButtonValue(editedValue) : getButtonValue(value)}
        {suffix && suffix}
      </button>
    );

  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editing]);

  if (isInput) {
    return <div className={innerContainerStyle && innerContainerStyle}>{childNode}</div>;
  }

  return (
    <td className={cellStyle && cellStyle}>
      <div className={innerContainerStyle && innerContainerStyle}>{childNode}</div>
    </td>
  );
});

import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../../constants/COLORS';

const useStyles = makeStyles(() => ({
  negotiationStatus: {
    display: 'flex',
  },
  result: {
    flex: '1 1 35%',
    boxShadow: '0px 2px 10px rgba(199, 201, 201, 0.5)',
    borderRadius: 5,
    marginRight: 24,
    padding: '24px 24px 50px 24px',
    minHeight: 700,
  },
  status: {
    flex: '1 1 65%',
    boxShadow: '0px 2px 10px rgba(199, 201, 201, 0.5)',
    borderRadius: 5,
    padding: 24,
  },
  title: {
    fontSize: 20,
    lineHeight: '24px',
    fontWeight: 700,
  },
  dropdowns: {
    padding: '15px 0',
    display: 'flex',
  },
  dropdown: {
    marginRight: 25,
  },
  dropdownButton: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: COLORS.gray11,
      borderRadius: 0,
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: COLORS.black2,
    },
  },
  increasesBlock: {
    padding: '15px',
  },
  increasesTitle: {
    fontSize: 20,
    lineHeight: '24px',
    color: COLORS.black4,
  },
  increasesMain: {
    marginTop: 24,
    display: 'flex',
    gap: '10px',
  },
  increasesMainLeft: {
    flex: '1 1 40%',
    fontSize: 16,
    fontWeight: 700,
  },
  increasesMainRight: {
    flex: '1 1 60%',
  },
  increasesMainText: {
    fontSize: 16,
    lineHeight: '19px',
    fontWeight: 700,
  },
  increasesMainValue: {
    marginTop: 24,
    fontSize: 22,
    lineHeight: '38px',
    fontWeight: 400,
    '& span': {
      fontSize: 14,
      lineHeight: '19px',
    },
  },
  targetLine: {
    width: 130,
    height: 6,
    backgroundColor: COLORS.blue1,
    borderRadius: 3,
    marginTop: 3,
  },
  achievedLine: {
    width: 130,
    height: 6,
    backgroundColor: COLORS.blue1,
    borderRadius: 3,
    marginTop: 3,
  },
  increasesPerformance: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 180,
    marginTop: 32,
    borderRadius: 8,
    padding: '4px 8px',
    color: COLORS.white1,
    fontSize: 12,
    lineHeight: '14px',
    '& svg': {
      marginRight: 3,
    },
  },
  increasesIndicators: {
    marginTop: 24,
  },
  increasesIndicatorsTitle: {
    fontSize: 12,
    lineHeight: '14px',
    fontWeight: 700,
  },
  increasesIndicatorsItems: {
    marginTop: 8,
    display: 'flex',
    justifyContent: 'space-between',
  },
  increasesIndicatorsItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: 16,
  },
  increasesIndicatorsItemCircle: {
    width: 10,
    height: 10,
    borderRadius: '50%',
    marginRight: 4,
  },
  increasesIndicatorsItemText: {
    fontSize: 12,
    lineHeight: '14px',
  },
  impactBlock: {
    marginTop: 50,
  },
  impactTitle: {
    fontSize: 20,
    lineHeight: '24px',
    color: COLORS.black4,
  },
  impactChart: {
    marginTop: 27,
    height: 200,
  },
  impactPerformance: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 180,
    marginTop: 32,
    borderRadius: 8,
    padding: '4px 8px',
    color: COLORS.white1,
    fontSize: 12,
    lineHeight: '14px',
    '& svg': {
      marginRight: 3,
    },
  },
  impactIndicators: {
    marginTop: 24,
  },
  impactIndicatorsTitle: {
    fontSize: 12,
    lineHeight: '14px',
    fontWeight: 700,
  },
  impactIndicatorsItems: {
    marginTop: 8,
    display: 'flex',
    justifyContent: 'space-between',
  },
  impactIndicatorsItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: 16,
  },
  impactIndicatorsItemCircle: {
    width: 10,
    height: 10,
    borderRadius: '50%',
    marginRight: 4,
  },
  impactIndicatorsItemText: {
    fontSize: 12,
    lineHeight: '14px',
  },
}));

export default useStyles;

import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  card: {
    width: 170,
    height: 170,
    backgroundColor: COLORS.white1,
    padding: '12px',
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: '0px 2px 10px rgba(199, 201, 201, 0.5)',
    cursor: 'pointer',
  },
  bottomText: {
    fontSize: 10,
    color: COLORS.black2,
  },
  statusContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 10,
  },
}));

export default useStyles;

import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: COLORS.gray2,
    fontSize: '12px !important',
    color: `${COLORS.gray16} !important`,
    borderRight: `1px solid ${COLORS.gray3}`,
    borderLeft: `1px solid ${COLORS.gray3}`,
  },
}));

export default useStyles;

import React, { useRef, useEffect } from 'react';

export function useHorizontalScroll() {
  //eslint-disable-next-line
  const elRef = useRef<any>();
  useEffect(() => {
    const el = elRef.current;
    if (el) {
      const onWheel = (e: React.WheelEvent) => {
        if (e.deltaY === 0) return;
        if (
          !(el.scrollLeft === 0 && e.deltaY < 0) &&
          !(el.scrollWidth - el.clientWidth - Math.round(el.scrollLeft) === 0 && e.deltaY > 0)
        ) {
          e.preventDefault();
        }
        el.scrollTo({
          left: el.scrollLeft + e.deltaY,
          behavior: 'smooth',
        });
      };
      el.addEventListener('wheel', onWheel);
      return () => el.removeEventListener('wheel', onWheel);
    }
  }, []);
  return elRef;
}

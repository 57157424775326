import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: COLORS.white1,
    display: 'flex',
    position: 'relative',
    columnGap: 24,
  },
  subtitle: {
    fontWeight: 700,
    color: COLORS.black1,
    fontSize: 16,
    marginBottom: 24,
  },
  box: {
    padding: '16px 24px 12px',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    borderRadius: 8,
  },
  box1: {
    width: '30%',
  },
  box2: {
    width: '70%',
  },
  categoriesContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '24px',
  },
  addCategoryButtonContainer: {
    display: 'flex',
  },
  checkboxContainer: {
    position: 'absolute',
    top: 10,
    left: 10,
  },
  indicatorList: {
    height: 290,
    display: 'flex',
    rowGap: 16,
    flexDirection: 'column',
    minHeight: 290,
    overflowY: 'auto',
  },
}));

export default useStyles;

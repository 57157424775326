import React, { useCallback, useEffect, useState, FC } from 'react';
import useStyles from './useStyles';
import { ReactComponent as SettingsIcon } from 'src/assets/icons/settings.svg';
import { IconButton } from '@mui/material';
import { COLORS } from 'src/constants/COLORS';
import { MonitoringModal } from './components/MonitoringModal';
import { ReactComponent as CustomizeEmailIcon } from 'src/assets/icons/customize-email.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  downloadMonitoringExcel,
  getMonitoringDates,
  getMonitoringInfoScreen,
  updateMonitoringByAdmin,
} from 'src/modules/monitoring/actions';
import { MonitoringTable } from 'src/components/common/MonitoringTable';
import { Store } from 'src/types';
import { Pagination } from 'src/components/common/Pagination';
import { DEFAULT_COUNT_OF_ITEMS } from 'src/constants/dafaultCountOfItems';
import { Loader } from 'src/components/common/Loader';
import { NoDataPlaceholder } from 'src/components/common/NoDataPlaceholder';
import { toast } from 'react-toastify';
import { Dropdown } from './components/Dropdown';
import dayjs from 'dayjs';
import { ButtonBase } from '../../../../common/ButtonBase';
import { MailTemplateModal } from '../../../../common/MailTemplateModal';
import { clearTemplateForEmail, getTemplateMail } from '../../../../../modules/app/actions';

const TEMPLATE_TYPE = 'MONITORING';

export const Monitoring: FC = () => {
  const classes = useStyles();
  const [monitoringModalVisible, setMonitoringModalVisible] = useState<boolean>(false);
  const [isOpenMailTemplateModal, setIsOpenMailTemplateModal] = useState<boolean>(false);

  const { monitoringInfoScreen, loading, monitoringDates } = useSelector(
    (state: Store) => state.monitoring,
  );

  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(DEFAULT_COUNT_OF_ITEMS);
  const [selectedDate, setSelectedDate] = useState<string>('');

  const firstMonitoringDate = monitoringDates[0];

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearTemplateForEmail());
    };
  }, []);

  const handleCloseModal = () => {
    setMonitoringModalVisible(false);
  };

  const handleOpenModal = () => {
    if (monitoringInfoScreen && !monitoringInfoScreen.secondSurveyConfigEnabled) {
      toast.warn('Please complete Final customer targets before proceeding to monitoring.');

      return;
    }

    setMonitoringModalVisible(true);
  };

  useEffect(() => {
    dispatch(getMonitoringDates.request());
  }, []);

  const handleChangeDate = (value: string) => {
    setSelectedDate(value);
  };

  useEffect(() => {
    const modifiedSelectedDate = dayjs(selectedDate || firstMonitoringDate, 'DD.MM.YYYY').format(
      'YYYY-MM-DD',
    );

    const controller = new AbortController();
    if (monitoringDates.length) {
      dispatch(
        getMonitoringInfoScreen.request({
          pageNumber: page === 0 ? page : page - 1,
          itemsOnPage: rowsPerPage,
          date: modifiedSelectedDate,
          signal: controller.signal,
        }),
      );
    }

    return () => {
      controller.abort();
    };
  }, [dispatch, page, rowsPerPage, selectedDate, monitoringDates]);

  const goToPage = useCallback((page: number) => {
    setPage(page);
  }, []);

  const handleChangeRowsPerPage = (value: number) => {
    setRowsPerPage(value);
    setPage(0);
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const onUpdateMonitoringByAdmin = useCallback(
    (data) => {
      dispatch(updateMonitoringByAdmin.request(data));
    },
    [dispatch],
  );

  const onDownloadExcel = (value: string) => {
    const modifiedValue = dayjs(value, 'DD.MM.YYYY').format('YYYY-MM-DD');
    dispatch(downloadMonitoringExcel.request(modifiedValue));
  };

  const handleOpenMailTemplateModal = () => {
    setIsOpenMailTemplateModal(true);
    dispatch(
      getTemplateMail.request({
        templateType: TEMPLATE_TYPE,
      }),
    );
  };

  const handleCloseMailTemplateModal = () => {
    setIsOpenMailTemplateModal(false);
  };

  return (
    <div className={classes.monitoringWrapper}>
      <div className={classes.headerContainer}>
        {firstMonitoringDate ? (
          <Dropdown
            width={200}
            dropdownArrowColor={COLORS.black1}
            dropdownItems={monitoringDates}
            defaultValue={firstMonitoringDate}
            onSelectCb={(value) => handleChangeDate(value)}
          />
        ) : (
          <Dropdown
            width={200}
            disabled
            dropdownArrowColor={COLORS.black1}
            dropdownItems={monitoringDates}
            defaultValue={''}
          />
        )}

        <ButtonBase
          onClick={() => onDownloadExcel(selectedDate || firstMonitoringDate)}
          title="Download Excel"
          variant="outlined"
          disabled={!firstMonitoringDate}
        />
        <IconButton onClick={handleOpenModal}>
          <SettingsIcon fill={COLORS.black4} />
        </IconButton>
        <IconButton onClick={handleOpenMailTemplateModal}>
          <CustomizeEmailIcon fill={COLORS.black4} />
        </IconButton>
        <MonitoringModal open={monitoringModalVisible} handleClose={handleCloseModal} />
        {isOpenMailTemplateModal && (
          <MailTemplateModal
            open={isOpenMailTemplateModal}
            templateType={TEMPLATE_TYPE}
            onClose={handleCloseMailTemplateModal}
          />
        )}
      </div>
      {loading ? (
        <Loader isLoading={loading} marginTop={35} />
      ) : !monitoringInfoScreen || !firstMonitoringDate ? (
        <NoDataPlaceholder />
      ) : (
        <>
          <MonitoringTable
            allowModifying={false}
            data={monitoringInfoScreen}
            onUpdateMonitoringByAdmin={onUpdateMonitoringByAdmin}
          />
          <Pagination
            goToPage={goToPage}
            totalPages={monitoringInfoScreen.pageable.totalPages}
            setRowPerPage={handleChangeRowsPerPage}
            setPage={handleChangePage}
            page={page}
            rowPerPage={rowsPerPage}
            marginTop={4}
          />
        </>
      )}
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import MultipleDropdown from '../../../../common/MultipleDropdown';
import { COLORS } from '../../../../../constants/COLORS';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from '../../../../../types';
import useStyles from './useStyles';
import { getPerformanceBreakdown } from '../../../../../modules/monitoring/actions';
import BreakdownChart from './BreakdownChart';
import { Loader } from '../../../../common/Loader';
import { getBUAbbreviations } from '../../../../../modules/specificTargets/actions';
import { getAccountManagerNames } from '../../../../../modules/TouchableRevenue/actions';
import { getMultipleDropdownValues } from '../../../../../helpers/getMultipleDropdownValues';

const PerformanceBreakdown = () => {
  const classes = useStyles();

  const [segmentsArr, setSegmentsArr] = React.useState(['All']);
  const [accountManagersArr, setAccountManagersArr] = React.useState(['All']);

  const [body, setBody] = useState({
    businessUnitAbbreviations: ['All'],
    accountManagers: ['All'],
  });

  const dispatch = useDispatch();

  const {
    specificTargets: { BUAbbreviations },
    touchableRevenue: { accountManagerNames },
    monitoring: { loading },
  } = useSelector((state: Store) => state);

  useEffect(() => {
    dispatch(getBUAbbreviations.request());
    dispatch(getAccountManagerNames.request());
  }, [dispatch]);

  useEffect(() => {
    const controller = new AbortController();
    dispatch(
      getPerformanceBreakdown.request({
        body,
        signal: controller.signal,
      }),
    );
    return () => {
      controller.abort();
    };
  }, [dispatch, body]);
  //eslint-disable-next-line
  const handleSegmentsChange = (event: any) => {
    getMultipleDropdownValues(event.target.value, setSegmentsArr);
  };

  useEffect(() => {
    setBody({ ...body, businessUnitAbbreviations: segmentsArr });
  }, [segmentsArr]);
  //eslint-disable-next-line
  const handleAccountManagerChange = (event: any) => {
    getMultipleDropdownValues(event.target.value, setAccountManagersArr);
  };

  useEffect(() => {
    setBody({ ...body, accountManagers: accountManagersArr });
  }, [accountManagersArr]);

  return (
    <>
      <div className={classes.dropdowns}>
        <div className={classes.dropdown}>
          <MultipleDropdown
            value={segmentsArr}
            array={['All', ...BUAbbreviations]}
            onChange={handleSegmentsChange}
            label="Segments"
            dropdownArrowColor={COLORS.black1}
          />
        </div>
        <div className={classes.dropdown}>
          <MultipleDropdown
            value={accountManagersArr}
            array={['All', ...accountManagerNames]}
            onChange={handleAccountManagerChange}
            label="Account managers"
            dropdownArrowColor={COLORS.black1}
          />
        </div>
      </div>
      {loading ? <Loader isLoading={loading} marginTop={35} /> : <BreakdownChart />}
    </>
  );
};

export default PerformanceBreakdown;

import React, { useContext, FC } from 'react';

import { DeleteButton } from 'src/components/common/DeleteButton';
import { ReactComponent as WarningIcon } from 'src/assets/icons/warning.svg';
import { SurveySettingsCategories } from 'src/constants/SurveySettingCategories';
import { EditableCell } from 'src/components/common/EditableCell';
import { SurveysProvider } from '../../../SurveysProvider';
import useStyles from '../../../useStyles';
import { COLORS } from '../../../../../../../../constants/COLORS';
import { ReactComponent as SettingsIcon } from 'src/assets/icons/settings.svg';
import { OtherDropdownItems, SelectedOther } from '../../../../../../../../types';

type OtherOptionsDropdownItemProps = {
  option: string;
  id: number;
  items: OtherDropdownItems[];
  onChangeValueCb: (value: string, id: number) => void;
  error: boolean;
  handleModalOpen: () => void;
  onSelectOther: (items: SelectedOther) => void;
};

export const OtherOptionsDropdownItem: FC<OtherOptionsDropdownItemProps> = ({
  option,
  id,
  items,
  onChangeValueCb,
  error,
  handleModalOpen,
  onSelectOther,
}) => {
  const classes = useStyles();

  const { onDeleteOption, handleCleanError, disabled } = useContext(SurveysProvider);

  const onChange = (value: string) => {
    if (error) {
      handleCleanError(id, 'other-option');
    }
    if (value.slice(-1) === ' ') {
      return;
    }
    if (value !== option) {
      onChangeValueCb(value, id);
    }
  };

  const onClickSettings = () => {
    if (!disabled) {
      onSelectOther({
        id,
        option,
        items,
      });
      handleModalOpen();
    }
  };

  return (
    <div className={classes.listItem}>
      <div className={classes.eoiInputContainer}>
        {error && <WarningIcon className={classes.listItemErrorIconEOI} />}

        <EditableCell
          isInput
          buttonStyle={`${classes.input} ${disabled && classes.inputDisabled} ${
            error && classes.listItemErrorInput
          }`}
          innerInputStyle={classes.input}
          disabled={disabled}
          value={option}
          onEditCb={onChange}
        />
      </div>
      <SettingsIcon
        onClick={onClickSettings}
        fill={disabled ? COLORS.gray3 : COLORS.black4}
        className={`${classes.settingsIcon} ${disabled && classes.settingIconDisabled}`}
      />
      <DeleteButton
        disabled={disabled}
        styles={classes.deleteButton}
        onClick={() => {
          if (!disabled) {
            onDeleteOption(id, SurveySettingsCategories.otherOptions);
          }
        }}
      />
    </div>
  );
};

import React from 'react';
import { Navigate } from 'react-router-dom';

export const PublicRoute = ({
  children,
  isAuthenticated,
  path,
}: {
  isAuthenticated: boolean;
  children: JSX.Element;
  path: string;
}) => {
  return !isAuthenticated ? children : <Navigate to={path} />;
};

import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  container: {
    width: '45%',
    display: 'flex',
    flexDirection: 'column',
    rowGap: 20,
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 14,
    alignItems: 'flex-start',
    boxShadow: '0px 2px 10px rgba(199, 201, 201, 0.5)',
    borderRadius: 8,
    padding: '16px 24px',
  },
  row: {
    width: '100%',
    columnGap: 15,
    display: 'grid',
    justifyItems: 'center',
    alignItems: 'center',
    gridTemplateColumns: '148px 1fr 1fr 1fr 1fr',
    alignSelf: 'center',
  },
  circleWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  secondTableRow: {
    columnGap: 23,
    width: '100%',
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '148px 3fr 1fr',
  },
  scoreNumberText: {
    width: 70,
    color: COLORS.black1,
    textAlign: 'center',
  },
  circle: {
    width: 30,
    height: 30,
    border: `1px solid ${COLORS.blue9}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
    color: COLORS.black2,
    borderRadius: '50%',
  },
  notEditableBlock: {
    width: 122,
    height: 40,
    borderRadius: 8,
    backgroundColor: COLORS.blue9,
    border: `1px solid ${COLORS.blue1}`,
    display: 'flex',
    alignItems: 'center',
    padding: '0 12px',
    color: COLORS.gray5,
  },
  impactText: {
    color: COLORS.black1,
  },
  text: {
    color: COLORS.black1,
    fontSize: 16,
    fontWeight: 400,
  },
  input: {
    border: `1px solid ${COLORS.gray26}`,
    borderRadius: 8,
    width: 70,
    height: 40,
    fontSize: 16,
    textAlign: 'center',
  },
}));

export default useStyles;

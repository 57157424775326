import React, { useContext } from 'react';
import { PlanBOption } from 'src/types';
// import { InputBase } from 'src/components/common/InputBase';

import useStyles from '../ContractCommitmentItem/useStyles';
import { DeleteButton } from 'src/components/common/DeleteButton';
import { SurveysProvider } from '../../SurveysProvider';
import { ReactComponent as WarningIcon } from 'src/assets/icons/warning.svg';
import { SurveySettingsCategories } from 'src/constants/SurveySettingCategories';
import { EditableCell } from 'src/components/common/EditableCell';

type Props = PlanBOption & { onChangeValueCb: (value: string, id: number) => void; error: boolean };

export const PlanBItem: React.FC<Props> = ({ option, id, onChangeValueCb, error }) => {
  const classes = useStyles();

  const { onDeleteOption, handleCleanError, disabled } = useContext(SurveysProvider);

  const onChange = (value: string) => {
    if (error) {
      handleCleanError(id, 'planBOptions');
    }

    if (value.slice(-1) === ' ') {
      return;
    }
    if (value !== option) {
      onChangeValueCb(value, id);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.inputContainer}>
        {error && <WarningIcon className={classes.listItemErrorIcon} />}

        <EditableCell
          isInput
          buttonStyle={`${classes.input} ${disabled && classes.inputDisabled} ${
            error && classes.listItemErrorInput
          }`}
          innerInputStyle={classes.input}
          disabled={disabled}
          value={option}
          onEditCb={onChange}
        />

        {/*<InputBase*/}
        {/*  disabled={disabled}*/}
        {/*  disableLeadingEmpty={true}*/}
        {/*  onChangeCb={onChange}*/}
        {/*  inputStyles={`${classes.input} ${disabled && classes.inputDisabled} ${*/}
        {/*    error && classes.listItemErrorInput*/}
        {/*  }`}*/}
        {/*  value={option}*/}
        {/*/>*/}
      </div>

      <DeleteButton
        disabled={disabled}
        styles={classes.deleteButton}
        onClick={() => {
          if (!disabled) {
            onDeleteOption(id, SurveySettingsCategories.planBOptions);
          }
        }}
      />
    </div>
  );
};

import React, { CSSProperties, useState } from 'react';
import useStyles from './useStyles';
import { checkIsNumber } from '../../../helpers/checkIsNumber';

type Props = {
  id?: string;
  value: string | null;
  defaultValue?: string;
  onChangeCb?: (value: string) => void;
  inputStyles?: string;
  disabled?: boolean;
  onlyNumbers?: boolean;
  placeholder?: string;
  disableLeadingEmpty?: boolean;
  //eslint-disable-next-line
  inputRef?: any;
  onPressEnter?: (value: string) => void;
  onBlur?: (value: string) => void;
  inlineStyle?: CSSProperties;
  needUpdateState?: boolean;
};

export const InputBase: React.FC<Props> = ({
  id,
  disableLeadingEmpty,
  disabled,
  value,
  defaultValue,
  onChangeCb,
  inputStyles,
  onPressEnter,
  onBlur,
  inputRef,
  onlyNumbers,
  inlineStyle,
  placeholder,
}) => {
  const classes = useStyles();

  const [inputValue, setInputValue] = useState(value || '');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;

    if (onlyNumbers) {
      const isNumber = checkIsNumber(value);

      if (!isNumber) {
        return;
      }
    }

    setInputValue(disableLeadingEmpty ? value.trimStart() : value);

    onChangeCb && onChangeCb(disableLeadingEmpty ? value.trimStart() : value);
  };

  return (
    <input
      style={inlineStyle}
      ref={inputRef}
      //eslint-disable-next-line
      onKeyPress={(e: any) => {
        if (e.key === 'Enter') {
          onPressEnter && onPressEnter(e.target.value);
        }
      }}
      //eslint-disable-next-line
      onBlur={(e: any) => {
        onBlur && onBlur(e.target.value);
      }}
      className={`${classes.inputContainer} ${inputStyles && inputStyles} ${
        disabled && classes.inputDisabled
      }`}
      id={id}
      disabled={disabled}
      placeholder={placeholder}
      value={inputValue || defaultValue}
      onChange={handleChange}
    />
  );
};

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  container: {
    overflowX: 'auto',
    paddingBottom: 10,
    paddingLeft: 110,
    minHeight: 500,
  },
}));

export default useStyles;

export const COLORS = {
  black1: '#333333',
  black2: '#4F4F4F',
  black3: '#000000',
  black4: '#39322B',
  black5: '#BABABA',
  black6: '#191919',
  gray1: '#EAEBEB',
  gray2: '#F1F2F2',
  gray3: '#C7C9C9',
  gray4: '#F8F8F8',
  gray5: '#F2F2F2',
  gray6: '#DDDFDF',
  gray7: '#DCDEDE',
  gray8: '#BABABA',
  gray9: '#CACACA',
  gray10: '#F0F0F0',
  gray11: '#828282',
  gray12: '#E0E0E0',
  gray13: '#E7E7E7',
  gray14: '#CDCDCD',
  gray15: '#D4D4D4',
  gray16: '#BDBDBD',
  gray17: '#E0E0E0',
  gray18: '#E9E9E9',
  gray19: '#FAFAFA',
  gray20: '#C4C4C4',
  gray21: '#E3E4E4',
  gray22: '#989898',
  gray23: '#858899',
  gray24: '#797988',
  gray25: '#9696A2',
  gray26: '#5C5C6E',
  gray27: '#CFCFD4',
  gray28: '#F2F2F3',
  gray29: '#E3E3E6',
  gray30: '#B3B3BB',
  gray31: '#ECECEE',
  white1: '#FFFFFF',
  white2: '#F8F8F8',
  white3: '#FDFCFF',
  white4: '#F4F4F7',
  green1: '#008190',
  green2: '#00ACC0',
  green3: '#33BDCD',
  green4: '#00A887',
  green5: '#005660',
  red1: '#DA1B5E',
  red2: '#FF5982',
  red3: '#BA3656',
  red4: '#992c2c',
  red5: '#BA1750',
  red6: '#831038',
  blue1: '#006B92',
  blue2: '#33BDCD',
  blue3: '#99C4D3',
  blue4: '#D4E8EF',
  blue5: '#C1E0EB',
  blue6: '#CFE4EB',
  blue7: '#BFD3DA',
  blue8: '#B2E6EC',
  blue9: '#00ACC0',
  blue10: '#D8EDEF',
  blue11: '#0092A3',
  blue12: '#E5F0F4',
  blue13: '#F2F7F9',
  blue14: '#CCE1E9',
  blue15: '#E0F5F7',
  blue16: '#005B7C',
  blue17: '#2681A2',
  blue18: '#4C97B2',
  blue19: '#D9E9EF',
  blue20: '#FBE8EF',
  yellow: '#E5BF18',
  pink1: '#F9A5A5',
  pink2: '#F0A4BF',
  pink3: '#E3AFBB',
  pink4: '#E1497E',
  pink5: '#E9769E',
  pink6: '#F4BACE',
  pink7: '#BC82BD',
  pink8: '#F2E6F2',
  purple1: '#3B3A70',
  orange1: '#FF6D6D',
  purple2: '#DDDCEC',
};

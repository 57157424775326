import React, { FC } from 'react';
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer } from 'recharts';
import { COLORS } from 'src/constants/COLORS';
import useStyles from './useStyles';
import { PriceIncreaseBridgeResponse } from '../../../../../../../types';

type ChartProps = {
  priceIncreaseBridge: PriceIncreaseBridgeResponse;
};

const Chart: FC<ChartProps> = ({ priceIncreaseBridge }) => {
  const classes = useStyles();

  const {
    concessionIncrease,
    sustainedIncrease,
    targetIncrease,
    breachOfContractTermOptions,
    planBOptions,
  } = priceIncreaseBridge || {};

  const modifiedPlanB = [];

  const modifiedDrivers = breachOfContractTermOptions?.map((obj: any, ind: number) => ({
    name: obj.name,
    color: COLORS.blue15,
    uv: obj.value,
    pv: breachOfContractTermOptions
      .slice(0, ind)
      .reduce((acc: any, curr: any) => acc + curr.value, 0),
  }));

  for (let i = 0; i < planBOptions?.length; i++) {
    if (i === 0) {
      modifiedPlanB.push({
        uv: planBOptions?.[i]?.value,
        pv:
          concessionIncrease +
          (planBOptions?.[1]?.value ? planBOptions?.[1]?.value : 0) +
          (planBOptions?.[2]?.value ? planBOptions?.[2]?.value : 0) +
          (planBOptions?.[3]?.value ? planBOptions?.[3]?.value : 0) +
          (planBOptions?.[4]?.value ? planBOptions?.[4]?.value : 0),
        color: COLORS.blue15,
        name: planBOptions[i].name,
        isPlanB: true,
      });
    }
    if (i === 1) {
      modifiedPlanB.push({
        uv: planBOptions[i].value,
        pv:
          concessionIncrease +
          (planBOptions?.[2]?.value ? planBOptions[2].value : 0) +
          (planBOptions?.[3]?.value ? planBOptions[3].value : 0) +
          (planBOptions?.[4]?.value ? planBOptions[4].value : 0),
        color: COLORS.blue15,
        name: planBOptions[i].name,
        isPlanB: true,
      });
    }
    if (i === 2) {
      modifiedPlanB.push({
        uv: planBOptions[i].value,
        pv:
          concessionIncrease +
          (planBOptions?.[3]?.value ? planBOptions[3].value : 0) +
          (planBOptions?.[4]?.value ? planBOptions[4].value : 0),
        color: COLORS.blue15,
        name: planBOptions[i].name,
        isPlanB: true,
      });
    }
    if (i === 3) {
      modifiedPlanB.push({
        uv: planBOptions[i].value,
        pv: concessionIncrease + (planBOptions?.[4]?.value ? planBOptions[4].value : 0),
        color: COLORS.blue15,
        name: planBOptions[i].name,
        isPlanB: true,
      });
    }
    if (i === 4) {
      modifiedPlanB.push({
        uv: planBOptions[i].value,
        pv: concessionIncrease,
        color: COLORS.blue15,
        name: planBOptions[i].name,
        isPlanB: true,
      });
    }
  }

  const chartData = [
    ...(modifiedDrivers || []),
    {
      name: 'Sustained increase',
      uv: sustainedIncrease,
      color: '#004B66',
    },
    {
      name: 'Target increase',
      uv: targetIncrease,
      color: '#4C97B2',
    },
    ...modifiedPlanB,
    {
      name: 'Concession increase',
      uv: concessionIncrease,
      color: '#004B66',
    },
  ];

  const renderCustomBarLabel = (props: any) => {
    const { x, y, width, index } = props;
    const labelValueObj = chartData.find((_, idx) => idx === index);
    const getValueUv = (value: any) => {
      if (value?.uv > 0) {
        if (value.isPlanB) {
          return `-${value?.uv}%`;
        }
        return `${value?.uv}%`;
      }
    };
    if (labelValueObj?.color === COLORS.blue15) {
      return (
        <text fontWeight={700} x={x + width / 2} y={y} fill="#666" textAnchor="middle" dy={-6}>
          {getValueUv(labelValueObj)}
        </text>
      );
    }
    return (
      <>
        <text fontWeight={700} x={x + width / 2} y={y - 15} fill="#666" textAnchor="middle" dy={-6}>
          {labelValueObj!.uv > 0 && labelValueObj?.name.split(' ')[0]}
        </text>
        <text fontWeight={700} x={x + width / 2} y={y} fill="#666" textAnchor="middle" dy={-6}>
          {labelValueObj!.uv > 0 && labelValueObj?.name.split(' ')[1]}
        </text>
      </>
    );
  };

  return (
    <div className={classes.chart}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={250}
          data={chartData}
          margin={{
            top: 50,
            right: 0,
            left: 0,
            bottom: 0,
          }}>
          <CartesianGrid vertical={false} horizontal={false} />
          <Bar
            isAnimationActive={false}
            stackId="a"
            dataKey="pv"
            fill="transparent"
            barSize={41}
            radius={[4, 4, 4, 4]}
          />
          <Bar
            stackId="a"
            dataKey="uv"
            fill="#B2E6EC"
            barSize={41}
            radius={[4, 4, 4, 4]}
            label={renderCustomBarLabel}>
            {chartData.map((item) => {
              return <Cell key={item.name} fill={item.color} />;
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <div className={classes.xAxisLine} />
    </div>
  );
};

export default Chart;

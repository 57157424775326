import React, { useCallback } from 'react';
import useStyles from '../../useStyles';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'src/types';

import { ReactComponent as CloseIcon } from 'src/assets/icons/Close.svg';
import { PriceIncreaseTimeLevelItem } from '../PriceIncreaseTimeLevelItem';
import { Stack } from '@mui/material';
import Modal from '@mui/material/Modal';

import {
  deleteSurveyPriceIncreaseTimeLevelOption as deleteSurveyModalOption,
  surveySettingUpdateEOIModalInfo,
} from 'src/modules/surveys/actions';

import { setSelectedEaseOfIncreaseDriver } from 'src/modules/surveys/actions';
import { ButtonBase } from 'src/components/common/ButtonBase';
import { TextButton } from '../../../../../../common/TextButton';

type Props = {
  open: boolean;
  handleClose: () => void;
};

export const SurveySettingsModal: React.FC<Props> = ({ open, handleClose }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { selectedEaseOfIncreaseDriver } = useSelector((state: Store) => state.surveys);

  const { updateSettingsLoading } = useSelector((state: Store) => state.surveys);

  const saveLevelsEOI = useCallback(() => {
    if (selectedEaseOfIncreaseDriver) {
      dispatch(
        surveySettingUpdateEOIModalInfo.request({
          requestPayload: {
            params: {
              optionId: selectedEaseOfIncreaseDriver.id,
            },
            body: selectedEaseOfIncreaseDriver.priceIncreaseTimeLevels.map(
              // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
              ({ temporaryId, ...keepAttrs }) => keepAttrs,
            ),
          },
          onSuccessCb: () => {
            handleClose();
          },
        }),
      );
    }
  }, [dispatch, handleClose, selectedEaseOfIncreaseDriver]);

  const onAddNewPriceIncreaseTimeLevels = useCallback(() => {
    if (selectedEaseOfIncreaseDriver) {
      dispatch(
        setSelectedEaseOfIncreaseDriver({
          ...selectedEaseOfIncreaseDriver,
          priceIncreaseTimeLevels: [
            ...selectedEaseOfIncreaseDriver.priceIncreaseTimeLevels,
            {
              score: 0,
              level: '',
              temporaryId: Math.floor(Math.random() * 1000),
            },
          ],
        }),
      );
    }
  }, [dispatch, selectedEaseOfIncreaseDriver]);

  const onChangeValueOfLevelsForLastIncreaseTimeScore = useCallback(
    (value: string, id: number) => {
      if (selectedEaseOfIncreaseDriver) {
        dispatch(
          setSelectedEaseOfIncreaseDriver({
            ...selectedEaseOfIncreaseDriver,
            priceIncreaseTimeLevels: selectedEaseOfIncreaseDriver.priceIncreaseTimeLevels.map(
              (item) => {
                if (item.id && item.id === id) {
                  return {
                    ...item,
                    score: Number(value),
                  };
                } else if (item.id === undefined && id === item.temporaryId) {
                  return {
                    ...item,
                    score: Number(value),
                  };
                }

                return item;
              },
            ),
          }),
        );
      }
    },
    [dispatch, selectedEaseOfIncreaseDriver],
  );

  const onChangeValueOfLevelsForLastIncreaseTimeLevel = useCallback(
    (value: string, id: number) => {
      if (selectedEaseOfIncreaseDriver) {
        dispatch(
          setSelectedEaseOfIncreaseDriver({
            ...selectedEaseOfIncreaseDriver,
            priceIncreaseTimeLevels: selectedEaseOfIncreaseDriver.priceIncreaseTimeLevels.map(
              (item) => {
                if (item.id && item.id === id) {
                  return {
                    ...item,
                    level: value,
                  };
                } else if (item.id === undefined && id === item.temporaryId) {
                  return {
                    ...item,
                    level: value,
                  };
                }

                return item;
              },
            ),
          }),
        );
      }
    },
    [dispatch, selectedEaseOfIncreaseDriver],
  );

  const deletePriceIncreaseTimeLevelOption = useCallback(
    ({ id, temporaryId }) => {
      dispatch(deleteSurveyModalOption.request({ id, temporaryId }));
    },
    [dispatch],
  );

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <div className={classes.modalContainer}>
          <IconButton
            onClick={handleClose}
            aria-label="delete"
            sx={{ display: 'flex', alignSelf: 'flex-end' }}>
            <CloseIcon width={16} height={16} />
          </IconButton>
          <p className={classes.modalTitle}>
            {selectedEaseOfIncreaseDriver && selectedEaseOfIncreaseDriver.option}
          </p>

          {selectedEaseOfIncreaseDriver &&
            selectedEaseOfIncreaseDriver.priceIncreaseTimeLevels.map((item) => (
              <PriceIncreaseTimeLevelItem
                deletePriceIncreaseTimeLevelOption={deletePriceIncreaseTimeLevelOption}
                onChangeScoreCb={onChangeValueOfLevelsForLastIncreaseTimeScore}
                onChangeLevelCb={onChangeValueOfLevelsForLastIncreaseTimeLevel}
                {...item}
                key={item.id || item.temporaryId}
              />
            ))}
          <Stack marginTop={1} alignItems="center">
            <TextButton onClick={onAddNewPriceIncreaseTimeLevels} title="Add level" />
          </Stack>
          <Stack marginTop={6} alignItems="center">
            <ButtonBase
              onClick={saveLevelsEOI}
              isLoading={updateSettingsLoading}
              variant="contained"
              title="Save"
            />
          </Stack>
        </div>
      </Modal>
    </div>
  );
};

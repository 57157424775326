import { ActionType } from 'deox';
import {
  getEOIWeighting,
  updateEOIWeighting,
  getBUAbbreviations,
  getBusinessCaseAssumptions,
  updateBusinessCaseAssumption,
  getTargetFineTuning,
  updateTargetFineTuning,
  exportCustomerTargetsExcel,
  downloadCustomerTargetsTemplate,
  getCustomerTargets,
  updateCustomerTargetCell,
  updateCustomerTargetBusinessUnitCell,
  updateExpandedStatusOfCustomerTargetsTable,
  getCustomerTargetsExpandedStatus,
  getCampaignImpact,
  downloadFinalCustomerTargetsExcel,
  getFinalCustomerTargets,
  checkWeightingsOfBusinessUnits,
} from './actions';
import { API } from 'src/api';
import { put, takeLatest } from 'redux-saga/effects';
import { processRequestError } from '../Errors/actions';
import { select } from 'typed-redux-saga';
import { Store as AppStore, Store } from 'src/types';
import { downloadFile } from 'src/api/downloadFile';
import store from '../../store';
import { toast } from 'react-toastify';

function* checkWeightingsOfBusinessUnitsSaga({
  payload,
}: ActionType<typeof checkWeightingsOfBusinessUnits.request>) {
  try {
    const { projectId } = yield select((state: Store) => state.projects);

    yield API.checkWeightingsOfBusinessUnits({ projectId });

    payload.onSuccessCb();
  } catch (error) {
    yield put(processRequestError({ error, failAction: checkWeightingsOfBusinessUnits.fail }));
  }
}

function* getEOIWeightingSaga({ payload }: ActionType<typeof getEOIWeighting.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.getEOIWeighting({ ...payload, projectId });

    yield put(getEOIWeighting.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: getEOIWeighting.fail }));
  }
}

function* updateEOIWeightingSaga({ payload }: ActionType<typeof updateEOIWeighting.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    yield API.updateEOIWeighting(payload, { projectId });

    yield put(updateEOIWeighting.success(payload));
  } catch (error) {
    yield put(processRequestError({ error, failAction: updateEOIWeighting.fail }));
  }
}

function* getBusinessCaseAssumptionsSaga() {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.getBusinessCaseAssumptions({ projectId });

    yield put(getBusinessCaseAssumptions.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: getBusinessCaseAssumptions.fail }));
  }
}

function* getBUAbbreviationSaga() {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.getBUAbbreviations({ projectId });

    yield put(getBUAbbreviations.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: getBUAbbreviations.fail }));
  }
}

function* updateBusinessCaseAssumptionSaga({
  payload,
}: ActionType<typeof updateBusinessCaseAssumption.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.updateBusinessCaseAssumption({
      body: payload,
      params: { projectId },
    });

    yield put(updateBusinessCaseAssumption.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: updateBusinessCaseAssumption.fail }));
  }
}

function* getTargetFineTuningSaga({ payload }: ActionType<typeof getTargetFineTuning.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.getTargetFineTuning({
      projectId,
      businessUnitAbbreviation: payload.businessUnitAbbreviation,
    });

    yield put(getTargetFineTuning.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: getTargetFineTuning.fail }));
  }
}

function* updateTargetFineTuningSaga({
  payload,
}: ActionType<typeof updateTargetFineTuning.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.updateTargetFineTuning({
      ...payload,
      params: {
        ...payload.params,
        projectId,
      },
    });

    yield put(updateTargetFineTuning.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: updateTargetFineTuning.fail }));
  }
}

function* exportCustomerTargetsExcelSaga({
  payload,
}: ActionType<typeof exportCustomerTargetsExcel.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { file } = payload;

    const formData = new FormData();
    formData.append('file', file);

    yield API.exportCustomerTargetsExcel({
      file: formData,
      projectId,
    });

    yield put(exportCustomerTargetsExcel.success());

    yield put(
      getCustomerTargets.request({
        data: { itemsOnPage: payload.itemsOnPage, pageNumber: payload.pageNumber },
      }),
    );
  } catch (error) {
    yield put(processRequestError({ error, failAction: exportCustomerTargetsExcel.fail }));
  }
}

function* downloadCustomerTargetsTemplateSaga() {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.downloadCustomerTargetsExcel({ projectId });

    yield downloadFile(data, 'customer_targets.xlsx');

    yield put(downloadCustomerTargetsTemplate.success());
  } catch (error) {
    yield put(processRequestError({ error, failAction: downloadCustomerTargetsTemplate.fail }));
  }
}

function* getCustomerTargetsSaga({ payload }: ActionType<typeof getCustomerTargets.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  setTimeout(() => {
    const appStore: AppStore = store.getState();

    const { loading } = appStore.specificTargets;

    if (loading) {
      toast.warn('Calculation processing may take some time, please wait');
    }
  }, 3000);

  try {
    const { data } = yield API.getCustomerTargets({ ...payload.data, projectId });

    yield put(getCustomerTargets.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: getCustomerTargets.fail }));
  }
}

function* updateCustomerTargetCellSaga({
  payload,
}: ActionType<typeof updateCustomerTargetCell.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.updateCustomerTargetCell({ params: { projectId }, body: payload });

    yield put(updateCustomerTargetCell.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: updateCustomerTargetCell.fail }));
  }
}

function* updateCustomerTargetBusinessUnitCellSaga({
  payload,
}: ActionType<typeof updateCustomerTargetBusinessUnitCell.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.updateCustomerTargetBusinessUnitCell({
      params: { projectId },
      body: payload,
    });

    yield put(updateCustomerTargetBusinessUnitCell.success(data));
  } catch (error) {
    yield put(
      processRequestError({ error, failAction: updateCustomerTargetBusinessUnitCell.fail }),
    );
  }
}

function* updateExpandedStatusOfCustomerTargetsTableSaga({
  payload,
}: ActionType<typeof updateExpandedStatusOfCustomerTargetsTable.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    yield API.updateExpandedStatusOfCustomerTargetsTable({ projectId, expanded: payload.expanded });

    yield put(updateExpandedStatusOfCustomerTargetsTable.success());
  } catch (error) {
    yield put(
      processRequestError({ error, failAction: updateExpandedStatusOfCustomerTargetsTable.fail }),
    );
  }
}

function* getCustomerTargetsExpandedStatusSaga() {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.getCustomerTargetsExpandedStatus({ projectId });

    yield put(getCustomerTargetsExpandedStatus.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: getCustomerTargetsExpandedStatus.fail }));
  }
}

function* getCampaignImpactSaga() {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.getCampaignImpact({ projectId });

    yield put(getCampaignImpact.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: getCampaignImpact.fail }));
  }
}

function* downloadFinalCustomerTargetsTemplateSaga() {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.downloadFinalCustomerTargetsTemplate({ projectId });

    console.log(data);

    yield downloadFile(data, 'final_customer_targets.xlsx');

    yield put(downloadFinalCustomerTargetsExcel.success());
  } catch (error) {
    yield put(processRequestError({ error, failAction: downloadFinalCustomerTargetsExcel.fail }));
  }
}

function* getFinalCustomerTargetsSaga() {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.getFinalCustomerTargets({ projectId });

    yield put(getFinalCustomerTargets.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: getFinalCustomerTargets.fail }));
  }
}

export function* watchSpecificTargets() {
  yield takeLatest(updateEOIWeighting.request, updateEOIWeightingSaga);
  yield takeLatest(getBUAbbreviations.request, getBUAbbreviationSaga);
  yield takeLatest(getBusinessCaseAssumptions.request, getBusinessCaseAssumptionsSaga);
  yield takeLatest(updateBusinessCaseAssumption.request, updateBusinessCaseAssumptionSaga);
  yield takeLatest(getTargetFineTuning.request, getTargetFineTuningSaga);
  yield takeLatest(getEOIWeighting.request, getEOIWeightingSaga);
  yield takeLatest(updateTargetFineTuning.request, updateTargetFineTuningSaga);
  yield takeLatest(exportCustomerTargetsExcel.request, exportCustomerTargetsExcelSaga);
  yield takeLatest(downloadCustomerTargetsTemplate.request, downloadCustomerTargetsTemplateSaga);
  yield takeLatest(getCustomerTargets.request, getCustomerTargetsSaga);
  yield takeLatest(updateCustomerTargetCell.request, updateCustomerTargetCellSaga);
  yield takeLatest(
    updateCustomerTargetBusinessUnitCell.request,
    updateCustomerTargetBusinessUnitCellSaga,
  );
  yield takeLatest(
    updateExpandedStatusOfCustomerTargetsTable.request,
    updateExpandedStatusOfCustomerTargetsTableSaga,
  );
  yield takeLatest(getCustomerTargetsExpandedStatus.request, getCustomerTargetsExpandedStatusSaga);
  yield takeLatest(getCampaignImpact.request, getCampaignImpactSaga);
  yield takeLatest(
    downloadFinalCustomerTargetsExcel.request,
    downloadFinalCustomerTargetsTemplateSaga,
  );
  yield takeLatest(getFinalCustomerTargets.request, getFinalCustomerTargetsSaga);
  yield takeLatest(checkWeightingsOfBusinessUnits.request, checkWeightingsOfBusinessUnitsSaga);
}

import React, { useEffect } from 'react';
import { Stack, Typography } from '@mui/material';
import useStyles from './useStyles';

import { COLORS } from 'src/constants/COLORS';
import { HomeStatusesOverview } from 'src/components/Views/HomeStatusesOverview';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getProgressHomepageCards } from 'src/modules/home/actions';
import { Store } from 'src/types';
import { ROLES } from 'src/constants/ROLES';
import { getSurveyResultAccess } from 'src/modules/surveys/actions';
import { ButtonBase } from '../../../../common/ButtonBase';

export const StatusOverview: React.FC = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { homepageCards } = useSelector((state: Store) => state.home);
  const { currentRole } = useSelector((state: Store) => state.app);

  const onNavigateToSettingsOverview = () => {
    navigate('/home/settings-overview');
  };

  const navigate = useNavigate();

  const menuItems = [
    {
      id: 1,
      title: 'Configure the levels',
      backGroundColor: COLORS.green5,
      callback: onNavigateToSettingsOverview,
    },
    {
      id: 2,
      title: 'Prepare implementation',
      backGroundColor: COLORS.purple1,
    },
    {
      id: 3,
      title: 'Roll-out initiative',
      backGroundColor: COLORS.red4,
      callback: () => {
        //
      },
    },
  ];

  useEffect(() => {
    dispatch(getProgressHomepageCards.request());
  }, [dispatch]);

  useEffect(() => {
    if (currentRole === ROLES.ROLE_ADMIN) {
      dispatch(getSurveyResultAccess.request());
    }
  }, [currentRole, dispatch]);

  return (
    <div className={classes.wrapper}>
      <Typography variant="h2">Status overview</Typography>

      <Stack paddingTop={6} direction="row" flexWrap="wrap" justifyContent="space-between">
        {menuItems.map((item) => (
          <div
            key={item.id}
            className={classes.container}
            style={{ backgroundColor: item.backGroundColor }}>
            <span className={classes.title}>{item.title}</span>
            {item.callback && (
              <ButtonBase
                onClick={item.callback}
                color="secondary"
                variant="outlined"
                title="Setup"
              />
            )}

            <div
              className={classes.triangle}
              style={{ borderColor: `#eeeeef transparent #eeeeef ${item.backGroundColor}` }}></div>
          </div>
        ))}
      </Stack>
      <HomeStatusesOverview homePageCards={homepageCards} />
    </div>
  );
};

import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  inputContainer: {
    border: `1px solid ${COLORS.gray26}`,
    borderRadius: 8,
    height: '40px',
    width: '200px',
    outline: 'none',
    padding: '0 16px',
    fontSize: '16px',
    color: COLORS.black1,
    '&::placeholder': {
      color: COLORS.gray22,
    },
    '&:placeholder': {
      color: COLORS.gray25,
    },
    '&:hover': {
      borderColor: COLORS.blue2,
    },
    '&:focus': {
      borderColor: COLORS.blue11,
    },
  },
  inputDisabled: {
    color: COLORS.gray23,
    borderColor: COLORS.gray23,
    backgroundColor: COLORS.gray28,
  },
}));

export default useStyles;

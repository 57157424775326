import { makeStyles } from '@mui/styles';

import bgImage from 'src/assets/images/home-bg.png';
import { COLORS } from '../../../../../constants/COLORS';

const useStyles = makeStyles(() => ({
  wrapper: {
    backgroundImage: `url(${bgImage})`,
    padding: '20px 40px',
    margin: '-20px -40px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    paddingBottom: '40px',
    backgroundColor: 'transparent',
  },
  container: {
    position: 'relative',
    padding: '24px 16px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '380px',
    height: 54,
    flex: '0 0 32.333333%',
    minHeight: 42,
    overflow: 'hidden',
  },
  title: {
    fontSize: 18,
    color: COLORS.white1,
  },
  triangle: {
    position: 'absolute',
    right: 0,
    width: 11,
    borderWidth: '44px 0 44px 12px',
    borderStyle: 'solid',
  },
}));

export default useStyles;

import React from 'react';
import {
  CustomerRegistry,
  SurveyResultBusinessUnit,
  UpdateCustomerRegistryTable,
  UpdateCustomerRevenues,
} from 'src/types';
import useStyles from './useStyles';
import { Dropdown } from 'src/components/common/Dropdown';
import { Switch } from 'src/components/common/Switch';
import { ColoredRound } from 'src/components/common/ColoredRound';
import { GrowWrapperComponent } from 'src/components/common/GrowWrapperComponent';
import { v4 as uuidv4 } from 'uuid';
import { EditableCell } from 'src/components/common/EditableCell';

export type TableHeader = {
  id: number | string;
  title: string;
  isRevenue?: boolean;
};

type Props = {
  data: CustomerRegistry[];
  accountManagerNames: string[];
  businessUnits: SurveyResultBusinessUnit[];
  onSelectAccountManager: (id: string, name: string) => void;
  notForEditing: boolean;
  onUpdateCell: (values: UpdateCustomerRegistryTable) => void;
  onUpdateRevenue: (params: UpdateCustomerRevenues) => void;
};

export const CustomerRegistryTable: React.FC<Props> = ({
  data,
  onSelectAccountManager,
  accountManagerNames,
  businessUnits,
  notForEditing,
  onUpdateCell,
  onUpdateRevenue,
}) => {
  const classes = useStyles();

  const revenuesHistoryTHead1 = data[0]?.revenuesHistory.find((_, index) => index === 0) as any;
  const revenuesHistoryTHead2 = data[0]?.revenuesHistory.find((_, index) => index === 1) as any;

  const getTextForRevenueHead = (year?: number) => {
    if (year) {
      return `Revenue (Year y-${data[0]?.revenuesHistory.length + 1 - year})`;
    }
    return 'Revenue (Year y)';
  };

  const tHeadSegments1 =
    revenuesHistoryTHead1?.revenues?.map((el: any) => ({
      id: uuidv4(),
      title: el?.businessUnitName,
      isRevenue: true,
    })) || [];

  const tHeadSegments2 =
    revenuesHistoryTHead2?.revenues?.map((el: any) => ({
      id: uuidv4(),
      title: el?.businessUnitName,
      isRevenue: true,
    })) || [];

  const headers: TableHeader[] = [
    {
      id: 0,
      title: 'Customer',
    },
    {
      id: 1,
      title: 'Customer ID',
    },
    {
      id: 2,
      title: 'Parent',
    },
    {
      id: 3,
      title: 'Parent ID',
    },
    {
      id: 4,
      title: 'Long tail customer',
    },
    ...tHeadSegments1,
    ...tHeadSegments2,
    ...businessUnits.map((item) => ({
      id: uuidv4(),
      title: item.businessUnitName,
      isRevenue: true,
    })),

    {
      id: 8,
      title: 'Account Manager',
    },
    {
      id: 9,
      title: 'Negotiation on parent level',
    },
    {
      id: 10,
      title: 'Status',
    },
  ];

  return (
    <GrowWrapperComponent>
      <div className={classes.container}>
        <table className={classes.table}>
          <thead className={classes.tHeader}>
            <tr className={classes.revenueRow}>
              <td />
              <td />
              <td />
              <td />
              <td />
              {revenuesHistoryTHead1?.year && (
                <td colSpan={businessUnits.length}>
                  <div className={classes.revenueText}>
                    {getTextForRevenueHead(revenuesHistoryTHead1?.year)}
                  </div>
                  <span />
                </td>
              )}
              {revenuesHistoryTHead2?.year && (
                <td colSpan={businessUnits.length}>
                  <div className={classes.revenueText}>
                    {getTextForRevenueHead(revenuesHistoryTHead2?.year)}
                  </div>
                  <span />
                </td>
              )}
              {businessUnits.length > 0 && (
                <td colSpan={businessUnits.length}>
                  <div className={classes.revenueText}>{getTextForRevenueHead()}</div>
                  <span />
                </td>
              )}
              <td />
              <td />
              <td />
            </tr>
            <tr className={classes.tHeaderRow}>
              {headers.map((item) => (
                <td key={item?.id}>
                  <div
                    style={{
                      paddingLeft: 15,
                      width: item?.isRevenue ? 'max-content' : 'auto',
                      minWidth: 60,
                    }}>
                    {item?.title}
                  </div>
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row) => (
              <tr className={classes.tBodyRow} key={row.id}>
                <EditableCell
                  disabled={notForEditing}
                  onEditCb={(value) => {
                    onUpdateCell({
                      id: row.id,
                      name: value.trim(),
                    });
                  }}
                  editable
                  value={row.name}
                  innerContainerStyle={classes.nameCell}
                  innerInputStyle={classes.innerInputStyle}
                />
                <EditableCell
                  disabled={notForEditing}
                  onEditCb={(value) => {
                    onUpdateCell({
                      id: row.id,
                      newId: value,
                    });
                  }}
                  editable
                  value={row.id.toString()}
                  innerContainerStyle={classes.nameCell}
                  innerInputStyle={classes.innerInputStyle}
                />

                <EditableCell
                  disabled={notForEditing}
                  onEditCb={(value) => {
                    onUpdateCell({
                      id: row.id,
                      parent: value,
                    });
                  }}
                  editable
                  value={row.parent ? row.parent : '-'}
                  innerContainerStyle={classes.parentCell}
                  innerInputStyle={classes.parentInputCell}
                />

                <EditableCell
                  disabled={notForEditing}
                  onEditCb={(value) => {
                    onUpdateCell({
                      id: row.id,
                      parentId: value,
                    });
                  }}
                  editable
                  value={row.parentId ? row.parentId.toString() : '-'}
                  innerContainerStyle={classes.parentIdCell}
                  innerInputStyle={classes.parentIdInputCell}
                />
                <td>
                  <div style={{ minWidth: 145 }}>
                    <Switch
                      isChecked={row.longTailCustomer}
                      onChangeCb={(value) => {
                        onUpdateCell({
                          id: row.id,
                          longTailCustomer: value,
                        });
                      }}
                      disabled={notForEditing}
                    />
                  </div>
                </td>

                {row?.revenuesHistory.map((el: any) => {
                  return el.revenues.map((item: any) => (
                    <EditableCell
                      key={item.businessUnitId}
                      value={item.revenue ? item.revenue.toString() : '0'}
                      editable
                      isRevenue={true}
                      onlyNumbers
                      disabled={notForEditing}
                      innerContainerStyle={classes.revenueCell}
                      innerInputStyle={classes.revenueInput}
                      onEditCb={(value) => {
                        onUpdateRevenue({
                          customerId: row.id,
                          businessUnitAbbreviation: item.businessUnitName,
                          revenue: +value,
                        });
                      }}
                    />
                  ));
                })}

                {row.revenues.map((item) => (
                  <EditableCell
                    key={item.businessUnitId}
                    value={item.revenue ? item.revenue.toString() : '0'}
                    editable
                    isRevenue={true}
                    onlyNumbers
                    disabled={notForEditing}
                    innerContainerStyle={classes.revenueCell}
                    innerInputStyle={classes.revenueInput}
                    onEditCb={(value) => {
                      onUpdateRevenue({
                        customerId: row.id,
                        businessUnitAbbreviation: item.businessUnitName,
                        revenue: +value,
                      });
                    }}
                  />
                ))}
                <td>
                  <div>
                    <Dropdown
                      onSelectCb={(name) => {
                        if (row.id) {
                          onSelectAccountManager(row.id, name);
                        }
                      }}
                      placeholder="Manager name"
                      defaultItem={row.accountManager}
                      width={170}
                      dropdownItems={accountManagerNames}
                      disabled={notForEditing}
                    />
                  </div>
                </td>
                <td>
                  <div style={{ minWidth: 145 }}>
                    <Switch
                      isChecked={row.negotiationOnParentLevel}
                      onChangeCb={(value) => {
                        onUpdateCell({
                          id: row.id,
                          negotiation: value,
                        });
                      }}
                      disabled={notForEditing}
                    />
                  </div>
                </td>
                <td>
                  <div>
                    <ColoredRound status={row.status} />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </GrowWrapperComponent>
  );
};

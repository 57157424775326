import React, { FC, useCallback, useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { BattleCardsTable } from './BattleCardsTable';
import { Pagination } from '../../../../common/Pagination';
import { DEFAULT_COUNT_OF_ITEMS } from '../../../../../constants/dafaultCountOfItems';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'src/components/common/Loader';
import {
  getBattleCardsCustomersForAccountManager,
  getBattleCardsCustomersForAdmin,
} from '../../../../../modules/battleCards/actions';
import { Store } from '../../../../../types';
import { ButtonBase } from '../../../../common/ButtonBase';
import { useNavigate } from 'react-router-dom';

type BattleCardsProps = {
  isBattleCards?: boolean;
};

const BattleCards: FC<BattleCardsProps> = ({ isBattleCards }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(DEFAULT_COUNT_OF_ITEMS);

  const { loading, customersPageable, customers } = useSelector(
    (state: Store) => state.battleCards,
  );

  const goToPage = useCallback((page: number) => {
    setPage(page);
  }, []);

  const handleChangeRowsPerPage = (value: number) => {
    setRowsPerPage(value);
    setPage(0);
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    if (isBattleCards) {
      dispatch(
        getBattleCardsCustomersForAccountManager.request({
          pageNumber: page === 0 ? page : page - 1,
          itemsOnPage: rowsPerPage,
          sortBy: 'name',
          sortOrder: 'asc',
        }),
      );
    } else {
      dispatch(
        getBattleCardsCustomersForAdmin.request({
          pageNumber: page === 0 ? page : page - 1,
          itemsOnPage: rowsPerPage,
          sortBy: 'name',
          sortOrder: 'asc',
        }),
      );
    }
  }, [page, rowsPerPage]);

  const goBack = useCallback(() => {
    navigate('/prepare-implementation/support-material/battlecards-status');
  }, [navigate]);

  const goNext = useCallback(() => {
    navigate('/rollout-initiative/monitoring-reports/monitoring');
  }, [navigate]);

  return (
    <>
      <Typography variant="h2">Battlecards</Typography>
      {loading ? (
        <Loader isLoading marginTop={40} />
      ) : (
        <>
          <BattleCardsTable isBattleCards={isBattleCards} customers={customers} />
          <Pagination
            goToPage={goToPage}
            totalPages={customersPageable?.totalPages as number}
            setRowPerPage={handleChangeRowsPerPage}
            setPage={handleChangePage}
            page={page}
            rowPerPage={rowsPerPage}
            marginTop={4}
          />
        </>
      )}
      <Stack spacing={6} direction="row" marginTop={loading ? 60 : 8} justifyContent="flex-end">
        <ButtonBase
          variant="outlined"
          title="Battlecards status"
          onClick={goBack}
          icon="goBack"
          iconLeft={true}
        />
        <ButtonBase variant="contained" title="Monitoring" onClick={goNext} icon="next" />
      </Stack>
    </>
  );
};

export default BattleCards;

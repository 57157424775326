import React, { useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { InputLabel } from '@mui/material';

import { ReactComponent as ChevronDown } from 'src/assets/icons/chevron-down.svg';
import useStyles from './useStyles';
import { COLORS } from '../../../constants/COLORS';
import { DROPDOWN_MENU_MAX_HEIGHT } from 'src/constants';

type Props = {
  width?: number | string;
  height?: number;
  needDisableDefault?: boolean;
  label?: string;
  onSelectCb?: (selectedText: string) => void;
  dropdownButtonStyles?: string;
  id?: string;
  dropdownItems: string[];
  dropdownItemsWithObjects?: {
    value: string;
    id: number;
    color?: string;
  }[];
  defaultItem: string | null;
  dropdownArrowColor?: string;
  dropdownListStyles?: string;
  placeholder?: string;
  disabled?: boolean;
  containerStyle?: string;
  needUpdateProps?: boolean;
  showRounds?: boolean;
};

export const Dropdown: React.FC<Props> = ({
  width = 142,
  height = 40,
  dropdownItems,
  onSelectCb,
  label,
  dropdownButtonStyles,
  dropdownListStyles,
  id,
  dropdownItemsWithObjects,
  defaultItem,
  placeholder,
  disabled,
  containerStyle,
  needUpdateProps,
  showRounds,
}) => {
  const classes = useStyles();

  const [value, setValue] = React.useState('');

  const handleChange = (event: SelectChangeEvent<typeof value>) => {
    const {
      target: { value },
    } = event;
    setValue(value);

    if (dropdownItemsWithObjects && onSelectCb) {
      const item = dropdownItemsWithObjects.find((item) => item.value === value);

      if (item) {
        onSelectCb(item.id.toString());

        return;
      }
    }

    onSelectCb && onSelectCb(value);
  };

  useEffect(() => {
    if (dropdownItemsWithObjects) {
      const foundItem = dropdownItemsWithObjects.find((a) => a.value === defaultItem);

      setValue((foundItem && foundItem.value) || '');
    }

    if (dropdownItems.length > 0) {
      setValue(dropdownItems.find((a) => a === defaultItem) || '');
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (needUpdateProps && dropdownItems.length > 0) {
      setValue(dropdownItems.find((a) => a === defaultItem) || '');
    }
  }, [defaultItem, dropdownItems, needUpdateProps]);

  return (
    <div
      className={`${containerStyle && containerStyle}`}
      style={{ display: 'flex', flexDirection: 'column' }}>
      {label && <p className={classes.label}>{label}</p>}
      <FormControl
        style={{ width, minWidth: 133 }}
        classes={{ root: `${classes.root} ${dropdownButtonStyles && dropdownButtonStyles}` }}>
        <InputLabel>{placeholder}</InputLabel>
        <Select
          disabled={disabled}
          id={id}
          style={{ width: '100%' }}
          sx={{ height }}
          IconComponent={(props) => {
            return <ChevronDown {...props} fill={disabled ? COLORS.gray23 : COLORS.gray26} />;
          }}
          value={value}
          label={placeholder}
          onChange={handleChange}
          MenuProps={{
            classes: { paper: `${classes.select} ${dropdownListStyles && dropdownListStyles}` },
            PaperProps: {
              style: {
                maxHeight: DROPDOWN_MENU_MAX_HEIGHT,
                borderRadius: 8,
                // minWidth: width,
              },
            },
          }}>
          {dropdownItemsWithObjects
            ? dropdownItemsWithObjects.map((item) => (
                <MenuItem disabled={item.value === 'No status'} key={item.id} value={item.value}>
                  <div style={{ display: 'flex', alignItems: 'center', flexShrink: 0 }}>
                    {showRounds && (
                      <div
                        style={{
                          width: 18,
                          height: 18,
                          borderRadius: '100%',
                          backgroundColor: item.color,
                          marginRight: 10,
                        }}
                      />
                    )}

                    {item.value}
                  </div>
                </MenuItem>
              ))
            : dropdownItems.map((name, index) => (
                <MenuItem key={index} value={name}>
                  {name}
                </MenuItem>
              ))}
        </Select>
      </FormControl>
    </div>
  );
};

import React from 'react';
import { useLocation } from 'react-router-dom';

import List from '@mui/material/List';

import useStyles from './useStyles';
import { NavItem } from './components/NavItem';
import { ROLES } from 'src/constants/ROLES';

export type Sublink = {
  id: number;
  text: string;
  url: string;
};

export type NestedLink = {
  id: number;
  text: string;
  hide?: boolean;
  url?: string;
  disabled?: boolean;
  sublinks?: Sublink[];
};

export type MenuLink = {
  id: number;
  url?: string;
  text: string;
  icon: string;
  open?: boolean;
  disabled?: boolean;
  nestedLinks?: NestedLink[];
};

type Props = {
  handleDrawerOpen: () => void;
  isDrawerOpen: boolean;
  menuLinks: MenuLink[];
  role: ROLES;
};

export const MainNavigation: React.FC<Props> = ({
  handleDrawerOpen,
  isDrawerOpen,
  menuLinks,
  role,
}) => {
  const classes = useStyles();
  const { pathname } = useLocation();

  return (
    <List
      className={classes.nav}
      onClick={handleDrawerOpen}
      component="nav"
      aria-labelledby="nested-list-subheader">
      {menuLinks.map((link) => (
        <NavItem
          role={role}
          disabled={link.disabled}
          isDrawerOpen={isDrawerOpen}
          icon={link.icon}
          isDropdownOpen={link.open}
          pathName={pathname}
          url={link.url}
          key={link.id}
          name={link.text}
          nestedLinks={link.nestedLinks}
        />
      ))}
    </List>
  );
};

import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../constants/COLORS';

const useStyles = makeStyles(() => ({
  modalCheckboxContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    marginTop: 16,
  },
  label: {
    fontSize: 16,
    marginLeft: 12,
  },
  checkboxContainer: {
    display: 'flex',
    width: 225,
    padding: '0 16px',
    alignItems: 'center',
    height: 40,
    borderRadius: 8,
    border: `1px solid ${COLORS.black6}`,
  },
  checkboxText: {
    marginLeft: 7,
  },
}));

export default useStyles;

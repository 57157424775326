import React, { memo } from 'react';
import useStyles from './useStyles';
import {
  PricePotentialMatrix,
  PricePotentialMatrixCategory,
  PricePotentialMatrixCostDriver,
  PricePotentialMatrixWeight,
} from 'src/types';
import { MatrixFirstPartRow } from '../MatrixFirstPartRow';
import { useDispatch } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import {
  updatePricePotentialCostDriver,
  updatePricePotentialMatrixIndicator,
  updatePricePotentialMatrixWeight,
} from 'src/modules/campaignTarget/actions';
import { MatrixSecondPartRow } from '../MatrixSecondPartRow';
import { WeightRow } from '../WeightRow';

import { GrowWrapperComponent } from 'src/components/common/GrowWrapperComponent';
import { useHorizontalScroll } from 'src/hooks/useHorizontalScroll';
import { COLORS } from '../../../../../../../constants/COLORS';

import { ReactComponent as OutlinePlusIcon } from 'src/assets/icons/outlinePlus.svg';

type Props = {
  pricePotentialsData: PricePotentialMatrix[];
  costDrivers: PricePotentialMatrixCostDriver[];
  categories: PricePotentialMatrixCategory[];
  weights: PricePotentialMatrixWeight[];
  disabled: boolean;
};

export const Matrix: React.FC<Props> = memo(function Matrix({
  pricePotentialsData,
  costDrivers,
  categories,
  weights,
  disabled,
}) {
  const classes = useStyles();

  const scrollRef = useHorizontalScroll();

  const dispatch = useDispatch();

  const updateCostDriver = useDebouncedCallback((body) => {
    dispatch(
      updatePricePotentialCostDriver.request({
        body,
      }),
    );
  }, 400);

  const updateIndicator = useDebouncedCallback((body) => {
    dispatch(
      updatePricePotentialMatrixIndicator.request({
        body,
      }),
    );
  }, 400);

  const updateWeight = useDebouncedCallback((body) => {
    dispatch(
      updatePricePotentialMatrixWeight.request({
        body,
      }),
    );
  }, 400);

  return (
    <GrowWrapperComponent>
      <div ref={scrollRef} className={classes.wrapper}>
        <table className={classes.table}>
          <thead>
            <tr>
              <th colSpan={2}></th>
              <th colSpan={costDrivers.length}>
                <p>Cost development</p>
              </th>
            </tr>
            <tr>
              <th>
                <p>Business unit</p>
              </th>
              <th>
                <p>Revenue</p>
              </th>
              {costDrivers.map((costDriver) => (
                <th key={costDriver.costDriverId}>
                  <p>{costDriver.costDriverName}</p>
                </th>
              ))}
            </tr>
            <tr>
              <td style={{ backgroundColor: COLORS.pink8 }}>
                <p></p>
              </td>
              <td
                className={classes.secondTableSecondRowBorder}
                style={{ backgroundColor: COLORS.pink8 }}>
                <p></p>
              </td>
              {costDrivers.map((costDriver) => (
                <th key={costDriver.costDriverId}>
                  <p></p>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {pricePotentialsData.map((item) => (
              <MatrixFirstPartRow
                disabled={disabled}
                updateCostDriver={updateCostDriver}
                key={item.businessUnitId}
                businessUnitId={item.businessUnitId}
                businessUnitAbbreviation={item.businessUnitAbbreviation}
                revenue={item.revenue}
                costDrivers={item.costDrivers}
              />
            ))}
          </tbody>
        </table>
        <div className={classes.tableSeparatorContainer}>
          <OutlinePlusIcon />
        </div>
        <table className={`${classes.table} ${classes.tableTwo}`}>
          <thead>
            <tr>
              {categories.map((category, index, array) => (
                <th
                  style={{ borderTopRightRadius: index === array.length - 1 ? 0 : '' }}
                  className={classes.secondTableFirstColumn}
                  colSpan={category.indicators.length}
                  key={category.categoryId}>
                  <p>{category.categoryName}</p>
                </th>
              ))}
              <th style={{ backgroundColor: COLORS.white1 }}>
                <p />
              </th>
              <th style={{ backgroundColor: COLORS.white1 }}>
                <p />
              </th>
              <th style={{ backgroundColor: COLORS.white1 }}>
                <p />
              </th>
            </tr>
            <tr>
              {categories.map((category) => {
                return category.indicators.map((indicator, index, array) => (
                  <th
                    style={{ padding: '0 24px' }}
                    className={`${classes.secondTableSecondRow} ${
                      index === array.length - 1 ? classes.borderRightAbsolute : ''
                    } ${index === array.length - 1 ? classes.secondTableSecondRowBorder : ''}`}
                    key={indicator.indicatorId}>
                    <p>{indicator.indicatorName}</p>
                  </th>
                ));
              })}
              <th
                style={{ padding: '0 24px' }}
                rowSpan={2}
                className={`${classes.additionalRightColumn} ${classes.additionalFieldsFirstTwoCells}`}>
                <p>Weight. score</p>
              </th>
              <th
                style={{ padding: '0 24px', width: 170 }}
                rowSpan={2}
                className={`${classes.additionalRightColumn} 
                ${classes.secondTableSecondRowBorder} ${classes.additionalFieldsFirstTwoCells}`}>
                <p> Price potential beyond cost in-crease</p>
                <span className={classes.borderRightAdditionalFields} />
              </th>
              <th
                style={{ padding: '0 24px' }}
                rowSpan={2}
                className={`${classes.additionalRightColumn} ${classes.additionalFieldsLast}`}>
                <p> Total target</p>
              </th>
            </tr>
            <WeightRow disabled={disabled} updateWeight={updateWeight} weights={weights} />
          </thead>
          <tbody>
            {pricePotentialsData.map((item) => (
              <MatrixSecondPartRow
                disabled={disabled}
                weightScore={item.weightScore}
                totalTarget={item.totalTarget}
                pricePotential={item.pricePotential}
                businessUnitId={item.businessUnitId}
                updateIndicator={updateIndicator}
                key={item.businessUnitId}
                categories={item.categories}
              />
            ))}
          </tbody>
        </table>
      </div>
    </GrowWrapperComponent>
  );
});

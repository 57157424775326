import { takeLatest, put, select } from 'redux-saga/effects';
import { ActionType } from 'deox';
import {
  getNegotiationProcess,
  getNegotiationStatus,
  updateConfigOfSecondSurvey,
  getConfigOfSecondSurvey,
  getMonitoringInfoScreen,
  getMonitoringSurvey,
  updateMonitoringByAdmin,
  getPerformanceBreakdown,
  getMonitoringDates,
  downloadMonitoringExcel,
} from './actions';

import { processRequestError } from '../Errors/actions';
import { API } from 'src/api';
import { Store } from 'src/types';
import { toast } from 'react-toastify';
import { downloadFile } from '../../api/downloadFile';

function* getMonitoringSurveySaga({ payload }: ActionType<typeof getMonitoringSurvey.request>) {
  try {
    const { projectId } = yield select((state: Store) => state.projects);

    const { data } = yield API.getMonitoringSurvey({ ...payload, projectId });

    yield put(getMonitoringSurvey.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: getMonitoringSurvey.fail }));
  }
}

function* getNegotiationProcessSaga({ payload }: ActionType<typeof getNegotiationProcess.request>) {
  try {
    const { projectId } = yield select((state: Store) => state.projects);

    const { data } = yield API.getNegotiationProcess({ ...payload, projectId });

    yield put(getNegotiationProcess.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: getNegotiationProcess.fail }));
  }
}

function* getNegotiationStatusSaga({ payload }: ActionType<typeof getNegotiationStatus.request>) {
  try {
    const { projectId } = yield select((state: Store) => state.projects);

    const { data } = yield API.getNegotiationStatus({
      body: payload.body,
      params: { projectId },
      signal: payload.signal,
    });

    yield put(getNegotiationStatus.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: getNegotiationStatus.fail }));
  }
}

function* getConfigOfSecondSurveySaga() {
  try {
    const { projectId } = yield select((state: Store) => state.projects);

    const { data } = yield API.getConfOfSecondSurvey({ projectId });

    yield put(getConfigOfSecondSurvey.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: getConfigOfSecondSurvey.fail }));
  }
}

function* updateConfigOfSecondSurveySaga({
  payload,
}: ActionType<typeof updateConfigOfSecondSurvey.request>) {
  try {
    const { body, onSuccessCb } = payload;
    const { projectId } = yield select((state: Store) => state.projects);

    const config = {
      body,
      params: { projectId },
    };

    yield API.updateConfOfSecondSurvey(config);

    yield put(updateConfigOfSecondSurvey.success());

    onSuccessCb && onSuccessCb();

    toast.success('Success');
  } catch (error) {
    yield put(processRequestError({ error, failAction: updateConfigOfSecondSurvey.fail }));
  }
}

function* getMonitoringInfoScreenSaga({
  payload,
}: ActionType<typeof getMonitoringInfoScreen.request>) {
  try {
    const { projectId } = yield select((state: Store) => state.projects);

    const { data } = yield API.getInfoForMonitoringScreen({ ...payload, projectId });

    yield put(getMonitoringInfoScreen.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: getMonitoringInfoScreen.fail }));
  }
}

function* updateMonitoringByAdminSaga({
  payload,
}: ActionType<typeof updateMonitoringByAdmin.request>) {
  try {
    const { projectId } = yield select((state: Store) => state.projects);

    yield API.updateMonitoringByAdmin({ ...payload }, projectId);
  } catch (error) {
    yield put(processRequestError({ error, failAction: getMonitoringInfoScreen.fail }));
  }
}

function* getPerformanceBreakdownSaga({
  payload,
}: ActionType<typeof getPerformanceBreakdown.request>) {
  try {
    const { projectId } = yield select((state: Store) => state.projects);

    const { data } = yield API.getPerformanceBreakdown({
      body: payload.body,
      params: { projectId },
      signal: payload.signal,
    });

    yield put(getPerformanceBreakdown.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: getPerformanceBreakdown.fail }));
  }
}

function* getMonitoringDatesSaga() {
  try {
    const { projectId } = yield select((state: Store) => state.projects);

    const { data } = yield API.getMonitoringDates({
      projectId,
    });

    yield put(getMonitoringDates.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: getMonitoringDates.fail }));
  }
}

function* downloadMonitoringExcelSaga({
  payload,
}: ActionType<typeof downloadMonitoringExcel.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.downloadMonitoringExcel({ projectId, date: payload });

    yield downloadFile(data, 'monitoring.xlsx');
  } catch (error) {
    yield put(processRequestError({ error, failAction: downloadMonitoringExcel.fail }));
  }
}

export function* watchMonitoring() {
  yield takeLatest(getConfigOfSecondSurvey.request, getConfigOfSecondSurveySaga);
  yield takeLatest(getNegotiationProcess.request, getNegotiationProcessSaga);
  yield takeLatest(getNegotiationStatus.request, getNegotiationStatusSaga);
  yield takeLatest(updateConfigOfSecondSurvey.request, updateConfigOfSecondSurveySaga);
  yield takeLatest(getMonitoringInfoScreen.request, getMonitoringInfoScreenSaga);
  yield takeLatest(updateMonitoringByAdmin.request, updateMonitoringByAdminSaga);
  yield takeLatest(getMonitoringSurvey.request, getMonitoringSurveySaga);
  yield takeLatest(getPerformanceBreakdown.request, getPerformanceBreakdownSaga);
  yield takeLatest(getMonitoringDates.request, getMonitoringDatesSaga);
  yield takeLatest(downloadMonitoringExcel.request, downloadMonitoringExcelSaga);
}

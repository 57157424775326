import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  notFoundWrapper: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  notFound: {
    textAlign: 'center',
  },
  notFoundText: {
    color: '#4F4F4F',
    fontSize: 36,
    marginBottom: 15,
  },
}));

export default useStyles;

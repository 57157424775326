import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Stack } from '@mui/material';
import { CAMPAIGN_TARGETS } from 'src/constants/CAMPAIGN_TARGETS';
import useStyles from './useStyles';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  getPricePotentialMatrix,
  updateApproachCampaignTarget as updateApproachCampaignTargetAction,
  updatePricePotentialAdditionalFields as updatePricePotentialAdditionalFieldsAction,
} from 'src/modules/campaignTarget/actions';
import { CampaignTargetHeaderController } from 'src/components/common/CampaignTargetHeaderController';

import { Store } from 'src/types';

import { TargetGivenContainer } from 'src/components/common/TargetGivenContainer';
import { AdditionalFieldsBox } from './components/AdditionalFieldsBox';
import { Matrix } from './components/Matrix';
import { Loader } from 'src/components/common/Loader';
import { ButtonBase } from 'src/components/common/ButtonBase';
import { ForbidEditCheckbox } from 'src/components/common/ForbidEditCheckbox';

import { COLORS } from 'src/constants/COLORS';
import { ScreenNames } from 'src/constants/ScreenNames';
import { toast } from 'react-toastify';
import { StepNames } from '../../../../../constants/stepNames';
import { Stepper } from '../../../../common/Stepper';

export const PricePotentialMatrix: React.FC = () => {
  const classes = useStyles();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { pricePotentialsMatrix, loading, campaignTargetApproach, targetsForBusinessUnits } =
    useSelector((state: Store) => state.campaignTarget);

  const { disableEditPage } = useSelector((state: Store) => state.home);

  const [sectionSelected, setSectionSelected] = useState<string>(campaignTargetApproach);

  const handleChange = useCallback(
    (value: string) => {
      setSectionSelected(value);

      dispatch(
        updateApproachCampaignTargetAction.request({
          approach: value,
        }),
      );
    },
    [dispatch],
  );

  const checkWeights = useCallback(() => {
    if (pricePotentialsMatrix) {
      const totalValue = pricePotentialsMatrix.weights.reduce((prev, item) => {
        return prev + item.weight;
      }, 0);

      if (totalValue !== 100) {
        toast.error('Weightings do not sum up to 100% for all segments');

        return;
      }

      return true;
    }
  }, [pricePotentialsMatrix]);

  useEffect(() => {
    handleChange(campaignTargetApproach);
  }, [campaignTargetApproach, handleChange]);

  const RenderSelectedTarget = useMemo(() => {
    switch (sectionSelected) {
      case CAMPAIGN_TARGETS.TARGET_GIVEN:
        return <TargetGivenContainer />;
      case CAMPAIGN_TARGETS.PRICE_POTENTIAL:
        return !pricePotentialsMatrix || loading ? (
          <Loader marginTop={20} isLoading={true} />
        ) : (
          <div>
            <h3 className={classes.title}>Price potential matrix</h3>
            <Matrix
              costDrivers={pricePotentialsMatrix.costDrivers}
              pricePotentialsData={pricePotentialsMatrix.pricePotentials}
              categories={pricePotentialsMatrix.categories}
              weights={pricePotentialsMatrix.weights}
              disabled={disableEditPage}
            />
          </div>
        );
    }
  }, [disableEditPage, loading, pricePotentialsMatrix, sectionSelected]);

  const updatePricePotentialAdditionalFields = useCallback(
    (body) => {
      dispatch(
        updatePricePotentialAdditionalFieldsAction.request({
          body,
        }),
      );
    },
    [dispatch],
  );

  const goBack = useCallback(() => {
    navigate('/configure-levels/campaign-target/target-given');
  }, [navigate]);

  const goNext = useCallback(
    (url: string) => {
      if (url === 'customer-specific-targets') {
        navigate('/configure-levels/customer-specific-targets');

        return;
      }

      navigate(`/configure-levels/campaign-target/${url}`);
    },
    [navigate],
  );

  const EnhancedButtonsComponent = useMemo(() => {
    const nextPage =
      sectionSelected === CAMPAIGN_TARGETS.TARGET_GIVEN
        ? { name: 'EOI weighting', url: 'customer-specific-targets' }
        : { name: 'Target setting', url: 'target-setting' };

    return (
      <Stack spacing={4} direction="row" marginTop={8} justifyContent="flex-end">
        <ForbidEditCheckbox
          showCheckbox={
            targetsForBusinessUnits ? targetsForBusinessUnits.targets.length > 0 : false
          }
          checkboxValue={disableEditPage}
          screenName={
            sectionSelected === CAMPAIGN_TARGETS.PRICE_POTENTIAL
              ? ScreenNames.PRICE_POTENTIAL_MATRIX
              : ScreenNames.CAMPAIGN_TARGET_TARGET_GIVEN
          }
        />
        <Stack width="100%" direction="row" justifyContent="flex-end" spacing={4}>
          <ButtonBase
            iconColor={COLORS.red1}
            variant="outlined"
            title="Campaign target"
            onClick={goBack}
            iconLeft={true}
            icon="goBack"
          />
          <ButtonBase
            iconColor={COLORS.red1}
            variant="contained"
            title={nextPage.name}
            onClick={() => {
              const canGoNext = checkWeights();

              if (canGoNext) {
                goNext(nextPage.url);
              }
            }}
            icon="next"
          />
        </Stack>
      </Stack>
    );
  }, [checkWeights, disableEditPage, goBack, goNext, sectionSelected, targetsForBusinessUnits]);

  useEffect(() => {
    if (sectionSelected === CAMPAIGN_TARGETS.PRICE_POTENTIAL) {
      dispatch(getPricePotentialMatrix.request());
    }
  }, [dispatch, sectionSelected]);

  return (
    <div>
      <Stepper
        steps={[
          {
            name: 'Touchable revenue',
            activeIndex: null,
            allCompleted: true,
          },
          {
            name: 'Campaign target',
            activeIndex: 0,
          },
          {
            name: 'Customer-specific targets',
            activeIndex: null,
          },
        ]}
        type={StepNames.ALL}
      />

      <div className={classes.container}>
        <CampaignTargetHeaderController
          sectionSelected={sectionSelected}
          handleChange={handleChange}
        />
        {pricePotentialsMatrix && sectionSelected === CAMPAIGN_TARGETS.PRICE_POTENTIAL && (
          <AdditionalFieldsBox
            disabled={disableEditPage}
            updatePricePotentialAdditionalFields={updatePricePotentialAdditionalFields}
            minScore={pricePotentialsMatrix.minScore}
            maxScore={pricePotentialsMatrix.maxScore}
            minRange={pricePotentialsMatrix.minRange}
            maxRange={pricePotentialsMatrix.maxRange}
          />
        )}
      </div>

      {RenderSelectedTarget}

      {EnhancedButtonsComponent}
    </div>
  );
};

import React, { memo } from 'react';
import useStyles from './useStyles';
import { COLORS } from 'src/constants/COLORS';

type Props = {
  isEven: boolean;
};

export const EmptyCell: React.FC<Props> = memo(function EmptyCell({ children, isEven }) {
  const classes = useStyles();

  return (
    <td
      style={{ backgroundColor: isEven ? COLORS.white1 : COLORS.blue13 }}
      className={classes.container}>
      <div>{children}</div>
    </td>
  );
});

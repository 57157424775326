import { createAction } from 'deox';
import {
  AccountManagersListResponse,
  CustomerRegistryResponse,
  DownloadSurveyResultParamsRequest,
  GetAccountManagerNamesResponse,
  GetAccountManagersListRequestParams,
  GetBusinessUnitsRequestParams,
  GetBusinessUnitsResponse,
  GetCustomerRegistryRequestParams,
  GetRevenueBaseRequestParams,
  GetRevenueBaseResponse,
  GetSurveyStatusesRequestParams,
  SendSurveyToAccountManagerRequestBody,
  SendSurveyToAllRequestParams,
  SetAccountManagerRequestParams,
  SurveyStatusResponse,
  UpdateAccountManagersTable,
  UpdateBusinessUnitsTable,
  UpdateCustomerRegistryTable,
  UpdateCustomerRegistryTableResponse,
  UpdateCustomerRevenues,
} from 'src/types';
import { ResponseError } from 'src/modules/Errors/actions';

export const getBusinessUnits = {
  request: createAction(
    'touchableRevenue/GET_BUSINESS_UNITS_REQUEST',
    (resolve) => (payload: GetBusinessUnitsRequestParams) => resolve(payload),
  ),
  success: createAction(
    'touchableRevenue/GET_BUSINESS_UNITS_SUCCESS',
    (resolve) => (payload: GetBusinessUnitsResponse) => resolve(payload),
  ),
  fail: createAction(
    'touchableRevenue/GET_BUSINESS_UNITS_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const updateBusinessUnitsTable = {
  request: createAction(
    'touchableRevenue/UPDATE_BUSINESS_UNITS_TABLE_REQUEST',
    (resolve) => (payload: UpdateBusinessUnitsTable) => resolve(payload),
  ),
  success: createAction(
    'touchableRevenue/UPDATE_BUSINESS_UNITS_TABLE_SUCCESS',
    (resolve) => () => resolve(),
  ),
  fail: createAction(
    'touchableRevenue/UPDATE_BUSINESS_UNITS_TABLE_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const exportBusinessUnitsExcel = {
  request: createAction(
    'touchableRevenue/EXPORT_BUSINESS_UNITS_EXCEL_REQUEST',
    (resolve) => (payload: { file: File; params: { itemsOnPage: number; sortBy: string } }) =>
      resolve(payload),
  ),
  success: createAction(
    'touchableRevenue/EXPORT_BUSINESS_UNITS_EXCEL_SUCCESS',
    (resolve) => (payload: GetBusinessUnitsResponse) => resolve(payload),
  ),
  fail: createAction(
    'touchableRevenue/EXPORT_BUSINESS_UNITS_EXCEL_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const downloadBusinessUnitsExcel = {
  request: createAction(
    'touchableRevenue/DOWNLOAD_BUSINESS_UNITS_REQUEST',
    (resolve) => () => resolve(),
  ),
  success: createAction(
    'touchableRevenue/DOWNLOAD_BUSINESS_UNITS_SUCCESS',
    (resolve) => (payload: File) => resolve(payload),
  ),
  fail: createAction(
    'touchableRevenue/DOWNLOAD_BUSINESS_UNITS_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const downloadAccountManagersListExcel = {
  request: createAction(
    'touchableRevenue/DOWNLOAD_ACCOUNT_MANAGERS_LIST_REQUEST',
    (resolve) => () => resolve(),
  ),
  success: createAction(
    'touchableRevenue/DOWNLOAD_ACCOUNT_MANAGERS_LIST_SUCCESS',
    (resolve) => (payload: File) => resolve(payload),
  ),
  fail: createAction(
    'touchableRevenue/DOWNLOAD_ACCOUNT_MANAGERS_LIST_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const exportAccountManagersListExcel = {
  request: createAction(
    'touchableRevenue/EXPORT_ACCOUNT_MANAGERS_LIST_REQUEST',
    (resolve) => (payload: { file: File; params: { itemsOnPage: number } }) => resolve(payload),
  ),
  success: createAction(
    'touchableRevenue/EXPORT_ACCOUNT_MANAGERS_LIST_SUCCESS',
    (resolve) => (payload: AccountManagersListResponse) => resolve(payload),
  ),
  fail: createAction(
    'touchableRevenue/EXPORT_ACCOUNT_MANAGERS_LIST_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const getAccountManagersList = {
  request: createAction(
    'touchableRevenue/GET_ACCOUNT_MANAGERS_LIST_REQUEST',
    (resolve) => (payload: GetAccountManagersListRequestParams) => resolve(payload),
  ),
  success: createAction(
    'touchableRevenue/GET_ACCOUNT_MANAGERS_LIST_SUCCESS',
    (resolve) => (payload: AccountManagersListResponse) => resolve(payload),
  ),
  fail: createAction(
    'touchableRevenue/GET_ACCOUNT_MANAGERS_LIST_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const updateAccountMangersTable = {
  request: createAction(
    'touchableRevenue/UPDATE_ACCOUNT_MANAGERS_TABLE_REQUEST',
    (resolve) => (payload: UpdateAccountManagersTable) => resolve(payload),
  ),
  success: createAction(
    'touchableRevenue/UPDATE_ACCOUNT_MANAGERS_TABLE_SUCCESS',
    (resolve) => () => resolve(),
  ),
  fail: createAction(
    'touchableRevenue/UPDATE_ACCOUNT_MANAGERS_TABLE_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const updateCustomerRevenues = {
  request: createAction(
    'touchableRevenue/UPDATE_CUSTOMER_REVENUES_REQUEST',
    (resolve) => (payload: UpdateCustomerRevenues) => resolve(payload),
  ),
  success: createAction(
    'touchableRevenue/UPDATE_CUSTOMER_REVENUES_SUCCESS',
    (resolve) => () => resolve(),
  ),
  fail: createAction(
    'touchableRevenue/UPDATE_CUSTOMER_REVENUES_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const downloadCustomerRegistryExcel = {
  request: createAction(
    'touchableRevenue/DOWNLOAD_CUSTOMER_REGISTRY_REQUEST',
    (resolve) => (payload: string) => resolve(payload),
  ),
  success: createAction(
    'touchableRevenue/DOWNLOAD_CUSTOMER_REGISTRY_SUCCESS',
    (resolve) => (payload: File) => resolve(payload),
  ),
  fail: createAction(
    'touchableRevenue/DOWNLOAD_CUSTOMER_REGISTRY_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const exportCustomerRegistryExcel = {
  request: createAction(
    'touchableRevenue/EXPORT_CUSTOMER_REGISTRY_REQUEST',
    (resolve) =>
      (payload: { requestData: { file: File; itemsOnPage: number }; onSuccessCb?: () => void }) =>
        resolve(payload),
  ),
  success: createAction(
    'touchableRevenue/EXPORT_CUSTOMER_REGISTRY_SUCCESS',
    (resolve) => (payload: CustomerRegistryResponse) => resolve(payload),
  ),
  fail: createAction(
    'touchableRevenue/EXPORT_CUSTOMER_REGISTRY_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const getCustomerRegistry = {
  request: createAction(
    'touchableRevenue/GET_CUSTOMER_REGISTRY_REQUEST',
    (resolve) => (payload: GetCustomerRegistryRequestParams) => resolve(payload),
  ),
  success: createAction(
    'touchableRevenue/GET_CUSTOMER_REGISTRY_SUCCESS',
    (resolve) => (payload: CustomerRegistryResponse) => resolve(payload),
  ),
  fail: createAction(
    'touchableRevenue/GET_CUSTOMER_REGISTRY_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const updateCustomerRegistryTable = {
  request: createAction(
    'touchableRevenue/UPDATE_CUSTOMER_REGISTRY_TABLE_REQUEST',
    (resolve) =>
      (payload: {
        payload: UpdateCustomerRegistryTable;
        pageableData: GetCustomerRegistryRequestParams;
      }) =>
        resolve(payload),
  ),
  success: createAction(
    'touchableRevenue/UPDATE_CUSTOMER_REGISTRY_TABLE_SUCCESS',
    (resolve) => (payload: UpdateCustomerRegistryTableResponse) => resolve(payload),
  ),
  fail: createAction(
    'touchableRevenue/UPDATE_CUSTOMER_REGISTRY_TABLE_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const getAccountManagerNames = {
  request: createAction(
    'touchableRevenue/GET_ACCOUNT_MANAGER_NAMES_REQUEST',
    (resolve) => () => resolve(),
  ),
  success: createAction(
    'touchableRevenue/GET_ACCOUNT_MANAGER_NAMES_SUCCESS',
    (resolve) => (payload: GetAccountManagerNamesResponse) => resolve(payload),
  ),
  fail: createAction(
    'touchableRevenue/GET_ACCOUNT_MANAGER_NAMES_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const setAccountManager = {
  request: createAction(
    'touchableRevenue/SET_ACCOUNT_MANAGER_REQUEST',
    (resolve) => (payload: SetAccountManagerRequestParams) => resolve(payload),
  ),
  success: createAction(
    'touchableRevenue/SET_ACCOUNT_MANAGER_SUCCESS',
    (resolve) => (payload: { id: string; accountManager: string }) => resolve(payload),
  ),
  fail: createAction(
    'touchableRevenue/SET_ACCOUNT_MANAGER_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const sendSurveyToAll = {
  request: createAction(
    'touchableRevenue/SEND_SURVEY_TO_ALL_REQUEST',
    (resolve) => (payload: SendSurveyToAllRequestParams) => resolve(payload),
  ),
  success: createAction(
    'touchableRevenue/SEND_SURVEY_TO_ALL_SUCCESS',
    (resolve) => (payload: { isSecondSurveyStatus: boolean }) => resolve(payload),
  ),
  fail: createAction(
    'touchableRevenue/SEND_SURVEY_TO_ALL_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const getSurveyStatuses = {
  request: createAction(
    'touchableRevenue/GET_SURVEY_STATUSES_REQUEST',
    (resolve) => (payload: GetSurveyStatusesRequestParams) => resolve(payload),
  ),
  success: createAction(
    'touchableRevenue/GET_SURVEY_STATUSES_SUCCESS',
    (resolve) => (payload: SurveyStatusResponse) => resolve(payload),
  ),
  fail: createAction(
    'touchableRevenue/GET_SURVEY_STATUSES_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const sendSurveyToAccountManager = {
  request: createAction(
    'touchableRevenue/SEND_SURVEY_TO_ACCOUNT_MANAGER_REQUEST',
    (resolve) =>
      (payload: {
        params: {
          surveyNumber: number;
        };
        body: SendSurveyToAccountManagerRequestBody;
      }) =>
        resolve(payload),
  ),
  success: createAction(
    'touchableRevenue/SEND_SURVEY_TO_ACCOUNT_MANAGER_SUCCESS',
    (resolve) => (payload: { accountManagerId: number; isSecondSurvey?: boolean }) =>
      resolve(payload),
  ),
  fail: createAction(
    'touchableRevenue/SEND_SURVEY_TO_ACCOUNT_MANAGER_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const getAccountManagerGroups = {
  request: createAction(
    'touchableRevenue/GET_ACCOUNT_MANAGER_GROUPS_REQUEST',
    (resolve) => () => resolve(),
  ),
  success: createAction(
    'touchableRevenue/GET_ACCOUNT_MANAGER_GROUPS_SUCCESS',
    (resolve) => (payload: string[]) => resolve(payload),
  ),
  fail: createAction(
    'touchableRevenue/GET_ACCOUNT_MANAGER_GROUPS_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const getRevenueBase = {
  request: createAction(
    'touchableRevenue/GET_REVENUE_BASE_REQUEST',
    (resolve) => (payload: GetRevenueBaseRequestParams) => resolve(payload),
  ),
  success: createAction(
    'touchableRevenue/GET_REVENUE_BASE_SUCCESS',
    (resolve) => (payload: GetRevenueBaseResponse) => resolve(payload),
  ),
  fail: createAction(
    'touchableRevenue/GET_REVENUE_BASE_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const getBusinessUnitsName = {
  request: createAction(
    'touchableRevenue/GET_BUSINESS_UNITS_NAME_REQUEST',
    (resolve) => () => resolve(),
  ),
  success: createAction(
    'touchableRevenue/GET_BUSINESS_UNITS_NAME_SUCCESS',
    (resolve) => (payload: string[]) => resolve(payload),
  ),
  fail: createAction(
    'touchableRevenue/GET_BUSINESS_UNITS_NAME_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const downloadSurveyResultExcel = {
  request: createAction(
    'touchableRevenue/DOWNLOAD_SURVEY_RESULT_REQUEST',
    (resolve) => (payload: DownloadSurveyResultParamsRequest) => resolve(payload),
  ),
  success: createAction(
    'touchableRevenue/DOWNLOAD_SURVEY_RESULT_SUCCESS',
    (resolve) => (payload: string[]) => resolve(payload),
  ),
  fail: createAction(
    'touchableRevenue/DOWNLOAD_SURVEY_RESULT_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

import React, { FC, useEffect, useState } from 'react';
import useStyles from './useStyles';
import { EditableCell } from '../../../../../../common/EditableCell';
import { useDispatch } from 'react-redux';
import {
  createConcessionsMatrix,
  createConcessionsMatrixForAccountManager,
} from '../../../../../../../modules/battleCards/actions';
import { ConcessionsMatrix } from '../../../../../../../types';

type InputBlockProps = {
  customerId?: string;
  concessionsMatrix: ConcessionsMatrix;
  isBattleCards?: boolean;
};
const InputBlocks: FC<InputBlockProps> = ({ customerId, isBattleCards, concessionsMatrix }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { concessionsMade, concessionsDemanded } = concessionsMatrix || {};

  const [concessionsMadeData, setConcessionsMadeData] = useState<string[]>([]);
  const [concessionsDemandedData, setConcessionsDemandedData] = useState<string[]>([]);

  useEffect(() => {
    if (concessionsMade?.length) {
      setConcessionsMadeData(concessionsMade);
    }
    if (concessionsDemanded?.length) {
      setConcessionsDemandedData(concessionsDemanded);
    }
  }, [concessionsMade, concessionsDemanded]);

  const handleChangeConcessionsMade = (value: string, index: number) => {
    const updatedConcessionsMade = concessionsMadeData?.map((el: string, idx: number) => {
      if (index === idx) {
        return value;
      }
      return el;
    });
    setConcessionsMadeData(updatedConcessionsMade as string[]);

    const payload = {
      params: {
        customerId: customerId,
      },
      body: {
        concessionsMade: updatedConcessionsMade,
        concessionsDemanded: concessionsDemandedData,
      },
    };
    if (isBattleCards) {
      dispatch(createConcessionsMatrixForAccountManager.request(payload));
    } else {
      dispatch(createConcessionsMatrix.request(payload));
    }
  };

  const handleChangeConcessionsDemanded = (value: string, index: number) => {
    const updatedConcessionsDemanded = concessionsDemandedData?.map((el: string, idx: number) => {
      if (index === idx) {
        return value;
      }
      return el;
    });
    setConcessionsDemandedData(updatedConcessionsDemanded as string[]);
    const payload = {
      params: {
        customerId: customerId,
      },
      body: {
        concessionsMade: concessionsMadeData,
        concessionsDemanded: updatedConcessionsDemanded,
      },
    };
    if (isBattleCards) {
      dispatch(createConcessionsMatrixForAccountManager.request(payload));
    } else {
      dispatch(createConcessionsMatrix.request(payload));
    }
  };

  return (
    <div className={classes.inputBlocks}>
      <div className={classes.block}>
        <div className={classes.header}>Concessions made</div>
        <div>
          {concessionsMade?.map((item: string, index: number) => (
            <div key={index} className={classes.fieldWrapper}>
              <div className={classes.fieldNumber}>{index + 1}.</div>
              <div className={classes.fieldInput}>
                <EditableCell
                  isInput
                  buttonStyle={classes.input}
                  innerInputStyle={classes.input}
                  value={concessionsMadeData[index]}
                  onEditCb={(e) => handleChangeConcessionsMade(e, index)}
                  placeholder="Enter text"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={classes.block}>
        <div className={classes.header}>Concessions demanded</div>
        <div>
          {concessionsMade?.map((item: string, index: number) => (
            <div key={index} className={classes.fieldWrapper}>
              <div className={classes.fieldNumber}>{index + 1}.</div>
              <div className={classes.fieldInput}>
                <EditableCell
                  isInput
                  buttonStyle={classes.input}
                  innerInputStyle={classes.input}
                  value={concessionsDemandedData[index]}
                  onEditCb={(e) => handleChangeConcessionsDemanded(e, index)}
                  placeholder="Enter text"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default InputBlocks;

import React, { FC, useEffect } from 'react';
import AccordionComponent from './AccordionComponent';
import PricingTargets from './PricingTargets';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBattleCard,
  getBattleCardForAccountManager,
} from '../../../../../modules/battleCards/actions';
import { PriceIncreaseBridgeResponse, Store } from '../../../../../types';
import { useParams } from 'react-router-dom';
import ObjectionHandling from './ObjectionHandling';
import ValueArgumentation from './ValueArgumentation';
import ConcessionMatrix from './ConcessionMatrix';
import PriceIncreaseBridge from './PriceIncreaseBridge';
import NegotiationPower from './NegotiationPower';
import NegotiationAndRevenueDevelopment from './NegotiationAndRevenueDevelopment';

type BattleCardProps = {
  isBattleCards?: boolean;
};

const BattleCard: FC<BattleCardProps> = ({ isBattleCards }) => {
  const dispatch = useDispatch();

  const { id } = useParams();

  const { battleCardData, loading } = useSelector((state: Store) => state.battleCards);

  useEffect(() => {
    if (isBattleCards) {
      dispatch(getBattleCardForAccountManager.request({ customerId: id }));
    } else {
      dispatch(getBattleCard.request({ customerId: id }));
    }
  }, []);

  return (
    <>
      <NegotiationAndRevenueDevelopment
        pricePotential={battleCardData?.negotiationPower?.pricePotential as number}
        revenueDevelopment={battleCardData?.revenueDevelopment}
        customerName={battleCardData?.customerName as string}
        loading={loading}
      />
      <AccordionComponent
        title="Negotiation power and pricing potential"
        component={
          <NegotiationPower
            pricePotential={battleCardData?.negotiationPower?.pricePotential as number}
          />
        }
      />
      <AccordionComponent
        title="Pricing targets"
        component={<PricingTargets pricingTargets={battleCardData?.pricingTargets} />}
      />
      <AccordionComponent
        title="Price increase bridge"
        component={
          <PriceIncreaseBridge
            priceIncreaseBridge={battleCardData?.priceIncreaseBridge as PriceIncreaseBridgeResponse}
            customerId={id}
            isBattleCards={isBattleCards as boolean}
          />
        }
      />
      <AccordionComponent
        title="Value argumentation"
        component={
          <ValueArgumentation
            valueArgumentation={battleCardData?.valueArgumentation}
            customerId={id}
            isBattleCards={isBattleCards}
          />
        }
      />
      <AccordionComponent
        title="Objection handling"
        component={
          <ObjectionHandling
            objectionHandling={battleCardData?.objectionHandling}
            customerId={id}
            isBattleCards={isBattleCards}
          />
        }
      />
      <AccordionComponent
        title="Concession matrix"
        component={
          <ConcessionMatrix
            customerId={id}
            concessionsMatrix={battleCardData?.concessionsMatrix}
            isBattleCards={isBattleCards}
          />
        }
      />
    </>
  );
};

export default BattleCard;

import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 30,
    overflowX: 'auto',
  },
  table: {
    borderCollapse: 'collapse',
    width: '100%',
    '& tbody td': {
      textAlign: 'center',
      height: 75,
      fontSize: 16,
      color: COLORS.black1,
      backgroundColor: COLORS.white1,
    },
    '& tbody tr:nth-child(6) td': {
      backgroundColor: COLORS.gray2,
      height: 20,
    },
    '& tbody tr:nth-child(6)': {
      backgroundColor: COLORS.gray2,
      height: 20,
      border: 'none',
      borderBottom: `1px solid ${COLORS.gray12}`,
    },
    '& tbody tr': {
      borderLeft: `1px solid ${COLORS.gray12}`,
      borderRight: `1px solid ${COLORS.gray12}`,
      borderBottom: `1px solid ${COLORS.gray12}`,
    },
    '& tbody tr:nth-child(5)': {
      borderBottom: `1px solid ${COLORS.gray12}`,
    },
    '& tbody tr:nth-child(7)': {
      borderBottom: `1px solid ${COLORS.gray12}`,
    },
  },
  theadRow: {
    height: 60,
    fontSize: 18,
    color: COLORS.black1,
    border: `1px solid ${COLORS.gray16}`,
    '& div': {
      fontWeight: 400,
    },
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default useStyles;

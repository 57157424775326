import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Modal from '@mui/material/Modal';
import useStyles from './useStyles';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as CloseIcon } from 'src/assets/icons/Close.svg';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { COLORS } from 'src/constants/COLORS';
import { WeekPicker } from '../WeekPicker';
import { DateTimePicker } from 'src/components/common/DateTimePicker';
import { ButtonBase } from 'src/components/common/ButtonBase';
import { Dropdown } from 'src/components/common/Dropdown';

import { week, dayNumber } from 'src/constants/week';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import {
  getConfigOfSecondSurvey,
  updateConfigOfSecondSurvey,
} from 'src/modules/monitoring/actions';
import { Store } from 'src/types';
import dayjs from 'dayjs';
import { Loader } from 'src/components/common/Loader';
import { checkIsNumber } from 'src/helpers/checkIsNumber';

const capitalize = (s: string) => {
  return s[0].toUpperCase() + s.slice(1).toLowerCase();
};

enum Frequency {
  weekly = 'WEEKLY',
  monthly = 'MONTHLY',
}

type Props = {
  open: boolean;
  handleClose: () => void;
};

export const MonitoringModal: React.FC<Props> = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { buttonLoading, configOfSecondSurvey, modalLoading } = useSelector(
    (state: Store) => state.monitoring,
  );

  const [frequency, setFrequency] = useState<string | Frequency>(Frequency.weekly);
  const [selectedDay, setSelectedDay] = useState('MONDAY');
  const [startDay, setStartDay] = useState<string | null>(null);
  const [deadlineQuantity, setDeadlineQuantity] = useState('');
  const [theDayNumber, setTheDayNumber] = useState('First');
  const [dayOfWeek, setDayOfWeek] = useState('MONDAY');

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;

    setFrequency(value);
  };

  const onSelectDay = useCallback((day) => {
    setSelectedDay(day);
  }, []);

  const onSelectStartDay = useCallback((date) => {
    setStartDay(date);
  }, []);

  const handleChangeQuantity = useCallback(
    (e) => {
      const { value } = e.target;

      if (frequency === Frequency.weekly) {
        if (value > 7) {
          toast.warn('Deadline should not be more than 7 days');
        }
      }

      if (frequency === Frequency.monthly) {
        if (value > 28) {
          toast.warn('Deadline should not be more than 28 days');
        }
      }

      const isNumber = checkIsNumber(value);

      if (isNumber) {
        setDeadlineQuantity(value);
      }
    },
    [frequency],
  );

  const handleChangeTheDayOfNumber = useCallback((value) => {
    setTheDayNumber(value);
  }, []);

  const handleChangeDayOfWeek = useCallback((value) => {
    setDayOfWeek(value);
  }, []);

  const capitalizedWeek = week.map((item) => capitalize(item));
  const capitalizedDayNumber = dayNumber.map((item) => capitalize(item));

  const isButtonDisabled = useMemo(() => {
    if (frequency === Frequency.weekly) {
      if (
        !selectedDay ||
        deadlineQuantity === '' ||
        +deadlineQuantity > 7 ||
        deadlineQuantity === '0'
      ) {
        return true;
      }
    }

    if (frequency === Frequency.monthly) {
      if (deadlineQuantity === '' || +deadlineQuantity > 28 || deadlineQuantity === '0') {
        return true;
      }
    }
  }, [deadlineQuantity, frequency, selectedDay]);

  const handleSubmit = useCallback(() => {
    if (!buttonLoading) {
      dispatch(
        updateConfigOfSecondSurvey.request({
          body: {
            frequency,
            dayOfWeek: frequency === Frequency.weekly ? selectedDay : dayOfWeek.toUpperCase(),
            dayNumber: frequency === Frequency.weekly ? null : theDayNumber.toUpperCase(),
            startDate: dayjs(startDay).format('DD-MM-YYYY'),
            deadline: +deadlineQuantity,
          },
          onSuccessCb: () => {
            handleClose();
          },
        }),
      );
    }
  }, [
    buttonLoading,
    dayOfWeek,
    deadlineQuantity,
    dispatch,
    frequency,
    handleClose,
    selectedDay,
    startDay,
    theDayNumber,
  ]);

  const parseDate = useCallback((inputDate: string | null) => {
    if (inputDate) {
      const [day, month, year] = inputDate.split('-');

      const date = new Date(+year, +month - 1, +day);

      return dayjs(date).format('');
    }

    return dayjs(new Date()).format();
  }, []);

  useEffect(() => {
    if (open) {
      dispatch(getConfigOfSecondSurvey.request());
    }
  }, [dispatch, open]);

  useEffect(() => {
    if (configOfSecondSurvey) {
      if (configOfSecondSurvey.dayNumber) {
        setTheDayNumber(configOfSecondSurvey.dayNumber);
      }
      setDayOfWeek(configOfSecondSurvey.dayOfWeek ? configOfSecondSurvey.dayOfWeek : 'MONDAY');
      setSelectedDay(configOfSecondSurvey.dayOfWeek ? configOfSecondSurvey.dayOfWeek : 'MONDAY');
      setDeadlineQuantity(
        configOfSecondSurvey.deadline ? configOfSecondSurvey.deadline.toString() : '0',
      );
      setStartDay(parseDate(configOfSecondSurvey.startDate));
      setFrequency(
        configOfSecondSurvey.frequency ? configOfSecondSurvey.frequency : Frequency.weekly,
      );
    }
  }, [configOfSecondSurvey, parseDate]);

  return (
    <Modal open={open} onClose={handleClose}>
      <div className={classes.modalContainer}>
        {modalLoading || !configOfSecondSurvey || startDay === null ? (
          <Loader isLoading marginTop={40} />
        ) : (
          <>
            <IconButton
              onClick={handleClose}
              aria-label="delete"
              sx={{ display: 'flex', alignSelf: 'flex-end', mr: -5 }}>
              <CloseIcon width={16} height={16} />
            </IconButton>
            <h2 className={classes.title}>Settings</h2>

            <div className={classes.frequencyContainer}>
              <div>Frequency:</div>
              <div>
                <FormControl sx={{ mt: -1.4, ml: 6 }}>
                  <RadioGroup
                    name="controlled-radio-buttons-group"
                    value={frequency}
                    onChange={onChange}>
                    <FormControlLabel
                      value={Frequency.weekly}
                      sx={{
                        '& .MuiFormControlLabel-label': {
                          fontSize: '14px',
                        },
                      }}
                      control={
                        <Radio
                          sx={{
                            color: COLORS.red1,
                            '&.Mui-checked': {
                              color: COLORS.red1,
                            },
                          }}
                        />
                      }
                      label="Weekly"
                    />
                    <FormControlLabel
                      value={Frequency.monthly}
                      sx={{
                        '& .MuiFormControlLabel-label': {
                          fontSize: '14px',
                        },
                      }}
                      control={
                        <Radio
                          sx={{
                            color: COLORS.red1,
                            '&.Mui-checked': {
                              color: COLORS.red1,
                            },
                          }}
                        />
                      }
                      label="Monthly"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>

            {frequency === Frequency.weekly && (
              <WeekPicker onSelectDay={onSelectDay} selectedDay={selectedDay} />
            )}

            {frequency === Frequency.monthly && (
              <div className={classes.onTheWrapper}>
                <div className={classes.text}>On the</div>
                <div className={classes.monthlyDropdowns}>
                  <Dropdown
                    width={240}
                    dropdownButtonStyles={classes.dropdownButton}
                    dropdownItems={capitalizedDayNumber}
                    defaultItem={capitalize(theDayNumber)}
                    onSelectCb={handleChangeTheDayOfNumber}
                  />
                  <Dropdown
                    width={240}
                    dropdownButtonStyles={classes.dropdownButton}
                    dropdownItems={capitalizedWeek}
                    defaultItem={capitalize(dayOfWeek)}
                    onSelectCb={handleChangeDayOfWeek}
                  />
                </div>
              </div>
            )}

            <div className={classes.inputWrapper}>
              <div className={classes.text}>Start day:</div>
              <DateTimePicker
                inputStyle={classes.datePicker}
                onChangeCb={onSelectStartDay}
                defaultDate={startDay}
              />
            </div>
            <div className={classes.inputWrapper}>
              <div className={classes.text}>Deadline in</div>
              <input
                className={classes.quantityInput}
                placeholder="Q-ty"
                value={deadlineQuantity}
                onChange={handleChangeQuantity}
              />
              <div className={classes.text}>days</div>
            </div>
            <ButtonBase
              disabled={isButtonDisabled}
              containerStyle={classes.buttonContainer}
              variant="contained"
              color="secondary"
              title="Save"
              onClick={handleSubmit}
              isLoading={buttonLoading}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../constants/COLORS';

const useStyles = makeStyles(() => ({
  container: {
    justifyContent: 'space-between',
    marginTop: '43px',
    rowGap: 30,
  },
  item: {
    position: 'relative',
    width: '380px',
    height: 190,
    flex: '0 0 32.333333%',
    cursor: 'pointer',
  },
  image: {
    width: '100%',
    objectFit: 'cover',
    height: '100%',
  },
  bg_wrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  textWrapper: {
    padding: '32px 24px',
    display: 'flex',
    alignItems: 'center',
  },
  circle: {
    width: 24,
    height: 24,
    borderRadius: '50%',
    backgroundColor: COLORS.white1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    color: 'transparent',
    fontWeight: 700,
    marginRight: 16,
  },
  progress: {
    position: 'absolute',
    right: '20px',
    top: '25px',
  },
}));

export default useStyles;

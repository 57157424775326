import { createTheme } from '@mui/material/styles';

import { COLORS } from './constants/COLORS';

const theme = createTheme({
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: 1280,
      xl: 1440,
    },
  },
  palette: {
    primary: {
      main: COLORS.black1,
      light: COLORS.gray2,
    },
    secondary: {
      main: COLORS.red1,
    },
    error: {
      main: COLORS.red1,
    },
    info: {
      main: COLORS.white1,
    },
  },
  spacing: 5,
  typography: {
    fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif",
    h3: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 400,
      color: '#EAEBEB',
    },
  },
  components: {
    MuiSwitch: {
      styleOverrides: {
        root: {
          '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: 'white',
            border: `1px solid ${COLORS.red1}`,
          },
          '& .MuiSwitch-switchBase.Mui-checked.Mui-disabled + .MuiSwitch-track': {
            borderColor: COLORS.pink3,
            opacity: 1,
          },
          '& .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track': {
            opacity: 1,
            borderColor: COLORS.gray18,
          },
          '& .MuiSwitch-switchBase.Mui-checked > .MuiSwitch-thumb': {
            backgroundColor: COLORS.red1,
          },
          '& .MuiSwitch-switchBase.Mui-checked.Mui-disabled > .MuiSwitch-thumb': {
            backgroundColor: COLORS.pink2,
          },
          '& .MuiSwitch-switchBase.Mui-disabled > .MuiSwitch-thumb': {
            backgroundColor: COLORS.gray18,
          },
        },
        thumb: {
          width: '24px',
          height: '24px',
          marginTop: '1px',
          backgroundColor: COLORS.gray3,
        },
        track: {
          backgroundColor: 'white',
          border: `1px solid ${COLORS.gray3}`,
          height: '20px',
          borderRadius: '10px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: 16,
          color: COLORS.black1,
        },
        h2: {
          fontWeight: 400,
          fontSize: 18,
        },
        h5: {
          fontSize: 14,
        },
        body1: {
          lineHeight: '24px',
        },
        body2: {
          color: '#C5C7DE',
          lineHeight: '18px',
        },
        h3: {
          color: COLORS.gray1,
        },
        h4: {
          color: COLORS.black2,
          fontWeight: 700,
        },
        subtitle1: {
          color: COLORS.white1,
          fontSize: '18px',
        },
        subtitle2: {
          color: COLORS.black3,
          fontSize: '12.8px',
          fontWeight: '400',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          paddingLeft: '0',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: 100,
          borderRadius: 8,
          textTransform: 'none',
        },
        containedPrimary: {
          backgroundColor: COLORS.red1,
          fontWeight: 700,
          color: COLORS.white1,
          height: 40,
          fontSize: 16,
          transition: '0.3s ease-out',
          '&:hover': {
            backgroundColor: COLORS.red5,
          },
          '&:active': {
            backgroundColor: COLORS.pink5,
          },
          '&:disabled': {
            backgroundColor: COLORS.pink6,
            color: COLORS.white1,
          },
        },
        outlined: {
          border: `1px solid ${COLORS.black6}`,
          backgroundColor: 'transparent',
          fontWeight: 400,
          color: COLORS.black6,
          height: 40,
          fontSize: 16,
          transition: '0.3s ease-out',
          '&:hover': {
            color: COLORS.red5,
            borderColor: COLORS.red6,
          },
          '&:active': {
            color: COLORS.pink4,
            borderColor: COLORS.pink4,
          },
          '&:disabled': {
            color: COLORS.gray23,
            borderColor: COLORS.gray23,
          },
        },
        outlinedSecondary: {
          borderColor: COLORS.white1,
          color: COLORS.white1,
          fontWeight: 700,
          '&:hover': {
            boxShadow: '0px 0px 10px #FFCEDA',
            borderColor: COLORS.white1,
            color: COLORS.white1,
          },
        },
        text: {
          color: COLORS.blue1,
          fontSize: '14px',
          paddingLeft: '14px',
          paddingRight: '14px',
        },
        sizeLarge: {
          height: 52,
        },
      },
    },
  },
});

export default theme;

import { createReducer } from 'deox';
import { produce } from 'immer';

import { CampaignTargetState } from './types';

import {
  getTargetForBusinessUnits,
  getPricePotential,
  updatePricePotentialCategories,
  updateCostDriver,
  deleteCostDriver,
  deletePricePotentialCategory,
  deletePricePotentialIndicator,
  getPricePotentialMatrix,
  updatePricePotentialCostDriver,
  updatePricePotentialMatrixIndicator,
  updatePricePotentialMatrixWeight,
  updatePricePotentialAdditionalFields,
  setTargetForBusinessUnits,
  editPricePotentialIndicator,
  getTargetSettings,
  updateTargetForTargetSettings,
  setEmptyFieldForTargetGiven,
  getApproachForCampaignTarget,
  updateApproachCampaignTarget,
} from './actions';

const initialState: CampaignTargetState = {
  loading: false,
  targetsForBusinessUnits: null,
  pricePotential: null,
  pricePotentialsMatrix: null,
  targetSettings: [],
  campaignTargetApproach: 'TARGET_GIVEN',
};

export const campaignTargetReducer = createReducer(initialState, (handleAction) => [
  handleAction(setEmptyFieldForTargetGiven, (state, { payload }) =>
    produce(state, (draft) => {
      if (state.targetsForBusinessUnits) {
        draft.targetsForBusinessUnits = {
          ...state.targetsForBusinessUnits,
          targets: state.targetsForBusinessUnits.targets.map((target) =>
            target.id === payload.targetId
              ? {
                  ...target,
                  target: null,
                }
              : target,
          ),
        };
      }
    }),
  ),
  handleAction(getTargetForBusinessUnits.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(getTargetForBusinessUnits.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(getTargetForBusinessUnits.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.targetsForBusinessUnits = payload;
    }),
  ),
  handleAction(getTargetForBusinessUnits.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(getPricePotential.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(getPricePotential.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.pricePotential = payload;
    }),
  ),
  handleAction(getPricePotential.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(updatePricePotentialCategories.success, (state, { payload }) =>
    produce(state, (draft) => {
      if (state.pricePotential) {
        draft.pricePotential = {
          ...state.pricePotential,
          categories: [...payload.categories],
        };
      }
    }),
  ),
  handleAction(updateCostDriver.success, (state, { payload }) =>
    produce(state, (draft) => {
      if (state.pricePotential) {
        draft.pricePotential = {
          ...state.pricePotential,
          costDrivers: [...payload.costDrivers],
        };
      }
    }),
  ),
  handleAction(deleteCostDriver.request, (state, { payload }) =>
    produce(state, (draft) => {
      if (state.pricePotential) {
        draft.pricePotential = {
          ...state.pricePotential,
          costDrivers: state.pricePotential.costDrivers.filter(
            (costDriver) => costDriver.id !== payload.costDriverId,
          ),
        };
      }
    }),
  ),
  handleAction(deletePricePotentialCategory.success, (state, { payload }) =>
    produce(state, (draft) => {
      if (state.pricePotential) {
        draft.pricePotential = {
          ...state.pricePotential,
          categories: state.pricePotential.categories.filter(
            (category) => category.id !== payload.categoryId,
          ),
        };
      }
    }),
  ),
  handleAction(deletePricePotentialIndicator.success, (state, { payload }) =>
    produce(state, (draft) => {
      if (state.pricePotential) {
        draft.pricePotential = {
          ...state.pricePotential,
          categories: state.pricePotential.categories.map((category) =>
            category.id === payload.categoryId
              ? {
                  ...category,
                  indicators: category.indicators.filter(
                    (indicator) => indicator.id !== payload.indicatorId,
                  ),
                }
              : { ...category },
          ),
        };
      }
    }),
  ),
  handleAction(getPricePotentialMatrix.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(getPricePotentialMatrix.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.pricePotentialsMatrix = payload;
    }),
  ),
  handleAction(getPricePotentialMatrix.request, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(updatePricePotentialCostDriver.success, (state, { payload }) =>
    produce(state, (draft) => {
      if (state.pricePotentialsMatrix) {
        draft.pricePotentialsMatrix = {
          ...state.pricePotentialsMatrix,
          pricePotentials: state.pricePotentialsMatrix.pricePotentials.map((pricePotential) =>
            pricePotential.businessUnitId === payload.businessUnitId
              ? {
                  ...pricePotential,
                  weightScore: payload.weightScore,
                  pricePotential: payload.pricePotential,
                  totalTarget: payload.totalTarget,
                }
              : pricePotential,
          ),
        };
      }
    }),
  ),
  handleAction(updatePricePotentialMatrixIndicator.success, (state, { payload }) =>
    produce(state, (draft) => {
      if (state.pricePotentialsMatrix) {
        draft.pricePotentialsMatrix = {
          ...state.pricePotentialsMatrix,
          pricePotentials: state.pricePotentialsMatrix.pricePotentials.map((pricePotential) =>
            pricePotential.businessUnitId === payload.businessUnitId
              ? {
                  ...pricePotential,
                  weightScore: payload.weightScore,
                  pricePotential: payload.pricePotential,
                  totalTarget: payload.totalTarget,
                  categories: pricePotential.categories.map((category) =>
                    category.categoryId === payload.categoryId
                      ? {
                          ...category,
                          indicators: category.indicators.map((indicator) =>
                            indicator.indicatorId === payload.indicatorId
                              ? {
                                  ...indicator,
                                  percentage: payload.percentage,
                                  colorCode: payload.colorCode,
                                }
                              : indicator,
                          ),
                        }
                      : category,
                  ),
                }
              : pricePotential,
          ),
        };
      }
    }),
  ),
  handleAction(updatePricePotentialMatrixWeight.success, (state, { payload }) =>
    produce(state, (draft) => {
      const { responseData, requestParams } = payload;

      if (state.pricePotentialsMatrix) {
        draft.pricePotentialsMatrix = {
          ...state.pricePotentialsMatrix,
          weights: state.pricePotentialsMatrix.weights.map((item) =>
            item.indicatorId === requestParams.body.indicatorId
              ? {
                  ...item,
                  weight: requestParams.body.weight,
                }
              : item,
          ),
          pricePotentials: state.pricePotentialsMatrix.pricePotentials.map((item, index) => ({
            ...item,
            pricePotential: responseData.pricePotentials[index].pricePotential,
            totalTarget: responseData.pricePotentials[index].totalTarget,
            weightScore: responseData.pricePotentials[index].weightScore,
          })),
        };
      }
    }),
  ),
  handleAction(updatePricePotentialAdditionalFields.success, (state, { payload }) =>
    produce(state, (draft) => {
      const { responseData, requestPayload } = payload;
      if (state.pricePotentialsMatrix) {
        draft.pricePotentialsMatrix = {
          ...state.pricePotentialsMatrix,
          ...requestPayload,
          pricePotentials: state.pricePotentialsMatrix.pricePotentials.map((item, index) => ({
            ...item,
            pricePotential: responseData.pricePotentials[index].pricePotential,
            totalTarget: responseData.pricePotentials[index].totalTarget,
            weightScore: responseData.pricePotentials[index].weightScore,
            categories: responseData.pricePotentials[index].categories,
          })),
        };
      }
    }),
  ),
  handleAction(setTargetForBusinessUnits.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.targetsForBusinessUnits = payload;
    }),
  ),
  handleAction(editPricePotentialIndicator.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.pricePotential = payload;
    }),
  ),
  handleAction(getTargetSettings.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(getTargetSettings.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.targetSettings = payload.targetSettings;

      draft.loading = false;
    }),
  ),
  handleAction(getTargetSettings.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(updateTargetForTargetSettings.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.targetSettings = payload.targetSettings;
    }),
  ),
  //
  handleAction(getApproachForCampaignTarget.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.campaignTargetApproach = payload;
    }),
  ),
  handleAction(updateApproachCampaignTarget.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.campaignTargetApproach = payload.approach;
    }),
  ),
]);

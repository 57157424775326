import { createAction } from 'deox';
import { ResponseError } from 'src/modules/Errors/actions';
import {
  AddNewProjectRequest,
  AddNewProjectResponse,
  EditProjectRequestBody,
  GetAllProjectsRequestParams,
  GetProjectsResponse,
} from '../../types';

export const getProjects = {
  request: createAction(
    'projects/GET_PROJECTS_REQUEST',
    (resolve) => (payload: GetAllProjectsRequestParams) => resolve(payload),
  ),
  success: createAction(
    'projects/GET_PROJECTS_SUCCESS',
    (resolve) => (payload: GetProjectsResponse) => resolve(payload),
  ),
  fail: createAction(
    'projects/GET_PROJECTS_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const addNewProject = {
  request: createAction(
    'projects/ADD_NEW_PROJECT_REQUEST',
    (resolve) => (payload: { requestPayload: AddNewProjectRequest; onSuccessCb?: () => void }) =>
      resolve(payload),
  ),
  success: createAction(
    'projects/ADD_NEW_PROJECT_SUCCESS',
    (resolve) => (payload: AddNewProjectResponse) => resolve(payload),
  ),
  fail: createAction(
    'projects/ADD_NEW_PROJECT_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const deleteProject = {
  request: createAction(
    'projects/DELETE_PROJECT_REQUEST',
    (resolve) => (payload: { requestPayload: { projectId: number }; onSuccessCb?: () => void }) =>
      resolve(payload),
  ),
  success: createAction(
    'projects/DELETE_PROJECT_SUCCESS',
    (resolve) => (payload: { projectId: number }) => resolve(payload),
  ),
  fail: createAction(
    'projects/DELETE_PROJECT_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const editProject = {
  request: createAction(
    'projects/EDIT_PROJECT_REQUEST',
    (resolve) => (payload: { requestPayload: EditProjectRequestBody; onSuccessCb?: () => void }) =>
      resolve(payload),
  ),
  success: createAction(
    'projects/EDIT_PROJECT_SUCCESS',
    (resolve) => (payload: { id: number; newName: string }) => resolve(payload),
  ),
  fail: createAction(
    'projects/EDIT_PROJECT_FAIL',
    (resolve) => (error: ResponseError) => resolve(error),
  ),
};

export const setCurrentProject = createAction(
  'projects/SET_CURRENT_PROJECT',
  (resolve) => (payload: { name: string; id: number }) => resolve(payload),
);

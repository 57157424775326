import { takeLatest, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { downloadFile } from 'src/api/downloadFile';

import {
  getBusinessUnits,
  exportBusinessUnitsExcel,
  exportAccountManagersListExcel,
  exportCustomerRegistryExcel,
  downloadBusinessUnitsExcel,
  downloadCustomerRegistryExcel,
  downloadAccountManagersListExcel,
  getAccountManagersList,
  getCustomerRegistry,
  getAccountManagerNames,
  setAccountManager,
  sendSurveyToAll,
  getSurveyStatuses,
  sendSurveyToAccountManager,
  getRevenueBase,
  getBusinessUnitsName,
  updateAccountMangersTable,
  updateBusinessUnitsTable,
  updateCustomerRegistryTable,
  updateCustomerRevenues,
  getAccountManagerGroups,
  downloadSurveyResultExcel,
} from './actions';
import { processRequestError } from 'src/modules/Errors/actions';

import { ActionType } from 'deox';
import { toast } from 'react-toastify';
import { select } from 'typed-redux-saga';
import { Store } from '../../types';

function* getBusinessUnitsSaga({ payload }: ActionType<typeof getBusinessUnits.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.getBusinessUnits({ ...payload, projectId });

    yield put(getBusinessUnits.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: getBusinessUnits.fail }));
  }
}

function* getAccountManagerGroupsSaga() {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.getAccountManagerGroups({ projectId });
    yield put(getAccountManagerGroups.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: getAccountManagerGroups.fail }));
  }
}

function* updateBusinessUnitsTableSaga({
  payload,
}: ActionType<typeof updateBusinessUnitsTable.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    yield API.updateBusinessUnitsTable({ ...payload, projectId });

    yield put(updateBusinessUnitsTable.success());
  } catch (error) {
    yield put(processRequestError({ error, failAction: updateBusinessUnitsTable.fail }));
  }
}

function* updateCustomerRegistryTableSaga({
  payload,
}: ActionType<typeof updateCustomerRegistryTable.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.updateCustomerRegistryTable({ ...payload.payload, projectId });

    yield put(updateCustomerRegistryTable.success(data));

    yield put(getCustomerRegistry.request(payload.pageableData));
  } catch (error) {
    yield put(
      processRequestError({
        error,
        failAction: updateCustomerRegistryTable.fail,
        fieldId: payload.payload.id,
        fieldName: Object.keys(payload.payload)[1],
      }),
    );
  }
}

function* updateCustomerRevenuesSaga({
  payload,
}: ActionType<typeof updateCustomerRevenues.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    yield API.updateCustomerRevenues({ ...payload, projectId });

    yield put(updateCustomerRevenues.success());
  } catch (error) {
    yield put(processRequestError({ error, failAction: updateCustomerRevenues.fail }));
  }
}

function* getAccountManagersListSaga({
  payload,
}: ActionType<typeof getAccountManagersList.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.getAccountManagersList({ ...payload, projectId });

    yield put(getAccountManagersList.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: getAccountManagersList.fail }));
  }
}

function* updateAccountMangersTableSaga({
  payload,
}: ActionType<typeof updateAccountMangersTable.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    yield API.updateAccountManagersTable({ ...payload, projectId });

    yield put(updateAccountMangersTable.success());
  } catch (error) {
    yield put(processRequestError({ error, failAction: updateAccountMangersTable.fail }));
  }
}

function* exportBusinessUnitsExcelSaga({
  payload,
}: ActionType<typeof exportBusinessUnitsExcel.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { file, params } = payload;

    const formData = new FormData();
    formData.append('file', file);

    const { data } = yield API.exportBusinessUnitsExcel(formData, { ...params, projectId });

    yield put(exportBusinessUnitsExcel.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: exportBusinessUnitsExcel.fail }));
  }
}

function* downloadBusinessUnitsExcelSaga() {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.downloadBusinessUnitsExcel({ projectId });

    console.log(data);

    yield downloadFile(data, 'business_units.xlsx');
  } catch (error) {
    yield put(processRequestError({ error, failAction: getBusinessUnits.fail }));
  }
}

function* downloadCustomerRegistrySaga({
  payload,
}: ActionType<typeof downloadCustomerRegistryExcel.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.downloadCustomerRegistryExcel({ projectId, years: payload });

    yield downloadFile(data, 'customer_registry.xlsx');
  } catch (error) {
    yield put(processRequestError({ error, failAction: downloadCustomerRegistryExcel.fail }));
  }
}

function* downloadAccountManagerListsSaga() {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.downloadAccountManagerListTemplate({ projectId });

    yield downloadFile(data, 'account_managers_list.xlsx');
  } catch (error) {
    yield put(processRequestError({ error, failAction: downloadAccountManagersListExcel.fail }));
  }
}

function* exportAccountManagersListSaga({
  payload,
}: ActionType<typeof exportAccountManagersListExcel.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { file, params } = payload;

    const formData = new FormData();
    formData.append('file', file);

    const { data } = yield API.exportAccountManagersList(formData, { ...params, projectId });

    yield put(exportAccountManagersListExcel.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: exportAccountManagersListExcel.fail }));
  }
}

function* exportCustomerRegistryExcelSaga({
  payload,
}: ActionType<typeof exportCustomerRegistryExcel.request>) {
  const { requestData } = payload;
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const formData = new FormData();
    formData.append('file', requestData.file);

    const { data } = yield API.exportCustomerRegistryExcel(formData, {
      projectId,
      itemsOnPage: requestData.itemsOnPage,
    });

    yield put(exportCustomerRegistryExcel.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: exportCustomerRegistryExcel.fail }));
  }
}

function* getCustomerRegistrySaga({ payload }: ActionType<typeof getCustomerRegistry.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.getCustomerRegistry({ ...payload, projectId });

    yield put(getCustomerRegistry.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: getCustomerRegistry.fail }));
  }
}

function* sendSurveyToAllSaga({ payload }: ActionType<typeof sendSurveyToAll.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.sendSurveyToAll({ ...payload, projectId });

    yield put(sendSurveyToAll.success({ isSecondSurveyStatus: payload.surveyNumber === 2 }));

    toast.success(data);
  } catch (error) {
    yield put(processRequestError({ error, failAction: sendSurveyToAll.fail }));
  }
}

function* getAccountManagerNamesSaga() {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.getAccountManagerNames({ projectId });

    yield put(getAccountManagerNames.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: getAccountManagerNames.fail }));
  }
}

function* setAccountManagerSaga({ payload }: ActionType<typeof setAccountManager.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    yield API.setAccountManager({
      ...payload,
      params: {
        ...payload.params,
        projectId,
      },
    });

    yield put(setAccountManager.success(payload.body));
  } catch (error) {
    yield put(processRequestError({ error, failAction: setAccountManager.fail }));
  }
}

function* getSurveyStatusesSaga({ payload }: ActionType<typeof getSurveyStatuses.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.getSurveyStatuses({ ...payload, projectId });

    yield put(getSurveyStatuses.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: getSurveyStatuses.fail }));
  }
}

function* sendSurveyToAccountManagerSaga({
  payload,
}: ActionType<typeof sendSurveyToAccountManager.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  const { body, params } = payload;

  try {
    const { data } = yield API.sendSurveyToAccountManager({ ...body, projectId }, params);

    yield put(
      sendSurveyToAccountManager.success({
        accountManagerId: body.accountManagerId,
        isSecondSurvey: params.surveyNumber === 2,
      }),
    );

    toast.success(data);
  } catch (error) {
    yield put(processRequestError({ error, failAction: sendSurveyToAccountManager.fail }));
  }
}

function* getRevenueBaseSaga({ payload }: ActionType<typeof getRevenueBase.request>) {
  const { projectId } = yield select((state: Store) => state.projects);
  try {
    const { data } = yield API.getRevenueBaseCharts({ ...payload, projectId });

    yield put(getRevenueBase.success(data));

    toast.success(data);
  } catch (error) {
    yield put(processRequestError({ error, failAction: getRevenueBase.fail }));
  }
}

function* getBusinessUnitsNameSaga() {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.getBusinessUnitsName({ projectId });

    yield put(getBusinessUnitsName.success(data));
  } catch (error) {
    yield put(processRequestError({ error, failAction: getBusinessUnitsName.fail }));
  }
}

function* downloadSurveyResultExcelSaga({
  payload,
}: ActionType<typeof downloadSurveyResultExcel.request>) {
  const { projectId } = yield select((state: Store) => state.projects);

  try {
    const { data } = yield API.downloadSurveyResultExcel(projectId, payload);

    yield downloadFile(data, 'survey_result_table.xlsx');
  } catch (error) {
    yield put(processRequestError({ error, failAction: getBusinessUnits.fail }));
  }
}

export function* watchTouchableRevenue() {
  yield takeLatest(updateAccountMangersTable.request, updateAccountMangersTableSaga);
  yield takeLatest(getBusinessUnits.request, getBusinessUnitsSaga);
  yield takeLatest(exportBusinessUnitsExcel.request, exportBusinessUnitsExcelSaga);
  yield takeLatest(downloadBusinessUnitsExcel.request, downloadBusinessUnitsExcelSaga);
  yield takeLatest(downloadAccountManagersListExcel.request, downloadAccountManagerListsSaga);
  yield takeLatest(exportAccountManagersListExcel.request, exportAccountManagersListSaga);
  yield takeLatest(getAccountManagersList.request, getAccountManagersListSaga);
  yield takeLatest(downloadCustomerRegistryExcel.request, downloadCustomerRegistrySaga);
  yield takeLatest(exportCustomerRegistryExcel.request, exportCustomerRegistryExcelSaga);
  yield takeLatest(getCustomerRegistry.request, getCustomerRegistrySaga);
  yield takeLatest(getAccountManagerNames.request, getAccountManagerNamesSaga);
  yield takeLatest(setAccountManager.request, setAccountManagerSaga);
  yield takeLatest(sendSurveyToAll.request, sendSurveyToAllSaga);
  yield takeLatest(getSurveyStatuses.request, getSurveyStatusesSaga);
  yield takeLatest(sendSurveyToAccountManager.request, sendSurveyToAccountManagerSaga);
  yield takeLatest(getRevenueBase.request, getRevenueBaseSaga);
  yield takeLatest(getBusinessUnitsName.request, getBusinessUnitsNameSaga);
  yield takeLatest(updateBusinessUnitsTable.request, updateBusinessUnitsTableSaga);
  yield takeLatest(updateCustomerRegistryTable.request, updateCustomerRegistryTableSaga);
  yield takeLatest(updateCustomerRevenues.request, updateCustomerRevenuesSaga);
  yield takeLatest(getAccountManagerGroups.request, getAccountManagerGroupsSaga);
  yield takeLatest(downloadSurveyResultExcel.request, downloadSurveyResultExcelSaga);
}

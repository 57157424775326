import React, { FC } from 'react';
import { ConcessionBox } from './ConcessionBox';
import useStyles from './useStyles';
import InputBlocks from './InputBlocks';
import { ConcessionsMatrix } from '../../../../../../types';

type ConcessionMatrixProps = {
  customerId?: string;
  concessionsMatrix?: ConcessionsMatrix;
  isBattleCards?: boolean;
};

const ConcessionMatrix: FC<ConcessionMatrixProps> = ({
  customerId,
  concessionsMatrix,
  isBattleCards,
}) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.boxes}>
        <ConcessionBox
          textInsideCircle="Order of concessions made"
          title="Value for the customer"
          bottomText="Value/costs for Company"
        />
        <ConcessionBox
          textInsideCircle="Order of demanded concessions"
          title="Value/costs for the customer"
          bottomText="Value for Company"
          isRight
        />
      </div>
      <InputBlocks
        customerId={customerId}
        concessionsMatrix={concessionsMatrix as ConcessionsMatrix}
        isBattleCards={isBattleCards}
      />
    </>
  );
};

export default ConcessionMatrix;

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  unauthorized: {
    height: 'calc(100vh - 190px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 36,
  },
}));

export default useStyles;

import React, { memo } from 'react';
import { PricePotentialMatrixWeight } from 'src/types';
import useStyles from './useStyles';
import { EditableCell } from 'src/components/common/EditableCell';
import { COLORS } from '../../../../../../../constants/COLORS';

type Props = {
  weights: PricePotentialMatrixWeight[];
  updateWeight: (body: { indicatorId: number; weight: number }) => void;
  disabled: boolean;
};

export const WeightRow: React.FC<Props> = memo(function WeightRow({
  weights,
  updateWeight,
  disabled,
}) {
  const classes = useStyles();

  return (
    <tr className={classes.row}>
      {weights.map((weight) => (
        <th
          style={{
            backgroundColor: COLORS.blue12,
          }}
          key={weight.indicatorId}>
          <EditableCell
            isInput={true}
            buttonStyle={classes.numberInput}
            innerInputStyle={classes.numberInput}
            disabled={disabled}
            placeholder={weight.weight === null ? 'Weight' : ''}
            value={weight.weight !== null ? weight.weight.toString() : ''}
            onEditCb={(value) => {
              updateWeight({
                indicatorId: weight.indicatorId,
                weight: +value,
              });
            }}
            suffix={weight.weight === null ? '' : '%'}
          />
        </th>
      ))}
    </tr>
  );
});

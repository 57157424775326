import React, { useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

import useStyles from './useStyles';

import { ReactComponent as CloseIcon } from 'src/assets/icons/Close.svg';
import { IconButton, Stack } from '@mui/material';
import { ButtonBase } from 'src/components/common/ButtonBase';

type Props = {
  handleClose: () => void;
  open: boolean;
  onSave?: (name: string) => void;
  onEdit?: (id: number, name: string) => void;
  loading: boolean;
  selectedProject?: { name: string; projectId: number } | null;
};

export const AddNewProjectModal: React.FC<Props> = ({
  handleClose,
  open,
  onSave,
  loading,
  onEdit,
  selectedProject,
}) => {
  const classes = useStyles();

  const [projectName, setProjectName] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setProjectName(value);
  };

  useEffect(() => {
    if (onEdit && selectedProject) {
      setProjectName(selectedProject.name);
    }
  }, [onEdit, selectedProject]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={open}>
        <div className={classes.modal}>
          <div className={classes.header}>
            <span>
              {onEdit && selectedProject ? `Update ${selectedProject.name}` : 'Create new project'}
            </span>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={classes.body}>
            <input
              autoFocus={true}
              placeholder="Enter project name"
              className={classes.input}
              onChange={handleChange}
              value={projectName}
            />
            <Stack direction="row" columnGap={5} marginTop={12} justifyContent="space-between">
              <ButtonBase
                inlineStyle={{ width: '200px' }}
                onClick={() => {
                  onSave && onSave(projectName);

                  onEdit && selectedProject && onEdit(selectedProject.projectId, projectName);

                  setTimeout(() => {
                    setProjectName('');
                  }, 1000);
                }}
                isLoading={loading}
                title="Save"
                variant="contained"
              />
              <ButtonBase
                inlineStyle={{ width: '200px' }}
                onClick={handleClose}
                title="Cancel"
                variant="contained"
              />
            </Stack>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

import React, { FC } from 'react';
import { GrowWrapperComponent } from '../../../../../common/GrowWrapperComponent';
import useStyles from './useStyles';
import TRow from './TRow';
import { TextButton } from '../../../../../common/TextButton';
import { useDispatch } from 'react-redux';
import {
  createObjectionHandling,
  createObjectionHandlingForAccountManager,
  deleteObjectionHandling,
  deleteObjectionHandlingForAccountManager,
  updateObjectionHandling,
  updateObjectionHandlingForAccountManager,
} from '../../../../../../modules/battleCards/actions';
import { ObjectionHandlingItem } from '../../../../../../types';

type ObjectionHandlingProps = {
  objectionHandling?: ObjectionHandlingItem[];
  customerId?: string;
  isBattleCards?: boolean;
};

const MAX_NUMBER_FIELDS = 6;

const ObjectionHandling: FC<ObjectionHandlingProps> = ({
  objectionHandling,
  customerId,
  isBattleCards,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const handleUpdate = (obj: ObjectionHandlingItem) => {
    const payload = {
      params: {
        customerId: customerId,
      },
      body: {
        id: obj?.id,
        objection: obj?.objection,
        counterArgumentation: obj?.counterArgumentation,
      },
    };
    if (isBattleCards) {
      dispatch(updateObjectionHandlingForAccountManager.request(payload));
    } else {
      dispatch(updateObjectionHandling.request(payload));
    }
  };

  const handleAddField = () => {
    const payload = {
      params: {
        customerId: customerId,
      },
      body: {
        id: null,
        objection: '',
        counterArgumentation: '',
      },
    };
    if (isBattleCards) {
      dispatch(createObjectionHandlingForAccountManager.request(payload));
    } else {
      dispatch(createObjectionHandling.request(payload));
    }
  };

  const handleDelete = (id: number) => {
    const params = {
      customerId: customerId,
      fieldId: id,
    };
    if (isBattleCards) {
      dispatch(deleteObjectionHandlingForAccountManager.request(params));
    } else {
      dispatch(deleteObjectionHandling.request(params));
    }
  };

  return (
    <GrowWrapperComponent>
      <div className={classes.wrapper}>
        <table className={classes.table}>
          <thead>
            <tr>
              <th>Objection</th>
              <th>Counter argumentation</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {objectionHandling?.map((item: ObjectionHandlingItem) => (
              <TRow
                key={item.id}
                item={item}
                onUpdate={handleUpdate}
                onDelete={handleDelete}
                objectionHandlingLength={objectionHandling.length}
              />
            ))}
          </tbody>
          <tfoot>
            {objectionHandling && objectionHandling!.length < MAX_NUMBER_FIELDS && (
              <div className={classes.buttonWrapper}>
                <TextButton title="Add fields" onClick={handleAddField} />
              </div>
            )}
          </tfoot>
        </table>
      </div>
    </GrowWrapperComponent>
  );
};

export default ObjectionHandling;

import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  container: {
    width: '31%',
    minHeight: '450px',
    display: 'flex',
    flexGrow: 0,
    flexDirection: 'column',
    padding: '16px 24px 0',
    backgroundColor: COLORS.white1,
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    borderRadius: 8,
  },
  responsiveContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  chartDetailContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% + 48px)',
    marginTop: 20,
    backgroundColor: COLORS.blue12,
    marginLeft: -24,
  },
  chartContainer: {
    paddingTop: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
  },
  chartDetailRowHeader: {
    height: 35,
    display: 'flex',
    columnGap: 50,
    paddingTop: 7,
    paddingLeft: '35%',
  },
  chartDetailRow: {
    height: 35,
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1.3fr 1.2fr',
    paddingLeft: 20,
  },
  chartDetailRevenue: {
    display: 'flex',
    backgroundColor: COLORS.white1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  chartDetailCustomers: {
    display: 'flex',
    backgroundColor: COLORS.blue13,
    justifyContent: 'center',
    alignItems: 'center',
  },
  chartDetailLabel: {
    fontWeight: 700,
    color: COLORS.black1,
  },
  tooltipContainer: {
    backgroundColor: COLORS.white1,
    border: `1px solid ${COLORS.gray1}`,
    display: 'flex',
    flexDirection: 'column',
    height: 60,
    borderRadius: 6,
    padding: '10px 12px',
    fontSize: 16,
    color: COLORS.black1,
  },
  title: {
    fontSize: 16,
    fontWeight: 700,
    color: COLORS.black6,
  },
  labelsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 16,
    rowGap: 8,
  },
  labelRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 8,
  },
  labelColorBox: {
    width: 16,
    height: 16,
    borderRadius: 4,
  },
  secondLabel: {
    width: 120,
  },
}));

export default useStyles;

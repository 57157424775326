import { makeStyles } from '@mui/styles';
import { COLORS } from 'src/constants/COLORS';

const useStyles = makeStyles(() => ({
  container: {
    overflowX: 'auto',
    overflowY: 'auto',
    height: 'calc(100vh - 310px)',
    boxShadow: '0px 2px 10px rgba(199, 201, 201, 0.5)',
    borderRadius: '8px',
    marginTop: 35,
  },
  table: {
    borderCollapse: 'collapse',
    width: '100%',
    '& tbody tr:nth-child(even)': {
      backgroundColor: COLORS.blue13,
      '& button': {
        backgroundColor: COLORS.blue13,
      },
    },
  },
  tHeader: {
    backgroundColor: COLORS.blue12,
  },
  tHeaderRow: {
    height: 60,
    '& td': {
      fontWeight: 700,
      padding: 0,
      color: COLORS.black2,
      paddingLeft: 15,
      fontSize: 16,
    },
  },
  tBodyRow: {
    backgroundColor: COLORS.white1,
    borderBottom: `1px solid ${COLORS.gray5}`,
    height: 50,
    minHeight: 74,
    '& td': {
      paddingLeft: 15,
      fontSize: 16,
      color: COLORS.black1,
    },
  },
}));

export default useStyles;

import React from 'react';
import { Bar, BarChart, CartesianGrid, Cell } from 'recharts';
import { useSelector } from 'react-redux';
import { Store } from '../../../../../../types';

import useStyles from './useStyles';
import { COLORS } from 'src/constants/COLORS';
import { numberSeparator } from '../../../../../../helpers/numberSeparator';

export const RevenueImpactChart = () => {
  const classes = useStyles();

  const {
    monitoring: {
      negotiationStatus: { negotiationResults },
    },
  } = useSelector((state: Store) => state);

  const getPvValue = () => {
    if (
      negotiationResults?.annualRevenueImpactAchieved >
      negotiationResults?.annualRevenueImpactTarget
    ) {
      return negotiationResults?.annualRevenueImpactTarget;
    } else {
      return negotiationResults?.annualRevenueImpactAchieved;
    }
  };

  const tableData = [
    {
      id: 1,
      name: 'Target (over all clients)',
      uv: negotiationResults?.annualRevenueImpactTarget,
      color: COLORS.blue8,
    },
    {
      id: 2,
      name: 'Delta',
      uv: Math.abs(negotiationResults?.annualRevenueImpactDelta),
      color: negotiationResults?.annualRevenueImpactDelta < 0 ? '#C0C0C0' : COLORS.blue1,
      pv: getPvValue(),
      isLessThanZero: negotiationResults?.annualRevenueImpactDelta <= 0,
    },
    {
      id: 3,
      name: 'Achieved/Planned ',
      uv: negotiationResults?.annualRevenueImpactAchieved,
      color: COLORS.blue8,
      test: negotiationResults?.annualRevenueImpactAchievedDifference,
    },
  ];

  const getUvValue = (value?: number) => {
    if (value) {
      if (value < 1000 && value > -1000) {
        return value;
      } else if (value >= 1000 && value < 1000000) {
        return `${Math.round((value / 1000) * 100) / 100}k`;
      } else if (value >= 1000000 && value < 1000000000) {
        return `${Math.round((value / 1000000) * 100) / 100}m`;
      } else if (value <= -1000 && value > -1000000) {
        return `${Math.round((value / 1000) * 100) / 100}k`;
      } else if (value <= -1000000 && value > -1000000000) {
        return `${Math.round((value / 1000000) * 100) / 100}m`;
      } else {
        return null;
      }
    }
  };

  //eslint-disable-next-line
  const renderCustomBarLabel = (props: any) => {
    const { x, width, name } = props;
    const labelValueObj = tableData.find((item) => item.name === name);
    return (
      <text fontWeight={700} x={x + width / 2} y={20} fill="#666" textAnchor="middle" dy={-6}>
        {labelValueObj?.isLessThanZero && '-'}
        {numberSeparator(getUvValue(labelValueObj?.uv))}
      </text>
    );
  };

  return (
    <div className={classes.revenueImpactChart}>
      <BarChart
        data={tableData}
        width={350}
        height={200}
        margin={{
          top: 20,
          // right: 30,
          // left: -10,
          bottom: 0,
        }}>
        <CartesianGrid vertical={false} horizontal={false} />
        <Bar dataKey="pv" stackId="a" fill="transparent" barSize={80}>
          {tableData.map((item) => {
            return <Cell key={item.id} fill="transparent" />;
          })}
        </Bar>
        <Bar
          isAnimationActive={false}
          dataKey="uv"
          stackId="a"
          fill="#82ca9d"
          barSize={24}
          radius={[0, 0, 0, 0]}
          label={renderCustomBarLabel}>
          {tableData.map((item) => {
            return <Cell key={item.id} fill={item.color} />;
          })}
        </Bar>
      </BarChart>
      <div className={classes.xAxisLine} />
      <div className={classes.xAxisLineLabel}>
        {tableData.map((item) => (
          <div key={item.name} className={classes.itemLabel}>
            {item.name}
          </div>
        ))}
      </div>
    </div>
  );
};

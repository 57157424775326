import React, { useEffect, useMemo } from 'react';
import { Stack } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { ButtonBase } from 'src/components/common/ButtonBase';

import { COLORS } from 'src/constants/COLORS';

import {
  getTargetSettings,
  updateTargetForTargetSettings,
} from 'src/modules/campaignTarget/actions';

import { Store } from 'src/types';
import { TargetSettingsTable } from './components/TargetSettingsTable';
import { Loader } from 'src/components/common/Loader';
import { useDebouncedCallback } from 'use-debounce';
import { NoDataPlaceholder } from 'src/components/common/NoDataPlaceholder';
import { ForbidEditCheckbox } from 'src/components/common/ForbidEditCheckbox';

import { ScreenNames } from 'src/constants/ScreenNames';
import { StepNames } from '../../../../../constants/stepNames';
import { Stepper } from '../../../../common/Stepper';

export const TargetSetting: React.FC = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { targetSettings, loading } = useSelector((state: Store) => state.campaignTarget);
  const { disableEditPage } = useSelector((state: Store) => state.home);

  const updateTarget = useDebouncedCallback((body) => {
    dispatch(updateTargetForTargetSettings.request(body));
  }, 300);

  const goBack = () => {
    navigate('/configure-levels/campaign-target/target-given');
  };

  const goNext = () => {
    navigate('/configure-levels/customer-specific-targets/eoi-weighting');
  };

  const EnhancedContentContainer = useMemo(() => {
    if (targetSettings.length === 0 && !loading) {
      return <NoDataPlaceholder text="No data yet. Please try again later." />;
    }

    return loading ? (
      <Loader isLoading={true} marginTop={40} />
    ) : (
      <TargetSettingsTable
        disableEdit={disableEditPage}
        updateTarget={updateTarget}
        settings={targetSettings}
      />
    );
  }, [disableEditPage, loading, targetSettings, updateTarget]);

  useEffect(() => {
    dispatch(getTargetSettings.request());
  }, [dispatch]);

  return (
    <div>
      <Stepper
        steps={[
          {
            name: 'Touchable revenue',
            activeIndex: null,
            allCompleted: true,
          },
          {
            name: 'Campaign target',
            activeIndex: 1,
          },
          {
            name: 'Customer-specific targets',
            activeIndex: null,
          },
        ]}
        type={StepNames.ALL}
      />
      {EnhancedContentContainer}

      <Stack
        spacing={4}
        direction="row"
        width="100%"
        marginTop={loading ? 63 : 23}
        justifyContent="space-between">
        <ForbidEditCheckbox
          showCheckbox={targetSettings.length > 0}
          checkboxValue={disableEditPage}
          screenName={ScreenNames.TARGET_SETTINGS}
        />
        <Stack width="100%" direction="row" spacing={4} justifyContent="flex-end">
          <ButtonBase
            iconColor={COLORS.red1}
            variant="outlined"
            title="Campaign target"
            onClick={goBack}
            icon="goBack"
            iconLeft={true}
          />
          <ButtonBase
            iconColor={COLORS.red1}
            variant="contained"
            title="EOI indicator weighting"
            onClick={goNext}
            icon="next"
          />
        </Stack>
      </Stack>
    </div>
  );
};
